import * as React from 'react';
import { CSSProperties } from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import { IconType } from 'react-icons/lib';
// import { RefHandler } from 'react-popper';
import {
  Colors,
  DEFAULT_COLOR_THEME,
  getThemeProps,
  hideOutline,
  IControlledProps,
  IDisabledProps,
  ILabelProps,
  IMessageProps,
  insetShadow,
  removeNonHTMLProps,
  safeInvoke,
  transitionBorder,
} from '../../common';
import { ValidationSummary } from '..';
import { FieldGroup } from '../FieldGroup/FieldGroup';
import { FormLabel } from '../FormLabel/FormLabel';

export interface ITextFieldProps {
  type?: 'text' | 'password' | 'email';

  name: string;

  placeholder?: string;

  innerRef?: any;

  /**
   * Icon from react-icons library, see MunikumIcons enum!
   */
  leftIcon?: IconType;

  // quick fix to change color on icon
  leftIconColor?: string;

  /**
   * Icon from react-icons library, see MunikumIcons enum!
   */
  rightIcon?: IconType;

  /**
   * custom style
   */
  style?: CSSProperties;

  /**
   * custom style on outer FieldGroup component, use this if you want to remove padding
   */
  fieldGroupStyle?: CSSProperties;

  /**
   * custom style on input element
   */
  inputStyle?: CSSProperties;
  /**
   * Icon was not clickable. Probaly a better fix than adding a prop?
   */
  onClickIcon?: () => void;
}

export type TextFieldState = {
  id?: string;
};

interface IIconRenderProps {
  hasLeftIcon: boolean;
  hasRightIcon: boolean;
}

type TextRenderProps = IMessageProps & IIconRenderProps;

const TextAndIconWrapper = styled.div`
  position: relative;
  display: block;
  width: 15em;

  height: 2.25em;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 0.55em;
  width: 1.3em;
  height: 1.3em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  //background-color: chocolate;
`;

const LeftIconWrapper = styled(IconWrapper)`
  left: 0.5em;
  color: ${props =>
    props.theme.noAccent?.iconColor ??
    getThemeProps(DEFAULT_COLOR_THEME).noAccent.iconColor};
`;
const RightIconWrapper = styled(IconWrapper)`
  right: 0.5em;
  color: ${props =>
    props.theme.noAccent?.iconColor ??
    getThemeProps(DEFAULT_COLOR_THEME).noAccent.iconColor};
`;

export const TextInput = styled.input`
  box-sizing: border-box;
  border: 1px solid
    ${props =>
      props.theme.noAccent?.borderColor ??
      getThemeProps(DEFAULT_COLOR_THEME).noAccent.borderColor};
  //min-width: 15em;
  //max-width: 100%;
  width: 100%;
  border-radius: 0.25em;
  background-color: ${props =>
    props.theme.secondaryContentBackgroundColor ??
    getThemeProps(DEFAULT_COLOR_THEME).secondaryContentBackgroundColor};
  font-size: 0.875em;
  line-height: 1.1875em;

  height: 100%;
  font-weight: 400;
  padding: 0.55em
    ${(props: TextRenderProps) => (props.hasRightIcon ? '.5em' : '.75em')}
    0.55em
    ${(props: TextRenderProps) => (props.hasLeftIcon ? '2.25em' : '.9375em')};

  // 1,3125
  // 1,3125 + .75 = 2,0625

  font-family: Lato, sans-serif;

  color: ${props => props.theme.textColor};
  //text-shadow: rgb(51, 51, 51) 0 0 0;

  &::placeholder {
    color: ${props => props.theme.textSecondary};
  }
  &:focus:enabled {
    // TODO: what todo when focus + error/success? let focus-border override?
    border-color: ${Colors.BLACK};
  }
  &:disabled {
    border: 1px solid ${Colors.GREY};
    background-color: ${Colors.GREYDISABLED};
  }

  ${insetShadow()};
  ${hideOutline()};
  ${transitionBorder()};
`;

export class TextField extends React.Component<
  ITextFieldProps &
    IControlledProps &
    ILabelProps &
    IMessageProps &
    IDisabledProps &
    React.HTMLProps<HTMLInputElement>,
  TextFieldState
> {
  public static defaultProps: Partial<ITextFieldProps> = {
    type: 'text',
  };

  UNSAFE_componentWillMount() {
    const id = _.uniqueId('txtField-');
    this.setState({
      id: id,
    });
  }

  renderLeftIcon() {
    if (this.props.leftIcon === undefined || this.props.leftIcon === null) {
      return null;
    }

    const Icon = this.props.leftIcon;

    return (
      <LeftIconWrapper>
        <Icon
          style={{
            transition: 'fill .25s ease-in-out, color .25s ease-in-out',
          }}
        />
      </LeftIconWrapper>
    );
  }

  renderRightIcon() {
    if (this.props.rightIcon === undefined || this.props.rightIcon === null) {
      return null;
    }

    const Icon = this.props.rightIcon;

    return (
      <RightIconWrapper
        onClick={() => {
          safeInvoke(this.props.onClickIcon);
        }}
      >
        <Icon
          style={{
            transition: 'fill .25s ease-in-out, color .25s ease-in-out',
          }}
        />
      </RightIconWrapper>
    );
  }

  render() {
    const {
      name,
      label,
      onChange,
      type,
      onBlur,
      value,
      info,
      success,
      warning,
      error,
      disabled,
      innerRef,
      leftIcon,
      rightIcon,
      style,
      fieldGroupStyle,
      inputStyle,
      ...rest
    } = this.props;

    const { id } = this.state;

    const result = (
      <FieldGroup style={fieldGroupStyle}>
        {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
        <TextAndIconWrapper style={style}>
          {leftIcon && this.renderLeftIcon()}
          {rightIcon && this.renderRightIcon()}
          <TextInput
            id={id}
            name={name}
            type={type}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            disabled={disabled}
            error={error}
            warning={warning}
            success={success}
            info={info}
            ref={innerRef}
            hasLeftIcon={leftIcon !== undefined && leftIcon !== null}
            hasRightIcon={rightIcon !== undefined && rightIcon !== null}
            style={inputStyle}
            {...removeNonHTMLProps(rest)}
          />
        </TextAndIconWrapper>
        <ValidationSummary
          info={info}
          success={success}
          warning={warning}
          error={error}
        />
      </FieldGroup>
    );

    return result;
  }
}
