import * as React from 'react';
import { IToastProps, Toast } from './Toast';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import _ from 'lodash';

const uuidv1 = require('uuid/v1');

const CustomOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  pointer-events: none;
  // user-select: none;
`;
const ToastCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 70px;
`;
interface IToasterCompProps {}
interface IToasterCompState {
  toasts: { [key: string]: any };
}

export class Toaster extends React.PureComponent<
  IToastProps,
  IToasterCompState
> {
  private toastRoot: HTMLElement = null;
  constructor(props: IToasterCompProps) {
    super(props);
    this.state = {
      toasts: [],
    };

    this.toastRoot = document.getElementById('toast-root') as HTMLElement;
    if (this.toastRoot === null) {
      const pr = document.createElement('div');
      pr.setAttribute('id', 'toast-root');
      document.body.appendChild(pr);
      this.toastRoot = pr;
    }
  }

  public removeToast(id: string) {
    const dismiss = _.clone(this.state.toasts);
    delete dismiss[id];
    this.setState(
      {
        toasts: dismiss,
      },
      () => {}
    );
  }

  public addToast(props: IToastProps): string {
    const myID = uuidv1();
    const { onDismiss, ...rest } = props;
    const temp: any = (
      <Toast
        key={myID}
        {...rest}
        onDismiss={() => {
          const dismiss = _.clone(this.state.toasts);
          delete dismiss[myID];
          this.setState({
            toasts: dismiss,
          });
        }}
      />
    );

    let hack = {};
    hack[myID] = temp;

    const thomas = Object.assign({}, this.state.toasts, hack);
    this.setState({
      toasts: thomas,
    });

    return myID;
  }
  render() {
    return (
      <>
        {createPortal(
          <CustomOverlay>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ToastCont>
                {_.values(this.state.toasts).map((c, i) => c)}
              </ToastCont>
            </div>
          </CustomOverlay>,
          this.toastRoot
        )}
      </>
    );
  }
}

export default Toaster;
