import * as React from 'react';
import { RefObject } from 'react';
import styled from 'styled-components';
import Query from 'react-apollo/Query';
import { myHistory } from '../../index';
import { Menu, MenuDivider, MenuItem } from '../../components/basic/Menu';
import { MunikumIcons } from '../../components/common/icons';
import { MenuTitle } from '../../components/basic/Menu/MenuTitle';
import { TopSearch } from './TopSearch';
import { AvatarButton } from './AvatarButton';
import { GET_ME_FOR_DASHBOARD } from '../../containers/Dashboard/AnnualCycleDashboard';
import { Colors, safeInvokeDeprecated } from '../../components/common';
import { TogglePopover } from '../../components/basic/Popover/TogglePopover';
import { sizes } from '../../components/common/media';
import { IconButton } from '../../components/basic/IconButton/IconButton';

export interface IHeaderProps {
  /**
   * Search here... or Search in grants...
   */
  searchPlaceholder: string;

  /**
   * User initials, two letters upper right corner
   */
  initials: string;

  /**
   * Number of unread notifications to show in badge, can be undefined
   */
  notificationCount?: number;

  userMenuLabel: string;

  /**
   * If you click on top search bar omniSearch pops up
   */
  handleClickSearch: () => void;
  myProfileLabel: string;
  isLeftSidebarExpanded: boolean;
  signoutLabel: string;
}

export interface IHeaderDispatch {
  /**
   * @deprecated hamburger sucks
   */
  onClickHamburger?: () => void;

  /**
   * @deprecated cant logout without dropdown menu
   */
  onLogout?: () => void;

  onClickFavourites: () => void;

  onClickNotifications: () => void;

  // onClickAvatar: () => void;

  onSignOut: () => void;
}

export interface IHeaderState {
  isLeftSidebarExpanded: boolean;
}

const HeaderContainer = styled.div`
  background-color: ${props => props.theme.contentBackgroundColor};
  width: 100%; // mobile
  z-index: 1;

  @media (min-width: ${sizes.ipad}px) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 3.125em; // 5em;
    //background-color: #fff;
  }
`;

const LeftContainer = styled.div`
  width: 100%;

  @media (min-width: ${sizes.ipad}px) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    //height: 5em;
  }

  //background-color: lightskyblue;
`;

const RightContainer = styled.div`
  //background-color: lightsalmon;

  display: none;

  @media (min-width: ${sizes.ipad}px) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 8em;
    height: 100%;

    border-left: solid 1px ${Colors.GREYBORDER};
    padding-right: 1.5em;
  }
`;

const SearchContainer = styled.div`
  //background-color: #BADA55;

  flex: 3;

  @media (min-width: ${sizes.ipad}px) {
    height: 3.125em;
    //calc(width: 100% - 208px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: ${(props: { isLeftSidebarExpanded: boolean }) =>
            props.isLeftSidebarExpanded ? '0' : '4em'};
    padding-right: 1.5em;
  }
`;

const PictureDiv = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export class Header2 extends React.PureComponent<
  IHeaderProps & IHeaderDispatch,
  IHeaderState
> {
  private favouriteRef: RefObject<IconButton>;

  constructor(props: any) {
    super(props);
    this.state = {
      isLeftSidebarExpanded: this.props.isLeftSidebarExpanded,
    };
    this.favouriteRef = React.createRef();
  }

  UNSAFE_componentWillReceiveProps(
    nextProps: Readonly<IHeaderProps & IHeaderDispatch>,
    nextContext: any
  ): void {
    if (nextProps.isLeftSidebarExpanded !== this.props.isLeftSidebarExpanded) {
      this.setState({
        isLeftSidebarExpanded: nextProps.isLeftSidebarExpanded,
      });
    }
  }

  handleClickHamburger = () => {
    safeInvokeDeprecated(this.props.onClickHamburger);
  };

  handleClickFavourite = () => {
    safeInvokeDeprecated(this.props.onClickFavourites);
  };

  handleClickBell = () => {
    safeInvokeDeprecated(this.props.onClickNotifications);
  };

  handleLogout = () => {
    safeInvokeDeprecated(this.props.onLogout);
  };

  flashStar = () => {
    if (this.favouriteRef.current) {
      safeInvokeDeprecated(this.favouriteRef.current.punch);
    }
  };

  render() {
    const { searchPlaceholder, initials, notificationCount } = this.props;

    return (
      <Query query={GET_ME_FOR_DASHBOARD}>
        {({ loading, error, data }) => {
          if (error) {
            throw error;
          }

          let picture: any = undefined;
          if (loading) {
          }
          if (!loading) {
            const me = data && data.me;
            if (me && me.picture) {
              picture = 'https://hel1.your-objectstorage.com/munikum-public/' + me.picture;
            }
          }

          return (
            <HeaderContainer>
              <LeftContainer>
                <SearchContainer
                  isLeftSidebarExpanded={this.state.isLeftSidebarExpanded}
                >
                  <TopSearch
                    handleSearchClick={this.props.handleClickSearch}
                    searchPlaceholder={searchPlaceholder}
                  />
                </SearchContainer>
              </LeftContainer>
              <RightContainer>
                <span style={{ fontSize: '.9em' }}>
                  <IconButton
                    ref={this.favouriteRef}
                    icon={MunikumIcons.Star}
                    style={{ marginLeft: '.1em' }}
                    onClick={this.handleClickFavourite}
                    disableGlobalClickHack={true}
                  />
                </span>
                <span style={{ fontSize: '.9em' }}>
                  <IconButton
                    icon={MunikumIcons.Alert}
                    onClick={this.handleClickBell}
                    style={{ marginLeft: '.1em' }}
                    badge={notificationCount}
                    disableGlobalClickHack={true}
                  />
                </span>

                <TogglePopover
                  position={'bottom'}
                  renderTarget={ref => {
                    return (
                      <div
                        ref={ref}
                        style={{
                          height: '3.125em',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <span style={{ fontSize: '.65em' }}>
                          {picture && (
                            <PictureDiv>
                              <img
                                style={{
                                  borderRadius: '50%',
                                  marginLeft: '.8em',
                                }}
                                src={picture}
                                width="30px"
                                height="30px"
                                alt={''}
                              />
                            </PictureDiv>
                          )}
                          {!picture && (
                            <AvatarButton
                              style={{ marginLeft: '.8em' }}
                              initials={initials}
                            />
                          )}
                        </span>
                      </div>
                    );
                  }}
                >
                  <Menu
                    style={{
                      width: '12em',
                    }}
                  >
                    <MenuTitle text={this.props.userMenuLabel} />
                    <MenuItem
                      leftIcon={MunikumIcons.MyProfile}
                      text={this.props.myProfileLabel}
                      onClick={() => myHistory.push('/my-profile')}
                    />
                    <MenuDivider />
                    <MenuItem
                      leftIcon={MunikumIcons.SignOut}
                      text={this.props.signoutLabel}
                      onClick={() => safeInvokeDeprecated(this.props.onSignOut)}
                    />
                  </Menu>
                </TogglePopover>
              </RightContainer>
            </HeaderContainer>
          );
        }}
      </Query>
    );
  }
}

// export const Header2 = injectIntl(Header);
