import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { TextSmall } from '../../basic/Structural/typography';
import { ProgressBar } from '../../basic/ProgressBar/ProgressBar';
import { sizes } from '../../common/media';

export interface IWheelSummaryProps {
  title: JSX.Element | string | null | false;
  completed: number;
  count: number;
  description?: JSX.Element | string | null | false;
  style?: CSSProperties;
}

interface IWheelSummaryState {
  // temp: number;
}

const Circle = styled.div`
  border-radius: 50%;
  
  
  opacity: 0;
  flex-direction: column;

  width: 100%;
  height: 100%;

  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;

  @media (min-width: ${400}px) {
    opacity: 1;
  }
  // @media (min-width: ${sizes.medium}px) {
  //   padding: 2em;
  // }

  box-sizing: border-box;
`;

/**
 * WheelSummary, circle with progressbar in the middle
 */
class WheelSummaryComp extends React.PureComponent<
  InjectedIntlProps & IWheelSummaryProps,
  IWheelSummaryState
> {
  render() {
    const { completed, count, style } = this.props;

    return (
      <Circle style={style}>
        <div style={{ flex: '0 0 33%', width: '90%' }}>
          <ProgressBar
            value={completed}
            max={count || 100}
            color={'#00B0B9'}
            min={0}
            style={{
              width: '100%',
              marginTop: '2.5em',
            }}
          />
        </div>
        <div style={{ flex: '0 0 33%', marginTop: '.25em' }}>
          <TextSmall>
            <FormattedMessage
              id={'WheelSummary.progress'}
              defaultMessage={'{completed} of {max}'}
              values={{
                completed: completed,
                max: count,
              }}
            />
          </TextSmall>
        </div>
      </Circle>
    );
  }
}

export const WheelSummary = injectIntl(WheelSummaryComp);
