import * as React from 'react';
import { RefObject } from 'react';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import { MunikumIcons } from '../common/icons';
import { IFile } from '../../services/fileservice';
import {
  FileUploader,
  IFileUploaderRenderProps,
} from '../basic/UserFile/FileUploader';
import {
  FieldsOnCommentReplies,
  FieldsOnRepliesFragment,
  UserFile,
} from '../../models/types';
import {
  CardContentContainer,
  CardLastUpdated,
  CardLeftContainerResponsive,
  ChatBubble,
  ChatBubbleHigh,
} from '../basic/Card/Card';
import { UserSummary } from '../discussion/UserSummary';
import { ContentState, convertToRaw } from 'draft-js';
import { MuToolbar, MuToolbarMode } from '../basic/MuEditor/MuToolbar';
import {
  ColorTheme,
  isReallyEmpty,
  safeInvoke,
  safeInvokeDeprecated,
} from '../common';
import {
  FileUploaderPicker,
  IFileItem,
} from '../basic/UserFile/FileUploaderPicker';
import { MuInternalEditor } from '../basic/MuEditor/MuInternalEditor';
import {
  IMuBag,
  IMuEditorRenderProps,
  MuEditor,
  MuEditorMode,
} from '../basic/MuEditor/MuEditor';
import {
  MuCancelButton,
  MuEditButton,
  MuSaveButton,
} from '../basic/MuEditor/MuButtons';
import { commonMessages } from '../language/commonMessages';
import { FileUploaderList } from '../basic/UserFile/FileUploaderList';
import styled from 'styled-components';
import { sizes } from '../common/media';
import moment from 'moment';
// import ContentState = Draft.Model.ImmutableData.ContentState;
import { Button, ButtonTheme } from '../basic';
import { Clap, ObjectType } from '../basic/Clap/Clap';
import { FileInfoCard } from '../basic/UserFile/FileInfoCard';
import { Overlay } from '../basic/Overlay/Overlay';
import { TextVerySmall } from '../basic/Structural/typography';
import { ClapDiv } from '../layout/Page/Page';

interface ICommentPersonOrganization {
  name: string;
  organizationImage?: string;
  url?: string;
}

interface ICommentPerson {
  id: string;
  name: string;
  role?: string;
  picture?: string;
  organization: ICommentPersonOrganization;
}

export interface ICommentViewProps {
  // comment: IComment;
  author: ICommentPerson;

  lastUpdated?: Date;
  /**
   * set this to put component in controlled mode
   */
  rawMarkdown: string;
  parentMetaId: string;
  refetchSubCommentType: ObjectType;
  me: ICommentPerson;
  onDeleteClick: () => void;
  onSubCommentDelete: (id: number | string, message: string) => void;
  canEdit: boolean;
  objectToClap?: ObjectType;
  onFileDelete?: (commentFileId: string) => void;

  commentId: string;
  isLiked: boolean;
  likes: number;
  /**
   * sub comments
   */
  comments?: FieldsOnRepliesFragment[];
  onClickClap?: () => void;

  // old solution:
  // files?: ReadonlyArray<IFile>;

  // new graphql solution:
  files?: any;
}

export interface ICommentViewDispatch {
  /**
   * save (main) comment
   * @param markdown
   */
  onUpdateComment?: (markdown: string, files: IFileItem[]) => void;

  /**
   * save new (sub) comment
   * @param markdown
   */
  onSaveSubComment?: (markdown: string, files: IFileItem[]) => void;

  /**
   * update (sub) comment with given id and meta
   * @param markdown
   * @param id
   * @param meta
   */
  onUpdateSubComment?: (markdown: string, id: string, meta: string) => void;

  /**
   * dispatched event telling you to scroll to this position!
   * @param pos
   */
  onShouldScrollToPosition?: (pos: { x: number; y: number }) => void;
}

interface ICommentViewState {
  /**
   * is editing (main) comment
   */
  isEditing: boolean;

  /**
   * makes the editor focus when clicking the component when in editor mode
   */
  isFocusingEditor: boolean;
  /**
   * main comment raw markdown
   */
  rawMarkdown: string;
  /**
   * add new comment local state
   */
  newComment?: string;
  // likes: number;

  /**
   * edit subcomment with meta id
   */
  editingSubComment?: string;

  isFileInfoCardVisible: boolean;
  fileInfoId: string;
}

const TextAndLastUpdatedContainer = styled.div`
  display: block;

  //background-color: lightgreen;
  background-color: ${props => props.theme.contentBackgroundColor};

  @media (min-width: ${sizes.large}px) {
    display: flex;
    justify-content: space-between;
    //align-content: stretch;
  }
`;

const TextDiv = styled.div`
  @media (min-width: ${sizes.large}px) {
    flex: 1 1 0;
  }
  min-width: 0; /* Firefox word wrap fix */
`;

const LastUpdatedDiv = styled.div`
  @media (min-width: ${sizes.large}px) {
    flex: 0 0 auto;
  }
`;
const LastUpdatedSubCommentDiv = styled.div`
  margin-top: 1em;
  @media (min-width: ${sizes.large}px) {
    margin-top: 0;
  }
`;
const SubCommentEditDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 1em;
  @media (min-width: ${1170}px) {
    justify-content: flex-end;
  }
`;

const FooterContainer = styled.div`
  display: block;

  //background-color: lightgreen;

  @media (min-width: ${sizes.large}px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const ToolbarAndFilesContainer = styled.div`
  display: flex;
`;

const ButtsContainer = styled.div`
  //background-color: pink;
  display: flex;
  justify-content: flex-start;
  align-content: flex-end;
  flex-wrap: wrap;
  margin-top: 8px;
`;

const DualCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: ${props => props.theme.contentBackgroundColor};

  margin-bottom: 1.5em; // 0.625em;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
`;

const MainContainer = styled.div`
  display: flex;
`;

const SubTitleAndCommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubCommentsWrapper = styled.div`
  padding-top: 1em;
  padding-bottom: 1em;
  //background-color: rebeccapurple;
  background-color: rgba(208, 211, 212, 0.2);
`;

const SubCommentContainer = styled.div`
  display: flex;
  //background-color: rgba(208, 211, 212, 0.2);

  //background-color: pink;

  margin-bottom: 1.25em;
`;
export const FilesContainer = styled.div`
  display: flex;
  margin-top: 8px;
`;
const CommentsTitle = styled.div`
  height: 2.25em;
  background-color: ${props => props.theme.contentBackgroundColor};
  border: 1px solid rgba(208, 211, 212, 0.2);

  line-height: 2.25em;

  font-family: 'Lato', sans-serif;
  font-size: 0.875em;
  font-weight: bold;
  color: ${props => props.theme.textColor};

  padding-left: 1.5em;
`;
const SubCommentDiv = styled.div`
  display: block;
  @media (min-width: ${sizes.large}px) {
    display: flex;
  }
`;
const CardLeftContainerResponsiveCustom = styled(CardLeftContainerResponsive)`
  @media (min-width: ${sizes.large}px) {
    border-radius: ${(props: {
        isBottomLeftRound: boolean;
        isTopLeftRound: boolean;
      }) => (props.isTopLeftRound ? '4px' : '0')}
      0 0
      ${(props: { isBottomLeftRound: boolean; isTopLeftRound: boolean }) =>
        props.isBottomLeftRound ? '4px' : '0'};
  }
`;

const messages = defineMessages({
  editComment: {
    id: 'CommentView2.editComment',
    defaultMessage: 'Edit comment...',
  },
  deleteCommentWarning: {
    id: 'CommentView2.deleteCommentWarning',
    defaultMessage: 'Are you sure you want to delete the comment...',
  },
});

/**
 * CommentView with subcomments (1 level)
 */
class CommentViewComp extends React.PureComponent<
  ICommentViewProps & InjectedIntlProps & ICommentViewDispatch,
  ICommentViewState
> {
  private readonly newSubCommentRef: RefObject<any>;
  private readonly editorRef: RefObject<any>;

  constructor(
    props: ICommentViewProps & ICommentViewDispatch & InjectedIntlProps
  ) {
    super(props);

    this.state = {
      isEditing: false,
      rawMarkdown: props.rawMarkdown,
      isFocusingEditor: false,
      isFileInfoCardVisible: false,
      fileInfoId: undefined,
    };

    this.newSubCommentRef = React.createRef();
    this.editorRef = React.createRef();
  }

  UNSAFE_componentWillReceiveProps(
    nextProps: ICommentViewProps & ICommentViewDispatch & InjectedIntlProps
  ) {
    if (
      nextProps.rawMarkdown !== undefined &&
      nextProps.rawMarkdown !== this.state.rawMarkdown
    ) {
      if (this.state.isEditing) {
        console.warn(
          'We received updated markdown from redux, but were still editing!'
        );
      } else {
        // console.log('updating from markdown..');
        this.setState({
          rawMarkdown: nextProps.rawMarkdown,
        });
      }
    }
  }

  /**
   * start editing (main) comment
   */
  handleEditComment = () => {
    this.setState({
      isEditing: !this.state.isEditing,
    });
  };

  /**
   * cancel editing (main) comment
   */
  handleCancelEditComment = () => {
    this.setState({
      isEditing: false,
    });
  };

  /**
   * save (main) comment.
   * using optimistic update, invoke onSave
   * @param markdown
   */
  // TODO: Add files later
  handleSaveComment = (markdown: string, files: IFileItem[]) => {
    this.setState(
      {
        rawMarkdown: markdown,
        isEditing: false,
      },
      () => {
        safeInvokeDeprecated(
          this.props.onUpdateComment,
          this.state.rawMarkdown,
          files
        );
        // console.log(files, ' er filer her?');
      }
    );
  };

  /**
   * add new (sub) comment editor to ui
   */
  handleAddSubComment = () => {
    this.setState(
      {
        newComment: '',
      },
      () => {
        if (this.newSubCommentRef.current) {
          const half = window.innerHeight / 2;
          const whereTo =
            this.newSubCommentRef.current.getBoundingClientRect().top -
            80 -
            half;

          // console.log(
          //   'test scroll to: ' + whereTo,
          //   this.newSubCommentRef.current.getBoundingClientRect()
          // );
          // window.scrollTo(0, whereTo);
          safeInvokeDeprecated(this.props.onShouldScrollToPosition, {
            x: 0,
            y: whereTo,
          });
        }
        //
        // // TODO: focus editor (first onMount works, but if we click a second time we also want to focus?)
        /*
          fixed it so when you first press new subComment it focuses the subcomment's container,
          this makes the page scroll down to the editor, then i focus the editor's ref
         */
        if (this.newSubCommentRef.current) {
          if (this.editorRef.current) {
            setTimeout(() => {
              const editorNode = this.editorRef.current;
              editorNode.focus();
            }, 1000);
          }
        }
      }
    );
  };

  /**
   * save new (sub) comment
   * @param markdown
   */
  handleSaveSubComment = (markdown: string, files: IFileItem[]) => {
    this.setState(
      {
        newComment: undefined,
      },
      () => safeInvokeDeprecated(this.props.onSaveSubComment, markdown, files)
    );
  };

  /**
   * start editing subComment
   */
  handleEditSubComment = (comment: FieldsOnCommentReplies) => {
    this.setState({
      editingSubComment: comment.id,
    });
  };

  handleCancelEditSubComment = () => {
    this.setState({
      editingSubComment: undefined,
    });
  };

  handleUpdateSubComment = (
    markdown: string,
    subCommentToUpdate: FieldsOnCommentReplies
  ) => {
    // console.log(markdown, subCommentToUpdate.id);
    this.setState(
      {
        editingSubComment: undefined,
      },
      () => {
        safeInvokeDeprecated(
          this.props.onUpdateSubComment,
          markdown,
          subCommentToUpdate.id,
          subCommentToUpdate
        );
      }
    );
  };

  render() {
    const {
      author,
      lastUpdated,
      intl,
      canEdit,
      comments,
      me,
      files,
    } = this.props;
    const { isEditing, rawMarkdown, newComment } = this.state;
    const isSubCommentsVisible =
      newComment !== undefined ||
      (comments !== undefined && comments.length > 0);
    // console.log('render commentview2. isSub ' + isSubCommentsVisible);

    return (
      <DualCardContainer>
        <MainContainer>
          <CardLeftContainerResponsiveCustom
            style={{ position: 'relative' }}
            isBottomLeftRound={!isSubCommentsVisible}
            isTopLeftRound={true}
          >
            <ChatBubble />
            {author && (
              <UserSummary
                userImage={author.picture}
                name={author.name}
                role={author.role || ''}
                organizationImage={
                  (author.organization &&
                    author.organization.organizationImage) ||
                  ''
                }
                organizationName={
                  (author.organization && author.organization.name) || ''
                }
                id={author.id}
                organizationUrl={author.organization && author.organization.url}
              />
            )}
            <ClapDiv isLiked={this.props.isLiked}>
              <MunikumIcons.Clap style={{ height: '1.3em', width: '1.3em' }} />
              <TextVerySmall
                style={{
                  marginLeft: '1.7em',
                }}
              >
                {this.props.likes} {intl.formatMessage(commonMessages.claps)}
              </TextVerySmall>
            </ClapDiv>
          </CardLeftContainerResponsiveCustom>
          <CardContentContainer
            onClick={() => {
              // changing state so the lifecyclemethod in the MuInternalEditor will run and focus the editor
              this.setState({
                isFocusingEditor: !this.state.isFocusingEditor,
              });
            }}
          >
            <MuEditor
              rawMarkdown={rawMarkdown}
              onSubmit={(
                markdown: string,
                files2: IFileItem[],
                contentState: ContentState,
                muEditorBag: IMuBag
              ) => {
                if (!isReallyEmpty(markdown) || files2.length > 0) {
                  this.handleSaveComment(
                    JSON.stringify(convertToRaw(contentState)),
                    files2
                  );
                }
              }}
              editorRenderFunc={(propsEditor: IMuEditorRenderProps) => {
                return (
                  <React.Fragment>
                    <TextAndLastUpdatedContainer>
                      <TextDiv>
                        <MuInternalEditor
                          isFocusingEditor={this.state.isFocusingEditor}
                          editorMode={
                            isEditing ? MuEditorMode.Edit : MuEditorMode.Preview
                          }
                          onChange={propsEditor.handleDraftChange}
                          draftState={propsEditor.draftState}
                        />
                      </TextDiv>
                      {!isEditing && (
                        <LastUpdatedDiv
                          style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            marginTop: '.5em',
                          }}
                        >
                          <CardLastUpdated
                            style={{ marginRight: '1em', marginTop: '.5em' }}
                          >
                            {intl.formatMessage(commonMessages.lastUpdated)}
                            {': '}
                            {moment
                              .utc(lastUpdated)
                              .local()
                              .format('YYYY-MM-DD')}
                          </CardLastUpdated>
                          <Clap
                            idToRefetch={this.props.parentMetaId}
                            idToClap={this.props.commentId}
                            ObjectToClap={this.props.objectToClap}
                            isClapped={this.props.isLiked}
                          />
                        </LastUpdatedDiv>
                      )}
                    </TextAndLastUpdatedContainer>

                    <FileUploader
                      onUploadableFiles={(files22: any) => {
                        // console.log(
                        //   'I will make MuEditor forget files?',
                        //   files22
                        // );
                        propsEditor.handleUploadableFiles(files22);
                      }}
                      onDeleteFile={(id: string) => {
                        safeInvokeDeprecated(this.props.onFileDelete, id);
                      }}
                      files={
                        files &&
                        files.map((file: UserFile) => {
                          const temp: IFile = {
                            size: file.size,
                            contenttype: file.contentType,
                            lastupdated: file.lastUpdated,
                            filename: file.name,
                            description: file.description,
                            id: file.id,
                          };
                          return temp;
                        })
                      }
                      fileUploaderRenderFunc={(
                        propsFiles: IFileUploaderRenderProps
                      ) => {
                        // console.log('renderProps files comp', propsFiles);
                        return (
                          <div>
                            <FilesContainer>
                              <FileUploaderList
                                onFileDelete={propsFiles.handleDeleteFile}
                                persistedFiles={propsFiles.persistedFiles}
                                recentFiles={propsFiles.recentFiles}
                                onRemoveFile={propsFiles.handleRemoveFile}
                                canEdit={canEdit}
                                isEditMode={this.state.isEditing}
                                onClick={(id: string) => {
                                  this.setState({
                                    isFileInfoCardVisible: true,
                                    fileInfoId: id,
                                  });
                                }}
                              />
                            </FilesContainer>
                            <FooterContainer>
                              <ToolbarAndFilesContainer>
                                {isEditing && (
                                  <MuToolbar
                                    toolbarMode={propsEditor.toolbarMode}
                                    onClickCommand={propsEditor.handleCommand}
                                    isItalicChecked={
                                      propsEditor.isItalicChecked
                                    }
                                    isBoldChecked={propsEditor.isBoldChecked}
                                    style={{
                                      marginRight: '.5em',
                                    }}
                                  />
                                )}
                              </ToolbarAndFilesContainer>
                              {!isEditing && (
                                <ButtsContainer>
                                  {canEdit && (
                                    <MuEditButton
                                      onClick={this.handleEditComment}
                                    />
                                  )}
                                  <Button
                                    buttonTheme={ButtonTheme.RED}
                                    text={intl.formatMessage(
                                      commonMessages.comment
                                    )}
                                    onClick={this.handleAddSubComment}
                                    style={{ marginLeft: '.5em' }}
                                  />
                                </ButtsContainer>
                              )}
                              {isEditing && (
                                <ButtsContainer>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Button
                                      leftIcon={MunikumIcons.Delete}
                                      theme={ColorTheme.Yellow}
                                      text={intl.formatMessage(
                                        commonMessages.delete
                                      )}
                                      style={{
                                        margin: '4px',
                                        width: '102px',
                                        height: '36px',
                                      }}
                                      onClick={() => {
                                        if (
                                          window.confirm(
                                            intl.formatMessage(
                                              messages.deleteCommentWarning
                                            )
                                          )
                                        ) {
                                          safeInvokeDeprecated(
                                            this.props.onDeleteClick
                                          );
                                          this.setState({
                                            isEditing: false,
                                          });
                                        }
                                      }}
                                    />
                                    <FileUploaderPicker
                                      onNewFiles={propsFiles.handleNewFiles}
                                      style={{
                                        margin: '4px',
                                      }}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <MuCancelButton
                                      onClick={() => {
                                        propsEditor.handleCancel();
                                        propsFiles.handleClearAllFiles();
                                        this.handleCancelEditComment();
                                      }}
                                      style={{ margin: '4px' }}
                                    />
                                    <MuSaveButton
                                      onClick={() => {
                                        // console.log('click');
                                        propsEditor.handleSubmit();
                                        propsFiles.handleClearAllFiles();
                                      }}
                                      style={{ margin: '4px' }}
                                    />
                                  </div>
                                </ButtsContainer>
                              )}
                            </FooterContainer>

                            <Overlay isOpen={this.state.isFileInfoCardVisible}>
                              <FileInfoCard
                                fileid={this.state.fileInfoId}
                                canEdit={canEdit}
                                canDelete={canEdit}
                                onDelete={() => {
                                  this.setState(
                                    {
                                      isFileInfoCardVisible: false,
                                    },
                                    () => {
                                      safeInvoke(
                                        this.props.onFileDelete,
                                        this.state.fileInfoId
                                      );
                                    }
                                  );
                                }}
                                onCancel={() => {
                                  this.setState({
                                    isFileInfoCardVisible: false,
                                  });
                                }}
                              />
                            </Overlay>
                          </div>
                        );
                      }}
                    />
                  </React.Fragment>
                );
              }}
            />
          </CardContentContainer>
        </MainContainer>

        {isSubCommentsVisible && (
          <SubTitleAndCommentsContainer>
            <CommentsTitle>
              Kommentarer ({comments ? comments.length : 0})
            </CommentsTitle>
            <SubCommentsWrapper>
              {comments &&
                comments.map((c: FieldsOnRepliesFragment) => {
                  const isEditingSubComment =
                    this.state.editingSubComment === c.id;
                  const canEditSubComment = c.author.id === this.props.me.id;

                  // console.log('render subcomment ' + c.id + ' isEditing: ' + isEditingSubComment);

                  return (
                    <SubCommentContainer key={'r2d2__' + c.id}>
                      <CardLeftContainerResponsiveCustom
                        isTopLeftRound={false}
                        isBottomLeftRound={false}
                        style={{
                          position: 'relative',
                          backgroundColor: 'transparent',
                          paddingTop: '.5em',
                        }}
                      >
                        <ChatBubbleHigh />
                        <UserSummary
                          name={c.author.name}
                          userImage={c.author.picture}
                          role={c.author.role || ''}
                          organizationName={
                            (c.author.organization &&
                              c.author.organization.name) ||
                            ''
                          }
                          organizationImage={
                            (c.author.organization &&
                              c.author.organization.organizationImage) ||
                            ''
                          }
                          id={c.author.id}
                          organizationUrl={
                            c.author.organization && c.author.organization.url
                          }
                        />
                        <ClapDiv isLiked={this.props.isLiked}>
                          <MunikumIcons.Clap
                            style={{ height: '1.3em', width: '1.3em' }}
                          />
                          <TextVerySmall
                            style={{
                              marginLeft: '1.7em',
                            }}
                          >
                            {c.likes} {intl.formatMessage(commonMessages.claps)}
                          </TextVerySmall>
                        </ClapDiv>
                      </CardLeftContainerResponsiveCustom>
                      <CardContentContainer
                        onClick={() => {
                          this.setState({
                            isFocusingEditor: !this.state.isFocusingEditor,
                          });
                        }}
                        style={{
                          marginRight: '.5em',
                        }}
                      >
                        <MuEditor
                          onSubmit={(
                            markdown: string,
                            files2: IFileItem[],
                            contentState: ContentState,
                            muEditorBag: IMuBag
                          ) => {
                            if (!isReallyEmpty(markdown)) {
                              this.handleUpdateSubComment(
                                JSON.stringify(convertToRaw(contentState)),
                                c
                              );
                            }
                          }}
                          onCancel={() => this.handleCancelEditSubComment()}
                          rawMarkdown={c.message}
                          toolbarMode={MuToolbarMode.None}
                          editorMode={
                            isEditingSubComment
                              ? MuEditorMode.Edit
                              : MuEditorMode.Preview
                          }
                          editorRenderFunc={(props: IMuEditorRenderProps) => {
                            return (
                              <SubCommentDiv>
                                <div style={{ flex: '1 1 auto' }}>
                                  <MuInternalEditor
                                    isFocusingEditor={
                                      this.state.isFocusingEditor
                                    }
                                    editorMode={props.editorMode}
                                    toolbarMode={props.toolbarMode}
                                    draftState={props.draftState}
                                    onChange={props.handleDraftChange}
                                  />

                                  {isEditingSubComment && (
                                    <SubCommentEditDiv style={{}}>
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'flex-start',
                                        }}
                                      >
                                        <Button
                                          leftIcon={MunikumIcons.Delete}
                                          theme={ColorTheme.Yellow}
                                          text={intl.formatMessage(
                                            commonMessages.delete
                                          )}
                                          style={{
                                            margin: '4px',
                                            width: '102px',
                                            height: '36px',
                                          }}
                                          onClick={() => {
                                            if (
                                              window.confirm(
                                                intl.formatMessage(
                                                  messages.deleteCommentWarning
                                                )
                                              )
                                            ) {
                                              safeInvoke(
                                                this.props.onSubCommentDelete,
                                                c.id,
                                                c.message
                                              );
                                              this.setState({
                                                isEditing: false,
                                              });
                                            }
                                          }}
                                        />
                                      </div>
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <MuCancelButton
                                          style={{ margin: '4px' }}
                                          onClick={props.handleCancel}
                                        />
                                        <MuSaveButton
                                          onClick={props.handleSubmit}
                                          style={{ margin: '4px' }}
                                        />
                                      </div>
                                    </SubCommentEditDiv>
                                  )}
                                </div>
                                {/*{isEditingSubComment && <div*/}
                                {/*style={{*/}
                                {/*flex: '0 0 auto',*/}
                                {/*marginLeft: '.5em',*/}
                                {/*marginBottom: '.875em',*/}
                                {/*alignSelf: 'flex-end',*/}
                                {/*}}*/}
                                {/*>*/}
                                {/**/}
                                {/*</div>}*/}
                                {!isEditingSubComment && (
                                  <div>
                                    <LastUpdatedSubCommentDiv
                                      style={{
                                        flex: '0 0 auto',
                                        marginLeft: '.5em',
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        justifyContent: 'space-between',
                                        overflowWrap: 'break-word',
                                        wordBreak: 'break-word',
                                        // marginBottom: '1em',
                                        wordWrap: 'break-word',
                                      }}
                                    >
                                      <CardLastUpdated
                                        style={{
                                          marginLeft: '.5em',
                                          marginRight: '1em',
                                          marginTop: '.5em',
                                        }}
                                      >
                                        {intl.formatMessage(
                                          commonMessages.lastUpdated
                                        )}
                                        {': '}
                                        {moment
                                          .utc(lastUpdated)
                                          .local()
                                          .format('YYYY-MM-DD')}
                                      </CardLastUpdated>
                                      <Clap
                                        idToRefetch={this.props.parentMetaId}
                                        idToClap={c.id}
                                        commentId={this.props.commentId}
                                        ObjectToClap={
                                          this.props.refetchSubCommentType
                                        }
                                        isClapped={c.isLiked}
                                      />
                                      {/*<div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', paddingTop: '1em'}}>*/}
                                      {/*/!*<MuEditButton />*!/*/}
                                      {/**/}
                                      {/*</div>*/}
                                    </LastUpdatedSubCommentDiv>
                                    {!isEditingSubComment && canEditSubComment && (
                                      <div
                                        style={{
                                          marginTop: '.5em',
                                          display: 'flex',
                                          justifyContent: 'flex-end',
                                          alignItems: 'flex-end',
                                          height: '100%',
                                        }}
                                      >
                                        <MuEditButton
                                          onClick={() =>
                                            this.handleEditSubComment(c)
                                          }
                                          style={{ marginBottom: '1.5em' }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                )}
                              </SubCommentDiv>
                            );
                          }}
                        />

                        {/*{newComment === undefined && (index === comments.length - 1) &&*/}
                        {/*<div>*/}
                        {/*<LinkComponent>Add comment...</LinkComponent>*/}
                        {/*</div>}*/}
                      </CardContentContainer>
                    </SubCommentContainer>
                  );
                })}

              {newComment !== undefined && (
                <SubCommentContainer>
                  <CardLeftContainerResponsiveCustom
                    isTopLeftRound={false}
                    isBottomLeftRound={false}
                    style={{
                      position: 'relative',
                      backgroundColor: 'transparent',
                      paddingTop: '.5em',
                    }}
                  >
                    <ChatBubbleHigh />
                    <UserSummary
                      name={me.name}
                      userImage={me.picture}
                      role={me.role || ''}
                      organizationName={
                        (me.organization && me.organization.name) || ''
                      }
                      organizationImage={
                        (me.organization &&
                          me.organization.organizationImage) ||
                        ''
                      }
                      id={me.id}
                      organizationUrl={me.organization && me.organization.url}
                    />
                  </CardLeftContainerResponsiveCustom>
                  <CardContentContainer
                    style={{
                      marginRight: '.5em',
                    }}
                    onClick={() => {
                      if (this.editorRef.current) {
                        const node = this.editorRef.current;
                        node.focus();
                      }
                    }}
                    ref={this.newSubCommentRef}
                  >
                    <MuEditor
                      onSubmit={(
                        markdown: string,
                        files2: IFileItem[],
                        contentState: ContentState,
                        muEditorBag: IMuBag
                      ) => {
                        if (!isReallyEmpty(markdown)) {
                          this.handleSaveSubComment(
                            JSON.stringify(convertToRaw(contentState)),
                            files2
                          );
                          muEditorBag.resetEditor();
                        }

                        // NOTE: a little "flash" on save, add optimistic update?
                      }}
                      toolbarMode={MuToolbarMode.None}
                      editorMode={MuEditorMode.Edit}
                      editorRenderFunc={(props: IMuEditorRenderProps) => {
                        return (
                          <div style={{ display: 'flex' }}>
                            <div style={{ flex: '1 1 auto' }}>
                              <MuInternalEditor
                                ref={this.editorRef}
                                editorMode={props.editorMode}
                                toolbarMode={props.toolbarMode}
                                draftState={props.draftState}
                                onChange={props.handleDraftChange}
                              />
                            </div>
                            <div
                              style={{
                                flex: '0 0 auto',
                                marginLeft: '.5em',
                                marginBottom: '.875em',
                                alignSelf: 'flex-end',
                              }}
                            >
                              <MuSaveButton onClick={props.handleSubmit} />
                            </div>
                          </div>
                        );
                      }}
                    />
                  </CardContentContainer>
                </SubCommentContainer>
              )}
            </SubCommentsWrapper>
          </SubTitleAndCommentsContainer>
        )}
      </DualCardContainer>
    );
  }
}

export const CommentView2 = injectIntl(CommentViewComp);
