import * as React from 'react';
import gql from 'graphql-tag';
import Tour from 'reactour';

import { Option } from 'react-select';
import styled from 'styled-components';
import { myApolloClient } from '../../graphql/apolloClientFactory';
import {
  MathiasDropDown,
  tagsPositionEnum,
} from '../basic/DropDownSelect/MathiasDropDown';
import { MunikumIcons } from './icons';
import { RelationQueryQuery, RelationQueryVariables } from '../../models/types';
import { ADD_TO_RELATED } from '../annualCycle/EditAnnualCycleForm';
import { Button, ButtonTheme } from '../basic';

const HelpText = styled.div`
  :&hover {
    cursor: pointer;
  }
`;

export const SEARCH_QUERY_RELATION = gql`
  query RelationQuery($query: String!, $onlyWrite: Boolean) {
    search(query: $query, onlyWrite: $onlyWrite) {
      topics {
        id
        title
        description
        uri
      }
      actionValues {
        id
        title
        description
        uri
      }
      discussions {
        id
        title
        description
        uri
      }
      calendars {
        id
        title
        description
        uri
      }
    }
  }
`;

interface INewRelationComp {
  meta: string;
  metasToAdd: Array<Number>;
  isTourOpen: boolean;
}

export class NewRelation extends React.PureComponent<{}, INewRelationComp> {
  constructor(props: any) {
    super(props);
    this.state = {
      meta: '',
      metasToAdd: [],
      isTourOpen: false,
    };
  }
  closeTour = () => {
    this.setState({
      isTourOpen: false,
    });
  };

  render() {
    const steps = [
      {
        position: 'right',
        selector: '.firstStep',
        content:
          'Her kan du søke på id`en, tittelen eller beskrivelsen og velge mellom et tilskudd, et innlegg, ' +
          'et årshjul eller en gruppe. I dinna boksen kan du berre velge en. Denne vil bli knytta til dei du velger i neste steg',
      },
      {
        position: 'right',

        selector: '.secondStep',
        content:
          'Her kan du velge fleire tilskudd, grupper, årshjul og innlegg, alle desse vil bli knytta til den du valgte i den første boksen og motsatt.',
      },
      {
        position: 'right',
        selector: '.submit',
        content:
          'Når du er valgt dei du vil lage en relasjon mellom, trykker du her. Det vil da komme en melding om alt gjekk smuud, eller noko gjekk gale.',
      },
      {
        selector: '[data-tut="reactour__goTo"]',
        content: ({ goTo }) => (
          <div>
            Vil du gjenta alle stegene, eller føler du deg klar for denne
            utfordringa?
            <div>
              <br /> Hilsen Mathias!
            </div>
            <Button
              buttonTheme={ButtonTheme.RED}
              onClick={() => goTo(0)}
              text={'Gå tilbake'}
            />
          </div>
        ),
      },
    ];

    return (
      <>
        <HelpText
          style={{
            marginBottom: '2em',
            color: '#FF5C39',
            fontSize: '18px',
            fontWeight: 'bold',
          }}
          onClick={() => {
            this.setState({
              isTourOpen: true,
            });
          }}
        >
          Trykk på meg for hjelp!
        </HelpText>
        <Tour
          steps={steps}
          isOpen={this.state.isTourOpen}
          onRequestClose={this.closeTour}
          maskClassName="mask"
          className="helper"
          rounded={5}
          accentColor={'#5cb7b7'}
        />
        <div className={'firstStep'} style={{ width: '20em' }}>
          <MathiasDropDown
            style={{ width: '20em', marginBottom: '12px' }}
            onTagRemoved={() => {
              this.setState({
                meta: '',
              });
            }}
            label={'Medlemmer'}
            maxElements={1}
            tagsPosition={tagsPositionEnum.INSIDE}
            selectedItems={[]}
            onChange={(value: Array<Option>) => {
              value.forEach(t => {
                this.setState({
                  meta: t.value,
                });
              });
            }}
            loadItems={(
              input: string,
              preloadedItems: Array<Option<string>>
            ): Promise<Array<Option<string>>> => {
              return new Promise(async (resolve, reject) => {
                const items: Array<Option<string>> = [];
                try {
                  const res = await myApolloClient.query<
                    RelationQueryQuery,
                    RelationQueryVariables
                  >({
                    query: SEARCH_QUERY_RELATION,
                    variables: {
                      query: input,
                    },
                  });

                  res.data.search.topics.forEach((topic, index) => {
                    const temp: Option<string> = {
                      label: topic.title,
                      value: topic.id,
                    };
                    items.push(temp);
                  });

                  res.data.search.actionValues.forEach(actionValues => {
                    const temp2: Option<string> = {
                      label: actionValues.title,
                      value: actionValues.id,
                    };
                    items.push(temp2);
                  });

                  res.data.search.discussions.forEach(discussions => {
                    const temp3: Option<string> = {
                      label: discussions.title,
                      value: discussions.id,
                    };
                    items.push(temp3);
                  });

                  res.data.search.calendars.forEach(calendars => {
                    const temp4: Option<string> = {
                      label: calendars.title,
                      value: calendars.id,
                    };
                    items.push(temp4);
                  });

                  res.data.search.topics.forEach((topic, index) => {
                    const temp: Option<string> = {
                      label: topic.title,
                      value: topic.id,
                    };
                    items.push(temp);
                  });
                  resolve(items);
                } catch (e) {
                  console.error('Fail', e);
                  reject(e);
                }
              });
            }}
          />
        </div>
        <div className={'secondStep'} style={{ width: '20em' }}>
          <MathiasDropDown
            style={{ width: '20em' }}
            label={'Til:'}
            // maxElements={1}
            tagsPosition={tagsPositionEnum.INSIDE}
            selectedItems={[]}
            onChange={(value: Array<Option>) => {
              value.forEach(t => {
                this.setState(
                  {
                    metasToAdd: [],
                  },
                  () => {
                    let valueToPush = +t.value;
                    this.state.metasToAdd.push(valueToPush);
                  }
                );
              });
            }}
            loadItems={(
              input: string,
              preloadedItems: Array<Option<string>>
            ): Promise<Array<Option<string>>> => {
              return new Promise(async (resolve, reject) => {
                const items: Array<Option<string>> = [];
                try {
                  const res = await myApolloClient.query<
                    RelationQueryQuery,
                    RelationQueryVariables
                  >({
                    query: SEARCH_QUERY_RELATION,
                    variables: {
                      query: input,
                    },
                  });

                  res.data.search.topics.forEach((topic, index) => {
                    const temp: Option<string> = {
                      label: topic.title,
                      value: topic.id,
                    };
                    items.push(temp);
                  });

                  res.data.search.actionValues.forEach(actionValues => {
                    const temp2: Option<string> = {
                      label: actionValues.title,
                      value: actionValues.id,
                    };
                    items.push(temp2);
                  });

                  res.data.search.discussions.forEach(discussions => {
                    const temp3: Option<string> = {
                      label: discussions.title,
                      value: discussions.id,
                    };
                    items.push(temp3);
                  });

                  res.data.search.calendars.forEach(calendars => {
                    const temp4: Option<string> = {
                      label: calendars.title,
                      value: calendars.id,
                    };
                    items.push(temp4);
                  });

                  res.data.search.topics.forEach((topic, index) => {
                    const temp: Option<string> = {
                      label: topic.title,
                      value: topic.id,
                    };
                    items.push(temp);
                  });
                  resolve(items);
                } catch (e) {
                  console.error('Fail', e);
                  reject(e);
                }
              });
            }}
          />
        </div>
        <Button
          buttonTheme={ButtonTheme.RED}
          className={'submit'}
          text={'Lag relasjon'}
          onClick={() => {
            myApolloClient
              .mutate({
                mutation: ADD_TO_RELATED,
                variables: {
                  input: {
                    meta: +this.state.meta,
                    metasToAdd: this.state.metasToAdd,
                  },
                },
              })
              .then(t => {
                alert('Nydelig, alt gjekk smuud');
              })
              .catch(e => {
                alert(e + 'something went wrong');
              });
          }}
          style={{ marginTop: '12px' }}
          leftIcon={MunikumIcons.LeftCheck}
        />
      </>
    );
  }
}
