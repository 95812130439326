import React from 'react';
import { CleanDialog, CloseWarningOption } from '../Dialog/CleanDialog';
import {
  MathiasDropDown,
  tagsPositionEnum,
} from '../DropDownSelect/MathiasDropDown';
import { myApolloClient } from '../../../graphql/apolloClientFactory';

import { Button } from '..';
import { Colors, ColorTheme, safeInvoke } from '../../common';

import { Option } from 'react-select';
import gql from 'graphql-tag';

// TODO Use this component on topicYearwheel tab/topicactionvalueTab, needs some work

interface IOverlayWithSearchProps {
  isOpen: boolean;
  currentValues: Array<any>;
  onSave: (values: Array<Option>) => void;
  onClose?: () => void;
  changeOwnerMode?: boolean;
}

const SEARCH_PERSONS = gql`
  query SearchPersons($query: String!) {
    search(query: $query) {
      persons {
        id
        name
        organization {
          id
          name
          organizationImage
          url
        }
        picture
        role
        department
      }
    }
  }
`;

export function OverlayWithSearch(props: IOverlayWithSearchProps) {
  const [values, setValues] = React.useState(props.currentValues);
  return (
    <CleanDialog
      canEscapeKeyClose={false}
      canOutsideClickClose={true}
      onClose={() => safeInvoke(props.onClose)}
      isOpen={props.isOpen}
      showCloseWarning={CloseWarningOption.SIMPLE}
    >
      <div
        style={{
          backgroundColor: '#fff',
          padding: '2em',
          width: '21em',
        }}
      >
        <div
          style={{
            marginBottom: '2em',
            fontSize: '16px',
            fontFamily: 'Lato, sans-serif',
            fontWeight: 'bold',
          }}
        >
          Ny eigar
        </div>
        {props.changeOwnerMode && (
          <div
            style={{
              marginBottom: '.5em',
              fontWeight: 'bold',
              fontSize: '12px',
              color: Colors.RED,
            }}
          >
            Velg Berre 1!!, Refrech sida når du er ferdig
          </div>
        )}
        <MathiasDropDown
          style={{ marginBottom: '2em' }}
          label={''}
          tagsPosition={tagsPositionEnum.INSIDE}
          selectedItems={values.map(item => {
            return {
              label: item.name,
              value: item.id,
            };
          })}
          onChange={(value: Array<Option>) => {
            setValues(
              value.map(c => {
                return c.value;
              })
            );
          }}
          loadItems={(input: string): Promise<Array<Option<string>>> => {
            return new Promise(async (resolve, reject) => {
              const items: Array<Option<string>> = [];
              try {
                const res = await myApolloClient.query({
                  query: SEARCH_PERSONS,
                  variables: {
                    query: input,
                  },
                });

                res.data.search.persons.forEach(person => {
                  const temp2: Option<string> = {
                    label: person.name,
                    value: person.id,
                  };
                  items.push(temp2);
                });

                resolve(items);
              } catch (e) {
                console.error('Fail', e);
                reject(e);
              }
            });
          }}
        />
        <div
          style={{
            display: 'flex',
            height: '100%',
            width: '100%',
            justifyContent: 'flex-end',
          }}
        >
          <>
            <Button
              text={'avbryt'}
              theme={ColorTheme.White}
              onClick={() => {
                setValues([]);
                safeInvoke(props.onClose);
              }}
            />
            <Button
              style={{ marginLeft: '.5em' }}
              text={'bekreft'}
              onClick={() => {
                safeInvoke(props.onSave, values);
              }}
            />
          </>
        </div>
      </div>
    </CleanDialog>
  );
}
