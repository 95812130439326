import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import {
  Colors,
  hideOutline,
  IControlledProps,
  IDisabledProps,
  ILabelProps,
  IMessageProps,
  insetShadow,
  isFunction,
  safeInvokeDeprecated,
  transitionBorder,
} from '../../common';
import { ValidationSummary } from '..';
import { FieldGroup } from '../FieldGroup/FieldGroup';
import { IoIosArrowDown } from 'react-icons/io';
import { FormLabel } from '../FormLabel/FormLabel';

const messages = defineMessages({
  noItems: {
    id: 'dropdownselect.noitems',
    defaultMessage: 'No items',
  },
  select: {
    id: 'dropdownselect.select',
    defaultMessage: 'Select...',
  },
});

const arrow = require('./down-arrow.svg') as string;

// TODO: add support for custom styles, className... have to style both wrapper and input?

const MyWrapper = styled.div`
  position: relative;
  display: inline-block;
  //background-color: #4a90e2;
  width: 100%;

  cursor: pointer;
`;

const DropDownSelectStyled = styled.select`
  position: relative;
  box-sizing: border-box;
  display: block;
  border: 1px solid ${props => props.theme.noAccent.borderColor};
  //min-width: 15em;
  max-width: 100%;
  width: 100%;
  border-radius: 0.25em;
  font-size: .875em;
  line-height: 1.1875em;
  //font-weight: 500;
  padding: 0.55em 0.75em 0.55em 0.9375em;

  // hide default dropdown arrow
  -webkit-appearance: none;
  -moz-appearance: none;
  // background: ${Colors.WHITE} url(${arrow}) right center no-repeat;
  background-color: ${props => props.theme.secondaryContentBackgroundColor};
  cursor: pointer;

  color:  ${props => props.theme.textColor};
  
  
  &::placeholder {
    color: ${Colors.GREYPLACEHOLDER};
  }
  &:focus:enabled {
    // TODO: what todo when focus + error/success? let focus-border override?
    border-color: ${props => props.theme.noAccent.borderColor};
  }
  &:disabled {
    border: 1px solid ${Colors.GREY};
    color: ${Colors.GREYDISABLEDTEXT};
    background-color: ${Colors.GREY};
  }

  ${insetShadow()};

  ${hideOutline()};
  ${transitionBorder()};
`;

// tslint:disable-next-line
export type ItemRenderFunc = (
  key: string,
  index: number,
  // tslint:disable-next-line
  item: any
) => string | null | false;
// export type ItemRenderFunc = (key: string, index: number, item: any) => JSX.Element | null | false;

export interface IDropDownSelectProps {
  /**
   * items as a dictionary with a string id / key
   */
  // tslint:disable-next-line
  items?: { [id: string]: any };

  /**
   * name of field in item
   * If you have a Dictionary<IPerson>, use accessor='name' to print the person's name in the option.
   */
  accessor?: string;
  defaultText?: string;
  /**
   * if you need a custom render func for options
   */
  itemRenderer?: ItemRenderFunc;

  // showEmptyOption?: boolean;

  // we already have a onChange event.. should we wrap it exposing selected value? (e.target.value)?
  // or emit additional event=
  onChangeSelected?: (selectedKey: string) => void;

  showEmptyOption?: boolean;

  emptyOptionText?: string;

  fieldGroupStyle?: CSSProperties;

  style?: CSSProperties;
}

export const DROPDOWN_EMPTY_VALUE = '__empty__';

/**
 * DropDownSelect - v1 simple
 *
 * Future improvements?:
 * - searchable textfield?
 * - select tags?
 * - multiselect?
 * - add label in list in addition to value?
 * - custom render funcs?
 * - change arrow color on hover
 */
class DropDownSelectComp extends React.PureComponent<
  IDropDownSelectProps &
    IControlledProps &
    ILabelProps &
    IMessageProps &
    IDisabledProps &
    React.HTMLProps<HTMLElement> &
    InjectedIntlProps,
  {
    selectedKey?: string;
    id: string;
  }
> {
  public static defaultProps = {
    showEmptyOption: true,
    value: undefined,
  };

  UNSAFE_componentWillMount() {
    const id = _.uniqueId('dd-');
    this.setState({
      id: id,
      selectedKey: undefined,
    });
  }

  // tslint:disable-next-line
  handleChange = (e: any) => {
    safeInvokeDeprecated(this.props.onChange, e);
    safeInvokeDeprecated(this.props.onChangeSelected, e.target.value);
  };

  render() {
    const {
      name,
      label,
      onBlur,
      value,
      info,
      success,
      warning,
      error,
      disabled,
      items,
      accessor,
      itemRenderer,
      showEmptyOption,
      emptyOptionText,
      fieldGroupStyle,
      style,
    } = this.props;
    const { id } = this.state;

    if (this.props.children && items) {
      console.warn('warning: dont use both children and props!');
    }

    if (!items) {
      return null;
    }

    return (
      <FieldGroup style={fieldGroupStyle}>
        {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
        <MyWrapper>
          <IoIosArrowDown
            fill={Colors.GREY}
            width={'1.0em'}
            height={'1.0em'}
            style={{
              position: 'absolute',
              right: '.55em',
              top: '.55em',
              pointerEvents: 'none',
              zIndex: 14,
            }}
          />
          <DropDownSelectStyled
            disabled={disabled}
            name={name}
            onChange={this.handleChange}
            onBlur={onBlur}
            value={value}
            id={id}
            // isEmptySelected={value === DROPDOWN_EMPTY_VALUE}
            style={style}
          >
            {showEmptyOption && (
              <option value={DROPDOWN_EMPTY_VALUE} key={'-1_s'}>
                {emptyOptionText ||
                  this.props.defaultText ||
                  this.props.intl.formatMessage(messages.select)}
              </option>
            )}
            {this.props.children}
            {items &&
              !this.props.children &&
              Object.keys(items).map((key: string, index: number) => {
                // tslint:disable-next-line
                let content: any = '';
                const item = items[key]; // item can be object or string

                if (itemRenderer && accessor) {
                  console.warn(
                    'warning, dont use both itemRender and accessor. itemRender will win'
                  );
                }

                if (itemRenderer) {
                  if (isFunction(itemRenderer)) {
                    content = itemRenderer(key, index, item);
                  }
                } else if (accessor) {
                  if (!item.hasOwnProperty(accessor as string)) {
                    console.warn(
                      'warning: ' +
                        accessor +
                        ' is invalid valueselector in dropdown! Valid keys are:' +
                        Object.keys(item).join(',')
                    );
                  }
                  content = item[accessor as string];
                } else if (typeof item === 'string') {
                  content = item;
                } else {
                  content = key;
                }

                return (
                  <option value={key} key={key}>
                    {content}
                  </option>
                );
              })}
          </DropDownSelectStyled>
        </MyWrapper>
        <ValidationSummary
          info={info}
          success={success}
          warning={warning}
          error={error}
        />
      </FieldGroup>
    );
  }
}

export const DropDownSelect = injectIntl(DropDownSelectComp);
