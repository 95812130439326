import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import { MunikumIcons } from '../../components/common/icons';
import moment from 'moment';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import { safeInvoke } from '../../components/common';
import { IContentMeta } from '../../services/models/contentMeta';
import { commonMessages } from '../../components/language/commonMessages';

const messages = defineMessages({
  seeAll: {
    id: 'TopicAboutInfo.seAll',
    defaultMessage: 'See all',
  },
  lastActivity: {
    id: 'TopicAboutInfo.lastActivity',
    defaultMessage: 'Last activity',
  },
});

const InfoBox = styled.div`
  width: 100%;
  background-color: ${props => props.theme.contentBackgroundColor};
  height: 258px;
  position: relative;
  box-sizing: border-box;
  min-width: 1px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  // IE11 fix
  z-index: 1;
`;

const InfoBoxContainer = styled.div`
  display: flex;
  align-items: flex-start;
  //flex-grow: 1;
  width: 100%;

  border-radius: 3px;
`;

const Title = styled.div`
  color: ${props => props.theme.textColor};
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-weight: bold;
  margin-top: 29px;
  margin-left: 24px;
  &:hover {
    cursor: pointer;
  }
`;
const NotFollowedText = styled.div`
  margin-left: 24px;
  font-style: italic;
  opacity: 0.7;
  font-size: 16px;
  color: ${props => props.theme.textColor};
  font-family: Lato, sans-serif;
`;

const LastActivity = styled.div`
  color: ${props => props.theme.textLabel};
  font-family: Lato, sans-serif;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.3px;
  text-align: right;
  margin-top: 31px;
  margin-right: 21px;
`;

const WhenPosted = styled.div`
  color: ${props => props.theme.textLabel};
  font-family: Lato, sans-serif;
  font-size: 10px;
  letter-spacing: 0.3px;
  text-align: right;
`;
const TextContainer = styled.div`
display:flex;
justify-Content:space-between;
align-Items: center;
border-Bottom: 1px solid #DFE2E5;
height:33px;
color: ${props => props.theme.textColor};
margin-left: 24px;
margin-right: 21px;

 :hover {
cursor:pointer;


`;

const TitleText = styled.div`
  color: ${props => props.theme.textColor};
  font-family: Lato, sans-serif;
  font-size: 14px;
  //width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${TextContainer}:hover & {
    text-decoration: underline;
  }
  :hover {
    text-decoration: underline;
  }
`;

export const SeeAll = styled.div`
  color: ${props => props.theme.accent1.color};
  font-family: Lato, sans-serif;
  font-size: 10px;
  margin-left: 24px;
  margin-bottom: 15px;
`;

export const SeeAllContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 33px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  :hover {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: #ff5c39;
  }
`;

const TestContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export interface InfoBoxInput {
  /***
   * meta id
   */
  id?: string;
  title?: string;
  lastUpdated?: Date;
  organizationImg?: any;
  role?: string;
  uri: string;
}

interface IInfoBoxes {
  title?: string;
  style?: CSSProperties;
  list?: Array<InfoBoxInput>;
  maxItems?: number;
  icon?: any;
  onClick: (uri: string, id: string) => void;
  dashboardMode?: boolean;
  onSeeAllClick: (id: string) => void;
}

export function metaToInfoBoxInput(meta: IContentMeta): InfoBoxInput {
  return {
    id: meta.id,
    title: meta.title,
    lastUpdated: meta.lastUpdated ? meta.lastUpdated : new Date(0),
    uri: meta.uri,
  };
}

const LastUpdadedComp: React.SFC<IInfoBoxes & InjectedIntlProps> = (
  props: IInfoBoxes & InjectedIntlProps
) => {
  const { list, intl } = props;
  if (props.maxItems) {
    list.splice(props.maxItems);
  }
  return (
    <InfoBoxContainer style={props.style}>
      <InfoBox>
        <TestContainer style={{ marginBottom: '22px' }}>
          <Title> {props.title} </Title>
          <LastActivity>
            {intl.formatMessage(messages.lastActivity)}
          </LastActivity>
        </TestContainer>

        {list &&
          list.map(values => {
            // console.log(values.lastUpdated, 'lastuUpdated', values.title);
            return (
              <TextContainer
                key={values.id}
                onClick={(e: any) => {
                  safeInvoke(props.onClick, values.uri, values.id);
                }}
              >
                <TitleText>{values.title}</TitleText>
                <WhenPosted>
                  {moment
                    .utc(values.lastUpdated)
                    .local()
                    .fromNow()}
                </WhenPosted>
              </TextContainer>
            );
          })}
        {list && list.length === 0 && (
          <NotFollowedText>
            {intl.formatMessage(commonMessages.noFollowedItemsDasbhoardMessage)}
          </NotFollowedText>
        )}

        <SeeAllContainer onClick={e => props.onSeeAllClick('')}>
          <SeeAll> {intl.formatMessage(messages.seeAll)}</SeeAll>
          <MunikumIcons.ArrowRight
            fill={'#333'}
            width={'15px'}
            height={'15px'}
            style={{
              marginBottom: '19px',
              marginRight: '22px',
            }}
          />
        </SeeAllContainer>
      </InfoBox>
    </InfoBoxContainer>
  );
};

export const LastUpdated = injectIntl(LastUpdadedComp);
