import * as React from 'react';
import { CSSProperties, RefObject } from 'react';
import styled from 'styled-components';
import { IconType } from 'react-icons/lib';
import {
  Colors,
  ColorTheme,
  getThemeProps,
  IThemeProps,
  removeNonHTMLProps,
  safeInvoke,
  safeInvokeDeprecated,
} from '../../common';
import { ButtonSize } from '..';
import { MunikumIcons } from '../../common/icons';
import { Tooltip } from '../Tooltip/Tooltip';

import { ColorPicker } from '../../annualCycle/ColorPicker';
import { MunikumKeys } from '../../common/keys';
import { Placement, Popover } from '../Popover/Popover';
import { IconWrapper } from '../Menu';

interface ITagSpanRenderProps {
  uppercase?: boolean;
  color?: string;
  style?: CSSProperties;
  hasLeftIcon?: boolean;
  canClose?: boolean;
  hasShadow: boolean;
}

export interface ITagProps {
  text: string;
  color: string;
  uppercase?: boolean;
  canClose?: boolean;
  hasShadow: boolean;
  styleText?: CSSProperties;
  toolTipMsgLeftIcon?: string | any;
  tooltip?: boolean;
  leftIcon?: IconType;
  popover?: boolean;
  onClick?: (e: Event) => void;
  onClickLeftIcon?: (e: Event) => void;
  stopPropagationOnColorClick?: boolean;
  leftIconColor?: string;
  onClickClose?: (e: any) => void;
  onRemoveColor?: () => void;
  onChangeColor?: (color: string) => void;
  theme?: ColorTheme;
  popoverPosition?: Placement;
  isPopoverOpen?: boolean;
}

const TextDiv = styled.div`
  margin-right: ${(props: ITagSpanRenderProps) =>
    props.canClose ? '0' : '10px'};

  margin-left: ${(props: ITagSpanRenderProps) =>
    props.hasLeftIcon ? '0' : '5px'};
  overflow: hidden;
  width: ${(props: ITagSpanRenderProps) =>
    props.hasLeftIcon ? '90%' : '100%'};
  max-width: 250px;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const CloseDiv = styled.div`
  display: ${(props: ITagSpanRenderProps) => (props.canClose ? '0' : 'flex')};
  align-items: center;
  justify-content: center;

  transition: all 0.2s ease-in-out;

  :hover {
    transform: scale(1.1);
  }
`;

const IconDiv = styled.div`
  display: ${(props: ITagSpanRenderProps) => (props.canClose ? '0' : 'flex')};
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  :hover {
    transform: scale(1.1);
  }
`;
const TagSpan = styled.span`
  display: flex;
  align-items: center;
  position: relative;
  border: none;
  border-radius: 4px;
  box-shadow: none;

  // no zoom/resize ok? do we want to use labels as inline elements inside text?
  min-width: 20px;

  //overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 2px 8px;
  line-height: 16px;
  font-size: 14px;
  letter-spacing: 0.5px;

  //line-height: 1em;
  //font-size: .625em;
  //min-width: 1.250em;
  //padding: .125em 1em;

  font-family: 'Lato', sans-serif;
  font-weight: 500;

  text-transform: ${(props: ITagSpanRenderProps) =>
    props.uppercase !== undefined
      ? props.uppercase
        ? 'uppercase'
        : ''
      : 'uppercase'};

  text-align: center;

  background-color: ${(props: ITagSpanRenderProps) =>
    props.color ? props.color : Colors.BLUE};
  color: ${Colors.WHITE};

  color-adjust: exact !important;
  -webkit-print-color-adjust: exact !important;

  :hover {
    cursor: pointer;
    box-shadow: ${(props: ITagSpanRenderProps) =>
      props.hasShadow ? ' 0 2px 7px 0 rgba(0, 0, 0, 0.3)' : ''};
  }
`;

/**
 * Tag
 */

export class Tag extends React.PureComponent<
  ITagProps & React.HTMLProps<HTMLDivElement>,
  { isPopoverOpen: boolean }
> {
  private popoverRef: RefObject<any>;
  constructor(props: ITagProps & React.HTMLProps<HTMLDivElement>) {
    super(props);
    this.popoverRef = React.createRef();
    this.state = {
      isPopoverOpen: false,
    };
  }

  handleClickOutside = e => {
    if (this.state.isPopoverOpen) {
      if (
        this.popoverRef &&
        !(
          this.popoverRef.current.contains(e.target) ||
          this.popoverRef.current.contains(e.target)
        )
      ) {
        this.setState({
          isPopoverOpen: false,
        });
      }
    }
  };
  handleKeyUp = e => {
    if (this.state.isPopoverOpen) {
      if (e.which === MunikumKeys.ESCAPE) {
        this.setState({
          isPopoverOpen: false,
        });
      }
    }
  };

  componentDidMount(): void {
    if (this.props.popover) {
      document.addEventListener('mousedown', this.handleClickOutside);
      document.addEventListener('keyup', this.handleKeyUp);
    }
  }
  componentWillUnmount(): void {
    if (this.props.popover) {
      document.removeEventListener('mousedown', this.handleClickOutside);
      document.removeEventListener('keyup', this.handleKeyUp);
    }
  }

  UNSAFE_componentWillReceiveProps(
    nextProps: Readonly<ITagProps & React.HTMLProps<HTMLDivElement>>,
    nextContext: any
  ): void {
    if (nextProps.isPopoverOpen !== this.state.isPopoverOpen) {
      this.setState({
        isPopoverOpen: nextProps.isPopoverOpen,
      });
    }
  }

  renderIcon(
    iconComponent: IconType,
    themeProps: IThemeProps,
    isDisabled: boolean
  ) {
    if (iconComponent === undefined || iconComponent === null) {
      return null;
    }

    const Icon = iconComponent;

    // width: ${(props: IIconRenderProps) => props.size === ButtonSize.Small ? '.75em' : props.size === ButtonSize.Normal ? '1em' : '1.25em'}; // 1.2em;
    // height: ${(props: IIconRenderProps) => props.size === ButtonSize.Small ? '.75em' : props.size === ButtonSize.Normal ? '1em' : '1.25em'}; // 1.2em;

    const small = '.75em';
    const normal = '1em';
    const large = '1.25em';
    const x =
      this.props.size === ButtonSize.Small
        ? small
        : this.props.size === ButtonSize.Medium
        ? normal
        : large;

    return (
      <IconWrapper
        style={{
          color: this.props.leftIconColor ? this.props.leftIconColor : '',
        }}
        isHovering={false}
        isDisabled={isDisabled}
      >
        <Icon
          width={x}
          height={x}
          style={{
            transition: 'fill .25s ease-in-out, color .25s ease-in-out',
          }}
        />
      </IconWrapper>
    );
  }

  handleClickLeftIcon = (e: React.MouseEvent<HTMLElement>) => {
    if (!this.props.stopPropagationOnColorClick) {
      e.stopPropagation();
    }
    safeInvokeDeprecated(this.props.onClickLeftIcon, e);
    this.setState({
      isPopoverOpen: !this.state.isPopoverOpen,
    });
  };

  handleClickClose = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    safeInvokeDeprecated(this.props.onClickClose, e);
  };

  render() {
    const {
      color,
      text,
      leftIcon,
      canClose,
      theme,
      hasShadow,
      style,
      styleText,
      uppercase,
      disabled,
      onClickClose,
      onClickLeftIcon,
      ...rest
    } = this.props;
    const myTheme = theme !== undefined ? theme : ColorTheme.Red;
    const myThemeProps = getThemeProps(myTheme);
    const close = this.props.popover ? (
      <MunikumIcons.Close2 style={{ fontWeight: 'lighter', fontSize: '1em' }} />
    ) : (
      <MunikumIcons.Cancel />
    );
    if (text === '') {
      return null;
    }
    return (
      <TagSpan
        onClick={(e: any) => safeInvoke(this.props.onClick, e)}
        style={style}
        color={color}
        hasLeftIcon={leftIcon !== undefined && leftIcon !== null}
        canClose={this.props.canClose}
        hasShadow={this.props.hasShadow}
        uppercase={uppercase}
        {...removeNonHTMLProps(rest)}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-evenly',
          }}
        >
          {this.props.tooltip && (
            <Tooltip
              usePortal={true}
              position={'bottom'}
              content={this.props.toolTipMsgLeftIcon}
            >
              <div>
                {leftIcon && (
                  <IconDiv hasShadow={false} onClick={this.handleClickLeftIcon}>
                    {this.renderIcon(leftIcon, myThemeProps, disabled || false)}
                  </IconDiv>
                )}
              </div>
            </Tooltip>
          )}
           
          {this.props.popover && (
            <Popover
              position={this.props.popoverPosition || 'bottom'}
              // usePortal={true}
              isOpen={this.state.isPopoverOpen}
              renderTarget={ref => {
                return (
                  <div ref={ref}>
                    {leftIcon && (
                      <IconDiv
                        style={{ marginTop: '.25em', marginRight: '.4em' }}
                        hasShadow={false}
                        onClick={this.handleClickLeftIcon}
                      >
                        {this.renderIcon(
                          leftIcon,
                          myThemeProps,
                          disabled || false
                        )}
                      </IconDiv>
                    )}
                  </div>
                );
              }}
            >
              <div ref={this.popoverRef} style={{ width: 'max-content' }}>
                <ColorPicker
                  disabled={false}
                  removeColorOption={true}
                  showOpacity={true}
                  label={this.props.label}
                  onRemoveColor={() => {
                    safeInvoke(this.props.onRemoveColor);
                  }}
                  selectedColor={this.props.color}
                  onChange={(color2: string) => {
                    safeInvoke(this.props.onChangeColor, color2);
                  }}
                />
              </div>
            </Popover>
          )}
          {!this.props.tooltip && leftIcon && !this.props.popover && (
            <IconDiv hasShadow={false} onClick={this.handleClickLeftIcon}>
              {this.renderIcon(leftIcon, myThemeProps, disabled || false)}
            </IconDiv>
          )}
          <TextDiv style={{ ...styleText! }} hasShadow={false}>
            {text}
          </TextDiv>
          {canClose && (
            <CloseDiv
              style={{ color: this.props.popover ? '#333' : '#fff' }}
              hasShadow={false}
              onClick={this.handleClickClose}
            >
              {close}
            </CloseDiv>
          )}
        </div>
      </TagSpan>
    );
  }
}
