import {
  initNetworkDetectionSaga,
  watchNetworkStatusSaga,
} from './network/networkStatus';
import { SagaMiddleware } from 'redux-saga';
import { authLoopSaga, checkMailSaga, initAuthSaga } from './auth/auth';
import {
  entitiesLoadSaga,
  entitiesLoopSaga,
  initEntitiesSaga,
} from './entities/entities';
import { invitePersonSaga } from './person/person';
import { onboardingSaga } from './onboarding/onboarding';
import {
  checkForUpdateSaga,
  checkNotificationsSaga,
  getNotificationsInfoSaga,
  readNotificationSaga,
  sendNotificationSaga,
  websocketSaga,
} from './notification/notification';
import { revokeRefreshTokenSaga } from './refreshToken/refreshToken';
import { uploadRequestWatcherSaga } from './file/file';
import { uploadFilesToTopicSaga } from './topic/topic';

/***
 * initializer, called on startup to start sagas
 * @param {SagaMiddleware<{}>} sagaMiddleware
 */
export const initSagas = (sagaMiddleware: SagaMiddleware<{}>) => {
  // 1. INITIALIZE all watching sagas (they don't do anything until they receive an action)

  // watch network:
  sagaMiddleware.run(watchNetworkStatusSaga);

  // auth:
  sagaMiddleware.run(authLoopSaga); // listens for login actions (username/pass or refresh_token)
  sagaMiddleware.run(checkMailSaga); // listens for check email actions

  // entities:
  sagaMiddleware.run(entitiesLoopSaga); // listens for getEntities actions
  sagaMiddleware.run(entitiesLoadSaga); // listens for loadData actions

  sagaMiddleware.run(invitePersonSaga);

  sagaMiddleware.run(sendNotificationSaga);
  sagaMiddleware.run(getNotificationsInfoSaga);
  sagaMiddleware.run(checkNotificationsSaga);
  sagaMiddleware.run(readNotificationSaga);
  sagaMiddleware.run(websocketSaga);
  // sagaMiddleware.run(checkForUpdateSaga);

  sagaMiddleware.run(revokeRefreshTokenSaga);

  sagaMiddleware.run(onboardingSaga);

  sagaMiddleware.run(uploadFilesToTopicSaga);

  // 2. INITIALIZE sagas that kick off stuff:
  sagaMiddleware.run(initAuthSaga); // read refresh_token from ls, wait for REHYDRATE, log in if refresh token / log out if not
  sagaMiddleware.run(initEntitiesSaga); // load static data (if not loaded already)
  sagaMiddleware.run(initNetworkDetectionSaga);

  sagaMiddleware.run(uploadRequestWatcherSaga);
};
