import * as React from 'react';
import { Colors } from '../common';
import styled from 'styled-components';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import gql from 'graphql-tag';
import Query from 'react-apollo/Query';
import { myHistory } from '../../index';
import { ActionValue, Calendar, Discussion, Topic } from '../../models/types';
import { FollowedMetaListItem } from './FollowedMetaListItem';
import { commonMessages } from '../language/commonMessages';
import { MyType } from '../basic/FollowButton/FollowButton2';
import { IDiscussionItem } from '../../services/models/discussion';
import { IActionValueItem } from '../../services/models/actionValueItem';
import { ITopicItem } from '../../services/models/topic';
import { MetaType } from '../../services/models/contentMeta';
import {
  AuthConsumer,
  authFuncActionValue,
  authFuncCalendar,
  authFuncDiscussion,
  authFuncTopic,
} from '../../auth';
import { SuspenseLoader } from '../basic/Loader/CircleSpinner';

export interface IFollowedMetaListProps {
  followedActionValues?: ReadonlyArray<IActionValueItem>;
  followedDiscussions?: ReadonlyArray<IDiscussionItem>;
  followedTopics?: ReadonlyArray<ITopicItem>;
  isOpen?: boolean;
}

export interface IFollowedMetaListDispatch {
  onClickFollowedItem?: (uri: string) => void;
  onFollowActionValue?: (id: string, follow: boolean) => void;
  onFollowDiscussion?: (id: string, follow: boolean) => void;
  onFollowTopic?: (id: string, follow: boolean) => void;
}

export interface IFollowedMetaListState {
  activeTab: number;
}

const WrapperDiv = styled.div``;

const ContentDiv = styled.div``;

export const GET_CALENDARS_FOR_MY_FOLLOWED = gql`
  query AllCalendarsForSidebar {
    myFollowed {
      calendars {
        id
        isFollowing
        title
        description
        uri
        lastUpdated
      }
    }
  }
`;
export const GET_DISCUSSION_FOR_MY_FOLLOWED = gql`
  query AllDiscussionsForSidebar {
    myFollowed {
      discussions {
        id
        isFollowing
        title
        description
        uri
        lastUpdated
        currentRevision {
          id
          body
        }
      }
    }
  }
`;
export const GET_ACTION_VALUE__FOR_MY_FOLLOWED = gql`
  query AllActionValueForSidebar {
    myFollowed {
      actionValues {
        id
        isFollowing
        title
        description
        uri
        lastUpdated
        currentRevision {
          id
          description
        }
      }
    }
  }
`;
export const GET_TOPICS_FOR_MY_FOLLOWED = gql`
  query AllTopicsForSidebar {
    myFollowed {
      topics {
        id
        isFollowing
        title
        description
        uri
        lastUpdated
      }
    }
  }
`;

const ButtonWrapperDiv = styled.div`
  display: flex;

  font-family: Lato, sans-serif;
  font-size: 0.875em;
  font-weight: 400;
`;

export const NotFollowedText = styled.div`
  opacity: 0.5;
  color: #333333;
  font-family: Lato, sans-serif;
  font-size: 18px;
`;

const ButtonDiv = styled.div`
  width: 50%;

  height: 3em;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  background-color: ${(props: { active: boolean }) =>
    (props.active && Colors.RED) || Colors.GREY};
  color: ${(props: { active: boolean }) =>
    (props.active && Colors.WHITE) || Colors.BLACK};
`;

const messages = defineMessages({
  grantsTitle: {
    id: 'FollowedMetaList.grantsTitle',
    defaultMessage: 'Grants',
  },
  discussionTitle: {
    id: 'FollowedMetaList.discussionTitle',
    defaultMessage: 'Discussion',
  },
  topicsTitle: {
    id: 'FollowedMetaList.topicsTitle',
    defaultMessage: 'Topics',
  },

  noFollowedTopics: {
    id: 'FollowedMetaList.noFollowedTopics',
    defaultMessage: 'You are not following any topics.',
  },

  noFollowedGrants: {
    id: 'FollowedMetaList.noFollowedGrants',
    defaultMessage: 'You are not following any grants.',
  },
  noFollowedDiscussions: {
    id: 'FollowedMetaList.noFollowedDiscussions',
    defaultMessage: 'You are not following any discussions.',
  },
  noFollowedCalendars: {
    id: 'FollowedMetaList.noFollowedCalendars',
    defaultMessage: 'You are not following any calendars.',
  },
});

const FollowedMetaListComponent = class extends React.PureComponent<
  IFollowedMetaListProps & IFollowedMetaListDispatch & InjectedIntlProps,
  IFollowedMetaListState
> {
  constructor(props: IFollowedMetaListProps & InjectedIntlProps) {
    super(props);
    this.state = { activeTab: 0 };
  }

  handleClick = (tab: number) => {
    this.setState({ activeTab: tab });
  };

  render() {
    const { intl, isOpen } = this.props;

    if (!isOpen) {
      return <div />;
    }

    const { activeTab } = this.state;
    return (
      <AuthConsumer>
        {auth => (
          <WrapperDiv>
            <ButtonWrapperDiv>
              <ButtonDiv
                onClick={() => this.handleClick(0)}
                active={activeTab === 0}
              >
                {intl.formatMessage(messages.topicsTitle)}
              </ButtonDiv>
              <ButtonDiv
                onClick={() => this.handleClick(1)}
                active={activeTab === 1}
              >
                {intl.formatMessage(messages.discussionTitle)}
              </ButtonDiv>
              {authFuncActionValue(auth) && (
                <ButtonDiv
                  onClick={() => this.handleClick(2)}
                  active={activeTab === 2}
                >
                  {intl.formatMessage(messages.grantsTitle)}
                </ButtonDiv>
              )}
              <ButtonDiv
                onClick={() => this.handleClick(3)}
                active={activeTab === 3}
              >
                {intl.formatMessage(commonMessages.yearWheel)}
              </ButtonDiv>
            </ButtonWrapperDiv>
            <ContentDiv style={{ padding: '1em' }}>
              {authFuncTopic(auth) && activeTab === 0 && (
                <Query
                  query={GET_TOPICS_FOR_MY_FOLLOWED}
                  fetchPolicy={'network-only'}
                >
                  {({ loading, error, data }) => {
                    if (loading) {
                      return <SuspenseLoader />;
                    }
                    if (error) {
                      throw error;
                    }
                    const FollowedTopics =
                      data && data.myFollowed && data.myFollowed.topics;

                    // console.log(sortedFollowedTopics);
                    if (FollowedTopics && FollowedTopics.length === 0) {
                      return (
                        <div>
                          <NotFollowedText>
                            {intl.formatMessage(messages.noFollowedTopics)}
                          </NotFollowedText>
                        </div>
                      );
                    }
                    return (
                      <div>
                        {FollowedTopics &&
                          FollowedTopics.map((value: Topic, i) => {
                            return (
                              <FollowedMetaListItem
                                key={value.id}
                                id={value.id}
                                uri={value.uri}
                                tooltip={intl.formatMessage(
                                  value.isFollowing
                                    ? commonMessages.unFollowHelpTopic
                                    : commonMessages.followHelpTopic
                                )}
                                type={MetaType.TOPIC}
                                title={value.title}
                                text={
                                  value.description ? value.description : ''
                                }
                                watched={value.isFollowing}
                                followType={MyType.TOPIC}
                                onClick={() => {
                                  myHistory.push('/topic/' + value.uri);
                                }}
                              />
                            );
                          })}
                      </div>
                    );
                  }}
                </Query>
              )}

              {authFuncDiscussion(auth) && activeTab === 1 && (
                <Query
                  query={GET_DISCUSSION_FOR_MY_FOLLOWED}
                  fetchPolicy={'network-only'}
                >
                  {({ loading, error, data }) => {
                    if (loading) {
                      return <SuspenseLoader />;
                    }
                    if (error) {
                      throw error;
                    }
                    const FollowedDiscussions =
                      data && data.myFollowed && data.myFollowed.discussions;

                    if (
                      FollowedDiscussions &&
                      FollowedDiscussions.length === 0
                    ) {
                      return (
                        <div>
                          <NotFollowedText>
                            {intl.formatMessage(messages.noFollowedDiscussions)}
                          </NotFollowedText>
                        </div>
                      );
                    }
                    return (
                      <div>
                        {FollowedDiscussions &&
                          FollowedDiscussions.map((value: Discussion, i) => {
                            return (
                              <FollowedMetaListItem
                                key={value.id}
                                id={value.id}
                                uri={value.uri}
                                type={MetaType.DISCUSSION}
                                title={value.title}
                                tooltip={intl.formatMessage(
                                  value.isFollowing
                                    ? commonMessages.unFollowHelpDiscussion
                                    : commonMessages.followHelpDiscussion
                                )}
                                text={
                                  value.currentRevision.body
                                    ? value.currentRevision.body
                                    : ''
                                }
                                watched={value.isFollowing}
                                followType={MyType.DISCUSSION}
                                onClick={() => {
                                  myHistory.push('/forum/public/' + value.uri);
                                }}
                              />
                            );
                          })}
                      </div>
                    );
                  }}
                </Query>
              )}

              {authFuncActionValue(auth) && activeTab === 2 && (
                <Query
                  query={GET_ACTION_VALUE__FOR_MY_FOLLOWED}
                  fetchPolicy={'network-only'}
                >
                  {({ loading, error, data }) => {
                    if (loading) {
                      return <SuspenseLoader />;
                    }
                    if (error) {
                      throw error;
                    }
                    const FollowedGrants =
                      data && data.myFollowed && data.myFollowed.actionValues;

                    if (FollowedGrants && FollowedGrants.length === 0) {
                      return (
                        <div>
                          <NotFollowedText>
                            {intl.formatMessage(messages.noFollowedGrants)}
                          </NotFollowedText>
                        </div>
                      );
                    }
                    return (
                      <div>
                        {FollowedGrants &&
                          FollowedGrants.map((value: ActionValue, i) => {
                            return (
                              <FollowedMetaListItem
                                tooltip={intl.formatMessage(
                                  value.isFollowing
                                    ? commonMessages.unFollowHelpGrant
                                    : commonMessages.followHelpGrant
                                )}
                                key={value.id}
                                id={value.id}
                                uri={value.uri}
                                type={MetaType.ACTION_VALUE}
                                title={value.title}
                                text={
                                  value.currentRevision.description
                                    ? value.currentRevision.description
                                    : ''
                                }
                                watched={value.isFollowing}
                                followType={MyType.ACTION_VALUE}
                                onClick={() => {
                                  myHistory.push('/action-value/' + value.uri);
                                }}
                              />
                            );
                          })}
                      </div>
                    );
                  }}
                </Query>
              )}

              {authFuncCalendar(auth) && activeTab === 3 && (
                <Query
                  query={GET_CALENDARS_FOR_MY_FOLLOWED}
                  fetchPolicy={'network-only'}
                >
                  {({ loading, error, data }) => {
                    if (loading) {
                      return <SuspenseLoader />;
                    }
                    if (error) {
                      throw error;
                    }
                    const followedYearWheels =
                      data && data.myFollowed && data.myFollowed.calendars;

                    if (followedYearWheels && followedYearWheels.length === 0) {
                      return (
                        <div>
                          <NotFollowedText>
                            {intl.formatMessage(messages.noFollowedCalendars)}
                          </NotFollowedText>
                        </div>
                      );
                    }

                    return (
                      <div>
                        {followedYearWheels &&
                          followedYearWheels.map((yearwheels: Calendar, i) => {
                            return (
                              <FollowedMetaListItem
                                tooltip={intl.formatMessage(
                                  yearwheels.isFollowing
                                    ? commonMessages.unfollowHelpCalendar
                                    : commonMessages.followHelpCalendar
                                )}
                                key={yearwheels.id}
                                id={yearwheels.id}
                                uri={yearwheels.uri}
                                type={MetaType.CALENDAR}
                                title={yearwheels.title}
                                text={
                                  yearwheels.description
                                    ? yearwheels.description
                                    : ''
                                }
                                watched={yearwheels.isFollowing}
                                followType={MyType.CALENDAR}
                                onClick={() => {
                                  myHistory.push('/calendar/' + yearwheels.uri);
                                }}
                              />
                            );
                          })}
                      </div>
                    );
                  }}
                </Query>
              )}
            </ContentDiv>
          </WrapperDiv>
        )}
      </AuthConsumer>
    );
  }
};

export const FollowedMetaList = injectIntl(FollowedMetaListComponent);
