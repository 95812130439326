import { MunikumIcons } from '../common/icons';
import * as React from 'react';
import { CSSProperties } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';

import styled from 'styled-components';
import { Option } from 'react-select';

import {
  AccessEnum,
  ContentStatus,
  ContentVisibilityEnum,
  RelationQueryQuery,
  RelationQueryVariables,
  Topic,
  TopicCategoryEnum,
} from '../../models/types';
import { commonLabels } from '../language/commonLabels';
import { Button, ButtonSize, TextField, ValidationMessage } from '../basic';
import { DropDownSelect } from '../basic/DropDownSelect/DropDownSelect';
import { commonMessages } from '../language/commonMessages';
import { metaMessages } from '../../services/models/contentMeta';
import {
  Colors,
  ColorTheme,
  getNamesAndValues,
  hideOutline,
  insetShadow,
  isReallyEmpty,
  MessageType,
  safeInvoke,
  transitionBorder,
} from '../common';
import { ITopicCreateOrUpdateForm } from '../../services/topicService';
import { KostraFunctionDropDown } from '../actionValue/KostraFunctionDropDown';
import {
  AnnualCycleAddMembersCard,
  SmallText,
} from '../annualCycle/AnnualCycleAddMembersCard';
import {
  MembersOfAnnualCycleCard,
  MembersOfAnnualCycleCardEveryoneComp,
  typeToList,
} from '../annualCycle/AnnualCycleMembers';
import {
  IStaticImageType,
  StaticImage,
} from '../basic/StaticImage/StaticImage';
import ToggleButton from '../basic/ToggleButton/ToggleButton';
import * as _ from 'lodash';
import { Tooltip } from '../basic/Tooltip/Tooltip';
import { ExternalLink } from '../basic/Structural/links';
import { MuTextField } from '../basic/MuEditor/MuTextField';
import { convertFromRaw } from 'draft-js';
import {
  MathiasDropDown,
  tagsPositionEnum,
} from '../basic/DropDownSelect/MathiasDropDown';
import { myApolloClient } from '../../graphql/apolloClientFactory';
import { SEARCH_QUERY_RELATION } from '../common/newRelationComp';
import { FormLabel } from '../basic/FormLabel/FormLabel';
import { Icons } from '../annualCycle/CreateAnnualCycleForm';
import { H4 } from '../basic/Structural/typography';

interface ITopicFormProps {
  onSave?: (
    form: ITopicCreateOrUpdateForm,
    relations: Array<Option>,
    relationsToRemove: Array<Option>
  ) => void;
  onCancel?: () => void;
  initialData?: ITopicCreateOrUpdateForm;
  errorMessage?: string;
  disabled?: boolean;
  style?: CSSProperties;
  isTopicEdit: boolean;
  relations?: Array<Topic>;
  onDelete?: () => void;
  myFollowedTags: any;
}

const ButtsCont = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;
const RightContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;

  padding: 1.875em;
`;
const LeftContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  max-width: 700px;
  padding: 1.875em;
`;
const Container = styled.div`
  display: block;
  background-color: ${props => props.theme.contentBackgroundColor};
  @media (min-width: ${1200}px) {
    display: flex;
  }
`;
export const DisabledTextField = styled.textarea`
  resize: none;

  box-sizing: border-box;

  //min-width: 15em;
  //max-width: 100%;
  width: 100%;
  border-radius: 0.25em;
  background-color: ${props => props.theme.contentBackgroundColor};
  font-size: 14px;
  line-height: 1.1875em;

  height: 100%;

  font-weight: 500;
  padding: 0.5em;

  // 1,3125
  // 1,3125 + .75 = 2,0625

  font-family: Lato, sans-serif;

  color: ${Colors.BLACK};

  &::placeholder {
    color: ${Colors.GREYPLACEHOLDER};
  }
  &:focus:enabled {
    // TODO: what todo when focus + error/success? let focus-border override?
    border-color: ${Colors.BLACK};
  }
  &:disabled {
    border: 1px solid ${Colors.GREY};
    color: ${Colors.GREYDISABLEDTEXT};
    background-color: ${Colors.GREY};
  }

  ${insetShadow()};
  ${hideOutline()};
  ${transitionBorder()};
`;

export const FormErrorMessage = styled.div`
  color: #ff5c39;
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  font-size: 14px;
  font-family: Lato, sans-serif;
`;

interface ITopicFormState {
  relations: Array<any>;
  isLoading: boolean;
  relationsToRemove: Array<Option>;
  isEditTagOpen: boolean;
}

class TopicFormComp extends React.PureComponent<
  InjectedIntlProps & ITopicFormProps,
  ITopicFormState
> {
  statusList: { [id: string]: string };
  categories: { [id: string]: string };

  constructor(props: InjectedIntlProps & ITopicFormProps) {
    super(props);
    this.state = {
      isLoading: false,
      relations: [],
      relationsToRemove: [],
      isEditTagOpen: false,
    };

    // if (props.initialData === undefined) {
    //   this.state = { title: '', body: '', description: '', status: undefined };
    // } else {
    //   this.state = {
    //     title: props.initialData.meta.title,
    //     description: props.initialData.meta.description,
    //     body: props.initialData.content.body,
    //     status: props.initialData.meta.status,
    //   };
    // }

    const contentStatuses: { [id: string]: string } = {};
    getNamesAndValues(ContentStatus).forEach(x => {
      contentStatuses[x.value] = props.intl.formatMessage(metaMessages[x.name]);
    });

    this.statusList = contentStatuses;

    let categoryKeys = Object.keys(TopicCategoryEnum);
    let categories: { [id: string]: string } = {};

    for (let i = 0; i < categoryKeys.length; i++) {
      categories[categoryKeys[i]] = categoryKeys[i];
    }
    delete contentStatuses.TO_APPROVAL;
    delete contentStatuses.OBSOLETE;

    this.categories = categories;
  }

  // handleStatusChanged = (id: string) => {
  //   this.setState({ status: ContentStatus[id] });
  // };
  //
  // handleChangeTitle = (e: any) => {
  //   this.setState({ title: e.target.value });
  //   console.log('setting state title to' + '' + e.target.value);
  // };
  //
  // handleChangeDescription = (e: any) => {
  //   this.setState({ description: e.target.value });
  //   console.log('setting state descritption to' + '' + e.target.value);
  // };
  //
  // handleChangeBody = (e: any) => {
  //   this.setState({ body: e.target.value });
  //   console.log('setting state body to' + '' + e.target.value);
  // };
  checkIfArrayHasReadMembers = formik => {
    if (formik.access.persons) {
      for (let i = 0; i < formik.access.persons.length; i++) {
        if (formik.access.persons[i].access === AccessEnum.READ) {
          return true;
        }
      }
    }
    if (formik.access.groups) {
      for (let i = 0; i < formik.access.groups.length; i++) {
        if (formik.access.groups[i].access === AccessEnum.READ) {
          return true;
        }
      }
    }

    if (formik.access.organizationGroups) {
      for (let i = 0; i < formik.access.organizationGroups.length; i++) {
        if (formik.access.organizationGroups[i].access === AccessEnum.READ) {
          return true;
        }
      }
    }

    if (formik.access.organizations) {
      for (let i = 0; i < formik.access.organizations.length; i++) {
        if (formik.access.organizations[i].access === AccessEnum.READ) {
          return true;
        }
      }
    }
  };
  deleteFromList = obj => {
    for (let i = 0; i < obj.length; i++) {
      if (obj[i].access === AccessEnum.READ) {
        obj.splice(i, 1);
        i--;
      }
    }
  };

  handleCancel = () => {
    safeInvoke(this.props.onCancel);
  };

  handleClick = () => {
    // alert('Topic saved');
  };

  // handleSave = () => {
  //   safeInvoke(this.props.onSave);
  //   console.log(this.state.title);
  // };

  render() {
    const { intl, initialData } = this.props;
    const categoryList = {
      TOPIC: 'Tema',
      SUBJECT_GROUP: 'Organisasjon',
      PROJECT: 'Prosjekt',
    };

    return (
      <Formik
        initialValues={initialData}
        validateOnBlur={true}
        onSubmit={(values: ITopicCreateOrUpdateForm) => {
          this.setState({
            isLoading: true,
          });
          safeInvoke(
            this.props.onSave,
            values,
            this.state.relations,
            this.state.relationsToRemove
          );
        }}
        validationSchema={yup.object().shape({
          title: yup
            .string()
            .required()
            .label(intl.formatMessage(commonLabels.title)),
          description: yup
            .string()
            .required()
            .label(intl.formatMessage(commonLabels.description)),
          category: yup
            .string()
            .required()
            .label(intl.formatMessage(commonLabels.category)),
        })}
        render={(formikProps: FormikProps<ITopicCreateOrUpdateForm>) => {
          return (
            <form
              onSubmit={formikProps.handleSubmit}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Container>
                <LeftContainer>
                  {this.props.disabled && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '.5em',
                        marginTop: '-1em',
                      }}
                    >
                      <MunikumIcons.warning
                        style={{
                          color: Colors.RED,
                          marginRight: '.2em',
                        }}
                      />
                      <SmallText
                        style={{
                          fontSize: '12px',
                          color: Colors.YELLOW,
                          marginLeft: '0em',
                        }}
                      >
                        {intl.formatMessage(commonMessages.noWriteAccessTopic)}
                      </SmallText>
                    </div>
                  )}
                  <TextField
                    label={intl.formatMessage(commonLabels.title)}
                    name={'title'}
                    value={formikProps.values.title}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    disabled={this.props.disabled}
                    style={{ width: '385px' }}
                    placeholder={intl.formatMessage(commonLabels.title)}
                    error={
                      formikProps.touched.title && formikProps.errors.title
                    }
                  />
                  <MuTextField
                    shouldEditorHaveFiles={false}
                    style={{ width: '385px', marginBottom: '.5em' }}
                    doIHaveAccessToAddFiles={true}
                    label={this.props.intl.formatMessage(commonLabels.text)}
                    placeholder={this.props.intl.formatMessage(
                      commonLabels.descriptionPlaceholder
                    )}
                    files={[]}
                    onSubmit={(value, files) => {
                      if (
                        !isReallyEmpty(
                          convertFromRaw(JSON.parse(value)).getPlainText()
                        )
                      ) {
                        formikProps.setFieldValue('description', value);
                        formikProps.setFieldValue(
                          'descriptionText',
                          convertFromRaw(JSON.parse(value)).getPlainText()
                        );
                      } else {
                        formikProps.setFieldValue('description', '');
                        formikProps.setFieldValue('descriptionText', '');
                      }
                    }}
                    value={formikProps.values.description}
                  />
                  {formikProps.touched.description &&
                    formikProps.errors.description && (
                      <FormErrorMessage>
                        <MunikumIcons.Cancel style={{ marginRight: '.25em' }} />
                        {this.props.intl.formatMessage(
                          commonMessages.descriptionRequired
                        )}
                      </FormErrorMessage>
                    )}
                  <Tooltip
                    content={intl.formatMessage(
                      commonMessages.createRelationTooltip
                    )}
                  >
                    <FormLabel>
                      {intl.formatMessage(commonMessages.createRelation)}
                    </FormLabel>
                  </Tooltip>
                  <MathiasDropDown
                    style={{
                      width: '100%',
                      maxWidth: '378px',
                      marginBottom: '.5em',
                    }}
                    tagsPosition={tagsPositionEnum.INSIDE}
                    selectedItems={
                      this.props.relations
                        ? this.props.relations.map(relation => {
                            return {
                              label: relation.title,
                              value: relation.id,
                            };
                          })
                        : []
                    }
                    onTagRemoved={item => {
                      this.state.relationsToRemove.push(item);
                    }}
                    onChange={(value: Array<Option>) => {
                      this.setState(
                        {
                          relations: [],
                        },
                        () => {
                          let temp = [];
                          value.forEach(t => {
                            temp.push(+t.value);
                          });
                          this.setState({
                            relations: temp,
                          });
                        }
                      );
                    }}
                    loadItems={(
                      input: string,
                      preloadedItems: Array<Option<string>>
                    ): Promise<Array<Option<string>>> => {
                      return new Promise(async (resolve, reject) => {
                        const items: Array<Option<string>> = [];
                        try {
                          const res = await myApolloClient.query<
                            RelationQueryQuery,
                            RelationQueryVariables
                          >({
                            query: SEARCH_QUERY_RELATION,
                            variables: {
                              query: input,
                              onlyWrite: true,
                            },
                            fetchPolicy: 'network-only',
                          });

                          res.data.search.topics.forEach((topic, index) => {
                            const temp: Option<string> = {
                              label: topic.title,
                              value: topic.id,
                            };
                            items.push(temp);
                          });
                          // res.data.search.calendars.forEach(
                          //   (calendar, index) => {
                          //     const temp: Option<string> = {
                          //       label: calendar.title,
                          //       value: calendar.id,
                          //     };
                          //     items.push(temp);
                          //   }
                          // );
                          resolve(items);
                        } catch (e) {
                          console.error('Fail', e);
                          reject(e);
                        }
                      });
                    }}
                  />

                  <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <DropDownSelect
                      disabled={this.props.disabled}
                      showEmptyOption={false}
                      style={{ height: '36px' }}
                      fieldGroupStyle={{ width: '190px', marginRight: '8px' }}
                      name={'category'}
                      label={intl.formatMessage(commonLabels.category)}
                      items={categoryList}
                      value={formikProps.values.category}
                      onChange={formikProps.handleChange}
                      error={
                        formikProps.errors.category &&
                        formikProps.touched.category
                      }
                    />
                    <KostraFunctionDropDown
                      disabled={this.props.disabled}
                      fieldGroupStyle={{ width: '190px' }}
                      style={{
                        // width for dropdownmenu
                        width: '300px',
                      }}
                      selectedItems={formikProps.values.kostraFunctions}
                      onSelectedItemsChanged={selectedItems =>
                        formikProps.setFieldValue(
                          'kostraFunctions',
                          selectedItems
                        )
                      }
                    />
                    {/*<div>*/}
                    {/*  <SmallTitle style={{ paddingBottom: '.3em' }}>*/}
                    {/*    {'Merkelapp'}*/}
                    {/*  </SmallTitle>*/}
                    {/*  <TagsDropdown*/}
                    {/*    fieldGroupStyle={{ width: '100%' }}*/}
                    {/*    style={{ maxWidth: '300px' }}*/}
                    {/*    myTags={[]}*/}
                    {/*    onSelectedItemsChanged={selectedItems => {*/}
                    {/*      formikProps.setFieldValue('tags', selectedItems);*/}
                    {/*    }}*/}
                    {/*    followedTags={*/}
                    {/*      this.props.myFollowedTags?.map(c => {*/}
                    {/*        return {*/}
                    {/*          id: c.title,*/}
                    {/*          title: c.title,*/}
                    {/*          color: c?.color ?? Colors.GREY,*/}
                    {/*        };*/}
                    {/*      }) ?? []*/}
                    {/*    }*/}
                    {/*    selectedItems={formikProps.values.tags.map(c => {*/}
                    {/*      const id = uuidv1();*/}
                    {/*      return {*/}
                    {/*        title: c.title,*/}
                    {/*        color: c?.color ?? Colors.GREY,*/}
                    {/*        id: id,*/}
                    {/*      };*/}
                    {/*    })}*/}
                    {/*  />*/}
                    {/*</div>*/}
                  </div>
                  {/*<DropDownSelect*/}
                  {/*disabled={this.props.disabled}*/}
                  {/*fieldGroupStyle={{ width: '385px' }}*/}
                  {/*name={'status'}*/}
                  {/*label={'Status'}*/}
                  {/*items={this.statusList}*/}
                  {/*value={(formikProps.values.status || 0).toString()}*/}
                  {/*onChange={formikProps.handleChange}*/}
                  {/*onBlur={formikProps.handleBlur}*/}
                  {/*error={formikProps.errors.status}*/}
                  {/*/>*/}

                  {this.props.errorMessage && (
                    <ValidationMessage
                      type={MessageType.ERROR}
                      msg={'Update failed' + this.props.errorMessage}
                    />
                  )}
                </LeftContainer>
                <RightContainer>
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom:
                          formikProps.values.access.isPrivate === true
                            ? '0em'
                            : '1.5em',
                      }}
                    >
                      <H4
                        style={{
                          fontWeight: 'bold',
                          marginRight: '1em',
                        }}
                      >
                        {intl.formatMessage(commonMessages.Access)}
                      </H4>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Icons isChecked={!formikProps.values.access.isPrivate}>
                          <MunikumIcons.Globe
                            style={{
                              height: '1.2em',
                              width: '1.2em',
                            }}
                          />
                        </Icons>
                        <Tooltip
                          position={'top-start'}
                          content={
                            formikProps.values.access.isPrivate
                              ? intl.formatMessage(
                                  commonMessages.makeYearWheelPublic
                                )
                              : intl.formatMessage(
                                  commonMessages.makeYearWheelPrivate
                                )
                          }
                        >
                          <div>
                            <ToggleButton
                              style={{
                                marginLeft: '.5em',
                                marginRight: '.3em',
                                marginTop: '.5em',
                              }}
                              checked={formikProps.values.access.isPrivate}
                              onClick={() => {
                                if (!this.props.disabled) {
                                  const hasReadAccess = this.checkIfArrayHasReadMembers(
                                    formikProps.values
                                  );
                                  if (formikProps.values.access.isPrivate) {
                                    if (hasReadAccess) {
                                      if (
                                        window.confirm(
                                          intl.formatMessage(
                                            commonMessages.MakeYearWheelPublicWarning
                                          )
                                        )
                                      ) {
                                        formikProps.setFieldValue(
                                          'access.isPrivate',
                                          !formikProps.values.access.isPrivate
                                        );
                                        const personCopy = _.cloneDeep(
                                          formikProps.values.access.persons
                                        );
                                        const personGroupCopy = _.cloneDeep(
                                          formikProps.values.access.groups
                                        );
                                        const orgCopy = _.cloneDeep(
                                          formikProps.values.access
                                            .organizations
                                        );
                                        const orgGroupCopy = _.cloneDeep(
                                          formikProps.values.access
                                            .organizationGroups
                                        );
                                        this.deleteFromList(personCopy);
                                        this.deleteFromList(personGroupCopy);
                                        this.deleteFromList(orgCopy);
                                        this.deleteFromList(orgGroupCopy);
                                        formikProps.setFieldValue(
                                          'access.persons',
                                          personCopy
                                        );
                                        formikProps.setFieldValue(
                                          'access.groups',
                                          personGroupCopy
                                        );
                                        formikProps.setFieldValue(
                                          'access.organizations',
                                          orgCopy
                                        );
                                        formikProps.setFieldValue(
                                          'access.organizationGroups',
                                          orgGroupCopy
                                        );
                                      }
                                    } else {
                                      formikProps.setFieldValue(
                                        'access.isPrivate',
                                        !formikProps.values.access.isPrivate
                                      );
                                    }
                                  } else if (
                                    !formikProps.values.access.isPrivate
                                  ) {
                                    formikProps.setFieldValue(
                                      'access.isPrivate',
                                      !formikProps.values.access.isPrivate
                                    );
                                  }
                                }
                              }}
                            />
                          </div>
                        </Tooltip>
                        <Icons isChecked={formikProps.values.access.isPrivate}>
                          <MunikumIcons.Padlock
                            style={{
                              height: '1.2em',
                              width: '1.2em',
                            }}
                          />
                        </Icons>
                      </div>
                    </div>
                    {formikProps.values.access.isPrivate && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          marginBottom:
                            formikProps.values.access.isPrivate === true
                              ? '.5em'
                              : '0',
                        }}
                      >
                        <Icons
                          isChecked={
                            formikProps.values.access.visibility !==
                            ContentVisibilityEnum.PRIVATE
                          }
                        >
                          <MunikumIcons.eye
                            style={{
                              height: '1.2em',
                              width: '1.2em',
                            }}
                          />
                        </Icons>
                        <Tooltip
                          position={'top-start'}
                          content={
                            formikProps.values.access.visibility ===
                            ContentVisibilityEnum.PRIVATE
                              ? intl.formatMessage(
                                  commonMessages.MakeYearWheelVisible
                                )
                              : intl.formatMessage(
                                  commonMessages.makeYearWheelNotVisible
                                )
                          }
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <ToggleButton
                              onClick={() => {
                                if (!this.props.disabled) {
                                  formikProps.values.access.visibility ===
                                  ContentVisibilityEnum.PRIVATE
                                    ? formikProps.setFieldValue(
                                        'access.visibility',
                                        ContentVisibilityEnum.PUBLIC
                                      )
                                    : formikProps.setFieldValue(
                                        'access.visibility',
                                        ContentVisibilityEnum.PRIVATE
                                      );
                                }
                              }}
                              style={{
                                marginLeft: '.5em',
                                marginRight: '.3em',
                                marginTop: '.5em',
                              }}
                              checked={
                                formikProps.values.access.visibility ===
                                ContentVisibilityEnum.PRIVATE
                              }
                              name={'da'}
                            />
                          </div>
                        </Tooltip>
                        <Icons
                          isChecked={
                            formikProps.values.access.visibility ===
                            ContentVisibilityEnum.PRIVATE
                          }
                        >
                          <MunikumIcons.eyeSlash
                            style={{
                              height: '1.2em',
                              width: '1.2em',
                            }}
                          />
                        </Icons>
                      </div>
                    )}
                    <div style={{ marginBottom: '.5em' }}>
                      <ExternalLink
                        style={{
                          fontSize: '12px',
                        }}
                        href={
                          'https://support.munikum.no/hc/no/articles/360027728352-Gi-lese-og-skrivetilgang-til-%C3%A5rshjulet'
                        }
                      >
                        {intl.formatMessage(
                          commonMessages.doYouNotFindThePerson
                        )}
                      </ExternalLink>
                    </div>
                    <div style={{ marginBottom: '1em' }}>
                      <AnnualCycleAddMembersCard
                        disabled={this.props.disabled}
                        isPrivate={formikProps.values.access.isPrivate}
                        style={{ marginBottom: '1em', maxWidth: '460px' }}
                        showOnlyPerson={false}
                        addGroup={(group, permission) => {
                          let isAlreadyInArray = false;
                          for (
                            let i = 0;
                            i < formikProps.values.access.groups.length;
                            i++
                          ) {
                            if (
                              formikProps.values.access.groups[i].group.id ===
                              group.id
                            ) {
                              isAlreadyInArray = true;
                              break;
                            }
                          }
                          if (isAlreadyInArray) {
                            return '';
                          } else {
                            const toSend = formikProps.values.access.groups.slice();
                            toSend.push({
                              access: permission,
                              group: group,
                            });
                            formikProps.setFieldValue('access.groups', toSend);
                          }
                        }}
                        addOrg={(org, permission) => {
                          let isAlreadyInArray = false;
                          for (
                            let i = 0;
                            i < formikProps.values.access.organizations.length;
                            i++
                          ) {
                            if (
                              formikProps.values.access.organizations[i]
                                .organization.id === org.id
                            ) {
                              isAlreadyInArray = true;
                              break;
                            }
                          }
                          if (isAlreadyInArray) {
                            return '';
                          } else {
                            const toSend = formikProps.values.access.organizations.slice();

                            toSend.push({
                              access: permission,
                              organization: org,
                            });
                            formikProps.setFieldValue(
                              'access.organizations',
                              toSend
                            );
                          }
                        }}
                        addOrgGroup={(orgGroup, permission) => {
                          let isAlreadyInArray = false;
                          if (formikProps.values.access.organizationGroups) {
                            for (
                              let i = 0;
                              i <
                              formikProps.values.access.organizationGroups
                                .length;
                              i++
                            ) {
                              if (
                                formikProps.values.access.organizationGroups[i]
                                  .organizationGroup.id === orgGroup.id
                              ) {
                                isAlreadyInArray = true;
                                break;
                              }
                            }
                          }
                          if (isAlreadyInArray) {
                            return '';
                          } else {
                            if (formikProps.values.access.organizationGroups) {
                              const toSend = formikProps.values.access.organizationGroups.slice();
                              toSend.push({
                                access: permission,
                                organizationGroup: orgGroup,
                              });

                              formikProps.setFieldValue(
                                'access.organizationGroups',
                                toSend
                              );
                            }
                          }
                        }}
                        showTag={false}
                        canChangePermission={
                          formikProps.values.access.isPrivate
                        }
                        isPermissionActive={true}
                        title={intl.formatMessage(commonMessages.to)}
                        addMember={(person, permission: AccessEnum) => {
                          let isAlreadyInArray = false;
                          for (
                            let i = 0;
                            i < formikProps.values.access.persons.length;
                            i++
                          ) {
                            if (
                              formikProps.values.access.persons[i].person.id ===
                              person.id
                            ) {
                              isAlreadyInArray = true;
                              break;
                            }
                          }
                          if (isAlreadyInArray) {
                            return '';
                          } else {
                            // if (person.id !== me.id) {

                            const toSend = formikProps.values.access.persons.slice();
                            toSend.push({
                              access: permission,
                              person: person,
                            });

                            formikProps.setFieldValue('access.persons', toSend);
                            // }
                          }
                        }}
                      />

                      <MembersOfAnnualCycleCard
                        type={typeToList.PERSONS}
                        disabled={this.props.disabled}
                        canChangePermission={false}
                        canRemove={false}
                        key={formikProps.values.createdBy.id}
                        profilePic={formikProps.values.createdBy.picture}
                        name={formikProps.values.createdBy.name}
                        organization={
                          formikProps.values.createdBy.organization &&
                          formikProps.values.createdBy.organization.name
                        }
                        department={formikProps.values.createdBy.department}
                        role={formikProps.values.createdBy.role}
                        handleRemoveMember={() => ''}
                        img={
                          <StaticImage
                            width={21}
                            height={21}
                            type={IStaticImageType.ORGANIZATION}
                            filename={
                              (formikProps.values.createdBy.organization &&
                                formikProps.values.createdBy.organization
                                  .organizationImage) ||
                              ''
                            }
                          />
                        }
                        permissionLevel={{
                          value: 'OWNER',
                          name: intl.formatMessage(commonMessages.owner),
                        }}
                      />
                      {formikProps.values.access &&
                        formikProps.values.access.isPrivate && (
                          <DisabledTextField
                            style={{ width: '460px' }}
                            name={'t'}
                            disabled={true}
                            value={intl.formatMessage(
                              commonMessages.infoBoxGroup
                            )}
                          />
                        )}
                      <div>
                        {formikProps.values.access.persons &&
                          formikProps.values.access.persons.map(
                            (person: any, i) => {
                              return (
                                <div key={'bla' + i}>
                                  <MembersOfAnnualCycleCard
                                    disabled={this.props.disabled}
                                    type={typeToList.PERSONS}
                                    canChangePermission={
                                      formikProps.values.access.isPrivate
                                    }
                                    canRemove={true}
                                    onChangeSelected={selectedKey => {
                                      // const personGroupCopyState = this.state.currentPersons.slice();
                                      const personGroupCopy =
                                        formikProps.values.access.persons;
                                      const getEnum =
                                        selectedKey === 'WRITE'
                                          ? AccessEnum.WRITE
                                          : AccessEnum.READ;
                                      // personGroupCopyState[
                                      //   i
                                      //   ].access = getEnum;
                                      personGroupCopy[i].access = getEnum;
                                      formikProps.setFieldValue(
                                        'access.persons',
                                        personGroupCopy
                                      );
                                    }}
                                    key={i}
                                    profilePic={person.picture}
                                    name={person.person.name}
                                    organization={
                                      person.person.organization &&
                                      person.person.organization.name
                                    }
                                    department={person.person.department}
                                    role={person.person.role}
                                    handleRemoveMember={() => {
                                      const personGroupCopyFormik = formikProps.values.access.persons.slice();
                                      personGroupCopyFormik.splice(i, 1);

                                      formikProps.setFieldValue(
                                        'access.persons',
                                        personGroupCopyFormik
                                      );
                                    }}
                                    img={
                                      <StaticImage
                                        width={21}
                                        height={21}
                                        type={IStaticImageType.ORGANIZATION}
                                        filename={
                                          (person.person.organization &&
                                            person.person.organization
                                              .organizationImage) ||
                                          ''
                                        }
                                      />
                                    }
                                    permissionLevel={{
                                      value: 'WRITE',
                                      name:
                                        person.access === 'WRITE'
                                          ? 'skrivetilgang'
                                          : 'Lesetilgang',
                                    }}
                                  />
                                </div>
                              );
                            }
                          )}
                      </div>
                      {/*Groups*/}
                      {formikProps.values.access.groups &&
                        formikProps.values.access.groups.map(
                          (group: any, i) => {
                            return (
                              <div key={'bla' + i}>
                                <MembersOfAnnualCycleCard
                                  disabled={this.props.disabled}
                                  type={typeToList.PERSONGROUPS}
                                  canChangePermission={
                                    formikProps.values.access.isPrivate
                                  }
                                  canRemove={true}
                                  onChangeSelected={selectedKey => {
                                    const groupCopy =
                                      formikProps.values.access.groups;
                                    const getEnum =
                                      selectedKey === 'WRITE'
                                        ? AccessEnum.WRITE
                                        : AccessEnum.READ;
                                    groupCopy[i].access = getEnum;

                                    formikProps.setFieldValue(
                                      'access.groups',
                                      groupCopy
                                    );
                                  }}
                                  key={i}
                                  name={group.group.title}
                                  handleRemoveMember={() => {
                                    const groupCopyFormik = formikProps.values.access.groups.slice();
                                    groupCopyFormik.splice(i, 1);

                                    formikProps.setFieldValue(
                                      'access.groups',
                                      groupCopyFormik
                                    );
                                  }}
                                  img={
                                    <StaticImage
                                      width={21}
                                      height={21}
                                      type={IStaticImageType.ORGANIZATION}
                                    />
                                  }
                                  permissionLevel={{
                                    value: 'WRITE',
                                    name:
                                      group.access === 'WRITE'
                                        ? 'skrivetilgang'
                                        : 'Lesetilgang',
                                  }}
                                />
                              </div>
                            );
                          }
                        )}
                      {/*organizations*/}
                      {formikProps.values.access.organizations &&
                        formikProps.values.access.organizations.map(
                          (org: any, index) => {
                            return (
                              <div key={'bla' + index}>
                                <MembersOfAnnualCycleCard
                                  disabled={this.props.disabled}
                                  type={typeToList.ORGANIZATIONS}
                                  canChangePermission={
                                    formikProps.values.access.isPrivate
                                  }
                                  canRemove={true}
                                  onChangeSelected={selectedKey => {
                                    const orgCopy =
                                      formikProps.values.access.organizations;
                                    const getEnum =
                                      selectedKey === 'WRITE'
                                        ? AccessEnum.WRITE
                                        : AccessEnum.READ;

                                    orgCopy[index].access = getEnum;

                                    formikProps.setFieldValue(
                                      'access.organizations',
                                      orgCopy
                                    );
                                  }}
                                  key={index}
                                  name={org.organization.name}
                                  handleRemoveMember={() => {
                                    const orgCopyFormik = formikProps.values.access.organizations.slice();

                                    orgCopyFormik.splice(index, 1);

                                    formikProps.setFieldValue(
                                      'access.organizations',
                                      orgCopyFormik
                                    );
                                  }}
                                  img={
                                    <StaticImage
                                      width={21}
                                      height={21}
                                      type={IStaticImageType.ORGANIZATION}
                                      filename={
                                        (org.organization &&
                                          org.organization.organizationImage) ||
                                        ''
                                      }
                                    />
                                  }
                                  permissionLevel={{
                                    value: 'WRITE',
                                    name:
                                      org.access === 'WRITE'
                                        ? 'skrivetilgang'
                                        : 'Lesetilgang',
                                  }}
                                />
                              </div>
                            );
                          }
                        )}
                      {/*OrganizationGroups*/}
                      {formikProps.values.access.organizationGroups &&
                        formikProps.values.access.organizationGroups.map(
                          (orgGroup: any, indexPosition) => {
                            return (
                              <div key={'bla' + indexPosition}>
                                <MembersOfAnnualCycleCard
                                  disabled={this.props.disabled}
                                  type={typeToList.ORGANIZATIONGROUPS}
                                  canChangePermission={
                                    formikProps.values.access.isPrivate
                                  }
                                  canRemove={true}
                                  key={indexPosition}
                                  name={orgGroup.organizationGroup.title}
                                  handleRemoveMember={() => {
                                    const orgGroupCopyFormik = formikProps.values.access.organizationGroups.slice();
                                    orgGroupCopyFormik.splice(indexPosition, 1);
                                    formikProps.setFieldValue(
                                      'access.organizationGroups',
                                      orgGroupCopyFormik
                                    );
                                  }}
                                  onChangeSelected={selectedKey => {
                                    const orgGroupCopy =
                                      formikProps.values.access
                                        .organizationGroups;
                                    const getEnum =
                                      selectedKey === 'WRITE'
                                        ? AccessEnum.WRITE
                                        : AccessEnum.READ;
                                    orgGroupCopy[
                                      indexPosition
                                    ].access = getEnum;
                                    formikProps.setFieldValue(
                                      'access.organizationGroups',
                                      orgGroupCopy
                                    );
                                  }}
                                  img={
                                    <StaticImage
                                      width={21}
                                      height={21}
                                      type={IStaticImageType.ORGANIZATION}
                                    />
                                  }
                                  permissionLevel={{
                                    value: 'WRITE',
                                    name:
                                      orgGroup.access === 'WRITE'
                                        ? 'skrivetilgang'
                                        : 'Lesetilgang',
                                  }}
                                />
                              </div>
                            );
                          }
                        )}
                      {!formikProps.values.access.isPrivate && (
                        <MembersOfAnnualCycleCardEveryoneComp
                          // style={{marginTop: '-1.2em'}}
                          onCancelClick={() => {
                            formikProps.setFieldValue(
                              'access.isPrivate',
                              !formikProps.values.access.isPrivate
                            );
                          }}
                        />
                      )}
                    </div>
                  </div>

                  <ButtsCont>
                    {this.props.isTopicEdit && (
                      <Button
                        text={intl.formatMessage(commonMessages.delete)}
                        size={ButtonSize.Medium}
                        theme={ColorTheme.Yellow}
                        onClick={() => {
                          safeInvoke(this.props.onDelete);
                        }}
                        leftIcon={MunikumIcons.Cancel}
                        style={{ marginRight: '8px' }}
                      />
                    )}
                    <Button
                      text={intl.formatMessage(commonMessages.cancel)}
                      size={ButtonSize.Medium}
                      theme={ColorTheme.White}
                      onClick={() => this.handleCancel()}
                      leftIcon={MunikumIcons.Cancel}
                    />
                    <Button
                      text={intl.formatMessage(commonMessages.save)}
                      size={ButtonSize.Medium}
                      isLoading={this.state.isLoading}
                      innerButtonStyle={{ justifyContent: 'center' }}
                      theme={ColorTheme.Red}
                      type={'submit'}
                      disabled={this.props.disabled}
                      leftIcon={MunikumIcons.Save}
                      onClick={this.handleClick}
                      style={{ marginLeft: '.5em' }}
                    />
                  </ButtsCont>
                </RightContainer>
              </Container>
            </form>
          );
        }}
      />
    );
  }
}

export const TopicForm = injectIntl(TopicFormComp);

TopicForm.defaultProps = {
  disabled: false,
};
