import * as React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { MuPreview } from '../basic/MuEditor/MuPreview';
import { IThemeProps, safeInvokeDeprecated } from '../common';
import { INotification } from '../../services/models/notification';

const ItemDate = styled.div`
  color: ${props => props.theme.textColor};
  font-size: 0.75em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 0 0 auto;
  align-self: flex-end;
`;

const ItemContainer = styled.div`
  margin-top: 0.5em;
  padding-bottom: 0.5em;

  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;

  ${(props: { read: boolean; theme: IThemeProps }) =>
    props.read
      ? props.theme.tertiaryContentBackgroundColor
      : props.theme.secondaryContentBackgroundColor};
`;

const BorderBox = styled.div`
  border-bottom: 1px solid ${props => props.theme.accent1.borderColor};
  height: 0.5em;
`;

const ItemBody = styled.div`
  font-size: 0.875em; // 14px
  line-height: 1.375em; // 22px
  color: ${props => props.theme.textColor};
  box-sizing: border-box;
  margin-right: 0.5em;
  flex: 1 1 auto;
  align-self: flex-start;

  padding-left: 0.25em;
`;

export interface INotificationListItem {
  item: INotification;
  onClick?: () => void;
  time: number;
}

export class NotificationListItem extends React.PureComponent<
  INotificationListItem,
  {}
> {
  render() {
    const { item, onClick } = this.props;

    if (!onClick) {
      return <div />;
    }

    return (
      <>
        <ItemContainer
          read={item.beenRead}
          onClick={e => safeInvokeDeprecated(this.props.onClick)}
        >
          <ItemBody>
            <MuPreview rawMarkdown={item.message} />
          </ItemBody>
          <ItemDate>
            {moment
              .utc(item.dateCreated)
              .local()
              .fromNow()}
          </ItemDate>
        </ItemContainer>
        <BorderBox />
      </>
    );
  }
}
