import { isType } from 'typescript-fsa';
import moment, { Moment } from 'moment';
import { Action } from 'redux';
import { call, put, race, select, take } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import * as LocalStorageService from '../../services/localStorageService';
import { forceClearApolloPersistedCache } from '../../services/localStorageService';
import * as personService from '../../services/personService';
import { createSelector } from 'reselect';
import {
  ICheckEmailResponse,
  login,
  refreshAccessToken,
  requestEmailPinLogin,
  signInWithMicrosoft,
  verifyNewUserPin,
  verifyPin,
} from '../../services/authService';
import { myHistory, teamsContext } from '../../index';
import { myApolloClient } from '../../graphql/apolloClientFactory';
import { getNotificationsInfoActionCreator } from '../notification/notification';
import { IAuthResult, LoginType } from './IAuthResult';
import { IPersonSimple } from '../../services/models/discussion';
import { IPerson, UserStatus } from '../../services/models/person';
import { updateMeActionCreator } from '../person/person';
import { showWelcomeUser } from '../onboarding/onboarding';
import { wrapApiError } from '../../services/api';
import { actionCreatorFactory } from '../actionCreatorFactory';
import { RootState } from '../rootReducer';
import { UserAuthInfo } from '../../auth';
import { getQueryStringParams } from '../../util/getQueryStringParams';
import { ColorTheme } from '../../components/common';
import * as Sentry from '@sentry/react';
import { Scope } from '@sentry/react';
import * as microsoftTeams from '@microsoft/teams-js';

const actionCreator = actionCreatorFactory('AUTH'); // AUTH = prefix

export const checkEmail = actionCreator<{ email: string }>('CHECK_EMAIL');
export const checkEmailSuccess = actionCreator<ICheckEmailResponse>(
  'CHECK_EMAIL_SUCCESS'
);

export const checkEmailFailed = actionCreator<{
  message: string;
  statusCode?: number;
}>('CHECK_EMAIL_FAILED');

export const sidebarChanged = actionCreator<boolean>('SIDEBAR_CHANGED');

export const themeChanged = actionCreator<ColorTheme>('THEME_CHANGED');

/***
 * SIGN_IN action can be either login with username and password, refresh_token from local storage, or pin code
 */
export const signin = actionCreator<{
  email?: string;
  password?: string;
  refresh_token?: string;
  pinCode?: string;
  newUser?: boolean;
  loginType: LoginType;
  token?: string;
}>('SIGN_IN');

export const signinSuccess = actionCreator<IAuthResult & { profile: IPerson }>(
  'SIGN_IN_SUCCESS'
);

// this action is dispatched when user is signed in (in any way) and access_token is stored on state.
export const userReady = actionCreator<{}>('USER_READY');

export const signinFailed = actionCreator<{
  message?: string;
  loginType: LoginType;
}>('SIGN_IN_FAILED');

export const signout = actionCreator<{ reason?: string }>('SIGN_OUT');
export const signoutSuccess = actionCreator<{}>('SIGN_OUT_SUCCESS');

// action to manually request a refresh of access token. refresh_token is fetched from local storage
export const refreshAccessTokenManual = actionCreator<{ reason?: string }>(
  'REFRESH_ACCESS_TOKEN_MANUAL'
);

export const appIsReady = actionCreator<{}>('APP_IS_READY');

// dispatch this action from signup step 1
export const verifyEmailSent = actionCreator<{ email: string }>(
  'VERIFY_EMAIL_HACK'
);

export interface IAuthUsername {
  readonly isLoggingInUsername: boolean;
  readonly errorLoginUsername?: string;
}

type VerifyEmailErrors =
  | 'tooManyAttempts'
  | 'badRequest'
  | 'serverError'
  | 'serverDown'
  | 'nouser'
  | 'invalidEmail';

export interface IAuthCheckEmail {
  readonly isCheckingEmail: boolean;
  readonly errorEmail?: VerifyEmailErrors;
  // { message: string; statusCode?: number }
  readonly validatedEmail?: {
    readonly email: string;
    readonly emailSent: Moment;
    readonly valid?: boolean;
    readonly newUser?: boolean;
    readonly isMunicipality: boolean;
    readonly organization?: {
      readonly id: number;
      readonly name: string;
    };
  };
}

export interface IAuthPin {
  readonly isLoggingInPin: boolean;
  readonly errorPin?: string;
}

export interface IAuthRefresh {
  readonly isLoggingInRefresh: boolean;
  readonly errorLoginRefresh?: string;
}

export interface ILoginStateUser {
  readonly username: string;
  readonly roles?: Array<string>;
  readonly profile?: IPerson;
}

export interface ILoginState {
  readonly auth_email: IAuthCheckEmail;
  readonly auth_pin: IAuthPin;
  readonly auth_username: IAuthUsername;
  readonly auth_refresh: IAuthRefresh;
  readonly isAuthenticated: boolean;
  readonly isAppReady: boolean; // is app init ready? (show loading screen or not..) same as !isLoggingIn (any type)
  readonly user?: ILoginStateUser;
  readonly token?: {
    readonly refresh_token: string;
    readonly access_token: string;
    readonly expires_in: number;
    readonly access_token_time: Date;
  };

  readonly verifyEmail?: string;
}

// initial state
const initialState: ILoginState = {
  isAuthenticated: false,
  isAppReady: false,
  auth_email: {
    isCheckingEmail: false,
  },
  auth_pin: {
    isLoggingInPin: false,
  },
  auth_username: {
    isLoggingInUsername: false,
  },
  auth_refresh: {
    isLoggingInRefresh: false,
  },
};

function apiErrorToMyError(err: {
  message: string;
  statusCode?: number;
}): VerifyEmailErrors {
  console.log('err', err);
  if (err.statusCode && err.statusCode === 401) {
    return 'nouser';
  }
  if (err.statusCode && err.statusCode === 400) {
    return 'badRequest';
  }
  if (err.message === 'Failed to fetch') {
    return 'serverDown';
  }
  return 'serverError';
}

export const reducer = (
  state: ILoginState = initialState,
  action: Action
): ILoginState => {
  if (isType(action, verifyEmailSent)) {
    return {
      ...state,
      verifyEmail: action.payload.email,
    };
  }

  if (isType(action, checkEmail)) {
    return {
      ...state,
      auth_email: {
        isCheckingEmail: true,
        errorEmail: undefined,
        validatedEmail: undefined,
      },
    };
  }
  if (isType(action, checkEmailFailed)) {
    return {
      ...state,
      auth_email: {
        isCheckingEmail: false,
        errorEmail: apiErrorToMyError(action.payload),
        validatedEmail: undefined,
      },
    };
  }
  if (isType(action, checkEmailSuccess)) {
    if (action.payload.tooManyAttempts) {
      return {
        ...state,
        auth_email: {
          isCheckingEmail: false,
          errorEmail: 'tooManyAttempts',
          validatedEmail: undefined,
        },
      };
    } else {
      return {
        ...state,
        auth_email: {
          ...state.auth_email,
          isCheckingEmail: false,
          errorEmail: undefined,
          validatedEmail: {
            email: action.payload.email,
            emailSent: action.meta ? action.meta.actionCreated : undefined,
            valid: action.payload.valid,
            newUser: action.payload.newUser,
            organization: action.payload.organization && {
              id: action.payload.organization.id,
              name: action.payload.organization.name,
            },
            isMunicipality: action.payload.isMunicipality || false,
          },
        },
      };
    }
  }
  if (isType(action, signin)) {
    switch (action.payload.loginType) {
      case LoginType.Pin:
        return {
          ...state,
          auth_pin: {
            isLoggingInPin: true,
            errorPin: undefined,
          },
        };
      case LoginType.Username:
        return {
          ...state,
          auth_username: {
            isLoggingInUsername: true,
            errorLoginUsername: undefined,
          },
        };
      case LoginType.Refresh:
        return {
          ...state,
          auth_refresh: {
            isLoggingInRefresh: true,
            errorLoginRefresh: undefined,
          },
        };
      case LoginType.RefreshOnLoad:
        return {
          ...state,
          auth_refresh: {
            isLoggingInRefresh: true,
            errorLoginRefresh: undefined,
          },
          token: {
            ...state.token,
            refresh_token: action.payload.refresh_token,
          },
        };
      default:
        return {
          ...state,
        };
    }
  }

  if (isType(action, signinSuccess)) {
    switch (action.payload.loginType) {
      case LoginType.Pin:
        return {
          ...state,
          auth_pin: {
            isLoggingInPin: false,
            errorPin: undefined,
          },
          isAuthenticated: true,
          token: {
            refresh_token: action.payload.refresh_token,
            access_token: action.payload.access_token,
            expires_in: action.payload.expires_in,
            access_token_time:
              (action.meta && action.meta.actionCreated) || new Date(),
          },
          user: {
            ...state.user,
            username: action.payload.username,
            roles: action.payload.roles,
            profile: action.payload.profile,
          },
        };
      case LoginType.Username:
        return {
          ...state,
          auth_username: {
            isLoggingInUsername: false,
            errorLoginUsername: undefined,
          },
          isAuthenticated: true,
          token: {
            refresh_token: action.payload.refresh_token,
            access_token: action.payload.access_token,
            expires_in: action.payload.expires_in,
            access_token_time:
              (action.meta && action.meta.actionCreated) || new Date(),
          },
          user: {
            ...state.user,
            username: action.payload.username,
            roles: action.payload.roles,
            profile: action.payload.profile,
          },
        };
      // Commented out stuff that does not need to be updated on refresh
      // This should fix full rerender on refresh bug
      case LoginType.Refresh: {
        return {
          ...state,
          auth_refresh: {
            isLoggingInRefresh: false,
            errorLoginRefresh: undefined,
          },
          // isAuthenticated: true,
          token: {
            refresh_token: action.payload.refresh_token,
            access_token: action.payload.access_token,
            expires_in: action.payload.expires_in,
            access_token_time:
              (action.meta && action.meta.actionCreated) || new Date(),
          },
          // user: {
          //   ...state.user,
          //   username: action.payload.username,
          //   roles: action.payload.roles,
          //   profile: action.payload.profile,
          // },
        };
      }
      case LoginType.RefreshOnLoad: {
        return {
          ...state,
          auth_refresh: {
            isLoggingInRefresh: false,
            errorLoginRefresh: undefined,
          },
          isAuthenticated: true,
          isAppReady: true,
          token: {
            refresh_token: action.payload.refresh_token,
            access_token: action.payload.access_token,
            expires_in: action.payload.expires_in,
            access_token_time:
              (action.meta && action.meta.actionCreated) || new Date(),
          },
          user: {
            ...state.user,
            username: action.payload.username,
            roles: action.payload.roles,
            profile: action.payload.profile,
          },
        };
      }
      default:
        return {
          ...state,
        };
    }
  }

  if (isType(action, updateMeActionCreator.success)) {
    return {
      ...state,
      user: {
        ...state.user,
        username: (state.user && state.user.username) || 'notgonnahappen',
        profile: action.payload.result,
      },
    };
  }

  if (isType(action, signinFailed)) {
    switch (action.payload.loginType) {
      case LoginType.Pin:
        return {
          ...state,
          auth_pin: {
            isLoggingInPin: false,
            errorPin: action.payload.message,
          },
          isAuthenticated: false,
        };
      case LoginType.Username:
        return {
          ...state,
          auth_username: {
            isLoggingInUsername: false,
            errorLoginUsername: action.payload.message,
          },
          isAuthenticated: false,
        };
      case LoginType.Refresh:
        return {
          ...state,
          auth_refresh: {
            isLoggingInRefresh: false,
            errorLoginRefresh: action.payload.message,
          },
          isAuthenticated: false,
          token: null,
        };
      case LoginType.RefreshOnLoad:
        return {
          ...state,
          auth_refresh: {
            isLoggingInRefresh: false,
            errorLoginRefresh: action.payload.message,
          },
          isAuthenticated: false,
          isAppReady: true,
          token: null,
        };
      default:
        return {
          ...state,
        };
    }
  }
  if (isType(action, signout)) {
    return {
      ...state,
      // isLoggingOut: true,
    };
  }

  if (isType(action, signoutSuccess)) {
    return {
      ...state,
      // isLoggingOut: false,
      user: undefined,
      token: undefined,
      auth_pin: {
        isLoggingInPin: false,
        errorPin: undefined,
      },
      auth_refresh: {
        isLoggingInRefresh: false,
        errorLoginRefresh: undefined,
      },
      auth_email: {
        isCheckingEmail: false,
        errorEmail: undefined,
      },
      isAuthenticated: false,
      isAppReady: true,
    };
  }
  if (isType(action, appIsReady)) {
    return {
      ...state,
      isAppReady: true,
    };
  }

  if (isType(action, sidebarChanged)) {
    return {
      ...state,
      user: {
        ...state.user,
        profile: {
          ...state.user.profile,
          isSidebarExpanded: action.payload,
        },
      },
    };
  }

  if (isType(action, themeChanged)) {
    return {
      ...state,
      user: {
        ...state.user,
        profile: {
          ...state.user.profile,
          theme: action.payload,
        },
      },
    };
  }

  return state;
};

export function* checkMailSaga() {
  while (true) {
    const action = yield take(checkEmail.type); // we picked up a check email action

    try {
      const result: ICheckEmailResponse = yield call(
        requestEmailPinLogin,
        action.payload.email
      );

      yield put(checkEmailSuccess(result));

      // navigate to:
      // 1. invalid non-muni email
      // 2. valid muni, but non-partner
      // 3. new user welcome screen
      // 4. or dont navigate at all...
      const isValidUserWithSubscription = result && result.valid;
      const isValidMunicipality = result && result.organization !== undefined;
      const isNewUser = result && result.newUser;

      if (isValidUserWithSubscription && isNewUser) {
        // we have a new user which hasnt logged in yet..
      } else if (!isValidUserWithSubscription) {
        if (isValidMunicipality) {
          myHistory.push('login/no-subscription');
        } else {
          // yield put(push('login/invalid-email')); // just a regular errormessage
        }
      }
    } catch (e) {
      yield put(checkEmailFailed(wrapApiError(e)));
    }
  }
}

// is main auth saga running?
let isRunning = false;

// initAuth, this must be called AFTER authLoopSaga! (authLoopSaga must be ready to pick up signin action)
export function* initAuthSaga() {
  // console.log('initAuthSaga');

  const storedRefreshToken = yield call(LocalStorageService.getRefreshToken);

  // console.log('waiting for rehydrate to complete');
  //
  // const rehydrate = yield take('persist/REHYDRATE');
  //
  // console.log('rehydrate done!');

  if (storedRefreshToken !== undefined) {
    if (!isRunning) {
      console.error(
        'main auth loop must be running before we put signin action with refresh token to store!'
      );
    }

    // console.log('signing in using refresh token');

    yield put(
      signin({
        refresh_token: storedRefreshToken,
        loginType: LoginType.RefreshOnLoad,
      })
    );
  } else {
    // action should remove tokens from state
    yield put(
      signout({ reason: 'no refresh_token on init, signing out user!' })
    );

    // cannot use yield* when targeting ES5???????
    // yield* logoutSaga(); // <=== note: yield* is used to reference another generator function
    yield call(LocalStorageService.resetTokens); // remove tokens from local storage
    yield put(signoutSuccess({})); //
  }
}

export function* forceRefreshTokenGetProfileAndSignIn() {
  const storedRefreshToken = yield call(LocalStorageService.getRefreshToken);

  const refreshResponse = yield call(refreshAccessToken, storedRefreshToken);

  yield call(
    LocalStorageService.storeRefreshToken,
    refreshResponse.refresh_token
  );

  const profile2 = yield call(
    personService.getMe,
    refreshResponse.access_token
  ); // token is not stored in state yet

  const refreshResult = refreshResponse;
  refreshResult.loginType = LoginType.Refresh;
  refreshResult.profile = profile2;

  // put action on store, REFRESH successful!
  yield put(signinSuccess(refreshResult));
}

/**
 * returns true if token expires soon (80%)
 * @param {RootState} state
 * @returns {boolean}
 */
const shouldRefreshTokenSelector = (state: RootState): boolean => {
  if (!state.auth || !state.auth.token || !state.auth.token.access_token_time) {
    return false;
  }

  let expires = moment(state.auth.token.access_token_time);
  expires.add(state.auth.token.expires_in, 'seconds');
  const expiresTime = moment(state.auth.token.access_token_time);
  expiresTime.add(state.auth.token.expires_in * 0.9, 'seconds');
  const diff = expiresTime.diff(moment(), 'seconds');
  // console.log('REF expires:' + expires.format('HH:mm:ss') +
  //    'expiresTime:' + expiresTime.format('HH:mm:ss') + 'diff: ' + diff);
  return diff < 0;
};

// main auth saga listening for auth actions test
export function* authLoopSaga() {
  isRunning = true;

  while (true) {
    // console.log('entered signedOut loop');
    const { payload } = yield take(signin.type); // listen/wait for login action

    let authResponse: any = undefined; // IAuthResponse

    try {
      if (
        payload.loginType === LoginType.Refresh ||
        payload.loginType === LoginType.RefreshOnLoad
      ) {
        // login using refresh_token
        if (!payload.refresh_token) {
          throw new Error('refresh_token is required');
        }
        authResponse = yield call(refreshAccessToken, payload.refresh_token);
      } else if (payload.loginType === LoginType.Username) {
        // username + password login
        if (!payload.email) {
          throw new Error('Email is required');
        }
        if (!payload.password) {
          throw new Error('Password is required');
        }
        authResponse = yield call(login, payload.email, payload.password);
      } else if (payload.loginType === LoginType.Pin) {
        // pin
        if (!payload.email) {
          throw new Error('Email is required');
        }
        if (!payload.pinCode) {
          throw new Error('PinCode is required');
        }

        if (payload.newUser) {
          authResponse = yield call(
            verifyNewUserPin,
            payload.email,
            payload.pinCode
          );
        } else {
          authResponse = yield call(verifyPin, payload.email, payload.pinCode);
        }
      } else if (payload.loginType === LoginType.Microsoft) {
        authResponse = yield call(signInWithMicrosoft, payload.token);
      } else {
        throw new Error('unsupported logintype');
      }

      // if we get here, login is successful
      if (authResponse === undefined) {
        throw new Error('error getting loginResult');
      }

      // store tokens in local store
      yield call(
        LocalStorageService.storeRefreshToken,
        authResponse.refresh_token
      );

      yield call(
        LocalStorageService.storeAccessToken,
        authResponse.access_token
      );

      if (payload.loginType === LoginType.Microsoft) {
        if (teamsContext) {
          console.log('Is teams login. Should notify');
          microsoftTeams.initialize();
          microsoftTeams.authentication.notifySuccess();
          window.close();
        } else {
          window.location.href = '/app/';
        }
      } else if (
        payload.loginType !== LoginType.Refresh &&
        payload.loginType !== LoginType.RefreshOnLoad
      ) {
        window.location.reload();
      }

      const profile = yield call(
        personService.getMe,
        authResponse.access_token
      ); // token is not stored in state yet

      // console.log('me:', profile);
      Sentry.configureScope(function(scope: Scope) {
        scope.setUser({
          id: profile.id,
          username: profile.email,
          email: profile.email,
        });
      });

      const authResult = authResponse;
      authResult.loginType = payload.loginType;
      authResult.profile = profile;

      // .resetStore will invalidate all cached queries in apolloClient, forcing it to get new data from server
      // this is to avoid "old" data from your last login showing on screen
      // yes, this is required! it resets the store in MEMORY (disk is purged/restore in apolloClientFactory)
      // yield call(myApolloClient.resetStore);
      if (myApolloClient) {
        yield call(myApolloClient.resetStore);
      } else {
        // @Kristoffer this fixes BUG where user got logged out when a new version was available.
        // if myApolloClient is undefined, it could be initializing (clearing cache, storing new version, etc)
        // signin already returned successful, but we need to wait for apolloClient to initialize..
        // added a 5 second max wait..
        for (let i = 0; i < 10; i++) {
          if (myApolloClient) {
            // console.log('client READY, reset store..');
            yield call(myApolloClient.resetStore);
            break;
          } else {
            // console.log('waiting for store...');
            yield delay(1000);
          }
        }
      }

      // NOTE: when putting success, we change redux global state setting auth=true. this will cause a redirect because of
      // hoc.. so the HOC needs to redirect?

      const queryParamsWhenLoggingIn = getQueryStringParams(
        window.location.search
      );

      // put action on store, login successful!
      yield put(signinSuccess(authResult)); // reducer should store tokens in state

      // this action is fired when user is ready (access_token is stored in redux state)
      yield put(userReady({}));

      yield put(getNotificationsInfoActionCreator.started({}));

      // if we have a fresh/new user, we fire a action:
      const isFreshUserLogin = profile.userStatus === UserStatus.NEED_INFO;
      const isShowWelcomeFlagSetAlready = yield select(
        (state: RootState) => state.onboarding.isWelcomeUserVisible
      );

      // console.log('isFresh: ' + isFreshUserLogin);
      // console.log('isWelcomeAlready: ' + isShowWelcomeFlagSetAlready);

      if (isFreshUserLogin) {
        yield put(showWelcomeUser({}));
      }

      if (isFreshUserLogin || isShowWelcomeFlagSetAlready) {
        myHistory.push('/welcome-user');
      } else {
        if (queryParamsWhenLoggingIn.hasOwnProperty('redirect')) {
          let temp = queryParamsWhenLoggingIn.redirect;
          if (temp.indexOf('/app') === 0) {
            temp = temp.replace('/app', '');
          }
          // console.log(
          //   'redirecting to: ' + temp,
          //   queryParamsWhenLoggingIn.redirect
          // );
          myHistory.push(temp);
        }
      }

      // we're logged in, entering the "signed-in-loop", waiting for 1. logout 2. refresh 3. timer refresh token
      // console.log('entering signedInLoop!');
      while (true) {
        // yield take(SIGN_OUT);
        const { signoutRequested, refreshReqMan, refreshTimer } = yield race({
          signoutRequested: take(signout.type),
          refreshReqMan: take(refreshAccessTokenManual.type),
          refreshTimer: delay(5 * 1000),
          // refreshTimer: delay(authResponse.expires_in * 0.8 * 1000), // auto refresh 20% before timeout?
        });

        // note: 30 seconds * .8 = 24s = 6 seconds to refresh token...
        // 60 seconds * 0.8 = 48 = 12 seconds..
        // 3600 seconds = 2880 = 720 s = 12 minutes = ok...

        if (signoutRequested) {
          break; // break out of signed-in-loop
        } else if (refreshTimer || refreshReqMan) {
          const shouldRefresh = yield select(shouldRefreshTokenSelector);

          if (shouldRefresh) {
            // const isOnline = yield select(isOnlineSelector);
            const isOnline = true;

            if (isOnline) {
              // console.log('refresh...');
              yield call(forceRefreshTokenGetProfileAndSignIn);
            } else {
              // console.log('hastag online, hastagg online, vi er ikkje online (cant refresh token)');
            }
          }
          // else {
          //   // console.log('no refresh yet');
          // }
        }
      }

      yield call(LocalStorageService.resetTokens); // remove tokens from local storage

      yield put(signoutSuccess({})); // sign out user (redirects)

      // redirect to homepage manually after HOC is gone:
      myHistory.push('/sign-out');

      // we sign out, delay 2 seconds to make sure that user is redirected to signin page,
      // THEN we forefully delete apollos persisted cache
      // console.log('cache=>we are logged out, waiting 2 seconds');
      // yield call(delay, 2000);
      // console.log(
      //   'cache=>we are logged out, FORCE deleting apollo persisted cache!'
      // );

      // running this async to enter signedout state sooner,,
      setTimeout(() => {
        forceClearApolloPersistedCache();
      }, 1000);

      // console.log('cache=>t3', t3);

      // NOTE: there are methods on ApolloClient to resetStore and clearStore, but they dont work...
    } catch (e) {
      // login failed. put failed action on redux-saga (saga middleware will pick up action and dispatch on store)
      yield put(
        signinFailed({ message: e.message, loginType: payload.loginType })
      );
    }
  }
}

export const profileSelector = createSelector<
  RootState,
  IPerson | undefined,
  IPerson
>(
  state => {
    // tslint:disable-next-line
    return (
      (state.auth && state.auth.user && state.auth.user.profile) || undefined
    );
    // tslint:disable-next-line
  },
  (result: IPerson) => result
);

export const usernameSelector = (state: RootState): string | undefined =>
  (state.auth &&
    state.auth.user &&
    state.auth.user.profile &&
    state.auth.user.profile.name) ||
  undefined;

export const userHashSelector = (state: RootState): string | undefined =>
  (state.auth &&
    state.auth.user &&
    state.auth.user.profile &&
    state.auth.user.profile.hash) ||
  undefined;

const rolesSelector = (state: RootState) =>
  (state.auth && state.auth.user && state.auth.user.roles) || [];

export const permissionsSelector = (
  state: RootState
): Array<string> | undefined =>
  (state.auth &&
    state.auth.user &&
    state.auth.user.profile &&
    state.auth.user.profile.permissions) ||
  undefined;

function getInitials(name?: string) {
  if (name === undefined || name === '') {
    return 'NN';
  }
  const temp = name.split(' ');
  if (temp.length === 1 && name.length >= 2) {
    return name.substring(0, 2).toUpperCase();
  }
  return temp[0][0] + temp[temp.length - 1][0].toUpperCase();
}

export const userInitialsSelector = createSelector<
  RootState,
  string | undefined,
  string
>(usernameSelector, (name: string | undefined) => getInitials(name));

export const emailSelector = (state: RootState): string | undefined =>
  (state.auth &&
    state.auth.user &&
    state.auth.user.profile &&
    state.auth.user.profile.email) ||
  undefined;

export const simpleMeSelector = (state: RootState): IPersonSimple | undefined =>
  (state.auth &&
    state.auth.user &&
    state.auth.user.profile &&
    state.auth.user.profile.simple) ||
  undefined;

export const userProfileSelector = (state: RootState): IPerson | undefined =>
  (state.auth && state.auth.user && state.auth.user.profile) || undefined;

// returns false if user is not logged in..
// TODO: we could use reselect to mwmoize this.. but I don't get the syntax yet...
export const isAdminSelector = (state: RootState): boolean =>
  (state &&
    state.auth &&
    state.auth.user &&
    state.auth.user.roles &&
    state.auth.user.roles.indexOf('ROLE_ADMIN') >= 0) ||
  false;

// Hack for not redirecting to login page on network loss
export const isAuthenticatedSelector = (state: RootState): boolean =>
  (state &&
    state.auth &&
    state.auth.token &&
    state.auth.token.refresh_token !== '') ||
  false;

// export const isAuthenticatedSelector = (state: RootState): boolean =>
//   (state && state.auth && state.auth.isAuthenticated) || false;

// export const userAuthInfoSelector = (state: RootState): MyAuthInfo | undefined => {
//   const isAuthenticated = isAuthenticatedSelector(state);
//   if (!isAuthenticated) {
//     return undefined;
//   }
//   return {
//     username: usernameSelector(state),
//     hash: userHashSelector(state),
//     email: emailSelector(state),
//     roles: state.auth.user.roles,
//     permissions: permissionsSelector(state)
//   };
// };

export const userAuthInfoSelector = createSelector<
  RootState,
  string | undefined,
  string | undefined,
  string | undefined,
  ReadonlyArray<string>,
  ReadonlyArray<string>,
  UserAuthInfo | undefined
>(
  usernameSelector,
  userHashSelector,
  emailSelector,
  rolesSelector,
  permissionsSelector,
  (res1, res2, res3, res4, res5) => {
    // console.log('TRANS AUTH');

    if (!res1 || !res2 || !res3) {
      return undefined;
    }

    return {
      username: res1,
      hash: res2,
      email: res3,
      roles: res4,
      permissions: res5,
    };
  }
);

export const themeSelector = (state: RootState): ColorTheme => {
  return state.auth?.user?.profile?.theme;
};
