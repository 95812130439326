import * as React from 'react';
import { CSSProperties } from 'react';
import { ContentBlock, ContentState, EditorState } from 'draft-js';
import styled from 'styled-components';
import {
  IMuBag,
  IMuEditorRenderProps,
  MuEditor,
  MuEditorMode,
} from './MuEditor';
import {
  Colors,
  isFunction,
  IThemeProps,
  safeInvokeDeprecated,
} from '../../common';
import { MunikumIcons } from '../../common/icons';
import { IFileItem } from '../UserFile/FileUploaderPicker';
import { InlineButton } from '../InlineButton/InlineButton';
import { MuToolbarMode } from './MuToolbar';
import { MuInternalEditor } from './MuInternalEditor';

const draftjsExport = require('draft-js-export-markdown');

const isEventComingFromLinkifyLink = (e: any): boolean => {
  try {
    const t1 = e.target;
    if (t1.tagName === 'A') {
      return true;
    }
    if (t1.parentElement.tagName === 'A') {
      return true;
    }
    if (t1.parentElement.parentElement.tagName === 'A') {
      // we could also check classname? but think A is enough...
      return true;
    }
  } catch (e) {
    return false;
  }

  return false;
};

export interface IMuContentEditableProps {
  name?: string;

  id?: string;

  value?: string;

  placeholder?: string;

  canEdit?: boolean;

  isMultiLine?: boolean;

  disabled?: boolean;

  shouldSubmitOnBlur?: boolean;

  alwaysShowBorderAndBackground?: boolean;

  focusOnMount?: boolean;

  style?: CSSProperties;

  hasRightIconsOnEdit?: boolean;

  onSubmit?: (markdown: string, contentState?: EditorState) => void;

  onCancel?: (md: string) => void;

  onBlur?: (e: any) => void;

  /**
   * NOTE: this will convert to markdown on EACH KEYPRESS!
   * dont use this if you dont have to =)
   * dont use this for longer content =)
   * @param markdown
   */
  onChange?: (markdown: string, e: Event) => void;
}

interface IMuContentEditableState {
  isEditing: boolean;
  isHovering: boolean;
  value: string;
  isLooser: boolean; // true if were loosing focus (used for delay...)
}

interface IRenderProps {
  isHovering: boolean;
  isEditing: boolean;
  canEdit: boolean;
}

const InlineEditorContainer = styled.div`
  position: relative;

  display: inline-flex;
  align-items: center; // vertical
  box-sizing: border-box;

  min-width: 2em; // ??? remove??

  cursor: ${(props: IRenderProps & { theme: IThemeProps }) =>
    props.canEdit ? 'text' : 'default'};

  background-color: ${(props: IRenderProps & { theme: IThemeProps }) =>
    props.isEditing
      ? props.theme.contentBackgroundColor
      : props.isHovering && props.canEdit
      ? Colors.ROW_BACKGROUND_HOVER
      : 'transparent'};

  border-radius: 4px;
  border-style: solid;
  border-color: ${(props: IRenderProps) =>
    props.isEditing ? '#333333' : 'transparent'};
  border-width: 1px;

  margin-left: -3px;
  padding-left: 3px;

  margin-right: -3px;
  padding-right: 3px;

  padding-bottom: 2px;
  padding-top: 2px;

  transition: border-color 0.25s ease-in-out, background-color 0.25s ease-in-out;

  //z-index: 100;
  overflow: visible;
`;

const AbsoluteVodka = styled.div`
  position: absolute;
  right: -30px;
  top: 0px;
  //background-color: orange;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 4px;
`;

/**
 * MuContentEditable
 *
 * TODO:
 * - when clicking edit button, we should set focus() on draft.js input. (but we cant yet because of draft.js bug..)
 */
export class MuContentEditable extends React.PureComponent<
  IMuContentEditableProps,
  IMuContentEditableState
> {
  public static defaultProps: IMuContentEditableProps = {
    shouldSubmitOnBlur: true,
  };

  private myEditor: React.RefObject<any>;

  constructor(props: IMuContentEditableProps) {
    super(props);

    this.state = {
      isEditing: this.props.focusOnMount,
      isHovering: false,
      isLooser: false,
      value: this.props.value,
    };

    this.myEditor = React.createRef();
  }

  UNSAFE_componentWillReceiveProps(nextProps: IMuContentEditableProps) {
    if (nextProps.value !== this.state.value) {
      this.setState({
        value: nextProps.value,
      });
    }
  }

  handleOnChange = (contentState: ContentState) => {
    if (this.props.onChange !== undefined) {
      // NOTE: this could be slow :-S
      const md = draftjsExport.stateToMarkdown(contentState);
      safeInvokeDeprecated(this.props.onChange, md);
    }
  };

  handleEdit = (e: any) => {
    // console.log('click detected, test wait hack to let user follow links???', e);
    const wasLinkClicked = isEventComingFromLinkifyLink(e);

    if (wasLinkClicked) {
      // console.log('cancel edit, you clicked a link...');
      return;
    }

    if (!this.state.isEditing && this.props.canEdit) {
      this.setState(
        {
          isEditing: true,
        },
        () => {
          // TODO: set focus"
          // console.log('contenteditable set focus!');
          if (
            this.myEditor.current &&
            isFunction(this.myEditor.current.focus)
          ) {
            this.myEditor.current.focus();
          }
        }
      );
    }
  };

  render() {
    const { canEdit } = this.props;
    const { isEditing, isHovering } = this.state;
    return (
      <MuEditor
        editorMode={
          canEdit && isEditing ? MuEditorMode.Edit : MuEditorMode.Preview
        }
        toolbarMode={MuToolbarMode.None}
        rawMarkdown={this.state.value || ''}
        onCancel={revertedMd => {
          this.setState(
            {
              isEditing: false,
              isHovering: false, // mini-hack to hide border after canceling
            },
            () => {
              safeInvokeDeprecated(this.props.onCancel, revertedMd);
            }
          );
        }}
        onSubmit={(
          markdown: string,
          files2: IFileItem[],
          contentState: ContentState,
          muEditorBag: IMuBag
        ) => {
          this.setState(
            {
              isEditing: false,
              isHovering: false, // mini-hack to hide border after saving
            },
            () => {
              safeInvokeDeprecated(this.props.onSubmit, markdown, contentState);
            }
          );
        }}
        onChange={this.handleOnChange}
        editorRenderFunc={(propsEditor: IMuEditorRenderProps) => {
          return (
            <div>
              <InlineEditorContainer
                isHovering={isHovering}
                isEditing={isEditing}
                canEdit={canEdit || false}
                style={{ ...this.props.style, width: '100%' }}
                onBlur={() => {
                  propsEditor.handleSubmit();
                }}
                onClick={this.handleEdit}
                onMouseEnter={() => {
                  this.setState({
                    isHovering: true,
                  });
                }}
                onMouseLeave={() => {
                  this.setState({
                    isHovering: false,
                  });
                }}
              >
                <MuInternalEditor
                  ref={this.myEditor}
                  id={this.props.id}
                  name={this.props.name}
                  placeholder={this.props.placeholder}
                  editorMode={propsEditor.editorMode}
                  toolbarMode={propsEditor.toolbarMode}
                  draftState={propsEditor.draftState}
                  onChange={(draftState: EditorState) => {
                    propsEditor.handleDraftChange(draftState);
                  }}
                  onBlur={(e: any) => {
                    // fix bug here..: need a delay (hotfix)

                    // if (this.props.shouldSubmitOnBlur) {
                    //   propsEditor.handleSubmit();
                    // }

                    if (this.props.shouldSubmitOnBlur) {
                      // console.log('onBlur, set looser');
                      this.setState(
                        {
                          isLooser: true,
                        },
                        () => {
                          // console.log(
                          //   'i am a looser' + this.state.isLooser.toString()
                          // );
                          setTimeout(() => {
                            if (this.state.isLooser) {
                              this.setState({
                                isLooser: false,
                              });
                              // console.log('submit on blur');
                              propsEditor.handleSubmit();
                            } else {
                              // console.log('not submitting, cancelled');
                            }
                          }, 100); // TODO: this will probably work.. maybe... should do something better like canceling event or something..
                        }
                      );
                    }

                    safeInvokeDeprecated(this.props.onBlur, e);
                  }}
                  muHandleReturnFn={(e: any, state: EditorState) => {
                    if (this.props.isMultiLine) {
                      return 'not-handled';
                    } else {
                      propsEditor.handleSubmit(); // submits
                      return 'handled'; // return key wont create newline
                    }
                  }}
                  style={{
                    display: 'inline-box', // custom inline css
                    width: '100%', // need this to make entire area focusable/clickable (without using refs)
                  }}
                  onEscape={() => {
                    propsEditor.handleCancel();
                  }}
                  muBlockStyleFn={(block: ContentBlock) => {
                    const type = block.getType();
                    if (this.props.isMultiLine) {
                      switch (type) {
                        case 'unstyled':
                          return 'muParagraph';
                        case 'blockquote':
                          return 'muQuote';
                        case 'code-block':
                          return 'muCode';
                        default:
                          return 'muBlock';
                      }
                    } else {
                      return 'muBlank';
                    }
                  }}
                />
                <AbsoluteVodka>
                  {isEditing && this.props.hasRightIconsOnEdit && (
                    <>
                      <InlineButton
                        icon={MunikumIcons.LeftCheck}
                        // icon={MunikumIcons.Save}
                        style={{}}
                        onClick={propsEditor.handleSubmit}
                      />
                      <InlineButton
                        icon={MunikumIcons.Close}
                        // icon={MunikumIcons.Cancel}
                        style={{
                          marginTop: '4px',
                        }}
                        onClick={() => {
                          // console.log('cancel');
                          if (this.state.isLooser) {
                            this.setState({
                              isLooser: false, // cancel submit on loose focus
                            });
                          }
                          propsEditor.handleCancel();
                        }}
                      />
                    </>
                  )}
                  {!isEditing && canEdit && isHovering && (
                    <>
                      <InlineButton
                        icon={MunikumIcons.Edit}
                        onClick={() => this.handleEdit({ dummy: 'from butt' })}
                      />
                    </>
                  )}
                </AbsoluteVodka>
              </InlineEditorContainer>
            </div>
          );
        }}
      />
    );
  }
}

MuContentEditable.defaultProps = {
  hasRightIconsOnEdit: true,
  focusOnMount: false,
};
