import * as React from 'react';
import { CSSProperties, RefObject } from 'react';
import styled, { withTheme } from 'styled-components';
import { IconType } from 'react-icons/lib';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  Colors,
  DEFAULT_COLOR_THEME,
  getThemeProps,
  IAccentColorSet,
  IActionProps,
  IThemeProps,
  removeNonHTMLProps,
  safeInvokeDeprecated,
} from '../../common';
import { ButtonSpinner } from '..';
import { Tooltip } from '../Tooltip/Tooltip';
import { isKeyboardClick } from '../../common/keys';
import { EmailSuccess } from '../../meta/EmailSuccess';

const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    outline: none;
  }
`;

export enum ButtonSize {
  /**
   * 26px, 1.625em
   */
  Small,

  /**
   * 36px, 2.25em
   */
  Medium,

  /**
   * 46px, 2.875 (-.125 border = 2.75)
   */
  Large,
}
export enum ButtonTheme {
  NORMAL = 'NORMAL',
  RED = 'RED',
}
interface IButtonRenderProps {
  accent: IAccentColorSet;

  isDisabled: boolean;

  hasLeftIcon: boolean;
  buttonTheme?: ButtonTheme;
  greyBackground?: boolean;

  hasRightIcon: boolean;

  size: ButtonSize;
}

const StyledButtonShell = styled.button`
  //background-color: palegreen;

  cursor: pointer;
  font-size: inherit; // need this for ems to behave

  border: none;
  padding: 0;
  outline: none;

  border-radius: 0.25em;
`;

const InnerButton = styled.div`
  //background-color: moccasin;
  display: flex;
  justify-content: ${(props: IButtonRenderProps) =>
    !props.hasLeftIcon && !props.hasRightIcon ? 'center' : 'space-between'};
  align-items: center;

  // this sets height of button. Remember border 2 px!
  height: ${(props: IButtonRenderProps) =>
    props.size === ButtonSize.Small
      ? '1.5em'
      : props.size === ButtonSize.Medium
      ? '2.25em'
      : '2.75em'};
  padding: 0
    ${(props: IButtonRenderProps) =>
      props.size === ButtonSize.Small ? '.5em' : '.625em'};

  font-family: 'Lato', sans-serif;
  font-weight: 500;
  font-size: inherit; // need this for ems to behave

  min-width: ${(props: IButtonRenderProps) =>
    props.size === ButtonSize.Small ? '1em' : '5em'};

  border: 1px solid
    ${props =>
      props.buttonTheme === ButtonTheme.NORMAL
        ? props.accent.borderColor
        : props.buttonTheme === ButtonTheme.RED
        ? props.theme.accent1.buttonBorderColorRedTheme
        : props.accent.borderColor};
  border-radius: 0.25em;
  //? props.theme.noAccent.buttonColorTertiaryBackground
  background-color: ${props =>
    props.buttonTheme === ButtonTheme.NORMAL
      ? props.accent.color
      : props.buttonTheme === ButtonTheme.RED
      ? props.theme.noAccent.buttonRedThemeBackgroundColor
      : props.accent.color};

  text-align: center;
  transition: background-color 0.15s, border-color 0.15s;

  ${StyledButtonShell}:hover:enabled & {
    background-color: ${props =>
      props.buttonTheme === ButtonTheme.NORMAL
        ? Colors.GREYBORDER
        : props.buttonTheme === ButtonTheme.RED
        ? props.theme.noAccent.buttonHoverColorRedTheme
        : Colors.GREYBORDER};
    border-color: ${props =>
      props.buttonTheme === ButtonTheme.NORMAL
        ? Colors.GREYBORDER
        : props.buttonTheme === ButtonTheme.RED
        ? props.theme.noAccent.buttonBorderColorRedTheme
        : Colors.GREYBORDER};

    // I think shadow looks good on hover too?
    // TODO: shadow on hover too? Or just on TAB?
    box-shadow: 0 -1px 1px rgba(255, 255, 255, 0.95),
      0 1px 5px rgba(0, 0, 0, 0.15);
  }

  ${StyledButtonShell}:disabled & {
    background-color: ${Colors.GREY};
    color: ${Colors.GREYDISABLEDTEXT};
    cursor: not-allowed;
    border: 1px solid ${Colors.GREY};
  }

  ${StyledButtonShell}:active:enabled & {
    background-color: ${props =>
      props.buttonTheme === ButtonTheme.NORMAL
        ? Colors.GREYBORDER
        : props.buttonTheme === ButtonTheme.RED
        ? props.theme.noAccent.buttonHoverColorRedTheme
        : Colors.GREYBORDER};
    border-color: ${props =>
      props.buttonTheme === ButtonTheme.NORMAL
        ? props.accent.borderColor
        : props.buttonTheme === ButtonTheme.RED
        ? props.theme.accent1.buttonBorderColorRedTheme
        : props.accent.borderColor};

    //transform: translate(1px, 1px);
    //transform: scale(.99);
  }

  &:focus {
    // disable focus on inner div with negative tabIndex
    outline: none;
  }

  ${StyledButtonShell}:focus & {
    // also disable when focus outer button (tabbing keyboard)
    outline: none;

    // TODO: decide on styling here.. backgroundColor? shadow? both? when?

    // background color when TABBING:
    background-color: ${props => props.accent.hoverColor};

    // hsadow when TABBING:
    box-shadow: 0 -1px 1px rgba(255, 255, 255, 0.95),
      0 1px 5px rgba(0, 0, 0, 0.15);
    //box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  }
`;

const TextWrapper = styled.div`
  color: ${props =>
    props.isDisabled
      ? Colors.GREYDISABLEDTEXT
      : props.buttonTheme === ButtonTheme.NORMAL
      ? props.accent.textColor
      : props.buttonTheme === ButtonTheme.RED
      ? props.theme.accent1.buttonColorRedTheme
      : props.accent.textColor};

  // normal:        .875em
  // small: 12px    .75em
  // large: 16px    1em
  font-size: ${(props: IButtonRenderProps) =>
    props.size === ButtonSize.Small
      ? '.75em'
      : props.size === ButtonSize.Medium
      ? '.875em'
      : '1em'};
  white-space: nowrap;

  text-align: center;

  // adjust text to be centered with one icon and flexbox space-between, mini padding hack:
  // no padding for small butts
  padding-right: ${(props: IButtonRenderProps) =>
    props.size !== ButtonSize.Small && props.hasLeftIcon && !props.hasRightIcon
      ? '1.2em'
      : '0'};
  padding-left: ${(props: IButtonRenderProps) =>
    props.size !== ButtonSize.Small && !props.hasLeftIcon && props.hasRightIcon
      ? '1.2em'
      : '0'};

  transition: all 0.15s ease-in-out;

  ${StyledButtonShell}:active:enabled & {
    transform: scale(0.975);
  }
`;

interface IIconRenderProps {
  isLeftIcon: boolean;
  size: ButtonSize;
}

const IconContainer = styled.div`
  // small:  .75   12px
  // normal: 1     16     (1.2 on container)
  // large:  1.25  20px

  width: ${(props: IIconRenderProps) =>
    props.size === ButtonSize.Small
      ? '.75em'
      : props.size === ButtonSize.Medium
      ? '1em'
      : '1.25em'}; // 1.2em;
  height: ${(props: IIconRenderProps) =>
    props.size === ButtonSize.Small
      ? '.75em'
      : props.size === ButtonSize.Medium
      ? '1em'
      : '1.25em'}; // 1.2em;

  margin-right: ${(props: IIconRenderProps) =>
    props.isLeftIcon ? '.5em' : '0'};
  margin-left: ${(props: IIconRenderProps) =>
    !props.isLeftIcon ? '.5em' : '0'};

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  // transition: all .15s ease-in-out;
  //
  // ${StyledButtonShell}:active:enabled & {
  //   transform: scale(.97);
  // }
`;

export interface IButtonProps extends IActionProps {
  innerButtonStyle?: CSSProperties;

  accent?: IAccentColorSet;

  /**
   * different sizes, affects height + font size
   */
  size?: ButtonSize;

  greyBackground?: boolean;

  /**
   * show loading animation
   */
  isLoading?: boolean;
  buttonTheme?: ButtonTheme;

  // show animation for sucess
  isSuccess?: boolean;

  /**
   * ref to inner button element
   * @param {HTMLElement} ref
   * @returns {any}
   */
  // tslint:disable-next-line
  // innerRef?: (ref: HTMLElement) => any;
  innerRef?: any;

  // tslint:disable-next-line
  children?: any;

  /**
   * Icon from react-icons library, see MunikumIcons enum
   */
  leftIcon?: IconType;

  /**
   * Icon from react-icons library, see MunikumIcons enum
   */
  rightIcon?: IconType;

  /**
   * custom CSS applied to button element itself
   */
  style?: CSSProperties;

  iconColor?: string;
  textStyle?: CSSProperties;
  // /**
  //  * custom CSS applied to div surrounding the button text
  //  */
  // textStyle?: CSSProperties;

  /**
   * built in button tooltip
   */
  tooltip?: JSX.Element | string;

  theme?: IThemeProps;
}
//
// export interface IButtonDispatch {
//
// }

export interface IButtonState {
  isActive?: boolean;
  isSuccess: boolean;
  currentKeyDown?: number;
}

/**
 * Button component. Test *markdown* formatting `section` test
 * Managing focus based on this article: http://kizu.ru/en/blog/keyboard-only-focus/#similar
 * Clicking doesnt show outline, but tabbing does! (accessible and looks nice :) )
 */
let iconColor = '';

class ButtonComp extends React.PureComponent<
  IButtonProps & React.HTMLProps<HTMLButtonElement>,
  IButtonState
> {
  public static defaultProps: IButtonProps &
    React.HTMLProps<HTMLButtonElement> = {
    text: '',
    size: ButtonSize.Medium,
    accent: getThemeProps(DEFAULT_COLOR_THEME).noAccent,
    buttonTheme: ButtonTheme.NORMAL,
  };

  private readonly buttonRef: RefObject<HTMLButtonElement>;

  constructor(
    // tslint:disable-next-line
    props: RouteComponentProps<any> &
      IButtonProps &
      React.HTMLProps<HTMLButtonElement>
  ) {
    super(props);

    this.buttonRef = React.createRef();

    this.state = {
      isActive: false,
      isSuccess: false,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps: IButtonProps) {
    if (nextProps.isSuccess !== this.state.isSuccess) {
      this.setState({
        isSuccess: nextProps.isSuccess,
      });
    }
  }
  protected handleKeyDown = (e: React.KeyboardEvent<{}>) => {
    if (isKeyboardClick(e.which)) {
      e.preventDefault();
      if (e.which !== this.state.currentKeyDown) {
        this.setState({
          isActive: true,
          currentKeyDown: e.which,
        });
      }
    }
  };

  protected handleKeyUp = (e: React.KeyboardEvent<{}>) => {
    if (this.buttonRef && this.buttonRef.current && isKeyboardClick(e.which)) {
      this.setState({
        isActive: false,
        currentKeyDown: undefined,
      });

      this.buttonRef.current.click();
    }
  };

  // tslint:disable-next-line
  handleClick = (e: any) => {
    safeInvokeDeprecated(this.props.onClick, e);
  };

  renderIcon(iconComponent: IconType, isDisabled: boolean) {
    if (iconComponent === undefined || iconComponent === null) {
      return null;
    }

    const Icon = iconComponent;

    // width: ${(props: IIconRenderProps) => props.size === ButtonSize.Small ? '.75em' : props.size === ButtonSize.Normal ? '1em' : '1.25em'}; // 1.2em;
    // height: ${(props: IIconRenderProps) => props.size === ButtonSize.Small ? '.75em' : props.size === ButtonSize.Normal ? '1em' : '1.25em'}; // 1.2em;

    const small = '.75em';
    const normal = '1em';
    const large = '1.25em';
    const x =
      this.props.size === ButtonSize.Small
        ? small
        : this.props.size === ButtonSize.Medium
        ? normal
        : large;
    if (this.props?.theme?.accent1) {
      iconColor = this.props.theme.accent1.iconRedThemeColor;
    }
    return (
      <Icon
        fill={
          isDisabled
            ? Colors.GREYDISABLEDTEXT
            : this.props.buttonTheme === ButtonTheme.NORMAL
            ? this.props.iconColor
            : this.props.buttonTheme === ButtonTheme.RED
            ? iconColor ?? this.props.accent.iconColor
            : this.props.accent.textColor
        }
        width={x}
        height={x}
        style={{
          transition: 'fill .25s ease-in-out, color .25s ease-in-out',
        }}
      />
    );
  }

  // TODO: Fix Button ref for positioning tooltips and modals!

  render() {
    const {
      isLoading,
      isSuccess,
      disabled,
      tooltip,
      style,
      leftIcon,
      rightIcon,
      size,
      innerRef,
      accent,
      ...rest
    } = this.props;

    const mySize = size !== undefined ? size : ButtonSize.Medium;
    const butt = (
      <StyledButtonShell
        type={'button'}
        tabIndex={0}
        onClick={this.handleClick}
        onKeyDown={this.handleKeyDown}
        onKeyUp={this.handleKeyUp}
        ref={this.buttonRef}
        disabled={disabled}
        // isDisabled={disabled || false}
        style={style}
        // hasLeftIcon={leftIcon !== undefined && leftIcon !== null}
        // hasRightIcon={rightIcon !== undefined && rightIcon !== null}
        // size={mySize}
        {...removeNonHTMLProps(rest)}
      >
        <InnerButton
          buttonTheme={this.props.buttonTheme}
          greyBackground={this.props.greyBackground}
          accent={this.props.accent}
          tabIndex={-1}
          isDisabled={disabled || false}
          hasLeftIcon={leftIcon !== undefined && leftIcon !== null}
          hasRightIcon={rightIcon !== undefined && rightIcon !== null}
          size={mySize}
          style={this.props.innerButtonStyle}
          ref={innerRef}
        >
          {isLoading && !this.state.isSuccess && (
            <CenterWrapper tabIndex={-1}>
              <ButtonSpinner />
            </CenterWrapper>
          )}

          {!isLoading && this.state.isSuccess && (
            <CenterWrapper>
              <EmailSuccess width={'40'} height={'40'} color={Colors.WHITE} />
            </CenterWrapper>
          )}

          {!isLoading && !this.state.isSuccess && (
            <React.Fragment>
              {leftIcon && (
                <IconContainer isLeftIcon={true} size={mySize}>
                  {this.renderIcon(leftIcon, disabled || false)}
                </IconContainer>
              )}
              {!leftIcon && rightIcon && <div />}
              <TextWrapper
                buttonTheme={this.props.buttonTheme}
                accent={this.props.accent}
                style={this.props.textStyle}
                isDisabled={disabled || false}
                hasLeftIcon={leftIcon !== undefined && leftIcon !== null}
                hasRightIcon={rightIcon !== undefined && rightIcon !== null}
                size={mySize}
              >
                {this.props.text}
              </TextWrapper>
              {rightIcon && (
                <IconContainer isLeftIcon={false} size={mySize}>
                  {this.renderIcon(rightIcon, disabled || false)}
                </IconContainer>
              )}
              {!rightIcon && leftIcon && <div />}
            </React.Fragment>
          )}
        </InnerButton>
      </StyledButtonShell>
    );

    if (tooltip !== undefined) {
      return (
        <Tooltip content={tooltip}>
          <div
            style={{
              display: 'inline-block',
            }}
          >
            {butt}
          </div>
        </Tooltip>
      );
    } else {
      return butt;
    }
  }
}

export interface INavButtonProps {
  /**
   * set this to navigate to given internal URL when button is clicked
   * Magic url strings:
   * 'back' => go back
   * 'forward' => go forward
   */
  navigateTo?: string;

  /**
   * set to true if you want to open new tab
   */
  openInNewWindow?: boolean;
}

class NavButtonComp extends React.PureComponent<
  // tslint:disable-next-line
  RouteComponentProps<any> &
    INavButtonProps &
    IButtonProps &
    React.HTMLProps<HTMLButtonElement>,
  {}
> {
  // tslint:disable-next-line
  handleClickCheckNavProp = (e: any) => {
    if (this.props.navigateTo !== undefined && this.props.navigateTo !== null) {
      if (this.props.openInNewWindow === true) {
        // console.log('open in new tab');
        const win = window.open(this.props.navigateTo, '_blank');
        if (win) {
          win.focus();
        }
      } else {
        if (this.props.history) {
          if (this.props.navigateTo === 'back') {
            this.props.history.goBack();
          } else if (this.props.navigateTo === 'forward') {
            this.props.history.goForward();
          } else {
            this.props.history.push(this.props.navigateTo);
          }
        }
      }
    }

    safeInvokeDeprecated(this.props.onClick, e);
  };

  render() {
    const {
      onClick,
      text,
      isLoading,
      disabled,
      tooltip,
      style,
      leftIcon,
      rightIcon,
      innerButtonStyle,
      size,
      ...rest
    } = this.props;

    return (
      <ButtonComp
        innerButtonStyle={innerButtonStyle}
        text={text}
        isLoading={isLoading}
        disabled={disabled}
        tooltip={tooltip}
        style={style}
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        size={size}
        onClick={this.handleClickCheckNavProp}
        {...removeNonHTMLProps(rest)}
      />
    );
  }
}

export const NavButton = withRouter(withTheme(NavButtonComp));

// export const Button = React.forwardRef((props: IButtonProps & React.HTMLProps<HTMLButtonElement>, ref) => <ButtonComp ref={ref as any} {...props} />)

export const Button = withTheme(ButtonComp);
