import * as React from 'react';
import { CSSProperties } from 'react';

export interface IStaticImageProps {
  filename?: string;
  type?: IStaticImageType;
  width: number;
  height: number;
  style?: CSSProperties;
}

export enum IStaticImageType {
  ORGANIZATION = 'organization',
}

export class StaticImage extends React.PureComponent<IStaticImageProps> {
  render() {
    const { filename, type, width, height, style } = this.props;

    if (filename && type) {
      // const nodeEnv = process.env.NODE_ENV;
      //
      // let url = '';
      // if (nodeEnv === 'development') {
      //   url += 'https://beta.munikum.no';
      // }

      let url = 'https://hel1.your-objectstorage.com/munikum-public/static/images/' + type + '/' + filename;

      return (
        <img alt={''} style={style} src={url} width={width} height={height} />
      );
    } else {
      return null;
    }
  }
}
