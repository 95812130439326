import * as React from 'react';
import styled from 'styled-components';
import { Colors } from '../../common';
import { FaCircle } from 'react-icons/fa';
import { Tooltip } from '../Tooltip/Tooltip';
import { convertFromRaw, Editor, EditorState } from 'draft-js';

export interface IFileListProps {
  files: any;
  onClick?: (id: string) => void;
}

const FileLink = styled.div`
  color: ${Colors.RED};
  &:hover {
    cursor: pointer;
  }
`;

export class FileListResource extends React.PureComponent<
  {} & IFileListProps,
  {}
> {
  private apiUrl = process.env.REACT_APP_API;

  render() {
    const { files } = this.props;

    let fileList;
    if (files && files.length) {
      fileList = files.map(f => {
        if (f) {
          let contentEl = undefined;

          if (f.description !== undefined && f.description !== '') {
            const contentState = EditorState.createWithContent(
              convertFromRaw(JSON.parse(f.description))
            );

            contentEl = (
              <div>
                <Editor
                  editorState={contentState}
                  readOnly={true}
                  onChange={null}
                />
              </div>
            );
          }

          const fileEl = (
            <FileLink
              // onClick={getDownloadFile}
              onClick={() => {
                this.props.onClick(f.id);
              }}
              data-id={f.id}
              data-url={this.apiUrl}
              key={'res_' + f.id}
            >
              <FaCircle /> {f.name}
            </FileLink>
          );

          if (contentEl === undefined) {
            return fileEl;
          } else {
            return (
              <Tooltip
                position={'top-start'}
                content={contentEl}
                key={'ttip_' + f.id}
              >
                {fileEl}
              </Tooltip>
            );
          }
        }

        return null;
      });
    }

    return <div>{fileList}</div>;
  }
}
