import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { Dictionary, merge } from 'lodash';
import { IEntityState, IFullReload, ILastUpdatedParams } from './entities';
import { IAsyncActionCreators } from '../actionCreatorFactory';
import { IApiError } from '../../services/api';
import { IEntity } from '../../services/models/entity';

export interface IEntityReducerFactoryOptions<TEntity> {
  entityListActionCreator?: IAsyncActionCreators<
    Partial<ILastUpdatedParams> & Partial<IFullReload>,
    ReadonlyArray<TEntity>,
    IApiError
  >;
  entityItemActionCreator?: IAsyncActionCreators<IEntity, TEntity, IApiError>;
}

/**
 * HOC entity reducer factory
 * @param {AsyncActionCreators<{}, ReadonlyArray<TEntity extends IEntity>, IApiError>} entityListActionCreator - pass your redux-fsa async action-creator here
 * @param entityItemActionCreator (optional) actioncreator for single item
 * @returns {(state: IEntityState<TEntity extends IEntity>, action: Action) => IEntityState<TEntity extends IEntity>} - returns a reducer-function to handle reducing your entity in a generic way
 */
export function entityReducerFactory<TEntity extends IEntity>(
  options: IEntityReducerFactoryOptions<TEntity>
) {
  const defaultState: IEntityState<TEntity> = {
    byId: {},
    isFetchingList: false,
    isFetchingSingle: false,
  };

  return function entityReducer(
    state: IEntityState<TEntity> = defaultState,
    action: Action
  ): IEntityState<TEntity> {
    // if (action.type === REHYDRATE) {
    //   // const test = action.payload.entities
    //   // console.log('rehydrate entities');
    //   // note: we could handle special cases here?
    //   return state;
    // }

    // mini-workaround, FSA's isType crashed in HOC, but constant works..
    // if (isType (action, signoutSuccess)) {
    if (action.type === 'AUTH/SIGN_OUT_SUCCESS') {
      console.log('entities_clear');
      return defaultState;
    }

    if (options.entityListActionCreator) {
      if (isType(action, options.entityListActionCreator.started)) {
        // console.log('meta: ', action.meta);
        return {
          ...state,
          isFetchingList: true,
          errorList: undefined,
          // lastFetchStarted: action.payload.lastUpdated ? action.payload.lastUpdated : undefined
          lastFetchStarted: action.meta ? action.meta.actionCreated : undefined,
        };
      }
      if (isType(action, options.entityListActionCreator.success)) {
        // BUG: if result is EMPTY on PARTIAL update, we still update byId causing re-renders and stuff..: => FIXED

        const myDict: Dictionary<TEntity> = {};
        action.payload.result.map(item => {
          return (myDict[item.id] = item);
        });

        const isPartial = action.payload.params.lastUpdated !== undefined;
        const isFullReload = action.payload.params.isFullReload === true;

        // console.log(
        //   'entities_success. isPartial:' +
        //     isPartial +
        //     ' isFullReload: ' +
        //     isFullReload +
        //     ' willDoFullReload: ' +
        //     (isFullReload && !isPartial) +
        //     ' name:' +
        //     options.entityListActionCreator.type
        // );

        if (action.payload.result.length > 0) {
          return {
            ...state,
            isFetchingList: false,
            errorList: undefined,
            byId:
              isFullReload && !isPartial
                ? myDict
                : merge({}, state.byId, myDict),
            lastFetched: state.lastFetchStarted || undefined,
            lastFetchStarted: undefined,
          };
        } else {
          // console.log('empty result, skipping merge to avoid updating state by reference..');
          return {
            ...state,
            isFetchingList: false,
            errorList: undefined,
            // byId:
            lastFetched: state.lastFetchStarted || undefined,
            lastFetchStarted: undefined,
          };
        }
      }
      if (isType(action, options.entityListActionCreator.failed)) {
        return {
          ...state,
          isFetchingList: false,
          errorList: {
            message: action.payload.error.message,
            statusCode: action.payload.error.statusCode,
          },
          lastFetched: undefined,
          lastFetchStarted: undefined,
        };
      }
    }

    if (options.entityItemActionCreator) {
      if (isType(action, options.entityItemActionCreator.started)) {
        return {
          ...state,
          isFetchingSingle: true,
          errorSingle: undefined,
        };
      }
      if (isType(action, options.entityItemActionCreator.success)) {
        console.log('entityItem success', action);
        return {
          ...state,
          byId: merge({}, state.byId, {
            [action.payload.result.id]: action.payload.result,
          }),
          errorSingle: undefined,
          isFetchingSingle: false,
        };
      }
      if (isType(action, options.entityItemActionCreator.failed)) {
        return {
          ...state,
          errorSingle: {
            message: action.payload.error.message,
            statusCode: action.payload.error.statusCode,
          },
          isFetchingSingle: false,
        };
      }
    }

    return state;
  };
}
