import * as React from 'react';
import { CSSProperties } from 'react';
import gql from 'graphql-tag';
import Query from 'react-apollo/Query';
import {
  defineMessages,
  FormattedMessage,
  InjectedIntlProps,
  injectIntl,
} from 'react-intl';
import Helmet from 'react-helmet';
import { LinkWrapper } from '../../components/basic/Structural/links';
import { MunikumIcons } from '../../components/common/icons';
import Tour from 'reactour';
import { INewsItem } from './DashboardPage';
import { commonMessages } from '../../components/language/commonMessages';
import { PageTitle } from '../../components/layout/Page/Page';
import { DashboardBox2 } from '../../components/dashboard/DashboardBox2';
import {
  ContainerDashboard,
  GET_ME_FOR_DASHBOARD,
  IconDiv,
  MainBox,
  OuterContainer,
} from './AnnualCycleDashboard';
import { Button } from '../../components/basic';
import { RootState } from '../../modules/rootReducer';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  filterTopicActionCreator,
  ITopicFilter,
  resetTopicsFilterActionCreator,
} from '../../services/topicService';
import { safeInvoke } from '../../components/common';
import { SuspenseLoader } from '../../components/basic/Loader/CircleSpinner';

const messages = defineMessages({
  whatDoYouWantToDo: {
    id: 'topic.whatDoYouWantToDo',
    defaultMessage: 'What do you want To do?',
  },
  startNewTopic: {
    id: 'topic.startNewTopic',
    defaultMessage: 'Create a new topic',
  },
  myTopics: {
    id: 'topic.myTopics',
    defaultMessage: 'My Topics',
  },
  myTopicsText: {
    id: 'topic.myTopicsText',
    defaultMessage: 'Here is a list for the topics you create and follow',
  },
  myMunicipalityBody: {
    id: 'topic.myMunicipalityBody',
    defaultMessage:
      'Here is a list for the topics created in your municipality',
  },

  startNewTopicText: {
    id: 'topic.startNewTopicText',
    defaultMessage: 'Create a new topic',
  },
  explore: {
    id: 'topic.explore',
    defaultMessage: 'Explore',
  },
  exploreText: {
    id: 'topic.exploreText',
    defaultMessage:
      'Link between experience exchange, grant, document and data for your municipality.',
  },
  news: {
    id: 'topic.news',
    defaultMessage: 'News',
  },
  recomended: {
    id: 'topic.recomended',
    defaultMessage: 'Recomended',
  },
  yourFollowed: {
    id: 'topic.yourFollowed',
    defaultMessage: 'Your followed',
  },
  TopicDashboardTourStep7: {
    id: 'AnnualCycleDashboard.TopicDashboardTourStep7',
    defaultMessage: 'Do you want to repeat the steps?',
  },
});
export const MY_FOLLOWED_TOPICS = gql`
  query MyFollowedTopics {
    myFollowed {
      topics {
        id
        isFollowing
        lastUpdated
      }
    }
  }
`;
export const GET_TOPICS_FOR_DASHBOARD = gql`
  query GetTopics($input: AllTopicsInput!) {
    allTopics(input: $input) {
      nodes {
        id
        uri
        lastUpdated
        title
        dateCreated
        isFollowing
      }
    }
    me {
      id
      organization {
        id
        name
        organizationImage
      }
    }
  }
`;
export const FOLLOW_TOPIC = gql`
  mutation FollowTopic($id: ID!) {
    follow(id: $id)
  }
`;
export const UNFOLLOW_TOPIC = gql`
  mutation UnfollowTopic($id: ID!) {
    unFollow(id: $id)
  }
`;

export const breakPoint = '1700px';
interface ITopicDashboardProps {
  style?: CSSProperties;
  list?: Array<any>;
  onClick?: (uri: string) => void;
  listFilter: ITopicFilter;
}
interface ITopicDashboardState {
  items: Array<INewsItem>;
  isTourOpen: boolean;
}
interface ITopicDashboardDispatch {
  setPredefinedOrg: (filter: ITopicFilter) => void;
  resetFilter: () => void;
}
export class TopicDashboard extends React.PureComponent<
  ITopicDashboardProps & ITopicDashboardDispatch & InjectedIntlProps,
  ITopicDashboardState
> {
  constructor(
    props: ITopicDashboardDispatch & ITopicDashboardProps & InjectedIntlProps
  ) {
    super(props);
    this.state = {
      items: [],
      isTourOpen: false,
    };
  }

  render() {
    const { intl } = this.props;

    const steps = [
      {
        position: 'right',
        selector: '[data-tut="reactour__step1"]',
        content: (
          <span style={{ color: '#000' }}>
            <FormattedMessage
              id={'topicDashboard.TopicDashboardTourStep1'}
              defaultMessage={
                'Here you will find an overview of topics you have created or have been given access to from others. Topics that you choose to follow will also be included in the list of your topics'
              }
              values={{
                br: <br />,
              }}
            />
          </span>
        ),
      },
      {
        position: 'right',

        selector: '[data-tut="reactour__step2"]',
        content: (
          <span style={{ color: '#000' }}>
            <FormattedMessage
              id={'topicDashboard.TopicDashboardTourStep2'}
              defaultMessage={
                ' Here you can see topics that other people has creates and been made public for you. {br} You can filtere on kostraFuctions, copy or share the topics with others.You can follow the topics and get notifications about changes.'
              }
              values={{
                br: <br />,
              }}
            />
          </span>
        ),
      },
      {
        position: 'right',
        selector: '[data-tut="reactour__step3"]',
        content: (
          <span style={{ color: '#000' }}>
            <FormattedMessage
              id={'topicDashboard.TopicDashboardTourStep3'}
              defaultMessage={
                'Here you can see which topics have been created and published in your municipality. Are there any topics for your municipality that you should follow?'
              }
              values={{
                br: <br />,
              }}
            />
          </span>
        ),
      },
      {
        position: 'right',
        selector: '[data-tut="reactour__step4"]',
        content: (
          <span style={{ color: '#000' }}>
            <FormattedMessage
              id={'topicDashboard.TopicDashboardTourStep4'}
              defaultMessage={
                'Here you can create topics, and add others that may or may not have read or write access to your topic.'
              }
              values={{
                br: <br />,
              }}
            />
          </span>
        ),
      },

      {
        position: 'right',
        selector: '[data-tut="reactour__step5"]',
        content: (
          <span style={{ color: '#000' }}>
            <FormattedMessage
              id={'topicDashboard.TopicDashboardTourStep5'}
              defaultMessage={
                'The activity log gives you an overview over the last updated topics wich you follow or has write permission to.'
              }
              values={{
                br: <br />,
              }}
            />
          </span>
        ),
      },
      {
        position: 'right',
        selector: '[data-tut="reactour__step6"]',
        content: (
          <span style={{ color: '#000' }}>
            <FormattedMessage
              id={'topicDashboard.TopicDashboardTourStep6'}
              defaultMessage={
                'We at the Munikum team will make you aware of news related to the topic. There may be new features or user stories that show different ways of using groups as tools.'
              }
              values={{
                br: <br />,
              }}
            />
          </span>
        ),
      },
      {
        selector: '[data-tut="reactour__goTo"]',
        content: ({ goTo }) => (
          <div style={{ color: '#000' }}>
            {intl.formatMessage(commonMessages.doYouWantToRepeatTheSteps)}
            <div />
            <Button
              style={{ marginTop: '1em' }}
              onClick={() => goTo(0)}
              text={intl.formatMessage(commonMessages.repeat)}
            />
          </div>
        ),
      },
    ];
    return (
      <Query query={GET_ME_FOR_DASHBOARD}>
        {({ loading, error, data }) => {
          if (error) {
            throw error;
          }
          if (loading) {
            return <SuspenseLoader />;
          }
          let myOrg =
            data && data.me && data.me.organization && data.me.organization;
          return (
            <>
              <Helmet>
                <title>{intl.formatMessage(commonMessages.topic)}</title>
              </Helmet>
              <OuterContainer>
                <div style={{ height: '2em' }} />

                <ContainerDashboard>
                  <MainBox style={{ margin: '4px' }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '1em',
                      }}
                    >
                      <IconDiv>
                        <MunikumIcons.Info
                          style={{
                            color: '#FF5C39',
                            width: '1.4em',
                            height: '1.4em',
                            marginRight: '.5em',
                          }}
                          onClick={() => {
                            this.setState({
                              isTourOpen: true,
                            });
                          }}
                        />
                      </IconDiv>

                      <PageTitle style={{}}>
                        {intl.formatMessage(commonMessages.topic)}
                      </PageTitle>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <LinkWrapper
                        data-tut="reactour__step1"
                        to={'/topic/list'}
                      >
                        <DashboardBox2
                          onClick={() => {
                            safeInvoke(this.props.setPredefinedOrg, {
                              ...this.props.listFilter,
                              organizations: [],
                              showOnlyMine: true,
                              predefinedOrg: false,
                            });
                          }}
                          title={intl.formatMessage(messages.myTopics)}
                          body={intl.formatMessage(messages.myTopicsText)}
                          icon={MunikumIcons.Topic}
                          isActive={true}
                          style={{
                            backgroundColor: '#00778B',
                          }}
                        />
                      </LinkWrapper>
                      <LinkWrapper
                        to={'/topic/list'}
                        data-tut="reactour__step2"
                      >
                        <DashboardBox2
                          onClick={() => {
                            safeInvoke(this.props.resetFilter);
                          }}
                          title={intl.formatMessage(messages.explore)}
                          body={intl.formatMessage(messages.exploreText)}
                          icon={MunikumIcons.Search}
                          isActive={true}
                          style={{
                            backgroundColor: '#00778B',
                          }}
                        />
                      </LinkWrapper>
                      <LinkWrapper
                        to={'/topic/list'}
                        data-tut="reactour__step3"
                      >
                        <DashboardBox2
                          onClick={() => {
                            safeInvoke(this.props.setPredefinedOrg, {
                              ...this.props.listFilter,
                              showOnlyMine: false,
                              predefinedOrg: true,
                              persons: [],
                              organizations: [myOrg],
                            });
                          }}
                          title={intl.formatMessage(
                            commonMessages.mymunicipality
                          )}
                          body={intl.formatMessage(messages.myMunicipalityBody)}
                          icon={MunikumIcons.KostraEducation}
                          isActive={true}
                          style={{
                            backgroundColor: '#00778B',
                          }}
                        />
                      </LinkWrapper>
                      <LinkWrapper
                        data-tut="reactour__step4"
                        style={{ alignSelf: 'flex-start' }}
                        to={'/topic/create'}
                      >
                        <DashboardBox2
                          onClick={() => {
                            safeInvoke(this.props.resetFilter);
                          }}
                          title={intl.formatMessage(messages.startNewTopic)}
                          body={intl.formatMessage(messages.startNewTopicText)}
                          icon={MunikumIcons.Add}
                          isActive={true}
                          style={{
                            backgroundColor: ' #00B0B9',
                          }}
                        />
                      </LinkWrapper>
                    </div>
                  </MainBox>
                </ContainerDashboard>
              </OuterContainer>
              <Tour
                steps={steps}
                isOpen={this.state.isTourOpen}
                onRequestClose={() => {
                  this.setState({ isTourOpen: false });
                }}
                maskClassName="mask"
                className="helper"
                rounded={5}
                accentColor={'#5cb7b7'}
              />
            </>
          );
        }}
      </Query>
    );
  }
}
const mapStateToProps = (
  state: RootState,
  ownProps: ITopicDashboardProps
): ITopicDashboardProps => ({
  listFilter: state.topicFilter.filter,
});
const mapDispatchToProps = (
  dispatch: Dispatch<{}>
): ITopicDashboardDispatch => ({
  setPredefinedOrg: (filter: ITopicFilter) => {
    dispatch(filterTopicActionCreator({ filter: filter }));
  },
  resetFilter: () => {
    dispatch(resetTopicsFilterActionCreator({}));
  },
});

export const TopicDashboardComp = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(TopicDashboard));

export default TopicDashboardComp;
