import * as _ from 'lodash';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { call, put, take } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import {
  checkNotifications,
  getNotificationList,
  getNotificationsInfo,
  ISendNotification,
  readNotification,
  sendNotification,
} from '../../services/notificationService';
import { INotification } from '../../services/models/notification';
import { IApiError } from '../../services/api';
import { actionCreatorFactory } from '../actionCreatorFactory';
import { RootState } from '../rootReducer';
import { signinSuccess, signoutSuccess } from '../auth/auth';
import { myStore, socket } from '../../index';
import { sleep } from '../../components/common';
import { getBuildNumber } from '../../components/layout/VersionNumber/VersionNumber';
import { updateServiceWorker } from '../ui/serviceWorker/serviceWorkerRedux';

const actionCreator = actionCreatorFactory('NOTIFICATION');

export const sendNotificationActionCreator = actionCreator<ISendNotification>(
  'SEND_NOTIFICATION'
);

export const getNotificationsInfoActionCreator = actionCreator.async<
  {},
  { date: Date },
  IApiError
>('GET_NOTIFICATIONS_INFO');

export const checkNotificationsActionCreator = actionCreator.async<
  {},
  { date: Date },
  IApiError
>('CHECK_NOTIFICATIONS');

export const readNotificationActionCreator = actionCreator.async<
  { id: string },
  {},
  IApiError
>('READ_NOTIFICATION');

export const getAllNotificationsActionCreator = actionCreator<
  Array<INotification>
>('GET_ALL_NOTIFICATIONS');

export const newNotificationActionCreator = actionCreator<INotification>(
  'NEW_NOTIFICATION'
);

export const metaUpdatedActionCreator = actionCreator<string>('META_UPDATED');

export const metaUpdatedClearActionCreator = actionCreator<string>(
  'META_UPDATED_CLEAR'
);

// TODO: we need redux actions to mark notifications as beenRead=true

// NOTE: we dont need this since we have polling?:
// export const loadNotificationList = actionCreator<{}>('LOAD');
export interface INotificationState {
  isGettingLastCheck: boolean;
  isChecking: boolean;
  lastCheck: Date;
  notifications: Array<INotification>;
  updatedMetas: Array<string>;
}

const initialState: INotificationState = {
  isGettingLastCheck: false,
  isChecking: false,
  lastCheck: new Date('0'),
  notifications: [],
  updatedMetas: [],
};

export const reducer = (
  state: INotificationState = initialState,
  action: Action
): INotificationState => {
  if (isType(action, getNotificationsInfoActionCreator.started)) {
    return {
      ...state,
      isGettingLastCheck: true,
    };
  }
  if (isType(action, getNotificationsInfoActionCreator.success)) {
    return {
      ...state,
      lastCheck: action.payload.result.date,
      isGettingLastCheck: false,
    };
  }
  if (isType(action, getNotificationsInfoActionCreator.failed)) {
    return {
      ...state,
      isGettingLastCheck: false,
    };
  }

  if (isType(action, checkNotificationsActionCreator.started)) {
    return {
      ...state,
      isChecking: true,
    };
  }
  if (isType(action, checkNotificationsActionCreator.success)) {
    return {
      ...state,
      lastCheck: action.payload.result.date,
      isChecking: false,
    };
  }
  if (isType(action, checkNotificationsActionCreator.failed)) {
    return {
      ...state,
      isChecking: false,
    };
  }
  if (isType(action, readNotificationActionCreator.success)) {
    let notifications: Array<INotification> = state.notifications;

    for (let n of notifications) {
      if (n.id === action.payload.params.id) {
        n.beenRead = true;
        break;
      }
    }

    return {
      ...state,
      notifications: notifications,
    };
  }
  if (isType(action, newNotificationActionCreator)) {
    return {
      ...state,
      notifications: [action.payload, ...state.notifications],
    };
  }
  if (isType(action, getAllNotificationsActionCreator)) {
    return {
      ...state,
      notifications: action.payload,
    };
  }

  if (isType(action, metaUpdatedActionCreator)) {
    if (state.updatedMetas.indexOf(action.payload) > -1) {
      return state;
    } else {
      return {
        ...state,
        updatedMetas: [action.payload, ...state.updatedMetas],
      };
    }
  }
  if (isType(action, metaUpdatedClearActionCreator)) {
    return {
      ...state,
      updatedMetas: [],
    };
  }

  return state;
};
//
// export function* loadNotificationListSaga() {
//   while (true) {
//     const action = yield take(loadNotificationList.type);
//     console.log('-------->> NOTIFICATIONS <<----------');
//
//     yield call(getNotificationList); // fixme
//
//     // test allow 5 minute old data
//     yield loadEntity({
//       actionCreator: getNotificationsActionCreator,
//       entityKey: 'notifications',
//       entityPartialUpdateIntervalInSeconds: 1,
//       isAdminRequired: false,
//     });
//   }
// }

// yield fork(
//   entitySagaFactory<IMunicipality>(
//     getMunicipalitiesActionCreator,
//     getMunicipalityList,
//     getMunicipalityActionCreator,
//     getMunicipality
//   )
// );

// TODO: later, implement generic Saga HOC, keep it DRY: const notificationsPollingSaga = entitySagaFactory()

//
// function* pollSagaWorker() {
//   while (true) {
//     try {
//       // loadEntity takes care of partial updates etc.
//       yield loadEntity({
//         actionCreator: getNotificationsActionCreator,
//         entityKey: 'notifications',
//         entityPartialUpdateIntervalInSeconds: 5,
//         entityFullUpdateIntervalInSeconds: EntityUpdateInterval.OneHour,
//         isAdminRequired: false,
//       });
//
//       yield call(delay, 1000 * 60); // 10 seconds delay?
//     } catch (err) {
//       console.log('err', err);
//     }
//   }
// }
//
// /**
//  * Saga watcher.
//  * TODO: rewrite to generic HOC and put in sagaFactory for reuse? =)
//  */
// export function* pollNotificationsSaga() {
//   while (true) {
//     yield take(startPollingNotificationsActionCreator.type);
//     yield race({
//       poll: call(pollSagaWorker),
//       stop: take(stopPollingNotificationsActionCreator.type),
//     });
//   }
// }
//
// export function* initPollNotificationsSaga() {
//   console.log('start polling notifications');
//   yield put(startPollingNotificationsActionCreator({}));
// }

export const lastCheckedSelector = (state: RootState) =>
  state.notifications.lastCheck;

// example nesting / chaining reselect selectors
export const notificationUnreadCountSelector = createSelector(
  (state: RootState) => state.notifications.notifications,
  lastCheckedSelector,
  (notifications: ReadonlyArray<INotification>, lastChecked: Date) => {
    const unread = _.filter(notifications, (item: INotification) => {
      return item.dateCreated > lastChecked;
    });

    // this recalculation only takes place when the result from notificationListSelector changes!
    // the alternative would be to recalculate each time MainLayout called render()..
    // console.log(
    //   'reselect calculating unread count. total=' +
    //     notifications.length +
    //     ' unread=' +
    //     unread.length
    // );
    return unread.length;
  }
);

export function* sendNotificationSaga() {
  while (true) {
    const action = yield take(sendNotificationActionCreator.type);

    try {
      const result = yield call(sendNotification, action.payload);
      console.log('send ok:' + result);
      if (!result) {
        throw Error('send failed');
      }
    } catch (e) {
      let tempError: { message: string; statusCode?: number } = {
        message: e.message,
      };
      if (e.hasOwnProperty('statusCode')) {
        tempError.statusCode = e.statusCode;
      }
      console.log('error sending:', tempError, e);
    }
  }
}

export function* getNotificationsInfoSaga() {
  while (true) {
    const action = yield take(getNotificationsInfoActionCreator.started.type);

    try {
      const result = yield call(getNotificationsInfo, action.payload);
      if (!result) {
        throw Error('send failed');
      }
      yield put(
        getNotificationsInfoActionCreator.success({
          params: action.payload,
          result: result,
        })
      );
    } catch (e) {
      let tempError: { message: string; statusCode?: number } = {
        message: e.message,
      };
      if (e.hasOwnProperty('statusCode')) {
        tempError.statusCode = e.statusCode;
      }
      console.log('error sending:', tempError, e);
      yield put(
        getNotificationsInfoActionCreator.failed({
          params: action.payload,
          error: tempError,
        })
      );
    }
  }
}

export function* checkNotificationsSaga() {
  while (true) {
    const action = yield take(checkNotificationsActionCreator.started.type);

    try {
      const result = yield call(checkNotifications, action.payload);
      if (!result) {
        throw Error('send failed');
      }
      yield put(
        checkNotificationsActionCreator.success({
          params: action.payload,
          result: result,
        })
      );
    } catch (e) {
      let tempError: { message: string; statusCode?: number } = {
        message: e.message,
      };
      if (e.hasOwnProperty('statusCode')) {
        tempError.statusCode = e.statusCode;
      }
      console.log('error sending:', tempError, e);
      yield put(
        checkNotificationsActionCreator.failed({
          params: action.payload,
          error: tempError,
        })
      );
    }
  }
}

export function* checkForUpdateSaga() {
  if (process.env.NODE_ENV === 'development') {
    return;
  }
  const currentVersion: String = getBuildNumber();
  while (true) {
    let versionFile = yield fetch('/app/version');
    let serverVersion: String = yield versionFile.text();
    serverVersion = serverVersion.trim();
    if (currentVersion !== serverVersion) {
      yield put(updateServiceWorker(''));
      return;
    }
    yield sleep(5 * 60 * 1000);
  }
}

export function* readNotificationSaga() {
  while (true) {
    const action = yield take(readNotificationActionCreator.started.type);

    try {
      const result = yield call(readNotification, action.payload.id);
      if (!result) {
        throw Error('send failed');
      }
      // yield put(
      //   getNotificationActionCreator.started({
      //     id: action.payload.id,
      //   })
      // );
      yield put(
        readNotificationActionCreator.success({
          params: action.payload,
          result: result,
        })
      );
    } catch (e) {
      let tempError: { message: string; statusCode?: number } = {
        message: e.message,
      };
      if (e.hasOwnProperty('statusCode')) {
        tempError.statusCode = e.statusCode;
      }
      console.log('error sending:', tempError, e);
      yield put(
        readNotificationActionCreator.failed({
          params: action.payload,
          error: tempError,
        })
      );
    }
  }
}

export function* websocketSaga() {
  while (true) {
    const action = yield take(signinSuccess);

    const notifications = yield getNotificationList();
    yield put(getAllNotificationsActionCreator(notifications));

    const userId = action.payload.profile.hash;

    try {
      socket.emit(
        'login',
        {
          token: action.payload.refresh_token,
        },
        function(err: any) {
          // This callback handles the response from the server.
          // If we wanted, we could have listened to a separate 'loginResponse'
          // event, but this pattern of passing a callback like this
          // is slightly more efficient.

          if (err) {
            // showLoginError(err);
            // console.log('websocket error');
          } else {
            // goToMainScreen();
            // console.log('Websocket connection logged in');
          }
        }
      );

      if (process.env.NODE_ENV === 'development') {
        socket.on('connect', function() {
          console.log('CONNECTED');
        });

        socket.on('subscribe', function(channelName: string) {
          console.log('subscribe:' + channelName);
        });

        socket.on('subscribeFail', function(channelName: string) {
          console.log('subscribeFail:' + channelName);
        });

        socket.on('unsubscribe', function(channelName: string) {
          console.log('unsubscribe:' + channelName);
        });

        socket.on('subscribeStateChange', function(data: string) {
          console.log('subscribeStateChange:' + JSON.stringify(data));
        });

        socket.on('message', function(data: string) {
          if (data.startsWith('#')) {
            return;
          }
          console.log('message:' + data);
        });
      }

      socket.on('message', function(data: string) {
        handleWebsocketMessage(data, userId);
      });

      socket.subscribe(action.payload.profile.channel, {
        waitForAuth: true,
      });

      while (true) {
        yield take(signoutSuccess);
        socket.disconnect();
      }
    } catch (e) {
      console.log(e);
    }
  }

  function handleWebsocketMessage(data: string, userId: string) {
    if (data.startsWith('#')) {
      return;
    }

    let json = JSON.parse(data);

    if (json.event !== '#publish' || !json.data || !json.data.data) {
      return;
    }

    let payload = json.data.data;

    switch (payload.type) {
      case 'NOTIFICATION':
        let notification = payload.notification;

        let n = new Notification('Munikum', {
          body: notification.message,
          icon: '/favicon.ico',
        });

        if (notification.href && notification.hrefType) {
          let uri = '';
          switch (notification.hrefType) {
            case 'TOPIC':
              uri = '/topic/';
              break;
            case 'DISCUSSION':
              uri = '/forum/public/';
              break;
            case 'ACTION_VALUE':
              uri = '/action-value/';
              break;
            case 'CALENDAR':
            case 'TASK':
            case 'EVENT':
            case 'EVENT_INSTANCE':
              uri = '/calendar/';
              break;
            default:
          }
          n.onclick = function(event: Event) {
            event.preventDefault();
            window.open('/app' + uri + notification.href);
          };
        }
        if (process.env.NODE_ENV === 'development') {
          console.log(n);
        }

        myStore.dispatch(
          newNotificationActionCreator({
            id: notification.id,
            dateCreated: notification.dateCreated,
            message: notification.message,
            beenRead: notification.beenRead,
            href: notification.href,
            hrefType: notification.hrefType,
          })
        );
        break;
      case 'META_UPDATE':
        if (payload.person === userId) {
          return;
        }
        myStore.dispatch(metaUpdatedActionCreator(payload.id));
        break;
      default:
        break;
    }
  }
}
