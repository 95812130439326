import { createHttpLink } from 'apollo-link-http';
import ApolloClient, { Resolvers } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { CachePersistor } from 'apollo-cache-persist';
import { ApolloCache } from 'apollo-cache';
import {
  APOLLO_CACHE_KEY,
  forceClearApolloPersistedCache,
  getCacheAppVersion,
  storeCacheAppVersion,
} from '../services/localStorageService';
import { getBuildNumber } from '../components/layout/VersionNumber/VersionNumber';
import { getAccessTokenFromStore } from '../services/api';

let url = 'http://localhost:8080/graphql';
if (process && process.env && process.env.REACT_APP_API) {
  url = process.env.REACT_APP_API + '/graphql';
}

const typeDefs = require('./munikum-local.graphqls');
// console.log('graphql url:' + url);

const httpLink = createHttpLink({
  uri: url,
  headers: {
    Pragma: 'no-cache',
  },
});

// acts as a middleware in apollo client :-)
const authLink = setContext((_, { headers }) => {
  const token = getAccessTokenFromStore();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors && graphQLErrors.length > 0) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const myCache = new InMemoryCache({
  addTypename: true,
});

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const myResolvers: Resolvers[] = [
  {
    Test: {
      id: (rootValue?: any, args?: any, context?: any, info?: any) => '123q',
      text: (rootValue?: any, args?: any, context?: any, info?: any) => {
        console.log('RESOVLE');
        return 'text';
      },
      completed: (rootValue?: any, args?: any, context?: any, info?: any) =>
        false,
    },
  },
];

export let myApolloClient: ApolloClient<any>;
export let myApolloPersistor: any;

export async function clearApolloCache(): Promise<void> {
  try {
    await sleep(200);
    if (myApolloPersistor) {
      // console.log('pause persistor..');
      await myApolloPersistor.pause();
      // console.log('purge persistor..');
      await myApolloPersistor.purge();
      await sleep(200);
    }

    // console.log('force clear apollo cache local storage...');
    await forceClearApolloPersistedCache();
    await sleep(200);
  } catch (e) {
    console.log('purge apollo cache failed', e);
  } finally {
    if (myApolloPersistor) {
      try {
        // console.log('resuming persistor..');
        await myApolloPersistor.resume();
      } catch (e2) {
        console.log(e2);
      }
    }
  }

  try {
    const runningAppVersion = await getRunningAppVersion();
    await storeCacheAppVersion(runningAppVersion);
    console.log(
      'Successfully upgraded cache to new app-version: ' + runningAppVersion
    );
  } catch (e) {
    console.log(e, 'error');
  }
  return Promise.resolve();
}

function getRunningAppVersion() {
  let runningAppVersion = getBuildNumber();

  if (process.env.NODE_ENV === 'development') {
    runningAppVersion = runningAppVersion + '79'; // use this to test purging stored cache
  }

  return runningAppVersion;
}

export async function initApolloClient(): Promise<{
  myApolloClient: ApolloClient<any>;
  myCache: ApolloCache<any>;
  myApolloPersistor: any;
}> {
  // console.log('init apollo client');

  const per = new CachePersistor({
    cache: myCache,
    storage: window.localStorage, // TODO: use localforage
    key: APOLLO_CACHE_KEY,
    maxSize: 1048576 * 2, // 2MB max is ok?
    debug: false, // set this to true to debug persitor
    serialize: true,
  });

  const runningAppVersion = await getRunningAppVersion();
  const persistedCacheAppVersion = await getCacheAppVersion();

  if (persistedCacheAppVersion === runningAppVersion) {
    // console.log('apollo persisted cache has same version, restoring');
    try {
      await per.restore();
      // console.log('apollo cache restored ok');
    } catch (e) {
      console.log('restore apollo cache failed');
    }
  } else {
    console.log(
      'Persisted cache version ' +
        persistedCacheAppVersion +
        ' does not match current version ' +
        runningAppVersion +
        '. Purging cache!'
    );
    await clearApolloCache();
  }

  myApolloClient = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, httpLink]), // removed: myStateLink !
    cache: myCache,

    connectToDevTools: true,
    resolvers: myResolvers, // new passing resolvers and typedefs here!
    typeDefs: typeDefs,
    // version: '1.1.0' // TODO: check if we can use this for invalidating cache?
  });

  myCache.writeData({
    data: {
      isLoggedIn: false,
      cartItems: [],
    },
  });

  // myCache.writeData<IMunikumLocalState>({id: '_munikumLocal', data: {
  //   fastCalendar: {
  //     'magic': {
  //       isFetching: false,
  //       calendarId: 'magic',
  //       __typename: 'fastCalendar'
  //     }
  //
  //   }
  // }});

  myApolloPersistor = per;

  return {
    myApolloClient: myApolloClient,
    myCache: myCache,
    myApolloPersistor: myApolloPersistor,
  };
}
