import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import sadMunin from './munin-sad.svg';
import happyMunin from './munin-happy.svg';
import sadMunin2 from './munin-sad2.svg';
import { ApolloError } from 'apollo-client';
import { FormattedMessage } from 'react-intl';
import { Colors, ColorTheme, safeInvokeDeprecated } from '../../common';
import { Button, ButtonSize } from '..';
import { Overlay } from '../Overlay/Overlay';
import { sizes } from '../../common/media';

export enum ThemeColor {
  SUCCESS_BLUE = 'SUCCESS_BLUE',
  SUCCESS_WHITE = 'SUCCESS_WHITE',

  ERROR_RED = 'ERROR_RED',
  ERROR_BLACK = 'ERROR_BLACK',
  ERROR_WHITE = 'ERROR_WHITE',
}

const myFunc = (color: ThemeColor): IStyleProps => {
  switch (color) {
    case ThemeColor.ERROR_BLACK:
      return {
        background: Colors.BLACK,
        footer: Colors.RED,
        button: ColorTheme.White,
        text: Colors.WHITE,
        img: sadMunin2,
      };
    case ThemeColor.ERROR_RED:
      return {
        background: Colors.RED,
        footer: Colors.BLACK,
        button: ColorTheme.White,
        text: Colors.WHITE,
        img: sadMunin,
      };
    case ThemeColor.ERROR_WHITE:
      return {
        background: Colors.WHITE,
        footer: Colors.RED,
        button: ColorTheme.Red,
        text: Colors.BLACK,
        img: sadMunin2,
      };
    case ThemeColor.SUCCESS_WHITE:
      return {
        background: Colors.WHITE,
        footer: Colors.BLUE,
        button: ColorTheme.Blue,
        text: Colors.BLACK,
        img: happyMunin,
      };

    case ThemeColor.SUCCESS_BLUE:
      return {
        background: Colors.BLUE,
        footer: Colors.BLACK,
        button: ColorTheme.White,
        text: Colors.WHITE,
        img: happyMunin,
      };

    default:
      return {
        background: Colors.BLACK,
        footer: Colors.RED,
        button: ColorTheme.White,
        text: Colors.WHITE,
        img: sadMunin2,
      };
  }
};

const ImgContainer = styled.div`
  display: flex;
  z-index: 1;
  justify-content: center;
  margin-bottom: -1.2em;
`;

const OuterDiv = styled.div`
  height: 535px;
  width: 483px;

  border-radius: 3px;

  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  background-color: ${(props: ISuccessMessageRenderProps) =>
    myFunc(props.theme).background};

  display: flex;
  align-items: center;
  flex-direction: column;
`;
const TitleDiv = styled.div`
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 52px;
`;

const Title = styled.div`
  color: ${(props: ISuccessMessageRenderProps) => myFunc(props.theme).text}
  font-family: Lato, sans serif;
	font-size: 32px;
	font-weight: bold;
`;

const Footer = styled.div`
  background-color: ${(props: ISuccessMessageRenderProps) =>
    myFunc(props.theme).footer}
  height: 14px;
  width: 100%;
`;

const BodyTextDiv = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
	height: 72px;
	width: 368px;
	  	
  color: ${(props: ISuccessMessageRenderProps) => myFunc(props.theme).text}
	font-family: Lato, sans-serif;
	font-size: 16px;
	text-align: center;
	margin-top: 30px;
	
	display:flex;
	justify-content: center;
	align-items:center;
`;

const MiddleContainer = styled.div`
  margin-top: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 48px;
`;

const ButtonCont = styled.div`
  display: flex;
  alignitems: center;
  justify-content: space-between;
`;
const Button2 = styled.div`
  display: ${(props: { isSecondButtonVisible: boolean }) =>
    props.isSecondButtonVisible ? 'flex' : 'none'};
  margin-left: 1em;
`;

interface IStatusMessageProps {
  theme?: ThemeColor;
  title: string;
  text: string | JSX.Element | ApolloError;
  style?: CSSProperties;

  buttonText?: string;
  buttonText2?: string;
  onClickButton?: () => void;
  onClickButton2?: () => void;

  /**
   * thomas added quick footer prop when I needed 3 buttons in footer =)
   */
  footer?: string | JSX.Element;
}

interface ISuccessMessageRenderProps {
  theme: ThemeColor;
}

interface IStyleProps {
  background?: string;
  footer?: string;
  button?: ColorTheme;
  text?: string;
  img?: string;
}

export class StatusMessage extends React.PureComponent<IStatusMessageProps> {
  render() {
    const myTheme = this.props.theme || ThemeColor.ERROR_BLACK;
    const myStyle = myFunc(myTheme);

    let errToDisplay: string | JSX.Element = '';

    if (this.props.text instanceof ApolloError) {
      const err: ApolloError = this.props.text;

      errToDisplay = (
        <FormattedMessage
          id={'StatusMessage.apolloError'}
          defaultMessage={'An error occurred when fetching data:{br}{message}'}
          values={{
            br: <br />,
            message: err.message,
          }}
        />
      );
    } else {
      errToDisplay = this.props.text;
    }

    return (
      <OuterDiv theme={this.props.theme} style={this.props.style}>
        <TitleDiv>
          <Title theme={this.props.theme}>{this.props.title}</Title>
        </TitleDiv>
        <BodyTextDiv theme={this.props.theme}>
          <div>{errToDisplay}</div>
        </BodyTextDiv>
        <MiddleContainer>
          <ImgContainer>
            <img height={'163px'} width={'166px'} src={myStyle.img} alt={''} />
          </ImgContainer>
          <Footer theme={this.props.theme} />
        </MiddleContainer>
        <ButtonCont>
          {this.props.buttonText && (
            <Button
              size={ButtonSize.Large}
              theme={myStyle.button}
              text={this.props.buttonText}
              onClick={() => safeInvokeDeprecated(this.props.onClickButton)}
            />
          )}
          <Button2 isSecondButtonVisible={this.props.buttonText2 !== undefined}>
            <Button
              size={ButtonSize.Large}
              theme={myStyle.button}
              text={this.props.buttonText2 || ''}
              onClick={() => safeInvokeDeprecated(this.props.onClickButton2)}
            />
          </Button2>

          {this.props.footer}
        </ButtonCont>
      </OuterDiv>
    );
  }
}

interface IDialogState {
  isOpen: boolean;
}

export class StatusMessageDialog extends React.PureComponent<
  IStatusMessageProps,
  IDialogState
> {
  constructor(props: IStatusMessageProps) {
    super(props);

    this.state = {
      isOpen: true,
    };
  }

  render() {
    return (
      <Overlay isOpen={this.state.isOpen}>
        <StatusMessage
          {...this.props}
          onClickButton={() => {
            this.setState({
              isOpen: false,
            });
            safeInvokeDeprecated(this.props.onClickButton);
          }}
        />
      </Overlay>
    );
  }
}

export const StatusMessageCenterContainer = styled.div`
  display: block;

  @media (min-width: ${sizes.large}px) {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40em;
  }
`;
