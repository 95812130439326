import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import moment from 'moment';
import * as ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import './fonts/typeface-lato/index.css';
import * as React from 'react';
import App from './App';
import { createBrowserHistory, createHashHistory, History } from 'history';
import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, compose, createStore, Middleware } from 'redux';
import { persistStore } from 'redux-persist';
import { initSagas } from './modules/rootSaga';
import { ApolloCacheGate } from './graphql/ApolloCacheGate';
import { Loader } from './components/basic/Loader/Loader';
import { rootReducer, RootState } from './modules/rootReducer';
import * as SocketCluster from 'socketcluster-client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import {
  getBuildNumber,
  getEnvironment,
} from './components/layout/VersionNumber/VersionNumber';
import * as microsoftTeams from '@microsoft/teams-js';

moment.locale('nn'); // nn, nb, en, we change this globally here!

const sagaMiddleware = createSagaMiddleware();
const middlewareList: Array<Middleware> = [sagaMiddleware];

// enable this to debug IE11 redux stuff
// middlewareList.push(loggerMiddleware);

// tslint:disable-next-line
const twin = window as any;
const isDevtoolsReady: boolean =
  typeof twin === 'object' &&
  twin.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ !== undefined;

const composeEnhancers = isDevtoolsReady
  ? twin.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
  })
  : compose;

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
});

const enhancers = composeEnhancers(
  applyMiddleware(...middlewareList),
  sentryReduxEnhancer
  // autoRehydrate({ log: true })
);

// FIXME
export const myStore: any = createStore<RootState>(rootReducer, enhancers);

// TODO: we can remove this.. now we persist apollo cache in stead!
// this starts rehydration:
const persistor = persistStore(
  myStore,
  undefined, // options is obsolete in v5
  () => {
    // console.log('rehydration complete');
    // if we need rehydrated state:
    // const rehydratedState = myStore.getState();
  }
);

let options = {
  hostname: process.env.REACT_APP_WS_URL,
  port: parseInt(
    (process.env.REACT_APP_WS_PORT && process.env.REACT_APP_WS_PORT) || '',
    10
  ),
  secure: process.env.NODE_ENV === 'production',
};

export let socket = SocketCluster.create(options);

export let CALENDAR_SHARE_HOST =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:4000/s/'
    : window.location.protocol + '//' + window.location.host + '/s/';

Sentry.init({
  dsn:
    'https://b3b36e1619c442fdb5158cbb7924828b@o503737.ingest.sentry.io/5589341',
  autoSessionTracking: true,
  release: 'munikum-client@' + getBuildNumber(),
  environment: getEnvironment(),
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

microsoftTeams.initialize();
export let teamsContext = undefined;
export let isTeamsDesktopApp = false;
microsoftTeams.getContext(context => {
  teamsContext = context;
  if (teamsContext.hostClientType === 'desktop') {
    isTeamsDesktopApp = true;
  }
  console.log(teamsContext);
});

const isElectron =
  navigator &&
  navigator.userAgent &&
  navigator.userAgent.toLowerCase().indexOf(' electron/') > -1;

// Desktop version of teams only works with hash history.
const shouldUseHashHistory = isElectron || isTeamsDesktopApp;
export const myHistory: History = shouldUseHashHistory
  ? createHashHistory({ basename: '/app' })
  : createBrowserHistory({ basename: '/app' });

initSagas(sagaMiddleware);
// TODO find a way to add the store
ReactDOM.render(
  <>
    <Provider store={myStore}>
      <ApolloCacheGate
        loading={<Loader name={'bird'} />}
        // onBeforeLift={() => console.log('lifting apollo cachegate...')}
        // onAfterLift={() => console.log('apollo cachegate LIFTED')}
      >
        <PersistGate
          persistor={persistor}
          loading={<Loader name={'bird'} />}
          // onBeforeLift={() => console.log('lifting persist gate...')}
        >
          <Router history={myHistory}>
            <App />
          </Router>
        </PersistGate>
      </ApolloCacheGate>
    </Provider>
  </>,
  document.getElementById('root') as HTMLElement
);
