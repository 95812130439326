import * as React from 'react';
import { CSSProperties } from 'react';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import {
  FOLLOW_TOPIC,
  MY_FOLLOWED_TOPICS,
  UNFOLLOW_TOPIC,
} from '../../../containers/Dashboard/TopicDashboard';
import { myApolloClient } from '../../../graphql/apolloClientFactory';
import { Button, ButtonSize, ButtonTheme } from '..';
import { ACTION_VALUE } from '../../../containers/ActionValue/ActionValueItemPage/ActionValueItemPage';
import {
  FOLLOW_DISCUSSION,
  MY_FOLLOWED_DISCUSSIONS,
  UNFOLLOW_DISCUSSION,
} from '../../../containers/Dashboard/ForumDashboard';
import {
  FOLLOW_GRANT,
  MY_FOLLOWED_GRANTS,
  UNFOLLOW_GRANT,
} from '../../../containers/Dashboard/ActionValueDashboard';
import { DISCUSSION } from '../../../containers/Discussion/DiscussionItemPage/DiscussionItemPage';
import { MunikumIcons } from '../../common/icons';
import {
  FOLLOW_CALENDAR_DASHBOARD,
  GET_CALENDAR_ISFOLLOWING,
  MY_FOLLOWED_CALENDARS,
  UNFOLLOW_CALENDAR_DASHBOARD,
} from '../../../containers/Dashboard/AnnualCycleDashboard';
import { TOPIC_QUERY } from '../../../containers/Topic/TopicItemPage/TopicItemPage';
import { commonMessages } from '../../language/commonMessages';
import { Colors, ColorTheme, safeInvoke } from '../../common';
import styled from 'styled-components';
import { MY_FOLLOWED_CALENDARS_DROPDOWN } from '../../../containers/AnnualCycle/calendar-queries';

const messages = defineMessages({
  join: {
    id: 'FollowButton2.join',
    defaultMessage: 'Join',
  },

  hasJoined: {
    id: 'FollowButton2.hasJoined',
    defaultMessage: 'Has joined',
  },
});

export enum ButtType {
  STAR,
  BUTTON,
}

export enum MyType {
  ACTION_VALUE,
  TOPIC,
  DISCUSSION,
  CALENDAR,
}
const StarDiv = styled.div`
  transition: 0.2s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

interface IFollowButton2Props {
  /*
  Defines witch button should be shown
   */
  buttonType: ButtType;
  borderButtonColor?: string;
  buttonTheme?: ColorTheme;
  starColorActive?: string;
  textValue?: string;
  onClick?: (isFollowing: boolean) => void;
  // ugly lazy way to get the right text :)
  CalendarPageButt?: boolean;
  /*
  Defines witch mutation and refetch should be used
   */
  followType: MyType;
  isFollowing: boolean;
  followId: string;
  style?: CSSProperties;
  /***
   * Shows another text for the button if true, Used on topicitempage
   */
  buttonText?: boolean;
}

interface IFollowButton2State {
  buttonText: string;
}
class FollowButton2Comp extends React.Component<
  IFollowButton2Props & InjectedIntlProps,
  IFollowButton2State
> {
  constructor(props: any) {
    super(props);
    this.state = {
      buttonText: this.props.textValue,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps: IFollowButton2Props) {
    if (this.state.buttonText !== nextProps.textValue) {
      this.setState({
        buttonText: nextProps.textValue,
      });
    }
  }

  handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    safeInvoke(this.props.onClick, !this.props.isFollowing);
    if (this.props.followType === MyType.ACTION_VALUE) {
      if (this.props.isFollowing) {
        myApolloClient
          .mutate({
            mutation: UNFOLLOW_GRANT,
            variables: {
              id: this.props.followId,
            },
          })
          .then(() => {
            myApolloClient
              .query({
                query: ACTION_VALUE,
                variables: { id: this.props.followId },
                fetchPolicy: 'network-only',
              })
              .then(() => {
                myApolloClient.query({
                  query: MY_FOLLOWED_GRANTS,
                  variables: { id: this.props.followId },
                  fetchPolicy: 'network-only',
                });
              });
          });
      } else {
        myApolloClient
          .mutate({
            mutation: FOLLOW_GRANT,
            variables: {
              id: this.props.followId,
            },
          })
          .then(() => {
            // console.log('unfollowed ok');
            myApolloClient
              .query({
                query: ACTION_VALUE,
                variables: { id: this.props.followId },
                fetchPolicy: 'network-only',
              })
              .then(() => {
                myApolloClient.query({
                  query: MY_FOLLOWED_GRANTS,
                  variables: { id: this.props.followId },
                  fetchPolicy: 'network-only',
                });
              });
          });
      }
    } else if (this.props.followType === MyType.TOPIC) {
      if (this.props.isFollowing) {
        myApolloClient
          .mutate({
            mutation: UNFOLLOW_TOPIC,
            variables: {
              id: this.props.followId,
            },
          })
          .then(() => {
            myApolloClient
              .query({
                query: TOPIC_QUERY,
                variables: { id: this.props.followId },
                fetchPolicy: 'network-only',
              })
              .then(() => {
                myApolloClient.query({
                  query: MY_FOLLOWED_TOPICS,
                  variables: { id: this.props.followId },
                  fetchPolicy: 'network-only',
                });
              });
          });
      } else {
        myApolloClient
          .mutate({
            mutation: FOLLOW_TOPIC,
            variables: {
              id: this.props.followId,
            },
          })
          .then(() => {
            // console.log('unfollowed ok');
            myApolloClient
              .query({
                query: TOPIC_QUERY,
                variables: { id: this.props.followId },
                fetchPolicy: 'network-only',
              })
              .then(() => {
                myApolloClient.query({
                  query: MY_FOLLOWED_TOPICS,
                  variables: { id: this.props.followId },
                  fetchPolicy: 'network-only',
                });
              });
          });
      }
    } else if (this.props.followType === MyType.DISCUSSION) {
      if (this.props.isFollowing) {
        myApolloClient
          .mutate({
            mutation: UNFOLLOW_DISCUSSION,
            variables: {
              id: this.props.followId,
            },
          })
          .then(() => {
            // console.log('unfollowed ok');
            myApolloClient
              .query({
                query: DISCUSSION,
                variables: { id: this.props.followId },
                fetchPolicy: 'network-only',
              })
              .then(() => {
                myApolloClient.query({
                  query: MY_FOLLOWED_DISCUSSIONS,
                  variables: { id: this.props.followId },
                  fetchPolicy: 'network-only',
                });
              });
          });
      } else {
        myApolloClient
          .mutate({
            mutation: FOLLOW_DISCUSSION,

            variables: {
              id: this.props.followId,
            },
          })
          .then(() => {
            // console.log('unfollowed ok');
            myApolloClient
              .query({
                query: DISCUSSION,
                variables: { id: this.props.followId },
                fetchPolicy: 'network-only',
              })
              .then(() => {
                myApolloClient.query({
                  query: MY_FOLLOWED_DISCUSSIONS,
                  variables: { id: this.props.followId },
                  fetchPolicy: 'network-only',
                });
              });
          });
      }
    } else if (this.props.followType === MyType.CALENDAR) {
      if (this.props.isFollowing) {
        myApolloClient
          .mutate({
            mutation: UNFOLLOW_CALENDAR_DASHBOARD,
            variables: {
              id: this.props.followId,
            },
          })
          .then(() => {
            // console.log('unfollowed ok');
            myApolloClient
              .query({
                query: GET_CALENDAR_ISFOLLOWING,
                variables: { id: this.props.followId },
                fetchPolicy: 'network-only',
              })
              .then(() => {
                myApolloClient.query({
                  query: MY_FOLLOWED_CALENDARS_DROPDOWN,
                  fetchPolicy: 'network-only',
                });
              });
          });
      } else {
        myApolloClient
          .mutate({
            mutation: FOLLOW_CALENDAR_DASHBOARD,

            variables: {
              id: this.props.followId,
            },
          })
          .then(() => {
            // console.log('unfollowed ok');
            myApolloClient
              .query({
                query: GET_CALENDAR_ISFOLLOWING,
                variables: { id: this.props.followId },
                fetchPolicy: 'network-only',
              })
              .then(() => {
                myApolloClient.query({
                  query: MY_FOLLOWED_CALENDARS,
                  variables: { id: this.props.followId },
                  fetchPolicy: 'network-only',
                });
              });
          });
      }
    }
  };

  render() {
    const { buttonType, isFollowing, intl, style, followType } = this.props;
    const StarSolid = MunikumIcons.Star;
    const StarOutline = MunikumIcons.StarBorder;
    const temp: any = function() {
      let text: any = '';
      if (followType === MyType.ACTION_VALUE) {
        text = isFollowing
          ? intl.formatMessage(commonMessages.unFollowHelpGrant)
          : intl.formatMessage(commonMessages.followHelpGrant);

        return text;
      } else if (followType === MyType.TOPIC) {
        text = isFollowing
          ? intl.formatMessage(commonMessages.unFollowHelpTopic)
          : intl.formatMessage(commonMessages.followHelpTopic);

        return text;
      } else if (followType === MyType.CALENDAR) {
        text = isFollowing
          ? intl.formatMessage(commonMessages.unfollowHelpCalendar)
          : intl.formatMessage(commonMessages.followHelpCalendar);

        return text;
      } else if (followType === MyType.DISCUSSION) {
        text = isFollowing
          ? intl.formatMessage(commonMessages.unFollowHelpDiscussion)
          : intl.formatMessage(commonMessages.followHelpDiscussion);

        return text;
      } else {
        return isFollowing ? 'Slutt å følge' : 'Følg';
      }
    };
    let textToShow = temp();
    const butt = (
      <>
        {this.props.CalendarPageButt && (
          <Button
            buttonTheme={ButtonTheme.RED}
            text={this.state.buttonText}
            size={
              this.props.buttonTheme === ColorTheme.Red
                ? ButtonSize.Medium
                : ButtonSize.Small
            }
            theme={this.props.buttonTheme}
            leftIcon={
              isFollowing
                ? MunikumIcons.FavouriteActive
                : MunikumIcons.Favourite
            }
            tooltip={textToShow}
            onClick={this.handleClick}
          />
        )}
        {!this.props.CalendarPageButt && (
          <Button
            buttonTheme={ButtonTheme.RED}
            text={
              this.props.textValue || isFollowing
                ? this.props.buttonText
                  ? intl.formatMessage(messages.hasJoined)
                  : intl.formatMessage(commonMessages.unfollow)
                : this.props.buttonText
                ? intl.formatMessage(messages.join)
                : intl.formatMessage(commonMessages.follow)
            }
            size={
              this.props.buttonTheme === ColorTheme.Red
                ? ButtonSize.Medium
                : ButtonSize.Small
            }
            theme={this.props.buttonTheme}
            leftIcon={
              isFollowing
                ? MunikumIcons.FavouriteActive
                : MunikumIcons.Favourite
            }
            tooltip={textToShow}
            onClick={this.handleClick}
          />
        )}
      </>
    );

    return buttonType === ButtType.BUTTON ? (
      butt
    ) : (
      <StarDiv
        style={{ ...style, cursor: 'pointer' }}
        onClick={this.handleClick}
      >
        {isFollowing ? (
          <StarSolid
            fill={this.props.starColorActive || Colors.RED}
            width={'1.7em'}
            height={'1.7em'}
            size={'1.7em'}
          />
        ) : (
          <StarOutline
            fill={this.props.borderButtonColor || Colors.RED}
            width={'1.7em'}
            height={'1.7em'}
            size={'1.7em'}
          />
        )}
      </StarDiv>
    );
  }
}
export const FollowButton2 = injectIntl(FollowButton2Comp);

FollowButton2.defaultProps = {
  buttonTheme: ColorTheme.White,
};
