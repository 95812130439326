import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import * as React from 'react';
import gql from 'graphql-tag';
import Mutation from 'react-apollo/Mutation';
import { Formik, FormikProps } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Option } from 'react-select';
import * as _ from 'lodash';
import Query, { QueryResult } from 'react-apollo/Query';
import { commonLabels } from '../language/commonLabels';
import { myApolloClient } from '../../graphql/apolloClientFactory';
import {
  AccessEnum,
  AddCalendarMutationMutation,
  AddCalendarMutationVariables,
  CalendarCategoryEnum,
  ContentStatus,
  ContentVisibilityEnum,
  CreateCalendarRingMutationVariables,
  FieldsOnKostraFunctionFragment,
  GetMeQuery,
  GetMeVariables,
  OrganizationAccessInput,
  OrganizationGroupAccessInput,
  Person,
  PersonGroupAccessInput,
  RelationQueryQuery,
  RelationQueryVariables,
} from '../../models/types';
import { ColorPicker } from './ColorPicker';
import { MunikumIcons } from '../common/icons';
import {
  CREATE_CALENDAR_RING,
  YEAR_WHEEL,
} from '../../containers/AnnualCycle/calendar-queries';
import {
  IStaticImageType,
  StaticImage,
} from '../basic/StaticImage/StaticImage';
import {
  ColorTheme,
  getNamesAndValues,
  isReallyEmpty,
  IThemeProps,
  safeInvokeDeprecated,
} from '../common';
import { ContentBox } from '../layout/ContentGrid/ContentBox';
import { ADD_TO_RELATED } from './EditAnnualCycleForm';
import { Pie } from './Pie';
import { commonMessages } from '../language/commonMessages';
import {
  StatusMessageDialog,
  ThemeColor,
} from '../basic/StatusMessage/StatusMessage';
import {
  MembersOfAnnualCycleCard,
  MembersOfAnnualCycleCardEveryoneComp,
  typeToList,
} from './AnnualCycleMembers';
import { metaMessages } from '../../services/models/contentMeta';
import {
  MathiasDropDown,
  tagsPositionEnum,
} from '../basic/DropDownSelect/MathiasDropDown';
import { SEARCH_QUERY_RELATION } from '../common/newRelationComp';
import { PageTitle } from '../layout/Page/Page';
import { MuContentEditable } from '../basic/MuEditor/MuContentEditable';
import { FormLabel } from '../basic/FormLabel/FormLabel';
import { KostraFunctionDropDown } from '../actionValue/KostraFunctionDropDown';
import ToggleButton from '../basic/ToggleButton/ToggleButton';
import { Tooltip } from '../basic/Tooltip/Tooltip';
import { AnnualCycleAddMembersCard } from './AnnualCycleAddMembersCard';
import { DisabledTextField } from '../topics/TopicForm';
import { Button, ButtonTheme } from '../basic';
import { ExternalLink } from '../basic/Structural/links';
import { MuTextField } from '../basic/MuEditor/MuTextField';
import { convertFromRaw } from 'draft-js';
import { DropDownSelect } from '../basic/DropDownSelect/DropDownSelect';
import { H4 } from '../basic/Structural/typography';

const ADD_CALENDAR = gql`
  mutation AddCalendarMutation($input: CreateCalendarInput!) {
    createCalendar(input: $input) {
      tags {
        id
        title
        color
      }
      id
      title
      dateCreated
      access {
        isPrivate
        persons {
          person {
            id
          }
          access
        }
      }
      createdBy {
        id
        name
      }
      color
      status
    }
  }
`;

export const GET_ME = gql`
  query GetMe {
    me {
      id
      name
      jobTitle
      picture
      isAdmin
      department
      settings {
        isSidebarExpanded
      }
      organization {
        id
        organizationImage
        name
        url
      }
    }
    myFollowed {
      tags {
        id
        title
        color
      }
    }
  }
`;

// const uuidv1 = require('uuid/v1');

export class MeQuery extends Query<GetMeQuery, GetMeVariables> {}

const messages = defineMessages({
  CycleIsCreated: {
    id: 'CreateAnnualCycleForm.CycleIsCreated',
    defaultMessage: 'Annual cycle is created',
  },
  createRelation: {
    id: 'CreateAnnualCycleForm.createRelation',
    defaultMessage: 'Connect calendar to:',
  },
  makeYearWheelPublic: {
    id: 'CreateAnnualCycleForm.makeYearWheelPublic',
    defaultMessage: 'Meke the yearwheel public',
  },
  makeYearWheelPrivate: {
    id: 'CreateAnnualCycleForm.makeYearWheelPrivate',
    defaultMessage: 'Meke the yearwheel private',
  },
});

export interface IMemberPerson extends Person {
  access?: AccessEnum;
}

// NOTE: we dont have to create our own interface for Formik, we can use created interface in types.ts
// export interface IAnnualCycleCreateAnnualCycle {
//   title?: string;
//   description?: string;
//   color?: string;
//   status?: ContentStatus;
// }

interface ICreateAnnualCycleFormProps {
  onCancel?: () => void;
  currentPersons?: Array<any>;
  currentPersonGroups?: Array<any>;
  currentOrgGroups?: Array<any>;
  currentOrg?: Array<any>;
  onSaveSuccess?: () => void;
  onNewActivityClick?: (id: string) => void;
  year: number;
  userhash: string;
}

// To get strongly typed props in Mutation's function as a child we have to define out own class (since we cant use Typescript generic < in React)

class TypedMutation extends Mutation<
  AddCalendarMutationMutation,
  AddCalendarMutationVariables
> {}

export const Title = styled.div`
  font-size: 30px;
  font-family: Lato, sans-serif;
  font-weight: bold;
  line-height: 36px;
  color: ${props => props.theme.textColor};
  margin-bottom: 0.5em;
`;

export const Container = styled.div`
  display: flex;
`;
export const Icons = styled.div`
  display: flex;
  color: ${(props: { isChecked: boolean; theme: IThemeProps }) =>
    props.isChecked ? props.theme.accent1.color : props.theme.textColor};
`;

export const LeftContainer = styled.div`
  margin-right: 1em;
  flex: 0 0 auto;
  padding: 1.875em 1.875em 0;
  box-sizing: border-box;
  width: 500px;
  overflow: auto;
  max-height: 90vh;
  background-color: ${props => props.theme.contentBackgroundColor};
`;

export const RightContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;

  min-width: 520px;
  max-width: 520px;
  padding: 1.875em;
  background-color: ${props => props.theme.tertiaryContentBackgroundColor};
`;

export const PieContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  //background-color: pink;
`;

const Error = styled.div`
  color: #ff5c39;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: Lato, sans-serif;
`;

interface ICreateAnnualCycleFormState {
  showError: boolean;
  showSuccess: boolean;
  idFromCalendar: string;
  topicItems: Array<any>;
  isEditTagOpen: boolean;
  currentKostraFunctions: ReadonlyArray<FieldsOnKostraFunctionFragment>;
  permission: AccessEnum;
  categoryKey: string;
}

class CreateAnnualCycleFormComp extends React.PureComponent<
  ICreateAnnualCycleFormProps & InjectedIntlProps,
  ICreateAnnualCycleFormState
> {
  statusList: { [id: string]: string };

  constructor(props: ICreateAnnualCycleFormProps & InjectedIntlProps) {
    super(props);

    this.state = {
      showError: false,
      showSuccess: false,
      idFromCalendar: '',
      topicItems: [],
      isEditTagOpen: false,
      permission: undefined,
      currentKostraFunctions: [],
      categoryKey: '0',
    };

    const contentStatuses: { [id: string]: string } = {};
    getNamesAndValues(ContentStatus).forEach(x => {
      contentStatuses[x.value] = props.intl.formatMessage(metaMessages[x.name]);
    });

    delete contentStatuses.TO_APPROVAL;
    this.statusList = contentStatuses;
  }

  handleError = (error: Error) => {
    this.setState({
      showError: true,
    });
  };

  handleCompleted = (result: any) => {
    this.setState({
      showSuccess: true,
    });
  };

  handleDismissError = () => {
    this.setState({
      showError: false,
    });
  };

  handleDismissSuccess = () => {
    this.setState(
      {
        showSuccess: false,
      },
      () => safeInvokeDeprecated(this.props.onSaveSuccess)
    );
  };
  deleteFromList = obj => {
    for (let i = 0; i < obj.length; i++) {
      if (obj[i].access === AccessEnum.READ) {
        obj.splice(i, 1);
        i--;
      }
    }
  };
  handleNewActivity = () => {
    this.setState(
      {
        showSuccess: false,
      },
      () =>
        safeInvokeDeprecated(
          this.props.onNewActivityClick,
          this.state.idFromCalendar
        )
    );
  };
  checkIfArrayHasReadMembers = formik => {
    for (let i = 0; i < formik.persons.length; i++) {
      if (formik.persons[i].access === AccessEnum.READ) {
        return true;
      }
    }
    for (let i = 0; i < formik.groups.length; i++) {
      if (formik.groups[i].access === AccessEnum.READ) {
        return true;
      }
    }
    for (let i = 0; i < formik.organizationGroups.length; i++) {
      if (formik.organizationGroups[i].access === AccessEnum.READ) {
        return true;
      }
    }
    for (let i = 0; i < formik.organizations.length; i++) {
      if (formik.organizations[i].access === AccessEnum.READ) {
        return true;
      }
    }
  };

  // handleRemoveMember = (person: IMemberPerson) => {
  //   const updatedMembers = this.state.currentMembers.slice();
  //   const tempIndex = updatedMembers.findIndex(p => p.id === person.id);
  //   updatedMembers.splice(tempIndex, 1);
  //
  //   this.setState({
  //     currentMembers: updatedMembers,
  //   });
  // };
  render() {
    const { intl } = this.props;
    delete this.statusList.OBSOLETE;
    let categoryKeys = Object.keys(CalendarCategoryEnum);
    let categoryArray: Array<{ key: number; value: string }> = [];
    for (let i = 0; i < categoryKeys.length; i++) {
      categoryArray.push({
        key: i,
        value: intl.formatMessage({
          id: 'CalendarListPage.' + categoryKeys[i].toLocaleLowerCase(),
          defaultMessage: categoryKeys[i].toLocaleLowerCase(),
        }),
      });
    }

    return (
      <ContentBox
        style={{
          padding: '0',
        }}
      >
        {/* put Mutation "far out", then we can display loading and error easily */}

        <TypedMutation
          mutation={ADD_CALENDAR}
          onCompleted={this.handleCompleted}
          onError={this.handleError}
        >
          {(addCalendar, { data, loading, called, error }) => {
            // we can show messages based on function as a child props here:
            // OR we can hook into onError (if we need more control, like showing dialog and hiding again)
            if (this.state.showError && error) {
              return (
                <StatusMessageDialog
                  theme={ThemeColor.ERROR_BLACK}
                  title={'error'}
                  text={'An error occured: ' + error.message}
                  buttonText={'Dismiss'} // we could also retry? check if apollo has a retry mechanismn?
                  onClickButton={this.handleDismissError}
                />
              );
            }

            if (this.state.showSuccess && called) {
              return (
                <StatusMessageDialog
                  theme={ThemeColor.SUCCESS_BLUE}
                  title={intl.formatMessage(commonLabels.success)}
                  text={intl.formatMessage(messages.CycleIsCreated)}
                  buttonText={intl.formatMessage(commonMessages.close)} // we could also retry? check if apollo has a retry mechanismn?
                  buttonText2={'Lag ny aktivitet'}
                  onClickButton2={this.handleNewActivity}
                  onClickButton={this.handleDismissSuccess}
                />
              );
            }

            // let colors = [
            //   { id: 1, name: 'blue' },
            //   { id: 2, name: 'red' },
            //   { id: 3, name: 'green' },
            // ];

            return (
              <div>
                {/* TODO: we could display a loader covering dialog if loading===true? => or is it too quick? :-P*/}
                {/*<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'pink', height: '100%'}}>*/}
                {/*<CircleSpinner />*/}
                {/*</div>*/}
                <MeQuery
                  query={GET_ME}
                  // variables={{ id: this.props.id }}
                  children={(result2: QueryResult<GetMeQuery>) => {
                    if (result2.loading) {
                      console.log('loading');
                    }
                    const me = result2.data && result2.data.me;
                    return (
                      <>
                        <Formik
                          validateOnBlur={true}
                          validationSchema={Yup.object().shape({
                            title: Yup.string()
                              .required()
                              .label(intl.formatMessage(commonLabels.title)),
                            color: Yup.string()
                              .required()
                              .label(intl.formatMessage(commonLabels.Color)),
                          })}
                          onSubmit={(values: any) => {
                            if (isReallyEmpty(values.title)) {
                            } else {
                              const temp = _.cloneDeep(values);
                              const accessToSend: any = {
                                persons: [],
                                groups: [],
                                organizations: [],
                                organizationGroups: [],
                              };
                              // temp.tags.forEach(c => {
                              //   if (c.color === Colors.GREY) {
                              //     c.color = null;
                              //   }
                              //   delete c.id;
                              // });
                              // remove all fields exept from id and access
                              if (temp.access.persons.length > 0) {
                                temp.access.persons.forEach((p: any) => {
                                  accessToSend.persons.push({
                                    access: p.access,
                                    person: p.person.id,
                                  });
                                });
                              }
                              if (temp.access.groups.length > 0) {
                                temp.access.groups.forEach(
                                  (g: PersonGroupAccessInput) => {
                                    accessToSend.groups.push({
                                      access: g.access,
                                      group: g.group.id,
                                    });
                                  }
                                );
                              }
                              if (temp.access.organizations.length > 0) {
                                temp.access.organizations.forEach(
                                  (o: OrganizationAccessInput) => {
                                    accessToSend.organizations.push({
                                      access: o.access,
                                      organization: o.organization.id,
                                    });
                                  }
                                );
                              }
                              if (temp.access.organizationGroups.length > 0) {
                                temp.access.organizationGroups.forEach(
                                  (org: OrganizationGroupAccessInput) => {
                                    accessToSend.organizationGroups.push({
                                      access: org.access,
                                      organizationGroup:
                                        org.organizationGroup.id,
                                    });
                                  }
                                );
                              }

                              temp.access.persons = accessToSend.persons;
                              temp.access.groups = accessToSend.groups;
                              temp.access.organizations =
                                accessToSend.organizations;
                              temp.access.organizationGroups =
                                accessToSend.organizationGroups;
                              addCalendar({
                                variables: {
                                  input: temp,
                                },
                              }).then(result => {
                                this.setState({
                                  idFromCalendar:
                                    (result &&
                                      result.data &&
                                      result.data.createCalendar &&
                                      result.data.createCalendar.id) ||
                                    '',
                                });
                                let updateRingInput: CreateCalendarRingMutationVariables = {
                                  input: {
                                    calendar:
                                      (result &&
                                        result.data &&
                                        result.data.createCalendar &&
                                        result.data.createCalendar.id) ||
                                      '',
                                    order:
                                      (result &&
                                        result.data &&
                                        result.data.createCalendar &&
                                        +result.data.createCalendar.id) ||
                                      1,
                                    color:
                                      (result &&
                                        result.data &&
                                        result.data.createCalendar &&
                                        result.data.createCalendar.color) ||
                                      '',
                                  },
                                };
                                if (this.state.topicItems.length > 0) {
                                  myApolloClient.mutate({
                                    mutation: ADD_TO_RELATED,
                                    variables: {
                                      input: {
                                        meta:
                                          result &&
                                          result.data &&
                                          result.data.createCalendar &&
                                          +result.data.createCalendar.id,
                                        metasToAdd: this.state.topicItems,
                                      },
                                    },
                                  });
                                }
                                myApolloClient
                                  .mutate({
                                    mutation: CREATE_CALENDAR_RING,
                                    variables: updateRingInput,
                                    refetchQueries: [
                                      {
                                        query: YEAR_WHEEL,
                                        variables: {
                                          year: this.props.year,
                                          person: this.props.userhash,
                                        },
                                      },
                                    ],
                                  })

                                  .catch(err => console.log('err', err));
                              });
                            }
                          }}
                          initialValues={{
                            category: CalendarCategoryEnum.TOPIC,
                            access: {
                              visibility: ContentVisibilityEnum.PUBLIC,
                              isPrivate: true,
                              persons: [],
                              groups: [],
                              organizations: [
                                {
                                  access: AccessEnum.READ,
                                  organization: me && me.organization,
                                },
                              ],
                              organizationGroups: [],
                            },
                            title: '',
                            // tags: [],
                            description: '',
                            descriptionText: '',
                            color: '',
                            status: ContentStatus.PUBLISHED, // NOTE: imported from types.ts!
                          }}
                          render={(formikProps: FormikProps<any>) => {
                            return (
                              <form onSubmit={formikProps.handleSubmit}>
                                <Container>
                                  <LeftContainer>
                                    <FormLabel>
                                      {intl.formatMessage(commonLabels.title)}
                                    </FormLabel>
                                    <PageTitle>
                                      <MuContentEditable
                                        id={'title'}
                                        name={'.title'}
                                        canEdit={true} // TODO: add access rights here.. disable if your user cannot edit!
                                        isMultiLine={false}
                                        value={
                                          formikProps.values.title &&
                                          formikProps.values.title
                                        }
                                        onSubmit={(md: string) => {
                                          if (!isReallyEmpty(md)) {
                                            // remove enter keys
                                            let text = md.replace(
                                              /(\r\n|\n|\r)/gm,
                                              ''
                                            );
                                            formikProps.setFieldValue(
                                              'title',
                                              text
                                            );
                                          }

                                          // prevents hidden sign in string
                                          if (isReallyEmpty(md)) {
                                            formikProps.setFieldValue(
                                              'title',
                                              ''
                                            );
                                          }
                                        }}
                                        style={{
                                          // minWidth: '6em',
                                          width: '100%',
                                          border:
                                            formikProps.touched.title &&
                                            formikProps.errors.title
                                              ? '1px solid #ff5c39'
                                              : 'none',
                                        }}
                                        placeholder={intl.formatMessage(
                                          commonLabels.titlePlaceholder
                                        )}
                                      />
                                    </PageTitle>
                                    <Error
                                      style={{
                                        marginTop: '.5em',
                                        marginBottom: '.5em',
                                      }}
                                    >
                                      {formikProps.touched.title &&
                                        formikProps.errors.title && (
                                          <MunikumIcons.Cancel
                                            style={{ marginRight: '.25em' }}
                                          />
                                        )}

                                      {formikProps.touched.title &&
                                        formikProps.errors.title}
                                    </Error>
                                    <div>
                                      {/*<div*/}
                                      {/*  style={{*/}
                                      {/*    display: 'flex',*/}
                                      {/*    marginTop: '.75em',*/}
                                      {/*    marginBottom: '1em',*/}
                                      {/*  }}*/}
                                      {/*>*/}
                                      {/*  /!*<InfoTooltip style={{marginLeft: '-2em'}} content={'test'}/>*!/*/}
                                      {/*  <TopicOption*/}
                                      {/*    onClick={() => {*/}
                                      {/*      formikProps.setFieldValue(*/}
                                      {/*        'category',*/}
                                      {/*        CalendarCategoryEnum.TOPIC*/}
                                      {/*      );*/}
                                      {/*    }}*/}
                                      {/*    style={{*/}
                                      {/*      backgroundColor:*/}
                                      {/*        formikProps.values.category ===*/}
                                      {/*        CalendarCategoryEnum.ORGANIZATION*/}
                                      {/*          ? '#fff'*/}
                                      {/*          : '#00778B',*/}
                                      {/*      border: '1px solid #D0D3D4 ',*/}
                                      {/*      color:*/}
                                      {/*        formikProps.values.category ===*/}
                                      {/*        CalendarCategoryEnum.TOPIC*/}
                                      {/*          ? '#fff'*/}
                                      {/*          : 'rgba(51, 51, 51, 0.4)',*/}
                                      {/*    }}*/}
                                      {/*  >*/}
                                      {/*    Temaårshjul*/}
                                      {/*  </TopicOption>*/}
                                      {/*  <OrganzationOption*/}
                                      {/*    onClick={() => {*/}
                                      {/*      formikProps.setFieldValue(*/}
                                      {/*        'category',*/}
                                      {/*        CalendarCategoryEnum.ORGANIZATION*/}
                                      {/*      );*/}
                                      {/*    }}*/}
                                      {/*    style={{*/}
                                      {/*      backgroundColor:*/}
                                      {/*        formikProps.values.category ===*/}
                                      {/*        CalendarCategoryEnum.ORGANIZATION*/}
                                      {/*          ? '#F5A623'*/}
                                      {/*          : '#fff',*/}
                                      {/*      border: '1px solid #D0D3D4 ',*/}
                                      {/*      color:*/}
                                      {/*        formikProps.values.category ===*/}
                                      {/*        CalendarCategoryEnum.ORGANIZATION*/}
                                      {/*          ? '#fff'*/}
                                      {/*          : 'rgba(51, 51, 51, 0.4)',*/}
                                      {/*    }}*/}
                                      {/*  >*/}
                                      {/*    Organisasjonsårshjul*/}
                                      {/*  </OrganzationOption>*/}
                                      {/*</div>*/}
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        <div
                                          style={{
                                            flex: '0 0 auto',
                                            border:
                                              formikProps.touched.color &&
                                              formikProps.errors.color
                                                ? '1px solid #ff5c39'
                                                : 'none',
                                            padding:
                                              formikProps.touched.color &&
                                              formikProps.errors.color
                                                ? '.2em'
                                                : '0',
                                            marginBottom:
                                              formikProps.touched.color &&
                                              formikProps.errors.color
                                                ? '.5em'
                                                : '0',
                                          }}
                                        >
                                          <ColorPicker
                                            label={intl.formatMessage(
                                              commonMessages.chooseColor
                                            )}
                                            selectedColor={
                                              formikProps.values.color
                                            }
                                            onChange={(color: string) =>
                                              formikProps.setFieldValue(
                                                'color',
                                                color
                                              )
                                            }
                                          />
                                        </div>

                                        <PieContainer
                                          style={{ flex: '1 1 10em' }}
                                        >
                                          <div
                                            style={{
                                              width: '10.5em',
                                              height: '10.5em',
                                            }}
                                          >
                                            <Pie
                                              color={formikProps.values.color}
                                            />
                                          </div>
                                        </PieContainer>
                                      </div>
                                      <Error style={{ marginBottom: '1em' }}>
                                        {formikProps.touched.color &&
                                          formikProps.errors.color && (
                                            <MunikumIcons.Cancel
                                              style={{ marginRight: '.25em' }}
                                            />
                                          )}

                                        {formikProps.touched.color &&
                                          formikProps.errors.color}
                                      </Error>
                                      <MathiasDropDown
                                        label={intl.formatMessage(
                                          messages.createRelation
                                        )}
                                        style={{
                                          marginBottom: '1em',
                                          width: '98.5%',
                                        }}
                                        tagsPosition={tagsPositionEnum.INSIDE}
                                        selectedItems={[]}
                                        onTagRemoved={item => {
                                          this.setState({
                                            topicItems: [],
                                          });
                                        }}
                                        onChange={(value: Array<Option>) => {
                                          this.setState(
                                            {
                                              topicItems: [],
                                            },
                                            () => {
                                              value.forEach(t => {
                                                this.state.topicItems.push(
                                                  +t.value
                                                );
                                              });
                                            }
                                          );
                                        }}
                                        loadItems={(
                                          input: string,
                                          preloadedItems: Array<Option<string>>
                                        ): Promise<Array<Option<string>>> => {
                                          return new Promise(
                                            async (resolve, reject) => {
                                              const items: Array<Option<
                                                string
                                              >> = [];
                                              try {
                                                const res = await myApolloClient.query<
                                                  RelationQueryQuery,
                                                  RelationQueryVariables
                                                >({
                                                  query: SEARCH_QUERY_RELATION,
                                                  variables: {
                                                    query: input,
                                                    onlyWrite: true,
                                                  },
                                                  fetchPolicy: 'network-only',
                                                });
                                                res.data.search.topics.forEach(
                                                  (topic, index) => {
                                                    const temp: Option<string> = {
                                                      label: topic.title,
                                                      value: topic.id,
                                                    };
                                                    items.push(temp);
                                                  }
                                                );
                                                resolve(items);
                                              } catch (e) {
                                                console.error('Fail', e);
                                                reject(e);
                                              }
                                            }
                                          );
                                        }}
                                      />

                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'flex-start',
                                        }}
                                      >
                                        <DropDownSelect
                                          label={intl.formatMessage(
                                            commonLabels.category
                                          )}
                                          name={'category'}
                                          style={{
                                            marginRight: '8px',
                                          }}
                                          onChangeSelected={e => {
                                            switch (e) {
                                              case '0':
                                                formikProps.setFieldValue(
                                                  'category',
                                                  CalendarCategoryEnum.TOPIC
                                                );
                                                this.setState({
                                                  categoryKey: e,
                                                });
                                                break;
                                              case '1':
                                                formikProps.setFieldValue(
                                                  'category',
                                                  CalendarCategoryEnum.ORGANIZATION
                                                );
                                                this.setState({
                                                  categoryKey: e,
                                                });
                                                break;
                                              case '2':
                                                formikProps.setFieldValue(
                                                  'category',
                                                  CalendarCategoryEnum.PROJECT
                                                );
                                                this.setState({
                                                  categoryKey: e,
                                                });
                                                break;
                                              case '3':
                                                formikProps.setFieldValue(
                                                  'category',
                                                  CalendarCategoryEnum.GRANT
                                                );
                                                this.setState({
                                                  categoryKey: e,
                                                });
                                                break;
                                              default:
                                                this.setState({
                                                  categoryKey: '0',
                                                });
                                                formikProps.setFieldValue(
                                                  'category',
                                                  CalendarCategoryEnum.TOPIC
                                                );
                                            }
                                          }}
                                          showEmptyOption={false}
                                          value={this.state.categoryKey}
                                          items={categoryArray}
                                          accessor={'value'}
                                          fieldGroupStyle={{
                                            width: '208px',
                                            marginRight: '.5em',
                                          }}
                                        />
                                        <KostraFunctionDropDown
                                          style={{
                                            width: '20em',
                                            marginTop: '-.1em',
                                          }}
                                          fieldGroupStyle={{ width: '224px' }}
                                          selectedItems={
                                            this.state.currentKostraFunctions
                                          }
                                          onSelectedItemsChanged={selectedItems => {
                                            formikProps.setFieldValue(
                                              'kostraFunctions',
                                              selectedItems.map(f => f.id)
                                            );
                                            this.setState({
                                              currentKostraFunctions: selectedItems,
                                            });
                                          }}
                                        />
                                        {/*<div style={{ width: '220px' }}>*/}
                                        {/*  <SmallTitle*/}
                                        {/*    style={{ marginBottom: '.3em' }}*/}
                                        {/*  >*/}
                                        {/*    {'Merkelapp'}*/}
                                        {/*  </SmallTitle>{' '}*/}
                                        {/*  <MyFollowedTagsContext.Consumer>*/}
                                        {/*    {myFollowedTags => (*/}
                                        {/*      <TagsDropdown*/}
                                        {/*        myTags={[]}*/}
                                        {/*        onSelectedItemsChanged={selectedItems => {*/}
                                        {/*          formikProps.setFieldValue(*/}
                                        {/*            'tags',*/}
                                        {/*            selectedItems*/}
                                        {/*          );*/}
                                        {/*        }}*/}
                                        {/*        followedTags={*/}
                                        {/*          myFollowedTags?.map(c => {*/}
                                        {/*            const id = uuidv1();*/}
                                        {/*            return {*/}
                                        {/*              id: id,*/}
                                        {/*              title: c.title,*/}
                                        {/*              color:*/}
                                        {/*                c?.color ?? Colors.GREY,*/}
                                        {/*            };*/}
                                        {/*          }) ?? []*/}
                                        {/*        }*/}
                                        {/*        selectedItems={*/}
                                        {/*          formikProps.values.tags*/}
                                        {/*        }*/}
                                        {/*      />*/}
                                        {/*    )}*/}
                                        {/*  </MyFollowedTagsContext.Consumer>*/}
                                        {/*</div>*/}
                                      </div>
                                      <div style={{ marginBottom: '2em' }}>
                                        <MuTextField
                                          style={{}}
                                          shouldEditorHaveFiles={false}
                                          doIHaveAccessToAddFiles={true}
                                          label={intl.formatMessage(
                                            commonMessages.description
                                          )}
                                          placeholder={intl.formatMessage(
                                            commonMessages.writeHerePlaceholder
                                          )}
                                          onSubmit={value => {
                                            formikProps.setFieldValue(
                                              'description',
                                              value
                                            );
                                            formikProps.setFieldValue(
                                              'descriptionText',
                                              convertFromRaw(
                                                JSON.parse(value)
                                              ).getPlainText()
                                            );
                                          }}
                                          value={formikProps.values.description}
                                        />
                                      </div>
                                      {/*<TextArea*/}
                                      {/*style={{ width: '100%' }}*/}
                                      {/*label={'Notes'}*/}
                                      {/*name={'notes'}*/}
                                      {/*value={formikProps.values.status}*/}
                                      {/*onChange={formikProps.handleChange}*/}
                                      {/*onBlur={formikProps.handleBlur}*/}
                                      {/*placeholder={'Description'}*/}
                                      {/*error={formikProps.errors.description}*/}
                                      {/*/>*/}

                                      {/*<p>*/}
                                      {/*test slow mueditor test:*/}
                                      {/*</p>*/}
                                      {/*<MuEditorTextArea*/}
                                      {/*markdown={formikProps.values.description}*/}
                                      {/*onChange={formikProps.handleChange}*/}
                                      {/*/>*/}

                                      {/*<Checkbox*/}
                                      {/*label={'This year wheel is recommended by Munikum'}*/}
                                      {/*/>*/}
                                    </div>
                                  </LeftContainer>
                                  <RightContainer>
                                    <div
                                      style={{
                                        minWidth: '450px', // IE 11 fix / hack
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                          marginBottom:
                                            formikProps.values.access
                                              .isPrivate === true
                                              ? '0'
                                              : '1em',
                                        }}
                                      >
                                        <H4
                                          style={{
                                            fontWeight: 'bold',
                                            marginRight: '1em',
                                          }}
                                        >
                                          {intl.formatMessage(
                                            commonMessages.Access
                                          )}
                                        </H4>
                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <Icons
                                            isChecked={
                                              !formikProps.values.access
                                                .isPrivate
                                            }
                                          >
                                            <MunikumIcons.Globe
                                              style={{
                                                height: '1.2em',
                                                width: '1.2em',
                                              }}
                                            />
                                          </Icons>
                                          <Tooltip
                                            position={'top-start'}
                                            content={
                                              formikProps.values.access
                                                .isPrivate
                                                ? intl.formatMessage(
                                                    commonMessages.makeYearWheelPublic
                                                  )
                                                : intl.formatMessage(
                                                    commonMessages.makeYearWheelPrivate
                                                  )
                                            }
                                          >
                                            <div>
                                              <ToggleButton
                                                style={{
                                                  marginLeft: '.5em',
                                                  marginRight: '.3em',
                                                  marginTop: '.5em',
                                                }}
                                                checked={
                                                  formikProps.values.access
                                                    .isPrivate
                                                }
                                                onClick={() => {
                                                  const hasReadAccess = this.checkIfArrayHasReadMembers(
                                                    formikProps.values.access
                                                  );
                                                  if (
                                                    formikProps.values.access
                                                      .isPrivate
                                                  ) {
                                                    if (hasReadAccess) {
                                                      if (
                                                        window.confirm(
                                                          intl.formatMessage(
                                                            commonMessages.MakeYearWheelPublicWarning
                                                          )
                                                        )
                                                      ) {
                                                        formikProps.setFieldValue(
                                                          'access.isPrivate',
                                                          !formikProps.values
                                                            .access.isPrivate
                                                        );
                                                        const personCopy = _.cloneDeep(
                                                          formikProps.values
                                                            .access.persons
                                                        );
                                                        const personGroupCopy = _.cloneDeep(
                                                          formikProps.values
                                                            .access.groups
                                                        );
                                                        const orgCopy = _.cloneDeep(
                                                          formikProps.values
                                                            .access
                                                            .organizations
                                                        );
                                                        const orgGroupCopy = _.cloneDeep(
                                                          formikProps.values
                                                            .access
                                                            .organizationGroups
                                                        );
                                                        this.deleteFromList(
                                                          personCopy
                                                        );
                                                        this.deleteFromList(
                                                          personGroupCopy
                                                        );
                                                        this.deleteFromList(
                                                          orgCopy
                                                        );
                                                        this.deleteFromList(
                                                          orgGroupCopy
                                                        );
                                                        formikProps.setFieldValue(
                                                          'access.persons',
                                                          personCopy
                                                        );
                                                        formikProps.setFieldValue(
                                                          'access.groups',
                                                          personGroupCopy
                                                        );
                                                        formikProps.setFieldValue(
                                                          'access.organizations',
                                                          orgCopy
                                                        );
                                                        formikProps.setFieldValue(
                                                          'access.organizationGroups',
                                                          orgGroupCopy
                                                        );
                                                      }
                                                    } else {
                                                      formikProps.setFieldValue(
                                                        'access.isPrivate',
                                                        !formikProps.values
                                                          .access.isPrivate
                                                      );
                                                    }
                                                  } else if (
                                                    !formikProps.values.access
                                                      .isPrivate
                                                  ) {
                                                    formikProps.setFieldValue(
                                                      'access.isPrivate',
                                                      !formikProps.values.access
                                                        .isPrivate
                                                    );
                                                  }
                                                }}
                                              />
                                            </div>
                                          </Tooltip>
                                          <Icons
                                            isChecked={
                                              formikProps.values.access
                                                .isPrivate
                                            }
                                          >
                                            <MunikumIcons.Padlock
                                              style={{
                                                height: '1.2em',
                                                width: '1.2em',
                                              }}
                                            />
                                          </Icons>
                                        </div>
                                      </div>
                                      {formikProps.values.access.isPrivate && (
                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-end',
                                            marginBottom:
                                              formikProps.values.access
                                                .isPrivate === true
                                                ? '.5em'
                                                : '0',
                                          }}
                                        >
                                          <Icons
                                            isChecked={
                                              formikProps.values.access
                                                .visibility !==
                                              ContentVisibilityEnum.PRIVATE
                                            }
                                          >
                                            <MunikumIcons.eye
                                              style={{
                                                height: '1.2em',
                                                width: '1.2em',
                                              }}
                                            />
                                          </Icons>
                                          <Tooltip
                                            position={'top-start'}
                                            content={
                                              formikProps.values.access
                                                .visibility ===
                                              ContentVisibilityEnum.PRIVATE
                                                ? intl.formatMessage(
                                                    commonMessages.MakeYearWheelVisible
                                                  )
                                                : intl.formatMessage(
                                                    commonMessages.makeYearWheelNotVisible
                                                  )
                                            }
                                          >
                                            <div
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                              }}
                                            >
                                              <ToggleButton
                                                onClick={() => {
                                                  formikProps.values.access
                                                    .visibility ===
                                                  ContentVisibilityEnum.PRIVATE
                                                    ? formikProps.setFieldValue(
                                                        'access.visibility',
                                                        ContentVisibilityEnum.PUBLIC
                                                      )
                                                    : formikProps.setFieldValue(
                                                        'access.visibility',
                                                        ContentVisibilityEnum.PRIVATE
                                                      );
                                                }}
                                                style={{
                                                  marginLeft: '.5em',
                                                  marginRight: '.3em',
                                                  marginTop: '.5em',
                                                }}
                                                checked={
                                                  formikProps.values.access
                                                    .visibility ===
                                                  ContentVisibilityEnum.PRIVATE
                                                }
                                                name={'da'}
                                              />
                                            </div>
                                          </Tooltip>
                                          <Icons
                                            isChecked={
                                              formikProps.values.access
                                                .visibility ===
                                              ContentVisibilityEnum.PRIVATE
                                            }
                                          >
                                            <MunikumIcons.eyeSlash
                                              style={{
                                                height: '1.2em',
                                                width: '1.2em',
                                              }}
                                            />
                                          </Icons>
                                        </div>
                                      )}
                                      {/*<Toogle/>*/}
                                      <div style={{ marginBottom: '.5em' }}>
                                        <ExternalLink
                                          style={{
                                            fontSize: '12px',
                                          }}
                                          href={
                                            'https://support.munikum.no/hc/no/articles/360027728352-Gi-lese-og-skrivetilgang-til-%C3%A5rshjulet'
                                          }
                                        >
                                          {intl.formatMessage(
                                            commonMessages.doYouNotFindThePerson
                                          )}
                                        </ExternalLink>
                                      </div>
                                      <div style={{ marginBottom: '1.250em' }}>
                                        <AnnualCycleAddMembersCard
                                          isPrivate={
                                            formikProps.values.access.isPrivate
                                          }
                                          showOnlyPerson={false}
                                          addGroup={(group, permission) => {
                                            let isAlreadyInArray = false;
                                            for (
                                              let i = 0;
                                              i <
                                              formikProps.values.access.groups
                                                .length;
                                              i++
                                            ) {
                                              if (
                                                formikProps.values.access
                                                  .groups[i].group.id ===
                                                group.id
                                              ) {
                                                isAlreadyInArray = true;
                                                break;
                                              }
                                            }
                                            if (isAlreadyInArray) {
                                              return '';
                                            } else {
                                              const toSend = formikProps.values.access.groups.slice();
                                              toSend.push({
                                                access: permission,
                                                group: group,
                                              });
                                              formikProps.setFieldValue(
                                                'access.groups',
                                                toSend
                                              );
                                            }
                                          }}
                                          addOrg={(org, permission) => {
                                            let isAlreadyInArray = false;
                                            for (
                                              let i = 0;
                                              i <
                                              formikProps.values.access
                                                .organizations.length;
                                              i++
                                            ) {
                                              if (
                                                formikProps.values.access
                                                  .organizations[i].organization
                                                  .id === org.id
                                              ) {
                                                isAlreadyInArray = true;
                                                break;
                                              }
                                            }
                                            if (isAlreadyInArray) {
                                              return '';
                                            } else {
                                              const toSend = formikProps.values.access.organizations.slice();
                                              toSend.push({
                                                access: permission,
                                                organization: org,
                                              });
                                              formikProps.setFieldValue(
                                                'access.organizations',
                                                toSend
                                              );
                                            }
                                          }}
                                          addOrgGroup={(
                                            orgGroup,
                                            permission
                                          ) => {
                                            let isAlreadyInArray = false;
                                            for (
                                              let i = 0;
                                              i <
                                              formikProps.values.access
                                                .organizationGroups.length;
                                              i++
                                            ) {
                                              if (
                                                formikProps.values.access
                                                  .organizationGroups[i]
                                                  .organizationGroup.id ===
                                                orgGroup.id
                                              ) {
                                                isAlreadyInArray = true;
                                                break;
                                              }
                                            }
                                            if (isAlreadyInArray) {
                                              return '';
                                            } else {
                                              const toSend = formikProps.values.access.organizationGroups.slice();
                                              toSend.push({
                                                access: permission,
                                                organizationGroup: orgGroup,
                                              });
                                              formikProps.setFieldValue(
                                                'access.organizationGroups',
                                                toSend
                                              );
                                            }
                                          }}
                                          showTag={false}
                                          canChangePermission={
                                            formikProps.values.access.isPrivate
                                          }
                                          isPermissionActive={true}
                                          title={intl.formatMessage(
                                            commonMessages.to
                                          )}
                                          permissionLevel={permissionLevel => {}}
                                          addMember={(
                                            person,
                                            permission: AccessEnum
                                          ) => {
                                            let isAlreadyInArray = false;
                                            for (
                                              let i = 0;
                                              i <
                                              formikProps.values.access.persons
                                                .length;
                                              i++
                                            ) {
                                              if (
                                                formikProps.values.access
                                                  .persons[i].person.id ===
                                                person.id
                                              ) {
                                                isAlreadyInArray = true;
                                                break;
                                              }
                                            }
                                            if (isAlreadyInArray) {
                                              return '';
                                            } else {
                                              if (person.id !== me.id) {
                                                const toSend: any = formikProps.values.access.persons.slice();

                                                toSend.push({
                                                  access: permission,
                                                  person: person,
                                                });
                                                formikProps.setFieldValue(
                                                  'access.persons',
                                                  toSend
                                                );
                                              }
                                            }
                                          }}
                                        />
                                      </div>
                                      <div>
                                        {me && (
                                          <MembersOfAnnualCycleCard
                                            type={typeToList.PERSONS}
                                            canChangePermission={false}
                                            canRemove={false}
                                            key={me.id}
                                            profilePic={me.picture}
                                            name={me.name}
                                            organization={
                                              me.organization &&
                                              me.organization.name
                                            }
                                            department={me.department}
                                            role={me.jobTitle}
                                            handleRemoveMember={() => ''}
                                            img={
                                              <StaticImage
                                                width={21}
                                                height={21}
                                                type={
                                                  IStaticImageType.ORGANIZATION
                                                }
                                                filename={
                                                  (me.organization &&
                                                    me.organization
                                                      .organizationImage) ||
                                                  ''
                                                }
                                              />
                                            }
                                            permissionLevel={{
                                              value: 'OWNER',
                                              name: intl.formatMessage(
                                                commonMessages.owner
                                              ),
                                            }}
                                          />
                                        )}
                                        {formikProps.values.access &&
                                          formikProps.values.access
                                            .isPrivate && (
                                            <DisabledTextField
                                              style={{ width: '460px' }}
                                              name={'t'}
                                              disabled={true}
                                              value={intl.formatMessage(
                                                commonMessages.infoBoxYearWheel
                                              )}
                                            />
                                          )}

                                        <div
                                          style={{
                                            maxHeight: '300px',
                                            overflowY: 'auto',
                                            marginBottom: '1em',
                                          }}
                                        >
                                          {/*Persons*/}
                                          {formikProps.values.access.persons.map(
                                            (person: any, i) => {
                                              // console.log(person, 'person');
                                              if (me) {
                                                if (
                                                  person.person.id !== me.id &&
                                                  me.id
                                                ) {
                                                  return (
                                                    <div key={'bla' + i}>
                                                      <MembersOfAnnualCycleCard
                                                        type={
                                                          typeToList.PERSONS
                                                        }
                                                        canChangePermission={
                                                          formikProps.values
                                                            .access.isPrivate
                                                        }
                                                        canRemove={true}
                                                        onChangeSelected={selectedKey => {
                                                          const personCopy = formikProps.values.access.persons.slice();
                                                          const myIndex = _.findIndex(
                                                            formikProps.values
                                                              .access.persons,
                                                            (p: any) => {
                                                              return (
                                                                person.person
                                                                  .id ===
                                                                p.person.id
                                                              );
                                                            }
                                                          );
                                                          const getEnum =
                                                            selectedKey ===
                                                            'WRITE'
                                                              ? AccessEnum.WRITE
                                                              : AccessEnum.READ;

                                                          personCopy[
                                                            myIndex
                                                          ].access = getEnum;

                                                          formikProps.setFieldValue(
                                                            'access.persons',
                                                            personCopy
                                                          );
                                                        }}
                                                        key={i}
                                                        profilePic={
                                                          person.picture
                                                        }
                                                        name={
                                                          person.person.name
                                                        }
                                                        organization={
                                                          person.person
                                                            .organization &&
                                                          person.person
                                                            .organization.name
                                                        }
                                                        department={
                                                          person.person
                                                            .department
                                                        }
                                                        role={
                                                          person.person.role
                                                        }
                                                        handleRemoveMember={() => {
                                                          const personsCopy = formikProps.values.access.persons.slice();

                                                          const myIndex = _.findIndex(
                                                            formikProps.values
                                                              .access.persons,
                                                            (p: any) => {
                                                              return (
                                                                person.person
                                                                  .id ===
                                                                p.person.id
                                                              );
                                                            }
                                                          );
                                                          personsCopy.splice(
                                                            myIndex,
                                                            1
                                                          );
                                                          formikProps.setFieldValue(
                                                            'access.persons',
                                                            personsCopy
                                                          );
                                                        }}
                                                        img={
                                                          <StaticImage
                                                            width={21}
                                                            height={21}
                                                            type={
                                                              IStaticImageType.ORGANIZATION
                                                            }
                                                            filename={
                                                              (person.person
                                                                .organization &&
                                                                person.person
                                                                  .organization
                                                                  .organizationImage) ||
                                                              ''
                                                            }
                                                          />
                                                        }
                                                        permissionLevel={{
                                                          value: 'WRITE',
                                                          name:
                                                            person.access ===
                                                            'WRITE'
                                                              ? 'skrivetilgang'
                                                              : 'Lesetilgang',
                                                        }}
                                                      />
                                                    </div>
                                                  );
                                                }
                                              }
                                              return '';
                                            }
                                          )}
                                          {/*Groups*/}
                                          {formikProps.values.access.groups.map(
                                            (group: any, i) => {
                                              if (me) {
                                                return (
                                                  <div key={'bla' + i}>
                                                    <MembersOfAnnualCycleCard
                                                      type={
                                                        typeToList.PERSONGROUPS
                                                      }
                                                      canChangePermission={
                                                        formikProps.values
                                                          .access.isPrivate
                                                      }
                                                      canRemove={true}
                                                      onChangeSelected={selectedKey => {
                                                        const groupCopy =
                                                          formikProps.values
                                                            .access.groups;
                                                        const myIndex = _.findIndex(
                                                          formikProps.values
                                                            .access.groups,
                                                          (
                                                            g: PersonGroupAccessInput
                                                          ) => {
                                                            return (
                                                              group.group.id ===
                                                              g.group.id
                                                            );
                                                          }
                                                        );
                                                        const getEnum =
                                                          selectedKey ===
                                                          'WRITE'
                                                            ? AccessEnum.WRITE
                                                            : AccessEnum.READ;
                                                        groupCopy[
                                                          myIndex
                                                        ].access = getEnum;
                                                        formikProps.setFieldValue(
                                                          'access.groups',
                                                          groupCopy
                                                        );
                                                      }}
                                                      key={i}
                                                      name={group.group.title}
                                                      handleRemoveMember={() => {
                                                        const groupCopyFormik = formikProps.values.access.groups.slice();
                                                        const myIndex = _.findIndex(
                                                          formikProps.values
                                                            .access.groups,
                                                          (
                                                            g: PersonGroupAccessInput
                                                          ) => {
                                                            return (
                                                              group.group.id ===
                                                              g.group.id
                                                            );
                                                          }
                                                        );
                                                        groupCopyFormik.splice(
                                                          myIndex,
                                                          1
                                                        );

                                                        formikProps.setFieldValue(
                                                          'access.groups',
                                                          groupCopyFormik
                                                        );
                                                      }}
                                                      img={
                                                        <StaticImage
                                                          width={21}
                                                          height={21}
                                                          type={
                                                            IStaticImageType.ORGANIZATION
                                                          }
                                                        />
                                                      }
                                                      permissionLevel={{
                                                        value: 'WRITE',
                                                        name:
                                                          group.access ===
                                                          'WRITE'
                                                            ? 'skrivetilgang'
                                                            : 'Lesetilgang',
                                                      }}
                                                    />
                                                  </div>
                                                );
                                              }
                                              return '';
                                            }
                                          )}
                                          {/*<Button text={'hhh'}*/}
                                          {/*onClick={()=> {*/}
                                          {/*myApolloClient.mutate({*/}
                                          {/*mutation: CREATE_ORGANIZATION_GROUP,*/}
                                          {/*variables: {input: {*/}
                                          {/*title: 'orgGruppe',*/}
                                          {/*members: [1]*/}
                                          {/*}}*/}
                                          {/*});*/}
                                          {/*myApolloClient.mutate({*/}
                                          {/*mutation: CREATE_ORGANIZATION_GROUP,*/}
                                          {/*variables: {input: {*/}
                                          {/*title: 'orgGruppe1',*/}
                                          {/*members: [1]*/}
                                          {/*}}*/}
                                          {/*});*/}
                                          {/*myApolloClient.mutate({*/}
                                          {/*mutation: CREATE_ORGANIZATION_GROUP,*/}
                                          {/*variables: {input: {*/}
                                          {/*title: 'orgGruppe2',*/}
                                          {/*members: [1]*/}
                                          {/*}}*/}
                                          {/*});*/}
                                          {/*}}*/}
                                          {/*/>*/}
                                          {formikProps.values.access
                                            .organizations &&
                                            formikProps.values.access.organizations.map(
                                              (org: any, index) => {
                                                if (me) {
                                                  return (
                                                    <div key={'bla' + index}>
                                                      <MembersOfAnnualCycleCard
                                                        type={
                                                          typeToList.ORGANIZATIONS
                                                        }
                                                        canChangePermission={
                                                          formikProps.values
                                                            .access.isPrivate
                                                        }
                                                        canRemove={true}
                                                        leftImg={
                                                          org.organization
                                                            .organizationImage
                                                        }
                                                        onChangeSelected={selectedKey => {
                                                          const orgCopy =
                                                            formikProps.values
                                                              .access
                                                              .organizations;
                                                          const myIndex = _.findIndex(
                                                            formikProps.values
                                                              .access
                                                              .organizations,
                                                            (
                                                              organization: OrganizationAccessInput
                                                            ) => {
                                                              return (
                                                                org.organization
                                                                  .id ===
                                                                organization
                                                                  .organization
                                                                  .id
                                                              );
                                                            }
                                                          );
                                                          const getEnum =
                                                            selectedKey ===
                                                            'WRITE'
                                                              ? AccessEnum.WRITE
                                                              : AccessEnum.READ;
                                                          orgCopy[
                                                            myIndex
                                                          ].access = getEnum;
                                                          formikProps.setFieldValue(
                                                            'access.organizations',
                                                            orgCopy
                                                          );
                                                        }}
                                                        key={index}
                                                        name={
                                                          org.organization.name
                                                        }
                                                        handleRemoveMember={() => {
                                                          const orgCopyFormik = formikProps.values.access.organizations.slice();
                                                          const myIndex = _.findIndex(
                                                            formikProps.values
                                                              .access
                                                              .organizations,
                                                            (
                                                              organization: OrganizationAccessInput
                                                            ) => {
                                                              return (
                                                                org.organization
                                                                  .id ===
                                                                organization
                                                                  .organization
                                                                  .id
                                                              );
                                                            }
                                                          );
                                                          // console.log(myIndex);

                                                          orgCopyFormik.splice(
                                                            myIndex,
                                                            1
                                                          );
                                                          formikProps.setFieldValue(
                                                            'access.organizations',
                                                            orgCopyFormik
                                                          );
                                                        }}
                                                        img={
                                                          <StaticImage
                                                            width={21}
                                                            height={21}
                                                            type={
                                                              IStaticImageType.ORGANIZATION
                                                            }
                                                            filename={
                                                              (org.organization &&
                                                                org.organization
                                                                  .organizationImage) ||
                                                              ''
                                                            }
                                                          />
                                                        }
                                                        permissionLevel={{
                                                          value: 'WRITE',
                                                          name:
                                                            org.access ===
                                                            'WRITE'
                                                              ? 'skrivetilgang'
                                                              : 'Lesetilgang',
                                                        }}
                                                      />
                                                    </div>
                                                  );
                                                }
                                                return '';
                                              }
                                            )}
                                          {/*OrganizationGroups*/}
                                          {formikProps.values.access.organizationGroups.map(
                                            (orgGroup: any, indexPosition) => {
                                              if (me) {
                                                return (
                                                  <div
                                                    key={'bla' + indexPosition}
                                                  >
                                                    <MembersOfAnnualCycleCard
                                                      type={
                                                        typeToList.ORGANIZATIONGROUPS
                                                      }
                                                      canChangePermission={
                                                        formikProps.values
                                                          .access.isPrivate
                                                      }
                                                      canRemove={true}
                                                      key={indexPosition}
                                                      name={
                                                        orgGroup
                                                          .organizationGroup
                                                          .title
                                                      }
                                                      handleRemoveMember={() => {
                                                        const orgGroupCopyFormik = formikProps.values.access.organizationGroups.slice();
                                                        const myIndex = _.findIndex(
                                                          formikProps.values
                                                            .access
                                                            .organizationGroups
                                                            .id,
                                                          (
                                                            orgGroup2: OrganizationGroupAccessInput
                                                          ) => {
                                                            return (
                                                              orgGroup
                                                                .organizationGroup
                                                                .id ===
                                                              orgGroup2.organizationGroup
                                                            );
                                                          }
                                                        );

                                                        orgGroupCopyFormik.splice(
                                                          myIndex,
                                                          1
                                                        );
                                                        formikProps.setFieldValue(
                                                          'access.organizationGroups',
                                                          orgGroupCopyFormik
                                                        );
                                                      }}
                                                      onChangeSelected={selectedKey => {
                                                        // TODO fix
                                                        const orgGroupCopy =
                                                          formikProps.values
                                                            .access
                                                            .organizationGroups;
                                                        const myIndex = _.findIndex(
                                                          orgGroupCopy,
                                                          (
                                                            organizationGroup: any
                                                          ) => {
                                                            return (
                                                              orgGroup
                                                                .organizationGroup
                                                                .id ===
                                                              organizationGroup
                                                                .organizationGroup
                                                                .id
                                                            );
                                                          }
                                                        );
                                                        const getEnum =
                                                          selectedKey ===
                                                          'WRITE'
                                                            ? AccessEnum.WRITE
                                                            : AccessEnum.READ;
                                                        orgGroupCopy[
                                                          myIndex
                                                        ].access = getEnum;
                                                        formikProps.setFieldValue(
                                                          'access.organizationGroups',
                                                          orgGroupCopy
                                                        );
                                                      }}
                                                      img={
                                                        <StaticImage
                                                          width={21}
                                                          height={21}
                                                          type={
                                                            IStaticImageType.ORGANIZATION
                                                          }
                                                        />
                                                      }
                                                      permissionLevel={{
                                                        value: 'WRITE',
                                                        name:
                                                          orgGroup.access ===
                                                          'WRITE'
                                                            ? 'skrivetilgang'
                                                            : 'Lesetilgang',
                                                      }}
                                                    />
                                                  </div>
                                                );
                                              } else {
                                                return 'error';
                                              }
                                            }
                                          )}

                                          {!formikProps.values.access
                                            .isPrivate && (
                                            <MembersOfAnnualCycleCardEveryoneComp
                                              // style={{marginTop: '-1.2em'}}
                                              onCancelClick={() => {
                                                formikProps.setFieldValue(
                                                  'access.isPrivate',
                                                  !formikProps.values.access
                                                    .isPrivate
                                                );
                                              }}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Button
                                        greyBackground={true}
                                        text={intl.formatMessage(
                                          commonLabels.Cancel
                                        )}
                                        theme={ColorTheme.White}
                                        onClick={() =>
                                          safeInvokeDeprecated(
                                            this.props.onCancel
                                          )
                                        }
                                        disabled={loading}
                                      />
                                      <Button
                                        greyBackground={true}
                                        isLoading={loading}
                                        buttonTheme={ButtonTheme.RED}
                                        text={intl.formatMessage(
                                          commonMessages.save
                                        )}
                                        theme={ColorTheme.Red}
                                        type={'submit'}
                                        style={{ marginLeft: '.5em' }}
                                        leftIcon={MunikumIcons.Save}
                                        // disabled={!formikProps.isValid}
                                      />
                                    </div>
                                  </RightContainer>
                                </Container>
                              </form>
                            );
                          }}
                        />
                      </>
                    );
                  }}
                />
              </div>
            );
          }}
        </TypedMutation>
      </ContentBox>
    );
  }
}

export const CreateAnnualCycleForm = injectIntl(CreateAnnualCycleFormComp);
