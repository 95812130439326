import * as React from 'react';
import {
  defineMessages,
  FormattedMessage,
  InjectedIntlProps,
  injectIntl,
} from 'react-intl';
import { Colors, removeNonHTMLProps } from '../../common';
import { ExternalLink } from '../../basic/Structural/links';
import styled from 'styled-components';
import { MunikumIcons } from '../../common/icons';

const messages = defineMessages({
  label: {
    id: 'SupportInfo.label',
    defaultMessage: 'For support:',
  },
  emailAddress: {
    id: 'SupportInfo.emailAddress',
    defaultMessage: 'post@munikum.no',
  },
  phone: {
    id: 'SupportInfo.phone',
    defaultMessage: '908 41 249',
  },
});

const InfoBox = styled.div`
  width: 11.125em;
  height: 7.75em;
  border: ${(props: { isLeftSideBarExpanded: boolean }) =>
    props.isLeftSideBarExpanded ? 'none' : '1px solid #ff5c39'};
  border-radius: 4px;
  color: ${Colors.WHITE};
  font-family: 'Lato', sans-serif;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SupportLink = styled.a`
  text-decoration: none;
  font-family: 'Lato', sans-serif;
  font-size: 1em;
  line-height: 1.5em;
  color: ${Colors.WHITE};

  border-bottom: 1px solid transparent;
  transition: border-bottom 0.2s ease-out;
  &:hover {
    border-bottom: 1px solid ${Colors.RED};
  }
`;

const InfoLabel = styled.div`
  font-weight: bold;
`;

export interface ISupportInfoProps {
  isLeftSidebarExpanded: boolean;
  // TODO: we should put email and phone here and store in db somewhere?
}

class SupportInfoComp extends React.PureComponent<
  ISupportInfoProps & InjectedIntlProps & React.HTMLProps<HTMLDivElement>,
  {}
> {
  render() {
    const { intl, ...rest } = this.props;

    return (
      <InfoBox
        isLeftSideBarExpanded={!this.props.isLeftSidebarExpanded}
        {...removeNonHTMLProps(rest)}
      >
        {this.props.isLeftSidebarExpanded && (
          <>
            <InfoLabel>
              <FormattedMessage
                id={'SupportInfo.label'}
                defaultMessage={'For support:'}
              />
            </InfoLabel>

            <div>
              <ExternalLink
                href={'mailto:' + intl.formatMessage(messages.emailAddress)}
                target={'_blank'}
                style={{ color: Colors.WHITE }}
              >
                {intl.formatMessage(messages.emailAddress)}
              </ExternalLink>
            </div>
          </>
        )}
        {!this.props.isLeftSidebarExpanded && (
          <ExternalLink
            href={'mailto:' + intl.formatMessage(messages.emailAddress)}
            target={'_blank'}
            style={{ color: Colors.WHITE }}
          >
            <MunikumIcons.Envelope
              style={{ marginBottom: '.5em', width: '1.2em', height: '1.2em' }}
            />
          </ExternalLink>
        )}
        <div>
          <SupportLink
            href={'tel:' + intl.formatMessage(messages.phone)}
            target={'_blank'}
            style={{ color: Colors.WHITE }}
          >
            {!this.props.isLeftSidebarExpanded ? (
              <MunikumIcons.Phone style={{ width: '1.2em', height: '1.2em' }} />
            ) : (
              intl.formatMessage(messages.phone)
            )}
          </SupportLink>
        </div>
      </InfoBox>
    );
  }
}

export const SupportInfo = injectIntl(SupportInfoComp);
