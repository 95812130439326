import * as React from 'react';
import { CSSProperties, RefObject } from 'react';
import { IconType } from 'react-icons/lib';

import styled from 'styled-components';
import {
  Colors,
  IDisabledProps,
  IStylable,
  IThemeProps,
  safeInvokeDeprecated,
} from '../../common';
import { punch } from '../../animation';
import { Tooltip } from '../Tooltip/Tooltip';

export enum IconButtonHoverStyle {
  ROUND = 'ROUND',
  SQUARE = 'SQUARE',
}

export interface IIconButtonProps {
  /**
   * Icon from react-icons library, see MunikumIcons enum!
   */
  icon: IconType; // IconType;

  /**
   * optional supply size (both width and height)
   * removed until we need it..
   */
  // size?: string;

  /**
   * number to display as badge
   * TODO: fancy animation :)
   */
  badge?: number;

  /**
   * ugly quick hack to disable global click event handler by adding a className to element. . .
   */
  disableGlobalClickHack?: boolean;

  hoverStyle?: IconButtonHoverStyle;

  // container style
  style?: CSSProperties;

  iconHoverColor?: string;

  iconColor?: string;

  tooltip?: JSX.Element | string;
}

export interface IIconButtonDispatch {
  onClick?: () => void;
}

export interface IIconButtonState {
  mouseHover: boolean;
  isPunching: boolean;
}

type IIconContainer = IDisabledProps & {
  size?: string;
  hover: boolean;
  theme: IThemeProps;
  hoverStyle: IconButtonHoverStyle;
};

// const hoverColor = ColorLib(Colors.GREY)
//   .alpha(0.4)
//   .string();

const defaultContainerSize = '2.2em';
const defaultIconSize = '1.5em';

const IconContainer = styled.div`
    background-color: ${(props: IIconContainer) =>
      !props.disabled && props.hover
        ? props.theme.noAccent.hoverColor
        : 'transparent'};
  border-radius: ${(props: IIconContainer) =>
    props.hoverStyle === IconButtonHoverStyle.ROUND ? '50%' : '3px'};
  width: ${(props: IIconContainer) => props.size || defaultContainerSize};
  height: ${(props: IIconContainer) => props.size || defaultContainerSize};
  
  display: flex;
  justify-content: center;
  align-items: center;
  
  transition: background-color .25s ease-in-out;

  cursor: ${(props: IIconContainer) =>
    props.disabled ? 'not-allowed' : 'pointer'}
  
  position: relative;
  
  &:active:enabled {
    // background-color: orange;
  }
  
  &:disabled {
    cursor: not-allowed;
  }
`;

const Badge = styled.div`
  background-color: ${Colors.RED};
  border-radius: 10px;

  width: ${(props: { count: number }) =>
    props.count > 9 ? '1.65em' : '1.25em'};
  height: 1.25em;

  padding: 0.25em 0.25em;

  position: absolute;
  top: -0.2em;
  left: ${(props: { count: number }) => (props.count > 9 ? '2em' : '2em')};

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Lato, sans-serif;
  color: ${props => props.theme.noAccent.color};
  font-size: 0.65em;
  font-weight: bold;
`;

const InnerIconContainer = styled.div`
  //background-color: aquamarine;
  ${(props: {
    isHovering: boolean;
    theme: IThemeProps;
    isRunning: boolean;
    disabled: boolean;
  }) => props.isRunning && ` animation: ${punch} .75s ease-in-out 1`};
  color: ${(props: {
    isHovering: boolean;
    theme: IThemeProps;
    isRunning: boolean;
    disabled: boolean;
  }) =>
    props.disabled
      ? props.theme.noAccent.disabledColor
      : props.isHovering
      ? props.theme.accent1.color
      : props.isHovering
      ? props.theme.accent1.hoverColor
      : props.theme.noAccent.textColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export class IconButton extends React.PureComponent<
  IIconButtonProps & IIconButtonDispatch & IDisabledProps & IStylable,
  IIconButtonState
> {
  private myRef: RefObject<HTMLDivElement>;

  constructor(props: IIconButtonProps & IIconButtonDispatch & IStylable) {
    super(props);

    this.state = {
      mouseHover: false,
      isPunching: false,
    };

    this.myRef = React.createRef();
  }

  componentDidMount() {
    if (this.myRef.current) {
      this.myRef.current.addEventListener('animationend', this.punchDone);
    }
  }

  componentWillUnmount() {
    if (this.myRef.current) {
      this.myRef.current.removeEventListener('animationend', this.punchDone);
    }
  }

  punchDone = () => {
    this.setState({
      isPunching: false,
    });
  };

  punch = () => {
    this.setState({
      isPunching: true,
    });
  };

  // tslint:disable-next-line
  handleClick = (e: any) => {
    // console.log('iconButt click');

    if (this.props.disabled) {
      return;
    }

    e.stopPropagation();
    safeInvokeDeprecated(this.props.onClick);
  };

  handleMouse = (isHover: boolean) => {
    this.setState({
      mouseHover: isHover,
    });
  };

  render() {
    const Icon = this.props.icon;

    const {
      disabled,
      badge,
      style,
      disableGlobalClickHack,
      hoverStyle,
    } = this.props;
    const { mouseHover, isPunching } = this.state;

    let badgeText = '';
    if (badge !== undefined) {
      if (badge > 9) {
        badgeText = '9+';
      } else if (badge > 0) {
        badgeText = badge.toString();
      }
    }

    // const badgeText = badge !== undefined && badge > 0 && badge > 9 ? '9+' : badge;

    const butt = (
      <IconContainer
        disabled={disabled}
        onMouseEnter={() => this.handleMouse(true)}
        onMouseLeave={() => this.handleMouse(false)}
        onClick={this.handleClick}
        size={defaultContainerSize}
        hover={mouseHover}
        hoverStyle={hoverStyle || IconButtonHoverStyle.ROUND}
        style={style}
        className={disableGlobalClickHack ? 'disable-global-click' : ''}
      >
        {/*{isPunching ? 'T': 'F'}*/}
        <InnerIconContainer
          isHovering={mouseHover || isPunching}
          disabled={disabled}
          ref={this.myRef}
          isRunning={isPunching}
          style={{
            width: defaultIconSize,
            height: defaultIconSize,
          }}
        >
          <Icon
            onClick={this.handleClick}
            // width={defaultIconSize}
            // height={defaultIconSize}
            width={'100%'}
            height={'100%'}
            size={'100%'}
            style={{
              transition: 'fill .25s ease-in-out', // TODO: when punching we should change this..
            }}
          />
        </InnerIconContainer>

        {badge !== undefined && badge > 0 && (
          <Badge count={badge}>{badgeText}</Badge>
        )}
      </IconContainer>
    );

    if (this.props.tooltip !== undefined) {
      return (
        <Tooltip content={this.props.tooltip}>
          <div
            style={{
              display: 'inline-block',
            }}
          >
            {butt}
          </div>
        </Tooltip>
      );
    } else {
      return butt;
    }
  }
}
