/***
 * rootReducer and RootState
 */
import { combineReducers, Reducer } from 'redux';
import { ILoginState, reducer as authReducer } from './auth/auth';
import { IPersonState, reducer as personsReducer } from './person/person';
import {
  INetworkState,
  reducer as networkReducer,
} from './network/networkStatus';
// import { IHomeState, reducer as homeReducer } from './home/home';
import { IUiState, reducer as uiReducer } from './ui/ui';
import {
  IEntitiesState,
  reducer as entitiesReducer,
} from './entities/entities';
import {
  IDiscussionState,
  reducer as discussionReducer,
} from './discussion/discussion';
import {
  ISettingsState,
  reducer as settingsReducer,
} from './settings/settings';
import {
  IServiceWorkerState,
  reducer as serviceWorkerReducer,
} from './ui/serviceWorker/serviceWorkerRedux';

import {
  IOnboardingState,
  reducer as onboardingReducer,
} from './onboarding/onboarding';
import {
  IActionValueState,
  reducer as actionValueReducer,
} from './actionValue/actionValue';

import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { reducer as topicReducer } from './topic/topic';
import {
  INotificationState,
  reducer as notificationReducer,
} from './notification/notification';
import {
  calendarReducer,
  ICalendarFilterState,
} from '../containers/AnnualCycle/calendar-service';
import {
  ITopicFilterState,
  topicFilterReducer,
} from '../services/topicService';

/***
 * redux global state!
 */
export type RootState = Readonly<{
  auth: Readonly<ILoginState>;
  // home: Readonly<IHomeState>;
  calendar: ICalendarFilterState;
  network: Readonly<INetworkState>;
  ui: Readonly<IUiState>;
  entities: Readonly<IEntitiesState>;
  notifications: INotificationState;
  topicFilter: ITopicFilterState;
  person: IPersonState;
  actionValue: IActionValueState;
  discussion: IDiscussionState;
  settings: Readonly<ISettingsState>;
  serviceWorker: Readonly<IServiceWorkerState>;
  onboarding: Readonly<IOnboardingState>;
}>;

const whitelist = [
  'persons',
  'sectors',
  'kostraFunctions',
  'organizationTypes',
];

export const rootReducer: Reducer<Readonly<RootState>> = combineReducers<
  RootState
>({
  auth: authReducer,
  // home: homeReducer,
  network: networkReducer,
  // router: routerReducer,
  ui: uiReducer,
  entities: persistReducer(
    { key: 'entities', storage: storage, whitelist: whitelist },
    entitiesReducer
  ),
  notifications: notificationReducer,
  person: personsReducer,
  actionValue: actionValueReducer,
  discussion: discussionReducer,
  settings: persistReducer(
    { key: 'settings', storage: storage },
    settingsReducer
  ),
  serviceWorker: serviceWorkerReducer,
  onboarding: onboardingReducer,
  calendar: calendarReducer,
  topicFilter: topicFilterReducer,
  topic: topicReducer,
});
