import moment from 'moment';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import * as React from 'react';
import { CSSProperties } from 'react';
import * as _ from 'lodash';
import styled from 'styled-components';
import { commonMessages } from '../language/commonMessages';
import { Colors, formatMunikumDate, IThemeProps } from '../common';
import { ButtonSize, NavButton } from '../basic';
import { MunikumIcons } from '../common/icons';
import { H3 } from '../basic/Structural/typography';
import { DeadlineType } from '../../models/types';

export interface IDeadlinesViewProps {
  deadlines?: DeadlineType[];
  title: string;
  style?: CSSProperties;
  showDefaultMessage?: string;
  meta?: string;
  calendarCode?: string;
}

const messages = defineMessages({
  hideAllDeadlines: {
    id: 'DeadlinesView.hidealldeadlines',
    defaultMessage: 'Hide all deadlines',
  },
  showAllDeadlines: {
    id: 'DeadlinesView.showalldeadlines',
    defaultMessage: 'Show all deadlines',
  },
});

const ExpandDeadlinesLink = styled.a`
  text-decoration: none;
  font-family: 'Lato', sans-serif;
  font-size: 0.625em;
  line-height: 0.75em;
  color: ${(props: any) => props.color || Colors.BLACK};
  border-bottom: solid 1px transparent;
  transition: border-bottom 0.2s ease-out;
  cursor: pointer;

  &:hover {
    border-bottom: solid 1px ${Colors.BLACK};
  }
`;

const Deadline = styled.div`
  color: ${(props: { isExpired: boolean }) =>
    props.isExpired ? Colors.GREY208 : Colors.RED};
  font-size: 1.5em;
  text-align: center;
  line-height: 1.6em;
  white-space: nowrap;
  float: left;

  //margin-right: 1.5em;
`;

const MiniContainer = styled.div`
  min-width: 7.5em;
`;

const ToggleDiv = styled.div`
  display: ${(props: { isVisible: boolean }) =>
    props.isVisible ? 'block' : 'none'};
  //background-color: rebeccapurple;
`;

/**
 * Expandable deadlines view.
 */
class DeadlinesViewComp extends React.PureComponent<
  IDeadlinesViewProps & InjectedIntlProps,
  { isExpanded: boolean }
> {
  constructor(props: IDeadlinesViewProps & InjectedIntlProps) {
    super(props);

    this.state = {
      isExpanded: false,
    };
  }

  handleExpand = (exp: boolean) => {
    this.setState({
      isExpanded: exp,
    });
  };

  render() {
    const { isExpanded } = this.state;
    const { deadlines, title, style, intl, showDefaultMessage } = this.props;

    if (
      deadlines === undefined ||
      deadlines === null ||
      deadlines.length === 0
    ) {
      // return null;
      return showDefaultMessage !== undefined ? (
        <div style={style}>
          <H3>{title}</H3>
          <Deadline isExpired={false}>{showDefaultMessage}</Deadline>
        </div>
      ) : null;
    }

    // DESC last deadline first..
    const sortedDeadlines = _.reverse(_.sortBy(deadlines, 'date'));

    let nextDeadline = moment(sortedDeadlines[0].date);

    const now = moment();
    sortedDeadlines.forEach((value, index) => {
      const x = moment(value.date);
      if (x.isAfter(now)) {
        nextDeadline = x;
      }
    });

    return (
      <div style={style}>
        <H3>{title}</H3>

        {!isExpanded && (
          <div style={{ display: 'flex' }}>
            <Deadline
              isExpired={nextDeadline.isBefore(moment())}
              key={'dldl_next'}
              style={{ marginRight: '.5em' }}
            >
              {nextDeadline.isBefore(moment()) ? (
                <del>{formatMunikumDate(nextDeadline)}</del>
              ) : (
                formatMunikumDate(nextDeadline)
              )}
            </Deadline>
            <NavButton
              text={intl.formatMessage(commonMessages.ExportToCalender)}
              size={ButtonSize.Medium}
              leftIcon={MunikumIcons.Date}
              navigateTo={
                process &&
                process.env &&
                process.env.REACT_APP_API +
                  '/meta/calendar?calendarCode=' +
                  this.props.calendarCode +
                  '&metaId=' +
                  this.props.meta +
                  '&deadlineId=' +
                  sortedDeadlines[0].id
              }
              openInNewWindow={true}
              tooltip={intl.formatMessage(commonMessages.ExportToCalender)}
            />
          </div>
        )}

        {sortedDeadlines.length > 1 && (
          <ToggleDiv isVisible={isExpanded}>
            {_.reverse(sortedDeadlines).map((value, index) => {
              const isExpired = moment(value.date).isBefore(moment());
              return (
                <div key={'dldl' + index} style={{ display: 'flex' }}>
                  <Deadline
                    isExpired={isExpired}
                    style={{ marginRight: '.5em' }}
                  >
                    {formatMunikumDate(value.date)}
                  </Deadline>
                  <NavButton
                    text={intl.formatMessage(commonMessages.ExportToCalender)}
                    size={ButtonSize.Medium}
                    leftIcon={MunikumIcons.Date}
                    style={{ marginTop: '0.125em', marginBottom: '.125em' }}
                    navigateTo={
                      process &&
                      process.env &&
                      process.env.REACT_APP_API +
                        '/meta/calendar?calendarCode=' +
                        this.props.calendarCode +
                        '&metaId=' +
                        this.props.meta +
                        '&deadlineId=' +
                        value.id
                    }
                    openInNewWindow={true}
                    tooltip={intl.formatMessage(
                      commonMessages.ExportToCalender
                    )}
                  />
                </div>
              );
            })}
          </ToggleDiv>
        )}

        {isExpanded && sortedDeadlines.length > 1 && (
          <ExpandDeadlinesLink
            onClick={() => {
              this.handleExpand(false);
            }}
          >
            {intl.formatMessage(messages.hideAllDeadlines)}
          </ExpandDeadlinesLink>
        )}
        {!isExpanded && sortedDeadlines.length > 1 && (
          <ExpandDeadlinesLink
            onClick={() => {
              this.handleExpand(true);
            }}
          >
            {intl.formatMessage(messages.showAllDeadlines)}
          </ExpandDeadlinesLink>
        )}
      </div>
    );
  }
}

export const DeadlinesView = injectIntl(DeadlinesViewComp);

const MiniTitle = styled.div`
  font-family: Lato, sans-serif;
  text-transform: uppercase;
  font-size: 0.625em;
  color: ${props => props.theme.textColor};
  letter-spacing: 0.36px;
  font-weight: normal;
`;

const MiniDeadline = styled.div`
  font-family: Lato, sans-serif;
  font-size: 0.875em;
  color: ${(props: { isExpired: boolean; theme: IThemeProps }) =>
    props.isExpired
      ? props.theme.textDisabledColor
      : props.theme.accent1.color};
  letter-spacing: 0.36px;
  //font-weight: bold;
`;

/**
 * mini version, smaller text, no expand
 * @param {IDeadlinesViewProps & {children?: React.ReactNode}} props
 * @returns {any}
 * @constructor
 */
const DeadlineViewMiniComp: React.SFC<IDeadlinesViewProps &
  InjectedIntlProps> = props => {
  if (
    props.deadlines === undefined ||
    props.deadlines === null ||
    props.deadlines.length === 0
  ) {
    return props.showDefaultMessage !== undefined ? (
      <MiniContainer style={props.style}>
        <MiniTitle>{props.title}</MiniTitle>
        <MiniDeadline isExpired={false}>
          {props.showDefaultMessage}
        </MiniDeadline>
      </MiniContainer>
    ) : null;
  }

  // DESC last deadline first..
  const sortedDeadlines = _.reverse(_.sortBy(props.deadlines, 'date'));

  let nextDeadline = moment(sortedDeadlines[0].date);
  const now = moment();
  sortedDeadlines.forEach((value, index) => {
    const x = moment(value.date);
    if (x.isAfter(now)) {
      nextDeadline = x;
    }
  });

  return (
    <MiniContainer style={props.style}>
      <MiniTitle>{props.title}</MiniTitle>
      <MiniDeadline isExpired={nextDeadline.isBefore(moment())}>
        {nextDeadline.isBefore(moment()) ? (
          <del>{formatMunikumDate(nextDeadline)}</del>
        ) : (
          formatMunikumDate(nextDeadline)
        )}
      </MiniDeadline>
    </MiniContainer>
  );
};

export const DeadlineViewMini = injectIntl(DeadlineViewMiniComp);
