import { IEntity } from './entity';
import { IPersonSimple } from './discussion';
import { ColorTheme } from '../../components/common';

export interface IPerson extends IEntity {
  name: string;
  hash?: string;
  email?: string;
  userStatus: UserStatus; // number;
  jobTitle?: string;
  department?: string;
  language?: string;

  // not implemented in api yet on list?:
  roles?: Array<string>;
  description?: string;
  dateCreated?: Date;
  lastUpdated?: Date;
  calendarCode?: string;
  imgUrl?: string; // dummy profile image url
  organization?: string;
  organizations?: Array<string>;
  simple?: IPersonSimple;
  permissions?: Array<string>;
  channel?: string;
  isSidebarExpanded?: boolean;
  theme?: ColorTheme;
}

export enum UserStatus {
  PENDING = 0,
  ACTIVATED = 1,
  DEACTIVATED = 2,
  NEED_INFO = 3,
}

// TODO: add more props..
// TODO: interface or class? (not type because of stacktrace name?) => github desktop uses class, but interface is fine?
