import ColorLib from 'color';
import { defineMessages } from 'react-intl';
import { teamsContext } from '../../index';

export const Colors = {
  RED: '#FF5C39',
  BADASS: '#BADA55',
  DARKRED: '#963821',
  REDCLICK: 'rgba(255,92,57,0.8)',
  BLUE: '#00B0B9',
  DARKBLUE: '#00778B',
  DARKYELLOW: '#A37F14',
  YELLOW: '#F2A900',
  BLACK: '#333333',
  EVENLIGHTERBLACK: '#4d4d4d',
  BLACKLITE: '#3B3B3B',
  BLACKER: '#222222',
  EVENBLACKER: '#111111',
  REALBLACK: '#000000',
  BLACK50: ColorLib('#333333')
    .alpha(0.5)
    .string(),
  BLACK70: ColorLib('#333333')
    .alpha(0.7)
    .string(),
  GREY: '#D0D3D4',
  GREY208: 'rgba(208,211,212,1)',
  GREYCLICK: ColorLib('#D0D3D4')
    .alpha(0.5)
    .lighten(0.5)
    .string(),
  GREYLABEL: 'rgba(51,51,51,0.7)',
  GREYOPACITY: 'rgba(245,245,245, .8)',
  GREYHOVEROPACITY: 'rgba(245,245,245)',
  GREYHOVEROPACITYEXTREME: 'rgba(225,225,225, .2)',
  GREYDISABLEDTEXT: ColorLib('#354052')
    .alpha(0.5)
    .string(),
  GREYPLACEHOLDER: ColorLib('rgba(51,51,51,0.7)')
    .alpha(0.5)
    .string(),
  GREYBORDER: 'rgba(208,211,212,0.6)',
  GREYDISABLED: 'rgba(208,211,212,0.4)',
  WHITE: '#FFFFFF',
  WHITEBACKGROUND: '#F5F5F5',
  GREYTEXT: '#565656;',
  ROW_BACKGROUND: 'rgba(208,211,212,0.2)',
  ROW_BACKGROUND_HOVER: ColorLib('#00B0B9')
    .alpha(0.2)
    .string(),
};

export enum ColorTheme {
  Red,
  Blue,
  White,
  Yellow,
  Black,
  GREY,
}

export interface IAccentColorSet {
  color: string; // Main color
  textColor: string; // Text color fit for use against main color
  hoverColor: string;
  iconRedThemeColor: string;
  buttonRedThemeBackgroundColor: string;
  buttonColorRedTheme: string;
  buttonBorderColorRedTheme: string;
  buttonHoverColorRedTheme: string;

  disabledColor: string;
  activeColor: string;
  hoverTextColor: string;
  iconColor: string;
  borderColor: string;
  borderHoverColor: string;
  borderActiveColor: string;
}

export const DEFAULT_COLOR_THEME = ColorTheme.Red;

export interface IThemeProps {
  noAccent: IAccentColorSet; // plain accent set
  accent1: IAccentColorSet; // red
  accent2: IAccentColorSet; // dark blue
  accent3: IAccentColorSet; // blue
  accent4: IAccentColorSet; // yellow

  textColor: string; // black
  iconColor: string;
  buttonColorTertiaryBackground: string;
  textSecondary: string;
  textInvertedColor: string; // white
  textLabel: string;
  textDisabledColor: string;
  iconRedThemeColor: string;
  menuBackgroundColor: string; // Background color of left menu
  contentBackgroundColor: string; // Background color of content boxes
  contentBackgroundHoverColor: string; // Background color of content boxes when hovering
  secondaryContentBackgroundColor: string; // Background color for content boxes in content boxes
  tertiaryContentBackgroundColor: string; // Background color for content boxes in content boxes
  bodyBackgroundColor: string; // Base background color
}

const defaultTheme: IThemeProps = {
  noAccent: {
    iconColor: Colors.GREYDISABLEDTEXT,
    color: Colors.GREYOPACITY,
    textColor: Colors.BLACK,
    hoverColor: Colors.GREYHOVEROPACITY,
    hoverTextColor: Colors.WHITE,
    disabledColor: Colors.GREYDISABLED,
    buttonColorRedTheme: Colors.RED,
    buttonHoverColorRedTheme: Colors.GREYHOVEROPACITY,
    buttonBorderColorRedTheme: Colors.RED,
    activeColor: Colors.WHITE,
    iconRedThemeColor: Colors.RED,
    buttonRedThemeBackgroundColor: Colors.WHITE,
    borderColor: Colors.GREYBORDER,
    borderHoverColor: Colors.GREY,
    borderActiveColor: ColorLib(Colors.GREYOPACITY)
      .alpha(0.8)
      .string(),
  },

  accent1: {
    iconColor: Colors.RED,
    buttonRedThemeBackgroundColor: Colors.WHITE,
    buttonColorRedTheme: Colors.RED,
    buttonBorderColorRedTheme: Colors.RED,
    buttonHoverColorRedTheme: Colors.GREYHOVEROPACITY,
    iconRedThemeColor: Colors.RED,
    color: Colors.RED,
    hoverTextColor: Colors.WHITE,
    textColor: Colors.WHITE,
    hoverColor: Colors.BLUE,
    disabledColor: Colors.RED,
    activeColor: Colors.RED,
    borderColor: Colors.BLACK,
    borderHoverColor: Colors.REALBLACK,
    borderActiveColor: ColorLib(Colors.BLACK)
      .alpha(0.8)
      .string(),
  },
  accent2: {
    iconColor: Colors.GREYDISABLEDTEXT,
    color: Colors.DARKBLUE,
    textColor: Colors.WHITE,
    hoverTextColor: Colors.WHITE,
    hoverColor: Colors.BLUE,
    disabledColor: Colors.RED,
    buttonRedThemeBackgroundColor: Colors.WHITE,
    iconRedThemeColor: Colors.RED,
    buttonColorRedTheme: Colors.RED,
    buttonBorderColorRedTheme: Colors.RED,
    buttonHoverColorRedTheme: Colors.GREYHOVEROPACITY,
    activeColor: Colors.RED,
    borderColor: Colors.BLACK,
    borderHoverColor: Colors.REALBLACK,
    borderActiveColor: ColorLib(Colors.BLACK)
      .alpha(0.8)
      .string(),
  },
  accent3: {
    iconColor: Colors.GREYDISABLEDTEXT,
    color: Colors.BLUE,
    textColor: Colors.WHITE,
    hoverColor: Colors.BLUE,
    hoverTextColor: Colors.WHITE,
    disabledColor: Colors.RED,
    buttonRedThemeBackgroundColor: Colors.WHITE,
    iconRedThemeColor: Colors.RED,
    buttonColorRedTheme: Colors.RED,
    buttonBorderColorRedTheme: Colors.RED,
    buttonHoverColorRedTheme: Colors.GREYHOVEROPACITY,
    activeColor: Colors.RED,
    borderColor: Colors.BLACK,
    borderHoverColor: Colors.REALBLACK,
    borderActiveColor: ColorLib(Colors.BLACK)
      .alpha(0.8)
      .string(),
  },
  accent4: {
    iconColor: Colors.GREYDISABLEDTEXT,
    color: Colors.YELLOW,
    hoverTextColor: Colors.WHITE,
    textColor: Colors.WHITE,
    buttonRedThemeBackgroundColor: Colors.WHITE,
    buttonBorderColorRedTheme: Colors.RED,
    buttonColorRedTheme: Colors.RED,
    buttonHoverColorRedTheme: Colors.GREYHOVEROPACITY,
    iconRedThemeColor: Colors.RED,
    hoverColor: Colors.BLUE,
    disabledColor: Colors.RED,
    activeColor: Colors.RED,
    borderColor: Colors.BLACK,
    borderHoverColor: Colors.REALBLACK,
    borderActiveColor: ColorLib(Colors.BLACK)
      .alpha(0.8)
      .string(),
  },

  textColor: Colors.BLACK,
  textInvertedColor: Colors.WHITE,
  textDisabledColor: Colors.GREYDISABLEDTEXT,
  textSecondary: Colors.GREYPLACEHOLDER,
  textLabel: Colors.GREYLABEL,
  iconColor: Colors.GREYLABEL,
  menuBackgroundColor: Colors.BLACK,
  iconRedThemeColor: Colors.RED,
  contentBackgroundColor: Colors.WHITE,
  buttonColorTertiaryBackground: Colors.WHITE,
  contentBackgroundHoverColor: Colors.BLUE,
  bodyBackgroundColor: Colors.WHITEBACKGROUND,
  secondaryContentBackgroundColor: Colors.WHITE,
  tertiaryContentBackgroundColor: Colors.WHITEBACKGROUND,
};

const blackTheme: IThemeProps = {
  noAccent: {
    iconColor: Colors.WHITE,
    color: Colors.BLACKLITE,
    iconRedThemeColor: Colors.WHITE,
    textColor: Colors.WHITE,
    hoverColor: Colors.GREYHOVEROPACITYEXTREME,
    buttonRedThemeBackgroundColor: Colors.RED,
    buttonBorderColorRedTheme: Colors.RED,
    buttonColorRedTheme: Colors.WHITE,
    buttonHoverColorRedTheme: Colors.DARKRED,
    hoverTextColor: Colors.WHITE,
    disabledColor: Colors.BLACKLITE,
    activeColor: Colors.WHITE,
    borderColor: Colors.GREYBORDER,
    borderHoverColor: Colors.BLACKLITE,
    borderActiveColor: ColorLib(Colors.BLACK)
      .alpha(0.8)
      .string(),
  },
  accent1: {
    iconColor: Colors.WHITE,
    color: Colors.RED,
    hoverTextColor: Colors.WHITE,
    textColor: Colors.WHITE,
    iconRedThemeColor: Colors.WHITE,
    hoverColor: Colors.BLUE,
    buttonRedThemeBackgroundColor: Colors.RED,
    buttonColorRedTheme: Colors.WHITE,
    buttonBorderColorRedTheme: Colors.RED,
    buttonHoverColorRedTheme: Colors.DARKRED,
    disabledColor: Colors.RED,
    activeColor: Colors.RED,
    borderColor: Colors.BLACK,
    borderHoverColor: Colors.REALBLACK,
    borderActiveColor: ColorLib(Colors.BLACK)
      .alpha(0.8)
      .string(),
  },
  accent2: {
    iconColor: Colors.WHITE,
    color: Colors.DARKBLUE,
    textColor: Colors.WHITE,
    buttonRedThemeBackgroundColor: Colors.RED,
    buttonColorRedTheme: Colors.WHITE,
    iconRedThemeColor: Colors.WHITE,
    hoverColor: Colors.BLUE,
    hoverTextColor: Colors.WHITE,
    disabledColor: Colors.RED,
    buttonBorderColorRedTheme: Colors.RED,
    buttonHoverColorRedTheme: Colors.DARKRED,
    activeColor: Colors.RED,
    borderColor: Colors.BLACK,
    borderHoverColor: Colors.REALBLACK,
    borderActiveColor: ColorLib(Colors.BLACK)
      .alpha(0.8)
      .string(),
  },
  accent3: {
    iconColor: Colors.WHITE,
    color: Colors.BLUE,
    hoverTextColor: Colors.WHITE,
    textColor: Colors.WHITE,
    iconRedThemeColor: Colors.WHITE,
    hoverColor: Colors.BLUE,
    buttonRedThemeBackgroundColor: Colors.RED,
    buttonColorRedTheme: Colors.WHITE,
    buttonBorderColorRedTheme: Colors.RED,
    buttonHoverColorRedTheme: Colors.DARKRED,
    disabledColor: Colors.RED,
    activeColor: Colors.RED,
    borderColor: Colors.BLACK,
    borderHoverColor: Colors.REALBLACK,
    borderActiveColor: ColorLib(Colors.BLACK)
      .alpha(0.8)
      .string(),
  },
  accent4: {
    iconColor: Colors.WHITE,
    color: Colors.YELLOW,
    textColor: Colors.WHITE,
    hoverColor: Colors.BLUE,
    iconRedThemeColor: Colors.WHITE,
    buttonRedThemeBackgroundColor: Colors.RED,
    buttonColorRedTheme: Colors.WHITE,
    buttonBorderColorRedTheme: Colors.RED,
    buttonHoverColorRedTheme: Colors.DARKRED,
    hoverTextColor: Colors.WHITE,
    disabledColor: Colors.RED,
    activeColor: Colors.RED,
    borderColor: Colors.BLACK,
    borderHoverColor: Colors.REALBLACK,
    borderActiveColor: ColorLib(Colors.BLACK)
      .alpha(0.8)
      .string(),
  },

  textColor: Colors.WHITE,
  textInvertedColor: Colors.BLACK,
  textSecondary: Colors.GREY,
  textDisabledColor: Colors.GREYDISABLEDTEXT,
  iconRedThemeColor: Colors.WHITE,
  iconColor: Colors.GREYLABEL,
  buttonColorTertiaryBackground: Colors.WHITE,
  menuBackgroundColor: Colors.BLACK,
  contentBackgroundColor: Colors.BLACKER,
  contentBackgroundHoverColor: Colors.BLACKLITE,
  bodyBackgroundColor: Colors.EVENBLACKER,
  textLabel: Colors.WHITEBACKGROUND,
  secondaryContentBackgroundColor: Colors.BLACKLITE,
  tertiaryContentBackgroundColor: Colors.EVENLIGHTERBLACK,
};

export const getThemeProps = (
  theme: ColorTheme = DEFAULT_COLOR_THEME
): IThemeProps => {
  if (teamsContext) {
    if (teamsContext.theme === 'dark' || teamsContext.theme === 'contrast') {
      return blackTheme;
    } else {
      return defaultTheme;
    }
  }
  switch (theme) {
    case ColorTheme.Black:
      return blackTheme;
    case ColorTheme.Red:
    default:
      return defaultTheme;
  }
  // if (theme === ColorTheme.Blue) {
  //   return {
  //     accentColor1: Colors.RED,
  //     accentColor2: Colors.BLUE,
  //     neutralColor: Colors.WHITE,
  //     borderColor: Colors.BLUE,
  //     borderHoverColor: Colors.DARKBLUE,
  //     backgroundColor: Colors.BLUE,
  //     backgroundHoverColor: Colors.DARKBLUE,
  //     backgroundDisabledColor: Colors.GREYDISABLED,
  //     textColor: Colors.WHITE,
  //     textInvertedColor: Colors.BLACK,
  //     backgroundActiveColor: ColorLib(Colors.BLUE)
  //       .alpha(0.8)
  //       .string(),
  //     textDisabledColor: Colors.GREYDISABLEDTEXT,
  //     borderActiveColor: ColorLib(Colors.BLUE)
  //     borderActiveColor: ColorLib(Colors.BLUE)
  //       .alpha(0.8)
  //       .string(),
  //     menuBackgroundColor: Colors.BLACK,
  //     contentBackgroundColor: Colors.WHITE,
  //     bodyBackgroundColor: Colors.WHITEBACKGROUND,
  //     textSecondary: Colors.GREYPLACEHOLDER,
  //     textLabel: Colors.GREYLABEL,
  //     secondaryContentBackgroundColor: Colors.WHITE,
  //   };
  // }
  // if (theme === ColorTheme.White) {
  //   return {
  //     accentColor1: Colors.RED,
  //     accentColor2: Colors.BLUE,
  //     neutralColor: Colors.WHITE,
  //     borderColor: Colors.GREYBORDER,
  //     borderHoverColor: Colors.GREYBORDER,
  //     backgroundColor: Colors.WHITE,
  //     backgroundHoverColor: ColorLib(Colors.GREY208)
  //       .alpha(0.4)
  //       .string(),
  //     backgroundDisabledColor: Colors.GREYDISABLED,
  //     textColor: Colors.BLACK,
  //     textInvertedColor: Colors.WHITE,
  //     backgroundActiveColor: ColorLib(Colors.GREY208)
  //       .alpha(0.2)
  //       .string(),
  //     textDisabledColor: Colors.GREYDISABLEDTEXT,
  //     borderActiveColor: Colors.GREYBORDER,
  //     menuBackgroundColor: Colors.BLACK,
  //     contentBackgroundColor: Colors.WHITE,
  //     bodyBackgroundColor: Colors.WHITEBACKGROUND,
  //     textSecondary: Colors.GREYPLACEHOLDER,
  //     textLabel: Colors.GREYLABEL,
  //     secondaryContentBackgroundColor: Colors.WHITE,
  //   };
  // }
  // if (theme === ColorTheme.Yellow) {
  //   return {
  //     accentColor1: Colors.RED,
  //     accentColor2: Colors.BLUE,
  //     neutralColor: Colors.WHITE,
  //     borderColor: Colors.YELLOW,
  //     borderHoverColor: Colors.DARKYELLOW,
  //     backgroundColor: Colors.YELLOW,
  //     backgroundHoverColor: Colors.DARKYELLOW,
  //     backgroundDisabledColor: Colors.GREYDISABLED,
  //     textColor: Colors.WHITE,
  //     textInvertedColor: Colors.BLACK,
  //     backgroundActiveColor: ColorLib(Colors.YELLOW)
  //       .alpha(0.8)
  //       .string(),
  //     textDisabledColor: Colors.GREYDISABLEDTEXT,
  //     borderActiveColor: ColorLib(Colors.YELLOW)
  //       .alpha(0.8)
  //       .string(),
  //     menuBackgroundColor: Colors.BLACK,
  //     contentBackgroundColor: Colors.WHITE,
  //     bodyBackgroundColor: Colors.WHITEBACKGROUND,
  //     textSecondary: Colors.GREYPLACEHOLDER,
  //     textLabel: Colors.GREYLABEL,
  //     secondaryContentBackgroundColor: Colors.WHITE,
  //   };
  // }
};

export const themeMessages = defineMessages({
  light: {
    id: 'theme.light',
    defaultMessage: 'Light Theme',
  },
  dark: {
    id: 'theme.dark',
    defaultMessage: 'Dark Theme',
  },
});
