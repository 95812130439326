import { keyframes } from 'styled-components';

export const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  
  to {
    transform: rotate(360deg);
  }
`;

export const fadeIn = keyframes`
  from {
    transform: scale(.25);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(.25);
    opacity: 0;
  }
`;

export const punch = keyframes`
  0% {
    transform: scale(1);
    transform-origin: center;
  }
  30% {
    transform: scale(1.2);
    transform-origin: center;
  }
  65% {
    transform: scale(.93);
    transform-origin: center;
  }
  100% {
    transform: scale(1);  
    transform-origin: center;
  }
`;
