import { InjectedIntlProps } from 'react-intl';
import * as React from 'react';
import { CSSProperties, RefObject } from 'react';
import styled from 'styled-components';
import {
  ContentBlock,
  DraftEditorCommand,
  DraftHandleValue,
  EditorState,
  getDefaultKeyBinding,
  Modifier,
  RichUtils,
} from 'draft-js';
import isSoftNewlineEvent from 'draft-js/lib/isSoftNewlineEvent';
import PluginEditor from 'draft-js-plugins-editor';

import './Draft.css'; // basic, default draft.js styles
import './MuInternalEditor.css'; // custom override draft.js styles
import './plugins/plugin-mentions.css'; // custom plugin styles
import './plugins/plugin-linkify.css';

import createMentionPlugin, {
  defaultSuggestionsFilter,
} from 'draft-js-mention-plugin';
import createLinkifyPlugin from 'draft-js-linkify-plugin';
import { isFunction, safeInvoke, safeInvokeDeprecated } from '../../common';
import { MuEditorMode } from './MuEditor';
import { mentions } from './plugins/mentions';
import { MuToolbarMode } from './MuToolbar';
import { MunikumKeys } from '../../common/keys';

/**
 * Default EditorWrapper
 * only cursor and font styling. If you need a textarea/box, padding, etc wrap this =)
 * test without flexbox
 */
const EditorDiv = styled.div`
  cursor: ${(props: { isEditing: boolean }) =>
    props.isEditing ? 'text' : 'cursor'} !important;
  color: ${props => props.theme.textColor};
`;

function muDefaultBlockStyleFn(block: ContentBlock): string {
  const type = block.getType();

  switch (type) {
    case 'unstyled':
      return 'muParagraph';
    case 'blockquote':
      return 'muQuote';
    case 'code-block':
      return 'muCode';
    default:
      return 'muBlock';
  }
}

export interface IMuInternalEditorProps {
  name?: string;

  id?: string;

  placeholder?: string;

  style?: CSSProperties;

  /**
   * set in controlled mode
   */
  editorMode?: MuEditorMode;

  /**
   * set in controlled mode
   */
  toolbarMode?: MuToolbarMode;

  /**
   * set in controlled mode
   */
  draftState?: EditorState;

  /**
   * set to true to enable mentions and other stuff
   */
  enableCoolBetaStuff?: boolean;

  /**
   * override default styles func
   * @param block
   */
  muBlockStyleFn?: (block: ContentBlock) => string;

  /**
   * override default return handling
   * @param e
   * @param state
   */
  muHandleReturnFn?: (e: any, state: EditorState) => DraftHandleValue;

  /**
   * listen to this to put in controlled mode
   * @param draftState
   */
  onChange?: (draftState: EditorState) => void;

  /**
   * escape key pressed
   */
  onEscape?: () => void;

  onBlur?: (e: any) => void;
  /**
   * ugly fix for forcing the editor to focus when clicking on the parent div
   */
  isFocusingEditor?: boolean;
}

interface IMuInternalEditorState {
  /**
   * edit or preview
   */
  editorMode: MuEditorMode;

  /**
   * get controlled by parent for forcing focus
   */
  isFocusingEditor: boolean;
  /**
   * well use this to filter out invalid keyboard shortcuts!
   */
  toolbarMode: MuToolbarMode;

  /**
   * internal draft.js state
   */
  draftState: EditorState;

  suggestions: Array<any>;
}

/**
 * MuInternalEditor is the 'core editor' wrapping draft.js
 */
// interface ITest {
//   href: string;
//
// }
//
// function Test(props: ITest) {
//   console.log(props);
//   return (
//     <LinkDiv isEditing={true} >
//       {props.href}
//     </LinkDiv>
//   );
// }

export class MuInternalEditor extends React.PureComponent<
  IMuInternalEditorProps,
  IMuInternalEditorState
> {
  private readonly draftRef: RefObject<any>;
  private readonly mentionPlugin: any;
  private readonly linkifyPlugin: any;

  constructor(props: IMuInternalEditorProps & InjectedIntlProps) {
    super(props);

    this.mentionPlugin = createMentionPlugin();

    this.draftRef = React.createRef();

    this.state = {
      isFocusingEditor: false,
      draftState: props.draftState || EditorState.createEmpty(),
      editorMode: props.editorMode || MuEditorMode.Edit,
      toolbarMode: props.toolbarMode || MuToolbarMode.Basic,
      suggestions: mentions,
    };

    this.linkifyPlugin = createLinkifyPlugin({
      // component: (props2: any) => (
      //   <Test href={props2.href} test={this.state.editorMode === MuEditorMode.Edit ? 'edit' :
      //   'preview'}/>
      // ),
      target: '_blank',
      rel: 'noreferrer noopener',
    });
    // this.focus = this.focus.bind(this);
  }

  onSearchChange = (test: any) => {
    // console.log('filter mentions', test);
    this.setState({
      suggestions: defaultSuggestionsFilter(test.value, mentions),
    });
  };

  onAddMention = () => {
    // get the mention object selected
    // console.log('on add mention');
  };
  //
  // focus = () => {
  //   // if (this.draftRef.current && this.state.editorMode === MuEditorMode.Edit) {
  //   //     //   console.log('focus editor');
  //   //     //   const node = this.draftRef.current;
  //   //     //   if (node) {
  //   //     //     // TODO: fix this in Editor with plugins support!
  //   //     //     node.focus();
  //   //     //   }
  //   //     // }
  // };
  //
  // componentDidMount() {
  //   this.focus();
  // }

  focus = () => {
    // console.log('handle click wrapper');
    // next frame hack
    // https://github.com/draft-js-plugins/draft-js-plugins/issues/1061
    // https://github.com/draft-js-plugins/draft-js-plugins/issues/357

    setTimeout(() => {
      if (
        this.draftRef.current &&
        this.state.editorMode === MuEditorMode.Edit
      ) {
        // console.log('muInt. try focus');
        const node = this.draftRef.current;
        if (this.draftRef && node && node.focus && isFunction(node.focus)) {
          // console.log('FOCUS!!!');
          node.focus();
        } else {
          // console.log('cannot find focus on plugineditor...');
        }
      }
    }, 0);
  };

  UNSAFE_componentWillReceiveProps(nextProps: IMuInternalEditorProps) {
    if (
      nextProps.editorMode &&
      nextProps.editorMode !== this.state.editorMode
    ) {
      this.setState({
        editorMode: nextProps.editorMode,
      });
      // const t = document.querySelector('draftJsLinkifyPlugin__link__2ittM');
      // console.log(t);
      // // t.firstChild
    }
    if (nextProps.isFocusingEditor !== this.state.isFocusingEditor) {
      this.focus();
      this.setState({
        isFocusingEditor: nextProps.isFocusingEditor,
      });
    }
    if (
      nextProps.draftState &&
      nextProps.draftState !== this.state.draftState
    ) {
      this.setState({
        draftState: nextProps.draftState,
      });
    }
    if (
      nextProps.toolbarMode &&
      nextProps.toolbarMode !== this.state.toolbarMode
    ) {
      this.setState({
        toolbarMode: nextProps.toolbarMode,
      });
    }
  }

  handleChange = (draftState: EditorState) => {
    setTimeout(() => {
      this.setState(
        {
          draftState: draftState,
        },
        () => {
          safeInvoke(this.props.onChange, this.state.draftState);
        }
      );
    }, 0);
  };

  handleBlur = (e: any) => {
    safeInvokeDeprecated(this.props.onBlur, e);
  };

  handleReturn = (e: any, state: EditorState): DraftHandleValue => {
    // check if special muHandleReturnFn is defined. if someone else is handling it, return handled.
    // if it returns unhandled, well check for soft return!
    if (this.props.muHandleReturnFn !== undefined) {
      const tempResult = this.props.muHandleReturnFn(e, state);
      if (tempResult === 'handled') {
        return 'handled';
      }
    }

    // console.log('MuInternalEditor.handleReturn');
    // Check if we have a soft return (shift return):
    if (this._handleReturnSoftNewline(e)) {
      return 'handled';
    }

    return 'not-handled';
  };

  _handleReturnSoftNewline(event: Object): boolean {
    // console.log('check if soft return');
    if (isSoftNewlineEvent(event)) {
      // console.log('very soft!');
      const selection = this.state.draftState.getSelection();

      if (selection.isCollapsed()) {
        this.handleChange(RichUtils.insertSoftNewline(this.state.draftState));
      } else {
        let content = this.state.draftState.getCurrentContent();
        let newContent = Modifier.removeRange(content, selection, 'forward');
        let newSelection = newContent.getSelectionAfter();
        let block = newContent.getBlockForKey(newSelection.getStartKey());
        newContent = Modifier.insertText(
          newContent,
          newSelection,
          '\n',
          block.getInlineStyleAt(newSelection.getStartOffset()),
          undefined
        );
        this.handleChange(
          EditorState.push(this.state.draftState, newContent, 'insert-fragment')
        );
      }
      return true;
    }
    return false;
  }

  keyBindingFunc = (e: React.KeyboardEvent<{}>): DraftEditorCommand | null => {
    // console.log('key: ' + e.key + ' (' + e.keyCode + ') ', e);
    // TODO: remove shortcuts we dont want to support?
    if (e.keyCode === MunikumKeys.ESCAPE) {
      safeInvoke(this.props.onEscape);
    }
    const temp = getDefaultKeyBinding(e);
    return temp;
  };

  handleKeyCommand = (
    command: DraftEditorCommand,
    editorState: EditorState
  ): DraftHandleValue => {
    // console.log('handleKeyCommand: ' + command);

    // filtering out invalid commands when toolbar is disabled
    if (this.state.toolbarMode === MuToolbarMode.None) {
      if (command === 'bold') {
        // console.log('no bold in none mode!');
        return 'handled';
      }
      if (command === 'italic') {
        return 'handled';
      }
      if (command === 'underline') {
        return 'handled';
      }
    }

    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      this.handleChange(newState);
      return 'handled';
    }

    return 'not-handled';
  };

  render() {
    const { style, muBlockStyleFn, id } = this.props;
    const { editorMode, draftState } = this.state;
    const { MentionSuggestions } = this.mentionPlugin;
    const plugins = [this.linkifyPlugin]; // this.mentionPlugin,
    if (this.props.enableCoolBetaStuff) {
      plugins.push(this.mentionPlugin);
    }

    const isReadOnly = editorMode === MuEditorMode.Preview;
    return (
      <EditorDiv
        className={isReadOnly ? 'preview' : 'editing'}
        isEditing={!isReadOnly}
        style={style}
        onClick={this.focus}
      >
        <PluginEditor
          id={id}
          // TODO dont know if this is correct
          name={name => name}
          placeholder={this.props.placeholder}
          editorState={draftState}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          plugins={plugins}
          ref={this.draftRef}
          blockStyleFn={muBlockStyleFn || muDefaultBlockStyleFn}
          keyBindingFn={this.keyBindingFunc}
          handleReturn={this.handleReturn}
          handleKeyCommand={this.handleKeyCommand}
          readOnly={isReadOnly}
        />
        <MentionSuggestions
          onSearchChange={this.onSearchChange}
          suggestions={this.state.suggestions}
          onAddMention={this.onAddMention}
        />
      </EditorDiv>
    );
  }
}
