import { InjectedIntlProps, injectIntl } from 'react-intl';
import {
  SelectMode,
  UltimateDropdown,
} from '../basic/DropDownSelectUltimate/UltimateDropdown';
import {
  FieldsOnKostraFunctionFragment,
  MyGetKostraFunctionsQuery,
} from '../../models/types';
import { MenuItem } from '../basic/Menu';
import { MunikumIcons } from '../common/icons';
import * as React from 'react';
import { CSSProperties } from 'react';
import { safeInvoke } from '../common';
import {
  GET_KOSTRA_FUNCTIONS,
  GetKostraFunctions,
  MY_GET_KOSTRA_FUNCTIONS,
} from './kostraQueries';
import { commonLabels } from '../language/commonLabels';
import Query from 'react-apollo/Query';

class GetMyKostraFunctions extends Query<MyGetKostraFunctionsQuery, {}> {}

const KostraDrop = UltimateDropdown.ofType<FieldsOnKostraFunctionFragment>();

class KostraFunctionDropDownComp extends React.PureComponent<
  InjectedIntlProps & {
    disabled?: boolean;
    selectedItems: ReadonlyArray<FieldsOnKostraFunctionFragment>;
    myItems?: ReadonlyArray<FieldsOnKostraFunctionFragment>;
    onSelectedItemsChanged?: (
      selectedItems: ReadonlyArray<FieldsOnKostraFunctionFragment>
    ) => void;
    style?: CSSProperties;
    fieldGroupStyle?: CSSProperties;
  },
  {}
> {
  render() {
    const { props } = this;

    return (
      <GetMyKostraFunctions query={MY_GET_KOSTRA_FUNCTIONS}>
        {({ loading, error, data }) => {
          if (loading) {
            return null;
          }

          let data2 =
            data &&
            data.me &&
            data.me.kostraFunctions &&
            data.me.kostraFunctions.map(it => it.id);

          return (
            <GetKostraFunctions query={GET_KOSTRA_FUNCTIONS}>
              {({ loading, error, data }) => {
                if (loading) {
                  return null;
                }
                if (error) {
                  throw error;
                }

                let groups = [];

                let mine = [];
                let others = [];
                if (data && data.allKostraFunctions && data2) {
                  data.allKostraFunctions.forEach(it => {
                    if (data2.includes(it.id)) {
                      mine.push(it);
                    } else {
                      others.push(it);
                    }
                  });
                }

                if (mine.length > 0) {
                  groups = [
                    {
                      header: props.intl.formatMessage(commonLabels.myKostra),
                      items: mine,
                    },
                    {
                      header: props.intl.formatMessage(commonLabels.other),
                      items: others,
                    },
                  ];
                }

                return (
                  <KostraDrop
                    disabled={this.props.disabled}
                    style={props.style}
                    fieldGroupStyle={props.fieldGroupStyle}
                    label={props.intl.formatMessage(commonLabels.chooseKostra)}
                    defaultText={props.intl.formatMessage(
                      commonLabels.chooseKostra
                    )}
                    items={(data && data.allKostraFunctions) || []}
                    groups={groups || []}
                    useFilterBox={true}
                    selectMode={SelectMode.MULTIPLE}
                    visibleRows={8}
                    itemGroupedHeaderRenderFunc={(
                      header: string,
                      key: string
                    ) => (
                      <MenuItem
                        key={key}
                        isGroupHeader={true}
                        text={
                          <div>
                            <div>{header}</div>
                          </div>
                        }
                      />
                    )}
                    itemRenderFunc={(
                      item: FieldsOnKostraFunctionFragment,
                      itemProps
                    ) => (
                      <MenuItem
                        key={itemProps.key}
                        dataFilterString={item.kid + item.title}
                        ref={React.createRef()}
                        onClick={itemProps.handleClick}
                        text={
                          <div>
                            <div>
                              {item.kid} {item.title}
                            </div>
                          </div>
                        }
                        isSelected={itemProps.isSelected}
                        leftIcon={
                          itemProps.isSelected
                            ? MunikumIcons.LeftCheck
                            : undefined
                        }
                      />
                    )}
                    onSelectedItemsChanged={(
                      selectedItems: ReadonlyArray<
                        FieldsOnKostraFunctionFragment
                      >
                    ) => {
                      // props.setFieldValue(
                      //   'kostraFunctions',
                      //   selectedItems.map(value =>
                      //     value.id.toString()
                      //   )
                      // );
                      safeInvoke(props.onSelectedItemsChanged, selectedItems);
                    }}
                    selectedItems={props.selectedItems}
                  />
                );
              }}
            </GetKostraFunctions>
          );
        }}
      </GetMyKostraFunctions>
    );
  }
}

export const KostraFunctionDropDown = injectIntl(KostraFunctionDropDownComp);

KostraFunctionDropDown.defaultProps = {
  disabled: false,
};
