import * as React from 'react';
import styled from 'styled-components';
import {
  Colors,
  hideOutline,
  safeInvoke,
  transitionBorder,
} from '../../components/common';
import { FaSearch } from 'react-icons/fa';

export interface ITopSearchProps {
  searchPlaceholder: string;

  /**
   * controlled mode
   */
  value?: string;
  /**
   * if click on searchbar omnisearch should pop up
   */
  handleSearchClick?: () => void;
}

const SearchContainer = styled.div`
  background-color: ${props => props.theme.contentBackgroundColor};
  position: relative;
  width: 100%;

  cursor: pointer;

  margin-left: 1em;
`;

const SearchInput = styled.input`
  cursor: pointer;

  box-sizing: border-box;
  width: 100%;

  padding: 0.55em 0.75em 0.55em 3.1em; // padding makes room for icon

  font-family: Lato, sans-serif;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;

  border: 0px solid transparent;

  background-color: ${props => props.theme.contentBackgroundColor};

  color: ${props => props.theme.textColor};

  &::placeholder {
    color: ${props => props.theme.textSecondary};
  }
  &:focus:enabled {
  }
  &:disabled {
    color: ${props => props.theme.textSecondary};
  }
  ${hideOutline()};
  ${transitionBorder()};
`;

const SearchIcon = styled(FaSearch)`
  width: 1.5em;
  height: 1.5em;

  position: absolute;
  top: 0.35em;
  left: 0.75em;
  z-index: 2;

  fill: ${props => props.theme.textColor};

  transition: fill 0.25s ease-in-out;

  ${SearchContainer}:hover & {
    fill: ${Colors.RED};
  }
`;

export class TopSearch extends React.PureComponent<ITopSearchProps> {
  render() {
    const { searchPlaceholder } = this.props;

    return (
      <SearchContainer
        onClick={() => {
          safeInvoke(this.props.handleSearchClick);
        }}
      >
        <SearchIcon />

        <SearchInput
          placeholder={searchPlaceholder}
          value={''}
          onChange={() => {}}
          disabled={false}
        />
      </SearchContainer>
    );
  }
}
