/***
 * networkStatus
 * - a redux module monitoring network status. contains actions, reducer and sagas
 */
import { Action } from 'redux';
import { actionCreatorFactory, isType } from 'typescript-fsa';
import { delay } from 'redux-saga';
import {
  call,
  cancel,
  cancelled,
  fork,
  join,
  put,
  race,
  take,
} from 'redux-saga/effects';

const actionCreator = actionCreatorFactory();

// actions:
export const BACKOFF = 'NET/BACKOFF';
export const backoffAction = actionCreator<{ msUntilNextPing: number }>(
  BACKOFF
);
export const BACKOFF_COMPLETE = 'NET/BACKOFF_COMPLETE';
export const backoffCompleteAction = actionCreator<{}>(BACKOFF_COMPLETE);
export const NAVIGATOR_OFFLINE = 'NET/NAVIGATOR_OFFLINE';
export const navigatorOfflineAction = actionCreator<{}>(NAVIGATOR_OFFLINE);
export const NAVIGATOR_ONLINE = 'NET/NAVIGATOR_ONLINE';
export const navigatorOnlineAction = actionCreator<{}>(NAVIGATOR_ONLINE);
export const PING = 'NET/PING';
export const pingAction = actionCreator<{ pingUrl: string }>(PING);
export const PING_CANCEL = 'NET/PING_CANCEL';
export const pingCancelAction = actionCreator<{}>(PING_CANCEL);
export const PING_FAILURE = 'NET/PING_FAILURE';
export const pingFailAction = actionCreator<{ errorMessage: string }>(
  PING_FAILURE
);
export const PING_PENDING = 'NET/PING_PENDING';
export const pingPendingAction = actionCreator<{}>(PING_PENDING);
export const PING_SUCCESS = 'NET/PING_SUCCESS';
export const pingSuccessAction = actionCreator<{}>(PING_SUCCESS);

// this is the action that kicks off everything :)
export const START_WATCH_NETWORK_STATUS = 'NET/START_WATCH_NETWORK_STATUS';
export const startWatchNetworkStatus = actionCreator<{ pingUrl: string }>(
  START_WATCH_NETWORK_STATUS
);

// we don't have to stop? :p
// export const STOP_WATCH_NETWORK_STATUS = 'STOP_WATCH_NETWORK_STATUS';
// export const stopWatchNetworkStatus = actionCreator<{}>(STOP_WATCH_NETWORK_STATUS);

// reducer:
export interface INetworkState {
  hasBeenOnline: boolean;
  hasDetectedNetworkStatus: boolean;
  isNavigatorOnline: boolean;
  isOnline: boolean;
  isPinging: boolean;
  msUntilNextPing: number;
  pingError?: string;
}

const initialState: INetworkState = {
  hasBeenOnline: false,
  hasDetectedNetworkStatus: false,
  isNavigatorOnline: false,
  isOnline: false,
  isPinging: false,
  msUntilNextPing: 0,
  pingError: '',
};

export const reducer = (
  state: INetworkState = initialState,
  action: Action
) => {
  // we'll just keep on monitoring..
  // if (isType(action, signoutSuccess)) {
  //   return initialState;
  // }

  if (isType(action, backoffAction)) {
    return {
      ...state,
      msUntilNextPing: action.payload.msUntilNextPing,
    };
  }
  if (isType(action, navigatorOfflineAction)) {
    return {
      ...state,
      hasDetectedNetworkStatus: true,
      isNavigatorOnline: false,
      isOnline: false,
    };
  }
  if (isType(action, navigatorOnlineAction)) {
    return {
      ...state,
      isNavigatorOnline: true,
    };
  }
  if (isType(action, pingPendingAction)) {
    return {
      ...state,
      isPinging: true,
    };
  }
  if (isType(action, pingFailAction)) {
    return {
      ...state,
      hasDetectedNetworkStatus: true,
      isOnline: false,
      isPinging: false,
      pingError: action.payload.errorMessage,
    };
  }
  if (isType(action, pingSuccessAction)) {
    const tempstate = {
      ...state,
      hasBeenOnline: true,
      hasDetectedNetworkStatus: true,
      isOnline: true,
      isPinging: false,
      pingError: undefined,
    };
    return tempstate;
  }

  return state;
};

// sagas:

/**
 * Create a promise which resolves
 * once a particular event is dispatched by the given DOM EventTarget.
 * @param  {EventTarget}  target An EventTarget
 * @param  {string}       type   The type of event
 * @return {Promise<any>}        Resolves with the event once it's dispatched
 */
// tslint:disable-next-line
function once(target: any, type: string) {
  return new Promise(resolve => {
    // tslint:disable-next-line
    const listener = (e: any) => {
      target.removeEventListener(type, listener);
      resolve(e);
    };
    target.addEventListener(type, listener);
  });
}

export function* watchWindowOnline() {
  while (true) {
    yield call(once, window, 'online');
    yield put(navigatorOnlineAction({}));
  }
}

export function* watchWindowOffline() {
  while (true) {
    yield call(once, window, 'offline');
    yield put(navigatorOfflineAction({}));
  }
}

/**
 * redux-saga task which continuously dispatches NAVIGATOR_ONLINE and NAVIGATOR_OFFLINE
 * actions as the browser's network status changes. An initial NAVIGATOR_ONLINE / NAVIGATOR_OFFLINE
 * action is dispatched based on `window.navigator.onLine` to establish the initial state.
 * @param {Object} navigator A `window.navigator` instance
 */
export function* watchNavigatorStatus(navigator: Navigator) {
  if (navigator.onLine) {
    yield put(navigatorOnlineAction({}));
  } else {
    yield put(navigatorOfflineAction({}));
  }
  yield fork(watchWindowOnline);
  yield fork(watchWindowOffline);
}

const fetchFunc = (url: string) =>
  fetch(url, {
    method: 'GET',
    cache: 'no-cache',
  });

export function* watchPing() {
  while (true) {
    const someAction = yield take([PING, PING_CANCEL]);
    if (someAction.type === PING_CANCEL) {
      break;
    }

    yield put(pingPendingAction({}));
    // Ensure ping takes at least 1 second
    const delayTask = yield fork(delay, 1000);
    try {
      const { ping: response } = yield race({
        ping: call(fetchFunc, someAction.payload.pingUrl),
        // Timeout if ping takes longer than 5 seconds
        timeout: call(delay, 5000),
      });
      if (response) {
        if (response.ok) {
          // Ping succeeded; indicate success immediately
          yield put(pingSuccessAction({}));
        } else {
          // Ping response is 4xx / 5xx; wait until delay completes, then indicate failure
          // console.log('ping failed', response);
          yield join(delayTask);
          yield put(
            pingFailAction({ errorMessage: 'ping failed, 4xx or 5xx' })
          );
        }
      } else {
        // Timed out
        yield put(pingFailAction({ errorMessage: 'ping timed out' }));
      }
    } catch (error) {
      // Ping failed; wait until delay completes
      yield join(delayTask);
      yield put(
        pingFailAction({ errorMessage: 'ping failed, ' + error.message })
      );
    } finally {
      if (yield cancelled()) {
        yield put(pingFailAction({ errorMessage: 'ping failed, cancelled' }));
      }
    }
  }
}

/**
 * redux-saga task which starts the backoff sequence whenever a BACKOFF action is  dispatched.
 */
export function* watchBackoff() {
  while (true) {
    const myBackoffAction = yield take(BACKOFF);
    const ms = myBackoffAction.payload.msUntilNextPing;
    // console.log('got backoff action..', ms);
    // const intervalLength = 1000; // count down by one second at a time
    // const intervalCount = Math.floor(ms / intervalLength);
    // for (const i of range(0, intervalCount)) {
    //   // eslint-disable-line no-unused-vars
    //   yield put(countDownAction({ intervalLength: intervalLength }));
    //   yield call(delay, intervalLength);
    // }
    // if (ms % intervalLength > 0) {
    //   // Count down by the remaining milliseconds if any
    //   yield put(countDownAction({ intervalLength: ms % intervalLength }));
    //   yield call(delay, ms % intervalLength);
    // }
    yield call(delay, ms);
    yield put(backoffCompleteAction({}));
  }
}

export function getNextFibonacciValue(
  randomizationFactor: number,
  previous: number,
  current: number
) {
  const next = previous + current;
  return next + next * randomizationFactor * Math.random();
}

export function* fibonacciPoll(
  pingUrl: string,
  randomizationFactor: number,
  initialDelay: number,
  maxDelay: number
) {
  let previousDelay = 0;
  let currentDelay = initialDelay;

  try {
    // console.log('pingurl:', pingUrl);
    yield put(pingAction({ pingUrl: pingUrl }));
    while (true) {
      const action = yield take([PING_SUCCESS, PING_FAILURE]);
      if (action.type === PING_SUCCESS) {
        return;
      }
      // console.log('ping failed, backoff and start race. delay:', currentDelay);
      // Ping failed; backoff
      yield put(backoffAction({ msUntilNextPing: currentDelay }));
      // Wait for a manual ping, or until the backoff has completed.
      const winner = yield race({
        backoffComplete: take(BACKOFF_COMPLETE),
        ping: take(PING),
      });

      if (winner.backoffComplete) {
        // console.log('Delay has elapsed; trigger another ping');
        // console.log('pingurl2', pingUrl);
        yield put(pingAction({ pingUrl: pingUrl }));
      }
      const nextDelay = getNextFibonacciValue(
        randomizationFactor,
        previousDelay,
        currentDelay
      );
      previousDelay = currentDelay;
      currentDelay = Math.min(nextDelay, maxDelay);
      // console.log('prev: ' + previousDelay + ', next delay: ' + nextDelay)
    }
  } finally {
    if (yield cancelled()) {
      yield put(pingCancelAction({}));
    }
  }
}

/**
 * redux-saga task which continuously query the browser's network status and the connectivity
 * to the server, dispatching actions to the network reducer when events occur.
 */
export function* watchNetworkStatusSaga() {
  // console.log('watchNetwork request...');
  const action = yield take(START_WATCH_NETWORK_STATUS);
  const pingUrl = action.payload.pingUrl;
  yield fork(watchBackoff);
  yield fork(watchPing);
  yield fork(watchNavigatorStatus, window.navigator);
  while (true) {
    // Begin polling when navigator is online
    yield take(NAVIGATOR_ONLINE);
    const pollTask = yield fork(fibonacciPoll, pingUrl, 0.5, 500, 10000);

    // Stop polling when navigator is offline
    yield take(NAVIGATOR_OFFLINE);
    yield cancel(pollTask);
  }
}

export function* initNetworkDetectionSaga() {
  // console.log('initNetworkDetectionSaga');
  if (process.env.NODE_ENV === 'production') {
    const pingUrl =
      (process && process.env && process.env.REACT_APP_API) ||
      'http://localhost:8080/api';
    yield put(startWatchNetworkStatus({ pingUrl: pingUrl }));
  } else {
    // NOTE: does API respond to IP now?
    const pingUrl =
      (process && process.env && process.env.REACT_APP_API) ||
      'http://localhost:8080/api';
    yield put(startWatchNetworkStatus({ pingUrl: pingUrl }));
  }
}

// export const isOnlineSelector = (state: RootState) => {
//   return (
//     (state.network && !state.network.hasDetectedNetworkStatus) ||
//     (state.network && state.network.isOnline)
//   );
// };
