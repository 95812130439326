import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import { Linear, TimelineLite, TimelineMax } from 'gsap';
import 'gsap/CSSPlugin';
import { TextNormal } from '../Structural/typography';

const PiePath = styled.path`
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #cfd2d2;
  fill-opacity: 0.4;
`;

/**
 * CircleSpinner
 * - named containers in Adobe illustrator
 * - optimized svg using https://jakearchibald.github.io/svgomg/
 * - testing animation with gsap
 * - https://www.sarasoueidan.com/blog/svg-coordinate-systems/
 *
 * NOTE: setting height and width to 100% wil fit spinner inside box keeping aspect ratio,
 *       default behaviour of svg is to grow as big as it can even if it overflows?
 *
 */
export class CircleSpinnerAnimation extends React.PureComponent<
  { style?: CSSProperties },
  {}
> {
  private myElements: Array<any>;
  private tl: TimelineLite;

  constructor(props: {}) {
    super(props);

    this.myElements = [];
    this.tl = new TimelineLite({
      repeat: -1,
    });
  }

  componentDidMount() {
    const timeline = new TimelineMax({ repeat: -1 });
    const speed = 0.25;

    let offset = 0;
    this.myElements.forEach((p: any) => {
      timeline.to(
        p,
        speed,
        {
          fillOpacity: 1,
          repeat: 1,
          yoyo: true,
          ease: Linear.easeInOut,
        },
        offset
      );
      offset = offset + 0.15;
    });
  }

  render() {
    const { style } = this.props;
    return (
      <>
        <svg
          id="LayerGroup"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 144 144"
          // preserveAspectRatio={'xMidYMid keep'}
          // style={Object.assign({ width: '100%', height: '100%' }, style)}
          style={style}
        >
          <g id="StyleguideGroup">
            <g id="Group" transform="translate(-169 -211)">
              <g id="SpinnerAndBird" transform="translate(169 211)">
                <g id="Circle" style={{ position: 'relative' }}>
                  <PiePath
                    ref={(path: any) => (this.myElements[0] = path)}
                    id="Bar1"
                    d="M118.7 17.4C107.3 7.5 92.6 1.1 76.4 0v19.2c10.9 1 20.8 5.3 28.8 11.8l13.5-13.6z"
                    onClick={() => {
                      console.log('clic');
                      this.tl.play();
                    }}
                  />
                  <PiePath
                    ref={(path: any) => (this.myElements[1] = path)}
                    id="Bar2"
                    d="M143.2 66.5c-1.1-16.2-7.6-30.9-17.7-42.3L112 37.7c6.7 7.9 11.1 17.9 12.1 28.8h19.1z"
                  />

                  <g id="Bar3" transform="translate(112 75.24)">
                    <PiePath
                      ref={(path: any) => (this.myElements[2] = path)}
                      id="Bar3b"
                      d="M31.3.8H12.1c-.9 11-5.2 21-11.9 29.1l13.6 13.6C23.9 31.9 30.3 17.1 31.3.8"
                    />
                  </g>

                  <PiePath
                    ref={(path: any) => (this.myElements[3] = path)}
                    id="Bar4"
                    d="M76.4 124.1v19.2c16.3-1.1 31.1-7.6 42.6-17.7l-13.6-13.5c-7.9 6.6-17.9 11-29 12"
                  />
                  <PiePath
                    ref={(path: any) => (this.myElements[4] = path)}
                    id="Bar5"
                    d="M24.2 125.5c11.5 10.2 26.3 16.7 42.6 17.7V124c-11-1-21.1-5.4-29.1-12.1l-13.5 13.6z"
                  />
                  <PiePath
                    ref={(path: any) => (this.myElements[5] = path)}
                    id="Bar6"
                    d="M0 76.1c1 16.3 7.4 31.1 17.5 42.6L31 105.2c-6.6-8-10.9-18.1-11.9-29.1H0z"
                  />
                  <PiePath
                    ref={(path: any) => (this.myElements[6] = path)}
                    id="Bar7"
                    d="M17.8 24.2C7.7 35.6 1.2 50.3 0 66.5h19.2c1-10.9 5.4-20.8 12.1-28.8L17.8 24.2z"
                  />
                  <PiePath
                    ref={(path: any) => (this.myElements[7] = path)}
                    id="Bar8"
                    d="M66.9 0C50.7 1.1 36 7.5 24.5 17.4L38.1 31c8-6.6 17.9-10.9 28.8-11.8V0z"
                  />
                </g>
                <g id="OuterBird" transform="translate(42 52)">
                  <g id="Bird" fillRule="evenodd" clipRule="evenodd">
                    <path
                      id="Fill-1"
                      fill="#993921"
                      d="M38.9 8.8L61 18.7 50.3 0z"
                    />
                    <path id="Fill-2" fill="#ff5c39" d="M19.5 0L0 40 38.9 9z" />
                    <path
                      id="Fill-3"
                      fill="#c1531b"
                      d="M19.4 0l19.7 8.9L50.4.1 36.5 0z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </>
    );
  }
}

export interface ICircleSpinnerProps {
  text?: string;
  style?: CSSProperties;
}

const Container = styled.div`
  width: 9em;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SpinnerContainer = styled.div`
  width: 9em;
  height: 9em;
`;

export class CircleSpinner extends React.PureComponent<
  ICircleSpinnerProps,
  {}
> {
  private textRef: any;

  constructor(props: ICircleSpinnerProps) {
    super(props);

    this.textRef = null;
  }

  componentDidMount() {
    // TODO: add if we buy license =)
    // if (this.props.text) {
    //   const split = new SplitText(this.textRef);
    // }
  }

  render() {
    const { style, text } = this.props;

    return (
      <Container style={style}>
        <SpinnerContainer>
          <CircleSpinnerAnimation style={{ width: '100%', height: '100%' }} />
        </SpinnerContainer>
        {text && (
          <TextNormal ref={(ref: any) => (this.textRef = ref)}>
            {text}
          </TextNormal>
        )}
      </Container>
    );
  }
}

const LoaderCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  min-height: 40em;
  margin: auto;
`;

export function SuspenseLoader(props: {
  style?: CSSProperties;
  text?: string;
}) {
  return (
    <LoaderCont style={props.style}>
      <CircleSpinner text={props.text} />
    </LoaderCont>
  );
}
