import * as React from 'react';
import { RefObject } from 'react';
import styled from 'styled-components';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { commonMessages } from '../language/commonMessages';
import {
  CardContainer,
  CardLeftContainerResponsive,
  ChatBubble,
} from '../basic/Card/Card';
import { ICommentSaveForm } from '../../services/commentService';
import { isReallyEmpty, safeInvokeDeprecated } from '../common';
import { IFileItem } from '../basic/UserFile/FileUploaderPicker';
import { UserSummary } from './UserSummary';
import { MuEditorBasic } from '../basic/MuEditor/MuEditorBasic';

export interface IAnswerDiscussionPersonOrganization {
  id: string;
  name: string;
  organizationImage?: string;
  url?: string;
}

export interface IAnswerDiscussionPerson {
  id: string;
  name: string;
  picture?: string;
  jobTitle?: string;
  organization: IAnswerDiscussionPersonOrganization;
}

const ContentContainer = styled.div`
  background-color: ${props => props.theme.contentBackgroundColor};
  flex: 1 1 0;
  padding: 1.25em;
  overflow-wrap: break-word;
  word-break: break-word; /* Chrome, Safari */
  word-wrap: break-word; /* IE11, Firefox */
`;

export interface IAnswerDiscussionViewProps {
  // set this if youre editing!
  // value?: ICommentSaveForm;
  // isAdmin: boolean;
  onSave: (rawMarkdown: string, files: IFileItem[]) => void;
  // title: string;
  // metaId: string;
  me: IAnswerDiscussionPerson;
}

/**
 * AnswerDiscussionView
 */
class AnswerDiscussionComp extends React.PureComponent<
  IAnswerDiscussionViewProps & InjectedIntlProps,
  {}
> {
  private editorRef: RefObject<MuEditorBasic>;

  constructor(props: IAnswerDiscussionViewProps & InjectedIntlProps) {
    super(props);

    this.editorRef = React.createRef();
  }

  handleSave = (values: ICommentSaveForm) => {
    // console.log('save1', values);

    safeInvokeDeprecated(this.props.onSave, values);
  };

  handleFocus = () => {
    if (this.editorRef.current) {
      this.editorRef.current.focus();
    }
  };

  render() {
    const { me, intl } = this.props;

    return (
      <CardContainer canHover={false} style={{}}>
        <CardLeftContainerResponsive style={{ position: 'relative' }}>
          <ChatBubble />
          <UserSummary
            userImage={me.picture}
            name={me.name}
            role={me.jobTitle || ''}
            organizationImage={
              (me.organization && me.organization.organizationImage) || ''
            }
            organizationName={(me.organization && me.organization.name) || ''}
            id={me.id}
            organizationUrl={me.organization && me.organization.url}
          />
        </CardLeftContainerResponsive>
        <ContentContainer
          style={{ minHeight: '8em' }}
          onClick={this.handleFocus}
        >
          <MuEditorBasic
            placeHolder={intl.formatMessage(
              commonMessages.writeHerePlaceholder
            )}
            onValidate={(md: string) => {
              return md.trim().length > 0 && md.trim() !== '\u200B';
            }}
            onSave={(md: string, files: IFileItem[]) => {
              if (!isReallyEmpty(md)) {
                safeInvokeDeprecated(this.props.onSave, md, files);
              }
            }}
            saveText={intl.formatMessage(commonMessages.answer)}
            ref={this.editorRef}
          />
        </ContentContainer>
      </CardContainer>
    );
  }
}

export const AnswerDiscussionView = injectIntl(AnswerDiscussionComp);
