import * as React from 'react';
import { CSSProperties } from 'react';
import { ContentState, convertFromRaw, EditorState, RichUtils } from 'draft-js';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { safeInvokeDeprecated } from '../../common';
import { IFileItem } from '../UserFile/FileUploaderPicker';
import { MuToolbarCommands, MuToolbarMode } from './MuToolbar';

const draftjsExport = require('draft-js-export-markdown');

export enum MuEditorMode {
  Edit = 'edit',
  Preview = 'preview',
}

// type MuEditorCommands = 'discard' | 'cancel' | 'save' | 'edit';

type MuSupportedCommands = MuToolbarCommands; // & MuEditorCommands;

export interface IMuEditorRenderProps {
  editorMode: MuEditorMode;
  toolbarMode: MuToolbarMode;

  isBoldChecked: boolean;
  isItalicChecked: boolean;

  /**
   * value for local draft.js state
   */
  draftState: EditorState;

  /**
   * onChange handler for local draft.js editor state
   * @param draftState
   */
  handleDraftChange: (draftState: EditorState) => void;

  /**
   * handler for toolbar commands
   * @param command
   */
  handleCommand: (command: MuSupportedCommands) => void;

  /**
   * handler for submitting data in editor. will raise onSubmit event
   */
  handleSubmit: () => void;

  /**
   * handler for cancel editing, will reset content to original content (set from markdown)
   */
  handleCancel: () => void;

  /**
   * handler for discarding everything, clearing editor
   */
  handleDiscard: () => void;

  handleUploadableFiles: (recentFiles: IFileItem[]) => void;

  doEmpty: boolean;
}

type EditorRenderFunc = (
  props: IMuEditorRenderProps
) => JSX.Element | null | false;

interface IMuEditorProps {
  style?: CSSProperties;

  /**
   * Set this to load editorState from markdown.
   * When editing, state is kept in local state!
   * warning: don't use this as a controlled component setting rawMarkdown on each update,
   * parsing markdown is too heavy..
   *
   * => testing to add markdown to state, use for caching and optimizing updates..
   */
  rawMarkdown?: string;

  /**
   * Is editor in Edit or Preview mode (default = Edit mode)
   */
  editorMode?: MuEditorMode;

  /**
   * controlled mode
   */
  toolbarMode?: MuToolbarMode;

  /**
   * Solving composition with render props
   */
  editorRenderFunc: EditorRenderFunc;
}

/**
 * callbacks available on submit
 */
export interface IMuBag {
  /**
   * clear editor data
   */
  resetEditor: () => void;

  /**
   * revert editor data to initial markdown
   */
  revertEditor: () => void;
}

interface IMuEditorDispatch {
  /**
   * omSubmit. dispatched when submitting.
   * to clear content, see muEditorBag.resetEditor()
   * to revert content (on failed update), see muEditor.revertEditor()
   * @param markdown
   * @param contentState
   * @param muEditorBag
   */
  onSubmit?: (
    markdown: string,
    files: IFileItem[],
    contentState: ContentState,
    muEditorBag: IMuBag
  ) => void;

  /**
   * onCancel. cancel button clicked
   */
  onCancel?: (revertedMarkdown: string) => void;

  /**
   * looses focus
   * @param e
   */
  onBlur?: (e: any) => void;

  /**
   * for use in contentEditable
   * @param contentState
   */
  onChange?: (contentState: ContentState) => void;
}

interface IMuEditorState {
  /**
   * Draft.js internal EditorState
   */
  draftState: EditorState;

  /**
   * edit or preview mode?
   */
  editorMode: MuEditorMode;

  /**
   * toolbar mode
   */
  toolbarMode: MuToolbarMode;

  isBoldChecked: boolean;

  isItalicChecked: boolean;

  /**
   * keeping local copy of contentstate when loading from raw markdown.
   * We can use this to revert local changes
   */
  backup: ContentState;

  /**
   * keep copy of raw markdown in internal state to compare to props (controllable)
   */
  rawMarkdown: string;

  newFiles: IFileItem[];
  uploadableFiles: IFileItem[];

  doEmpty: boolean;
}

/**
 * MuEditor
 * Use renderProp and place MuToolbar and MuInternalEditor where you want, and attach handlers
 * from renderProp. See also MuEditorBasic for a 'complete bundle editor component' :-)
 */
class MuEditorComp extends React.PureComponent<
  IMuEditorProps & InjectedIntlProps & IMuEditorDispatch,
  IMuEditorState
> {
  constructor(props: IMuEditorProps & InjectedIntlProps & IMuEditorDispatch) {
    super(props);

    let edison =
      props.rawMarkdown !== undefined
        ? props.rawMarkdown.substring(0, 1) === '{'
          ? EditorState.createWithContent(
              convertFromRaw(JSON.parse(props.rawMarkdown))
            )
          : EditorState.createWithContent(
              ContentState.createFromText(props.rawMarkdown)
            )
        : EditorState.createEmpty();

    // console.log(
    //   'after parse markdown to editorstate',
    //   props.rawMarkdown,
    //   edison
    // );

    const backup = edison.getCurrentContent();

    this.state = {
      draftState: edison,
      editorMode: props.editorMode || MuEditorMode.Edit,
      toolbarMode: props.toolbarMode || MuToolbarMode.Basic,
      isBoldChecked: false,
      isItalicChecked: false,
      backup: backup,
      rawMarkdown: props.rawMarkdown || '',
      newFiles: [],
      uploadableFiles: [],
      doEmpty: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: IMuEditorProps) {
    if (
      nextProps.editorMode &&
      nextProps.editorMode !== this.state.editorMode
    ) {
      this.setState(
        {
          editorMode: nextProps.editorMode,
        },
        () => {
          // TODO: focus
          // this.props.rawMarkdown.substring(0, 1) === '{' ?
          //   EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.rawMarkdown))) :
          //   EditorState.createWithContent(ContentState.createFromText(this.props.rawMarkdown));
          // this.draftRef.current.focus();
        }
      );
    }
    if (
      nextProps.toolbarMode &&
      nextProps.toolbarMode !== this.state.toolbarMode
    ) {
      this.setState({
        toolbarMode: nextProps.toolbarMode,
      });
    }
    if (
      nextProps.rawMarkdown !== undefined &&
      nextProps.rawMarkdown !== this.state.rawMarkdown
    ) {
      // console.log('setting content in MuEditor from markdown!');

      // hmm.. new markdown: "a", old markdown: "", new local draft.js state: "element: "a""

      // trying to recreate but keeping selectionSTate:

      // const selectionState = this.state.draftState.getSelection();
      //
      // const off1 = selectionState.getAnchorOffset();
      // const off2 = selectionState.getFocusOffset();
      this.setState({
        rawMarkdown: nextProps.rawMarkdown,
        draftState:
          nextProps.rawMarkdown !== undefined
            ? nextProps.rawMarkdown.substring(0, 1) === '{'
              ? EditorState.createWithContent(
                  convertFromRaw(JSON.parse(nextProps.rawMarkdown))
                )
              : EditorState.createWithContent(
                  ContentState.createFromText(nextProps.rawMarkdown)
                )
            : EditorState.createEmpty(),
      });

      // const newContentState = draftjsImport.stateFromMarkdown(
      //   nextProps.rawMarkdown
      // );

      // doesnt work, selection state is fucked up because of new ids for blocks...
      // const newEditorState = EditorState.create({
      //   currentContent: newContentState,
      //   selection: selectionState
      // });

      // const newEditorState = EditorState.createWithContent(newContentState);

      // idea, but wont work ...
      // newContentState.moveFocusToEnd(newEditorState);
    }
  }

  //
  // handlePastedFiles = (files: Array<Blob>): DraftHandleValue => {
  //   console.log('pasted some files!');
  //   return 'handled';
  // };
  //
  // handleDroppedFiles = (
  //   selection: SelectionState,
  //   files: Array<Blob>
  // ): DraftHandleValue => {
  //   console.log('dropped some files!');
  //   return 'handled';
  // };

  // handleContainerClick = (e: any) => {
  //   console.log('click container but not buttons');
  //
  //   // TODO: get this to work... it works, but breaks editor selection stuff
  //   // e.preventDefault();
  //   // e.stopPropagation();
  //   if (this.draftRef.current) {
  //     // const currentSelection = this.state.draftState.getSelection();
  //     // const hasFocus = currentSelection.getHasFocus();
  //
  //     // if (!hasFocus) {
  //     console.log('set focus');
  //     this.draftRef.current.focus();
  //     e.preventDefault();
  //     e.stopPropagation();
  //     // }
  //   }
  // };

  handleResetEditor = () => {
    // console.log('resetting form!');
    const temp = EditorState.createEmpty();
    this.handleChange(temp);
    this.setState(
      {
        backup: temp.getCurrentContent(),
        doEmpty: true,
      },
      () => {
        this.setState({ doEmpty: false });
      }
    );
  };

  public focus() {
    // console.log('FOCUS');
  }

  handleRevertEditor = () => {
    // console.log('reverting editor to last known backup..');
    const revertedState = EditorState.createWithContent(this.state.backup);
    this.handleChange(revertedState);

    if (this.props.onCancel) {
      const markdown = draftjsExport.stateToMarkdown(
        revertedState.getCurrentContent()
      );
      this.props.onCancel(markdown);
    }
  };

  handleSubmit = () => {
    const contentState = this.state.draftState.getCurrentContent();

    // console.log('converting to markdown');

    const markdown = draftjsExport.stateToMarkdown(contentState);

    // console.log('md', markdown);

    // NOTE: optimistic updates etc. are left to consumer to implement! well just dispatch onSubmit just like formik
    const files = this.state.uploadableFiles;
    // console.log('submit, files in state', files);
    safeInvokeDeprecated(this.props.onSubmit, markdown, files, contentState, {
      resetEditor: this.handleResetEditor,
      revertEditor: this.handleRevertEditor,
    });

    // const backupBeforeEditing = this.state.backup;
    //
    // this.setState(
    //   {
    //     backup: undefined,
    //     editorMode: this.props.switchToPreviewOnSubmit
    //       ? MuEditorMode.Preview
    //       : MuEditorMode.Edit,
    //     draftState: !this.props.switchToPreviewOnSubmit && this.props.clearOnSubmit
    //       ? EditorState.createEmpty()
    //       : EditorState.createWithContent(contentState),
    //   },
    //   () => {
    //     console.log('state updated optimistic, invoking onSave..');
    //
    //     // TODO: we need to handle optimistic updates that fails! Now it apears that
    //     // everything is ok, but data is only stored in local state.
    //     // todo: add isSaving-prop, use redux, and then what?
    //     // for now..: let's just be an optimist ;-)
    //
    //     safeInvoke(this.props.onSubmit, markdown);
    //
    //
    //     // , (success: boolean) => {
    //     //   if (!success) {
    //     //     // note: we should probably show an errormessage, save failed=
    //     //     console.log('Save failed, reverting optimistic update...');
    //     //     this.setState({
    //     //       draftState: EditorState.createWithContent(contentState),
    //     //     });
    //     //   }
    //     // });
    //   }
    // );
  };
  //
  // handleCancel = () => {
  //   console.log('cancel');
  //   // safeInvoke(this.props.onCancel);
  //   if (this.state.backup !== undefined) {
  //     this.setState({
  //       draftState: EditorState.createWithContent(this.state.backup),
  //       backup: undefined,
  //       editorMode: MuEditorMode.Preview,
  //     });
  //   } else {
  //     this.setState({
  //       editorMode: MuEditorMode.Preview,
  //     });
  //   }
  // };
  //
  // handleEdit = () => {
  //   const backup = this.state.draftState.getCurrentContent();
  //   this.setState({
  //     backup: backup,
  //     editorMode: MuEditorMode.Edit,
  //   });
  // };
  //
  // handleBlur = (e: SyntheticEvent) => {
  //   safeInvoke(this.props.onBlur, e);
  // };

  /**
   * handle editor commands
   * @param cmd
   */
  handleCommand = (cmd: MuSupportedCommands) => {
    // console.log('dont worry, im handling it ' + cmd);

    if (
      this.state.toolbarMode === MuToolbarMode.Basic ||
      this.state.toolbarMode === MuToolbarMode.Full
    ) {
      if (cmd === 'bold') {
        const newState = RichUtils.handleKeyCommand(
          this.state.draftState,
          'bold'
        );
        this.handleChange(newState);
      }
      if (cmd === 'italic') {
        const newState = RichUtils.handleKeyCommand(
          this.state.draftState,
          'italic'
        );
        this.handleChange(newState);
      }
    }
  };

  handleChange = (draftState: EditorState, shouldDispatch: boolean = true) => {
    const temp = draftState.getCurrentInlineStyle();

    const isBoldChecked = temp.has('BOLD');
    const isItalicChecked = temp.has('ITALIC');

    this.setState(
      {
        draftState: draftState,
        isBoldChecked: isBoldChecked,
        isItalicChecked: isItalicChecked,
      },
      () => {
        if (shouldDispatch && this.props.onChange !== undefined) {
          const contentState = this.state.draftState.getCurrentContent();
          safeInvokeDeprecated(this.props.onChange, contentState);
        }
      }
    );
  };

  handleUploadableFiles = (recentFiles: IFileItem[]) => {
    this.setState({
      uploadableFiles: recentFiles,
    });
  };

  render() {
    // console.log('render outer');
    return (
      <React.Fragment>
        {this.props.editorRenderFunc({
          editorMode: this.state.editorMode,
          toolbarMode: this.state.toolbarMode,
          isBoldChecked: this.state.isBoldChecked,
          isItalicChecked: this.state.isItalicChecked,
          draftState: this.state.draftState,
          handleCommand: this.handleCommand,
          handleDraftChange: this.handleChange,
          handleSubmit: this.handleSubmit,
          handleCancel: this.handleRevertEditor,
          handleDiscard: this.handleResetEditor,
          handleUploadableFiles: this.handleUploadableFiles,
          doEmpty: this.state.doEmpty,
        })}
      </React.Fragment>
    );
  }
}

export const MuEditor = injectIntl(MuEditorComp);
