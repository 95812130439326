import styled from 'styled-components';
import { sizes } from '../../common/media';
import { IThemeProps } from '../../common';

export const Page = styled.div`
  max-width: 84.125em;
  margin: 0 auto;
`;

export const PageTop = styled.div`
  display: block;
  position: relative;
  margin: 0 auto 0.5em 0;

  @media (min-width: ${sizes.medium}px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const PageTopPure = styled.div`
  position: relative;
  margin: 0 auto 0.5em 0;
`;

export const PageTopSplitContainer = styled.div`
  display: block;

  @media (min-width: ${sizes.medium}px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const PageTitle = styled.div`
  //background-color: rebeccapurple;
  color: ${props => props.theme.textColor};
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  font-size: 2em;
  word-wrap: break-word;
  hyphens: auto;
  max-width: 40em;
  overflow: visible; // NOTE: needs to be visible to enable content editable!
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 1px; /* IE11 Fix */
`;

export const PageLastUpdated = styled.div`
  font-family: Lato, sans-serif;
  font-size: 0.875em;
  color: ${props => props.theme.textColor};
  margin-top: 0.5em;
  margin-bottom: 1.5em;
`;

export const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;
export const CursorDiv = styled.div`
  &:hover {
    cursor: pointer;
  }
`;
export const RefreshMessage = styled.div`
  padding: 1em;
  margin: auto;
  text-align: center;
  background-color: ${props => props.theme.accent1.activeColor};
  color: ${props => props.theme.textColor};
`;
export const ClapDiv = styled.div`
  display: flex;
  margin-top: 0.5em;
  margin-left: 0.25em;
  margin-right: 0.5em;
  align-items: center;
  fill: ${(props: { isLiked: boolean; theme: IThemeProps }) =>
    props.isLiked ? props.theme.accent1.activeColor : props.theme.textColor};
`;
