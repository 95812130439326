import * as React from 'react';
import { Dispatch } from 'redux';
import { signout } from '../../modules/auth/auth';
import { connect } from 'react-redux';
import { safeInvokeDeprecated } from '../../components/common';
import { RootState } from '../../modules/rootReducer';
import { Link } from 'react-router-dom';

export interface ISignOutPageProps {
  isAuthenticated: boolean;
  signOut?: (values: { token: string }) => void;
}

class SignOutPageComp extends React.PureComponent<ISignOutPageProps> {
  render() {
    const { isAuthenticated } = this.props;

    if (isAuthenticated) {
      safeInvokeDeprecated(this.props.signOut);
      return <div />;
    }

    return (
      <div>
        Du er no logga ut.{' '}
        <Link to={'/login'}>Gå tilbake til innlogginssida</Link>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): ISignOutPageProps => ({
  isAuthenticated: state.auth && state.auth.isAuthenticated,
});

const mapDispatchToPropsSimple = (
  dispatch: Dispatch<{}>
): Pick<ISignOutPageProps, 'signOut'> => {
  return {
    signOut: () => {
      dispatch(signout({}));
    },
  };
};

const SignOutPage = connect<ISignOutPageProps, {}, {}>(
  mapStateToProps,
  mapDispatchToPropsSimple
)(SignOutPageComp);

export default SignOutPage;
