import { defineMessages } from 'react-intl';

/**
 * A set of helful, common messages
 * @type {{}}
 */
export const commonMessages = defineMessages({
  next: {
    id: 'common.next',
    defaultMessage: 'Next',
  },
  previous: {
    id: 'common.previous',
    defaultMessage: 'Previous',
  },
  delete: {
    id: 'common.delete',
    defaultMessage: 'Delete',
  },
  add: {
    id: 'common.add',
    defaultMessage: 'Add',
  },
  create: {
    id: 'common.create',
    defaultMessage: 'Create',
  },
  edit: {
    id: 'common.edit',
    defaultMessage: 'Edit',
  },
  myFavourites: {
    id: 'common.myFavourites',
    defaultMessage: 'My favourites',
  },
  activeTags: {
    id: 'common.activeTags',
    defaultMessage: 'Tags in chosen annual-cycles',
  },
  rings: {
    id: 'common.rings',
    defaultMessage: 'Rings',
  },
  chooseView: {
    id: 'common.chooseView',
    defaultMessage: 'Choose view',
  },
  timeline: {
    id: 'common.timeline',
    defaultMessage: 'Timeline',
  },
  linkIsOccupied: {
    id: 'common.linkIsOccupied',
    defaultMessage: 'Link is already in use',
  },
  chooseColorTags: {
    id: 'common.chooseColorTags',
    defaultMessage: 'Choose color for tag',
  },
  week: {
    id: 'common.week',
    defaultMessage: 'Week',
  },
  activity: {
    id: 'common.activity',
    defaultMessage: 'Activity',
  },
  writeTitleForLink: {
    id: 'common.writeTitleForLink',
    defaultMessage: 'Write title for link here...',
  },
  copy: {
    id: 'common.copy',
    defaultMessage: 'Copy',
  },
  createLinkToShare: {
    id: 'common.createLinkToShare',
    defaultMessage: 'Create link to share',
  },
  endDateIsMandatory: {
    id: 'common.endDateIsMandatory',
    defaultMessage: 'end date is mandatory',
  },
  startDateIsMandatory: {
    id: 'common.startDateIsMandatory',
    defaultMessage: 'Start date is mandatory',
  },
  chooseTags: {
    id: 'common.chooseTags',
    defaultMessage: 'Choose tags',
  },
  createLink: {
    id: 'common.createLink',
    defaultMessage: 'Create link',
  },
  removeFromFavourites: {
    id: 'common.removeFromFavourites',
    defaultMessage: 'Remove from favourites',
  },
  searchResult: {
    id: 'common.searchResult',
    defaultMessage: 'Search result',
  },
  newRelationActionValue: {
    id: 'common.newRelationActionValue',
    defaultMessage: 'Add an action-value',
  },
  project: {
    id: 'common.project',
    defaultMessage: 'Project',
  },
  role: {
    id: 'common.role',
    defaultMessage: 'Role:',
  },
  addTag: {
    id: 'common.addTag',
    defaultMessage: 'Add tag:',
  },
  myTags: {
    id: 'common.myTags',
    defaultMessage: 'My tags',
  },
  tags: {
    id: 'common.tags',
    defaultMessage: 'Tags',
  },
  editProfile: {
    id: 'common.editProfile',
    defaultMessage: 'Edit profile',
  },
  selectEndDate: {
    id: 'common.selectEndDate',
    defaultMessage: 'Deadline',
  },
  selectStartDate: {
    id: 'common.selectStartDate',
    defaultMessage: 'Start date',
  },
  goToGroup: {
    id: 'common.goToGroup',
    defaultMessage: 'Go to groups',
  },
  notSpecified: {
    id: 'common.notSpecified',
    defaultMessage: 'Not specified',
  },
  searchMunicipalitiesAndPersons: {
    id: 'common.searchMunicipalitiesAndPersons',
    defaultMessage: 'Choose municipality or persons',
  },
  goToYearWheel: {
    id: 'common.goToYearWheel',
    defaultMessage: 'Go to calendars',
  },
  createRelationTooltip: {
    id: 'common.createRelationTooltip',
    defaultMessage:
      'Create relations to other topics you have write access to.',
  },
  chooseYearWheelToGetPreview: {
    id: 'common.chooseYearWheelToGetPreview',
    defaultMessage: 'Choose a calendar to get a preview over your instances.',
  },
  instancesPreviewTooltip: {
    id: 'common.instancesPreviewTooltip',
    defaultMessage:
      'Displays a preview over the instances for the selected year',
  },
  selectedPersons: {
    id: 'common.selectedPersons',
    defaultMessage: 'Selected persons',
  },
  noInstances: {
    id: 'common.noInstances',
    defaultMessage: 'There is no instances',
  },
  myAssignedTasks: {
    id: 'common.myAssignedTasks',
    defaultMessage: 'My assigned tasks',
  },
  createRelation: {
    id: 'common.createRelation',
    defaultMessage: 'Create relation to a group',
  },
  infinity: {
    id: 'common.infinity',
    defaultMessage: 'Infinity',
  },
  removeEndDate: {
    id: 'common.removeEndDate',
    defaultMessage: 'Click here to remove end date',
  },
  todoBelongsToTemplateWarning: {
    id: 'common.todoBelongsToTemplateWarning',
    defaultMessage: 'Go to the series to delete or edit this todo',
  },
  removeRepetition: {
    id: 'common.removeRepetition',
    defaultMessage: 'Remove repetition',
  },
  editKostra: {
    id: 'common.editKostra',
    defaultMessage: 'Edit KOSTRA',
  },
  orgSearchResult: {
    id: 'common.orgSearchResult',
    defaultMessage: 'Search result',
  },
  selectedOrgs: {
    id: 'common.selectedOrgs',
    defaultMessage: 'Selected organizations',
  },
  editYearWheelToolTip: {
    id: 'common.editYearWheelToolTip',
    defaultMessage: 'Edit calendar',
  },
  searchMunicipalities: {
    id: 'common.searchMunicipalities',
    defaultMessage: 'Search for municipalities',
  },
  removeRelationToGroup: {
    id: 'common.removeRelationToGroup',
    defaultMessage: 'Remove relation to the group',
  },
  removeRelationToActionValueWarning: {
    id: 'common.removeRelationToActionValueWarning',
    defaultMessage:
      'Are you sure you want to remove the relation to the action-value',
  },
  removeRelationToYearWheel: {
    id: 'common.removeRelationToYearWheel',
    defaultMessage: 'Remove relation to the calendar',
  },
  removeRelationToYearWheelWarning: {
    id: 'common.removeRelationToYearWheelWarning',
    defaultMessage:
      'Are you sure you want to remove the relation to the calendar',
  },
  addLink: {
    id: 'common.addLink',
    defaultMessage: 'Add link',
  },
  searching: {
    id: 'common.searching',
    defaultMessage: 'Searching...',
  },

  dashboardTopicBody: {
    id: 'common.dashboardTopicBody',
    defaultMessage: 'A arena for internal and external interaction',
  },
  dashboardForumBody: {
    id: 'common.dashboardForumBody',
    defaultMessage: 'A open forum for sharing experience',
  },
  dashboardActionvalueBody: {
    id: 'common.dashboardActionvalueBody',
    defaultMessage: 'An overview over grants and reimbursement schemes',
  },
  fieldErrorCalendar: {
    id: 'common.fieldErrorCalendar',
    defaultMessage: 'Calendar is a mandatory field',
  },
  yourKostraFunctions: {
    id: 'common.yourKostraFunctions',
    defaultMessage: 'Your occupational areas',
  },
  dashboardHelpBody: {
    id: 'common.dashboardHelpBody',
    defaultMessage: 'User guidance',
  },
  noRepetitionsText: {
    id: 'common.noRepetitionsText',
    defaultMessage:
      'You have zero repetitions, try to change the end date or start data more ahead in time ',
  },
  noResult: {
    id: 'common.noResult',
    defaultMessage: 'No result...',
  },
  news: {
    id: 'common.news',
    defaultMessage: 'News',
  },
  monday: {
    id: 'common.monday',
    defaultMessage: 'Monday',
  },
  tuesday: {
    id: 'common.tuesday',
    defaultMessage: 'Tuesday',
  },
  wednesday: {
    id: 'common.wednesday',
    defaultMessage: 'Wednesday',
  },
  thursday: {
    id: 'common.thursday',
    defaultMessage: 'Thursday',
  },
  friday: {
    id: 'common.friday',
    defaultMessage: 'Friday',
  },
  saturday: {
    id: 'common.saturday',
    defaultMessage: 'Saturday',
  },
  sunday: {
    id: 'common.sunday',
    defaultMessage: 'Sunday',
  },
  showOnlyMineTopics: {
    id: 'common.showOnlyMineTopics',
    defaultMessage: 'Show only my topics',
  },
  noFollowedItemsDasbhoardMessage: {
    id: 'common.noFollowedItemsDasbhoardMessage',
    defaultMessage: 'Here will the last updated items you follow appear',
  },
  repeat: {
    id: 'common.repeat',
    defaultMessage: 'Repeat',
  },
  dashboardYearWheelBody: {
    id: 'common.dashboardYearWheelBody',
    defaultMessage: 'A tool for overview and planning',
  },
  showOnlyMineActionvalues: {
    id: 'common.showOnlyMineActionvalues',
    defaultMessage: 'Show only my action values',
  },
  doYouWantToRepeatTheSteps: {
    id: 'common.doYouWantToRepeatTheSteps',
    defaultMessage: 'Do you want to repeat the steps?',
  },

  yearWheelIsConnectedToTheseTopics: {
    id: 'common.yearWheelIsConnectedToTheseTopics',
    defaultMessage: 'The year wheel is connected to the following topics',
  },

  deleteCalendarWarning: {
    id: 'common.deleteCalendarWarning',
    defaultMessage: 'Are you sure you want to delete the calendar?',
  },
  deleteFileWaring: {
    id: 'common.deleteFileWarning',
    defaultMessage: 'Are you sure you want to delete the file?',
  },
  doYouNotFindThePerson: {
    id: 'AnnualCycle.doYouNotFindThePerson',
    defaultMessage: 'Do you not find the person?',
  },
  shortDescription: {
    id: 'common.shortDescription',
    defaultMessage: 'Are you sure you want to delete the file?',
  },
  addToMyYearWheels: {
    id: 'common.addToMyYearWheels',
    defaultMessage: 'Add to my calendars',
  },
  removeFromMyYearWheels: {
    id: 'common.removeFromMyYearWheels',
    defaultMessage: 'remove from my calendars',
  },
  post: {
    id: 'common.post',
    defaultMessage: 'post',
  },
  newDiscussion: {
    id: 'common.newDiscussion',
    defaultMessage: 'New discussion',
  },
  newDiscussionTopic: {
    id: 'common.newDiscussionTopic',
    defaultMessage: ' -new discussion',
  },
  clickOutsideWarning: {
    id: 'common.clickOusideWarning',
    defaultMessage:
      'Are you sure you want to close the dialoug? Possible changes wont be saved!',
  },
  network: {
    id: 'common.network',
    defaultMessage: 'Network',
  },
  relatedGroups: {
    id: 'common.relatedGroups',
    defaultMessage: 'Related groups',
  },
  deleteProfilePic: {
    id: 'common.deleteProfilePic',
    defaultMessage: 'Are you sure you want to delete your profile picture?',
  },
  noWriteAccess: {
    id: 'common.noWriteAccess',
    defaultMessage: 'You do not have write access to this task',
  },
  noWriteAccessSeries: {
    id: 'common.noWriteAccessSeries',
    defaultMessage: 'You do not have write access to this serie',
  },
  noWriteAccessCalendar: {
    id: 'common.noWriteAccessCalendar',
    defaultMessage: 'You do not have write access to this calendar',
  },
  noWriteAccessTopic: {
    id: 'common.noWriteAccessTopic',
    defaultMessage: 'You do not have write access to this group',
  },
  chooseMunicipality: {
    id: 'common.chooseMunicipality',
    defaultMessage: 'Choose municipality',
  },
  isYearWheelPublic: {
    id: 'common.isYearWheelPublic',
    defaultMessage: 'Is the year wheel public?',
  },
  infoBoxYearWheel: {
    id: 'common.infoBoxYearWheel',
    defaultMessage: 'Only the persons under will be able to see your yearwheel',
  },
  infoBoxGroup: {
    id: 'common.infoBoxGroup',
    defaultMessage: 'Only the persons under will be able to see your group',
  },
  recieveNotification: {
    id: 'common.recieveNotification',
    defaultMessage: 'recieve notifications',
  },
  Access: {
    id: 'common.Access',
    defaultMessage: 'Access',
  },
  closeDialog: {
    id: 'common.closeDialog',
    defaultMessage: 'Are you sure you want to close the dialog',
  },
  tryAnotherFilter: {
    id: 'common.tryAnotherFilter',
    defaultMessage: 'Try to filter on other options',
  },
  MakeYearWheelPublicWarning: {
    id: 'common.MakeYearWheelPublicWarning',
    defaultMessage:
      'Are you sure you want to make the yhear wheel public? Everyone can see your yearwheel and those with read access disapers from the list',
  },
  makeYearWheelPublic: {
    id: 'common.makeYearWheelPublic',
    defaultMessage: 'Make the yearwheel public',
  },
  MakeYearWheelVisible: {
    id: 'common.MakeYearWheelVisible',
    defaultMessage: 'Make the year wheel visible',
  },
  makeYearWheelNotVisible: {
    id: 'common.makeYearWheelNotVisible',
    defaultMessage: 'Make the yearwheel hidden for others',
  },
  hiddenCardTopicTitle: {
    id: 'common,hiddenCardTopicTitle',
    defaultMessage: 'Hidden',
  },
  hiddenCardTopicBody: {
    id: 'common,hiddenCardTopicBody',
    defaultMessage:
      'Only users with read/write access will be able to see the group in the grouplist',
  },
  hiddenCardTYearWheelTitle: {
    id: 'common,hiddenCardTYearWheelTitle',
    defaultMessage: 'Hidden',
  },
  hiddenCardYearWheelBody: {
    id: 'common,hiddenCardYearWheelBody',
    defaultMessage:
      'Only users with read/write access will be able to see the calendar in the calendarlist',
  },
  makeYearWheelPrivate: {
    id: 'common.makeYearWheelPrivate',
    defaultMessage: 'Make the yearwheel private',
  },
  All: {
    id: 'common.All',
    defaultMessage: 'All',
  },
  to: {
    id: 'common.to',
    defaultMessage: 'TO:',
  },
  canEdit: {
    id: 'common.canEdit',
    defaultMessage: 'Write',
  },
  assignedBy: {
    id: 'common.assignedBy',
    defaultMessage: 'Assigned by',
  },
  claps: {
    id: 'common.claps',
    defaultMessage: 'Claps',
  },
  followers: {
    id: 'common.followers',
    defaultMessage: 'Followers',
  },
  finnish: {
    id: 'common.finnish',
    defaultMessage: 'Finnish',
  },

  canEdit1: {
    id: 'common.canEdit1',
    defaultMessage: 'Can write',
  },

  groups: {
    id: 'common.Groups',
    defaultMessage: 'Groups',
  },
  chooseColor: {
    id: 'common.chooseColor',
    defaultMessage: 'Choose color',
  },
  save: {
    id: 'common.save',
    defaultMessage: 'Save',
  },
  calendars: {
    id: 'common.calendars',
    defaultMessage: 'Calendars',
  },
  titleRequired: {
    id: 'common.titleRequired',
    defaultMessage: 'Title is required',
  },
  descriptionRequired: {
    id: 'common.descriptionRequired',
    defaultMessage: 'Descrition is required',
  },
  write: {
    id: 'common.write',
    defaultMessage: 'Write',
  },
  writeTitleHere: {
    id: 'common.writeTitleHere',
    defaultMessage: 'Write title here',
  },
  writeDescriptionHere: {
    id: 'common.writeDescriptionHere',
    defaultMessage: 'Write description here',
  },
  loading: {
    id: 'common.loading',
    defaultMessage: 'Loading...',
  },
  nodata: {
    id: 'common.nodata',
    defaultMessage: 'No rows found',
  },
  page: {
    id: 'common.page',
    defaultMessage: 'Page',
  },
  message: {
    id: 'common.message',
    defaultMessage: 'Message',
  },
  of: {
    id: 'common.of',
    defaultMessage: 'of',
    description: 'page x OF y',
  },
  owner: {
    id: 'common.owner',
    defaultMessage: 'Owner',
  },
  rows: {
    id: 'common.rows',
    defaultMessage: 'rows',
  },
  confirm: {
    id: 'common.confirm',
    defaultMessage: 'Confirm',
  },
  connectYearWheelToTopic: {
    id: 'common.connectYearWheelToTopic',
    defaultMessage: 'Connect year wheel to a group',
  },

  aboutMunikum: {
    id: 'common.aboutMunikum',
    defaultMessage: 'About munikum',
  },
  cancel: {
    id: 'common.cancel',
    defaultMessage: 'Cancel',
  },
  left: {
    id: 'common.left',
    defaultMessage: 'Left',
  },
  right: {
    id: 'common.right',
    defaultMessage: 'Right',
  },
  ok: {
    id: 'common.ok',
    defaultMessage: 'Ok',
  },
  actions: {
    id: 'common.actions',
    defaultMessage: 'Actions',
  },

  mymunicipality: {
    id: 'common.mymunicipality',
    defaultMessage: 'My municipality',
  },
  login: {
    id: 'common.login',
    defaultMessage: 'Log in',
  },
  publish: {
    id: 'common.publish',
    defaultMessage: 'Publish',
  },
  answer: {
    id: 'common.answer',
    defaultMessage: 'Answer',
  },
  answers: {
    id: 'common.answers',
    defaultMessage: 'answers',
  },
  share: {
    id: 'common.share',
    defaultMessage: 'Share',
  },
  name: {
    id: 'profileview.name',
    defaultMessage: 'Name:',
  },
  jobTitle: {
    id: 'profileview.jobTitle',
    defaultMessage: 'Job title:',
  },
  department: {
    id: 'profileview.department',
    defaultMessage: 'Department:',
  },
  resources: {
    id: 'profileview.resources',
    defaultMessage: 'Resources',
  },

  myProfile: {
    id: 'profileview.myProfile',
    defaultMessage: 'My profile',
  },

  organization: {
    id: 'profileview.organization',
    defaultMessage: 'Organization:',
  },
  follow: {
    id: 'common.follow',
    defaultMessage: 'Follow',
  },
  unfollow: {
    id: 'common.unfollow',
    defaultMessage: 'Unfollow',
  },
  followHelpCalendar: {
    id: 'common.followHelpCalendar',
    defaultMessage:
      'Follow this calendar to receive notifications when calendar changes and deadlines are approaching.',
  },
  unfollowHelpCalendar: {
    id: 'common.unfollowHelpCalendar',
    defaultMessage:
      'Click to unfollow calendar. You will no longer receive notifications and e-mails regarding this calendar.',
  },
  eMail: {
    id: 'common.eMail',
    defaultMessage: 'Email',
  },

  more: {
    id: 'common.more',
    defaultMessage: 'More',
  },
  options: {
    id: 'common.options',
    defaultMessage: 'Options',
  },
  lastUpdated: {
    id: 'common.lastUpdated',
    defaultMessage: 'Last updated',
  },
  searchInMunikum: {
    id: 'common.searchInMunikum',
    defaultMessage: 'Search in Munikum...',
  },
  actionValues: {
    id: 'common.actionValues',
    defaultMessage: 'action values',
  },
  comingSoon: {
    id: 'common.comingSoon',
    defaultMessage: 'Coming soon...',
  },
  applicationDeadlines: {
    id: 'common.applicationDeadlines',
    defaultMessage: 'Application deadlines',
  },
  applicationDeadline: {
    id: 'common.applicationDeadline',
    defaultMessage: 'Application deadline',
  },
  reportDeadline: {
    id: 'common.reportingDeadline',
    defaultMessage: 'Report deadline',
  },
  defaultApplicationDeadlinesMessage: {
    id: 'common.defaultApplicationDeadlinesMessage',
    defaultMessage: 'Running',
  },
  goBackButton: {
    id: 'common.goBackButton',
    defaultMessage: 'Back',
  },
  discard: {
    id: 'common.discard',
    defaultMessage: 'Discard',
  },
  uploadFiles: {
    id: 'common.uploadFiles',
    defaultMessage: 'Upload files',
  },
  comment: {
    id: 'common.comment',
    defaultMessage: 'Comment',
  },
  writeHerePlaceholder: {
    id: 'common.writeHerePlaceholder',
    defaultMessage: 'Write here...',
  },
  readMore: {
    id: 'common.readMore',
    defaultMessage: 'Read more',
  },
  readLess: {
    id: 'common.readLess',
    defaultMessage: 'Read less',
  },
  title: {
    id: 'common.title',
    defaultMessage: 'Title',
  },
  last_updated: {
    id: 'common.last_updated',
    defaultMessage: 'Last Updated',
  },
  description: {
    id: 'common.description',
    defaultMessage: 'Description',
  },
  document: {
    id: 'common.documnet',
    defaultMessage: 'Document',
  },
  ExportToCalender: {
    id: 'common.ExportToCalender',
    defaultMessage: 'Export to calender',
  },
  Topics: {
    id: 'common.topics',
    defaultMessage: 'Topics',
  },
  Sort: {
    id: 'common.sort',
    defaultMessage: 'Sort',
  },
  Sector: {
    id: 'common.sector',
    defaultMessage: 'Sector',
  },
  Category: {
    id: 'common.category',
    defaultMessage: 'Category',
  },
  Order: {
    id: 'common.order',
    defaultMessage: 'Order',
  },
  Ascending: {
    id: 'common.ascending',
    defaultMessage: 'Ascending',
  },
  Descending: {
    id: 'common.descending',
    defaultMessage: 'Descending',
  },
  help: {
    id: 'common.help',
    defaultMessage: 'Help',
  },

  unFollowHelpTopic: {
    id: 'common.unFollowHelpTopic',
    defaultMessage:
      'Click to unfollow this topic. You will no longer receive notifications and e-mails regarding this topic',
  },
  followHelpTopic: {
    id: 'common.followHelpTopic',
    defaultMessage:
      'Follow this topic to receive notifications when the topic changes',
  },
  unFollowHelpDiscussion: {
    id: 'common.unFollowHelpDiscussion',
    defaultMessage:
      'Click to unfollow this discussion. You will no longer receive notifications and e-mails regarding this discussion',
  },
  followHelpDiscussion: {
    id: 'common.followHelpDiscussion',
    defaultMessage:
      'Follow this discussion to receive notifications when the discussion changes',
  },
  followHelpGrant: {
    id: 'common.followHelpGrant',
    defaultMessage:
      'Follow this grant to receive notifications when grant changes and deadlines are approaching.',
  },
  unFollowHelpGrant: {
    id: 'common.unFollowHelpGrant',
    defaultMessage:
      'Click to unfollow grant. You will no longer receive notifications and e-mails regarding this grant',
  },
  forum: {
    id: 'common.forum',
    defaultMessage: 'Forum',
  },
  grants: {
    id: 'common.grants',
    defaultMessage: 'Grants',
  },
  topic: {
    id: 'common.topic',
    defaultMessage: 'Topic',
  },
  yearWheel: {
    id: 'common.yearWheel',
    defaultMessage: 'Year wheel',
  },
  lastActivity: {
    id: 'common.lastActivity',
    defaultMessage: 'Last activity',
  },
  last_activity: {
    id: 'common.last_activity',
    defaultMessage: 'Last activity',
  },
  month: {
    id: 'common.month',
    defaultMessage: 'month',
  },
  pie: {
    id: 'common.pie',
    defaultMessage: 'pie',
  },
  verify: {
    id: 'common.verify',
    defaultMessage: 'Verify',
  },
  signUp: {
    id: 'common.signUp',
    defaultMessage: 'Sign up',
  },
  signUpTrial: {
    id: 'common.signUpTrial',
    defaultMessage: 'Start trial',
  },
  eventRequired: {
    id: 'common.eventRequired',
    defaultMessage: 'You must add at least one activity',
  },
  calendarPlaceholder: {
    id: 'common.calendarPlaceholder',
    defaultMessage: 'Select annual cycle...',
  },
  close: {
    id: 'common.close',
    defaultMessage: 'Close',
  },
  retry: {
    id: 'common.retry',
    defaultMessage: 'Retry',
  },
  clickHere: {
    id: 'common.clickHere',
    defaultMessage: 'click here',
  },
  selected: {
    id: 'common.selected',
    defaultMessage: 'selected',
  },
  errorLoadData: {
    id: 'common.errorLoadData',
    defaultMessage: 'Error loading data',
  },
  reload: {
    id: 'common.reload',
    defaultMessage: 'Reload',
  },
  noaccess: {
    id: 'common.noaccess',
    defaultMessage: 'No access',
  },
  filepickerAddFiles: {
    id: 'filepicker.addfiles',
    defaultMessage: 'Add files',
  },
  filepickerAddMessage: {
    id: 'filepicker.addmessage',
    defaultMessage: 'Drop file(s) here or click on the button below.',
  },
  filepickerChooseFileses: {
    id: 'filepicker.choosefileses',
    defaultMessage: 'Choose file(s)',
  },
  filepickerChooseFile: {
    id: 'filepicker.choosefile',
    defaultMessage: 'Add file',
  },
  filepickerChooseFiles: {
    id: 'filepicker.choosefiles',
    defaultMessage: 'Add files',
  },
  filepickerNotAccepted: {
    id: 'filepicker.notaccepted',
    defaultMessage: 'File type is not accepted',
  },
  filepickerIsSelected: {
    id: 'filepicker.isselected',
    defaultMessage: 'File is already selected',
  },
  filepickerTooBig: {
    id: 'filepicker.tobig',
    defaultMessage: 'File size too big',
  },
  deadline: {
    id: 'common.deadline',
    defaultMessage: 'Deadline',
  },
  logins: {
    id: 'common.logins',
    defaultMessage: 'Your logins',
  },
  apiKeys: {
    id: 'common.apiKeys',
    defaultMessage: 'API keys',
  },
  archive: {
    id: 'common.archive',
    defaultMessage: 'Archive',
  },
  archived: {
    id: 'common.archived',
    defaultMessage: 'Archived',
  },
  archiveCalendarWarning: {
    id: 'common.archiveCalendarWarning',
    defaultMessage: 'Are you sure you want to archive this calendar?',
  },
  calendarArchivedNotification: {
    id: 'common.calendarArchivedNotification',
    defaultMessage: 'This annual cycle is archived and cannot be edited.',
  },
  calendarFork: {
    id: 'common.calendarFork',
    defaultMessage: 'Sub Annual Cycles',
  },
  copiedFrom: {
    id: 'common.copiedFrom',
    defaultMessage: 'Copied from',
  },
  copyAsTemplate: {
    id: 'common.copyAsTemplate',
    defaultMessage: 'Copy as template',
  },
  copyAsTemplateTooltip: {
    id: 'common.copyAsTemplateTooltip',
    defaultMessage: 'Standalone annual cycle',
  },
  copyAsForkedCalendar: {
    id: 'common.copyAsForkedCalendar',
    defaultMessage: 'Copy as sub annual cycle',
  },
  copyAsForkedCalendarTooltip: {
    id: 'common.copyAsForkedCalendarTooltip',
    defaultMessage: 'An annual cycle connected to this annual cycle',
  },
  thisTaskInForkedCalendar: {
    id: 'common.thisTaskInForkedCalendar',
    defaultMessage: 'This task in sub annual cycles',
  },
});
