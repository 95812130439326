import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import * as React from 'react';
import { RefObject, Suspense } from 'react';
import styled from 'styled-components';
import {
  emailSelector,
  isAdminSelector,
  permissionsSelector,
  sidebarChanged,
  signout,
  userHashSelector,
  userInitialsSelector,
  usernameSelector,
} from '../../modules/auth/auth';
import { Header2 } from './Header';
import { CleanDialog } from '../../components/basic/Dialog/CleanDialog';
import { resetDiscussionFilterActionCreator } from '../../modules/discussion/discussion';
import {
  checkNotificationsActionCreator,
  notificationUnreadCountSelector,
} from '../../modules/notification/notification';
import { IContentMeta } from '../../services/models/contentMeta';
import { MainMenu } from './MainMenu';
import { getBuildNumber } from '../../components/layout/VersionNumber/VersionNumber';
import { RightSidebar, RightSidebarContentType } from './RightSidebar';
import { clientResetActionValuesFilterActionCreator } from '../../modules/actionValue/actionValue';
import { ServiceWorkerUpdate } from '../../components/layout/ServiceWorkerUpdate/ServiceWorkerUpdate';
import { SupportInfo } from '../../components/layout/SupportInfo/SupportInfo';
import { MyHelmet } from '../../components/helmet/MyHelmet';
import {
  Colors,
  isFunction,
  safeInvoke,
  safeInvokeDeprecated,
} from '../../components/common';
import { INotification } from '../../services/models/notification';
import { Link } from 'react-router-dom';
import { OmniSearchComp } from '../../components/omniSearch/OmniSearch';
import { shouldScrollToTop } from '../../modules/ui/mainLayout/mainLayout';
import { RootState } from '../../modules/rootReducer';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { sizes } from '../../components/common/media';
import ErrorBoundary from './ErrorBoundary';
import { MunikumScrollContext } from '../../components/ScrollToTop/MunikumScrollContext';
import { AuthConsumer } from '../../auth';
import { myStore, teamsContext } from '../../index';
import { Query } from 'react-apollo';
import { GET_ME } from '../../components/annualCycle/CreateAnnualCycleForm';
import munikumLogo from '../MainLayout/munikum-logo.png';
import { MunikumIcons } from '../../components/common/icons';

import MediaQuery from 'react-responsive';
import { UPDATE_ME_SETTINGS } from '../../components/person/personQueries';
import { myApolloClient } from '../../graphql/apolloClientFactory';

const messages = defineMessages({
  searchPlaceholder: {
    id: 'MainLayout.searchPlaceholder',
    defaultMessage: 'Search in Munikum...',
  },
  userMenu: {
    id: 'Header.userMenu',
    defaultMessage: 'User menu',
  },
  myProfile: {
    id: 'Header.myProfile',
    defaultMessage: 'My profile',
  },
  signout: {
    id: 'Header.signout',
    defaultMessage: 'Sign out',
  },
});

interface IProps2 {
  isUpdateMessageVisible: boolean;
  isLeftSidebarExpanded: boolean;
  isTeams: boolean;
}

const LayoutContainer = styled.div`
  display: block; // mobile
  @media (min-width: ${sizes.medium}px) {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
  }

  @media print {
    display: block;
    width: 100%;
    margin: 0;
    float: none;
    overflow: visible;
    background-color: #fff;

    font-size: 0.8em; // NOTE: this will scale down entire content inside when printing!!!
  }

  color: ${props => props.theme.textColor};
`;

// background-color: ${Colors.BLACK};

const LeftSidebarContainer = styled.div`
  width: 100%; // mobile
  display: block;
  background-color: ${props => props.theme.menuBackgroundColor};

  position: relative;
  transition: ease-in-out 0.3s;

  @media (min-width: ${sizes.ipad}px) {
    display: ${(props: { isSidebarExpanded: boolean; isTeams: boolean }) =>
            props.isTeams ? 'none' : 'flex'};
    justify-content: space-between;
    flex-direction: column;

    width: ${(props: { isSidebarExpanded: boolean; isTeams: boolean }) =>
            props.isTeams ? '0em' : props.isSidebarExpanded ? '15.6em' : '4em'};
    height: 100vh;
    position: fixed;
    z-index: 10;
    //overflow: hidden;
  }

  @media print {
    display: none;
  }
`;

const MainContainer = styled.div`
  //background-color: orange;
  width: 100%;
  overflow: hidden;
  min-height: 100%;
  margin-left: 0;
  @media (min-width: ${sizes.medium}px) {
    //position: relative;
    margin-left: ${(props: {
      isLeftSidebarExpanded: boolean;
      isTeams: boolean;
    }) => (props.isLeftSidebarExpanded && !props.isTeams ? '15.625em' : '0')};
  }
  @media print {
    width: 100%;
    margin: 0;
    float: none;
    overflow: visible;
    background-color: #fff;
  }
`;

// margin: ${(props: { isFixed: boolean }) => props.isFixed ? '0 auto' : ''}

const HeaderContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  z-index: 9;

  @media (min-width: ${sizes.medium}px) {
    height: ${(props: { isUpdateMessageVisible: boolean; isTeams: boolean }) =>
            props.isTeams
                    ? '0em'
                    : props.isUpdateMessageVisible
                            ? '6.175em'
                            : '3.125em'};
  }

  @media print {
    display: none;
  }
`;

const ShadowHack = styled.div`
  display: none;

  @media (min-width: ${sizes.medium}px) {
    display: ${(props: IProps2) => (props.isTeams ? 'none' : 'unset')};
    //border-top: 1px solid #d0d3d4;
    //box-shadow: 0 -1px 10px 1px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 6px 10px -5px rgba(0, 0, 0, 0.2);

    height: 20px;
    //background-color: rgba(44, 44, 56, .4);
    position: absolute;
    top: ${(props: IProps2) =>
            props.isTeams
                    ? '0em'
                    : props.isUpdateMessageVisible
                            ? '6.175em'
                            : '3.125em'};
    left: ${(props: IProps2) =>
            props.isTeams ? '0em' : props.isLeftSidebarExpanded ? '15.625em' : '4em'};

    right: 0;

    z-index: 10;
  }

  @media print {
    display: none;
  }
`;

// position: absolute;
// top: ${(props: { isHeaderFixed: boolean }) =>
// props.isHeaderFixed ? '5em' : '0'};

const SidebarAndMainContainer = styled.div`
  background-color: #f3f3f3;

  @media (min-width: ${sizes.medium}px) {
    top: ${(props: IProps2) =>
            props.isTeams
                    ? '0em'
                    : props.isUpdateMessageVisible
                            ? '6.125em'
                            : '3.125em'};
    left: ${(props: IProps2) =>
            props.isTeams
                    ? '0em'
                    : props.isLeftSidebarExpanded
                            ? '15.625em'
                            : '3.125em'};
    position: absolute;
    right: 0;
    bottom: 0;

    overflow-y: auto;
  }

  @media print {
    position: relative;
    display: block;
    left: 0;
    top: 0;

    width: 100%;
    margin: 0;
    float: none;
    overflow: visible;
    background-color: #fff;
  }
`;

const LeftSidebarDiv = styled.div`
  width: 100%;
  display: ${(props: { isLeftSidebarExpanded: boolean }) =>
          props.isLeftSidebarExpanded ? 'block' : 'flex'};
  margin-top: ${(props: { isLeftSidebarExpanded: boolean }) =>
          props.isLeftSidebarExpanded ? '0' : '1em'};
  margin-left: ${(props: { isLeftSidebarExpanded: boolean }) =>
          props.isLeftSidebarExpanded ? '0' : '-.7em'};
  justify-content: ${(props: { isLeftSidebarExpanded: boolean }) =>
          props.isLeftSidebarExpanded ? 'center' : 'flex-end'};
  flex: 0 0 auto;
`;

const ContentContainer = styled.div`
  background-color: ${props => props.theme.bodyBackgroundColor};
  //min-height: 100vh;
  min-height: 100%;
  box-sizing: border-box;
  //padding: 1em 1em;
  padding: 0.5em 1em;
  z-index: 1;
  @media (min-width: ${sizes.medium}px) {
    //padding: 1.5em 2em;
    padding: 1em 2em;
  }
  @media (min-width: ${1300}px) {
    //padding: 2em 5em;
    padding: 1em 5em;
  }
`;

const SidebarLogo = styled.div`
  max-width: 340px;
  @media (min-width: ${sizes.medium}px) {
    max-width: ${(props: { isLeftSidebarExpanded: boolean }) =>
            props.isLeftSidebarExpanded ? '340px' : '3em'};
  }
`;

const NavLogo = styled.img`
  display: block;
  margin-left: ${(props: { isLeftSidebarExpanded: boolean }) =>
          props.isLeftSidebarExpanded ? '0.65em' : '0.4em'};
  margin-top: 0.45em;
  //margin-right: auto;
  width: 85%;
  //background-color: orange;
`;

const BetaInfo = styled.div`
  position: relative;
  left: 5.5em;
  top: -1em;

  @media (min-width: ${sizes.medium}px) {
    position: relative;
    left: 4.25em;
    top: -0.7em;
  }

  text-transform: uppercase;
  font-family: 'Lato', sans-serif;
  font-size: 0.75em;
  font-weight: 300;

  color: ${Colors.WHITE};
`;

const MobileFooter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%; // mobile
  background-color: ${Colors.BLACK};
  padding-top: 2em;

  @media (min-width: ${sizes.medium}px) {
    display: none;
  }
`;

const SidebarFooter = styled.div`
  display: none; // mobile
  @media (min-width: ${sizes.medium}px) {
    display: flex;
    flex-direction: column;

    justify-content: flex-end;
    align-items: center;
    min-height: 12.25em;
  }
`;
const ToogleExpandDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 1.4em;
  height: 1.4em;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);

  border: 1px solid ${Colors.GREY};

  &:hover {
    cursor: pointer;
  }
`;

const logo = require('./munikum-logo-white.svg') as string;

export interface IMainLayoutProps {
  /**
   * set this to controlled mode
   */
  rightSidebarContent?: RightSidebarContentType;

  /**
   * Used in Helmet (tracking)
   */
  username?: string;

  userHash?: string;

  isAdmin: boolean;
  /**
   * Used in Helmet (tracking)
   */
  email?: string;

  /**
   * Users initials, example: TT. Two letters..
   */
  initials: string;

  /**
   * Notiofications for current user
   */
  notifications?: ReadonlyArray<INotification>;

  /**
   * count unread notifications
   * setting this as prop to cache counting with reselect (mainlayout renders a lot)
   */
  unreadNotificationsCount: number;

  serviceWorkerUpdated: boolean;

  isLeftSidebarExpanded: boolean;

  forums?: ReadonlyArray<IContentMeta>;
  permissions?: ReadonlyArray<string>;
  notificationsLastChecked?: Date;
  shouldScrollToTop?: boolean;
  shouldScrollToPosition?: { x: number; y: number };
}

export interface IMainLayoutDispatch {
  onToggleLeftSidebar: (isExpanded: boolean) => void;

  onToggleRightSidebar?: (isExpanded: boolean) => void;

  // /**
  //  * @deprecated for now..
  //  */
  // onLogout?: () => void;

  // onClickAvatar?: () => void;

  onClickSignOut: () => void;

  onClickNotifications?: () => void;

  onDoneScrolling?: () => void;

  onClickActionValue?: () => void;

  onClickForum?: () => void;
}

export interface IMainLayoutState {
  /**
   * is LEFT sidebar expanded
   */

  /**
   * is RIGHT sidebar expanded
   */
  isHoveringExpandButton: boolean;
  isRightSidebarExpanded: boolean;

  /**
   * what is displayed in right sidebar?
   */
  rightSidebarContent: RightSidebarContentType;

  notifications?: ReadonlyArray<INotification>;

  shouldFlash: boolean;

  isOmniSearchOpen: boolean;
}

export class MainLayoutComponent extends React.PureComponent<
  IMainLayoutProps & IMainLayoutDispatch & InjectedIntlProps,
  IMainLayoutState
> {
  private myRef: React.RefObject<HTMLDivElement>;

  private headerRef: RefObject<Header2>;

  private omniSearchRef: RefObject<any>;

  constructor(
    props: IMainLayoutProps & IMainLayoutDispatch & InjectedIntlProps
  ) {
    super(props);

    this.state = {
      isHoveringExpandButton: false,
      isRightSidebarExpanded: false,
      rightSidebarContent: RightSidebarContentType.Favourites,
      notifications: props.notifications || [],
      shouldFlash: false,
      isOmniSearchOpen: false,
    };

    this.myRef = React.createRef();

    this.headerRef = React.createRef();
    this.omniSearchRef = React.createRef();
  }

  UNSAFE_componentWillMount() {
    document.addEventListener('keydown', this.handleKeyDown.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown.bind(this));
  }

  UNSAFE_componentWillReceiveProps(nextProps: IMainLayoutProps) {
    const { notifications } = this.props;

    this.setState({
      notifications: notifications,
    });

    if (
      nextProps.shouldScrollToTop !== this.props.shouldScrollToTop &&
      nextProps.shouldScrollToTop
    ) {
      this.handleScrollToTop(true);
    }

    if (
      nextProps.shouldScrollToPosition &&
      nextProps.shouldScrollToPosition !== this.props.shouldScrollToPosition
    ) {
      this.handleScrollToPosition(
        nextProps.shouldScrollToPosition.x,
        nextProps.shouldScrollToPosition.y,
        true
      );
    }
  }

  UNSAFE_componentWillUpdate() {
    if (this.state.shouldFlash && this.headerRef.current) {
      safeInvokeDeprecated(this.headerRef.current.flashStar);
      this.setState({
        shouldFlash: false,
      });
    }
  }

  //
  // handleToggleLeftSidebar = () => {
  //   this.setState(
  //     {
  //       ...this.state,
  //       isLeftSidebarExpanded: !this.state.isLeftSidebarExpanded,
  //     },
  //     () => {
  //       safeInvoke(
  //         this.props.onToggleLeftSidebar,
  //         this.state.isLeftSidebarExpanded
  //       );
  //     }
  //   );
  // };

  handleClickFavourites = () => {
    // console.log('click fav');
    if (this.state.isRightSidebarExpanded) {
      if (
        this.state.rightSidebarContent === RightSidebarContentType.Favourites
      ) {
        // if expanded and favourites already selected, then close sidebar (?)
        this.setState(
          {
            ...this.state,
            isRightSidebarExpanded: false,
          },
          () => {
            safeInvokeDeprecated(this.props.onToggleRightSidebar, false);
          }
        );
      } else {
        // if expanded, but favourites not selected, then select favs
        this.setState({
          ...this.state,
          rightSidebarContent: RightSidebarContentType.Favourites,
        });
      }
    } else {
      this.setState(
        {
          ...this.state,
          isRightSidebarExpanded: true,
          rightSidebarContent: RightSidebarContentType.Favourites,
        },
        () => {
          safeInvokeDeprecated(this.props.onToggleRightSidebar, true);
        }
      );
    }
  };

  handleClickNotifications = () => {
    if (this.state.isRightSidebarExpanded) {
      if (
        this.state.rightSidebarContent === RightSidebarContentType.Notifications
      ) {
        // if expanded and notifications already selected, then close sidebar (?)
        this.setState(
          {
            ...this.state,
            isRightSidebarExpanded: false,
          },
          () => {
            safeInvoke(this.props.onToggleRightSidebar, false);
          }
        );
      } else {
        // if expanded, but notifications not selected, then select notifics
        myStore.dispatch(checkNotificationsActionCreator.started({}));
        this.setState({
          ...this.state,
          rightSidebarContent: RightSidebarContentType.Notifications,
        });
      }
    } else {
      myStore.dispatch(checkNotificationsActionCreator.started({}));
      this.setState(
        {
          ...this.state,
          isRightSidebarExpanded: true,
          rightSidebarContent: RightSidebarContentType.Notifications,
        },
        () => {
          safeInvoke(this.props.onToggleRightSidebar, true);
        }
      );
    }
  };
  //
  // handleClickAvatar = () => {
  //   safeInvoke(this.props.onClickAvatar);
  // };

  handleRightSidebarEscape = () => {
    // console.log('my right wants to escape..');
    if (this.state.isRightSidebarExpanded) {
      this.setState(
        {
          ...this.state,
          isRightSidebarExpanded: false,
        },
        () => {
          safeInvokeDeprecated(this.props.onToggleRightSidebar, false);
        }
      );
    }
  };

  handleScrollToPosition = (x: number, y: number, isRedux: boolean = false) => {
    // console.log('handleScrollTo ' + x + ', ' + y);
    if (this.myRef.current) {
      const opt: ScrollToOptions = {
        left: x,
        top: y + this.myRef.current.scrollTop,
        behavior: 'smooth',
      };

      if (isFunction(this.myRef.current.scrollTo)) {
        // Fancy browser
        this.myRef.current.scrollTo(opt);
      } else {
        // IE 11
        this.myRef.current.scrollTop = y;
        this.myRef.current.scrollLeft = x;
      }
    }
    if (isRedux) {
      safeInvokeDeprecated(this.props.onDoneScrolling);
    }
  };

  handleScrollToTop = (isRedux: boolean = false) => {
    if (this.myRef.current) {
      this.myRef.current.scrollTop = 0; // no smooth scrolling here? just jump to top?
    }
    if (isRedux) {
      safeInvokeDeprecated(this.props.onDoneScrolling);
    }
  };

  // tslint:disable-next-line
  handleClickOutsideRightSidebar = (e: any) => {
    // console.log('right sidebar was open, and you clicked OUTSIDE!', e);

    // mini-hack to ignore outside click if it was favourite or notification buttons:
    let isInsideWhitelistButts = false;
    try {
      const temp =
        e.target.parentNode.attributes.class.value.indexOf(
          'disable-global-click'
        ) >= 0;
      if (temp) {
        isInsideWhitelistButts = true;
      }
      // tslint:disable-next-line
    } catch (e) {
      // tslint:disable-next-line
    }
    try {
      const temp =
        e.target.attributes.class.value.indexOf('disable-global-click') >= 0;
      if (temp) {
        isInsideWhitelistButts = true;
      }
      // tslint:disable-next-line
    } catch (e) {
      // tslint:disable-next-line
    }

    if (this.state.isRightSidebarExpanded && !isInsideWhitelistButts) {
      this.setState(
        {
          ...this.state,
          isRightSidebarExpanded: false,
        },
        () => {
          safeInvokeDeprecated(this.props.onToggleRightSidebar, false);
          safeInvokeDeprecated(this.props.onToggleRightSidebar, false);
        }
      );
    }
  };

  handleKeyDown = (e: any) => {
    // console.log(e);

    if (e.ctrlKey && e.key === 'k') {
      e.preventDefault();
      e.stopPropagation();
      this.handleOpenOmniSearch();

      // if (this.omniSearchRef.current) {
      //   // console.log('test', this.omniSearchRef.current);
      //   // this.omniSearchRef.current.myFocus();
      //
      //   // const test3 = this.omniSearchRef.current.getWrappedIntance();
      //   // console.log('test3', test3);
      //
      //   const test2 = this.omniSearchRef.current.refs.wrappedInstance;
      //   // console.log('test2', test2);
      //   test2.myFocus();
      // }
    }

    if (e.keyCode === 27) {
      e.preventDefault();
      e.stopPropagation();
      this.handleEsc();
    }
  };

  handleOpenOmniSearch = () => {
    this.setState(
      {
        isOmniSearchOpen: true,
      },
      () => {
        if (this.omniSearchRef.current) {
          this.omniSearchRef.current.focus();
        }
      }
    );
  };

  handleEsc = () => {
    this.setState({
      isOmniSearchOpen: false,
    });
  };

  render() {
    const {
      username,
      email,
      intl,
      notifications,
      unreadNotificationsCount,
      serviceWorkerUpdated,
      forums,
      isLeftSidebarExpanded,
    } = this.props;

    const { isRightSidebarExpanded, rightSidebarContent } = this.state;

    const version = getBuildNumber();
    const that = this;

    // Ugly hack to request notification permission
    document.addEventListener('click', () => {
      if (Notification.permission === 'default') {
        Notification.requestPermission();
      }
    });

    const isTeams: boolean = teamsContext;

    return (
      <MunikumScrollContext.Provider
        value={{
          scrollToPosition: (x: number, y: number) =>
            that.handleScrollToPosition(x, y),
          scrollToTop: () => that.handleScrollToTop(),
        }}
      >
        <LayoutContainer onKeyPress={this.handleKeyDown}>
          <MyHelmet username={username} email={email} />

          <AuthConsumer>
            {props => {
              // console.log('AUTH_CONSUME_RNDR', props);
              return (
                <LeftSidebarContainer
                  isSidebarExpanded={isLeftSidebarExpanded}
                  isTeams={isTeams}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: '1 1 auto',
                    }}
                  >
                    <LeftSidebarDiv
                      isLeftSidebarExpanded={isLeftSidebarExpanded}
                    >
                      <>
                        <MediaQuery
                          query={'(min-width: ' + sizes.medium + 'px)'}
                        >
                          <div
                            style={{
                              position: 'absolute',
                              right: '-.75em',
                              top: '4em',
                              zIndex: 100,
                            }}
                          >
                            <ToogleExpandDiv
                              onMouseEnter={() =>
                                this.setState({ isHoveringExpandButton: true })
                              }
                              onMouseLeave={() =>
                                this.setState({ isHoveringExpandButton: false })
                              }
                              style={{
                                backgroundColor: this.state
                                  .isHoveringExpandButton
                                  ? Colors.RED
                                  : '#fff',
                              }}
                              onClick={() => {
                                safeInvoke(
                                  this.props.onToggleLeftSidebar,
                                  !isLeftSidebarExpanded
                                );
                                myApolloClient.mutate({
                                  mutation: UPDATE_ME_SETTINGS,
                                  variables: {
                                    input: {
                                      isSidebarExpanded: !isLeftSidebarExpanded,
                                    },
                                  },
                                });
                              }}
                            >
                              {isLeftSidebarExpanded ? (
                                <MunikumIcons.ArrowLeft
                                  className={'arrow'}
                                  style={{
                                    color: this.state.isHoveringExpandButton
                                      ? Colors.WHITE
                                      : Colors.RED,
                                    height: '1.5em',
                                    width: '1.5em',
                                  }}
                                />
                              ) : (
                                <MunikumIcons.ArrowRight
                                  className={'arrow'}
                                  style={{
                                    color: this.state.isHoveringExpandButton
                                      ? Colors.WHITE
                                      : Colors.RED,
                                    height: '1.5em',
                                    width: '1.5em',
                                  }}
                                />
                              )}
                            </ToogleExpandDiv>
                          </div>
                        </MediaQuery>
                        <SidebarLogo
                          isLeftSidebarExpanded={isLeftSidebarExpanded}
                        >
                          <Link to="/">
                            <MediaQuery
                              query={'(min-width: ' + sizes.medium + 'px)'}
                            >
                              <NavLogo
                                isLeftSidebarExpanded={isLeftSidebarExpanded}
                                src={isLeftSidebarExpanded ? logo : munikumLogo}
                                alt={'logo'}
                              />
                            </MediaQuery>
                            <MediaQuery
                              query={
                                '(max-width: ' + (sizes.medium - 1) + 'px)'
                              }
                            >
                              <NavLogo
                                isLeftSidebarExpanded={isLeftSidebarExpanded}
                                src={logo}
                                alt={'logo'}
                              />
                            </MediaQuery>
                          </Link>
                        </SidebarLogo>
                        {/*{isLeftSidebarExpanded && (*/}
                        {/*  <BetaInfo>{version}</BetaInfo>*/}
                        {/*)}*/}
                      </>
                    </LeftSidebarDiv>
                    {props.userAuthInfo && props.userAuthInfo.hash && (
                      <>
                        <MediaQuery
                          query={'(min-width: ' + sizes.medium + 'px)'}
                        >
                          <MainMenu
                            isLeftSidebarExpanded={isLeftSidebarExpanded}
                            style={{
                              flex: '1 1 auto',
                              overflow: 'auto',
                            }}
                            userHash={
                              props.userAuthInfo && props.userAuthInfo.hash
                            }
                            forums={forums}
                          />
                        </MediaQuery>

                        <MediaQuery
                          query={'(max-width: ' + (sizes.medium - 1) + 'px)'}
                        >
                          <MainMenu
                            isLeftSidebarExpanded={true}
                            style={{
                              flex: '1 1 auto',
                              overflow: 'auto',
                            }}
                            userHash={
                              props.userAuthInfo && props.userAuthInfo.hash
                            }
                            forums={forums}
                          />
                        </MediaQuery>
                      </>
                    )}
                  </div>
                  <MediaQuery query={'(min-width: ' + sizes.medium + 'px)'}>
                    <SidebarFooter
                      style={{
                        flex: '0 0 auto',
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <SupportInfo
                        isLeftSidebarExpanded={isLeftSidebarExpanded}
                        style={{ marginBottom: '2.250em' }}
                      />
                    </SidebarFooter>
                  </MediaQuery>
                </LeftSidebarContainer>
              );
            }}
          </AuthConsumer>
          <MainContainer
            isLeftSidebarExpanded={isLeftSidebarExpanded}
            isTeams={isTeams}
          >
            <HeaderContainer
              isUpdateMessageVisible={serviceWorkerUpdated}
              isTeams={isTeams}
            >
              {serviceWorkerUpdated && <ServiceWorkerUpdate />}
              <Query query={GET_ME}>
                {({ loading, error, data, networkStatus }) => {
                  if (loading) {
                    return '';
                  }
                  const me = data && data.me && data.me.name;
                  return (
                    <Header2
                      isLeftSidebarExpanded={isLeftSidebarExpanded}
                      ref={this.headerRef}
                      initials={me}
                      onClickFavourites={this.handleClickFavourites}
                      // onClickAvatar={this.handleClickAvatar}
                      handleClickSearch={this.handleOpenOmniSearch}
                      onSignOut={() => safeInvoke(this.props.onClickSignOut)}
                      onClickNotifications={this.handleClickNotifications}
                      notificationCount={unreadNotificationsCount}
                      searchPlaceholder={'Søk (CTRL + K)'}
                      userMenuLabel={intl.formatMessage(messages.userMenu)}
                      myProfileLabel={intl.formatMessage(messages.myProfile)}
                      signoutLabel={intl.formatMessage(messages.signout)}
                    />
                  );
                }}
              </Query>
            </HeaderContainer>
            <ShadowHack
              isLeftSidebarExpanded={isLeftSidebarExpanded}
              isUpdateMessageVisible={serviceWorkerUpdated}
              isTeams={isTeams}
            />
            <SidebarAndMainContainer
              isLeftSidebarExpanded={isLeftSidebarExpanded}
              isTeams={isTeams}
              ref={this.myRef}
              isUpdateMessageVisible={serviceWorkerUpdated}
            >
              <RightSidebar
                isHeaderFixed={true}
                isExpanded={isRightSidebarExpanded}
                notifications={notifications}
                // followedDiscussions={followedDiscussions}
                // followedActionValues={followedActionValues}
                // followedTopics={followedTopics}
                rightSidebarContent={rightSidebarContent}
                onClickOutside={this.handleClickOutsideRightSidebar}
                onEscape={this.handleRightSidebarEscape}
              />

              {/**/}
              {/*<ContentContainer style={{ paddingTop: '2em' }}>*/}
              {/*<AutoBreadCrumbs*/}
              {/*intl={this.props.intl}*/}
              {/*style={{ marginBottom: '1em' }}*/}
              {/*/>*/}

              {/* TODO: spinner when loading html lazy? (flickering?..) */}
              <Suspense fallback={''}>
                <ErrorBoundary>{this.props.children}</ErrorBoundary>
              </Suspense>
              {/*</ContentContainer>*/}
            </SidebarAndMainContainer>
          </MainContainer>
          <MobileFooter>
            <SupportInfo
              isLeftSidebarExpanded={isLeftSidebarExpanded}
              style={{ marginBottom: '2.250em' }}
            />
          </MobileFooter>
          <CleanDialog
            canOutsideClickClose={true}
            isOpen={this.state.isOmniSearchOpen}
            onClose={() => this.setState({ isOmniSearchOpen: false })}
            canEscapeKeyClose={true}
            style={{
              height: '90%',
              width: '90%',
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'stretch',
              alignItems: 'stretch',
              boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.1)',
              borderRadius: '3px',
            }}
          >
            <>
              <OmniSearchComp
                intl={intl}
                ref={this.omniSearchRef}
                close={() => {
                  this.setState({
                    isOmniSearchOpen: false,
                  });
                }}
              />
            </>
          </CleanDialog>
        </LayoutContainer>
      </MunikumScrollContext.Provider>
    );
  }
}

const mapStateToProps = (
  state: RootState
  // ownProps: Pick<IMainLayoutProps, 'title'>
): IMainLayoutProps => ({
  username: usernameSelector(state),
  email: emailSelector(state),
  isLeftSidebarExpanded: state?.auth?.user?.profile?.isSidebarExpanded ?? true,
  userHash: userHashSelector(state),
  isAdmin: isAdminSelector(state),
  initials: userInitialsSelector(state),
  // icon: ownProps.icon,
  notifications: state.notifications.notifications, // ok?
  // followedActionValues: actionValueFollowedListSelector(state),
  // followedDiscussions: discussionFollowedListSelector(state),
  // followedTopics: topicFollowedListSelector(state),
  // followedTouched: state && state.meta.follow.touched,
  // followedCount: followedMetasCountSelector(state),
  unreadNotificationsCount: notificationUnreadCountSelector(state),
  serviceWorkerUpdated:
    state.serviceWorker && state.serviceWorker.serviceWorkerUpdated,
  permissions: permissionsSelector(state),
  notificationsLastChecked:
    state.notifications && state.notifications.lastCheck,
  shouldScrollToTop: state.ui.mainLayout.shouldScrollToTop,
  shouldScrollToPosition: state.ui.mainLayout.shouldScrollToPosition,
});

const mapDispatchToProps = (dispatch: Dispatch<{}>): IMainLayoutDispatch => ({
  onToggleLeftSidebar: isExpanded => {
    dispatch(sidebarChanged(isExpanded));
  },
  // onLogout: () => {
  //   dispatch(signout({ reason: 'logged out from MainLayout' }));
  // },
  // onClickAvatar: () => {
  //   dispatch(push('/profile'));
  // },
  onClickSignOut: () => {
    dispatch(signout({ reason: 'from menu' }));
  },
  onClickNotifications: () => {
    dispatch(checkNotificationsActionCreator.started({}));
  },
  onDoneScrolling: () => {
    dispatch(shouldScrollToTop.done({ params: {}, result: {} }));
  },
  onClickActionValue: () => {
    // console.log('resetting filter');
    dispatch(clientResetActionValuesFilterActionCreator({}));
  },
  onClickForum: () => {
    dispatch(resetDiscussionFilterActionCreator({}));
  },
});

export const MainLayoutPure = injectIntl(MainLayoutComponent);

/***
 * This is the "connected" MainLayout. props and handlers are connected to redux.
 */

export const ConnectedMainLayoutPure = connect<
  IMainLayoutProps,
  IMainLayoutDispatch
>(
  mapStateToProps,
  mapDispatchToProps
)(MainLayoutPure);

const MainLayoutCc: React.FunctionComponent<IMainLayoutProps &
  IMainLayoutDispatch &
  InjectedIntlProps> = props => {
  return (
    <MainLayoutComponent
      onToggleLeftSidebar={props.onToggleLeftSidebar}
      isLeftSidebarExpanded={props.isLeftSidebarExpanded}
      isAdmin={props.isAdmin}
      initials={props.initials}
      unreadNotificationsCount={props.unreadNotificationsCount}
      serviceWorkerUpdated={props.serviceWorkerUpdated}
      onClickSignOut={props.onClickSignOut}
      intl={props.intl}
    >
      <ContentContainer style={{ paddingTop: '2em' }}>
        {props.children}
      </ContentContainer>
    </MainLayoutComponent>
  );
};

export const MainLayout = injectIntl(MainLayoutCc);

export const ConnectedMainLayout = connect<
  IMainLayoutProps,
  IMainLayoutDispatch
>(
  mapStateToProps,
  mapDispatchToProps
)(MainLayout);
