import * as React from 'react';
import { CSSProperties, RefObject } from 'react';
// import { RefHandler } from 'react-popper';
import _ from 'lodash';
import { IconType } from 'react-icons/lib';
import {
  defineMessages,
  InjectedIntl,
  InjectedIntlProps,
  injectIntl,
} from 'react-intl';
import styled from 'styled-components';
import { TextField, ValidationSummary } from '..';
import { MunikumIcons } from '../../common/icons';
import { FieldGroup } from '../FieldGroup/FieldGroup';
import {
  Colors,
  getNamesAndValues,
  ILabelProps,
  IMessageProps,
  isFunction,
  Omit,
  safeInvoke,
} from '../../common';
import { MenuItem } from '../Menu';
import { FormLabel } from '../FormLabel/FormLabel';
import { MunikumKeys } from '../../common/keys';
import { Popover } from '../Popover/Popover';
import { UltimateFilterBox } from './UltimateFilterBox';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';

const ultimateMessages = defineMessages({
  noItems: {
    id: 'UltimateDropdown.noitems',
    defaultMessage: 'No items',
  },
  defaultDropdownText: {
    id: 'UltimateDropdown.defaultDropdownText',
    defaultMessage: 'Select...',
  },
});

export interface IItemRenderProps {
  /**
   * attach this to let the ULTIMATE dropdown handle mouse click :-)
   */
  handleClick: () => void;

  /**
   * items index in the list
   */
  index: number;

  /**
   * is item disabled?
   */
  isDisabled: boolean;

  /**
   * is item active / mouseOVer?
   */
  isActive: boolean;

  /**
   * true if current item is SELECTED / checked
   */
  isSelected: boolean;

  selectMode: SelectMode;

  key: string;
}

export interface IDropdownItem {
  id: string;
}

export type IItemRenderFunc<T> = (
  item: T,
  itemProps: IItemRenderProps
) => JSX.Element;

export interface ITargetExtraInfo<T> {
  selectedItems: ReadonlyArray<T>;
  isOpen: boolean;
}

export type ITargetRenderFunc<T> = (
  ref: any,
  info: ITargetExtraInfo<T>
) => JSX.Element | null | false;

export type IListItemRenderFunc<T> = (
  items?: ReadonlyArray<T>
) => // TODO: add more if needed
JSX.Element;

export interface IItemGrouped<T> {
  header: string;
  items: ReadonlyArray<T>;
}

export type IGroupedItemHeaderRenderFunc = (
  header: string,
  key: string
) => JSX.Element;

export enum SelectMode {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
}

const EmptyText = styled.div`
  font-family: 'Lato', sans-serif;
  font-size: 1em;
  line-height: 1.5em;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const EmptyDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  padding: 0.25em;
`;
const AddTagDiv = styled.div`
  padding: 0.75em 2em;
  word-break: break-all;
  align-items: center;
  display: flex;
  font-size: 16px;
  font-family: Lato, sans-serif;

  :hover {
    cursor: pointer;
    color: #fff;
    background-color: #00b0b9;
  }
`;
type OtherStuff = IMessageProps & ILabelProps; // & React.HTMLProps<HTMLInputElement>;

export interface IUltimateDropdownProps<T extends IDropdownItem>
  extends OtherStuff {
  /**
   * items to display
   */
  items?: ReadonlyArray<T>;

  groups?: ReadonlyArray<IItemGrouped<T>>;
  loadingInstances?: boolean;

  /**
   * selected items
   */
  selectedItems?: ReadonlyArray<T>;
  /**
   * Prop for TagsDropdown
   */
  showAddTagOption?: boolean;
  /**
   * When selecting the create new tag option
   */
  onClickNewTag?: () => void;

  /**
   * SearchText on tags dropdown
   */
  searchValue?: string;

  /**
   * async items to display
   * @returns {Promise<ReadonlyArray<T>>}
   */
  loadItems?: () => Promise<ReadonlyArray<T>>;

  /**
   * mandatory item render func.
   * example: return MenuItem
   */
  itemRenderFunc: IItemRenderFunc<T>;

  /**
   * optionally override entire list renderer
   * (default impl. renders a Menu item, and children at itemRenderFuncs)
   */
  itemListRenderFunc?: IListItemRenderFunc<T>;

  itemGroupedHeaderRenderFunc?: IGroupedItemHeaderRenderFunc;

  /**
   * optional render func for target
   * default renders something that looks like a dropdown...
   * with leftIcon and arrow as rightIcon. use leftIcon-prop to customize left icon
   */
  targetRenderFunc?: ITargetRenderFunc<T>;

  /**
   * optional select mode to set multi-select
   * default = SINGLE
   */
  selectMode?: SelectMode;

  tagsMode?: boolean;

  canDeselect?: boolean;

  onSelectedItemsChanged?: (selectedItems: ReadonlyArray<T>) => void;

  onSelected?: (selectedItem: T) => void;

  onUnselected?: (unselectedItem: T) => void;

  onBlur?: (e: any) => void;

  // PROPS when default target renderer is used (ignored when custom target):

  style?: CSSProperties;

  fieldGroupStyle?: CSSProperties;

  leftIcon?: IconType;

  isOpen?: boolean;

  /**
   * form name?
   */
  name?: string;

  /**
   * default text to render in dropdown when nothing is selected
   */
  defaultText?: string;

  disabled?: boolean;

  emptyText?: string;

  useFilterBox?: boolean;

  visibleRows?: number;

  rowHeight?: number;
}

interface IUltimateDropdownState<T extends IDropdownItem> {
  isOpen: boolean;

  selectedItems: ReadonlyArray<T>;

  id: string;

  activeItem: T | undefined;

  menuItems: Array<any>;
  currentMenuItems: Array<any>;

  currentFilter: string;

  currentListLength: number;
}

interface IMainListProps {
  height: number;
}

const MainList = styled.div`
  height: ${(props: IMainListProps) => props.height}px;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
`;

const TargetContainerDef = styled.div`
  //width: 15em;
`;

export interface IUltimateEnumItem extends IDropdownItem {
  value: string;
}

function getTranslatedEnumValues(
  intl: InjectedIntl,
  enumObj: {},
  messages: {}
) {
  const temp: { [id: string]: string } = {};
  getNamesAndValues(enumObj).forEach(x => {
    temp[x.value] = intl.formatMessage(messages[x.name]);
  });
  return temp;
}

// const myEnumItemRenderFunc: IItemRenderFunc<IUltimateEnumItem> = (item => (<MenuItem text={item.value} />));

type SubtractedProps = Omit<
  IUltimateDropdownProps<IUltimateEnumItem>,
  'items' | 'itemRenderFunc'
>;

type IEnumDropdownProps = SubtractedProps &
  InjectedIntlProps & {
    /**
     * OPTIONAL itemRenderFunc!
     */
    itemRenderFunc?: IItemRenderFunc<IUltimateEnumItem>;

    // items: Array<IUltimateEnumItem>;
  };

// default enum dropdown item render func
const myEnumItemRenderFunc = (
  item: IUltimateEnumItem,
  itemProps: IItemRenderProps
) => {
  return (
    <MenuItem
      key={'enum_' + item.value}
      text={item.value}
      onClick={itemProps.handleClick}
      leftIcon={itemProps.isSelected ? MunikumIcons.LeftCheck : undefined}
    />
  );
};

/**
 * The ULTIMATE dropdown component :-P
 */
export class UltimateDropdown<T extends IDropdownItem> extends React.Component<
  IUltimateDropdownProps<T>,
  IUltimateDropdownState<T>
> {
  // use this to tell if clicks are coming from inside the target wrapper div
  private readonly wrapperRef: RefObject<HTMLDivElement>;
  private runningId: number;
  private virtListRef: List;
  private rowHeight: number;
  // use this to whitelist content inside popover
  private readonly whiteRef: RefObject<HTMLDivElement>;

  public static ofType<T extends IDropdownItem>() {
    return UltimateDropdown as new (
      props: IUltimateDropdownProps<T>
    ) => UltimateDropdown<T>;
  }

  /**
   * TypeScript PORN
   * create a ULtimate dropdown from enum factory func =)
   *
   *
   * TODO: i morgen:
   * - less padding. maybe imoplement styledguide?
   *
   *
   * @param enumObj your enum type
   * @param messages translated i18n enum messages
   */
  public static ofEnumType = <P extends IEnumDropdownProps>(
    enumObj: {},
    messages: ReactIntl.Messages
  ) => {
    const WrapperEnumComp: React.SFC<IEnumDropdownProps> = props => {
      const TypedEnumDropdown = UltimateDropdown.ofType<IUltimateEnumItem>(); // ok?
      const monkeys2 = getTranslatedEnumValues(props.intl, enumObj, messages);

      const bugs: Array<IUltimateEnumItem> = Object.keys(monkeys2).map(
        (key, index) => {
          return {
            id: key,
            value: props.intl.formatMessage(messages[key]),
          };
        }
      );

      // console.log('bugs', bugs, monkeys2);

      return (
        <TypedEnumDropdown
          itemRenderFunc={props.itemRenderFunc || myEnumItemRenderFunc}
          defaultText={
            props.defaultText ||
            props.intl.formatMessage(ultimateMessages.defaultDropdownText)
          }
          items={bugs}
          {...props}
        />
      );
    };
    return injectIntl(WrapperEnumComp);
  };

  constructor(props: IUltimateDropdownProps<T>) {
    super(props);

    this.state = {
      isOpen: this.props.isOpen,
      id: _.uniqueId('dropUltimate-'),
      selectedItems: this.props.selectedItems || [],
      activeItem: undefined,
      menuItems: [],
      currentMenuItems: [],
      currentFilter: '',
      currentListLength: 0,
    };

    this.runningId = 0;
    this.virtListRef = null;
    this.rowHeight = this.props.rowHeight || 36;

    // console.log('CTOR', this.state.selectedItems);
    this.wrapperRef = React.createRef();
    this.whiteRef = React.createRef();

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  handleKeyUp(e: any) {
    if (this.state.isOpen) {
      if (e.keyCode === MunikumKeys.ESCAPE) {
        e.stopPropagation();
        this.setState({
          isOpen: false,
        });
      }
    }
  }

  handleClickOutside(e: any) {
    if (this.wrapperRef.current && this.whiteRef.current) {
      const isInsideTargetWrapper = this.wrapperRef.current.contains(e.target);
      const isInsideWhiteWrapper = this.whiteRef.current.contains(e.target);

      if (
        this.state.isOpen &&
        !isInsideTargetWrapper &&
        !isInsideWhiteWrapper
      ) {
        setTimeout(() => {
          this.setState({
            isOpen: false,
          });
        }, 200);
      }
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('keyup', this.handleKeyUp);

    this.makeItemList();
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('keyup', this.handleKeyUp);
  }

  UNSAFE_componentWillReceiveProps(nextProps: IUltimateDropdownProps<T>) {
    this.setState(
      {
        menuItems: [],
      },
      () => {
        this.makeItemList();
      }
    );
    if (nextProps.selectedItems !== undefined) {
      if (
        this.state.selectedItems.length === 0 &&
        nextProps.selectedItems.length === 0
      ) {
        return;
      }
      // TODO: optimize more later (gets called a lot.. we should probably implement shouldCompUpdate
      // console.log('setSelectedItems: ', nextProps.selectedItems);
      if (!_.isEqual(nextProps.selectedItems, this.state.selectedItems)) {
        // console.log('received updated props, set state');
        this.setState({
          selectedItems: nextProps.selectedItems,
        });
      } else {
        // console.log('skip setState in willreceive');
      }
    }
  }

  renderDefaultTarget = (ref: any) => {
    let tempText = this.props.defaultText;

    // tempText = this.props.selectedItems && this.props.selectedItems.map(item => item.hasOwnProperty('title') ? (item as any).title).join(',') : ;

    const getItemText = (item: IDropdownItem) => {
      // enum dropdowns defaults to value
      // console.log('heck');
      if (item.hasOwnProperty('value')) {
        return (item as any).value;
      }

      // meta stuff has title
      if (item.hasOwnProperty('title')) {
        return (item as any).title;
      }

      return item.id;
    };

    if (
      this.state.selectedItems !== undefined &&
      this.state.selectedItems.length > 0
    ) {
      tempText = this.state.selectedItems
        .map(item => getItemText(item))
        .join(', ');
    }

    return (
      <FieldGroup style={this.props.fieldGroupStyle}>
        {this.props.label && (
          <FormLabel htmlFor={this.state.id}>{this.props.label}</FormLabel>
        )}
        <TargetContainerDef>
          <TextField
            innerRef={ref}
            name={this.props.name || 'nan'}
            value={tempText}
            readOnly={true}
            disabled={this.props.disabled}
            onChange={() => {}}
            onBlur={this.props.onBlur}
            inputStyle={{
              cursor: this.props.disabled ? 'not-allowed' : 'pointer',
              textShadow: '0 0 0 ' + Colors.BLACK,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            style={{
              width: '100%',
            }}
            leftIcon={this.props.leftIcon}
            rightIcon={MunikumIcons.Dropdown}
            fieldGroupStyle={{
              padding: 0,
              cursor: this.props.disabled ? 'not-allowed' : 'pointer',
            }}
          />
        </TargetContainerDef>
        <ValidationSummary
          info={this.props.info}
          success={this.props.success}
          warning={this.props.warning}
          error={this.props.error}
        />
      </FieldGroup>
    );
  };

  handleClickItem = (item: T) => {
    // console.log('ultimate disabled check');
    if (this.props.disabled === true) {
      return;
    }

    const isSelectedIndex = _.findIndex(
      this.state.selectedItems,
      (c: IDropdownItem) => c.id === item.id
    );
    let copy = this.state.selectedItems.slice();

    if (this.props.selectMode === SelectMode.MULTIPLE) {
      if (isSelectedIndex > -1) {
        copy.splice(isSelectedIndex, 1);
      } else {
        // extra stuff to keep in same order..:
        let cat: Array<any> = [];

        if (this.props.items) {
          if (!this.props.tagsMode) {
            this.props.items.forEach(kitten => {
              if (
                item.id === kitten.id ||
                _.findIndex(this.state.selectedItems, c => c.id === kitten.id) >
                  -1
              ) {
                cat.push(kitten);
              }
            });
          } else {
            cat = this.state.selectedItems.slice();
            cat.push(item);
          }

          copy = cat;
        } else {
        }
      }
    } else {
      if (isSelectedIndex > -1) {
        let canReallyDeselect = true; // default TRUE (cannot use defaultProps on generics..)
        if (this.props.canDeselect !== undefined) {
          canReallyDeselect = this.props.canDeselect;
        }

        if (canReallyDeselect) {
          copy = [];
        } else {
          copy = [item]; // cannot deselect item. .
        }
      } else {
        copy = [item];
      }
    }

    // TODO: i morgen: feilsøk kvifor vi kan klikke veldig mange ganger... kanskje vi må disable click når vi loader?

    // console.log('handleClickItem ', this.state.selectedItems, copy);

    this.setState(
      {
        selectedItems: copy,
      },
      () => {
        // console.log('state is updated');
        safeInvoke(this.props.onSelectedItemsChanged, this.state.selectedItems);
        if (isSelectedIndex > -1) {
          safeInvoke(this.props.onUnselected, item);
        } else {
          safeInvoke(this.props.onSelected, item);
        }

        let cloneMItems = Object.assign([], this.state.menuItems);

        for (let i = 0; i < this.state.menuItems.length; i++) {
          let parts = this.state?.menuItems[i]?.key?.split('_') ?? [];
          if (parts.length > 2) {
            if (item.id === parts[3]) {
              cloneMItems[i] = this.makeMRow(item, Number(parts[3]));
              break;
            }
          }
        }

        this.setState(
          {
            menuItems: cloneMItems,
          },
          () => {
            this.updateMenuItems();
          }
        );
      }
    );

    if (
      this.props.selectMode === SelectMode.SINGLE ||
      this.props.selectMode === undefined
    ) {
      this.setState({
        isOpen: false,
      });
    }
  };

  makeItemList = () => {
    const { items, groups } = this.props;

    if (this.state.menuItems.length === 0) {
      // menu items:
      let menuItems: Array<any> = [];

      if (
        items !== undefined &&
        (groups === undefined || groups.length === 0)
      ) {
        const mItems = this.createActiveMenuItems(items);
        menuItems.push(...mItems);
      }

      if (
        groups !== undefined &&
        groups.length > 0 &&
        this.props.itemGroupedHeaderRenderFunc
      ) {
        for (let i = 0; i < groups.length; i++) {
          const headerItem = this.props.itemGroupedHeaderRenderFunc(
            groups[i].header,
            '_header_' + (this.runningId++).toString()
          );
          menuItems.push(headerItem);

          const groupItems = this.createActiveMenuItems(groups[i].items);
          menuItems.push(...groupItems);
        }
      }

      this.setState({
        menuItems: menuItems,
        currentMenuItems: menuItems,
        currentListLength: menuItems.length,
      });
    }
  };

  makeMRow = (item: T, listIdx: number) => {
    const mItem = this.props.itemRenderFunc(item, {
      handleClick: () => this.handleClickItem(item),
      key: '_listitem_' + listIdx + '_' + item.id,
      index: listIdx,
      isDisabled: false,
      isActive:
        this.state.activeItem !== undefined &&
        this.state.activeItem.id === item.id,
      isSelected:
        _.findIndex(this.state.selectedItems, c => c.id === item.id) > -1,
      selectMode: this.props.selectMode || SelectMode.SINGLE, // NOTE: defaultProps on generics is not supported yet in TypeScript, but coming soon :-) https://github.com/Microsoft/TypeScript/issues/23812
    });

    return mItem;
  };

  createActiveMenuItems = (items: ReadonlyArray<T>) => {
    const menuItems: Array<any> = [];

    items.forEach((item: T) => {
      menuItems.push(this.makeMRow(item, this.runningId++));
    });

    return menuItems;
  };

  updateMenuItems = () => {
    if (this.state.currentFilter.length > 0) {
      // eslint-disable-next-line
      const newList = this.state.menuItems.filter(item => {
        const type =
          item.key.substr(0, 10) === '_listitem_' ? 'item' : 'header';

        if (
          type === 'header' ||
          (type === 'item' &&
            item.props !== undefined &&
            item.props.dataFilterString
              .toLowerCase()
              .includes(this.state.currentFilter.toLowerCase()))
        ) {
          return item;
        }
      });

      this.setState({
        currentMenuItems: newList,
        currentListLength: newList.length,
      });
    } else {
      this.setState({
        currentMenuItems: this.state.menuItems,
        currentListLength: this.state.menuItems.length,
      });
    }
  };

  virtualRowRenderer = (props: ListRowProps): React.ReactNode => {
    const item = this.state.currentMenuItems[props.index];
    return (
      <div
        style={props.style}
        key={props.key}
        onMouseEnter={() =>
          this.setState({
            activeItem: item,
          })
        }
        onMouseLeave={() =>
          this.setState({
            activeItem: undefined,
          })
        }
      >
        {item}
      </div>
    );
  };

  render() {
    const { selectedItems, isOpen } = this.state;
    const myStyle = Object.assign(
      { padding: '.5em', width: '25em', boxSizing: 'border-box' },
      this.props.style
    );

    return (
      <Popover
        isAggressiveUpdateModeEnabled={true}
        isDefaultOpen={false}
        isOpen={isOpen}
        style={myStyle}
        renderTarget={(ref: any) => {
          let targetToRender: JSX.Element | null | false;
          if (
            this.props.targetRenderFunc !== undefined &&
            isFunction(this.props.targetRenderFunc)
          ) {
            targetToRender = this.props.targetRenderFunc(ref, {
              selectedItems: selectedItems,
              isOpen: isOpen,
            });
          } else {
            targetToRender = this.renderDefaultTarget(ref);
          }

          return (
            <div
              ref={this.wrapperRef}
              onClick={e => {
                if (!this.props.disabled) {
                  this.setState({
                    isOpen: !this.state.isOpen,
                  });
                }
              }}
            >
              {targetToRender}
            </div>
          );
        }}
        position={'bottom'}
        showDebugInfo={false}
      >
        <div
          ref={this.whiteRef}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          {this.props.useFilterBox !== undefined && this.props.useFilterBox && (
            <UltimateFilterBox
              filterFunc={(phrase: string) => {
                this.setState(
                  {
                    currentFilter: phrase,
                  },
                  () => {
                    this.updateMenuItems();
                  }
                );
              }}
            />
          )}
          {/*<Menu>*/}
          {this.state.currentListLength > 0 && (
            <MainList
              height={
                this.state.currentListLength >=
                (this.props.visibleRows ? this.props.visibleRows : 10)
                  ? (this.props.visibleRows ? this.props.visibleRows : 10) *
                    this.rowHeight
                  : this.state.currentListLength * this.rowHeight
              }
            >
              <AutoSizer>
                {props => {
                  return (
                    <List
                      ref={ref => (this.virtListRef = ref)}
                      style={{
                        outline: 'none',
                      }}
                      width={props.width}
                      height={props.height}
                      rowCount={this.state.currentListLength}
                      rowHeight={this.rowHeight}
                      rowRenderer={props2 => this.virtualRowRenderer(props2)}
                    />
                  );
                }}
              </AutoSizer>
            </MainList>
          )}
          {this.state.currentListLength === 0 && (
            <EmptyDiv>
              <EmptyText> {this.props.emptyText || 'No items'} </EmptyText>
            </EmptyDiv>
          )}
          {/*</Menu>*/}
        </div>
        {this.props.showAddTagOption && this.props.searchValue.length > 0 && (
          <AddTagDiv
            onClick={() => {
              safeInvoke(this.props.onClickNewTag);
            }}
          >
            {this.props.searchValue} (Ny merkelapp)
          </AddTagDiv>
        )}
      </Popover>
    );
  }
}
