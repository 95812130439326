import { Dictionary } from 'lodash';
import { isType } from 'typescript-fsa';
import { Action } from 'redux';
import { createSelector } from 'reselect';
import {
  IActionValueCreateOrUpdateForm,
  IActionValueParameters,
} from '../../services/actionValueService';
import {
  ActionValueCategory,
  IActionValueParameterValue,
} from '../../services/models/actionValue';
import { signoutSuccess } from '../auth/auth';
import { IApiError } from '../../services/api';
import { IOrganization } from '../../services/models/organization';
import { actionCreatorFactory } from '../actionCreatorFactory';
import { ContentStatus, OrderEnum, SortEnum } from '../../models/types';
import { RootState } from '../rootReducer';

const actionCreator = actionCreatorFactory('ACTION_VALUE');

/**
 * ActionValue redux module (actions, reducer, sagas, selectors)
 * list: state for showing list of actionvalues for user (not admin view?)
 *        filter, pagination, etc.
 * edit: edit could be removed...
 *        the plan was to show a list of actionvalues the user is editing..
 *
 *
 *
 */

export const getActionValueParametersActionCreator = actionCreator.async<
  {},
  {
    groups: IActionValueParameters;
    defaultValues: ReadonlyArray<IActionValueParameterValue>;
  },
  IApiError
>('ACTION_VALUE_PARAMETERS');

// action to start editing actionValue. get data from api, put everything we need in state, and redirect to editpage
export const editActionValueActionCreator = actionCreator.async<
  { actionValueContentId: string },
  {
    form: IActionValueCreateOrUpdateForm;
    actionValueMetaId: string;
    actionValueContentId: string;
    source?: IOrganization;
  },
  IApiError
>('UI_EDIT');

// close edit page
export const cancelEditActionValueActionCreator = actionCreator<{}>(
  'UI_CANCEL_EDIT'
);

// update actions
export const updateActionValueActionCreator = actionCreator.async<
  IActionValueCreateOrUpdateForm,
  {},
  IApiError
>('UPDATE');

// we also need one for create, since create is different than update:
export const saveActionValueActionCreator = actionCreator.async<
  IActionValueCreateOrUpdateForm,
  {},
  IApiError
>('SAVE');

// special action to load data when showing list:
// export const loadActionValueList = actionCreator<{}>('LOAD');

export const clientNavigateToPageActionCreator = actionCreator<{
  page: number;
}>('CLIENT_CHANGE_PAGE');

export const clientFilterActionValuesActionCreator = actionCreator<
  IActionValueFilter
>('CLIENT_CHANGE_FILTER');

export const clientResetActionValuesFilterActionCreator = actionCreator<{}>(
  'CLIENT_RESET_FILTER'
);

export const adminNavigateToPageActionCreator = actionCreator<{ page: number }>(
  'ADMIN_CHANGE_PAGE'
);

export const adminFilterActionValuesActionCreator = actionCreator<
  IActionValueFilter
>('ADMIN_CHANGE_FILTER');

export const adminResetActionValuesFilterActionCreator = actionCreator<{}>(
  'ADMIN_RESET_FILTER'
);

export const expanderToggleActionValueList = actionCreator<{}>(
  'EXPANDER_TOGGLE'
);
export const changeShowOnlyMyFollowedActionCreator = actionCreator<{
  showOnlyMyFollowed: boolean;
}>('CHANGE_SHOW_ONLY_MY_FOLLOWED_ACTION_CREATOR');

export const loadUsedOrganizationsActionCreator = actionCreator.async<
  {},
  {},
  IApiError
>('LOAD_USED_ORGS');

export interface IActionValueFilter {
  /**
   * true if you want to show grants with deadlines where all are expired
   */
  showExpired: boolean;

  /***
   * if true it onlys show actionvalues you follow in the actionvaluelist
   */
  showOnlyMyFollowed: boolean;

  /**
   * mandatory, empty string ofr nothing ;)
   */
  search: string;

  /**
   * A list of contentstatuses you want to filter on
   */
  status: Array<ContentStatus>;

  /**
   * operational or investment
   */
  category: Array<ActionValueCategory>;

  /**
   * array of ids to organizations you want to filter on (source)
   */
  source: Array<string>;

  sortBy?: 'title' | 'lastUpdated.desc' | 'deadline';

  // sortByFields: Array<string>;

  // sortDirection: 'asc' | 'desc';

  sector: Array<string>;

  kostraFunctions: Array<string>;
  kostraGroup: number | null;
  kostraGroupKey: string | undefined;
  sortKey: string | undefined;
  sort: SortEnum;
  order: OrderEnum;
}

const defaultClientFilter: IActionValueFilter = {
  showExpired: true,
  sort: SortEnum.DEADLINE,
  order: OrderEnum.DESCENDING,
  showOnlyMyFollowed: false,
  search: '',
  status: [ContentStatus.PUBLISHED],
  category: [],
  sortKey: '2',
  source: [],
  sortBy: 'title',
  sector: [],
  kostraFunctions: [],
  kostraGroup: null,
  kostraGroupKey: undefined,
};

const defaultAdminFilter: IActionValueFilter = {
  showExpired: true,
  kostraGroupKey: undefined,
  search: '',
  showOnlyMyFollowed: false,
  status: [],
  category: [],
  sort: SortEnum.DEADLINE,
  order: OrderEnum.DESCENDING,
  source: [],
  sortKey: '2',
  sortBy: 'title',
  sector: [],
  kostraGroup: null,
  kostraFunctions: [],
};

export interface IActionValueState {
  edit: {
    isPreparingToEdit: boolean;
    error?: string;
    forms: {
      [byActionValueContentId: string]: Readonly<
        IActionValueCreateOrUpdateForm
      >;
    };
  };
  update: {
    isUpdating: boolean;
    error?: string;
    isSavingDraft: boolean;
    isSavingPublished: boolean;
  };
  create: {
    isSaving: boolean;
    error?: string;
  };
  parameters?: {
    /**
     * Valid parameters from DB grouped by effort and gain.
     */
    groups: IActionValueParameters;

    /**
     * Default parametervalues when creating new actionvalues
     */
    defaultValues: ReadonlyArray<IActionValueParameterValue>;
  };
  parametersError?: string;

  clientList: {
    showOnlyMyFollowed: false;
    filter: IActionValueFilter;
    paging: {
      page: number;
    };
    expanderIsOpen: boolean;
  };

  adminList: {
    filter: IActionValueFilter;
  };

  usedSources: {
    isLoading: boolean;
    error?: string;
    organizations: { [byId: string]: IOrganization };
  };
}

const initialState: IActionValueState = {
  edit: {
    isPreparingToEdit: false,
    forms: {},
  },
  update: {
    isUpdating: false,
    isSavingDraft: false,
    isSavingPublished: false,
  },
  create: {
    isSaving: false,
  },
  clientList: {
    showOnlyMyFollowed: false,
    filter: defaultClientFilter,
    paging: {
      page: 0,
    },
    expanderIsOpen: true,
  },
  adminList: {
    filter: defaultAdminFilter,
  },
  usedSources: {
    isLoading: false,
    organizations: {},
  },
};

export const reducer = (
  state: IActionValueState = initialState,
  action: Action
): IActionValueState => {
  if (isType(action, signoutSuccess)) {
    return initialState;
  }

  if (isType(action, loadUsedOrganizationsActionCreator.started)) {
    return {
      ...state,
      usedSources: {
        ...state.usedSources,
        isLoading: true,
        error: undefined,
      },
    };
  }

  if (isType(action, loadUsedOrganizationsActionCreator.success)) {
    return {
      ...state,
      usedSources: {
        isLoading: false,
        error: undefined,
        organizations: action.payload.result,
      },
    };
  }

  if (isType(action, loadUsedOrganizationsActionCreator.failed)) {
    return {
      ...state,
      usedSources: {
        isLoading: false,
        error: action.payload.error.message,
        organizations: {},
      },
    };
  }

  if (isType(action, expanderToggleActionValueList)) {
    return {
      ...state,
      clientList: {
        ...state.clientList,
        expanderIsOpen: !state.clientList.expanderIsOpen,
      },
    };
  }

  if (isType(action, clientFilterActionValuesActionCreator)) {
    return {
      ...state,
      clientList: {
        ...state.clientList,
        filter: action.payload,
        paging: {
          ...state.clientList.paging,
          page: 0, // reset paging when changing filter
        },
      },
    };
  }
  if (isType(action, changeShowOnlyMyFollowedActionCreator)) {
    return {
      ...state,
      clientList: {
        ...state.clientList,
        filter: {
          ...state.clientList.filter,
          showOnlyMyFollowed: action.payload.showOnlyMyFollowed,
        },
      },
    };
  }
  if (isType(action, clientResetActionValuesFilterActionCreator)) {
    return {
      ...state,
      clientList: {
        ...state.clientList,
        filter: defaultClientFilter,
        paging: {
          ...state.clientList.paging,
          page: 0,
        },
      },
    };
  }

  if (isType(action, adminFilterActionValuesActionCreator)) {
    return {
      ...state,
      adminList: {
        ...state.adminList,
        filter: action.payload,
      },
    };
  }

  if (isType(action, adminResetActionValuesFilterActionCreator)) {
    return {
      ...state,
      adminList: {
        ...state.adminList,
        filter: defaultAdminFilter,
      },
    };
  }

  if (isType(action, clientNavigateToPageActionCreator)) {
    return {
      ...state,
      clientList: {
        ...state.clientList,
        paging: {
          page: action.payload.page,
        },
      },
    };
  }

  if (isType(action, saveActionValueActionCreator.started)) {
    return {
      ...state,
      create: {
        error: undefined,
        isSaving: true,
      },
    };
  }
  if (isType(action, saveActionValueActionCreator.success)) {
    return {
      ...state,
      create: {
        isSaving: false,
        error: undefined,
      },
    };
  }
  if (isType(action, saveActionValueActionCreator.failed)) {
    return {
      ...state,
      create: {
        isSaving: false,
        error: action.payload.error.message,
      },
    };
  }

  if (isType(action, getActionValueParametersActionCreator.started)) {
    return {
      ...state,
      parametersError: undefined,
    };
  }
  if (isType(action, getActionValueParametersActionCreator.success)) {
    console.log('success', action.payload.result);
    return {
      ...state,
      parameters: {
        groups: action.payload.result.groups,
        defaultValues: action.payload.result.defaultValues,
      },
      parametersError: undefined,
    };
  }
  if (isType(action, getActionValueParametersActionCreator.failed)) {
    return {
      ...state,
      parametersError: action.payload.error.message,
    };
  }

  if (isType(action, updateActionValueActionCreator.started)) {
    return {
      ...state,
      update: {
        isUpdating: true,
        error: undefined,
        isSavingPublished: action.payload.status === ContentStatus.PUBLISHED,
        isSavingDraft: action.payload.status === ContentStatus.DRAFT,
      },
    };
  }
  if (isType(action, updateActionValueActionCreator.success)) {
    return {
      ...state,
      update: {
        isUpdating: false,
        error: undefined,
        isSavingPublished: false,
        isSavingDraft: false,
      },
    };
  }
  if (isType(action, updateActionValueActionCreator.failed)) {
    return {
      ...state,
      update: {
        isUpdating: false,
        error: action.payload.error.message,
        isSavingPublished: false,
        isSavingDraft: false,
      },
    };
  }

  if (isType(action, editActionValueActionCreator.started)) {
    return {
      ...state,
      edit: {
        ...state.edit,
        isPreparingToEdit: true,
        error: undefined,
        forms: {}, // remove if we want to edit many items..
      },
    };
  }
  if (isType(action, editActionValueActionCreator.success)) {
    // for now we'll only support editing one actionvalue at a time
    const myDict: Dictionary<IActionValueCreateOrUpdateForm> = {};
    myDict[action.payload.result.actionValueContentId] =
      action.payload.result.form;

    return {
      ...state,
      edit: {
        ...state.edit,
        isPreparingToEdit: false,
        error: undefined,
        forms: myDict,
      },
    };
  }
  if (isType(action, editActionValueActionCreator.failed)) {
    return {
      ...state,
      edit: {
        ...state.edit,
        isPreparingToEdit: false,
        error: action.payload.error.message,
        forms: {},
      },
    };
  }
  if (isType(action, cancelEditActionValueActionCreator)) {
    return {
      ...state,
      edit: {
        ...state.edit,
        isPreparingToEdit: false,
        error: undefined,
        forms: {},
      },
    };
  }
  return state;
};

export const organizationUsedSourceSelector = createSelector<
  RootState,
  { [byId: string]: Readonly<IOrganization> },
  { [byId: string]: Readonly<IOrganization> }
>(
  state => state.actionValue.usedSources.organizations,
  res => res
);
