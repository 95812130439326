import * as React from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import queryString from 'query-string';
import { Dispatch } from 'redux';
import { signin } from '../../modules/auth/auth';
import { LoginType } from '../../modules/auth/IAuthResult';
import { connect } from 'react-redux';
import { safeInvoke } from '../../components/common';
import { RootState } from '../../modules/rootReducer';
import { isTeamsDesktopApp } from '../../index';

export interface IMicrosoftLoginProps {
  isAuthenticated: boolean;
  login?: (values: { token: string }) => void;
}

class LoginMicrosoftPageComp extends React.PureComponent<
  // tslint:disable-next-line
  IMicrosoftLoginProps & RouteComponentProps<any>
> {
  render() {
    const { isAuthenticated } = this.props;

    if (isAuthenticated) {
      return <Redirect to={'/'} />;
    }

    if (!this.props.location.hash) {
      window.location.href =
        process.env.REACT_APP_API +
        '/auth/login-with-microsoft' +
        (isTeamsDesktopApp ? '?isTeams=true' : '');
      return <div>Redirecting to Microsoft</div>;
    }

    const params = queryString.parse(
      this.props.location.hash.replace('/access_token', 'access_token')
    );

    safeInvoke(this.props.login, { token: params.access_token });

    return <div>Logging in with Microsoft...</div>;
  }
}

const mapStateToProps = (state: RootState): IMicrosoftLoginProps => ({
  isAuthenticated: state.auth && state.auth.isAuthenticated,
});

const mapDispatchToPropsSimple = (
  dispatch: Dispatch<{}>
): Pick<IMicrosoftLoginProps, 'login'> => {
  return {
    login: (values: { token: string }) => {
      if (values && values.token) {
        dispatch(
          signin({
            token: values.token,
            loginType: LoginType.Microsoft,
          })
        );
      }
    },
  };
};

const LoginMicrosoftPage = connect<IMicrosoftLoginProps, {}, {}>(
  mapStateToProps,
  mapDispatchToPropsSimple
)(LoginMicrosoftPageComp);

export default withRouter(LoginMicrosoftPage);
