import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import {
  defineMessages,
  FormattedMessage,
  InjectedIntlProps,
  injectIntl,
} from 'react-intl';
import { InjectedAuthRouterProps } from 'redux-auth-wrapper/history4/redirect';
import styled from 'styled-components';
import Query from 'react-apollo/Query';
import {
  ActionValueMe,
  ContentStatus,
  Discussion,
  DiscussionComments,
  DiscussionQuery,
  DiscussionTopics,
  DiscussionVariables,
  OrderEnum,
  SortEnum,
} from '../../../models/types';
import { myApolloClient } from '../../../graphql/apolloClientFactory';
import { myHistory, myStore, socket } from '../../../index';
import { QuestionView } from '../../../components/discussion/QuestionView';
import { IFile, uploadFile } from '../../../services/fileservice';
import { ButtonSize, ButtonTheme, NavButton } from '../../../components/basic';
import { MunikumIcons } from '../../../components/common/icons';
import { LinkWrapper } from '../../../components/basic/Structural/links';
import {
  FlexGrid,
  FlexGridItem,
  FlexGridItemTop,
  FlexGridRow,
} from '../../../components/layout/FlexGrid/FlexGrid';
import {
  ClapDiv,
  Page,
  PageLastUpdated,
  PageTitle,
  PageTopPure,
  PageTopSplitContainer,
  RefreshMessage,
} from '../../../components/layout/Page/Page';
import { TextVerySmall } from '../../../components/basic/Structural/typography';
import {
  ButtType,
  FollowButton2,
  MyType,
} from '../../../components/basic/FollowButton/FollowButton2';
import Helmet from 'react-helmet';
import { IFileItem } from '../../../components/basic/UserFile/FileUploaderPicker';
import { ICommentEditForm } from '../../../services/commentService';
import { ShareButton } from '../../../components/meta/ShareButton';
import { CommentView2 } from '../../../components/comment/CommentView2';
import { IDiscussionCreateOrUpdateForm } from '../../../services/discussionService';
import {
  StatusMessage,
  ThemeColor,
} from '../../../components/basic/StatusMessage/StatusMessage';
import { commonMessages } from '../../../components/language/commonMessages';
import { SuspenseLoader } from '../../../components/basic/Loader/CircleSpinner';
import { errorMessages } from '../../../components/language/errorMessages';
import { Tag } from '../../../components/basic/Tag/Tag';
import {
  Colors,
  formatMunikumDate,
  isReallyEmpty,
  safeInvokeDeprecated,
} from '../../../components/common';
import { AnswerDiscussionView } from '../../../components/discussion/AnswerDiscussionView';
import { sizes } from '../../../components/common/media';
import gql from 'graphql-tag';
import { MunikumScrollContext } from '../../../components/ScrollToTop/MunikumScrollContext';
import { Clap, ObjectType } from '../../../components/basic/Clap/Clap';
import { MuContentEditable } from '../../../components/basic/MuEditor/MuContentEditable';
import { ALL_DISCUSSIONS } from '../DiscussionListPage/DiscussionListPage';
import { convertFromRaw } from 'draft-js';
import { RootState } from '../../../modules/rootReducer';
import { connect } from 'react-redux';
import { metaUpdatedClearActionCreator } from '../../../modules/notification/notification';

const messages = defineMessages({
  questions: {
    id: 'DiscussionItemPage.questions',
    defaultMessage: 'Question',
  },
  answers: {
    id: 'DiscussionItemPage.answers',
    defaultMessage: 'Answers',
  },
  noAnswers: {
    id: 'DiscussionItemPage.noAnswers',
    defaultMessage: 'No answers',
  },
  replyTitle: {
    id: 'DiscussionItemPage.replyTitle',
    defaultMessage: 'Reply to question',
  },
  topicText: {
    id: 'DiscussionItemPage.topicText',
    defaultMessage: 'The discussion is linked to the following topics:',
  },
  TopicConnectedToTitle: {
    id: 'DiscussionItemPage.TopicConnectedToTitle',
    defaultMessage: 'The discussion is linked to the following topics:',
  },
  DeleteFileWarning: {
    id: 'DiscussionItemPage.DeleteFileWarning',
    defaultMessage: 'Are you sure you want to delete the file?',
  },
});

export interface IDiscussionItemProps {
  username?: string;
  currentItem?: Discussion;
  me?: ActionValueMe;
  newCommentIsSaving: boolean;
  newCommentSuccess: boolean;
  isDataUpdating: boolean;
  isSharing: boolean;

  // onSaveComment?: (values: ICommentSaveForm, files: IFileItem[]) => void;
  onUpdateComment?: (values: ICommentEditForm) => void;
  onFollowDiscussion?: (id: string, follow: boolean) => void;
  onTopicClick?: (uri: string) => void;

  // onShouldScrollToPosition?: (pos: { x: number; y: number }) => void;

  onUpdateQuestion?: (
    form: IDiscussionCreateOrUpdateForm,
    files: IFileItem[]
  ) => void;
}

interface IDiscussionItemStateProps {
  updatedMetas: Array<string>;
}

const PageTopRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  //background-color: paleturquoise;

  padding-top: 0.5em;
  margin-left: 0.875em;

  @media (min-width: ${sizes.medium}px) {
    justify-content: flex-start;
    //background-color: rebeccapurple;
    align-self: flex-end;

    padding-top: 0;
  }
`;

const TopicConnectedToTitle = styled.div`
  color: ${props => props.theme.textColor};
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: bold;
  //margin: 12px 6px 12px 31px;
`;

export const UPDATE_DISCUSSION = gql`
  mutation UpdateDiscussion($input: UpdateDiscussionInput!) {
    updateDiscussion(input: $input) {
      id
      status
    }
  }
`;
export const CREATE_COMMENT = gql`
  mutation CreateComment($input: CreateCommentInput!) {
    createComment(input: $input) {
      id
    }
  }
`;
export const UPDATE_COMMENT = gql`
  mutation UpdateComment($input: UpdateCommentInput!) {
    updateComment(input: $input) {
      id
    }
  }
`;
export const SET_STATUS = gql`
  mutation SetStatus($input: SetStatusInput!) {
    setStatus(input: $input)
  }
`;

export const fieldsOnUserFile = gql`
  fragment fieldsOnUserFile on UserFile {
    id
    name
    description
    lastUpdated
    size
    likes
    isLiked
    contentType
    access {
      myAccess
    }
    createdBy {
      id
      name
      picture
      organization {
        id
        name
        organizationImage
        url
      }
    }
  }
`;

export const fieldsOnAuthor = gql`
  fragment fieldsOnAuthor on Person {
    id
    name
    role
    picture
    organization {
      id
      name
      organizationImage
      url
    }
  }
`;
export const fieldsOnReplies = gql`
  fragment fieldsOnReplies on Comment {
    id
    message
    dateCreated
    lastUpdated
    status
    likes
    isLiked
    author {
      ...fieldsOnAuthor
    }
  }
  ${fieldsOnAuthor}
`;

export const fieldsOnComment = gql`
  fragment fieldsOnComment on Comment {
    id
    message
    dateCreated
    likes
    lastUpdated
    status
    isLiked
    replies {
      ...fieldsOnReplies
    }
    author {
      ...fieldsOnAuthor
    }

    files {
      ...fieldsOnUserFile
    }
  }
  ${fieldsOnAuthor}
  ${fieldsOnUserFile}
  ${fieldsOnReplies}
`;

export const fieldsOnMeSimple = gql`
  fragment fieldsOnMeSimple on Me {
    id
    name
    jobTitle
    picture
    isAdmin
    calendarCode
    organization {
      id
      name
      organizationImage
      url
    }
  }
`;

export const DISCUSSION = gql`
  query Discussion($id: ID!) {
    discussion(id: $id) {
      id
      title
      dateCreated
      lastUpdated
      isLiked
      uri
      likes
      description
      status
      isFollowing
      createdBy {
        ...fieldsOnAuthor
      }
      currentRevision {
        id
        description
        body
      }
      topics {
        id
        title
        uri
      }
      files {
        ...fieldsOnUserFile
      }
      comments {
        ...fieldsOnComment
        replies {
          ...fieldsOnReplies
        }
      }
    }
    me {
      ...fieldsOnMeSimple
    }
  }
  ${fieldsOnUserFile}
  ${fieldsOnAuthor}
  ${fieldsOnComment}
  ${fieldsOnMeSimple}
  ${fieldsOnReplies}
`;
interface IDiscussionQueryCompState {
  reRender: boolean;
}

class DiscussionQueryComp extends Query<DiscussionQuery, DiscussionVariables> {}

const DiscussionComponent = class extends React.PureComponent<
  IDiscussionItemProps &
    InjectedAuthRouterProps &
    RouteComponentProps<{ uri: string }> &
    InjectedIntlProps &
    IDiscussionItemStateProps,
  IDiscussionQueryCompState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      reRender: false,
    };
  }

  componentDidMount(): void {
    const id =
      'META_' +
      this.props.match.params.uri.substring(
        this.props.match.params.uri.lastIndexOf('-') + 1,
        this.props.match.params.uri.length
      );
    socket.subscribe(id, {
      waitForAuth: true,
    });
    myStore.dispatch(metaUpdatedClearActionCreator(undefined));
  }

  componentWillUnmount(): void {
    const id =
      'META_' +
      this.props.match.params.uri.substring(
        this.props.match.params.uri.lastIndexOf('-') + 1,
        this.props.match.params.uri.length
      );
    socket.unsubscribe(id);
  }

  handleUpdateDiscussion = async (
    discussionId: string,
    rawMarkdown: string,
    files2: IFileItem[],
    title: string
  ) => {
    // first we create the comment
    await myApolloClient.mutate({
      mutation: UPDATE_DISCUSSION,
      variables: {
        input: {
          id: discussionId,
          title: title,
          body: rawMarkdown,
          bodyText: convertFromRaw(JSON.parse(rawMarkdown)).getPlainText(),
          status: ContentStatus.PUBLISHED,
        },
      },
    });

    // then we read out the newly created comment's id (metaId) from the result:
    // then we upload all files attached to the comment's metaId:
    for (let i = 0; i < files2.length; i++) {
      if (files2[i].file !== null && files2[i].file !== null) {
        try {
          const uploadResult = await uploadFile(
            files2[i].file!,
            discussionId,
            files2[i].description,
            p => console.log('upload progress: ' + p)
          );
          if (!uploadResult.greatSuccess) {
            console.warn('Oops, upload failed: ' + files2[i].filename);
            alert('Filen du valgte er for stor');
            // NOTE: we could take some action here? Now we just continue to the next file...
          }
        } catch (uploadErr) {
          console.log('upload err', uploadErr);
        }
      }
    }

    // finally we update the cache by re-fetching the entire discussion (with comments)
    await myApolloClient.query({
      query: DISCUSSION,
      variables: {
        id: discussionId,
      },
      fetchPolicy: 'network-only',
    });
  };

  handleUpdateComment = async (
    commentId: string,
    rawMarkdown: string,
    files2: IFileItem[],
    discussionId: string
  ) => {
    // first we create the comment
    let descText = '';
    try {
      descText = convertFromRaw(JSON.parse(rawMarkdown)).getPlainText();
    } catch (e) {
      descText = rawMarkdown;
    }
    const result = await myApolloClient.mutate({
      mutation: UPDATE_COMMENT,
      variables: {
        input: {
          id: commentId,
          meta: commentId,
          parent: undefined, // ??
          message: rawMarkdown,
          messageText: descText,
          status: ContentStatus.PUBLISHED,
        },
      },
    });
    // then we read out the newly created comment's id (metaId) from the result:
    const freshId =
      (result.data &&
        result.data.updateComment &&
        result.data.updateComment.id) ||
      undefined;
    // console.log(freshId, 'id');
    // then we upload all files attached to the comment's metaId:
    for (let i = 0; i < files2.length; i++) {
      if (files2[i].file !== null && files2[i].file !== null) {
        try {
          const uploadResult = await uploadFile(
            files2[i].file!,
            freshId,
            files2[i].description,
            p => console.log('upload progress: ' + p)
          );
          if (!uploadResult.greatSuccess) {
            console.warn('Oops, upload failed: ' + files2[i].filename);
            // NOTE: we could take some action here? Now we just continue to the next file...
          }
        } catch (uploadErr) {
          console.log('upload err', uploadErr);
        }
      }
    }

    // finally we update the cache by re-fetching the entire discussion (with comments)
    await myApolloClient.query({
      query: DISCUSSION,
      variables: {
        id: discussionId,
      },
      fetchPolicy: 'network-only',
    });
  };

  // NOTE that this function is async
  handleCreateComment = async (
    discussionId: string,
    rawMarkdown: string,
    files2: IFileItem[]
  ) => {
    // first we create the comment
    let descText = '';
    try {
      descText = convertFromRaw(JSON.parse(rawMarkdown)).getPlainText();
    } catch (e) {
      descText = rawMarkdown;
    }
    const result = await myApolloClient.mutate({
      mutation: CREATE_COMMENT,
      variables: {
        input: {
          meta: discussionId,
          parent: undefined, // ??
          message: rawMarkdown,
          messageText: descText,
          status: ContentStatus.PUBLISHED,
        },
      },
    });

    // then we read out the newly created comment's id (metaId) from the result:
    const freshId =
      (result.data &&
        result.data.createComment &&
        result.data.createComment.id) ||
      undefined;

    // TODO: mandag, skriv uploadMultipleFile i fileservice.ts

    // const uploadResult = await uploadMultipleFiles(files2);

    // then we upload all files attached to the comment's metaId:
    for (let i = 0; i < files2.length; i++) {
      if (files2[i].file !== null && files2[i].file !== null) {
        try {
          const uploadResult = await uploadFile(
            files2[i].file!,
            freshId,
            files2[i].description,
            p => console.log('upload progress: ' + p)
          );
          if (!uploadResult.greatSuccess) {
            console.warn('Oops, upload failed: ' + files2[i].filename);
            // NOTE: we could take some action here? Now we just continue to the next file...
          }
        } catch (uploadErr) {
          console.log('upload err', uploadErr);
        }
      }
    }

    // finally we update the cache by re-fetching the entire discussion (with comments)
    await myApolloClient.query({
      query: DISCUSSION,
      variables: {
        id: discussionId,
      },
      fetchPolicy: 'network-only',
    });
  };

  handleFollow = (id: string, follow: boolean) => {
    safeInvokeDeprecated(this.props.onFollowDiscussion, id, follow);
  };

  pickFile = (
    allFiles: { [byId: string]: Readonly<IFile> },
    userFiles: ReadonlyArray<number> | undefined
  ) => {
    let retVal: Array<IFile> = [];
    if (userFiles && allFiles) {
      for (let ufId of userFiles) {
        Object.keys(allFiles).forEach(index => {
          if (+allFiles[index].id === ufId) {
            retVal.push(allFiles[index]);
          }
        });
      }
    }
    return retVal;
  };

  render() {
    const { intl } = this.props;

    const id = this.props.match.params.uri.substring(
      this.props.match.params.uri.lastIndexOf('-') + 1,
      this.props.match.params.uri.length
    );

    return (
      <DiscussionQueryComp
        query={DISCUSSION}
        variables={{ id: id }}
        fetchPolicy={'network-only'}
      >
        {({ loading, error, data, refetch }) => {
          if (loading) {
            return <SuspenseLoader />;
          }

          let title =
            (data && data.discussion && data.discussion.title) || undefined;

          // todo: when error change title?

          const username = (data && data.me && data.me.id) || undefined;

          const canEditQuestion =
            (data &&
              data.discussion &&
              data.discussion.createdBy &&
              data.discussion.createdBy.id === username) ||
            (data && data.me && data.me.isAdmin) ||
            false;

          let lastComment: Date = new Date(1970, 1, 1, 1, 1, 1);

          if (data && data.discussion) {
            lastComment = data.discussion.dateCreated;

            if (data.discussion.comments) {
              data.discussion.comments.forEach(comment => {
                if (comment.dateCreated > lastComment) {
                  lastComment = comment.dateCreated;
                }
              });
            }
          }

          let needRefresh = false;
          this.props.updatedMetas.forEach(value => {
            if (+value === +id) {
              needRefresh = true;
            }
          });

          return (
            <Page>
              <Helmet>
                <title>{title}</title>
              </Helmet>
              {needRefresh && (
                <RefreshMessage
                  onClick={() => {
                    refetch();
                    myStore.dispatch(metaUpdatedClearActionCreator(undefined));
                  }}
                >
                  Informasjonen nedanfor har blitt oppdatert. Klikk her for å
                  laste inn på nytt.
                </RefreshMessage>
              )}
              <PageTopPure>
                <PageTopSplitContainer>
                  <PageTitle>
                    {data && data.discussion && (
                      <MuContentEditable
                        style={{
                          maxWidth: '40em',
                          overflow: 'hidden',
                          wordBreak: 'break-word',
                        }}
                        value={title}
                        canEdit={canEditQuestion}
                        onSubmit={(markdown: string) => {
                          if (!data || !data.discussion) {
                            return;
                          }
                          let descText = '';
                          try {
                            descText = convertFromRaw(
                              JSON.parse(
                                data.discussion &&
                                  data.discussion.currentRevision.body
                              )
                            ).getPlainText();
                          } catch (e) {
                            descText =
                              data.discussion &&
                              data.discussion.currentRevision.body;
                          }
                          if (!isReallyEmpty(markdown)) {
                            myApolloClient
                              .mutate({
                                mutation: UPDATE_DISCUSSION,
                                variables: {
                                  input: {
                                    id: data.discussion && data.discussion.id,
                                    title: markdown,
                                    body:
                                      data.discussion &&
                                      data.discussion.currentRevision.body,
                                    bodyText: descText,
                                    status: ContentStatus.PUBLISHED,
                                  },
                                },
                              })
                              .then(c => {
                                myApolloClient.query({
                                  query: DISCUSSION,
                                  fetchPolicy: 'network-only',
                                  variables: {
                                    id: data.discussion && data.discussion.id,
                                  },
                                });
                              });
                          }
                        }}
                      />
                    )}
                  </PageTitle>
                  <PageTopRight>
                    {data && data.discussion && (
                      <>
                        <div
                          style={{
                            display: 'flex',
                          }}
                        >
                          <div style={{ marginRight: '.5em' }}>
                            <Clap
                              idToRefetch={data.discussion.id}
                              isClapped={data.discussion.isLiked}
                              ObjectToClap={ObjectType.DISCUSSION}
                              idToClap={data.discussion.id}
                            />
                          </div>
                          <FollowButton2
                            followId={data.discussion.id}
                            isFollowing={data.discussion.isFollowing}
                            buttonType={ButtType.BUTTON}
                            followType={MyType.DISCUSSION}
                          />
                          <ShareButton
                            meta={data.discussion.id}
                            style={{
                              marginLeft: '.5em',
                            }}
                          />
                        </div>

                        <NavButton
                          greyBackground={true}
                          buttonTheme={ButtonTheme.RED}
                          navigateTo={'back'}
                          leftIcon={MunikumIcons.Back}
                          text={intl.formatMessage(commonMessages.goBackButton)}
                          size={ButtonSize.Medium}
                          style={{
                            marginLeft: '.875em',
                          }}
                        />
                      </>
                    )}
                  </PageTopRight>
                </PageTopSplitContainer>

                <div>
                  <PageLastUpdated>
                    {intl.formatMessage(commonMessages.lastActivity)}:{' '}
                    {data && data.discussion
                      ? formatMunikumDate(lastComment)
                      : ''}
                  </PageLastUpdated>
                </div>
              </PageTopPure>

              {error && (
                <StatusMessage
                  theme={ThemeColor.ERROR_BLACK}
                  title={intl.formatMessage(errorMessages.notFound)}
                  text={
                    <FormattedMessage
                      id={'DiscussionItemPage.notFound'}
                      defaultMessage={
                        'Discussion with id {id} was not found!{br}Parsed uri: {uri}'
                      }
                      values={{
                        id: id,
                        uri: this.props.match.params.uri,
                        br: <br />,
                      }}
                    />
                  }
                  buttonText={intl.formatMessage(commonMessages.retry)}
                  onClickButton={() => window.location.reload()}
                />
              )}

              {data && data.discussion && (
                <FlexGrid
                  style={{
                    marginTop: '1.25em',
                  }}
                >
                  <FlexGridRow
                    topBorder={false}
                    style={{
                      justifyContent: 'space-between',
                      display: 'flex',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexFlow: 'row wrap',
                      }}
                    >
                      {data.discussion.topics.length > 0 && (
                        <TopicConnectedToTitle>
                          {intl.formatMessage(messages.TopicConnectedToTitle)}
                        </TopicConnectedToTitle>
                      )}
                      {data.discussion.topics.map(
                        (values: DiscussionTopics) => {
                          return (
                            <LinkWrapper
                              to={'/topic/' + values.uri}
                              key={values.id}
                            >
                              <Tag
                                hasShadow={true}
                                text={values.title}
                                color={Colors.DARKBLUE}
                                uppercase={false}
                                style={{ marginLeft: '10px' }}
                              />
                            </LinkWrapper>
                          );
                        }
                      )}
                    </div>

                    <FlexGridItemTop
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <ClapDiv
                        isLiked={data.discussion.isLiked}
                        style={{ marginRight: '1em', marginTop: '0' }}
                      >
                        <MunikumIcons.Clap
                          style={{ height: '1.3em', width: '1.3em' }}
                        />
                        <TextVerySmall>
                          {data.discussion.likes}{' '}
                          {intl.formatMessage(commonMessages.claps)}
                        </TextVerySmall>
                      </ClapDiv>
                      <TextVerySmall
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {data.discussion.comments.length}{' '}
                        {intl.formatMessage(messages.answers)}
                      </TextVerySmall>
                    </FlexGridItemTop>
                  </FlexGridRow>

                  <FlexGridRow topBorder={true}>
                    <FlexGridItem>
                      <QuestionView
                        onFileDelete={(fileId: string) => {
                          if (
                            window.confirm(
                              intl.formatMessage(messages.DeleteFileWarning)
                            )
                          ) {
                            myApolloClient
                              .mutate({
                                mutation: SET_STATUS,
                                variables: {
                                  input: {
                                    id: +fileId,
                                    status: ContentStatus.OBSOLETE,
                                  },
                                },
                              })
                              .then(() => {
                                myApolloClient.query({
                                  query: DISCUSSION,
                                  variables: {
                                    id: data.discussion && data.discussion.id,
                                  },
                                  fetchPolicy: 'network-only',
                                });
                              });
                          }
                        }}
                        onDeleteClick={() => {
                          let descText = '';
                          try {
                            descText = convertFromRaw(
                              JSON.parse(
                                data.discussion &&
                                  data.discussion.currentRevision.body
                              )
                            ).getPlainText();
                          } catch (e) {
                            descText =
                              data.discussion &&
                              data.discussion.currentRevision.body;
                          }
                          myApolloClient
                            .mutate({
                              mutation: UPDATE_DISCUSSION,
                              variables: {
                                input: {
                                  id: data.discussion && data.discussion.id,
                                  status: ContentStatus.OBSOLETE,
                                  title:
                                    data.discussion && data.discussion.title,
                                  bodyText: descText,
                                  body:
                                    data.discussion &&
                                    data.discussion.currentRevision.body,
                                },
                              },
                              update: (
                                proxy,
                                { data: { removeDiscussion } }
                              ) => {
                                let cacheData: any = proxy.readQuery({
                                  query: ALL_DISCUSSIONS,
                                  variables: {
                                    sort: SortEnum.LAST_UPDATED,
                                    order: OrderEnum.DESCENDING,
                                    onlyMine: false,
                                    limit: 10,
                                    offset: 0,
                                  },
                                });
                                let discussionId: number = +data.discussion
                                  .id as number;
                                for (
                                  let i = 0;
                                  i < cacheData.allDiscussions.nodes.length;
                                  i++
                                ) {
                                  const idToTest: number = cacheData
                                    .allDiscussions.nodes[i].id as number;
                                  if (idToTest === discussionId) {
                                    cacheData.allDiscussions.nodes.splice(i, 1);
                                  }
                                }
                                proxy.writeQuery({
                                  query: ALL_DISCUSSIONS,
                                  variables: {
                                    variables: {
                                      sort: SortEnum.LAST_UPDATED,
                                      order: OrderEnum.DESCENDING,
                                      onlyMine: false,
                                      limit: 10,
                                      offset: 0,
                                    },
                                  },
                                  data: cacheData,
                                });
                              },
                            })
                            .then(() => {
                              myHistory.push('/forum/public');
                            });
                        }}
                        createdBy={data.discussion.createdBy}
                        rawMarkdown={data.discussion.currentRevision.body || ''}
                        lastUpdated={data.discussion.lastUpdated}
                        canEdit={
                          data.me.id === data.discussion.createdBy.id ||
                          data.me.isAdmin
                        }
                        onSave={(md: string, files2: IFileItem[]) => {
                          const idForDisc =
                            data.discussion && data.discussion.id;
                          this.handleUpdateDiscussion(
                            idForDisc || '',
                            md,
                            files2,
                            (data.discussion && data.discussion.title) || ''
                          );
                        }}
                        files={
                          data.discussion &&
                          data.discussion.files.map(file => {
                            const temp: IFile = {
                              id: file.id,
                              filename: file.name,
                              description: file.description,
                              lastupdated: file.lastUpdated,
                              contenttype: file.contentType,
                              size: file.size,
                            };
                            return temp;
                          })
                        }
                      />

                      <MunikumScrollContext.Consumer>
                        {scrollHelpers => (
                          <div>
                            {data.discussion.comments.map(
                              (c: DiscussionComments, x: number) => {
                                let canEditComment = false;
                                if (c.author.id === username) {
                                  canEditComment = true;
                                }
                                if (data && data.me && data.me.isAdmin) {
                                  canEditComment = true;
                                }
                                return (
                                  <CommentView2
                                    parentMetaId={data.discussion.id}
                                    refetchSubCommentType={
                                      ObjectType.SUBCOMMENTDISCUSSION
                                    }
                                    onClickClap={() => {
                                      setTimeout(() => {
                                        this.setState({
                                          reRender: !this.state.reRender,
                                        });
                                      }, 2000);
                                    }}
                                    likes={c.likes}
                                    isLiked={c.isLiked}
                                    commentId={c.id}
                                    key={'comment_' + x}
                                    objectToClap={ObjectType.COMMENTDISCUSSION}
                                    onFileDelete={(commentFileId: string) => {
                                      if (
                                        window.confirm(
                                          intl.formatMessage(
                                            messages.DeleteFileWarning
                                          )
                                        )
                                      ) {
                                        myApolloClient
                                          .mutate({
                                            mutation: SET_STATUS,
                                            variables: {
                                              input: {
                                                id: commentFileId,
                                                status: ContentStatus.OBSOLETE,
                                              },
                                            },
                                          })
                                          .then(() => {
                                            myApolloClient.query({
                                              query: DISCUSSION,
                                              variables: {
                                                id:
                                                  data.discussion &&
                                                  data.discussion.id,
                                              },
                                              fetchPolicy: 'network-only',
                                            });
                                          });
                                      }
                                    }}
                                    onDeleteClick={() => {
                                      let descText = '';
                                      try {
                                        descText = convertFromRaw(
                                          JSON.parse(c.message)
                                        ).getPlainText();
                                      } catch (e) {
                                        descText = c.message;
                                      }
                                      myApolloClient
                                        .mutate({
                                          mutation: UPDATE_COMMENT,
                                          variables: {
                                            input: {
                                              id: c.id,
                                              meta:
                                                data &&
                                                data.discussion &&
                                                data.discussion.id &&
                                                data.discussion.id,
                                              status: ContentStatus.OBSOLETE,
                                              message: c.message,
                                              messageText: descText,
                                              parent: undefined,
                                            },
                                          },
                                        })
                                        .then(() => {
                                          myApolloClient.query({
                                            query: DISCUSSION,
                                            variables: {
                                              id:
                                                data.discussion &&
                                                data.discussion.id,
                                            },
                                            fetchPolicy: 'network-only',
                                          });
                                        });
                                    }}
                                    onSubCommentDelete={(
                                      id2: string | number,
                                      message: string
                                    ) => {
                                      let descText = '';
                                      try {
                                        descText = convertFromRaw(
                                          JSON.parse(message)
                                        ).getPlainText();
                                      } catch (e) {
                                        descText = message;
                                      }
                                      myApolloClient
                                        .mutate({
                                          mutation: UPDATE_COMMENT,
                                          variables: {
                                            input: {
                                              id: +id2,
                                              meta:
                                                data &&
                                                data.discussion &&
                                                data.discussion.id &&
                                                data.discussion.id,
                                              status: ContentStatus.OBSOLETE,
                                              message: message,
                                              messageText: descText,
                                              parent: c.id,
                                            },
                                          },
                                        })
                                        .then(() => {
                                          myApolloClient.query({
                                            query: DISCUSSION,
                                            variables: {
                                              id:
                                                data.discussion &&
                                                data.discussion.id,
                                            },
                                            fetchPolicy: 'network-only',
                                          });
                                        });
                                    }}
                                    canEdit={canEditComment}
                                    comments={c.replies}
                                    me={data.me}
                                    lastUpdated={c.lastUpdated}
                                    author={c.author}
                                    rawMarkdown={c.message}
                                    onUpdateComment={(md: string, files) => {
                                      // console.log(files, ' mathias');
                                      this.handleUpdateComment(
                                        c.id,
                                        md,
                                        files,
                                        (data.discussion &&
                                          data.discussion.id) ||
                                          ''
                                      );
                                    }}
                                    onSaveSubComment={(
                                      md: string,
                                      files2: IFileItem[]
                                    ) => {
                                      let descText = '';
                                      try {
                                        descText = convertFromRaw(
                                          JSON.parse(md)
                                        ).getPlainText();
                                      } catch (e) {
                                        descText = md;
                                      }
                                      myApolloClient
                                        .mutate({
                                          mutation: CREATE_COMMENT,
                                          variables: {
                                            input: {
                                              meta:
                                                data.discussion &&
                                                data.discussion.id,
                                              message: md,
                                              messageText: descText,
                                              parent: c.id,
                                              status: ContentStatus.PUBLISHED,
                                            },
                                          },
                                        })
                                        .then(() => {
                                          myApolloClient.query({
                                            query: DISCUSSION,
                                            variables: {
                                              id:
                                                data.discussion &&
                                                data.discussion.id,
                                            },
                                            fetchPolicy: 'network-only',
                                          });
                                        });
                                      // this.handleSaveComment(saveForm, files2);
                                    }}
                                    onUpdateSubComment={(
                                      md: string,
                                      itemId: string,
                                      meta: any
                                    ) => {
                                      // console.log(
                                      //   'onUpdateSubComment. what is meta again?' +
                                      //     meta +
                                      //     ', ' +
                                      //     c
                                      // );
                                      let descText = '';
                                      try {
                                        descText = convertFromRaw(
                                          JSON.parse(md)
                                        ).getPlainText();
                                      } catch (e) {
                                        descText = md;
                                      }
                                      myApolloClient.mutate({
                                        mutation: UPDATE_COMMENT,
                                        variables: {
                                          input: {
                                            id: itemId,
                                            meta:
                                              data &&
                                              data.discussion &&
                                              data.discussion.id &&
                                              data.discussion.id,
                                            parent: c.id,
                                            message: md,
                                            messageText: descText,
                                            status: ContentStatus.PUBLISHED,
                                          },
                                        },
                                        refetchQueries: [
                                          {
                                            query: DISCUSSION,
                                            variables: {
                                              id:
                                                data.discussion &&
                                                data.discussion.id,
                                            },
                                          },
                                        ],
                                      });
                                    }}
                                    onShouldScrollToPosition={(pos: {
                                      x: number;
                                      y: number;
                                    }) =>
                                      scrollHelpers.scrollToPosition(
                                        pos.x,
                                        pos.y
                                      )
                                    }
                                    files={c.files}
                                  />
                                );
                              }
                            )}
                          </div>
                        )}
                      </MunikumScrollContext.Consumer>

                      <AnswerDiscussionView
                        me={data.me}
                        onSave={(rawMarkdown: string, files2: IFileItem[]) => {
                          if (data.discussion && !isReallyEmpty(rawMarkdown)) {
                            const discussionId =
                              (data && data.discussion && data.discussion.id) ||
                              undefined;
                            if (discussionId !== undefined) {
                              this.handleCreateComment(
                                discussionId,
                                rawMarkdown,
                                files2
                              );
                            }
                          }
                        }}
                      />
                    </FlexGridItem>
                  </FlexGridRow>
                </FlexGrid>
              )}
            </Page>
          );
        }}
      </DiscussionQueryComp>
    );
  }
};

const mapStateToProps = (state: RootState): IDiscussionItemStateProps => ({
  updatedMetas: state.notifications.updatedMetas,
});

export const DiscussionItemPage = connect<{}, {}, IDiscussionItemStateProps>(
  mapStateToProps,
  undefined
)(injectIntl(DiscussionComponent));
export default DiscussionItemPage;
