import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import { IFileItem } from './FileUploaderPicker';
import { IThemeProps, safeInvoke } from '../../common';
import { FaPaperclip, FaTimesCircle } from 'react-icons/fa';
import { guid } from './FileCommon';
import { Tooltip } from '../Tooltip/Tooltip';
import { convertFromRaw, Editor, EditorState } from 'draft-js';
import { AccessEnum } from '../../../models/types';

export interface IFileListFileProps {
  file?: IFileItem;
  fileid?: string;
  filename: string;
  description?: string;
  persisted: boolean;
  metaid?: number;
  uuid: string;
  removeitem: (id: string) => void;
  onFileDelete?: () => void;
  onClick?: (id: string) => void;
  canEdit?: boolean;
  isEditMode?: boolean;
  iconStyle?: CSSProperties;
  style?: CSSProperties;
  canRemove?: boolean;
}

interface IFileRenderProps {
  persisted: boolean;
}

const FileListFileBox = styled.div`
  display: flex;
  flex-direction: row;
  border: 2px solid lightgray;
  border-radius: 5px;
  margin: 4px;
  align-items: center;
  padding: 5px 10px;
  &:hover {
    cursor: pointer;
  }
  color: ${(props: IFileRenderProps & { theme: IThemeProps }) =>
    props.persisted ? props.theme.textColor : props.theme.textColor};
`;

const FileListFileIdentityBox = styled.div`
  flex: 6;
  display: flex;
  flex-direction: row;
`;

const FileListFileIconBox = styled.div`
  //flex: 1;
  margin-right: 5px;
  margin-top: 2px;
`;
const FileListFileTitleBox = styled.div`
  display: inline-block;
  font-size: 14px;
  max-width: 30em;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 4;
  white-space: nowrap;
`;

const FileListFileRemoverBox = styled.div`
  flex: 1;
  margin: 2px 5px 0px 5px;
`;

class FileListFile extends React.PureComponent<IFileListFileProps, {}> {
  handleRemoval = () => {
    // console.log(this.props.uuid);
    this.props.removeitem(this.props.uuid);
  };

  handleClick = () => {
    if (this.props.fileid && this.props.onClick) {
      // doDownloadFile(this.props.fileid);
      console.log('FILE CLICKED');

      this.props.onClick(this.props.fileid);
    }
  };

  render() {
    const { persisted, filename } = this.props;

    let contentEl = undefined;

    if (this.props.description !== undefined && this.props.description !== '') {
      const contentState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(this.props.description))
      );

      contentEl = (
        <div>
          <Editor editorState={contentState} readOnly={true} onChange={null} />
        </div>
      );
    }

    let fileBox = undefined;

    const fileEl = (
      <FileListFileIdentityBox onClick={this.handleClick}>
        <FileListFileIconBox>
          <FaPaperclip style={this.props.iconStyle} />
        </FileListFileIconBox>
        <FileListFileTitleBox>{filename}</FileListFileTitleBox>
        {this.props.canRemove && (
          <FileListFileRemoverBox
            style={{ marginLeft: '.5em' }}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              safeInvoke(this.props.onFileDelete);
            }}
          >
            <FaTimesCircle style={this.props.iconStyle} />
          </FileListFileRemoverBox>
        )}
      </FileListFileIdentityBox>
    );

    if (contentEl !== undefined) {
      fileBox = (
        <Tooltip position={'top-start'} content={contentEl}>
          {fileEl}
        </Tooltip>
      );
    } else {
      fileBox = fileEl;
    }

    return (
      <FileListFileBox style={this.props.style} persisted={persisted}>
        {fileBox}
        {!persisted && (
          <FileListFileRemoverBox onClick={this.handleRemoval}>
            <FaTimesCircle style={this.props.iconStyle} />
          </FileListFileRemoverBox>
        )}
      </FileListFileBox>
    );
  }
}

export interface IFileUploaderListProps {
  metaid?: number;
  recentFiles?: IFileItem[];
  persistedFiles?: ReadonlyArray<any>;
  emptyAllRecent?: boolean;
  onRemoveFile: (id: string) => void;
  onFileDelete?: (id: string) => void;
  onClick?: (id: string, access: AccessEnum) => void;
  canEdit?: boolean;
  iconStyle?: CSSProperties;
  style?: CSSProperties;
  canRemove?: boolean;
  isEditMode?: boolean;
}

const MainBox = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export class FileUploaderList extends React.PureComponent<
  {} & IFileUploaderListProps,
  {}
> {
  private apiUrl = process.env.REACT_APP_API;

  render() {
    const { metaid, onRemoveFile } = this.props;

    // console.log('Test', this.props.persistedFiles, this.props.recentFiles);
    return (
      <MainBox>
        {this.props.persistedFiles &&
          this.props.persistedFiles.map(f => {
            let fUuid: string = guid();
            if (f) {
              return (
                <FileListFile
                  canRemove={this.props.canRemove}
                  isEditMode={this.props.isEditMode}
                  onFileDelete={() => {
                    safeInvoke(this.props.onFileDelete, f.id);
                  }} // TODO: correct id?
                  onClick={() => {
                    safeInvoke(this.props.onClick, f.id, f.access);
                  }}
                  metaid={metaid}
                  uuid={fUuid}
                  style={this.props.style}
                  iconStyle={this.props.iconStyle}
                  fileid={f.id}
                  canEdit={this.props.canEdit || false}
                  filename={f.filename}
                  description={f.description}
                  persisted={true}
                  removeitem={onRemoveFile}
                  key={fUuid}
                />
              );
            }
            return '';
          })}
        {this.props.recentFiles &&
          this.props.recentFiles.map(f => (
            <FileListFile
              iconStyle={this.props.iconStyle}
              style={this.props.style}
              isEditMode={this.props.isEditMode}
              canEdit={this.props.canEdit || false}
              metaid={metaid}
              uuid={f.uuid}
              fileid={f.fileid}
              filename={f.filename}
              description={f.description}
              persisted={false}
              removeitem={onRemoveFile}
              key={f.uuid}
            />
          ))}
      </MainBox>
    );
  }
}
