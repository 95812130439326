import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import { MunikumIcons } from '../common/icons';
import moment from 'moment';
import Mutation from 'react-apollo/Mutation';
import gql from 'graphql-tag';
import { IEventInstanceViewModel } from './AnnualCycleRightMenu';
import {
  Colors,
  ColorTheme,
  safeInvoke,
  safeInvokeDeprecated,
} from '../common';
import {
  ContentStatus,
  UpdateEventInstanceInput,
  UpdateInstanceStatusVariables,
} from '../../models/types';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { CheckboxLight } from '../basic/Checkbox/CheckboxLight';
import { Avatar } from '../person/Avatar';
import { Tooltip } from '../basic/Tooltip/Tooltip';
import { TextNormal } from '../basic/Structural/typography';
import { Button } from '../basic';
import { commonLabels } from '../language/commonLabels';
import { Formik, FormikProps } from 'formik';
import { IToastHelpers, ToasterContext } from '../basic/Toaster/ToasterContext';
import { commonMessages } from '../language/commonMessages';
import { convertFromRaw } from 'draft-js';
import { MuTextField } from '../basic/MuEditor/MuTextField';
import { UserPreview } from '../basic/UserPreview/UserPreview';
import _ from 'lodash';
import { ProgressBar } from '../basic/ProgressBar/ProgressBar';

const TaskDiv = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  height: 100%;
  overflow: hidden;
  // white-space: nowrap;
  //text-overflow: ellipsis;
`;

const TaskDate = styled.div`
  color: ${props => props.theme.textColor};
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 10em;
`;

const TaskForkInfo = styled.div`
  color: ${props => props.theme.textColor};
  font-size: 12px;
  width: 7em;
  margin-right: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TaskText = styled.div`
  color: ${props => props.theme.textColor};
  font-size: 14px;
  line-height: 17px;
  //max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-self: center;
`;

const CalanderDiv = styled.a`
  height: 24px;
  width: 29px;
  border: 1px solid ${props => props.theme.noAccent.iconColor};
  border-radius: 4px;
  background-color: ${props => props.theme.secondaryContentBackgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.noAccent.textColor};
`;

const InfoDiv = styled.div`
  margin-left: 0.5em;
  display: flex;
  align-items: center;
  //width: 220px;
  justify-content: flex-end;
  overflow: hidden;
  flex: 0 0 ${(props: { hasForks: boolean }) => (props.hasForks ? '300px' : '200px')};
`;

const AvatarDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  //overflow: hidden;
  //white-space: nowrap;
  //text-overflow: ellipsis;
  color: rgba(51, 51, 51, 0.7);
  font-family: Lato, sans-serif;
  margin-right: 1em;
  //font-size: 12px;
`;

export const TaskContainer = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: ${props => props.theme.secondaryContentBackgroundColor};
  opacity: ${(props: { isFinished: boolean; isHighlighted: boolean }) =>
          props.isFinished ? '0.5' : '1'};

  //border: 1px solid #d0d3d4;

  box-shadow: ${(props: { isFinished: boolean; isHighlighted: boolean }) =>
          props.isHighlighted
                  ? '0 2px 7px 0 rgba(0, 0, 0, 0.2)'
                  : '0 2px 7px 0 rgba(0, 0, 0, 0.1)'};
  height: 38px;
  margin-bottom: 8px;
  box-sizing: border-box;
  padding: 0.5em;

  overflow: hidden;

  overflow-wrap: break-word;
  word-break: break-word; /* Chrome, Safari */
  word-wrap: break-word; /* IE11, Firefox */

  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    //transform: scale(1.01);
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2);
  }

  page-break-inside: avoid;
`;

const Circle = styled.div`
  height: 1em;
  width: 1em;
  border-radius: 50%;
  margin-right: 0.5em;
  opacity: 0.5;
`;

export interface ITaskCompProps {
  eventInstance: IEventInstanceViewModel;
  canWrite: boolean;
  taskId: string;
  year: number;
  // eventInstanceId: string;
  disabled?: boolean;
  style?: CSSProperties;
  checkBoxColor?: string;
  isHighlighted?: boolean;
  isFinishAllTodosClicked?: boolean;
  calendarCode: string;
  me?: any;
  onFavouriteClick?: () => void;
  /**
   * this is dispatched after a task is completed successfully (isDone is set to true)
   */
  onCompleted?: (id: number | string, isDone: boolean) => void;

  // stupid temp name...
  onRestored?: (id: number | string, isDone: boolean) => void;

  /**
   * onClick card (open edit task)
   */
  onClick: () => void;
}

export const UPDATE_INSTANCE_STATUS = gql`
    mutation UpdateInstanceStatus($input: UpdateEventInstanceInput!) {
        updateEventInstance(input: $input) {
            id
            isDone
            isFavourite
            status
        }
    }
`;

interface ITaskCompUpdateState {
  isFavourite: boolean;
  isDone: boolean;
  // myTodosToSend: Array<Todos>;
  // myInstanceToSend: any;
  noteText: string;
}

interface IAddTaskComp {
  onToastRemove?: () => void;
  eventInstance: IEventInstanceViewModel;
  isDone?: boolean;
}

// const tags = [
//   { color: Colors.RED, id: '1', title: 'Lunch' },
//   { color: Colors.BLUE, id: '2', title: 'Gaming' },
//   { color: Colors.YELLOW, id: '3', title: 'Økonomi' },
// ];

class AddTaskComp extends React.PureComponent<
  IAddTaskComp & InjectedIntlProps,
  ITaskCompUpdateState
> {
  // constructor(props: any) {
  //   super(props);
  //   this.state = {
  //     isDone: this.props.eventInstance.isDone
  //   };
  // }
  render() {
    const { intl } = this.props;
    return (
      <Mutation
        mutation={UPDATE_INSTANCE_STATUS}
        // optimisticResponse={{
        //   updateEventInstance: {
        //     id: this.props.eventInstance.id,
        //     isDone: this.props.eventInstance.isDone,
        //     note: this.props.eventInstance.note,
        //     __typename: 'EventInstance',
        //
        //   },
        // }}
      >
        {(updateInstanceStatus, { data, client }) => {
          return (
            <Formik
              initialValues={{
                note: this.props.eventInstance.note || '',
              }}
              onSubmit={(values: any) => {
                let noteText = values.note;
                let descText = values.note;
                try {
                  noteText = convertFromRaw(
                    JSON.parse(values.note)
                  ).getPlainText();
                } catch (e) {
                  noteText = values.note;
                }
                try {
                  descText = convertFromRaw(
                    JSON.parse(this.props.eventInstance.description)
                  ).getPlainText();
                } catch (e) {
                  descText = this.props.eventInstance.description;
                }
                const values2: UpdateEventInstanceInput = {
                  id: this.props.eventInstance.id,
                  title: this.props.eventInstance.title,
                  description: this.props.eventInstance.description,
                  descriptionText: descText,
                  startDate: this.props.eventInstance.startDate,
                  endDate:
                    this.props.eventInstance.endDate ||
                    this.props.eventInstance.startDate,
                  status: ContentStatus.PUBLISHED,
                  note: values.note,
                  noteText: noteText,
                  isDone: this.props.isDone,
                  isFavourite: this.props.eventInstance.isFavourite,
                  responsible: this.props.eventInstance.responsible
                    ? this.props.eventInstance.responsible.id
                    : null,
                  reminderDate: this.props.eventInstance.reminderDate,
                };
                const temp2: UpdateInstanceStatusVariables = {
                  input: values2,
                };
                updateInstanceStatus({ variables: temp2 }).then(c => {
                  this.props.eventInstance.note = values.note || '';
                  safeInvokeDeprecated(this.props.onToastRemove);
                });
              }}
              render={(props: FormikProps<any>) => {
                return (
                  <div
                    style={{
                      marginRight: '1em',
                    }}
                  >
                    <TextNormal
                      style={{
                        color: '#fff',
                        fontSize: '16px',
                        marginBottom: '.5em',
                      }}
                    >
                      <FormattedMessage
                        id={'TaskComp.ToastNoteTitle'}
                        defaultMessage={'Do you want to add a note?'}
                        values={{
                          br: <br />,
                        }}
                      />
                    </TextNormal>
                    <MuTextField
                      style={{
                        backgroundColor: '#fff',
                        color: '#333',
                        maxWidth: '15em',
                        minHeight: '7.5em',
                        marginBottom: 0,
                      }}
                      value={props.values.note}
                      onSubmit={value => {
                        props.setFieldValue('note', value);
                      }}
                      placeholder={intl.formatMessage(
                        commonLabels.notesPlaceholder
                      )}
                      doIHaveAccessToAddFiles={false}
                      shouldEditorHaveFiles={false}
                    />
                    <div
                      style={{
                        marginTop: '1em',
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <ToasterContext.Consumer>
                        {(helpers: IToastHelpers) => (
                          <>
                            <Button
                              style={{ marginRight: '.5em' }}
                              onClick={() => {
                                safeInvokeDeprecated(this.props.onToastRemove);
                              }}
                              // size={ButtonSize.Small}
                              theme={ColorTheme.White}
                              text={'Nei'}
                            />
                            <Button
                              // size={ButtonSize.Small}
                              text={'Lagre'}
                              onClick={() => {
                                props.submitForm();
                              }}
                            />
                          </>
                        )}
                      </ToasterContext.Consumer>
                    </div>
                  </div>
                );
              }}
            />
          );
        }}
      </Mutation>
    );
  }
}

export const AddTask = injectIntl(AddTaskComp);

class TaskCompComp extends React.PureComponent<
  ITaskCompProps & InjectedIntlProps,
  ITaskCompUpdateState
> {
  constructor(props: ITaskCompProps & InjectedIntlProps) {
    super(props);
    this.state = {
      isFavourite: this.props.eventInstance.isFavourite,
      isDone: this.props.eventInstance.isDone,
      noteText: this.props.eventInstance.note || '',
      // myTodosToSend: [],
      // myInstanceToSend: undefined,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: ITaskCompProps) {
    if (nextProps.eventInstance.isFavourite !== this.state.isFavourite) {
      this.setState({
        isFavourite: nextProps.eventInstance.isFavourite,
      });
    }
    if (nextProps.eventInstance.note !== this.state.noteText) {
      this.setState({
        noteText: nextProps.eventInstance.note,
      });
    }
    if (nextProps.eventInstance.isDone !== this.state.isDone) {
      this.setState({
        isDone: nextProps.eventInstance.isDone,
      });
    }
  }

  render() {
    const { intl } = this.props;
    const tags = _.sortBy(this.props.eventInstance.tags, 'title');
    // const { currentTodo } = this.props;
    // const Icon = () => {
    //   if (this.props.eventInstance.isFavourite) {
    //     return (
    //       <svg height="28" width="17">
    //         <path d="M0 0 L17 0 L17 28 L9 22 L0 28 Z" fill="#ff5242" />
    //         <path
    //           d="M9 7 L11 12 L16 12 L12 15 L13 21 L9 17 L5 21 L6 15 L2 12 L7 12 Z"
    //           fill="#fff"
    //         />
    //       </svg>
    //     );
    //   } else {
    //     return (
    //       <svg height="28" width="17">
    //         <path
    //           d="M9 7 L11 12 L16 12 L12 15 L13 21 L9 17 L5 21 L6 15 L2 12 L7 12 Z"
    //           fill="#fff"
    //           stroke="#aaa"
    //         />
    //       </svg>
    //     );
    //   }
    // };

    let profilePic: any = undefined;
    if (this.props.eventInstance?.responsible?.picture) {
      profilePic =
        'https://hel1.your-objectstorage.com/munikum-public/' +
        this.props.eventInstance.responsible.picture;
    }

    let completedForks = 0;
    this.props.eventInstance.forks.forEach(fork => {
      if (fork.isDone || fork.status === 'OBSOLETE') {
        completedForks++;
      }
    });

    return (
      <>
        <>
          <Mutation
            mutation={UPDATE_INSTANCE_STATUS}
            optimisticResponse={{
              updateEventInstance: {
                id: this.props.eventInstance.id,
                isDone: this.state.isDone,
                isFavourite: this.state.isFavourite,
                status: ContentStatus.PUBLISHED,
                __typename: 'EventInstance',
              },
            }}
          >
            {(updateInstanceStatus, { data, client }) => {
              return (
                <TaskContainer
                  isHighlighted={this.props.isHighlighted || false}
                  style={this.props.style}
                  isFinished={this.state.isDone}
                  onClick={() => {
                    safeInvoke(this.props.onClick);
                  }}
                >
                  <TaskDiv>
                    <ToasterContext.Consumer>
                      {(toastHelper: IToastHelpers) => (
                        <CheckboxLight
                          style={{
                            flex: '0 0 auto',
                          }}
                          color={this.props.checkBoxColor}
                          checked={this.state.isDone}
                          onClick={() => {
                            if (this.props.canWrite) {
                              this.setState(
                                {
                                  isDone: !this.state.isDone,
                                },
                                () => {
                                  if (!this.props.eventInstance.id) {
                                    throw Error('id not defined');
                                  }
                                  let isDescriptionDraftJsFormat = false;
                                  let isNoteDraftJsFormat = false;
                                  try {
                                    convertFromRaw(
                                      JSON.parse(
                                        this.props.eventInstance.description
                                      )
                                    ).getPlainText();
                                    isDescriptionDraftJsFormat = true;
                                  } catch (e) {
                                    isDescriptionDraftJsFormat = false;
                                  }
                                  try {
                                    convertFromRaw(
                                      JSON.parse(this.props.eventInstance.note)
                                    ).getPlainText();
                                    isNoteDraftJsFormat = true;
                                  } catch (e) {
                                    isNoteDraftJsFormat = false;
                                  }

                                  const values: UpdateEventInstanceInput = {
                                    id: this.props.eventInstance.id,
                                    title: this.props.eventInstance.title,
                                    description: this.props.eventInstance
                                      .description,
                                    descriptionText: this.props.eventInstance
                                      .description
                                      ? isDescriptionDraftJsFormat
                                        ? convertFromRaw(
                                          JSON.parse(
                                            this.props.eventInstance
                                              .description
                                          )
                                        ).getPlainText()
                                        : ''
                                      : this.props.eventInstance.description,
                                    startDate: this.props.eventInstance
                                      .startDate,
                                    endDate:
                                      this.props.eventInstance.endDate ||
                                      this.props.eventInstance.startDate,
                                    status: ContentStatus.PUBLISHED,
                                    note: this.props.eventInstance.note,
                                    noteText: this.props.eventInstance.note
                                      ? isNoteDraftJsFormat
                                        ? convertFromRaw(
                                          JSON.parse(
                                            this.props.eventInstance.note
                                          )
                                        ).getPlainText()
                                        : ''
                                      : this.props.eventInstance.note,
                                    isDone: this.state.isDone,
                                    isFavourite: this.state.isFavourite,
                                    responsible: this.props.eventInstance
                                      .responsible
                                      ? this.props.eventInstance.responsible.id
                                      : null,
                                    reminderDate: this.props.eventInstance
                                      .reminderDate,
                                  };
                                  const temp2: UpdateInstanceStatusVariables = {
                                    input: values,
                                  };
                                  updateInstanceStatus({
                                    variables: temp2,
                                  }).then(() => {
                                    this.state.isDone
                                      ? safeInvoke(
                                        this.props.onCompleted,
                                        this.props.eventInstance.id,
                                        true
                                      )
                                      : safeInvoke(
                                        this.props.onRestored,
                                        this.props.eventInstance.id,
                                        false
                                      );
                                  });

                                  // FIXME Removed task completion popup
                                  // if (this.state.isDone) {
                                  //   const myId = toastHelper.addToast({
                                  //     message: (
                                  //       <AddTask
                                  //         isDone={this.state.isDone}
                                  //         eventInstance={
                                  //           this.props.eventInstance
                                  //         }
                                  //         onToastRemove={() => {
                                  //           toastHelper.removeToast(myId);
                                  //         }}
                                  //       />
                                  //     ),
                                  //     theme: ToastType.SUCCESS_BLUE,
                                  //     leftIcon: MunikumIcons.Info,
                                  //     rightIcon: MunikumIcons.Cancel,
                                  //     onClickRightIcon: e => {
                                  //       toastHelper.removeToast(myId);
                                  //     },
                                  //   });
                                  // }
                                }
                              );
                            } else {
                              alert(
                                intl.formatMessage(commonMessages.noWriteAccess)
                              );
                            }
                          }}
                        />
                      )}
                    </ToasterContext.Consumer>

                    {/*<Checkbox*/}
                    {/*fieldGroupStyle={{*/}
                    {/*paddingBottom: '0px',*/}
                    {/*}}*/}
                    {/*color={this.props.checkBoxColor}*/}
                    {/*unCheckedColor={this.props.checkBoxColor}*/}
                    {/*borderColor={this.props.checkBoxColor}*/}
                    {/*checked={this.state.isDone}*/}
                    {/*onClick={() => {*/}
                    {/*if (this.state.isDone) {*/}
                    {/*// UNCHECK:*/}
                    {/*this.setState(*/}
                    {/*{*/}
                    {/*isDone: !this.state.isDone,*/}
                    {/*isFinishTemAllOpen: false,*/}
                    {/*},*/}
                    {/*() => {*/}
                    {/*if (!this.props.eventInstance.id) {*/}
                    {/*throw Error('id not defined');*/}
                    {/*}*/}

                    {/*const values: UpdateEventInstanceInput = {*/}
                    {/*id: this.props.eventInstance.id,*/}
                    {/*title: this.props.eventInstance.title,*/}
                    {/*description: this.props.eventInstance.description,*/}
                    {/*startDate: this.props.eventInstance.startDate,*/}
                    {/*endDate:*/}
                    {/*this.props.eventInstance.endDate ||*/}
                    {/*this.props.eventInstance.startDate,*/}
                    {/*status: ContentStatus.PUBLISHED,*/}
                    {/*isDone: this.state.isDone,*/}
                    {/*isFavourite: this.state.isFavourite,*/}
                    {/*responsible: this.props.eventInstance.responsible*/}
                    {/*? this.props.eventInstance.responsible.id*/}
                    {/*: null,*/}
                    {/*reminderDate: this.props.eventInstance.reminderDate,*/}
                    {/*};*/}
                    {/*const temp2: UpdateInstanceStatusVariables = {*/}
                    {/*input: values,*/}
                    {/*};*/}
                    {/*updateInstanceStatus({ variables: temp2 }).then(() => {*/}
                    {/*safeInvoke(this.props.onRestored);*/}
                    {/*});*/}
                    {/*}*/}
                    {/*);*/}
                    {/*} else {*/}
                    {/*// CHECK, well just show dialog:*/}
                    {/*this.setState({*/}
                    {/*isFinishTemAllOpen: true,*/}
                    {/*});*/}
                    {/*}*/}
                    {/*}}*/}
                    {/*/>*/}

                    <TaskText
                      style={{
                        marginLeft: '.5em',
                      }}
                    >
                      {this.state.isDone && (
                        <Tooltip
                          usePortal={true}
                          style={{
                            fontSize: '16px',
                          }}
                          content={this.props.eventInstance.title}
                          position={'top-start'}
                        >
                          <del
                            style={{
                              textDecoration: 'line-through',
                            }}
                          >
                            {this.props.eventInstance.title}
                          </del>
                        </Tooltip>
                      )}

                      {!this.state.isDone && (
                        <Tooltip
                          usePortal={true}
                          style={{
                            fontSize: '16px',
                          }}
                          content={this.props.eventInstance.title}
                          position={'top-start'}
                        >
                          <div
                            style={{
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {this.props.eventInstance.title}
                          </div>
                        </Tooltip>
                      )}
                      {/*<Border isFinished={this.state.isDone} />*/}
                    </TaskText>
                  </TaskDiv>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {tags &&
                      tags.map((c: any, i) => {
                        if (c.color !== '' || undefined) {
                          return (
                            <Tooltip key={c.title + i} content={c.title}>
                              <Circle
                                key={i}
                                style={{
                                  backgroundColor: c?.color ?? Colors.GREY,
                                }}
                              />
                            </Tooltip>
                          );
                        } else {
                          return (
                            <Tooltip content={c.title}>
                              <Circle
                                key={i}
                                style={{ backgroundColor: Colors.GREY }}
                              />
                            </Tooltip>
                          );
                        }
                      })}
                  </div>
                  <InfoDiv
                    hasForks={
                      this.props.eventInstance.forks &&
                      this.props.eventInstance.forks.length > 0
                    }
                  >
                    {this.props.eventInstance.forks &&
                      this.props.eventInstance.forks.length > 0 && (
                        <TaskForkInfo>
                          <ProgressBar
                            color={'#FF5C39'}
                            value={completedForks}
                            max={this.props.eventInstance.forks.length}
                            min={0}
                            style={{
                              width: '50px',
                            }}
                          />
                          <div style={{ textAlign: 'center', width: '6em' }}>
                            {completedForks} /{' '}
                            {this.props.eventInstance.forks.length}
                          </div>
                        </TaskForkInfo>
                      )}
                    <AvatarDiv>
                      <Tooltip
                        usePortal={true}
                        content={
                          <UserPreview
                            organizationImage={
                              this.props.eventInstance?.responsible
                                ?.organization?.organizationImage ??
                              this.props.eventInstance?.createdBy?.organization
                                ?.organizationImage
                            }
                            image={profilePic}
                            name={
                              this.props.eventInstance?.responsible?.name ??
                              this.props.eventInstance?.createdBy?.name
                            }
                            organizationName={
                              this.props.eventInstance.responsible?.organization
                                ?.name ??
                              this.props.eventInstance.createdBy?.organization
                                ?.name
                            }
                            jobTitle={
                              this.props.eventInstance?.responsible?.role ??
                              this.props.eventInstance?.createdBy?.role
                            }
                            id={
                              this.props.eventInstance.responsible?.id ??
                              this.props.eventInstance.createdBy.id
                            }
                            organizationUrl={
                              this.props.eventInstance.responsible?.organization
                                ?.url ??
                              this.props.eventInstance.createdBy?.organization
                                ?.url
                            }
                          />
                        }
                      >
                        <div
                          style={{
                            display: 'inline-block',
                          }}
                        >
                          {profilePic && (
                            <img
                              // onClick={(e)=> {
                              //   e.stopPropagation();
                              //   myHistory.push('/person/' +
                              //     this.props.eventInstance?.responsible?.id ??
                              //     this.props.eventInstance?.createdBy?.id);
                              // }}
                              alt={'profil bilde'}
                              style={{
                                borderRadius: '50%',
                              }}
                              src={profilePic}
                              width="25px"
                              height="25px"
                            />
                          )}
                          {!profilePic && (
                            <Avatar
                              // onClick={(e: any)=> {
                              //   e.stopPropagation();
                              //   myHistory.push('/person/' +
                              //     this.props.eventInstance?.responsible?.id ??
                              //     this.props.eventInstance?.createdBy?.id);
                              // }}
                              name={
                                this.props.eventInstance?.responsible?.name ??
                                this.props.eventInstance?.createdBy?.name
                              }
                              style={{ marginRight: '.2em' }}
                            />
                          )}
                        </div>
                      </Tooltip>
                    </AvatarDiv>
                    <TaskDate>
                      {this.props.eventInstance.startDate ===
                      this.props.eventInstance.endDate ? (
                        <div
                          style={{
                            alignItems: 'center',
                            display: 'flex',
                          }}
                        >
                          <div
                            style={{
                              fontWeight: 'bold',
                              width: '2.7em',
                              marginRight: '1em',
                            }}
                          >
                            {intl.formatMessage(commonLabels.endDate)}:
                          </div>
                          {moment(
                            this.props.eventInstance.endDate || undefined
                          ).format('ddd Do MMM')}
                        </div>
                      ) : (
                        <>
                          <div
                            style={{
                              alignItems: 'center',
                              display: 'flex',
                            }}
                          >
                            <div
                              style={{
                                fontWeight: 'bold',
                                width: '2.7em',
                                marginRight: '1em',
                              }}
                            >
                              {intl.formatMessage(commonLabels.startDate)}:
                            </div>
                            {moment(
                              this.props.eventInstance.startDate || undefined
                            ).format('ddd Do MMM')}
                          </div>
                          <div
                            style={{
                              alignItems: 'center',
                              display: 'flex',
                            }}
                          >
                            <div
                              style={{
                                fontWeight: 'bold',
                                width: '2.7em',
                                marginRight: '1em',
                              }}
                            >
                              {intl.formatMessage(commonLabels.endDate)}:
                            </div>
                            <div>
                              {moment(
                                this.props.eventInstance.endDate || undefined
                              ).format('ddd Do MMM')}
                            </div>
                          </div>
                        </>
                      )}
                    </TaskDate>
                    <Tooltip usePortal={true} content={'Legg til i Outlook'}>
                      <div
                        style={{
                          display: 'inline-block',
                        }}
                      >
                        <CalanderDiv
                          onClick={(e: any) => e.stopPropagation()}
                          href={
                            process.env.REACT_APP_API +
                            '/meta/calendar?calendarCode=' +
                            this.props.calendarCode +
                            '&metaId=' +
                            this.props.eventInstance.id
                          }
                        >
                          <MunikumIcons.YearWheel />
                        </CalanderDiv>
                      </div>
                    </Tooltip>

                    {/*<StarDiv*/}
                    {/*isFavourited={this.state.isFavourite}*/}
                    {/*style={{*/}
                    {/*display: 'flex',*/}
                    {/*alignItems: 'center',*/}
                    {/*justifyContent: 'center',*/}
                    {/*}}*/}
                    {/*>*/}
                    {/*<MunikumIcons.Flag*/}
                    {/*style={{ width: '1em', height: '1em' }}*/}
                    {/*onClick={e => {*/}
                    {/*e.stopPropagation();*/}

                    {/*if (this.props.canWrite) {*/}
                    {/*if (!this.props.eventInstance.id) {*/}
                    {/*return;*/}
                    {/*}*/}

                    {/*this.setState(*/}
                    {/*{*/}
                    {/*isFavourite: !this.state.isFavourite,*/}
                    {/*},*/}
                    {/*() => {*/}
                    {/*const values: UpdateEventInstanceInput = {*/}
                    {/*id: this.props.eventInstance.id || '',*/}
                    {/*title: this.props.eventInstance.title,*/}
                    {/*description: this.props.eventInstance*/}
                    {/*.description,*/}
                    {/*startDate: this.props.eventInstance.startDate,*/}
                    {/*endDate:*/}
                    {/*this.props.eventInstance.endDate ||*/}
                    {/*this.props.eventInstance.startDate,*/}
                    {/*status: ContentStatus.PUBLISHED,*/}
                    {/*isDone: this.state.isDone,*/}
                    {/*isFavourite: this.state.isFavourite,*/}
                    {/*responsible: this.props.eventInstance*/}
                    {/*.responsible*/}
                    {/*? this.props.eventInstance.responsible.id*/}
                    {/*: null,*/}
                    {/*reminderDate: this.props.eventInstance*/}
                    {/*.reminderDate,*/}
                    {/*};*/}
                    {/*const temp2: UpdateInstanceStatusVariables = {*/}
                    {/*input: values,*/}
                    {/*};*/}
                    {/*updateInstanceStatus({*/}
                    {/*variables: temp2,*/}
                    {/*});*/}
                    {/*}*/}
                    {/*);*/}
                    {/*} else {*/}
                    {/*alert(*/}
                    {/*intl.formatMessage(commonMessages.noWriteAccess)*/}
                    {/*);*/}
                    {/*}*/}
                    {/*}}*/}
                    {/*/>*/}
                    {/*</StarDiv>*/}
                  </InfoDiv>
                </TaskContainer>
              );
            }}
          </Mutation>
        </>
      </>
    );
  }
}

export const TaskComp = injectIntl(TaskCompComp);
