import * as React from 'react';
import styled, { css } from 'styled-components';
import { IconType } from 'react-icons/lib';
import { IThemeProps, safeInvoke } from '../../common';
import { MunikumIcons } from '../../common/icons';

interface IIconRenderProps {
  isLeftIcon: boolean;
}

const IconContainer = styled.div`
  width: 1.25em;
  height: 1.25em;
  margin-right: ${(props: IIconRenderProps) =>
    props.isLeftIcon ? '.5em' : '0'};
  margin-left: ${(props: IIconRenderProps) =>
    !props.isLeftIcon ? '.5em' : '0'};

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  //background-color: salmon;
`;

const TextWrapper = styled.div`
  color: ${props => props.theme.noAccent.textColor};
  font-family: 'Lato', sans-serif;
  font-size: 1em;
  line-height: 1.5em;
  text-align: left;

  //padding-right: 0.5em;
  //padding-left: 0.5em;

  //background-color: yellow;
  :hover {
    color: ${props => props.theme.noAccent.hoverTextColor};
  }

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  //width: 100%;
`;

interface IItemRow {
  isGroupHeader: boolean;
}

const MenuItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props =>
    props.isGroupHeader ? '#ccc' : props.theme.secondaryContentBackgroundColor};
  font-weight: ${(props: IItemRow) =>
    props.isGroupHeader ? 'bold' : 'normal'};
  color: ${props => (props.isGroupHeader ? '#000' : props.theme.textColor)};
  //margin-bottom: 0px;

  border-radius: 3px;
  padding: 0.25em;

  ${(props: IItemRow) =>
    !props.isGroupHeader &&
    css`
      &:hover {
        background-color: ${props => props.theme.accent1.hoverColor};
        color: ${props => props.theme.accent1.textColor};
        cursor: pointer;
      }
    `}
  ${(props: IItemRow) =>
    props.isGroupHeader &&
    css`
      &:hover {
        cursor: default;
      }
    `}
`;

export interface IMenuItemProps {
  /**
   * text or JSX Element to render in menu element
   */
  text: JSX.Element | string;

  /**
   * leftIcon
   */
  leftIcon?: IconType;

  /**
   * rightIcon
   */
  rightIcon?: IconType;

  disabled?: boolean;

  isGroupHeader?: boolean;

  onClick?: () => void;
  loadingInstances?: boolean;
  isSelected?: boolean;

  /**
   * default true, set to false to hide icon containers
   */
  hideIconContainers?: boolean;

  dataFilterString?: string;
}

interface IMenuItemState {
  isHovering: boolean;
  leftIcon: IconType;
  loadingInstances: boolean;
}

export const IconWrapper = styled.div`
  color: ${(props: {
    isDisabled: boolean;
    isHovering: boolean;
    theme: IThemeProps;
  }) =>
    props.isDisabled
      ? props.theme.textDisabledColor
      : props.isHovering
      ? props.theme.noAccent.hoverTextColor
      : props.theme.textColor};
`;
export const IconAccentColorWrapper = styled.div`
  color: ${(props: { isDisabled: boolean; theme: IThemeProps }) =>
    props.isDisabled ? props.theme.textDisabledColor : 'red'};
`;

export class MenuItem extends React.PureComponent<
  IMenuItemProps,
  IMenuItemState
> {
  constructor(props: IMenuItemProps) {
    super(props);
    this.state = {
      leftIcon: this.props.leftIcon,
      isHovering: false,
      loadingInstances: this.props.loadingInstances,
    };
  }

  UNSAFE_componentWillUpdate(
    nextProps: Readonly<IMenuItemProps>,
    nextState: Readonly<IMenuItemState>,
    nextContext: any
  ): void {
    if (nextProps.loadingInstances !== this.state.loadingInstances) {
      this.setState({
        loadingInstances: nextProps.loadingInstances,
      });
    }
  }

  renderIcon(
    iconComponent: IconType,
    isDisabled: boolean,
    isHovering: boolean
  ) {
    if (iconComponent === undefined || iconComponent === null) {
      return null;
    }

    const Icon = iconComponent;

    return (
      <IconWrapper isDisabled={isDisabled} isHovering={isHovering}>
        <Icon
          width={'1em'}
          height={'1em'}
          // style={{
          //   transition: 'fill .25s ease-in-out, color .25s ease-in-out',
          // }}
        />
      </IconWrapper>
    );
  }

  handleClickDiv = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!this.state.loadingInstances) {
      if (this.state.leftIcon === undefined) {
        this.setState(
          {
            leftIcon: MunikumIcons.LeftCheck,
          },
          () => {}
        );
      } else {
        this.setState(
          {
            leftIcon: undefined,
          },
          () => {}
        );
      }
      safeInvoke(this.props.onClick);
    }
  };

  render() {
    const {
      text,
      rightIcon,
      disabled,
      hideIconContainers,
      isGroupHeader,
    } = this.props;
    return (
      <MenuItemRow
        onClick={this.handleClickDiv}
        onMouseEnter={() => this.setState({ isHovering: true })}
        onMouseLeave={() => this.setState({ isHovering: false })}
        isGroupHeader={isGroupHeader}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            overflow: 'hidden',
            flex: '1 1 auto',
          }}
        >
          {!hideIconContainers && (
            <IconContainer
              isLeftIcon={true}
              style={{ alignSelf: 'center', flex: '0 0 auto' }}
            >
              {this.state.leftIcon &&
                // this.props.isSelected &&
                this.renderIcon(
                  this.state.leftIcon,
                  disabled || false,
                  this.state.isHovering
                )}
            </IconContainer>
          )}
          <TextWrapper style={{ flex: '1 1 auto' }}>{text}</TextWrapper>
        </div>
        {!hideIconContainers && (
          <div>
            {rightIcon && (
              <IconContainer isLeftIcon={false}>
                {this.renderIcon(
                  rightIcon,
                  disabled || false,
                  this.state.isHovering
                )}
              </IconContainer>
            )}
          </div>
        )}
      </MenuItemRow>
    );
  }
}
