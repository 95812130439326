import * as React from 'react';
import styled from 'styled-components';
import { IconType } from 'react-icons/lib';
import { Colors, IDisabledProps, safeInvokeDeprecated } from '../../common';
import { FlexGrid } from '../../layout/FlexGrid/FlexGrid';

const SmallTabContentContainer = styled.div`
  //background-color: lavenderblush;

  margin-top: 0.625em;
`;
export enum TabTheme {
  RED = 'RED',

  BLUE = 'BLUE',
}

interface ISmallTabProps {
  first: boolean; // rounded corners
  last: boolean; // rounded corners
  disabled: boolean; // grey
  isSelected: boolean;

  theme: TabTheme;
}

function getSmallTabRadius(isFirst: boolean, isLast: boolean): string {
  const topLeft = isFirst ? '4px' : '0';
  const topRight = isLast ? '4px' : '0';
  const bottomRight = isLast ? '4px' : '0';
  const bottomLeft = isFirst ? '4px' : '0';
  const s = ' ';
  const temp = ''.concat(topLeft, s, topRight, s, bottomRight, s, bottomLeft);
  return temp;
}

const SmallTab = styled.div`
  min-width: 8.275em; // must be larger then SmallTabTitle min-width
  height: 2.75em;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props: ISmallTabProps) =>
    props.isSelected ? '#00778B' : '#00B0B9'};

  background-color: ${(props: ISmallTabProps) =>
    props.theme === TabTheme.BLUE
      ? props.isSelected
        ? '#00778B'
        : '#00B0B9'
      : props.isSelected
      ? Colors.RED
      : Colors.WHITE};

  transition: background-color 0.25s ease-in-out;

  border-top: 1px solid rgba(208, 211, 212, 0.7);
  border-bottom: 1px solid rgba(208, 211, 212, 0.7);
  border-right: 1px solid rgba(208, 211, 212, 0.7);
  border-left: 0;

  &:nth-child(1) {
    border-left: 1px solid rgba(208, 211, 212, 0.7);
  }

  border-radius: ${(props: ISmallTabProps) =>
    getSmallTabRadius(props.first, props.last)};

  cursor: pointer;

  &:disabled {
    background-color: ${(props: ISmallTabProps) =>
      props.isSelected ? Colors.GREY : Colors.WHITE};
    cursor: not-allowed;
  }

  &:hover:enabled {
  }

  white-space: nowrap;
  overflow: hidden;
`;

const SmallTabTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;

  margin-left: 1.375em;
  margin-right: 1.375em;
  min-width: 6em;

  //background-color: fuchsia;
`;

const SmallTabTitle = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-family: Lato, sans-serif;
  font-size: 0.875em;
  font-weight: bold;
  color: ${(props: ISmallTabProps) =>
    props.theme === TabTheme.BLUE
      ? Colors.WHITE
      : props.isSelected
      ? Colors.WHITE
      : Colors.BLACK};

  //background-color: pink;

  &disabled {
    color: ${(props: ISmallTabProps) =>
      props.isSelected ? Colors.BLACK : Colors.GREYDISABLEDTEXT};
  }
`;

const IconContainer = styled.div`
  border-radius: 50%;
  width: 1.2em;
  height: 1.2em;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  //background-color: orange;
`;

export interface ITabsProps extends IDisabledProps {
  /**
   * Default selected Tab id
   * (only in uncontrolled mode)
   */
  defaultSelectedTabId?: string | number;

  /**
   * Set this to put Tabs component in controlled mode
   */
  selectedTabId?: string | number;

  /**
   * tabs as prop for now, see render func content in tab
   * we could also support Tab components as children, maybe later?
   */
  tabs: Array<ITabProps>;

  theme: TabTheme;
}

export interface ITabsState {
  selectedTabId: string | number;
}

export interface ITabsDispatch {
  onChange?: (selectedId: string | number) => void;
}

export class Tabs extends React.PureComponent<
  ITabsProps & ITabsDispatch,
  ITabsState
> {
  constructor(props: ITabsProps) {
    super(props);

    // // TODO: can we find first chikldren id?
    // const tabs: Array<ITabProps> = [];
    //
    // if (this.props.children !== undefined && React.Children.count(this.props.children) > 0) {
    //   React.Children.map(this.props.children, (child: ReactChild, i: number) => {
    //     // const test1 = child is string;
    //   })
    // }

    // we'll just do render props for now..

    // default selected tab id. if 1 doesnt exist well just render null as content?
    const default2 =
      (props.tabs && props.tabs.length > 0 && props.tabs[0].id) || 1;

    this.state = {
      selectedTabId:
        props.defaultSelectedTabId || props.selectedTabId || default2,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: ITabsProps) {
    const { selectedTabId } = nextProps;

    if (
      selectedTabId !== undefined &&
      selectedTabId !== this.state.selectedTabId
    ) {
      this.setState({
        ...this.state,
        selectedTabId: selectedTabId,
      });
    }
  }

  handleOnClick = (tab: ITabProps, index: number) => {
    // console.log('click tab', tab);
    this.setState(
      {
        ...this.state,
        selectedTabId: tab.id,
      },
      () => {
        safeInvokeDeprecated(this.props.onChange, tab.id);
      }
    );
  };

  renderIcon(tab: ITabProps, isTabSelected: boolean) {
    if (tab.icon === undefined || tab.icon === null) {
      return null;
    }

    const Icon = tab.icon;

    return (
      <IconContainer
        style={{
          marginRight: '.25em',
          marginTop: '-.05em',
        }}
      >
        <Icon
          fill={isTabSelected ? Colors.WHITE : Colors.BLACK}
          style={{
            transition: 'fill .25s ease-in-out, color .25s ease-in-out',
          }}
        />
      </IconContainer>
    );
  }

  render() {
    const { tabs, disabled } = this.props;
    const { selectedTabId } = this.state;

    return (
      <div>
        <FlexGrid
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            margin: '4px 8px ',
          }}
        >
          {tabs.map((tab, index) => {
            const isTabSelected = tab.id === selectedTabId;
            const isTabDisabled = disabled || tab.disabled || false; // ok?

            // console.log('icon', tab.icon);

            return (
              <SmallTab
                theme={this.props.theme}
                key={'smalltab-' + index}
                first={index === 0}
                last={index === tabs.length - 1}
                disabled={isTabDisabled}
                isSelected={isTabSelected}
                onClick={() => this.handleOnClick(tab, index)}
              >
                <SmallTabTitleContainer>
                  {this.renderIcon(tab, isTabSelected)}
                  <SmallTabTitle
                    theme={this.props.theme}
                    first={index === 0}
                    last={index === tabs.length - 1}
                    disabled={isTabDisabled}
                    isSelected={isTabSelected}
                  >
                    {tab.title}
                  </SmallTabTitle>
                </SmallTabTitleContainer>
              </SmallTab>
            );
          })}
        </FlexGrid>
        <SmallTabContentContainer>
          {tabs.map((tab, index) => {
            const isTabSelected = tab.id === selectedTabId;

            return tab.content ? (
              <div
                style={{ display: isTabSelected ? 'block' : 'none' }}
                key={'tab-' + index}
              >
                {tab.content({})}
              </div>
            ) : null;
          })}
        </SmallTabContentContainer>
      </div>
    );
  }
}

export interface ITabRenderProps {}

export type TabRenderFunc = (
  props: ITabRenderProps
) => JSX.Element | null | false;

export interface ITabProps extends IDisabledProps {
  /**
   * tabs id
   */
  id: string | number;

  /**
   * tab title just string ok
   */
  title: string;

  /**
   * Icon from react-icons library, see MunikumIcons enum!
   */
  icon?: IconType;

  /**
   * tabs render func. if undefined, no content will be rendered.
   * children not supported yet
   */
  content?: TabRenderFunc;
}
