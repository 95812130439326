import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { IconType } from 'react-icons/lib';
import { isFunction } from 'util';
import MediaQuery from 'react-responsive';
import { Colors, safeInvokeDeprecated } from '../../components/common';
import { MunikumIcons } from '../../components/common/icons';
import { IContentMeta } from '../../services/models/contentMeta';
import { sizes } from '../../components/common/media';
import { AuthQuery } from '../../auth/AuthQuery';
import {
  authFuncActionValue,
  authFuncCalendar,
  authFuncIsAdmin,
  authFuncTopic,
} from '../../auth';
import { Tooltip } from '../../components/basic/Tooltip/Tooltip';
import { Button } from '../../components/basic';

export interface IMainMenuProps {
  userHash?: string;
  forums?: ReadonlyArray<IContentMeta>;
  isLeftSidebarExpanded: boolean;
  style?: CSSProperties;
}

export interface IMainMenuState {
  isMobileExpanded: boolean;
}

const MainMenuDiv = styled.div`
  background-color: ${Colors.BLACK};
`;

const SidebarMenuMobile = styled.div`
  font-family: Lato, sans-serif;

  @media (max-width: ${sizes.medium}px) {
    max-height: ${(props: { isMobileOpen: boolean }) =>
      props.isMobileOpen ? '725px' : '0px'};
    transition: max-height 0.25s ease-in;
    overflow: hidden;
  }
`;

const SidebarMenuDesktop = styled.div`
  font-family: Lato, sans-serif;
`;

interface INavUl {
  level: number;
}

const NavUl = styled.ul`
  list-style: none;
  font-size: ${(props: INavUl) => 1 - props.level * 0.2}em;
  padding-top: ${(props: INavUl) => (props.level > 0 ? 0.5 : 0)}em;
  padding-left: ${(props: INavUl) =>
    (props.level === 0 ? 2.5 : 0.2) + props.level * 0.8}em;
`;

const NavLi = styled.li`
  padding-top: ${(props: INavUl) => (props.level === 0 ? 0.625 : 0.35)}em;
  padding-bottom: ${(props: INavUl) => (props.level === 0 ? 0.625 : 0.35)}em;
`;

const NavSubUl = styled.ul`
  list-style: none;
  padding-left: 1em; // 15px;
  font-size: 0.875em;
`;

const NavSubLi = styled.li`
  padding-top: 0.35em; // 5px;
  padding-bottom: 0.35em; // 5px;
`;

const NavLink = styled(Link)`
  color: #fff;
  text-decoration: none;
`;

const NavA = styled.a`
  color: #fff;
  text-decoration: none;
`;

const MenuItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props: { isLeftSidebarExpanded: boolean }) =>
    props.isLeftSidebarExpanded ? 'flex-start' : 'flex-end'};
`;

const MobileMenuButtonContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  padding-left: 1.5em;
  padding-bottom: 0.5em;

  @media (min-width: ${sizes.medium}px) {
    display: none;
  }
`;

const ButtContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;

const Circle = styled.div`
  cursor: pointer;
  border-radius: 50%;
  background-color: ${Colors.RED};
  width: 2em;
  height: 2em;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Mobtext = styled.div`
  cursor: pointer;
  font-size: 1em;
  color: white;
  margin-left: 0.5em;
  border-bottom: 1px solid transparent;

  transition: border 0.25s ease-in-out;

  &:hover {
    border-bottom: 1px solid ${Colors.RED};
  }
`;

type IMenuItemRenderFunc = (props: {}) => JSX.Element | null | false;

interface IMenuItemProps {
  /**
   * url to navigate to on click
   */
  url: string;

  /**
   * Icon from react-icons library, see MunikumIcons enum!
   */
  icon?: IconType;

  isLeftSidebarExpanded: boolean;

  /**
   * text to render or intl message
   */
  content: string | IMenuItemRenderFunc;

  /**
   * we could do this different with recursion or HOCs..
   */
  level: number;

  targetBlank?: boolean;

  onClick?: () => void;

  external?: boolean;
}

interface IMenuItemState {
  isHover: boolean;
}

// const MenuItem: React.SFC<IMenuItemProps> = (props: IMenuItemProps) => {
class MenuItem extends React.PureComponent<IMenuItemProps, IMenuItemState> {
  constructor(props: IMenuItemProps) {
    super(props);

    this.state = {
      isHover: false,
    };
  }

  handleMouse = (isHover: boolean) => {
    this.setState({
      isHover: isHover,
    });
  };

  render() {
    const NavIcon = this.props.icon;
    const {
      content,
      url,
      level,
      targetBlank,
      isLeftSidebarExpanded,
      external,
    } = this.props;
    const { isHover } = this.state;

    if (external) {
      return (
        <NavLi
          level={level}
          onClick={(e: any) => safeInvokeDeprecated(this.props.onClick)}
        >
          <NavA
            href={url}
            target={(targetBlank && '_blank') || ''}
            onMouseEnter={() => this.handleMouse(true)}
            onMouseLeave={() => this.handleMouse(false)}
          >
            <MenuItemContainer isLeftSidebarExpanded={isLeftSidebarExpanded}>
              {NavIcon && (
                <Tooltip
                  position={'right'}
                  content={
                    (typeof content !== 'string' &&
                      isFunction(content) &&
                      content({})) ||
                    ''
                  }
                >
                  <div>
                    <NavIcon
                      size={'1.25em'}
                      style={{
                        marginRight: isLeftSidebarExpanded ? '.5em' : '1.5em',
                        transition: 'fill .25s ease-in-out',
                      }}
                      fill={isHover ? Colors.RED : Colors.WHITE}
                    />
                  </div>
                </Tooltip>
              )}
              {isLeftSidebarExpanded && (
                <>
                  {typeof content !== 'string' &&
                    isFunction(content) &&
                    content({})}
                  {typeof content === 'string' && content}
                </>
              )}
            </MenuItemContainer>
          </NavA>
          {this.props.children && (
            <NavUl
              style={{ paddingLeft: isLeftSidebarExpanded ? '2.5em' : 0 }}
              level={level + 1}
            >
              {this.props.children}
            </NavUl>
          )}
        </NavLi>
      );
    }

    return (
      <NavLi
        level={level}
        onClick={(e: any) => safeInvokeDeprecated(this.props.onClick)}
      >
        <NavLink
          to={url}
          target={(targetBlank && '_blank') || ''}
          onMouseEnter={() => this.handleMouse(true)}
          onMouseLeave={() => this.handleMouse(false)}
        >
          <MenuItemContainer isLeftSidebarExpanded={isLeftSidebarExpanded}>
            {NavIcon && (
              <Tooltip
                position={'right'}
                content={
                  (typeof content !== 'string' &&
                    isFunction(content) &&
                    content({})) ||
                  ''
                }
              >
                <div>
                  <NavIcon
                    size={'1.25em'}
                    style={{
                      marginRight: isLeftSidebarExpanded ? '.5em' : '1.5em',
                      transition: 'fill .25s ease-in-out',
                    }}
                    fill={isHover ? Colors.RED : Colors.WHITE}
                  />
                </div>
              </Tooltip>
            )}
            {isLeftSidebarExpanded && (
              <>
                {typeof content !== 'string' &&
                  isFunction(content) &&
                  content({})}
                {typeof content === 'string' && content}
              </>
            )}
          </MenuItemContainer>
        </NavLink>
        {this.props.children && (
          <NavUl
            style={{ paddingLeft: isLeftSidebarExpanded ? '2.5em' : 0 }}
            level={level + 1}
          >
            {this.props.children}
          </NavUl>
        )}
      </NavLi>
    );
  }
}

class MainMenuComp extends React.PureComponent<
  IMainMenuProps & InjectedIntlProps,
  IMainMenuState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      isMobileExpanded: false,
    };
  }

  handleTest = () => {
    // console.log('hnadling it');
    this.setState({
      isMobileExpanded: !this.state.isMobileExpanded,
    });
  };

  render() {
    const { style, isLeftSidebarExpanded } = this.props;

    const mainMenu = (
      <NavUl style={{ paddingLeft: isLeftSidebarExpanded ? '' : 0 }} level={0}>
        <MenuItem
          isLeftSidebarExpanded={isLeftSidebarExpanded}
          icon={MunikumIcons.Home}
          url={'/home'}
          content={() => (
            <>
              <FormattedMessage
                id={'MainLayout.home'}
                defaultMessage={'Home'}
              />
            </>
          )}
          level={0}
        />

        <AuthQuery authFunc={authFuncCalendar}>
          <MenuItem
            isLeftSidebarExpanded={isLeftSidebarExpanded}
            icon={MunikumIcons.YearWheel}
            url={'/annual-cycle/'}
            content={() => (
              <>
                <FormattedMessage
                  id={'MainLayout.annualCycle'}
                  defaultMessage={'Annual Cycle'}
                />
              </>
            )}
            level={0}
          />
        </AuthQuery>

        {/*<AuthQuery authFunc={authFuncDiscussion}>*/}
        {/*  <MenuItem*/}
        {/*    isLeftSidebarExpanded={isLeftSidebarExpanded}*/}
        {/*    icon={MunikumIcons.Discussion}*/}
        {/*    url={'/forum'}*/}
        {/*    content={() => (*/}
        {/*      <FormattedMessage*/}
        {/*        id={'MainLayout.forumLink'}*/}
        {/*        defaultMessage={'Forum'}*/}
        {/*      />*/}
        {/*    )}*/}
        {/*    level={0}*/}
        {/*  />*/}
        {/*</AuthQuery>*/}

        <AuthQuery authFunc={authFuncActionValue}>
          <MenuItem
            isLeftSidebarExpanded={isLeftSidebarExpanded}
            icon={MunikumIcons.ActionValue}
            url={'/action-value'}
            content={() => (
              <FormattedMessage
                id={'MainLayout.actionValueLink'}
                defaultMessage={'Grants'}
              />
            )}
            level={0}
          />
        </AuthQuery>

        <AuthQuery authFunc={authFuncTopic}>
          <MenuItem
            isLeftSidebarExpanded={isLeftSidebarExpanded}
            icon={MunikumIcons.Topic}
            url={'/topic'}
            content={() => (
              <FormattedMessage
                id={'MainLayout.topicLink'}
                defaultMessage={'Groups '}
              />
            )}
            level={0}
          />
        </AuthQuery>

        <MenuItem
          isLeftSidebarExpanded={isLeftSidebarExpanded}
          icon={MunikumIcons.About}
          url={'/about'}
          content={() => (
            <FormattedMessage
              id={'MainLayout.aboutLink'}
              defaultMessage={'About Munikum'}
            />
          )}
          level={0}
        />
        {/*<MenuItem*/}
        {/*  isLeftSidebarExpanded={isLeftSidebarExpanded}*/}
        {/*  icon={MunikumIcons.Help}*/}
        {/*  url={'https://support.munikum.no/knowledge'}*/}
        {/*  external={true}*/}
        {/*  targetBlank={true}*/}
        {/*  content={() => (*/}
        {/*    <FormattedMessage*/}
        {/*      id={'MainLayout.helpLink'}*/}
        {/*      defaultMessage={'Help'}*/}
        {/*    />*/}
        {/*  )}*/}
        {/*  level={0}*/}
        {/*/>*/}
      </NavUl>
    );
    const adminMenu = isLeftSidebarExpanded && (
      <NavUl
        style={{
          paddingLeft: isLeftSidebarExpanded ? '' : 0,
          marginTop: '1.25em',
        }}
        level={0}
      >
        <NavLi level={0}>
          <Button
            text={'Admin'}
            onClick={() => (window.location.href = '/app/admin/')}
          />
        </NavLi>
        <NavLi level={0}>
          <NavLink to="/admin">Admin</NavLink>
          <NavSubUl>
            <NavSubLi>
              <NavLink to="/admin/newGroup">
                <FormattedMessage
                  id={'MainLayout.newGroup'}
                  defaultMessage={'new group'}
                />
              </NavLink>
            </NavSubLi>
            <NavSubLi>
              <NavLink to="/admin/newRelation">
                <FormattedMessage
                  id={'MainLayout.newRelation'}
                  defaultMessage={'new Relation'}
                />
              </NavLink>
            </NavSubLi>
          </NavSubUl>
        </NavLi>
      </NavUl>
    );
    const labsMenu = isLeftSidebarExpanded && (
      <NavUl level={0} style={{ marginTop: '1.25em' }}>
        <MenuItem
          isLeftSidebarExpanded={isLeftSidebarExpanded}
          level={0}
          url={'/labs'}
          icon={MunikumIcons.Labs}
          content={'Munikum Labs'}
        >
          <MenuItem
            level={1}
            url={'/labs/gql1'}
            content={'Gql1'}
            isLeftSidebarExpanded={isLeftSidebarExpanded}
          />
          <MenuItem
            level={1}
            url={'/labs/debug'}
            content={'Testing'}
            isLeftSidebarExpanded={isLeftSidebarExpanded}
          />
          <MenuItem
            level={1}
            url={'/labs/responsive'}
            content={'Responsive'}
            isLeftSidebarExpanded={isLeftSidebarExpanded}
          />

          <MenuItem
            level={1}
            url={'/labs/network'}
            content={'Network'}
            isLeftSidebarExpanded={isLeftSidebarExpanded}
          />
          <MenuItem
            level={1}
            url={'/labs/invite'}
            content={'Invite'}
            isLeftSidebarExpanded={isLeftSidebarExpanded}
          />
          <MenuItem
            isLeftSidebarExpanded={isLeftSidebarExpanded}
            level={1}
            url={'/labs/notification'}
            content={'Notification'}
          />
          <MenuItem
            isLeftSidebarExpanded={isLeftSidebarExpanded}
            level={1}
            url={'/labs/fileupload'}
            icon={MunikumIcons.FileUpload}
            content={'File Upload'}
          />
          <MenuItem
            isLeftSidebarExpanded={isLeftSidebarExpanded}
            level={1}
            url={'/labs/mueditor'}
            content={'MuEditor'}
          />
        </MenuItem>
      </NavUl>
    );

    return (
      <>
        <MediaQuery query={'(max-width: ' + sizes.medium + 'px)'}>
          <MainMenuDiv style={style}>
            <MobileMenuButtonContainer>
              <ButtContainer
                onClick={() => {
                  this.setState({
                    isMobileExpanded: !this.state.isMobileExpanded,
                  });
                  // console.log('hello');
                }}
              >
                <Circle>
                  {this.state.isMobileExpanded && (
                    <MunikumIcons.ArrowDown size={32} fill={'white'} />
                  )}
                  {!this.state.isMobileExpanded && (
                    <MunikumIcons.ArrowRight size={32} fill={'white'} />
                  )}
                </Circle>
                <Mobtext>
                  {this.state.isMobileExpanded ? 'Skjul meny' : 'Vis meny'}
                </Mobtext>
              </ButtContainer>
            </MobileMenuButtonContainer>

            <SidebarMenuMobile isMobileOpen={this.state.isMobileExpanded}>
              {mainMenu}
            </SidebarMenuMobile>
          </MainMenuDiv>
        </MediaQuery>
        <MediaQuery query={'(min-width: ' + sizes.medium + 'px)'}>
          <MainMenuDiv style={style}>
            <SidebarMenuDesktop>
              {mainMenu}
              <AuthQuery authFunc={authFuncIsAdmin}>
                {adminMenu}
                {labsMenu}
              </AuthQuery>
            </SidebarMenuDesktop>
          </MainMenuDiv>
        </MediaQuery>
      </>
    );
  }
}

export const MainMenu = injectIntl(MainMenuComp);
