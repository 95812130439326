import gql from 'graphql-tag';
import Query from 'react-apollo/Query';
import {
  CalendarQuery,
  CalendarVariables,
  GetIncompleteTasksAssignedByMeQuery,
  GetMyUnfinishedTasksQuery,
  GetUpcomingDeadlinesQuery,
  MyCalendarsQuery,
  MyCalendarsVariables,
  YearWheelQuery,
  YearWheelRingVariables,
  YearWheelVariables,
} from '../../models/types';

export const fieldsOnTask = gql`
  fragment fieldsOnTask on Task {
    id
    title
    status
    tags {
      id
      title
      color
    }
  }
`;

export const fieldsOnMiniPerson = gql`
  fragment fieldsOnMiniPerson on Person {
    id
    name
    role
    picture
    organization {
      id
      name
      organizationImage
      url
    }
  }
`;

export const fieldsOnEvent = gql`
  fragment fieldsOnEvent on Event {
    id
    title
    description
    startDate
    reminderDate
    forkedFrom {
      id
      title
      uri
    }
    tags {
      id
      title
      color
    }
    files {
      id
      name
      description
      lastUpdated
      size
      contentType
      access {
        myAccess
      }
    }

    assignedBy {
      department
      ...fieldsOnMiniPerson
    }
    endDate
    responsible {
      ...fieldsOnMiniPerson
    }

    repetitionDefinition
    endRepetitionDate
    startRepetitionDate
    status
    todoTemplate {
      id
      title
      description
      status
      index
    }
    instances {
      id
      title
      forkedFrom {
        id
        title
        uri
      }
      forks {
        id
        title
        event {
          task {
            id
            calendars {
              id
              title
              uri
            }
          }
        }
        tags {
          id
          title
          color
        }
        files {
          id
          name
          description
          lastUpdated
          size
          contentType
          access {
            myAccess
          }
        }
        description
        isDone
        assignedBy {
          department
          ...fieldsOnMiniPerson
        }
        isFavourite
        status
        note
        startDate
        endDate
        responsible {
          ...fieldsOnMiniPerson
        }
        reminderDate
        createdBy {
          ...fieldsOnMiniPerson
        }
        todos {
          id
          title
          description
          descriptionText
          isDone
          status
          parent
          fromTemplate
          note
          index
        }
      }
      tags {
        id
        title
        color
      }
      files {
        id
        name
        description
        lastUpdated
        size
        contentType
        access {
          myAccess
        }
      }
      description
      isDone
      assignedBy {
        department
        ...fieldsOnMiniPerson
      }
      isFavourite
      status
      note
      startDate
      endDate
      responsible {
        ...fieldsOnMiniPerson
      }
      reminderDate
      createdBy {
        ...fieldsOnMiniPerson
      }
      todos {
        id
        title
        description
        descriptionText
        isDone
        status
        parent
        fromTemplate
        note
        index
      }
    }
  }
  ${fieldsOnMiniPerson}
`;

export const fieldsOnEventInstance = gql`
  fragment fieldsOnEventInstance on EventInstance {
    id
    title
    forkedFrom {
      id
      title
      uri
    }
    forks {
      id
      title
      event {
        id
        task {
          id
          calendars {
            id
            title
            uri
          }
        }
      }
      tags {
        id
        title
        color
      }
      files {
        id
        name
        description
        lastUpdated
        size
        contentType
        access {
          myAccess
        }
      }
      description
      isDone
      assignedBy {
        department
        ...fieldsOnMiniPerson
      }
      isFavourite
      status
      note
      startDate
      endDate
      responsible {
        ...fieldsOnMiniPerson
      }
      reminderDate
      createdBy {
        ...fieldsOnMiniPerson
      }
      todos {
        id
        title
        description
        descriptionText
        isDone
        status
        parent
        fromTemplate
        note
        index
      }
    }
    tags {
      id
      title
      color
    }
    files {
      id
      name
      description
      lastUpdated
      size
      contentType
      access {
        myAccess
      }
    }
    description
    isDone
    assignedBy {
      department
      ...fieldsOnMiniPerson
    }
    isFavourite
    status
    note
    startDate
    endDate
    responsible {
      ...fieldsOnMiniPerson
    }
    reminderDate
    createdBy {
      ...fieldsOnMiniPerson
    }
    todos {
      id
      title
      description
      descriptionText
      isDone
      status
      parent
      fromTemplate
      note
      index
    }
  }
  ${fieldsOnMiniPerson}
`;

export const fieldsOnCalendar = gql`
  fragment fieldsOnCalendar on Calendar {
    id
    title
    uri
    description
    category
    color
    status
    lastUpdated
    dateCreated
    isFollowing
    isRestricted
    isLimited
    isLiked
    likes
    access {
      myAccess
      isPrivate
      persons {
        access
        person {
          id
        }
      }
    }
    topics {
      id
      title
      uri
      dateCreated
      isFollowing
      isLimited
      isRestricted
    }
    followers {
      id
    }
    createdBy {
      id
      name
      picture
      role
      organization {
        id
        organizationImage
        name
        url
      }
    }
    forkedFrom {
      id
      title
      uri
      color
    }
    forks {
      id
      title
      uri
      color
    }
  }
`;

export const fieldsOnCalendarLookup = gql`
  fragment fieldsOnCalendarLookup on Calendar {
    id
    title
    description
    color
    access {
      persons {
        access
        person {
          id
        }
      }
    }
    createdBy {
      id
    }
  }
`;

// NOTE: we removed a lot of stuff here!!
// NOTE: calendar same fields as dropdown
const fieldsOnCalendarRing = gql`
  fragment fieldsOnCalendarRing on CalendarRing {
    id
    order
    color
    calendar {
      ...fieldsOnCalendarLookup
    }
  }
  ${fieldsOnCalendarLookup}
`;

// NOTE: removed su bdata here:
export const YEAR_WHEEL = gql`
  query YearWheel($person: ID!) {
    myFollowed(input: { excludeWrite: true }) {
      calendars {
        id
        title
        description
        color
        isFollowing
      }
    }
    me {
      id
      name
      calendarCode
      picture
      isAdmin
      settings {
        showAssignedTasks
        calendarReverseDirection
        calendarElementView
        calendarUseCtrl
        calendarSelectedView
        calendarStartMonth
        calendarHideCompleted
      }
    }
    myCalendars(input: { sort: TITLE, order: ASCENDING }) {
      ...fieldsOnCalendarLookup
    }
    yearWheel(person: $person) {
      ...fieldsOnCalendarRing
    }
    myAssignedTasks {
      ...fieldsOnCalendarRing
    }
  }
  ${fieldsOnCalendarRing}
  ${fieldsOnCalendarLookup}
`;

// seperated into one query per ring/calendar:
export const YEAR_WHEEL_CALENDAR_RING = gql`
  query YearWheelRing($calendarId: ID!, $year: Int, $from: Date, $to: Date) {
    myFollowed {
      tags {
        id
        title
        color
      }
    }
    calendar(id: $calendarId) {
      id
      title
      description
      uri
      category
      color
      status
      lastUpdated
      dateCreated
      isFollowing
      isRestricted
      isLimited
      isLiked
      likes
      access {
        myAccess
        isPrivate
        persons {
          access
          person {
            id
          }
        }
      }
      topics {
        id
        title
        uri
        dateCreated
        isFollowing
        isLimited
        isRestricted
      }
      followers {
        id
      }
      createdBy {
        id
        name
        picture
        role
        organization {
          id
          organizationImage
          name
          url
        }
      }
      forkedFrom {
        id
        title
        uri
      }
      tasks(year: $year, from: $from, to: $to) {
        ...fieldsOnTask
        events(year: $year, from: $from, to: $to) {
          ...fieldsOnEvent
          instances {
            ...fieldsOnEventInstance
          }
        }
      }
    }
  }
  ${fieldsOnEvent}
  ${fieldsOnEventInstance}
  ${fieldsOnTask}
`;

// massive ALL IN ONE query...  (not ASYNC or streaming. . .)
// same problem again...
// So the BEST solution is either:
//  1. Add @client state, add async loading state there, pass everything to reselect. Can use query ABOVE!
//    or
//  2. Use this MASSIVE query, let backend implement streaming response. (slow until backend fixes that)
//    or
//  3. Just add another hack, event, reset local state..
// export const YEAR_WHEEL_ALL_MY_RINGS_DATA = gql`
//   query AllMyRingsData($person: ID!) {
//     yearWheel(person: $person) {
//
//     }
//   }
//
// `;

// DATA query
export const YEAR_WHEEL_MAGIC_RING = gql`
  query YearWheelMagicRing($year: Int, $from: Date, $to: Date) {
    myAssignedTasks(year: $year, from: $from, to: $to) {
      calendar {
        id
        title
        uri
        description
        category
        color
        status
        lastUpdated
        dateCreated
        isFollowing
        isRestricted
        isLimited
        access {
          myAccess
          isPrivate
          persons {
            access
            person {
              id
            }
          }
        }
        createdBy {
          id
          name
          picture
          role
          organization {
            id
            organizationImage
            name
          }
        }
        tasks(year: $year, from: $from, to: $to) {
          ...fieldsOnTask
          events(year: $year, from: $from, to: $to) {
            ...fieldsOnEvent
            instances {
              ...fieldsOnEventInstance
            }
          }
        }
      }
    }
  }
  ${fieldsOnEvent}
  ${fieldsOnEventInstance}
  ${fieldsOnTask}
`;

export const UPDATE_SETTINGS = gql`
  mutation UpdateSettings($input: UpdateMeSettingsInput!) {
    updateSettings(input: $input) {
      id
      calendarCode
      picture
      isAdmin
      settings {
        showAssignedTasks
        calendarReverseDirection
        calendarElementView
        calendarUseCtrl
        calendarStartMonth
        calendarHideCompleted
        calendarSelectedView
      }
    }
  }
`;

export const CREATE_CALENDAR_RING = gql`
  mutation CreateCalendarRingMutation($input: CreateCalendarRingInput!) {
    createCalendarRing(input: $input) {
      ...fieldsOnCalendarRing
    }
  }
  ${fieldsOnCalendarRing}
`;

export const DELETE_CALENDAR_RING = gql`
  mutation DeleteCalendarRingMutation($id: ID!) {
    deleteCalendarRing(id: $id)
  }
`;

export const ARCHIVE_CALENDAR = gql`
  mutation ArchiveCalendarMutation($id: ID!) {
    archiveCalendar(id: $id) {
      id
      status
    }
  }
`;

export const UNARCHIVE_CALENDAR = gql`
  mutation UnArchiveCalendarMutation($id: ID!) {
    unArchiveCalendar(id: $id) {
      id
      status
    }
  }
`;

export class YearWheelQueryComp extends Query<
  YearWheelQuery,
  YearWheelVariables
> {}

export class YearWheelRingQuery extends Query<
  YearWheelRingQuery,
  YearWheelRingVariables
> {}

export const MY_CALENDARS = gql`
  query MyCalendars {
    myCalendars(input: { sort: TITLE, order: ASCENDING }) {
      id
      title
      description
      descriptionText
      color
    }
  }
`;

export class MyCalendarsQueryComp extends Query<
  MyCalendarsQuery,
  MyCalendarsVariables
> {}

export const CALENDAR = gql`
  query Calendar($id: ID!, $year: Int, $tags: [String!]) {
    myFollowed {
      tags {
        id
        title
        color
      }
    }
    me {
      id
      name
      calendarCode
      picture
      isAdmin
      settings {
        calendarHideCompleted
        showAssignedTasks
      }
    }
    yearWheel(person: $id) {
      calendar {
        id
      }
    }
    calendar(id: $id) {
      ...fieldsOnCalendar
      tasks(year: $year, tags: $tags) {
        ...fieldsOnTask
        events(year: $year) {
          ...fieldsOnEvent
          instances {
            ...fieldsOnEventInstance
          }
        }
      }
    }
  }
  ${fieldsOnCalendar}
  ${fieldsOnTask}
  ${fieldsOnEvent}
  ${fieldsOnEventInstance}
`;

export const SEARCH_TAGS = gql`
  query SearchTags($query: String!) {
    search(onlyWrite: false, query: $query) {
      tags {
        id
        title
        color
      }
    }
  }
`;
export const FOLLOW_TAG = gql`
  mutation FollowTag($input: FollowTagInput!) {
    followTag(input: $input) {
      id
      color
      title
    }
  }
`;
export const UNFOLLOW_TAG = gql`
  mutation UnfollowTag($title: String!) {
    unFollowTag(title: $title) {
      id
      title
      color
    }
  }
`;

export const MAKE_SHARABLE = gql`
  mutation MakeSharable($input: MakeSharableInput!) {
    makeSharable(input: $input)
  }
`;
export const REMOVE_SHARABLE = gql`
  mutation RemoveSharable($id: ID!) {
    removeSharable(id: $id)
  }
`;

export class CalendarQueryComp extends Query<
  CalendarQuery,
  CalendarVariables
> {}

export const MY_FOLLOWED_CALENDARS_DROPDOWN = gql`
  query GetMyFollowedCalendarsDropdown {
    myFollowed(input: { excludeWrite: true }) {
      calendars {
        id
        title
        description
        color
        isFollowing
      }
    }
  }
`;

export class GetIncompleteTasksAssignedByMeQueryComp extends Query<
  GetIncompleteTasksAssignedByMeQuery,
  {}
> {}

export const GET_INCOMPLETE_TASKS_ASSIGNED_BY_ME = gql`
  query GetIncompleteTasksAssignedByMe {
    me {
      id
      calendarCode
    }
    incompleteTasksAssignedByMe {
      id
      title
      isFavourite
      isDone
      note
      tags {
        id
        title
        color
      }
      description
      startDate
      endDate
      reminderDate
      createdBy {
        ...fieldsOnMiniPerson
      }
      responsible {
        ...fieldsOnMiniPerson
      }
      event {
        id
        task {
          id
          title
          calendars {
            id
            title
            color
            uri
          }
        }
      }
    }
  }
  ${fieldsOnMiniPerson}
`;

export const GET_UPCOMING_DEADLINES = gql`
  query GetUpcomingDeadlines {
    me {
      id
      calendarCode
    }
    upcomingDeadlines {
      id
      title
      isFavourite
      isDone
      note
      tags {
        id
        title
        color
      }
      description
      startDate
      endDate
      reminderDate
      createdBy {
        ...fieldsOnMiniPerson
      }
      responsible {
        ...fieldsOnMiniPerson
      }
      assignedBy {
        ...fieldsOnMiniPerson
      }
      event {
        id
        task {
          id
          title
          calendars {
            id
            title
            color
            uri
          }
        }
      }
    }
  }
  ${fieldsOnMiniPerson}
`;

export class GetUpcomingDeadlinesQueryComp extends Query<
  GetUpcomingDeadlinesQuery,
  {}
> {}

export const GET_MY_UNFINISHED_TASKS = gql`
  query GetMyUnfinishedTasks {
    me {
      id
      calendarCode
    }
    myUnfinishedTasks {
      id
      title
      isFavourite
      isDone
      note
      tags {
        id
        title
        color
      }
      description
      startDate
      endDate
      reminderDate
      createdBy {
        ...fieldsOnMiniPerson
      }
      responsible {
        ...fieldsOnMiniPerson
      }
      assignedBy {
        ...fieldsOnMiniPerson
      }
      event {
        id
        task {
          id
          title
          calendars {
            id
            title
            color
            uri
          }
        }
      }
    }
  }
  ${fieldsOnMiniPerson}
`;

export class GetMyUnfinishedTasksQueryComp extends Query<
  GetMyUnfinishedTasksQuery,
  {}
> {}
