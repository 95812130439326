import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import {
  BackendLocaleMap,
  localeSelector,
  setLocaleActionCreator,
  SupportedUserLocale,
  SupportedUserLocaleNames,
} from '../../../modules/settings/settings';
import { safeInvokeDeprecated } from '../../common';
import { RootState } from '../../../modules/rootReducer';
import {
  SelectMode,
  UltimateDropdown,
} from '../../basic/DropDownSelectUltimate/UltimateDropdown';
import _ from 'lodash';
import { MenuItem } from '../../basic/Menu';
import { IconBaseProps } from 'react-icons/src/iconBase';
import { commonMessages } from '../commonMessages';
import gql from 'graphql-tag';

interface ILangItem {
  id: string;
  title: string;
  backendKey: string;
}

const FlagDropdown = UltimateDropdown.ofType<ILangItem>();
const no = require('./no.png') as string;
const en = require('./gb.png') as string;

const messages = defineMessages({
  changeLanguage: {
    id: 'languageselector.changelanguage',
    defaultMessage: 'Language settings',
  },
});

export interface ILanguageSelectorProps {
  activeLocale: SupportedUserLocale;
  onChangeLocale?: (locale: SupportedUserLocale) => void;
}

const EnFlag = (): JSX.Element => <img src={en} alt={'en'} />;
const NoFlag = (): JSX.Element => <img src={no} alt={'no'} />;

const myItems = _.keys(SupportedUserLocaleNames).map(key => ({
  id: key,
  title: SupportedUserLocaleNames[key],
  backendKey: BackendLocaleMap[key],
}));

export const GET_LOCALE_QUERY = gql`
  query GetLocale {
    me {
      id
      name
      description
      jobTitle
      department
      language
      kostraFunctions {
        id
      }
    }
  }
`;

export const UPDATE_LOCALE = gql`
  mutation UpdateLocale($input: UpdateMeInput!) {
    updateMe(input: $input) {
      id
      language
    }
  }
`;

class LanguageSelectorComp extends React.PureComponent<
  ILanguageSelectorProps & InjectedIntlProps,
  {}
> {
  render() {
    const { intl, activeLocale } = this.props;

    const mySelected = myItems.filter(c => c.id === activeLocale); // intl.locale.toLowerCase());

    return (
      <FlagDropdown
        label={intl.formatMessage(messages.changeLanguage)}
        defaultText={'Choose your language...'}
        items={myItems}
        selectedItems={mySelected}
        leftIcon={(props: IconBaseProps) => {
          return mySelected.length === 1 && mySelected[0].id === 'en' ? (
            <img src={en} alt={'en'} />
          ) : (
            <img src={no} alt={'no'} />
          );
        }}
        onSelected={(item: ILangItem) => {
          safeInvokeDeprecated(this.props.onChangeLocale, item.id);
        }}
        selectMode={SelectMode.SINGLE}
        canDeselect={false}
        itemRenderFunc={(item, props) => {
          return (
            <MenuItem
              key={props.key}
              onClick={() => {
                props.handleClick();
              }}
              text={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    // width: '12em'
                  }}
                >
                  <div
                    style={
                      {
                        // fontWeight: props.isSelected ? 700 : 500
                      }
                    }
                  >
                    {item.title}
                  </div>
                  <div
                    style={{
                      fontSize: '.75em',
                      color: props.isActive ? '#fff' : 'grey',
                    }}
                  >
                    {props.isSelected
                      ? '(' + intl.formatMessage(commonMessages.selected) + ')'
                      : ''}
                  </div>
                </div>
              }
              leftIcon={item.id === 'en' ? EnFlag : NoFlag}
              // leftIcon={props.isSelected ? MunikumIcons.LeftCheck : undefined}
            />
          );
        }}
      />
    );
  }
}

const mapStateToProps = (
  state: RootState,
  ownProps: {}
): ILanguageSelectorProps => ({
  activeLocale: localeSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<{}>) => ({
  onChangeLocale: (locale: SupportedUserLocale) => {
    dispatch(setLocaleActionCreator({ locale: locale }));

    // TODO: also add MUTATION here to save to Graphql! (or do that in redux module)
  },
});

export const LanguageSelectorLang = injectIntl(LanguageSelectorComp);

/**
 * ConnectedLanguageSelector is a ready to use dropdown connected to redux (settings.locale)
 */
export const LanguageSelector = connect<ILanguageSelectorProps, {}, {}>(
  mapStateToProps,
  mapDispatchToProps
)(LanguageSelectorLang);
