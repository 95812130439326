import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../common';
import { MunikumIcons } from '../../common/icons';
import { IStaticImageType, StaticImage } from '../StaticImage/StaticImage';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import { myHistory } from '../../../index';

// Component is used when hovering images of users

const profileImage = require('./user.svg') as string;

const Cont = styled.div`
  display: flex;
`;
const Name = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-family: Lato, sans-serif;
  color: ${Colors.RED};
  text-align: start;
  margin-left: 0.5em;
  &:hover {
    cursor: pointer;
    text-decoration: underline;

    text-decoration-color: ${Colors.RED};
  }
`;

const SmallTextHover = styled.div`
  font-size: 12px;
  &:hover {
    text-decoration: underline;
    color: ${Colors.RED};
    text-decoration-color: ${Colors.RED};
    cursor: pointer;
  }
  text-align: start;
  display: flex;
  font-family: Lato, sans-serif;
  color: ${Colors.BLACK};
  max-width: 15em;
`;
const SmallText = styled.div`
  font-size: 12px;

  text-align: start;
  display: flex;
  font-family: Lato, sans-serif;
  color: ${Colors.BLACK};
  max-width: 15em;
`;
const RigthDiv = styled.div`
  display: flex;
  //margin-top: .5em;
  padding: 0.5em;
`;

const ImgDiv = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const messages = defineMessages({
  WorkAs: {
    id: 'UserPreview.WorkAs',
    defaultMessage: 'Works as',
  },
});

interface IUserPreviewProps {
  id: string;
  image: any;
  name: string;
  organizationName?: string;
  organizationImage?: string;
  organizationUrl?: string;
  jobTitle: string;
}

function UserPreviewComp(props: IUserPreviewProps & InjectedIntlProps) {
  return (
    <Cont
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <ImgDiv
        style={{ marginLeft: '-.5em' }}
        onClick={() => myHistory.push('/person/' + props.id)}
      >
        <img
          alt={'profile info'}
          style={{}}
          src={props.image ? props.image : profileImage}
          width="120"
          height="120"
        />
      </ImgDiv>
      <div>
        <Name onClick={() => myHistory.push('/person/' + props.id)}>
          {props.name}
        </Name>
        {props.jobTitle && (
          <RigthDiv style={{ marginTop: '1em' }}>
            <MunikumIcons.Job
              style={{
                color: Colors.BLACK,
                marginRight: '.5em',
                width: '.8em',
                height: '.8em',
              }}
            />
            <SmallText>
              {props.intl.formatMessage(messages.WorkAs)} {props.jobTitle}
            </SmallText>
          </RigthDiv>
        )}
        {props.organizationName && props.organizationUrl && (
          <RigthDiv>
            {props.organizationImage && (
              <StaticImage
                filename={props.organizationImage}
                type={IStaticImageType.ORGANIZATION}
                width={16}
                height={16}
              />
            )}
            <SmallTextHover
              style={{ marginLeft: '.5em' }}
              onClick={() =>
                myHistory.push('/organization/' + props.organizationUrl)
              }
            >
              {props.organizationName}
            </SmallTextHover>
          </RigthDiv>
        )}
      </div>
    </Cont>
  );
}
export const UserPreview = injectIntl(UserPreviewComp);
