import { AuthInfo } from './AuthContext';

/***
 * A collection of reusable authorize functions, use in AuthRoute, AuthQuery, or useAuth hook
 */
type AuthFunc = (auth: AuthInfo) => boolean;

export const authFuncActionValue: AuthFunc = (auth: AuthInfo) =>
  auth.isAdmin ||
  (auth.userAuthInfo &&
    auth.userAuthInfo.permissions.indexOf('ACTION_VALUE') > -1) ||
  false;

export const authFuncTopic: AuthFunc = (auth: AuthInfo) =>
  auth.isAdmin ||
  (auth.userAuthInfo && auth.userAuthInfo.permissions.indexOf('TOPIC') > -1) ||
  false;

export const authFuncCalendar: AuthFunc = (auth: AuthInfo) =>
  auth.isAdmin ||
  (auth.userAuthInfo &&
    auth.userAuthInfo.permissions.indexOf('CALENDAR') > -1) ||
  false;

export function authFuncDiscussion(auth: AuthInfo) {
  return (
    auth.isAdmin ||
    (auth.userAuthInfo &&
      auth.userAuthInfo.permissions.indexOf('DISCUSSION') > -1) ||
    false
  );
}

export function authFuncIsAdmin(auth: AuthInfo) {
  return auth.isAdmin;
}

export function authFuncNever(auth: AuthInfo) {
  return false;
}

// TODO temp remove
export function authFuncCalendarTimeline(auth: AuthInfo) {
  return (
    auth.isAdmin ||
    (auth.userAuthInfo &&
      auth.userAuthInfo.permissions.indexOf('CALENDAR_TIMELINE') > -1) ||
    false
  );
}

// TODO temp remove
export function authFuncCalendarShare(auth: AuthInfo) {
  return (
    auth.isAdmin ||
    (auth.userAuthInfo &&
      auth.userAuthInfo.permissions.indexOf('CALENDAR_SHARE') > -1) ||
    false
  );
}
