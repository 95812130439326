import styled from 'styled-components';
import { sizes } from '../../common/media';

const isDebugMode =
  window.isDebugMode !== undefined ? window.isDebugMode : false;

/**
 * Outer FlexGrid element, white background and rounded corners
 * @type {StyledComponentClass<JSX.IntrinsicElements["div"], any, JSX.IntrinsicElements["div"]>}
 */
export const FlexGrid = styled.div`
  border-radius: 3px;
  background-color: ${props => props.theme.contentBackgroundColor};
  color: ${props => props.theme.textColor};
  max-width: 84.125em;
  margin: 0 auto;
  box-sizing: border-box;

  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1); // ok?
`;

/**
 * FlexGridRow, grey border on top, 50% grid padding
 * @type {StyledComponentClass<JSX.IntrinsicElements["div"], any, JSX.IntrinsicElements["div"]>}
 */
export const FlexGridRow = styled.div`
  ${(props: { topBorder: boolean }) =>
    (props.topBorder && 'border-top: 2px solid rgba(208,211,212,0.2);') || ''};

  // 50% padding on outer box, margin on items inside
  padding: 0.875em;

  // IE11 Fix
  min-width: 1px;
`;

/**
 * FlexGridRowContainer is a default flex container with align-items: flex-start and justify-content: space-between.
 * you can also use your own flex container in stead of this.
 * @type {StyledComponentClass<JSX.IntrinsicElements["div"], any, JSX.IntrinsicElements["div"]>}
 */
export const FlexGridRowContainer = styled.div`
  display: block;

  @media (min-width: ${sizes.medium}px) {
    display: flex;
    align-items: flex-start; // center or flex-start <=== vertical align
    justify-content: space-between; // ok as default?
  }

  //background-color: pink;
`;

/**
 *
 * @type {StyledComponentClass<JSX.IntrinsicElements["div"], any, JSX.IntrinsicElements["div"]>}
 */
export const FlexGridRowContainerCentered = styled(FlexGridRowContainer)`
  @media (min-width: ${sizes.medium}px) {
    align-items: center;
  }
`;

/**
 * FlexGridItem, 50% margin to create grid gutter
 * set flex prop yourself, example:
 * - first column:   1 1 .875em
 * - second column:  1 1 0px
 * - third column:   2 2 0px
 *
 * first column need flex-basis .875 to fix gaps..
 * other columns need 0 and px unit to fix IE11 bug
 * @type {StyledComponentClass<JSX.IntrinsicElements["div"], any, JSX.IntrinsicElements["div"]>}
 */
export const FlexGridItem = styled.div`
  background-color: ${isDebugMode ? 'skyblue' : 'transparent'};
  margin: 0.875em;

  overflow-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;
`;

/**
 * FlexGridItemTop, only left and right margin, not top and bottom. For smaller stuff / inforow
 */
export const FlexGridItemTop = styled.div`
  background-color: ${isDebugMode ? 'skyblue' : 'transparent'};
  margin: 0 0.875em;
`;
