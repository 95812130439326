import * as React from 'react';
import { CSSProperties, RefObject } from 'react';
import {
  Manager,
  Popper,
  PopperChildrenProps,
  Reference,
  ReferenceChildrenProps,
} from 'react-popper';
import styled from 'styled-components';
import { createPortal } from 'react-dom';
import { Colors, isFunction, IThemeProps } from '../../common';

/**
 * This box's position is controlled by Popper.js!
 *
 * TODO later:
 * - add better animation with scale and keyframes for "popping" feeling...
 * - maybe also set display:none when animation is finished? easier to inspect elements
 *
 *   //  animation: ${fadeIn} 1s cubic-bezier(0.51, 0.23, 0.02, 0.96) paused, ${fadeOut} 1s cubic-bezier(0.51, 0.23, 0.02, 0.96) paused;
 *   // opacity: ${(props: IPopoverRenderProps) => props.isOpen ? '1' : '0'};
 */
const PopoverPositionBox = styled.div`
  // cant transition transform since this is the box's position :p
  // transition: opacity .3s ease-in-out, transform .3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  opacity: ${(props: IPopoverRenderProps) => (props.isOpen ? '1' : '0')};
  // note: we dont transition this.. remove / replace with proper animation
  transform: ${(props: IPopoverRenderProps) =>
    props.isOpen ? 'scale(1)' : 'scale(0)'};
  background-color: ${(props: IPopoverRenderProps) =>
    props.theme.secondaryContentBackgroundColor};
  pointer-events: ${(props: IPopoverRenderProps) =>
    props.isOpen ? 'auto' : 'none'};

  // this creates a new stacking context starting here:
  position: relative;
  z-index: 15;
`;
//
// animation-name: ${popIn};
// animation-duration: 1s;
// animation-timing-function: cubic-bezier(0.51, 0.23, 0.02, 0.96);
// animation-fill-mode: forwards;
// animation-iteration-count: 1;
// animation-direction: normal;
// animation-introduceWheel-state: ${(props: IPopoverRenderProps) => props.isOpen ? 'running' : 'paused'};

const PopoverInnerPositionBox = styled.div`
  position: relative;

  // This fixes BOX positioning (but not arrow position)
  top: ${(props: IPopoverRenderProps) =>
    props.placement === 'top' ||
    props.placement === 'top-start' ||
    props.placement === 'top-end'
      ? '-.5em'
      : props.placement === 'bottom' ||
        props.placement === 'bottom-start' ||
        props.placement === 'bottom-end'
      ? '.5em'
      : '0'};
  left: ${(props: IPopoverRenderProps) =>
    props.placement === 'left'
      ? '-.5em'
      : props.placement === 'right'
      ? '.5em'
      : '0'};

  pointer-events: ${(props: IPopoverRenderProps) =>
    props.isOpen ? 'auto' : 'none'};
`;

interface IPopoverRenderProps {
  placement: Placement;
  backgroundColor?: string;
  foregroundColor?: string;
  isOpen: boolean;
  theme?: IThemeProps;
}

const PopoverArrowTop = styled.div`
  width: 1em;
  height: 1em;
  position: absolute;
  //z-index: 32; // TODO: remove to fix shadow?

  /* pseudo element rendering the arrow which is a box rotated 45 deg */
  &::after {
    display: inline-block;

    background-color: ${props => props.theme.secondaryContentBackgroundColor};

    position: absolute;
    content: ' ';

    width: 1em;
    height: 1em;

    transform: rotate(45deg) scale(0.6);
    z-index: 35;
  }

  &::before {
    display: inline-block;

    position: absolute;
    content: ' ';
    width: 1em;
    height: 1em;

    transform: rotate(45deg) scale(0.6);

    z-index: -1; // this is relative to stacking context created in above div!

    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2),
      0 8px 24px rgba(16, 22, 26, 0.2);
  }
`;

const Pop = styled.div`
  border-radius: 4px;
  background-color: ${props => props.theme.secondaryContentBackgroundColor};
  color: #333;

  padding: 1.25em; // can be overridden by consumer
  
  //box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.4);
  //none|h-offset v-offset blur spread color |inset|initial|inherit;
  box-shadow: 0 0 0 1px rgba(16,22,26,.1), 0 2px 4px rgba(16,22,26,.2), 0 8px 24px rgba(16,22,26,.2);
  z-index: 30;
  
  
  
  /* transform: ${(props: IPopoverRenderProps) =>
    props.isOpen ? 'scale(1)' : 'scale(0)'};
  transition: transform .3s ease-in-out; */
`;

export type Placement =
  | 'auto-start'
  | 'auto'
  | 'auto-end'
  | 'top-start'
  | 'top'
  | 'top-end'
  | 'right-start'
  | 'right'
  | 'right-end'
  | 'bottom-end'
  | 'bottom'
  | 'bottom-start'
  | 'left-end'
  | 'left'
  | 'left-start';

export interface IPopoverProps {
  /**
   * render function for target.
   * Return the Button or similar you want to render.
   * Remember to set your Button's ref (or innerRef if it's a styled component) = ref
   * @param {RefHandler} ref
   * @returns {string | JSX.Element}
   */
  renderTarget?: (ref: any) => JSX.Element;

  /**
   * a target element, we will fix ref for you =) No strings allowed, wrap in span please
   */
  target?: JSX.Element; // | string;

  /**
   * popper position
   */
  position?: Placement;

  /**
   * is popover initially open (only if you use internal comp state, not controlled mode isOpen!)
   */
  isDefaultOpen?: boolean;

  /**
   * put in controlled mode set isOpen explicit
   */
  isOpen?: boolean;

  /**
   * render arrow? (not tested)
   */
  showArrow?: boolean;

  /**
   * CSS style applied to Pop box
   */
  style?: React.CSSProperties;

  /**
   * CSS style applied to arrow
   */
  arrowStyle?: React.CSSProperties;

  /**
   * color as string (needed this to apply css to arrow pseudo element)
   */
  backgroundColor?: string;

  /**
   * color as string
   */
  foregroundColor?: string;

  /**
   * set to true to render debug placement info
   */
  showDebugInfo?: boolean;

  /**
   * enable this to force update on popper-js position on EACH RENDER
   * don't use this unless you really have to, rendering will be twice as slow...
   */
  isAggressiveUpdateModeEnabled?: boolean;

  /**
   * hide popover when target is ouside users visible area? deafault = true!
   */
  hideWhenTargetIsOutOfBounds?: boolean;

  /**
   * Set this to true if you have trouble with z-index, hidden overflow, etc.
   * note: Dont set this to true if you have a lot of popovers in a list, IE11 wil kill you!
   */
  usePortal?: boolean;

  /**
   * set to true if you have a Popover inside a static scrollable div and have overflow or positioning issues. . .
   */
  useStaticHack?: boolean;
}

export interface IPopoverState {
  isOpen: boolean;
}
//
// export const ForwardPopover = React.forwardRef<Popover>((props, ref) => {
//   return <Popover {...props} ref={ref} />;
// });

/**
 * Popover component
 * Future improvements:
 * - clamp arrow position >5 and < box-5
 * - animation on isOpen change
 * - shadow on arrow?
 */
export class Popover extends React.PureComponent<IPopoverProps, IPopoverState> {
  public static defaultProps: IPopoverProps = {
    position: 'auto',
    isDefaultOpen: false,
    backgroundColor: Colors.WHITE,
    foregroundColor: Colors.BLACK,
    hideWhenTargetIsOutOfBounds: true,
    usePortal: false,
  };

  // old style ref:
  private readonly portalRoot: HTMLElement;

  // 16.3 style ref:
  private readonly targetRef: RefObject<HTMLElement>;

  constructor(props: IPopoverProps) {
    super(props);

    this.targetRef = React.createRef();

    this.state = {
      isOpen: this.props.isDefaultOpen || this.props.isOpen || false,
    };

    this.portalRoot = document.getElementById('portal-root') as HTMLElement;
    if (this.portalRoot === null) {
      const pr = document.createElement('div');
      pr.setAttribute('id', 'portal-root');
      document.body.appendChild(pr);
      this.portalRoot = pr;
      // console.log('root appended', pr, this.portalRoot);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: IPopoverProps) {
    if (
      nextProps.isOpen !== undefined &&
      nextProps.isOpen !== this.state.isOpen
    ) {
      this.setState({
        ...this.state,
        isOpen: nextProps.isOpen,
      });
    }
  }

  limitArrowPlacement = (
    pp: CSSProperties,
    placement: Placement
  ): React.CSSProperties => {
    // console.log('øøøø', pp, placement);

    // TODO: fix this.. I want to limit min and max x and y position!

    if (placement !== undefined) {
      if (placement.indexOf('bottom') > -1 || placement.indexOf('top') > -1) {
        if (pp.left !== undefined && pp.left < 10) {
          return Object.assign(pp, { left: 10 });
        }
      }
    }

    return pp;
  };

  getRemainingArrowPlacement = (placement: Placement): React.CSSProperties => {
    if (placement === undefined || placement === null) {
      return {};
    } else {
      // console.log('getRemaining: ' + placement);

      if (placement === 'left') {
        return {
          right: '0',
        };
      } else if (placement === 'right') {
        return {
          left: '0',
        };
      } else if (placement === 'top') {
        return {
          bottom: '0',
        };
      } else if (placement === 'bottom') {
        return {
          top: '0',
        };
      } else if (placement === 'bottom-start') {
        return {
          top: '0',
        };
      } else if (placement === 'top-start') {
        return {
          bottom: '0',
        };
      } else if (placement === 'bottom-end') {
        return {
          top: '0',
        };
      } else if (placement === 'top-end') {
        return {
          bottom: '0',
        };
      } else {
        console.warn('placement not implemented: ' + placement);
      }
    }

    return {};
  };

  // NOTE: this did NOT work...
  sanitizePosition = (position: any) => {
    if (position !== undefined && position !== null) {
      let spos = Object.assign({}, position); // shallow copy..

      if (spos.hasOwnProperty('top') && spos.top === '') {
        spos.top = '0';
      }
      if (spos.hasOwnProperty('left') && spos.left === '') {
        spos.left = '0';
      }
      if (spos.hasOwnProperty('right') && spos.right === '') {
        spos.right = '0';
      }
      if (spos.hasOwnProperty('bottom') && spos.bottom === '') {
        spos.bottom = '0';
      }
      return spos;
    }
  };

  render() {
    const {
      target,
      renderTarget,
      position,
      style,
      arrowStyle,
      showDebugInfo,
      backgroundColor,
      foregroundColor,
      isAggressiveUpdateModeEnabled,
      hideWhenTargetIsOutOfBounds,
      usePortal,
    } = this.props;

    const { isOpen } = this.state;

    const myArrowBaseStyle = arrowStyle || {};

    // console.log('render Popover');

    const myMods = this.props.useStaticHack
      ? {
          preventOverflow: {
            escapeWithReference: true,
          },
        }
      : {};

    const popper = (
      <Popper
        modifiers={myMods}
        placement={position}
        children={(props: PopperChildrenProps) => {
          const isPopoverVisible = hideWhenTargetIsOutOfBounds
            ? props.outOfBoundaries
              ? false
              : isOpen
            : isOpen;
          //
          // console.log(
          //   'render Popper.popper.children:::' +
          //     isOpen +
          //     ', ' +
          //     this.props.isOpen +
          //     ', ' +
          //     isPopoverVisible
          // );

          let pp = this.getRemainingArrowPlacement(props.placement);

          this.sanitizePosition(props.arrowProps.style);

          let calculatedArrowStyle = Object.assign(
            {},
            props.arrowProps.style,
            pp,
            // sanitizedPosition,
            myArrowBaseStyle
          );

          // console.log('PP: ' + props.placement + '.. POPPER,PP_REST: ', props.arrowProps.style, pp);

          // calculatedArrowStyle = this.limitArrowPlacement(calculatedArrowStyle, props.placement);

          // console.log('pp', pp, calculatedArrowStyle);

          // Slider component needs this, don't use it if you don't have to...
          if (isAggressiveUpdateModeEnabled) {
            props.scheduleUpdate();
          }

          return (
            <PopoverPositionBox
              ref={props.ref}
              style={props.style} // <=== this sets position using absolute!
              data-placement={props.placement}
              placement={props.placement}
              isOpen={isPopoverVisible}
            >
              <PopoverInnerPositionBox
                placement={props.placement}
                isOpen={isPopoverVisible}
              >
                <Pop
                  style={style}
                  placement={props.placement}
                  backgroundColor={backgroundColor}
                  foregroundColor={foregroundColor}
                  isOpen={isPopoverVisible}
                >
                  {this.props.children}
                  {showDebugInfo && (
                    <div
                      style={{
                        fontSize: '.5em',
                        lineHeight: '1em',
                      }}
                    >
                      <br />
                      Placement: {props.placement} <br />
                      outOfBoundaries: {props.outOfBoundaries ? 'Y' : 'N'}{' '}
                      <br />
                      POP_ARROW: {JSON.stringify(props.arrowProps.style)}
                      <br />
                      PP_PP: {JSON.stringify(pp)}
                      <br />
                      CALC_arrow: {JSON.stringify(calculatedArrowStyle)}
                      <br />
                    </div>
                  )}
                </Pop>
              </PopoverInnerPositionBox>
              <PopoverArrowTop
                ref={props.arrowProps.ref}
                style={calculatedArrowStyle}
              />
            </PopoverPositionBox>
          );
        }}
      />
    );

    return (
      <Manager>
        <Reference
          children={(props: ReferenceChildrenProps) => {
            // console.log('render Popover.children.ref');
            // let tempTarget: JSX.Element;
            let targetToRender: JSX.Element;

            if (
              renderTarget !== undefined &&
              renderTarget !== null &&
              isFunction(renderTarget)
            ) {
              targetToRender = renderTarget(props.ref); // setting ref here, could be innerRef !!
            } else if (target !== undefined && target !== null) {
              targetToRender = React.cloneElement(target, {
                ref: props.ref,
              });
            } else {
              throw Error(
                'you have to set either renderTarget or target in Popover!'
              );
            }

            return targetToRender;
          }}
        />

        {/* NOTE: dummy hack fixes IE11 popopvers temporarily, but we'll get issues if we want to animate popover entering and leaving.. ?*/}

        {isOpen ? (
          usePortal ? (
            createPortal(popper, this.portalRoot)
          ) : (
            <React.Fragment>{popper}</React.Fragment>
          )
        ) : (
          <div style={{ display: 'none' }}>dummy</div>
        )}
      </Manager>
    );
  }
}
