import * as React from 'react';
import {
  GET_MY_UNFINISHED_TASKS,
  GetMyUnfinishedTasksQueryComp,
} from '../../containers/AnnualCycle/calendar-queries';
import { SuspenseLoader } from '../basic/Loader/CircleSpinner';
import { TaskComp2 } from './TaskComp2';
import { myHistory } from '../../index';

export class MyUnfinishedTasks extends React.PureComponent {
  render() {
    return (
      <div style={{ width: '100%' }}>
        <h1>Mine uferdige oppgåver</h1>
        <GetMyUnfinishedTasksQueryComp
          query={GET_MY_UNFINISHED_TASKS}
          variables={{}}
          fetchPolicy={'cache-and-network'}
        >
          {({ loading, error, data, refetch, networkStatus }) => {
            if (error) {
              throw error;
            }
            if (loading || networkStatus === 4) {
              return <SuspenseLoader />;
            }
            if (!data) {
              return <div />;
            }

            return data.myUnfinishedTasks.map((value, index) => {
              return (
                <div
                  key={index}
                  style={{
                    height: '36px',
                    marginTop: '10px',
                  }}
                >
                  <TaskComp2
                    canWrite={true}
                    calendarCode={data.me.calendarCode}
                    checkBoxColor={value.event.task.calendars[0].color}
                    profileToShow={value.assignedBy}
                    assignedToMe={true}
                    me={data.me}
                    eventInstance={{
                      id: value.id,
                      title: value.title,
                      isFavourite: value.isFavourite,
                      isDone: value.isDone,
                      note: value.note,
                      tags: value.tags,
                      description: value.description,

                      startDate: value.startDate,
                      endDate: value.endDate,
                      reminderDate: value.reminderDate,

                      createdBy: value.createdBy,
                      responsible: value.responsible,

                      taskId: value.event.task.id,
                      taskCalendarId: value.event.task.calendars[0].id,
                      taskTitle: value.event.task.title,
                      taskCalendarTitle: value.event.task.calendars[0].title,

                      // color: value.event.task.calendars[0].color,
                      // year: 2021,
                      // status: value.status,
                    }}
                    onClick={() => {
                      myHistory.push(
                        '/calendar/' + value.event.task.calendars[0].uri
                      );
                    }}
                    // onCompleted={(id, isDone: boolean) => {
                    //   console.log(id);
                    // }}
                    // checkBoxColor={g.color}
                    style={{
                      // width: '100%',
                      // height: '36px',
                      marginLeft: '10px',
                      marginRight: '10px',
                    }}
                  />
                </div>
              );
            });
          }}
        </GetMyUnfinishedTasksQueryComp>
      </div>
    );
  }
}
