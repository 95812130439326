import { CALENDAR_SHARE_HOST } from '../../index';
import * as React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';

interface ICalendarShareEmbedPreviewProps {
  uri: string;
}

interface ICalendarShareEmbedPreviewState {
  template: string;
  year: number;
  startMonth: number;
  month: number;
}

class CalendarShareEmbedPreviewClass extends React.PureComponent<
  ICalendarShareEmbedPreviewProps & InjectedIntlProps,
  ICalendarShareEmbedPreviewState
> {
  constructor(props: ICalendarShareEmbedPreviewProps & InjectedIntlProps) {
    super(props);
    this.state = {
      template: 'munikum',
      year: new Date().getFullYear(),
      startMonth: 1,
      month: 0,
    };
  }

  render() {
    let uri = this.props.uri;
    let { template, year, startMonth, month } = this.state;

    let fullUri = CALENDAR_SHARE_HOST + uri + '?template=' + template;

    if (year !== new Date().getFullYear()) {
      fullUri += '&year=' + year;
    }

    if (startMonth !== 1) {
      fullUri += '&start_month=' + startMonth;
    }

    if (month !== 0) {
      fullUri += '&month=' + month;
    }

    let embedCode =
      '<div style="overflow: hidden; padding-top: 100%; position: relative;"><iframe src="' +
      fullUri +
      '" style="border: 0; height: 100%; left: 0; position: absolute; top: 0; width: 100%;" scrolling="no" allowfullscreen></iframe></div>';

    return (
      <div>
        <h1>Embed preview</h1>
        Template:
        <select
          onChange={e => {
            this.setState({ template: e.target.value });
          }}
        >
          <option value="munikum">munikum</option>
          <option value="calendar">calendar</option>
          <option value="simple">simple</option>
          <option value="embed">embed</option>
        </select>
        Year:
        <select
          onChange={e => {
            this.setState({ year: +e.target.value });
          }}
        >
          <option value="2020">2020</option>
          <option value="2019">2019</option>
          <option value="2021">2021</option>
        </select>
        Start month:
        <select
          onChange={e => {
            this.setState({ startMonth: +e.target.value });
          }}
        >
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>
        </select>
        Month:
        <select
          onChange={e => {
            this.setState({ month: +e.target.value });
          }}
        >
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>
        </select>
        <textarea
          style={{
            backgroundColor: '#fff',
            color: '#000',
            borderRadius: '3px',
            padding: '5px',
            width: '90%',
          }}
          value={embedCode}
        />
        <div
          style={{
            overflow: 'hidden',
            paddingTop: '100%',
            position: 'relative',
          }}
        >
          <iframe
            title="Embed preview"
            src={fullUri}
            style={{
              border: 0,
              height: '100%',
              left: 0,
              position: 'absolute',
              top: 0,
              width: '100%',
            }}
            scrolling="false"
            allowFullScreen
          />
        </div>
      </div>
    );
  }
}

export const CalendarShareEmbedPreview = injectIntl(
  CalendarShareEmbedPreviewClass
);
