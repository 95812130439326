import { InjectedIntlProps, injectIntl } from 'react-intl';
import * as React from 'react';
import { FieldArray, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Option } from 'react-select';
import gql from 'graphql-tag';
import Query from 'react-apollo/Query';
import Mutation from 'react-apollo/Mutation';
import * as _ from 'lodash';
import { DropDownSelect } from '../basic/DropDownSelect/DropDownSelect';

import {
  Container,
  Icons,
  LeftContainer,
  PieContainer,
  RightContainer,
} from './CreateAnnualCycleForm';
import { myApolloClient } from '../../graphql/apolloClientFactory';
import {
  AccessEnum,
  CalendarCategoryEnum,
  ContentStatus,
  ContentVisibilityEnum,
  CreateCalendarRingMutationMutation,
  CreateCalendarRingMutationVariables,
  DeleteCalendarRingMutationVariables,
  FieldsOnKostraFunctionFragment,
  GetAnnualCycleQueryQuery,
  GetAnnualCycleQueryVariables,
  OrganizationAccessInput,
  OrganizationGroupAccessInput,
  Person,
  PersonGroupAccessInput,
  RelationQueryQuery,
  RelationQueryVariables,
  UpdateCalendarInput,
  UpdateCalendarVariables,
} from '../../models/types';
import { MunikumIcons } from '../common/icons';
import { FormLabel } from '../basic/FormLabel/FormLabel';
import {
  ARCHIVE_CALENDAR,
  CREATE_CALENDAR_RING,
  DELETE_CALENDAR_RING,
  YEAR_WHEEL,
} from '../../containers/AnnualCycle/calendar-queries';
import {
  IStaticImageType,
  StaticImage,
} from '../basic/StaticImage/StaticImage';
import {
  Colors,
  ColorTheme,
  getNamesAndValues,
  safeInvokeDeprecated,
} from '../common';
import { Button, ButtonSize, ButtonTheme } from '../basic';
import { ContentBox } from '../layout/ContentGrid/ContentBox';
import { commonMessages } from '../language/commonMessages';

import {
  CreateSharableUriCard,
  MembersOfAnnualCycleCard,
  MembersOfAnnualCycleCardEveryoneComp,
  typeToList,
} from './AnnualCycleMembers';
import { messages } from './messages';
import { ToastType } from '../basic/Toaster/Toast';
import { CopyAnnualCycle } from './copyAnnualCycle';
import { metaMessages } from '../../services/models/contentMeta';
import { ColorPicker } from './ColorPicker';
import { Pie } from './Pie';
import {
  AnnualCycleAddMembersCard,
  SmallText,
} from './AnnualCycleAddMembersCard';
import {
  MathiasDropDown,
  tagsPositionEnum,
} from '../basic/DropDownSelect/MathiasDropDown';
import { SEARCH_QUERY_RELATION } from '../common/newRelationComp';
import { MuContentEditable } from '../basic/MuEditor/MuContentEditable';
import { commonLabels } from '../language/commonLabels';
import { PageTitle } from '../layout/Page/Page';
import ToggleButton from '../basic/ToggleButton/ToggleButton';
import { KostraFunctionDropDown } from '../actionValue/KostraFunctionDropDown';
import { fieldsOnKostraFunction } from '../actionValue/kostraQueries';
import { Tooltip } from '../basic/Tooltip/Tooltip';
import { DisabledTextField } from '../topics/TopicForm';
import { ExternalLink } from '../basic/Structural/links';
import { MuTextField } from '../basic/MuEditor/MuTextField';
import { convertFromRaw } from 'draft-js';
import { H4 } from '../basic/Structural/typography';
import { CleanDialog, CloseWarningOption } from '../basic/Dialog/CleanDialog';
import { Toaster } from '../basic/Toaster/Toaster';
import { CalendarShareEmbedPreview } from './CalendarShareEmbedPreview';

// const uuidv1 = require('uuid/v1');
// eslint-disable-next-line
let permissionOptions: string = '';
interface IEditAnnualCycleFormProps {
  onCancel?: () => void;
  userHash?: string;
  onSaveSuccess?: () => void;
  id: string;
  onDeleteSuccesful?: () => void;
  // handleEditAnnualCycleSave: (values: UpdateCalendarInput) => void;
  year: number;
}

interface IEditAnnualCycleFormState {
  showError: boolean;
  showSuccess: boolean;
  categoryKey: string;
  showShareError: boolean;
  isEditTagOpen: boolean;
  currentMembers: Array<Person>;
  isCopyYearWheelOpen: boolean;
  membersToSend: Array<any>;
  year: number;
  currentKostraFunctions?: ReadonlyArray<FieldsOnKostraFunctionFragment>;
}

export const FIELDS_ON_PERSON = gql`
  fragment fieldsOnPerson on Person {
    id
    name
    role
    picture
    organization {
      id
      name
      organizationImage
      url
    }
    department
  }
`;
const GET_ANNUAL_CYCLE_QUERY = gql`
  query GetAnnualCycleQuery($id: ID!) {
    myFollowed {
      tags {
        id
        title
        color
      }
    }
    calendar(id: $id) {
      id
      shareableUri
      category
      tags {
        id
        title
        color
      }
      topics {
        title
        id
      }
      kostraFunctions {
        ...FieldsOnKostraFunction
      }
      actionValues {
        title
        id
      }
      discussions {
        title
        id
      }
      access {
        isPrivate
        myAccess
        visibility
        groups {
          group {
            id
            title
            createdBy {
              id
              name
            }
            dateCreated
            lastUpdated
            members {
              name
              id
            }
          }
          access
        }
        organizationGroups {
          access
          organizationGroup {
            createdBy {
              id
              name
            }
            id
            lastUpdated
            title
            members {
              name
              id
            }
          }
        }
        organizations {
          access
          organization {
            name
            id
          }
        }
        persons {
          access
          person {
            name
            id
            department
            picture
            role
            organization {
              id
              name
              organizationImage
            }
          }
        }
      }

      title
      description
      descriptionText
      color
      createdBy {
        ...fieldsOnPerson
      }
      status
    }
    me {
      id
      isAdmin
      calendarCode
    }
  }

  ${FIELDS_ON_PERSON}
  ${fieldsOnKostraFunction}
`;
export const SEARCH_TOPIC = gql`
  query SearchTopic($query: String!) {
    search(query: $query) {
      topics {
        id
        title
      }
    }
  }
`;
const UPDATE_CALENDAR = gql`
  mutation UpdateCalendar($input: UpdateCalendarInput!) {
    updateCalendar(input: $input) {
      id
      access {
        isPrivate
        persons {
          access
          person {
            ...fieldsOnPerson
          }
        }
      }
      title
      description
      color
      createdBy {
        ...fieldsOnPerson
      }
      status
    }
  }
  ${FIELDS_ON_PERSON}
`;

export const ADD_TO_RELATED = gql`
  mutation AddToRelated($input: AddToRelatedInput!) {
    addToRelated(input: $input) {
      id
      success
    }
  }
`;

export const REMOVE_RELATED = gql`
  mutation RemoveRelated($input: RemoveFromRelatedInput!) {
    removeFromRelated(input: $input) {
      id
      success
    }
  }
`;

export const COPY_CALENDAR = gql`
  mutation CopyCalendarMutation($input: CopyCalendarInput!) {
    copyCalendar(input: $input)
  }
`;

export const FORK_CALENDAR = gql`
  mutation ForkCalendarMutation($input: CopyCalendarInput!) {
    forkCalendar(input: $input)
  }
`;

class AnnualCycleQuery extends Query<
  GetAnnualCycleQueryQuery,
  GetAnnualCycleQueryVariables
> {}

interface IEditFormProps {
  updateForm: UpdateCalendarInput;
  topicItems: Array<any>;
}

class EditAnnualCycleFormComp extends React.PureComponent<
  IEditAnnualCycleFormProps & InjectedIntlProps,
  IEditAnnualCycleFormState
> {
  statusList: { [id: string]: string };

  private toastRef: React.RefObject<any> = null;

  constructor(props: IEditAnnualCycleFormProps & InjectedIntlProps) {
    super(props);
    this.toastRef = React.createRef();
    this.state = {
      categoryKey: '0',
      showError: false,
      showShareError: false,
      year: props.year || new Date().getFullYear(),
      currentMembers: [],
      isEditTagOpen: false,
      showSuccess: false,
      isCopyYearWheelOpen: false,
      membersToSend: [],
      currentKostraFunctions: undefined, // NOTE: undefined on purpose =)
    };
    const contentStatuses: { [id: string]: string } = {};
    getNamesAndValues(ContentStatus).forEach(x => {
      contentStatuses[x.value] = props.intl.formatMessage(metaMessages[x.name]);
    });
    delete contentStatuses.TO_APPROVAL;

    this.statusList = contentStatuses;
  }

  // handleRemoveMember = (person: IMemberPerson) => {
  //   const updatedMembers = this.state.currentMembers.slice();
  //   const tempIndex = updatedMembers.findIndex(p => p.id === person.id);
  //   updatedMembers.splice(tempIndex, 1);
  //   this.setState({
  //     currentMembers: updatedMembers,
  //
  //   });
  // };
  // handleCompleted = (data: GetAnnualCycleQuery.Query ) => {
  //   console.log('he');
  //   const members = data.calendar && data.calendar.access.map((v) => {
  //     return v.person;
  //   });
  //   if(members) {
  //     this.setState({
  //       currentMembers: members,
  //     });
  //   }
  //
  // };
  checkIfArrayHasReadMembers = formik => {
    for (let i = 0; i < formik.updateForm.access.persons.length; i++) {
      if (formik.updateForm.access.persons[i].access === AccessEnum.READ) {
        return true;
      }
    }
    for (let i = 0; i < formik.updateForm.access.groups.length; i++) {
      if (formik.updateForm.access.groups[i].access === AccessEnum.READ) {
        return true;
      }
    }
    for (
      let i = 0;
      i < formik.updateForm.access.organizationGroups.length;
      i++
    ) {
      if (
        formik.updateForm.access.organizationGroups[i].access ===
        AccessEnum.READ
      ) {
        return true;
      }
    }
    for (let i = 0; i < formik.updateForm.access.organizations.length; i++) {
      if (
        formik.updateForm.access.organizations[i].access === AccessEnum.READ
      ) {
        return true;
      }
    }
  };

  deleteFromList = obj => {
    for (let i = 0; i < obj.length; i++) {
      if (obj[i].access === AccessEnum.READ) {
        obj.splice(i, 1);
        i--;
      }
    }
  };

  render() {
    const { intl } = this.props;
    delete this.statusList.OBSOLETE;
    let categoryKeys = Object.keys(CalendarCategoryEnum);
    let categoryArray: Array<{ key: number; value: string }> = [];
    for (let i = 0; i < categoryKeys.length; i++) {
      categoryArray.push({
        key: i,
        value: intl.formatMessage({
          id: 'CalendarListPage.' + categoryKeys[i].toLocaleLowerCase(),
          defaultMessage: categoryKeys[i].toLocaleLowerCase(),
        }),
      });
    }
    return (
      <ContentBox style={{ padding: '0' }}>
        <AnnualCycleQuery
          fetchPolicy={'cache-and-network'}
          query={GET_ANNUAL_CYCLE_QUERY}
          variables={{ id: this.props.id }}
          // GetAnnualCycleQueryQuery
          children={(result: any) => {
            if (result.error) {
              return <div>error {result.error.message}</div>;
            } else if (result.loading) {
              return '';
            } else if (result.data === undefined) {
              return <div>no data</div>;
            }
            const me = result.data.me;
            const title =
              result.data && result.data.calendar && result.data.calendar.title;
            const description =
              result.data &&
              result.data.calendar &&
              result.data.calendar.description;
            // const tags = result?.data?.calendar?.tags;
            const currentColor =
              result.data && result.data.calendar && result.data.calendar.color;

            const createdBy =
              result.data &&
              result.data.calendar &&
              result.data.calendar.createdBy;

            const members =
              result.data &&
              result.data.calendar &&
              result.data.calendar.access;
            const category =
              result.data &&
              result.data.calendar &&
              result.data.calendar.category;

            const oldRelations = [];
            // eslint-disable-next-line
            const topicRelations =
              result.data &&
              result.data.calendar &&
              result.data.calendar.topics.forEach(c => {
                oldRelations.push({ label: c.title, value: c.id });
              });
            const olRelationsClone = _.cloneDeep(
              oldRelations.map(c => +c.value)
            );
            let isDescriptionDraftJsFormat = false;
            try {
              convertFromRaw(JSON.parse(description)).getPlainText();
              isDescriptionDraftJsFormat = true;
            } catch (e) {
              isDescriptionDraftJsFormat = false;
            }
            const dataKostras =
              (result.data &&
                result.data.calendar &&
                result.data.calendar.kostraFunctions) ||
              [];
            // eslint-disable-next-line
            const access =
              result.data &&
              result.data.calendar &&
              result.data.calendar.access;

            let doIhaveAccess = false;
            if (
              result.data &&
              result.data.calendar &&
              result.data.calendar.access &&
              result.data.calendar.access.myAccess === AccessEnum.WRITE
            ) {
              doIhaveAccess = true;
            }

            const uri = result?.data?.calendar?.shareableUri ?? '';
            return (
              <div>
                <Toaster ref={this.toastRef} theme={ToastType.LOADING} />
                <Mutation
                  mutation={UPDATE_CALENDAR}
                  refetchQueries={[
                    {
                      query: YEAR_WHEEL,
                      variables: {
                        year: this.props.year,
                        person: this.props.userHash,
                      },
                    },
                  ]}
                  onCompleted={res => console.log('main mut complete')}
                >
                  {(updateCalendar, { data, loading, called, error }) => (
                    <Formik
                      validateOnBlur={true}
                      validationSchema={Yup.object().shape({
                        updateForm: Yup.object().shape({
                          title: Yup.string()
                            .label('title')
                            .required(),
                          color: Yup.string()
                            .label('color')
                            .required(),
                        }),
                      })}
                      onSubmit={async (values: IEditFormProps) => {
                        // console.log(values, 'values top');
                        try {
                          // if (values.updateForm.tags.length > 0) {
                          //   values.updateForm.tags.forEach((c: any) => {
                          //     delete c.id;
                          //     delete c.__typename;
                          //     if (c.color === Colors.GREY) {
                          //       c.color = null;
                          //     }
                          //   });
                          // }
                          await myApolloClient.mutate<
                            CreateCalendarRingMutationMutation,
                            CreateCalendarRingMutationVariables
                          >({
                            mutation: CREATE_CALENDAR_RING,
                            variables: {
                              input: {
                                calendar: values.updateForm.id,
                                color: values.updateForm.color,
                                order: +values.updateForm.id,
                              },
                            },
                          });
                          // console.log('create ring ok');
                        } catch (err) {}

                        // 2. update calendar:
                        try {
                          const temp = _.cloneDeep(values.updateForm);
                          const accessToSend: any = {
                            persons: [],
                            groups: [],
                            organizations: [],
                            organizationGroups: [],
                          };
                          if (temp.access.persons.length > 0) {
                            temp.access.persons.forEach((p: any) => {
                              accessToSend.persons.push({
                                access: p.access,
                                person: p.person.id,
                              });
                            });
                          }
                          if (temp.access.groups.length > 0) {
                            temp.access.groups.forEach(
                              (g: PersonGroupAccessInput) => {
                                accessToSend.groups.push({
                                  access: g.access,
                                  group: g.group.id,
                                });
                              }
                            );
                          }
                          if (temp.access.organizations.length > 0) {
                            temp.access.organizations.forEach(
                              (o: OrganizationAccessInput) => {
                                accessToSend.organizations.push({
                                  access: o.access,
                                  organization: o.organization.id,
                                });
                              }
                            );
                          }
                          if (temp.access.organizationGroups.length > 0) {
                            temp.access.organizationGroups.forEach(
                              (org: OrganizationGroupAccessInput) => {
                                accessToSend.organizationGroups.push({
                                  access: org.access,
                                  organizationGroup: org.organizationGroup.id,
                                });
                              }
                            );
                          }

                          // TODO: works? TODO Seems like calendar wont update because it cant read map of undefined(only happens when i dont add another kostra function)
                          if (this.state.currentKostraFunctions) {
                            temp.kostraFunctions = this.state.currentKostraFunctions.map(
                              f => f.id
                            );
                          }

                          temp.access.persons = accessToSend.persons;
                          temp.access.groups = accessToSend.groups;
                          temp.access.organizations =
                            accessToSend.organizations;
                          temp.access.organizationGroups =
                            accessToSend.organizationGroups;

                          const temp2: UpdateCalendarVariables = {
                            input: temp,
                          };
                          // console.log(temp, 'haleluja');

                          await updateCalendar({
                            variables: temp2,
                          });
                          // console.log('updated calendar OK!', res2);
                        } catch (err) {
                          console.error('error updating calendar', err);
                        }
                        const addRelations: Array<number> = [];
                        const removeRelations: Array<number> = [];

                        const oldRelationsNumber = olRelationsClone;
                        const newRelationsNumber = values.topicItems.map(
                          c => +c.value
                        );

                        oldRelationsNumber.forEach(c => {
                          if (newRelationsNumber.indexOf(c) === -1) {
                            removeRelations.push(c);
                          }
                        });

                        newRelationsNumber.forEach(c => {
                          if (oldRelationsNumber.indexOf(c) === -1) {
                            addRelations.push(c);
                          }
                        });

                        if (removeRelations.length > 0) {
                          await myApolloClient.mutate({
                            mutation: REMOVE_RELATED,
                            variables: {
                              input: {
                                meta: +values.updateForm.id,
                                metasToDelete: removeRelations,
                              },
                            },
                          });
                        }

                        if (addRelations.length > 0) {
                          await myApolloClient.mutate({
                            mutation: ADD_TO_RELATED,
                            variables: {
                              input: {
                                meta: +values.updateForm.id,
                                metasToAdd: addRelations,
                              },
                            },
                          });
                        }

                        // 3. invoke onSuccess
                        safeInvokeDeprecated(this.props.onSaveSuccess);
                      }}
                      initialValues={{
                        updateForm: {
                          id: this.props.id,
                          // tags: tags,
                          category: category,
                          title: title || '',
                          access: {
                            visibility: members.visibility,
                            isPrivate: members.isPrivate,
                            persons: members.persons,
                            groups: members.groups,
                            organizations: members.organizations,
                            organizationGroups: members.organizationGroups,
                          },
                          description: description || '',
                          descriptionText: description
                            ? isDescriptionDraftJsFormat
                              ? convertFromRaw(
                                  JSON.parse(description)
                                ).getPlainText()
                              : description
                            : '',
                          color: currentColor || '',
                          status: ContentStatus.PUBLISHED,
                        },
                        topicItems: oldRelations,
                      }}
                      render={(formikProps: FormikProps<any>) => {
                        // console.log(formikProps.values.updateForm, 'formik');
                        // console.log(doIhaveAccess, 'access');
                        return (
                          <form onSubmit={formikProps.handleSubmit}>
                            <Container>
                              <LeftContainer className={'container_div_id'}>
                                <div>
                                  {/*<div*/}
                                  {/*style={{*/}
                                  {/*display: 'flex',*/}
                                  {/*alignItems: 'center',*/}
                                  {/*fontFamily: 'Lato, sans-serif',*/}
                                  {/*marginTop: '-2em',*/}
                                  {/*marginBottom: '1em',*/}
                                  {/*}}*/}
                                  {/*>*/}
                                  {/*<Checkbox*/}
                                  {/*// style={{marginTop: '-2em'}}*/}
                                  {/*checked={*/}
                                  {/*formikProps.values.updateForm.isPublic*/}
                                  {/*}*/}
                                  {/*onClick={() => {*/}
                                  {/*formikProps.setFieldValue(*/}
                                  {/*'updateForm.isPublic',*/}
                                  {/*!formikProps.values.updateForm*/}
                                  {/*.isPublic*/}
                                  {/*);*/}
                                  {/*}}*/}
                                  {/*/>*/}
                                  {/*{intl.formatMessage(*/}
                                  {/*commonMessages.isYearWheelPublic*/}
                                  {/*)}*/}
                                  {/*</div>*/}
                                  {!doIhaveAccess && (
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginBottom: '.5em',
                                        marginTop: '-1em',
                                      }}
                                    >
                                      <MunikumIcons.warning
                                        style={{
                                          color: Colors.RED,
                                          marginRight: '.2em',
                                        }}
                                      />
                                      <SmallText
                                        style={{
                                          fontSize: '12px',
                                          color: Colors.YELLOW,
                                          marginLeft: '0em',
                                        }}
                                      >
                                        {intl.formatMessage(
                                          commonMessages.noWriteAccessCalendar
                                        )}
                                      </SmallText>
                                    </div>
                                  )}
                                  <FormLabel>
                                    {intl.formatMessage(commonLabels.title)}
                                  </FormLabel>
                                  <PageTitle>
                                    <MuContentEditable
                                      id={'updateForm.title'}
                                      name={'updateForm.title'}
                                      canEdit={doIhaveAccess} // TODO: add access rights here.. disable if your user cannot edit!
                                      isMultiLine={false}
                                      value={
                                        formikProps.values.updateForm &&
                                        formikProps.values.updateForm.title
                                      }
                                      onSubmit={(md: string) => {
                                        // remove enter keys
                                        let text = md.replace(
                                          /(\r\n|\n|\r)/gm,
                                          ''
                                        );
                                        formikProps.setFieldValue(
                                          'updateForm.title',
                                          text
                                        );
                                      }}
                                      style={{
                                        // minWidth: '6em',
                                        width: '100%',
                                      }}
                                      placeholder={intl.formatMessage(
                                        commonLabels.titlePlaceholder
                                      )}
                                    />
                                  </PageTitle>
                                  <div>
                                    {/*<div*/}
                                    {/*  style={{*/}
                                    {/*    display: 'flex',*/}
                                    {/*    marginTop: '.75em',*/}
                                    {/*    marginBottom: '1em',*/}
                                    {/*  }}*/}
                                    {/*>*/}
                                    {/*  <TopicOption*/}
                                    {/*    onClick={() => {*/}
                                    {/*      if (doIhaveAccess) {*/}
                                    {/*        formikProps.setFieldValue(*/}
                                    {/*          'updateForm.category',*/}
                                    {/*          CalendarCategoryEnum.TOPIC*/}
                                    {/*        );*/}
                                    {/*      }*/}
                                    {/*    }}*/}
                                    {/*    style={{*/}
                                    {/*      backgroundColor:*/}
                                    {/*        formikProps.values.updateForm*/}
                                    {/*          .category ===*/}
                                    {/*        CalendarCategoryEnum.ORGANIZATION*/}
                                    {/*          ? '#fff'*/}
                                    {/*          : '#00778B',*/}
                                    {/*      color:*/}
                                    {/*        formikProps.values.updateForm*/}
                                    {/*          .category ===*/}
                                    {/*        CalendarCategoryEnum.TOPIC*/}
                                    {/*          ? '#fff'*/}
                                    {/*          : 'rgba(51, 51, 51, 0.4)',*/}
                                    {/*    }}*/}
                                    {/*  >*/}
                                    {/*    Temaårshjul*/}
                                    {/*  </TopicOption>*/}
                                    {/*  <OrganzationOption*/}
                                    {/*    onClick={() => {*/}
                                    {/*      if (doIhaveAccess) {*/}
                                    {/*        formikProps.setFieldValue(*/}
                                    {/*          'updateForm.category',*/}
                                    {/*          CalendarCategoryEnum.ORGANIZATION*/}
                                    {/*        );*/}
                                    {/*      }*/}
                                    {/*    }}*/}
                                    {/*    style={{*/}
                                    {/*      backgroundColor:*/}
                                    {/*        formikProps.values.updateForm*/}
                                    {/*          .category ===*/}
                                    {/*        CalendarCategoryEnum.ORGANIZATION*/}
                                    {/*          ? '#F5A623'*/}
                                    {/*          : '#fff',*/}
                                    {/*      color:*/}
                                    {/*        formikProps.values.updateForm*/}
                                    {/*          .category ===*/}
                                    {/*        CalendarCategoryEnum.ORGANIZATION*/}
                                    {/*          ? '#fff'*/}
                                    {/*          : 'rgba(51, 51, 51, 0.4)',*/}
                                    {/*    }}*/}
                                    {/*  >*/}
                                    {/*    Organisasjonsårshjul*/}
                                    {/*  </OrganzationOption>*/}
                                    {/*</div>*/}

                                    {/*<Title style={{ marginBottom: '.75em' }}>*/}
                                    {/*<MuContentEditable*/}
                                    {/*rawMarkdown={formikProps.values.title}*/}
                                    {/*canEdit={true}*/}
                                    {/*onChange={(markdown: string) => {*/}
                                    {/*formikProps.setFieldValue(*/}
                                    {/*'title',*/}
                                    {/*markdown*/}
                                    {/*);*/}
                                    {/*}}*/}
                                    {/*onSubmit={(markdown: string) => {*/}
                                    {/*formikProps.setFieldValue(*/}
                                    {/*'title',*/}
                                    {/*markdown*/}
                                    {/*);*/}
                                    {/*}}*/}
                                    {/*/>*/}
                                    {/*</Title>*/}

                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        marginTop: '.5em',
                                      }}
                                    >
                                      <div style={{ flex: '0 0 auto' }}>
                                        <ColorPicker
                                          disabled={!doIhaveAccess}
                                          label={intl.formatMessage(
                                            commonMessages.chooseColor
                                          )}
                                          selectedColor={
                                            formikProps.values.updateForm &&
                                            formikProps.values.updateForm.color
                                          }
                                          onChange={(color: string) => {
                                            if (doIhaveAccess) {
                                              formikProps.setFieldValue(
                                                'updateForm.color',
                                                color
                                              );
                                            }
                                          }}
                                        />
                                      </div>

                                      <PieContainer
                                        style={{ flex: '1 1 10em' }}
                                      >
                                        <div
                                          style={{
                                            width: '10.5em',
                                            height: '10.5em',
                                          }}
                                        >
                                          <Pie
                                            color={
                                              formikProps.values.updateForm &&
                                              formikProps.values.updateForm
                                                .color
                                            }
                                          />
                                        </div>
                                      </PieContainer>
                                    </div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        // justifyContent: 'space-between',
                                        marginBottom: '1em',
                                      }}
                                    >
                                      <Button
                                        leftIcon={MunikumIcons.copy}
                                        text={intl.formatMessage(
                                          messages.copyYearWheel
                                        )}
                                        size={ButtonSize.Medium}
                                        theme={ColorTheme.White}
                                        onClick={() => {
                                          this.setState({
                                            isCopyYearWheelOpen: true,
                                          });
                                        }}
                                      />
                                      {result.data.calendar.id &&
                                        result.data.me.calendarCode && (
                                          <div
                                            style={{
                                              marginLeft: '8px',
                                              display: 'flex',
                                            }}
                                          >
                                            <Tooltip
                                              content={intl.formatMessage(
                                                messages.ExportToolTip
                                              )}
                                            >
                                              <a
                                                href={
                                                  (process &&
                                                    process.env &&
                                                    process.env.REACT_APP_API) +
                                                  '/meta/export?calendarCode=' +
                                                  result.data.me.calendarCode +
                                                  '&metaId=' +
                                                  result.data.calendar.id
                                                }
                                              >
                                                <Button
                                                  size={ButtonSize.Medium}
                                                  theme={ColorTheme.White}
                                                  leftIcon={MunikumIcons.Export}
                                                  text={intl.formatMessage(
                                                    messages.Export
                                                  )}
                                                />
                                              </a>
                                            </Tooltip>

                                            <span
                                              style={{ marginLeft: '8px' }}
                                            />
                                            <Tooltip
                                              content={intl.formatMessage(
                                                messages.SubscribeToolTip
                                              )}
                                            >
                                              <a
                                                href={
                                                  (process &&
                                                    process.env &&
                                                    process.env.REACT_APP_API.replace(
                                                      'https://',
                                                      'webcal://'
                                                    ).replace(
                                                      'http://',
                                                      'webcal://'
                                                    )) +
                                                  '/meta/calendar?calendarCode=' +
                                                  result.data.me.calendarCode +
                                                  '&metaId=' +
                                                  result.data.calendar.id
                                                }
                                              >
                                                <Button
                                                  size={ButtonSize.Medium}
                                                  leftIcon={
                                                    MunikumIcons.Subscribe
                                                  }
                                                  text={intl.formatMessage(
                                                    messages.Subscribe
                                                  )}
                                                  theme={ColorTheme.White}
                                                />
                                              </a>
                                            </Tooltip>
                                          </div>
                                        )}
                                    </div>

                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        marginBottom: '.5em',
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          flexDirection: 'column',
                                          width: '100%',
                                        }}
                                      >
                                        <MathiasDropDown
                                          disabled={!doIhaveAccess}
                                          label={intl.formatMessage(
                                            messages.createRelation
                                          )}
                                          style={{
                                            width: '98%',
                                          }}
                                          tagsPosition={tagsPositionEnum.INSIDE}
                                          selectedItems={
                                            formikProps.values.topicItems
                                          }
                                          onChange={(value: Array<Option>) => {
                                            formikProps.setFieldValue(
                                              'topicItems',
                                              value
                                            );
                                          }}
                                          loadItems={(
                                            input: string,
                                            preloadedItems: Array<
                                              Option<string>
                                            >
                                          ): Promise<Array<Option<string>>> => {
                                            return new Promise(
                                              async (resolve, reject) => {
                                                const items: Array<Option<
                                                  string
                                                >> = [];
                                                try {
                                                  const res = await myApolloClient.query<
                                                    RelationQueryQuery,
                                                    RelationQueryVariables
                                                  >({
                                                    query: SEARCH_QUERY_RELATION,
                                                    variables: {
                                                      query: input,
                                                      onlyWrite: true,
                                                    },
                                                    fetchPolicy: 'network-only',
                                                  });

                                                  res.data.search.topics.forEach(
                                                    (topic, index) => {
                                                      const temp: Option<string> = {
                                                        label: topic.title,
                                                        value: topic.id,
                                                      };
                                                      items.push(temp);
                                                    }
                                                  );
                                                  resolve(items);
                                                } catch (e) {
                                                  console.error('Fail', e);
                                                  reject(e);
                                                }
                                              }
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                      }}
                                    >
                                      <DropDownSelect
                                        label={intl.formatMessage(
                                          commonLabels.category
                                        )}
                                        name={'category'}
                                        onChangeSelected={e => {
                                          switch (e) {
                                            case '0':
                                              formikProps.setFieldValue(
                                                'updateForm.category',
                                                CalendarCategoryEnum.TOPIC
                                              );
                                              this.setState({
                                                categoryKey: e,
                                              });
                                              break;
                                            case '1':
                                              formikProps.setFieldValue(
                                                'updateForm.category',
                                                CalendarCategoryEnum.ORGANIZATION
                                              );
                                              this.setState({
                                                categoryKey: e,
                                              });
                                              break;
                                            case '2':
                                              formikProps.setFieldValue(
                                                'updateForm.category',
                                                CalendarCategoryEnum.PROJECT
                                              );
                                              this.setState({
                                                categoryKey: e,
                                              });
                                              break;
                                            case '3':
                                              formikProps.setFieldValue(
                                                'updateForm.category',
                                                CalendarCategoryEnum.GRANT
                                              );
                                              this.setState({
                                                categoryKey: e,
                                              });
                                              break;
                                            default:
                                              this.setState({
                                                categoryKey: '0',
                                              });
                                              formikProps.setFieldValue(
                                                'updateForm.category',
                                                CalendarCategoryEnum.TOPIC
                                              );
                                          }
                                        }}
                                        showEmptyOption={false}
                                        value={
                                          formikProps.values.updateForm
                                            .category ===
                                          CalendarCategoryEnum.TOPIC
                                            ? '0'
                                            : formikProps.values.updateForm
                                                .category ===
                                              CalendarCategoryEnum.ORGANIZATION
                                            ? '1'
                                            : formikProps.values.updateForm
                                                .category ===
                                              CalendarCategoryEnum.PROJECT
                                            ? '2'
                                            : '3'
                                        }
                                        items={categoryArray}
                                        accessor={'value'}
                                        fieldGroupStyle={{
                                          width: '216px',
                                          marginRight: '8px',
                                        }}
                                      />
                                      <KostraFunctionDropDown
                                        disabled={!doIhaveAccess}
                                        style={{
                                          width: '20em',
                                          marginTop: '-.1em',
                                        }}
                                        fieldGroupStyle={{ width: '216px' }}
                                        selectedItems={
                                          this.state.currentKostraFunctions ||
                                          dataKostras
                                        }
                                        onSelectedItemsChanged={selectedItems => {
                                          formikProps.setFieldValue(
                                            'kostraFunctions',
                                            selectedItems.map(f => f.id)
                                          );
                                          this.setState({
                                            currentKostraFunctions: selectedItems,
                                          });
                                        }}
                                      />
                                      {/*<div style={{ width: '213px' }}>*/}
                                      {/*  <SmallTitle*/}
                                      {/*    style={{ paddingBottom: '.3em' }}*/}
                                      {/*  >*/}
                                      {/*    {'Merkelapp'}*/}
                                      {/*  </SmallTitle>{' '}*/}
                                      {/*  <MyFollowedTagsContext.Consumer>*/}
                                      {/*    {myFollowedTags2 => (*/}
                                      {/*      <TagsDropdown*/}
                                      {/*        myTags={[]}*/}
                                      {/*        onSelectedItemsChanged={selectedItems => {*/}
                                      {/*          formikProps.setFieldValue(*/}
                                      {/*            'updateForm.tags',*/}
                                      {/*            selectedItems*/}
                                      {/*          );*/}
                                      {/*        }}*/}
                                      {/*        followedTags={*/}
                                      {/*          myFollowedTags2?.map(c => {*/}
                                      {/*            const id = uuidv1();*/}
                                      {/*            return {*/}
                                      {/*              id: id,*/}
                                      {/*              title: c.title,*/}
                                      {/*              color:*/}
                                      {/*                c?.color ?? Colors.GREY,*/}
                                      {/*            };*/}
                                      {/*          }) ?? []*/}
                                      {/*        }*/}
                                      {/*        selectedItems={formikProps.values.updateForm.tags.map(*/}
                                      {/*          c => {*/}
                                      {/*            const id = uuidv1();*/}
                                      {/*            return {*/}
                                      {/*              title: c.title,*/}
                                      {/*              color:*/}
                                      {/*                c?.color ?? Colors.GREY,*/}
                                      {/*              id: id,*/}
                                      {/*            };*/}
                                      {/*          }*/}
                                      {/*        )}*/}
                                      {/*      />*/}
                                      {/*    )}*/}
                                      {/*  </MyFollowedTagsContext.Consumer>*/}
                                      {/*</div>*/}
                                    </div>
                                    <div style={{ marginBottom: '2em' }}>
                                      <MuTextField
                                        shouldEditorHaveFiles={false}
                                        doIHaveAccessToAddFiles={true}
                                        label={intl.formatMessage(
                                          commonMessages.description
                                        )}
                                        placeholder={intl.formatMessage(
                                          commonMessages.writeHerePlaceholder
                                        )}
                                        onSubmit={value => {
                                          formikProps.setFieldValue(
                                            'updateForm.description',
                                            value
                                          );
                                          formikProps.setFieldValue(
                                            'updateForm.descriptionText',
                                            convertFromRaw(
                                              JSON.parse(value)
                                            ).getPlainText()
                                          );
                                        }}
                                        value={
                                          formikProps.values.updateForm &&
                                          formikProps.values.updateForm
                                            .description
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </LeftContainer>
                              <RightContainer>
                                <div
                                  style={{
                                    minWidth: '450px', // IE 11 fix / hack
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      marginBottom:
                                        formikProps.values.updateForm.access
                                          .isPrivate === false
                                          ? '1em'
                                          : '0em',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <H4
                                      style={{
                                        fontWeight: 'bold',
                                        marginRight: '1em',
                                      }}
                                    >
                                      {intl.formatMessage(
                                        commonMessages.Access
                                      )}
                                    </H4>
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Icons
                                        isChecked={
                                          !formikProps.values.updateForm.access
                                            .isPrivate
                                        }
                                      >
                                        <MunikumIcons.Globe
                                          style={{
                                            height: '1.2em',
                                            width: '1.2em',
                                          }}
                                        />
                                      </Icons>
                                      <Tooltip
                                        position={'top-start'}
                                        content={
                                          formikProps.values.updateForm.access
                                            .isPrivate
                                            ? intl.formatMessage(
                                                commonMessages.makeYearWheelPublic
                                              )
                                            : intl.formatMessage(
                                                commonMessages.makeYearWheelPrivate
                                              )
                                        }
                                      >
                                        <div>
                                          <ToggleButton
                                            style={{
                                              marginLeft: '.5em',
                                              marginRight: '.3em',
                                              marginTop: '.5em',
                                            }}
                                            checked={
                                              formikProps.values.updateForm
                                                .access.isPrivate
                                            }
                                            onClick={() => {
                                              if (doIhaveAccess) {
                                                const hasReadAccess = this.checkIfArrayHasReadMembers(
                                                  formikProps.values
                                                );
                                                if (
                                                  formikProps.values.updateForm
                                                    .access.isPrivate
                                                ) {
                                                  if (hasReadAccess) {
                                                    if (
                                                      window.confirm(
                                                        intl.formatMessage(
                                                          commonMessages.MakeYearWheelPublicWarning
                                                        )
                                                      )
                                                    ) {
                                                      formikProps.setFieldValue(
                                                        'updateForm.access.isPrivate',
                                                        !formikProps.values
                                                          .updateForm.access
                                                          .isPrivate
                                                      );
                                                      const personCopy = _.cloneDeep(
                                                        formikProps.values
                                                          .updateForm.access
                                                          .persons
                                                      );
                                                      const personGroupCopy = _.cloneDeep(
                                                        formikProps.values
                                                          .updateForm.access
                                                          .groups
                                                      );
                                                      const orgCopy = _.cloneDeep(
                                                        formikProps.values
                                                          .updateForm.access
                                                          .organizations
                                                      );
                                                      const orgGroupCopy = _.cloneDeep(
                                                        formikProps.values
                                                          .updateForm.access
                                                          .organizationGroups
                                                      );
                                                      this.deleteFromList(
                                                        personCopy
                                                      );
                                                      this.deleteFromList(
                                                        personGroupCopy
                                                      );
                                                      this.deleteFromList(
                                                        orgCopy
                                                      );
                                                      this.deleteFromList(
                                                        orgGroupCopy
                                                      );
                                                      formikProps.setFieldValue(
                                                        'updateForm.access.persons',
                                                        personCopy
                                                      );
                                                      formikProps.setFieldValue(
                                                        'updateForm.access.groups',
                                                        personGroupCopy
                                                      );
                                                      formikProps.setFieldValue(
                                                        'updateForm.access.organizations',
                                                        orgCopy
                                                      );
                                                      formikProps.setFieldValue(
                                                        'updateForm.access.organizationGroups',
                                                        orgGroupCopy
                                                      );
                                                    }
                                                  } else {
                                                    formikProps.setFieldValue(
                                                      'updateForm.access.isPrivate',
                                                      !formikProps.values
                                                        .updateForm.access
                                                        .isPrivate
                                                    );
                                                  }
                                                } else if (
                                                  !formikProps.values.updateForm
                                                    .access.isPrivate
                                                ) {
                                                  formikProps.setFieldValue(
                                                    'updateForm.access.isPrivate',
                                                    !formikProps.values
                                                      .updateForm.access
                                                      .isPrivate
                                                  );
                                                }
                                              }
                                            }}
                                          />
                                        </div>
                                      </Tooltip>
                                      <Icons
                                        isChecked={
                                          formikProps.values.updateForm.access
                                            .isPrivate
                                        }
                                      >
                                        <MunikumIcons.Padlock
                                          style={{
                                            height: '1.2em',
                                            width: '1.2em',
                                          }}
                                        />
                                      </Icons>
                                    </div>
                                  </div>
                                  {formikProps.values.updateForm.access
                                    .isPrivate && (
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end',
                                        marginBottom:
                                          formikProps.values.updateForm.access
                                            .isPrivate === true
                                            ? '.5em'
                                            : '0',
                                      }}
                                    >
                                      <Icons
                                        isChecked={
                                          formikProps.values.updateForm.access
                                            .visibility !==
                                          ContentVisibilityEnum.PRIVATE
                                        }
                                      >
                                        <MunikumIcons.eye
                                          style={{
                                            height: '1.2em',
                                            width: '1.2em',
                                          }}
                                        />
                                      </Icons>
                                      <Tooltip
                                        position={'top-start'}
                                        content={
                                          formikProps.values.updateForm.access
                                            .visibility ===
                                          ContentVisibilityEnum.PRIVATE
                                            ? intl.formatMessage(
                                                commonMessages.MakeYearWheelVisible
                                              )
                                            : intl.formatMessage(
                                                commonMessages.makeYearWheelNotVisible
                                              )
                                        }
                                      >
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                          }}
                                        >
                                          <ToggleButton
                                            onClick={() => {
                                              if (doIhaveAccess) {
                                                formikProps.values.updateForm
                                                  .access.visibility ===
                                                ContentVisibilityEnum.PRIVATE
                                                  ? formikProps.setFieldValue(
                                                      'updateForm.access.visibility',
                                                      ContentVisibilityEnum.PUBLIC
                                                    )
                                                  : formikProps.setFieldValue(
                                                      'updateForm.access.visibility',
                                                      ContentVisibilityEnum.PRIVATE
                                                    );
                                              }
                                            }}
                                            style={{
                                              marginLeft: '.5em',
                                              marginRight: '.3em',
                                              marginTop: '.5em',
                                            }}
                                            checked={
                                              formikProps.values.updateForm
                                                .access.visibility ===
                                              ContentVisibilityEnum.PRIVATE
                                            }
                                            name={'da'}
                                          />
                                        </div>
                                      </Tooltip>
                                      <Icons
                                        isChecked={
                                          formikProps.values.updateForm.access
                                            .visibility ===
                                          ContentVisibilityEnum.PRIVATE
                                        }
                                      >
                                        <MunikumIcons.eyeSlash
                                          style={{
                                            height: '1.2em',
                                            width: '1.2em',
                                          }}
                                        />
                                      </Icons>
                                    </div>
                                  )}
                                  <div style={{ marginBottom: '.5em' }}>
                                    <ExternalLink
                                      style={{
                                        fontSize: '12px',
                                      }}
                                      href={
                                        'https://support.munikum.no/hc/no/articles/360027728352-Gi-lese-og-skrivetilgang-til-%C3%A5rshjulet'
                                      }
                                    >
                                      {intl.formatMessage(
                                        commonMessages.doYouNotFindThePerson
                                      )}
                                    </ExternalLink>
                                  </div>
                                  <FieldArray
                                    name={'personAccessStuff'}
                                    render={arrayHelpers => {
                                      return (
                                        <div>
                                          <div
                                            style={{ marginBottom: '1.250em' }}
                                          >
                                            <div>
                                              <AnnualCycleAddMembersCard
                                                disabled={!doIhaveAccess}
                                                isPrivate={
                                                  formikProps.values.updateForm
                                                    .access.isPrivate
                                                }
                                                showOnlyPerson={false}
                                                addGroup={(
                                                  group,
                                                  permission
                                                ) => {
                                                  let isAlreadyInArray = false;
                                                  for (
                                                    let i = 0;
                                                    i <
                                                    formikProps.values
                                                      .updateForm.access.groups
                                                      .length;
                                                    i++
                                                  ) {
                                                    if (
                                                      formikProps.values
                                                        .updateForm.access
                                                        .groups[i].group.id ===
                                                      group.id
                                                    ) {
                                                      isAlreadyInArray = true;
                                                      break;
                                                    }
                                                  }
                                                  if (isAlreadyInArray) {
                                                    return '';
                                                  } else {
                                                    const toSend = formikProps.values.updateForm.access.groups.slice();
                                                    toSend.push({
                                                      access: permission,
                                                      group: group,
                                                    });
                                                    formikProps.setFieldValue(
                                                      'updateForm.access.groups',
                                                      toSend
                                                    );
                                                  }
                                                }}
                                                addOrg={(org, permission) => {
                                                  let isAlreadyInArray = false;
                                                  for (
                                                    let i = 0;
                                                    i <
                                                    formikProps.values
                                                      .updateForm.access
                                                      .organizations.length;
                                                    i++
                                                  ) {
                                                    if (
                                                      formikProps.values
                                                        .updateForm.access
                                                        .organizations[i]
                                                        .organization.id ===
                                                      org.id
                                                    ) {
                                                      isAlreadyInArray = true;
                                                      break;
                                                    }
                                                  }
                                                  if (isAlreadyInArray) {
                                                    return '';
                                                  } else {
                                                    const toSend = formikProps.values.updateForm.access.organizations.slice();

                                                    toSend.push({
                                                      access: permission,
                                                      organization: org,
                                                    });
                                                    formikProps.setFieldValue(
                                                      'updateForm.access.organizations',
                                                      toSend
                                                    );
                                                  }
                                                }}
                                                addOrgGroup={(
                                                  orgGroup,
                                                  permission
                                                ) => {
                                                  let isAlreadyInArray = false;
                                                  for (
                                                    let i = 0;
                                                    i <
                                                    formikProps.values
                                                      .updateForm.access
                                                      .organizationGroups
                                                      .length;
                                                    i++
                                                  ) {
                                                    if (
                                                      formikProps.values
                                                        .updateForm.access
                                                        .organizationGroups[i]
                                                        .organizationGroup
                                                        .id === orgGroup.id
                                                    ) {
                                                      isAlreadyInArray = true;
                                                      break;
                                                    }
                                                  }
                                                  if (isAlreadyInArray) {
                                                    return '';
                                                  } else {
                                                    const toSend = formikProps.values.updateForm.access.organizationGroups.slice();
                                                    toSend.push({
                                                      access: permission,
                                                      organizationGroup: orgGroup,
                                                    });

                                                    formikProps.setFieldValue(
                                                      'updateForm.access.organizationGroups',
                                                      toSend
                                                    );
                                                  }
                                                }}
                                                showTag={false}
                                                canChangePermission={
                                                  formikProps.values.updateForm
                                                    .access.isPrivate
                                                }
                                                isPermissionActive={true}
                                                title={intl.formatMessage(
                                                  commonMessages.to
                                                )}
                                                permissionLevel={permissionLevel => {
                                                  permissionOptions = permissionLevel.valueOf();
                                                }}
                                                addMember={(
                                                  person,
                                                  permission: AccessEnum
                                                ) => {
                                                  let isAlreadyInArray = false;
                                                  for (
                                                    let i = 0;
                                                    i <
                                                    formikProps.values
                                                      .updateForm.access.persons
                                                      .length;
                                                    i++
                                                  ) {
                                                    if (
                                                      formikProps.values
                                                        .updateForm.access
                                                        .persons[i].person
                                                        .id === person.id
                                                    ) {
                                                      isAlreadyInArray = true;
                                                      break;
                                                    }
                                                  }
                                                  if (isAlreadyInArray) {
                                                    return '';
                                                  } else {
                                                    // if (person.id !== me.id) {

                                                    const toSend = formikProps.values.updateForm.access.persons.slice();
                                                    toSend.push({
                                                      access: permission,
                                                      person: person,
                                                    });

                                                    formikProps.setFieldValue(
                                                      'updateForm.access.persons',
                                                      toSend
                                                    );
                                                    // }
                                                  }
                                                }}
                                              />
                                            </div>
                                          </div>
                                          {createdBy && (
                                            <MembersOfAnnualCycleCard
                                              disabled={!doIhaveAccess}
                                              type={typeToList.PERSONS}
                                              canChangePermission={false}
                                              canRemove={false}
                                              key={createdBy.id}
                                              profilePic={createdBy.picture}
                                              name={createdBy.name}
                                              organization={
                                                createdBy.organization &&
                                                createdBy.organization.name
                                              }
                                              department={createdBy.department}
                                              role={createdBy.role}
                                              handleRemoveMember={() => ''}
                                              img={
                                                <StaticImage
                                                  width={21}
                                                  height={21}
                                                  type={
                                                    IStaticImageType.ORGANIZATION
                                                  }
                                                  filename={
                                                    (createdBy.organization &&
                                                      createdBy.organization
                                                        .organizationImage) ||
                                                    ''
                                                  }
                                                />
                                              }
                                              permissionLevel={{
                                                value: 'OWNER',
                                                name: intl.formatMessage(
                                                  commonMessages.owner
                                                ),
                                              }}
                                            />
                                          )}
                                          {formikProps.values.updateForm
                                            .access &&
                                            formikProps.values.updateForm.access
                                              .isPrivate && (
                                              <DisabledTextField
                                                style={{ width: '460px' }}
                                                name={'t'}
                                                disabled={true}
                                                value={intl.formatMessage(
                                                  commonMessages.infoBoxYearWheel
                                                )}
                                              />
                                            )}
                                          <div
                                            style={{
                                              maxHeight: '300px',
                                              overflowY: 'auto',
                                              marginBottom: '1em',
                                            }}
                                          >
                                            {formikProps.values.updateForm.access.persons.map(
                                              (person: any, i) => {
                                                return (
                                                  <div key={'bla' + i}>
                                                    <MembersOfAnnualCycleCard
                                                      disabled={!doIhaveAccess}
                                                      type={typeToList.PERSONS}
                                                      canChangePermission={
                                                        formikProps.values
                                                          .updateForm.access
                                                          .isPrivate
                                                      }
                                                      canRemove={true}
                                                      onChangeSelected={selectedKey => {
                                                        // formikProps.handleBlur()
                                                        const personCopy =
                                                          formikProps.values
                                                            .updateForm.access
                                                            .persons;
                                                        const getEnum =
                                                          selectedKey ===
                                                          'WRITE'
                                                            ? AccessEnum.WRITE
                                                            : AccessEnum.READ;

                                                        personCopy[
                                                          i
                                                        ].access = getEnum;
                                                        formikProps.setFieldValue(
                                                          'updateForm.access.persons',
                                                          personCopy
                                                        );
                                                      }}
                                                      key={i}
                                                      profilePic={
                                                        person.picture
                                                      }
                                                      name={person.person.name}
                                                      organization={
                                                        person.person
                                                          .organization &&
                                                        person.person
                                                          .organization.name
                                                      }
                                                      department={
                                                        person.person.department
                                                      }
                                                      role={person.person.role}
                                                      handleRemoveMember={() => {
                                                        const personGroupCopyFormik = formikProps.values.updateForm.access.persons.slice();
                                                        personGroupCopyFormik.splice(
                                                          i,
                                                          1
                                                        );

                                                        formikProps.setFieldValue(
                                                          'updateForm.access.persons',
                                                          personGroupCopyFormik
                                                        );
                                                      }}
                                                      img={
                                                        <StaticImage
                                                          width={21}
                                                          height={21}
                                                          type={
                                                            IStaticImageType.ORGANIZATION
                                                          }
                                                          filename={
                                                            (person.person
                                                              .organization &&
                                                              person.person
                                                                .organization
                                                                .organizationImage) ||
                                                            ''
                                                          }
                                                        />
                                                      }
                                                      permissionLevel={{
                                                        value: 'WRITE',
                                                        name:
                                                          person.access ===
                                                          'WRITE'
                                                            ? 'skrivetilgang'
                                                            : 'Lesetilgang',
                                                      }}
                                                    />
                                                  </div>
                                                );
                                              }
                                            )}
                                            {/*Groups*/}
                                            {formikProps.values.updateForm.access.groups.map(
                                              (group: any, i) => {
                                                return (
                                                  <div key={'bla' + i}>
                                                    <MembersOfAnnualCycleCard
                                                      disabled={!doIhaveAccess}
                                                      type={
                                                        typeToList.PERSONGROUPS
                                                      }
                                                      canChangePermission={
                                                        formikProps.values
                                                          .updateForm.access
                                                          .isPrivate
                                                      }
                                                      canRemove={true}
                                                      onChangeSelected={selectedKey => {
                                                        const groupCopy =
                                                          formikProps.values
                                                            .updateForm.access
                                                            .groups;
                                                        const getEnum =
                                                          selectedKey ===
                                                          'WRITE'
                                                            ? AccessEnum.WRITE
                                                            : AccessEnum.READ;
                                                        groupCopy[
                                                          i
                                                        ].access = getEnum;

                                                        formikProps.setFieldValue(
                                                          'updateForm.access.groups',
                                                          groupCopy
                                                        );
                                                      }}
                                                      key={i}
                                                      name={group.group.title}
                                                      handleRemoveMember={() => {
                                                        const groupCopyFormik = formikProps.values.updateForm.access.groups.slice();
                                                        groupCopyFormik.splice(
                                                          i,
                                                          1
                                                        );

                                                        formikProps.setFieldValue(
                                                          'updateForm.access.groups',
                                                          groupCopyFormik
                                                        );
                                                      }}
                                                      img={
                                                        <StaticImage
                                                          width={21}
                                                          height={21}
                                                          type={
                                                            IStaticImageType.ORGANIZATION
                                                          }
                                                        />
                                                      }
                                                      permissionLevel={{
                                                        value: 'WRITE',
                                                        name:
                                                          group.access ===
                                                          'WRITE'
                                                            ? 'skrivetilgang'
                                                            : 'Lesetilgang',
                                                      }}
                                                    />
                                                  </div>
                                                );
                                              }
                                            )}
                                            {/*<Button text={'hhh'}*/}
                                            {/*onClick={()=> {*/}
                                            {/*myApolloClient.mutate({*/}
                                            {/*mutation: CREATE_ORGANIZATION_GROUP,*/}
                                            {/*variables: {input: {*/}
                                            {/*title: 'orgGruppe',*/}
                                            {/*members: [1]*/}
                                            {/*}}*/}
                                            {/*});*/}
                                            {/*myApolloClient.mutate({*/}
                                            {/*mutation: CREATE_ORGANIZATION_GROUP,*/}
                                            {/*variables: {input: {*/}
                                            {/*title: 'orgGruppe1',*/}
                                            {/*members: [1]*/}
                                            {/*}}*/}
                                            {/*});*/}
                                            {/*myApolloClient.mutate({*/}
                                            {/*mutation: CREATE_ORGANIZATION_GROUP,*/}
                                            {/*variables: {input: {*/}
                                            {/*title: 'orgGruppe2',*/}
                                            {/*members: [1]*/}
                                            {/*}}*/}
                                            {/*});*/}
                                            {/*}}*/}
                                            {/*/>*/}
                                            {formikProps.values.updateForm.access.organizations.map(
                                              (org: any, index) => {
                                                return (
                                                  <div key={'bla' + index}>
                                                    <MembersOfAnnualCycleCard
                                                      disabled={!doIhaveAccess}
                                                      type={
                                                        typeToList.ORGANIZATIONS
                                                      }
                                                      canChangePermission={
                                                        formikProps.values
                                                          .updateForm.access
                                                          .isPrivate
                                                      }
                                                      canRemove={true}
                                                      onChangeSelected={selectedKey => {
                                                        const orgCopy =
                                                          formikProps.values
                                                            .updateForm.access
                                                            .organizations;
                                                        const getEnum =
                                                          selectedKey ===
                                                          'WRITE'
                                                            ? AccessEnum.WRITE
                                                            : AccessEnum.READ;

                                                        orgCopy[
                                                          index
                                                        ].access = getEnum;

                                                        formikProps.setFieldValue(
                                                          'updateForm.access.organizations',
                                                          orgCopy
                                                        );
                                                      }}
                                                      key={index}
                                                      name={
                                                        org.organization.name
                                                      }
                                                      handleRemoveMember={() => {
                                                        const orgCopyFormik = formikProps.values.updateForm.access.organizations.slice();

                                                        orgCopyFormik.splice(
                                                          index,
                                                          1
                                                        );

                                                        formikProps.setFieldValue(
                                                          'updateForm.access.organizations',
                                                          orgCopyFormik
                                                        );
                                                      }}
                                                      img={
                                                        <StaticImage
                                                          width={21}
                                                          height={21}
                                                          type={
                                                            IStaticImageType.ORGANIZATION
                                                          }
                                                          filename={
                                                            (org.organization &&
                                                              org.organization
                                                                .organizationImage) ||
                                                            ''
                                                          }
                                                        />
                                                      }
                                                      permissionLevel={{
                                                        value: 'WRITE',
                                                        name:
                                                          org.access === 'WRITE'
                                                            ? 'skrivetilgang'
                                                            : 'Lesetilgang',
                                                      }}
                                                    />
                                                  </div>
                                                );
                                              }
                                            )}
                                            {/*OrganizationGroups*/}
                                            {formikProps.values.updateForm.access.organizationGroups.map(
                                              (
                                                orgGroup: any,
                                                indexPosition
                                              ) => {
                                                return (
                                                  <div
                                                    key={'bla' + indexPosition}
                                                  >
                                                    <MembersOfAnnualCycleCard
                                                      disabled={!doIhaveAccess}
                                                      type={
                                                        typeToList.ORGANIZATIONGROUPS
                                                      }
                                                      canChangePermission={
                                                        formikProps.values
                                                          .updateForm.access
                                                          .isPrivate
                                                      }
                                                      canRemove={true}
                                                      key={indexPosition}
                                                      name={
                                                        orgGroup
                                                          .organizationGroup
                                                          .title
                                                      }
                                                      handleRemoveMember={() => {
                                                        const orgGroupCopyFormik = formikProps.values.updateForm.access.organizationGroups.slice();
                                                        orgGroupCopyFormik.splice(
                                                          indexPosition,
                                                          1
                                                        );
                                                        formikProps.setFieldValue(
                                                          'updateForm.access.organizationGroups',
                                                          orgGroupCopyFormik
                                                        );
                                                      }}
                                                      onChangeSelected={selectedKey => {
                                                        const orgGroupCopy =
                                                          formikProps.values
                                                            .updateForm.access
                                                            .organizationGroups;
                                                        const getEnum =
                                                          selectedKey ===
                                                          'WRITE'
                                                            ? AccessEnum.WRITE
                                                            : AccessEnum.READ;
                                                        orgGroupCopy[
                                                          indexPosition
                                                        ].access = getEnum;
                                                        formikProps.setFieldValue(
                                                          'updateForm.access.organizationGroups',
                                                          orgGroupCopy
                                                        );
                                                      }}
                                                      img={
                                                        <StaticImage
                                                          width={21}
                                                          height={21}
                                                          type={
                                                            IStaticImageType.ORGANIZATION
                                                          }
                                                        />
                                                      }
                                                      permissionLevel={{
                                                        value: 'WRITE',
                                                        name:
                                                          orgGroup.access ===
                                                          'WRITE'
                                                            ? 'skrivetilgang'
                                                            : 'Lesetilgang',
                                                      }}
                                                    />
                                                  </div>
                                                );
                                              }
                                            )}
                                            {!formikProps.values.updateForm
                                              .access.isPrivate && (
                                              <MembersOfAnnualCycleCardEveryoneComp
                                                disabled={!doIhaveAccess}
                                                // style={{marginTop: '-1.2em'}}
                                                onCancelClick={() => {
                                                  formikProps.setFieldValue(
                                                    'updateForm.access.isPrivate',
                                                    !formikProps.values
                                                      .updateForm.access
                                                      .isPrivate
                                                  );
                                                }}
                                              />
                                            )}
                                            <CreateSharableUriCard
                                              isThereUri={
                                                uri !== undefined &&
                                                uri.length > 0
                                              }
                                              uri={uri}
                                              handleErrorChange={(
                                                state: boolean
                                              ) => {
                                                this.setState({
                                                  showShareError: state,
                                                });
                                              }}
                                              id={this.props.id}
                                              style={{ marginTop: '.25em' }}
                                            />
                                          </div>
                                          {me.isAdmin && uri && (
                                            <CalendarShareEmbedPreview
                                              uri={uri}
                                            />
                                          )}
                                        </div>
                                      );
                                    }}
                                  />
                                </div>

                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Button
                                    greyBackground={true}
                                    text={intl.formatMessage(
                                      commonMessages.archive
                                    )}
                                    theme={ColorTheme.White}
                                    onClick={() => {
                                      if (
                                        window.confirm(
                                          intl.formatMessage(
                                            commonMessages.archiveCalendarWarning
                                          )
                                        )
                                      ) {
                                        myApolloClient.mutate({
                                          mutation: ARCHIVE_CALENDAR,
                                          variables: {
                                            id: result.data.calendar.id,
                                          },
                                        });

                                        safeInvokeDeprecated(
                                          this.props.onCancel
                                        );
                                      }
                                    }}
                                    style={{ marginLeft: '.5em' }}
                                    // disabled={loading}
                                  />

                                  <Button
                                    greyBackground={true}
                                    disabled={!doIhaveAccess}
                                    text={intl.formatMessage(
                                      commonMessages.delete
                                    )}
                                    theme={ColorTheme.Red}
                                    style={{ marginLeft: '.5em' }}
                                    onClick={() => {
                                      if (
                                        window.confirm(
                                          intl.formatMessage(
                                            commonMessages.deleteCalendarWarning
                                          )
                                        )
                                      ) {
                                        const temp = _.cloneDeep(
                                          formikProps.values.updateForm
                                        );

                                        const accessToSend: any = {
                                          persons: [],
                                          groups: [],
                                          organizations: [],
                                          organizationGroups: [],
                                        };
                                        if (
                                          temp.access.persons &&
                                          temp.access.persons.length > 0
                                        ) {
                                          temp.access.persons.forEach(
                                            (p: any) => {
                                              accessToSend.persons.push({
                                                access: p.access,
                                                person: p.person.id,
                                              });
                                            }
                                          );
                                        }
                                        if (
                                          temp.access.groups &&
                                          temp.access.groups.length > 0
                                        ) {
                                          temp.access.groups.forEach(
                                            (g: PersonGroupAccessInput) => {
                                              accessToSend.groups.push({
                                                access: g.access,
                                                group: g.group.id,
                                              });
                                            }
                                          );
                                        }
                                        if (
                                          temp.access.organizations &&
                                          temp.access.organizations.length > 0
                                        ) {
                                          temp.access.organizations.forEach(
                                            (o: OrganizationAccessInput) => {
                                              accessToSend.organizations.push({
                                                access: o.access,
                                                organization: o.organization.id,
                                              });
                                            }
                                          );
                                        }
                                        if (
                                          temp.access.organizationGroups &&
                                          temp.access.organizationGroups
                                            .length > 0
                                        ) {
                                          temp.access.organizationGroups.forEach(
                                            (
                                              org: OrganizationGroupAccessInput
                                            ) => {
                                              accessToSend.organizationGroups.push(
                                                {
                                                  access: org.access,
                                                  organizationGroup:
                                                    org.organizationGroup.id,
                                                }
                                              );
                                            }
                                          );
                                        }

                                        temp.access.persons =
                                          accessToSend.persons;
                                        temp.access.groups =
                                          accessToSend.groups;
                                        temp.access.organizations =
                                          accessToSend.organizations;
                                        temp.access.organizationGroups =
                                          accessToSend.organizationGroups;

                                        temp.status = ContentStatus.OBSOLETE;

                                        let input2: UpdateCalendarVariables = {
                                          input: temp,
                                        };
                                        let input: DeleteCalendarRingMutationVariables = {
                                          id: temp.id,
                                        };

                                        myApolloClient
                                          .mutate({
                                            mutation: UPDATE_CALENDAR,
                                            variables: input2,
                                          })
                                          .then(res2 => {
                                            myApolloClient
                                              .mutate({
                                                mutation: DELETE_CALENDAR_RING,
                                                variables: input,
                                              })

                                              .catch(err3 =>
                                                console.log('err3', err3)
                                              );
                                            safeInvokeDeprecated(
                                              this.props.onDeleteSuccesful
                                            );
                                          })
                                          .then(c => {
                                            myApolloClient.query({
                                              query: YEAR_WHEEL,
                                              variables: {
                                                year: this.state.year,
                                                person: this.props.userHash,
                                              },
                                              fetchPolicy: 'network-only',
                                            });
                                          })
                                          .catch(err =>
                                            console.log('err', err)
                                          );
                                      }
                                    }}
                                  />

                                  <Button
                                    greyBackground={true}
                                    text={intl.formatMessage(
                                      commonMessages.cancel
                                    )}
                                    theme={ColorTheme.White}
                                    onClick={() =>
                                      safeInvokeDeprecated(this.props.onCancel)
                                    }
                                    style={{ marginLeft: '.5em' }}
                                    // disabled={loading}
                                  />
                                  <Button
                                    greyBackground={true}
                                    // isLoading={loading}
                                    buttonTheme={ButtonTheme.RED}
                                    text={intl.formatMessage(
                                      commonMessages.save
                                    )}
                                    disabled={!doIhaveAccess}
                                    theme={ColorTheme.Red}
                                    type={'submit'}
                                    style={{ marginLeft: '.5em' }}
                                    leftIcon={MunikumIcons.Save}
                                  />
                                </div>
                              </RightContainer>
                              <CleanDialog
                                canEscapeKeyClose={false} // TODO: doesnt work with contenteditable.. fix? also doesnt work when trying to cancel event bubbling . . .
                                canOutsideClickClose={true}
                                showCloseWarning={CloseWarningOption.SIMPLE}
                                onClose={() =>
                                  this.setState({ isCopyYearWheelOpen: false })
                                }
                                isOpen={this.state.isCopyYearWheelOpen}
                              >
                                <CopyAnnualCycle
                                  initialTitle={
                                    formikProps.values.updateForm.title
                                  }
                                  textTitle={'Kopier årshjul som mal'}
                                  onSubmit={async (form: any) => {
                                    this.setState({
                                      isCopyYearWheelOpen: false,
                                    });
                                    new Promise(async (resolve, reject) => {
                                      try {
                                        await myApolloClient.mutate({
                                          mutation: COPY_CALENDAR,
                                          variables: {
                                            input: {
                                              id: this.props.id,
                                              title: form.title,
                                            },
                                          },
                                        });
                                        // myApolloClient
                                        //   .mutate({
                                        //     mutation: CREATE_CALENDAR_RING,
                                        //     variables: {input: {
                                        //       id: resultCopy.data.copyCalendar.id,
                                        //       order: 1,
                                        //       color:data.calendar &&
                                        //         data.calendar &&
                                        //         data.calendar.color
                                        //
                                        //       }},
                                        //     refetchQueries: [
                                        //       {
                                        //         query: YEAR_WHEEL,
                                        //         variables: {
                                        //           year: this.state.filter.selectedYear,
                                        //           person: data.me && data.me.id,
                                        //         },
                                        //       },
                                        //     ],
                                        //   })
                                        // TODO: mutate add calendar to my rings

                                        // TODO:_ remove this =)
                                        await new Promise(resolve1 =>
                                          setTimeout(() => resolve(), 7000)
                                        );

                                        // FERDIG
                                        resolve();
                                      } catch (e) {
                                        console.log(e, 'Error');

                                        // FAIL
                                        reject(e);
                                      }
                                    });
                                    // console.log('over ref');

                                    // if (this.toastRef.current) {
                                    //   // console.log('has ref');
                                    //   const that = this;
                                    //   const myId = this.toastRef.current.addToast(
                                    //     {
                                    //       message: (
                                    //         <CopyToast
                                    //           task={copyPromise}
                                    //           onDone={() => {
                                    //             // console.log(
                                    //             //   'DONE, remove soon?'
                                    //             // );
                                    //             setTimeout(() => {
                                    //               if (this.toastRef.current) {
                                    //                 that.toastRef.current.removeToast(
                                    //                   myId
                                    //                 );
                                    //               }
                                    //             }, 1000 * 45); // TODO: kor lang tiid treng vi her? =)
                                    //           }}
                                    //         />
                                    //       ),
                                    //       theme: ToastType.LOADING,
                                    //       leftIcon: MunikumIcons.Info,
                                    //       rightIcon: MunikumIcons.Cancel,
                                    //       onClickRightIcon: e => {
                                    //         this.toastRef.current.removeToast(
                                    //           myId
                                    //         );
                                    //       },
                                    //     }
                                    //   );
                                    // }
                                  }}
                                  onClickCancel={() => {
                                    this.setState({
                                      isCopyYearWheelOpen: false,
                                    });
                                  }}
                                />
                              </CleanDialog>
                            </Container>
                          </form>
                        );
                      }}
                    />
                  )}
                </Mutation>
              </div>
            );
          }}
        />

        {/* put Mutation "far out", then we can display loading and error easily */}

        {/*<Mutation*/}
        {/*mutation={UPDATE_CALENDAR}*/}
        {/*// refetchQueries={[{ query:  }]} // TODO: aDd this*/}
        {/*onCompleted={this.handleCompleted}*/}
        {/*onError={this.handleError}*/}
        {/*>*/}
        {/*{(updateCalendar, { data, loading, called, error }) => {*/}
        {/*// we can show messages based on function as a child props here:*/}
        {/*// OR we can hook into onError (if we need more control, like showing dialog and hiding again)*/}
        {/*if (this.state.showError && error) {*/}
        {/*return (*/}
        {/*<StatusMessageDialog*/}
        {/*theme={ThemeColor.ERROR_BLACK}*/}
        {/*title={'Error'}*/}
        {/*text={'An error occured: ' + 'error.message'}*/}
        {/*buttonText={'Dismiss'} // we could also retry? check if apollo has a retry mechanismn?*/}
        {/*onClickButton={this.handleDismissError}*/}
        {/*/>*/}
        {/*);*/}
        {/*}*/}

        {/*if (this.state.showSuccess && called) {*/}
        {/*return (*/}
        {/*<StatusMessageDialog*/}
        {/*theme={ThemeColor.SUCCESS_BLUE}*/}
        {/*title={'Great success'}*/}
        {/*text={'Cycle is created!'}*/}
        {/*buttonText={'Success'} // we could also retry? check if apollo has a retry mechanismn?*/}
        {/*onClickButton={this.handleDismissSuccess}*/}
        {/*/>*/}
        {/*);*/}
        {/*}*/}

        {/*// let colors = [*/}
        {/*//   { id: 1, name: 'blue' },*/}
        {/*//   { id: 2, name: 'red' },*/}
        {/*//   { id: 3, name: 'green' },*/}
        {/*// ];*/}

        {/*</Mutation>*/}
      </ContentBox>
    );
  }
}

export const EditAnnualCycleForm = injectIntl(EditAnnualCycleFormComp);
