import { IHttpLink } from './models/actionValue';
import {
  CreateTagInput,
  FieldsOnKostraFunctionFragment,
  Maybe,
  OrderEnum,
  Person,
  SortEnum,
  TopicCategoryEnum,
  TopicCategorySortEnum,
} from '../models/types';
import { ContentStatus } from './models/contentMeta';
import { actionCreatorFactory } from '../modules/actionCreatorFactory';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';

const actionCreator = actionCreatorFactory('TOPIC');

interface ITopicCreateOrganization {
  name: string;
  organizationImage?: string;
}

interface ITopicCreatePerson {
  id: string;
  name: string;
  picture?: string;
  department?: string;
  role?: string;
  organization: ITopicCreateOrganization;
}

export interface ITopicCreateOrUpdateForm {
  id?: string;
  title?: string;
  description?: string;
  body?: string;
  tags: Maybe<CreateTagInput[]>;
  status?: ContentStatus;
  category?: TopicCategoryEnum | string;
  links?: ReadonlyArray<IHttpLink>;
  sector?: string;
  kostraFunctions?: ReadonlyArray<FieldsOnKostraFunctionFragment>;
  access: any;
  createdBy: ITopicCreatePerson;
}

export interface ITopicFilter {
  sort?: SortEnum;
  order?: OrderEnum;
  predefinedOrg: boolean;
  limit?: number;
  persons: Array<Person>;
  organizations: Array<any>;
  category?: TopicCategorySortEnum;
  offset?: number;
  showOnlyMine?: boolean;
  // organizations?: Array<Organization>;
  sortValue?: string;
  kostraGroup?: number | null;
  kostraGroupValue?: string | undefined;
  categoryKey?: string | undefined;
}

export interface ITopicFilterState {
  filter: ITopicFilter;
  currentPage: number;
}

const initialState: ITopicFilterState = {
  filter: {
    organizations: [],
    persons: [],
    showOnlyMine: false,
    predefinedOrg: false,
    categoryKey: undefined,
    kostraGroupValue: undefined,
    category: TopicCategorySortEnum.ALL,
    sort: SortEnum.LAST_UPDATED,
    order: OrderEnum.DESCENDING,
    limit: 12,
    sortValue: '1',
    offset: 0,
    kostraGroup: null,
  },
  currentPage: 0,
};

export const resetTopicsFilterActionCreator = actionCreator<{}>(
  'CLIENT_RESET_FILTER_TOPIC'
);

export const topicChangePageActionCreator = actionCreator<{
  page: number;
}>('CHANGE_PAGE_TOPIC');

export const filterTopicActionCreator = actionCreator<{
  filter: ITopicFilter;
}>('FILTER_TOPIC');

export const topicFilterReducer = (
  state: ITopicFilterState = initialState,
  action: Action
): ITopicFilterState => {
  if (isType(action, resetTopicsFilterActionCreator)) {
    return {
      filter: {
        organizations: [],
        persons: [],
        showOnlyMine: false,
        predefinedOrg: false,
        categoryKey: undefined,
        kostraGroupValue: undefined,
        category: TopicCategorySortEnum.ALL,
        sort: SortEnum.LAST_UPDATED,
        order: OrderEnum.DESCENDING,
        limit: 12,
        sortValue: '1',
        offset: 0,
        // organizations: [],
        kostraGroup: null,
      },
      currentPage: 0,
    };
  }

  if (isType(action, filterTopicActionCreator)) {
    return {
      filter: {
        ...action.payload.filter,
      },
      currentPage: 0,
    };
  }
  if (isType(action, topicChangePageActionCreator)) {
    return {
      filter: state.filter,
      currentPage: action.payload.page,
    };
  }

  return state;
};
