import { IContentData } from './contentData';

export enum radarType {
  EFFORT = 0,
  GAIN = 1,
}

export interface IActionValueRadarGraph {
  sum: number;
  name: string;
  type: radarType;
  comment: string;
}

export interface IActionValueParameterValue {
  // id to parameter object in db
  parameter: string;

  // id to option in db
  option: string;

  comment?: string;
}

export interface IActionValue extends IContentData {
  category: number;

  kostraFunctions?: ReadonlyArray<string>;

  watchStatus?: boolean;

  /**
   * this is data returned from API that is easy to read
   */
  radarGraph?: ReadonlyArray<IActionValueRadarGraph>;

  /**
   * parameters we can send back to API
   */
  parameters?: ReadonlyArray<IActionValueParameterValue>;
  limits?: ReadonlyArray<IActionValueLimit>;
  gains?: ReadonlyArray<IActionValueGain>;
  cards?: ReadonlyArray<IActionValueCard>;

  /**
   * source URL
   */
  sourceUrl?: string;

  userFiles: ReadonlyArray<string>;
}

export interface IActionValueLimit {
  attribute: number;
  secondAttribute?: number;
  firstLimitValue: number;
  secondLimitValue?: number;
  limitType: ActionValueLimitType;
  limitOption: ActionValueLimitOption;
}

export interface IActionValueGain {
  attribute: number;
  gainMultiplier: number;
  accountingAttribute?: number;
}

export interface IActionValueCard {
  step: number;
  text?: string;
  identifier: string;
}

export interface IHttpLink {
  label: string;
  href: string;
}

export enum ActionValueCategory {
  OPERATIONAL = 0,
  INVESTMENT = 1,
}

export enum ActionValueLimitType {
  IS_ABOVE = 0,
  IS_BELOW = 1,
  IS_BETWEEN = 2,
}

export enum ActionValueLimitOption {
  CONSTANT = 0,
  VARIABLE = 1,
}
