import * as React from 'react';
import Helmet from 'react-helmet';
import { Page } from '../components/layout/Page/Page';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { H1, TextNormal } from '../components/basic/Structural/typography';
import { commonMessages } from '../components/language/commonMessages';
import styled from 'styled-components';
import { Button } from '../components/basic';
import { myHistory } from '../index';
import { Tooltip } from '../components/basic/Tooltip/Tooltip';
import { AuthConsumer } from './AuthContext';

const NoAccessBox = styled.div`
  border-radius: 4px;
  background-color: #fff;
  width: 640px;
  margin: 0 auto;
  box-sizing: border-box;

  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1); // ok?
  display: flex;
`;

const background = require('./noAccessBackground.svg') as string;

const Left = styled.div`
  background-image: url(${background});
  background-size: cover;

  flex: 0 0 189px;
  height: 310px;

  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  position: relative;
`;

const Right = styled.div`
  flex: 1 1 auto;
  //background-color: pink;

  display: flex;
  justify-content: center;
  align-items: center;

  padding-left: 60px;
  padding-right: 60px;
`;

const NoAccessPageComp: React.FunctionComponent<InjectedIntlProps> = props => (
  <Page
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      minHeight: '100%',
      paddingTop: '2em',
    }}
  >
    <Helmet>
      <title>{props.intl.formatMessage(commonMessages.noaccess)}</title>
    </Helmet>
    <div style={{ backgroundColor: 'pink', height: '100%' }}>
      <NoAccessBox>
        <Left>
          <Tooltip
            position={'right'}
            content={
              <AuthConsumer>
                {props => (
                  <FormattedMessage
                    id={'NoAccessPage.tooltip'}
                    defaultMessage={
                      'Hello {name}!{br}You are logged in with email {email}.{br}{br}Permissions:{br} {permissions}{br}{br}Roles:{br}{roles}'
                    }
                    values={{
                      br: <br />,
                      name:
                        (props.userAuthInfo && props.userAuthInfo.username) ||
                        'NA',
                      email:
                        (props.userAuthInfo && props.userAuthInfo.email) ||
                        'NA',
                      permissions:
                        (props.userAuthInfo &&
                          props.userAuthInfo.permissions.join(', ')) ||
                        'NA',
                      roles:
                        (props.userAuthInfo &&
                          props.userAuthInfo.roles.join(', ')) ||
                        'NA',
                    }}
                  />
                )}
              </AuthConsumer>
            }
          >
            <div
              style={{
                position: 'absolute',
                top: '95px',
                left: '50px',
                width: '80px',
                height: '100px',
              }}
            />
          </Tooltip>
        </Left>
        <Right>
          <div>
            <H1>{props.intl.formatMessage(commonMessages.noaccess)}</H1>
            <TextNormal>
              <FormattedMessage
                id={'NoAccessPage.message'}
                defaultMessage={"You don't have access to this page"}
              />
            </TextNormal>
            <Button
              text={props.intl.formatMessage(commonMessages.goBackButton)}
              onClick={() => myHistory.goBack()}
              style={{ marginTop: '20px' }}
            />
          </div>
        </Right>
      </NoAccessBox>
    </div>
  </Page>
);

const NoAccessPage = injectIntl(NoAccessPageComp);

export default NoAccessPage;
