import { isType } from 'typescript-fsa';
import { Action } from 'redux';
import { ContentStatus } from '../../services/models/contentMeta';
import { IDiscussionCreateOrUpdateForm } from '../../services/discussionService';
import { IApiError } from '../../services/api';
import { IFileItem } from '../../components/basic/UserFile/FileUploaderPicker';
import { actionCreatorFactory } from '../actionCreatorFactory';
import { OrderEnum, Organization, SortEnum } from '../../models/types';

const actionCreator = actionCreatorFactory('DISCUSSION');

/**
 * Discussion redux module (actions, reducer, sagas, selectors). see also entities.ts for generic code!
 */

// actions

// action to start editing discussion. get data from api, put everything we need in state, and redirect to editpage
export const editDiscussionActionCreator = actionCreator.async<
  { id: string; isFromEditPage?: boolean },
  IDiscussionCreateOrUpdateForm,
  IApiError
>('UI_EDIT');

// close edit page
export const cancelEditDiscussionActionCreator = actionCreator<{}>(
  'UI_CANCEL_EDIT'
);

// update actions
export const updateDiscussionActionCreator = actionCreator.async<
  { form: IDiscussionCreateOrUpdateForm; files: IFileItem[] },
  IDiscussionCreateOrUpdateForm,
  IApiError
>('UPDATE');

// we'll skip keeping create in redux state.. could be a nice feature if we want to create, navigate, continue to edit..

// // action to start editing a new action value
// export const createDiscussionActionCreator = actionCreator.async<{ newItem: IDiscussionCreateOrUpdateForm }, { form: IDiscussionCreateOrUpdateForm, tempKey: string}, IApiError>('UI_CREATE');
//
// // cancel editing newly created action value
// export const cancelCreateDiscussionActionCreator = actionCreator<{tempKeyToRemove: string}>(
//   'UI_CANCEL_CREATE'
// );

// we also need one for create, since create is different than update:
export const saveDiscussionActionCreator = actionCreator.async<
  IDiscussionCreateOrUpdateForm,
  {},
  IApiError
>('SAVE');

export const filterDiscussionsActionCreator = actionCreator<{
  filter: IDiscussionsFilter;
}>('FILTER_DISCUSSION');

export const resetDiscussionFilterActionCreator = actionCreator<{}>(
  'CLIENT_RESET_FILTER'
);

export interface IDiscussionsFilter {
  search: string;
  page: 0;
  showOnlyMine: boolean;
  organizations: Array<Organization>;
  order: OrderEnum.DESCENDING;
  limit: 10;
  // if you go throug myMunicipality show diffent title
  predefinedMunicipality: boolean;
  sort: SortEnum.LAST_UPDATED;
  sortValue: '1';
  offset: 0;
}

export interface IDiscussionState {
  edit: {
    isPreparingToEdit: boolean;
    error?: string;
    form?: IDiscussionCreateOrUpdateForm;
  };
  update: {
    isUpdating: boolean;
    error?: string;
  };
  create: {
    isSavingDraft: boolean;
    isSavingPublished: boolean;
    error?: string;
  };
  list: {
    filter: IDiscussionsFilter;
  };
}

const initialState: IDiscussionState = {
  edit: {
    isPreparingToEdit: false,
  },
  update: {
    isUpdating: false,
  },
  create: {
    isSavingDraft: false,
    isSavingPublished: false,
  },
  list: {
    filter: {
      predefinedMunicipality: false,
      search: '',
      page: 0,
      organizations: [],
      showOnlyMine: false,
      order: OrderEnum.DESCENDING,
      limit: 10,
      sort: SortEnum.LAST_UPDATED,
      sortValue: '1',
      offset: 0,
    },
  },
};

export const reducer = (
  state: IDiscussionState = initialState,
  action: Action
): IDiscussionState => {
  if (isType(action, resetDiscussionFilterActionCreator)) {
    return {
      ...state,
      list: {
        ...state.list,
        filter: {
          predefinedMunicipality: false,
          organizations: [],
          search: '',
          showOnlyMine: false,
          sort: SortEnum.LAST_UPDATED,
          order: OrderEnum.DESCENDING,
          limit: 10,
          sortValue: '1',
          offset: 0,
          page: 0,
        },
      },
    };
  }

  if (isType(action, filterDiscussionsActionCreator)) {
    return {
      ...state,
      list: {
        ...state.list,
        filter: {
          ...action.payload.filter,
          offset:
            action.payload.filter.offset === state.list.filter.offset
              ? 0
              : action.payload.filter.offset,
        },
      },
    };
  }

  if (isType(action, saveDiscussionActionCreator.started)) {
    return {
      ...state,
      create: {
        error: undefined,
        isSavingPublished: action.payload.status === ContentStatus.PUBLISHED,
        isSavingDraft: action.payload.status === ContentStatus.DRAFT,
      },
    };
  }
  if (isType(action, saveDiscussionActionCreator.success)) {
    return {
      ...state,
      create: {
        isSavingPublished: false,
        isSavingDraft: false,
        error: undefined,
      },
    };
  }
  if (isType(action, saveDiscussionActionCreator.failed)) {
    return {
      ...state,
      create: {
        isSavingPublished: false,
        isSavingDraft: false,
        error: action.payload.error.message,
      },
    };
  }

  if (isType(action, updateDiscussionActionCreator.started)) {
    return {
      ...state,
      update: {
        isUpdating: true,
        error: undefined,
      },
    };
  }
  if (isType(action, updateDiscussionActionCreator.success)) {
    return {
      ...state,
      update: {
        isUpdating: false,
        error: undefined,
      },
    };
  }
  if (isType(action, updateDiscussionActionCreator.failed)) {
    return {
      ...state,
      update: {
        isUpdating: false,
        error: action.payload.error.message,
      },
    };
  }

  if (isType(action, editDiscussionActionCreator.started)) {
    return {
      ...state,
      edit: {
        ...state.edit,
        isPreparingToEdit: true,
        error: undefined,
      },
    };
  }
  if (isType(action, editDiscussionActionCreator.success)) {
    return {
      ...state,
      edit: {
        ...state.edit,
        isPreparingToEdit: false,
        error: undefined,
        form: action.payload.result,
      },
    };
  }
  if (isType(action, editDiscussionActionCreator.failed)) {
    return {
      ...state,
      edit: {
        ...state.edit,
        isPreparingToEdit: false,
        error: action.payload.error.message,
      },
    };
  }
  if (isType(action, cancelEditDiscussionActionCreator)) {
    return {
      ...state,
      edit: {
        ...state.edit,
        isPreparingToEdit: false,
        error: undefined,
        form: undefined,
      },
    };
  }
  return state;
};
