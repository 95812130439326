import * as React from 'react';
import { RootState } from '../../modules/rootReducer';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

interface IMyAnnualCycleRedirectPageProps {
  person: string;
}

class MyAnnualCycleRedirectPageComp extends React.PureComponent<
  IMyAnnualCycleRedirectPageProps
> {
  render() {
    return <Redirect to={'/person/' + this.props.person + '/annual-cycle'} />;
  }
}

const mapStateToProps = (
  state: RootState
): IMyAnnualCycleRedirectPageProps => ({
  person: state.auth && state.auth.user.profile.hash,
});

const MyAnnualCycleRedirectPage = connect<
  IMyAnnualCycleRedirectPageProps,
  {},
  {}
>(
  mapStateToProps,
  undefined
)(MyAnnualCycleRedirectPageComp);

export default MyAnnualCycleRedirectPage;
