import * as React from 'react';
import { CSSProperties, RefObject } from 'react';
import clamp from 'clamp-js';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import styled from 'styled-components';
import { CalendarCategoryEnum, OrderEnum, SortEnum } from '../../models/types';
import { UserSummary } from '../discussion/UserSummary';
import {
  ButtType,
  FollowButton2,
  MyType,
} from '../basic/FollowButton/FollowButton2';
import { Colors, safeInvoke } from '../common';
import { Tooltip } from '../basic/Tooltip/Tooltip';
import { Clap, ObjectType } from '../basic/Clap/Clap';
import { MunikumIcons } from '../common/icons';
import { commonMessages } from '../language/commonMessages';

import {
  IStaticImageType,
  StaticImage,
} from '../basic/StaticImage/StaticImage';
import { H2, TextSmall } from '../basic/Structural/typography';
import { ContentStatus } from '../../services/models/contentMeta';

export interface ICalendarInputQuery {
  sort: SortEnum;
  order: OrderEnum;
  limit: number;
  offset: number;
}

interface IAnnualCycleCardProps {
  item: any;
  canRemove?: boolean;
  style?: CSSProperties;
  onClickRemove?: (id: number | string) => void;
}

interface IDiscussionCardDispatch {
  onClick?: (uri: string) => void;
  onFollow?: (id: string, follow: boolean) => void;
  onTopicClick?: (uri: string) => void;
}

interface IDiscussionCardState {}

const messages = defineMessages({
  answers: {
    id: 'DiscussionCard.answers',
    defaultMessage: 'answers',
  },
  views: {
    id: 'DiscussionCard.views',
    defaultMessage: 'views',
  },
  followers: {
    id: 'DiscussionCard.followers',
    defaultMessage: 'followers',
  },
  files: {
    id: 'DiscussionCard.files',
    defaultMessage: 'files',
  },
  organizationYearWheel: {
    id: 'AnnualCycleCard.organizationYearWheel',
    defaultMessage: 'Organization',
  },
  topicYearWheel: {
    id: 'AnnualCycleCard.topicYearWheel',
    defaultMessage: 'Topic',
  },
});

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  background-color: ${props => props.theme.contentBackgroundColor};
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(208, 211, 212, 0.2);
  height: 240px;
  width: 100%;
  position: relative;
  margin-bottom: 1.5em; // 0.625em;

  transition: box-shadow 0.2s ease-in-out;

  ${(props: { isLimited: boolean }) =>
    (props.isLimited && 'opacity: 0.5;') || ''};

  &:hover {
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }
`;

const FooterRowContainer = styled.div`
  display: flex;
  padding: 0.5em;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(208, 211, 212, 0.2);
`;

const SmallTextDiv = styled.div`
  font-family: 'Lato', sans-serif;
  color: ${props => props.theme.textColor};
  font-size: 0.65em;
  margin-right: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 12em;
`;

const SummaryContainer = styled.div`
  display: flex;
  //background-color: lightgreen;
  margin-left: 0.75em;
  margin-right: 4px;
  align-items: center;
  justify-content: flex-end;
`;

const SummaryText = styled.div`
  display: inline-block;

  font-size: 0.625em;
  line-height: 1em;
  //margin-bottom: 2em;
  @media (min-width: ${1710}px) {
    //margin-bottom: 2em;
  }
  font-weight: bold;
  font-family: Lato, sans-serif;
  color: ${props => props.theme.colorText};
  white-space: nowrap;
`;

const FollowContainer = styled.div`
  //flex: 1 1 0px;
  margin-left: 0.5em;
  display: flex;
  align-items: center;

  //background-color: yellow;
`;

const ImageAndTextContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 0.5em;
`;

const RemoveDiv = styled.div`
  transition: 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
`;

const CardContentContainer = styled.div`
  //padding: 1.25em;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-wrap: break-word;
  word-break: break-word; /* Chrome, Safari */
  word-wrap: break-word; /* IE11, Firefox */
`;

const WheelSvg = styled.svg`
  width: 75%;

  @media (min-width: ${1460}px) {
    align-items: flex-end;
  }
`;
const UserDiv = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media (min-width: ${1460}px) {
  }
`;
const TopDiv = styled.div`
  padding: 2px 10px;
  display: flex;
  justify-content: space-between;
  height: 36px;
  align-items: center;
  border-radius: 4px 4px 0 0;

  box-sizing: border-box;
`;
const ContentCont = styled.div`
  display: flex;
  height: 160px;
`;
const YearWheelTypeDiv = styled.div`
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 12px;
`;
/**
 * Card, used in DiscussionList
 */

interface IAnnualCycleCardState {
  wheelSizeBazenOnScreenSize: number;
  isPopoverOpen: boolean;
}
class AnnualCycleCardComponent extends React.PureComponent<
  IAnnualCycleCardProps & InjectedIntlProps,
  IAnnualCycleCardState
> {
  private titlRef: RefObject<any> = null;

  constructor(props: any) {
    super(props);
    this.titlRef = React.createRef();
    this.state = {
      wheelSizeBazenOnScreenSize: 75,
      isPopoverOpen: false,
    };
  }
  componentDidMount() {
    if (this.titlRef.current) {
      let module = this.titlRef.current;
      clamp(module, { clamp: 2 });
    }
  }
  render() {
    const { item, intl, style } = this.props;
    // console.log(item, 'topics');
    const followers = (item && item.followers && item.followers.length) || 0;
    // let temp =
    //   item &&
    //   item.createdBy &&
    //   item.createdBy.organization &&
    //   item.createdBy.organization &&
    //   item.createdBy.organization.name.toLowerCase();

    const yearWheelImg = (
      <WheelSvg
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        fill={'#fff'}
        stroke={'#fff'}
        viewBox="0 0 400 400"
      >
        <path
          d="M243.8 114.286L290.98 32.43c-25.456-13.825-54.423-21.945-85.147-22.822v94.363c13.606.878 26.335 4.609 37.965 10.315zm52.887 80.758h94.364c-.878-30.724-8.997-59.691-22.823-85.147l-81.855 47.182c5.925 11.63 9.656 24.359 10.314 37.965zM194.862 104.19V9.608c-30.723.877-59.69 8.997-85.147 22.822l47.182 81.856c11.63-5.706 24.359-9.437 37.965-10.095zm86.244 43.451l81.855-47.182a192.195 192.195 0 0 0-62.324-62.324l-47.182 81.855c10.973 7.023 20.41 16.46 27.651 27.651zm-167.002 9.437l-81.855-47.182c-13.826 25.456-21.945 54.423-22.823 85.147h94.364c.878-13.606 4.608-26.335 10.314-37.965zm139.132 124.209l47.182 81.855a192.195 192.195 0 0 0 62.324-62.324l-81.855-47.182c-7.023 10.973-16.46 20.409-27.651 27.65zm-47.402 15.58v94.365c30.724-.878 59.691-8.998 85.147-22.823L243.8 286.555c-11.63 5.925-24.359 9.655-37.965 10.314zm-48.937-10.094l-47.182 81.855c25.456 13.826 54.424 21.945 85.147 22.823v-94.364c-13.606-.878-26.334-4.608-37.965-10.314zm-37.307-33.356l-81.855 47.181a192.195 192.195 0 0 0 62.324 62.325l47.182-81.856c-10.972-7.022-20.409-16.458-27.65-27.65zm-15.58-47.402H9.644c.878 30.723 8.998 59.69 22.823 85.147l81.855-47.182c-5.925-11.63-9.655-24.359-10.314-37.965zm192.677 0c-.658 13.606-4.389 26.334-10.095 37.965l81.856 47.182c13.825-25.456 21.945-54.424 22.823-85.147zM147.46 119.772L100.28 37.917a192.195 192.195 0 0 0-62.325 62.324l81.856 47.182c7.022-10.973 16.459-20.41 27.65-27.651z"
          id="path3760"
          strokeWidth="2.195"
        />
      </WheelSvg>
    );

    return (
      <CardContainer
        style={{ ...style, margin: '0' }}
        isLimited={item.isLimited}
      >
        <TopDiv
          style={{
            backgroundColor:
              this.props.item.category === CalendarCategoryEnum.TOPIC
                ? '#00778B'
                : this.props.item.category === CalendarCategoryEnum.ORGANIZATION
                ? '#F5A623'
                : this.props.item.category === CalendarCategoryEnum.PROJECT
                ? '#963821'
                : '#8CAD9C',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                height: '32px',
                width: '32px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {yearWheelImg}
            </div>
            <YearWheelTypeDiv>
              {this.props.item.category === CalendarCategoryEnum.TOPIC
                ? intl.formatMessage(messages.topicYearWheel).toUpperCase()
                : this.props.item.category === CalendarCategoryEnum.ORGANIZATION
                ? intl
                    .formatMessage(messages.organizationYearWheel)
                    .toUpperCase()
                : this.props.item.category === CalendarCategoryEnum.PROJECT
                ? intl.formatMessage(commonMessages.project).toUpperCase()
                : intl.formatMessage(commonMessages.grants).toUpperCase()}
            </YearWheelTypeDiv>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FollowButton2
              starColorActive={Colors.WHITE}
              borderButtonColor={'#fff'}
              style={{
                marginLeft: '.5em',
                marginRight: '.5em',
              }}
              followId={item.id}
              isFollowing={item.isFollowing}
              buttonType={ButtType.STAR}
              followType={MyType.CALENDAR}
            />
            {this.props.canRemove && (
              <Tooltip
                content={intl.formatMessage(
                  commonMessages.removeRelationToYearWheel
                )}
              >
                <RemoveDiv>
                  <MunikumIcons.Cancel
                    style={{
                      color: Colors.WHITE,
                      height: '1.4em',
                      width: '1.4em',
                      marginTop: '.1em',
                    }}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      safeInvoke(this.props.onClickRemove, item.id);
                    }}
                  />
                </RemoveDiv>
              </Tooltip>
            )}
          </div>
        </TopDiv>
        <ContentCont>
          <CardContentContainer>
            <div style={{ margin: '12px' }}>
              <H2
                style={{
                  fontFamily: 'Lato, sans-serif',
                  fontSize: '1.375em',
                  lineHeight: '1.25em',
                  fontWeight: 'bold',
                  maxHeight: '56px',
                }}
                ref={this.titlRef}
              >
                {item.title}{' '}
                {item.status === ContentStatus.ARCHIVED && (
                  <span style={{ fontStyle: 'italic' }}>
                    ({intl.formatMessage(commonMessages.archived)})
                  </span>
                )}
              </H2>
            </div>
            <UserDiv>
              <UserSummary
                style={{ maxWidth: '100%', marginLeft: '12px' }}
                userOrganizationImage={
                  item &&
                  item.createdBy &&
                  item.createdBy.organization &&
                  item.createdBy.organization.organizationImage
                }
                userOrgName={item.createdBy.organization.name}
                userImage={
                  item &&
                  item.createdBy &&
                  item.createdBy.picture &&
                  item.createdBy.picture
                }
                name={
                  item &&
                  item.createdBy &&
                  item.createdBy.name &&
                  item.createdBy.name
                }
                role={
                  (item &&
                    item.createdBy &&
                    item.createdBy.role &&
                    item.createdBy.role) ||
                  ''
                }
                id={
                  item &&
                  item.createdBy &&
                  item.createdBy.id &&
                  item.createdBy.id
                }
                organizationUrl={item.createdBy.organization.url}
              />
              <ImageAndTextContainer>
                <StaticImage
                  filename={
                    item &&
                    item.createdBy &&
                    item.createdBy.organization &&
                    item.createdBy.organization.organizationImage
                  }
                  type={IStaticImageType.ORGANIZATION}
                  width={20}
                  height={20}
                />
                <SmallTextDiv
                  style={{
                    marginLeft: '1.6em',
                    fontWeight: 'bold',
                    letterSpacing: '.5px',
                  }}
                >
                  {item.createdBy.organization.name}
                </SmallTextDiv>
              </ImageAndTextContainer>
            </UserDiv>

            {item.isLimited && (
              <div style={{}}>
                <MunikumIcons.Padlock
                  style={{
                    width: '3em',
                    height: '3em',
                    opacity: 1,
                    position: 'absolute',
                    bottom: '35%',
                    left: '45%',
                    zIndex: 2,
                  }}
                />
              </div>
            )}
          </CardContentContainer>
        </ContentCont>
        <FooterRowContainer>
          {/*<CardLastUpdated style={{ marginLeft: '4px' }}>*/}
          {/*  <FormattedMessage*/}
          {/*    id={'DiscussionCard.lastActivity'}*/}
          {/*    defaultMessage={'Last activity'}*/}
          {/*  />*/}
          {/*  :{' '}*/}
          {/*  {moment*/}
          {/*    .utc(item.lastUpdated)*/}
          {/*    .local()*/}
          {/*    .format('YYYY-MM-DD')}*/}
          {/*</CardLastUpdated>*/}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FollowContainer>
              <Clap
                idToRefetch={item.id}
                ObjectToClap={ObjectType.CALENDAR}
                isClapped={item.isLiked}
                idToClap={item.id}
              />
              <TextSmall
                style={{
                  marginLeft: '.5em',
                }}
              >
                {this.props.item.likes}{' '}
                {intl.formatMessage(commonMessages.claps)}
              </TextSmall>
            </FollowContainer>
            <SummaryContainer style={{}}>
              <SummaryText>
                {followers}
                {'  '}
                {intl.formatMessage(messages.followers)}
              </SummaryText>
            </SummaryContainer>
          </div>
        </FooterRowContainer>
      </CardContainer>
    );
  }
}

const AnnualCycleCard = injectIntl(AnnualCycleCardComponent);

export default AnnualCycleCard;
