import * as React from 'react';
import styled from 'styled-components';
import {
  IMessageProps,
  MessageType,
  MultiMessageType,
  removeNonHTMLProps,
} from '../../common';
import { ValidationMessage } from '..';

const Container = styled.div`
  //padding-top: 0.175em;
  //padding-bottom: 0.175em;
`;

export class ValidationSummary extends React.Component<
  IMessageProps & { className?: string },
  {}
> {
  render() {
    const { info, warning, error, success, className } = this.props;

    // tslint:disable-next-line
    const messages: Array<any> = [];
    let count = 0;

    const pushMsg = (msg: MultiMessageType, type: MessageType) => {
      if (msg) {
        if (typeof msg === 'string') {
          messages.push(
            <ValidationMessage type={type} msg={msg} key={type + '_36'} />
          );
          count = count + 1;
        } else if (msg instanceof Array) {
          msg.forEach((item: string, index: number) => {
            messages.push(
              <ValidationMessage
                type={type}
                msg={item}
                key={type + '_' + index}
              />
            );
            count = count + 1;
          });
        }
      }
    };

    // positive first?
    pushMsg(info, MessageType.INFO);
    pushMsg(success, MessageType.SUCCESS);
    pushMsg(warning, MessageType.WARNING);
    pushMsg(error, MessageType.ERROR);

    if (count === 0) {
      return null;
    }

    return <Container className={className}>{messages}</Container>;
  }
}

export const FixedValidationSummary: React.SFC<IMessageProps & {
  heightEm: number;
  className?: string;
}> = props => {
  const { heightEm, ...rest } = props;
  const Temp = styled(ValidationSummary)`
    min-height: ${heightEm}em;
    margin-top: 0.5em;
  `;
  return <Temp {...removeNonHTMLProps(rest)} />;
};
