import * as React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import styled from 'styled-components';
import { commonMessages } from '../../language/commonMessages';
import { PageInfo } from '../../../models/types';
import { ColorTheme, safeInvokeDeprecated } from '../../common';
import { DropDownSelect } from '../DropDownSelect/DropDownSelect';
import { Button } from '..';
import {
  MunikumScrollContext,
  MunikumScrollHelpers,
} from '../../ScrollToTop/MunikumScrollContext';

export interface IPagerProps {
  pageInfo: PageInfo;
  onPageChanged: (pageNumber: number) => void;
}

const PagerDiv = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PagerSubDiv = styled.span`
  padding: 20px;
`;

class GraphQLPagerComponent extends React.PureComponent<
  IPagerProps & InjectedIntlProps,
  {}
> {
  handlePageChange = (pageNumber: number) => {
    safeInvokeDeprecated(() => this.props.onPageChanged(pageNumber));
  };

  render() {
    const { pageInfo, intl } = this.props;

    let pageArray: Array<{ value: number }> = [];

    for (let i = 0; i < pageInfo.numberOfPages; i++) {
      pageArray.push({ value: i + 1 });
    }

    return (
      <MunikumScrollContext.Consumer>
        {(scrollHelpers: MunikumScrollHelpers) => (
          <div>
            <PagerDiv>
              <PagerSubDiv>
                <Button
                  text={intl.formatMessage(commonMessages.previous)}
                  onClick={() => {
                    this.handlePageChange(pageInfo.pageNumber - 1);
                    scrollHelpers.scrollToTop();
                  }}
                  disabled={!pageInfo.hasPreviousPage}
                />
              </PagerSubDiv>
              <PagerSubDiv>
                {intl.formatMessage(commonMessages.page)}:{' '}
                <div
                  style={{
                    width: '36px',
                    display: 'inline-block',
                    paddingLeft: '3px',
                    marginRight: '.2em',
                    paddingRight: '3px',
                  }}
                >
                  {' '}
                  <DropDownSelect
                    name={'pages'}
                    showEmptyOption={false}
                    onChangeSelected={e => {
                      this.handlePageChange(pageArray[e].value);
                      scrollHelpers.scrollToTop();
                    }}
                    items={pageArray}
                    value={(pageInfo.pageNumber - 1).toString()}
                    accessor={'value'}
                    style={{
                      height: '36px',
                      width: ' 36px',
                      // float: 'left',
                      minWidth: '36px',
                      maxWidth: '36px',
                      padding: '2px',
                      margin: '0',
                    }}
                  />
                </div>
                {intl.formatMessage(commonMessages.of)} {pageInfo.numberOfPages}
              </PagerSubDiv>
              <PagerSubDiv>
                <Button
                  text={intl.formatMessage(commonMessages.next)}
                  theme={ColorTheme.Red}
                  onClick={() => {
                    this.handlePageChange(pageInfo.pageNumber + 1);
                    scrollHelpers.scrollToTop();
                  }}
                  disabled={!pageInfo.hasNextPage}
                />
              </PagerSubDiv>
            </PagerDiv>
          </div>
        )}
      </MunikumScrollContext.Consumer>
    );
  }
}

export const GraphQLPager = injectIntl(GraphQLPagerComponent);
