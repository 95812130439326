import * as React from 'react';
import { Suspense } from 'react';
import styled from 'styled-components';
import { SuspenseLoader } from '../../components/basic/Loader/CircleSpinner';
import { IDiscussionItem } from '../../services/models/discussion';
import { safeInvokeDeprecated } from '../../components/common';
import { ConnectedNotificationList } from '../../components/notification/NotificationList';
import { IActionValueItem } from '../../services/models/actionValueItem';
import { INotification } from '../../services/models/notification';
import { MunikumKeys } from '../../components/common/keys';
import { ITopicItem } from '../../services/models/topic';
import { sizes } from '../../components/common/media';
import { FollowedMetaList } from '../../components/meta/FollowedMetaList';

interface IRight {
  isHeaderFixed: boolean;

  isExpanded: boolean;
}

const RightSidebarContainer = styled.div`
  display: none; // mobile ;-)
  background-color: ${props => props.theme.contentBackgroundColor};

  @media (min-width: ${sizes.ipad}px) {
    display: block;
    width: 27.5em;
    height: calc(100% - 3.125em);
    z-index: 10;
    position: fixed;
    right: 0;

    transform: ${(props: IRight) =>
      props.isExpanded ? 'translateX(0)' : 'translateX(27.5em)'};
    transition: transform 0.25s ease-in-out;

    padding-bottom: 0;
    padding-top: 0;

    // NOTE: scrolls header too.. ok?
    // TODO: disable scrolling on content/body when this is visible!
    overflow-y: auto;

    // apple look:
    //box-shadow: -37px 0px 70px rgba(0, 0, 0, 0.3);

    // styleguide:
    box-shadow: -2px 2px 4px 0 rgba(51, 51, 51, 0.2);
  }

  @media print {
    display: none;
  }
`;

export const FilterTest = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  // test apple effect.:
  filter: blur(5px) brightness(1.1);
  width: 100%;
  height: 100%;
`;

export enum RightSidebarContentType {
  Favourites,
  Notifications,
}

export interface IRightSidebarProps {
  /**
   * is header fixed? (adjust height)
   */
  isHeaderFixed: boolean;

  /**
   * is right sidebar expanded, controlled mode only
   */
  isExpanded: boolean;

  notifications?: ReadonlyArray<INotification>;
  followedActionValues?: ReadonlyArray<IActionValueItem>;
  followedDiscussions?: ReadonlyArray<IDiscussionItem>;
  followedTopics?: ReadonlyArray<ITopicItem>;

  /**
   * controlled mode only
   */
  rightSidebarContent: RightSidebarContentType;

  /**
   * a whitelist of refs to ignore on click outside
   */
  whitelistRefs?: ReadonlyArray<HTMLElement>;
}

export interface IRightSidebarDispatch {
  /**
   * handler when user clicks outside sidebar component, close?
   */
  // tslint:disable-next-line
  onClickOutside?: (e: any) => void;

  /**
   * user clicked escape in right sidebar
   */
  onEscape?: () => void;
}

export class RightSidebar extends React.PureComponent<
  IRightSidebarProps & IRightSidebarDispatch,
  {}
> {
  // protected wrapperRef: HTMLDivElement | null | undefined;
  private wrapperRef: React.RefObject<HTMLDivElement>;

  constructor(props: IRightSidebarProps) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);

    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('keyup', this.handleKeyUp);
  }

  // NOTE: we dont unmount this.. we only hide it.. meaning all event listeners will be active all the time..
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('keyup', this.handleKeyUp);
  }

  // tslint:disable-next-line
  handleKeyUp(e: any) {
    if (this.props.isExpanded) {
      // only react to keyevents if expanded.
      if (e.keyCode === MunikumKeys.ESCAPE) {
        safeInvokeDeprecated(this.props.onEscape);
      }
    }
  }

  // tslint:disable-next-line
  handleClickOutside(e: any) {
    // if (this.wrapperRef !== undefined && this.wrapperRef !== null) {
    if (this.wrapperRef.current) {
      const isInsideRightSidebar = this.wrapperRef.current.contains(e.target);
      let isInsideWhitelist = false;

      // NOTE: whitelist not tested..
      if (this.props.whitelistRefs !== undefined) {
        for (let item of this.props.whitelistRefs) {
          if (item.contains(e.target)) {
            isInsideWhitelist = true;
            break;
          }
        }
      }

      if (
        this.props.isExpanded &&
        !isInsideRightSidebar &&
        !isInsideWhitelist
      ) {
        safeInvokeDeprecated(this.props.onClickOutside, e);
      }
    }
  }
  //
  // // tslint:disable-next-line
  //   handleKeyUp = (e: any) => {
  //     console.log('right keyup')
  //     if (e.keyCode === MunikumKeys.ESCAPE) {
  //       safeInvoke(this.props.onEscape);
  //     }
  //   };

  render() {
    const { rightSidebarContent, isHeaderFixed, isExpanded } = this.props;

    return (
      <RightSidebarContainer
        isHeaderFixed={isHeaderFixed}
        isExpanded={isExpanded}
        // ref={(ref: any) => (this.wrapperRef = ref)}
        ref={this.wrapperRef}
      >
        <Suspense fallback={<SuspenseLoader />}>
          <>
            {rightSidebarContent === RightSidebarContentType.Notifications && (
              <ConnectedNotificationList isSidebarOpen={isExpanded} />
            )}
            {rightSidebarContent === RightSidebarContentType.Favourites && (
              <FollowedMetaList isOpen={isExpanded} />
            )}
          </>
        </Suspense>
      </RightSidebarContainer>
    );
  }
}
