import * as React from 'react';
import Helmet from 'react-helmet';

export interface IMyHelmetProps {
  title?: string;
  username?: string;
  email?: string;
}

/**
 * MyHelmet
 * if you want to override <head><title>some title</title></head>,
 * just insert <Helmet><title>some title</title></Helmet> in sub components!! that will override title
 * but not piwik or other scripts
 */
export class MyHelmet extends React.PureComponent<IMyHelmetProps> {
  onChangeClientState = () => {};

  render() {
    const { title } = this.props;

    return (
      <Helmet
        onChangeClientState={this.onChangeClientState}
        titleTemplate={'Munikum - %s'}
        defaultTitle={'Munikum'}
      >
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
    );
  }
}
