import * as React from 'react';
import styled from 'styled-components';
import {
  Colors,
  IStylable,
  safeInvokeDeprecated,
} from '../../components/common';
import { rotate360 } from '../../components/animation';
import { FaCog } from 'react-icons/fa';

const AvatarContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

const Avatar = styled.div`
  background-color: ${Colors.RED};
  width: 3.125em;
  height: 3.125em;

  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Letters = styled.div`
  color: #fff;
  font-family: Lato, sans-serif;
  font-size: 1.45em;
  font-weight: 500;
  letter-spacing: 0.6px;

  text-transform: uppercase;
`;

const WheelContainer = styled.div`
  background-color: #fff;
  border-radius: 50%;

  position: absolute;
  bottom: -0.25em;
  right: -0.25em;

  width: 1.1em;
  height: 1.1em;
`;

const Spin = styled.div`
  position: absolute;
  top: 0.07em;
  left: 0.05em;

  display: flex;
  justify-content: center;
  align-items: center;

  //background-color: rgba(34, 34, 65, .3);

  transition: fill 0.25s ease-in-out;
  animation-fill-mode: forwards;
  animation: ${rotate360} 18s linear infinite;
  animation-play-state: ${(props: { isSpinning: boolean }) =>
    props.isSpinning ? 'running' : 'paused'};
`;

const Wheel = styled(FaCog)``;

export interface IAvatarButtonProps {
  /**
   * Initials, example: TS
   * two letters
   */
  initials: string;

  // Dont change this .. maybe =)
  // innerRef?: (ref: HTMLElement) => any;
  // innerRef?: React.RefObject<HTMLElement>;
}

export interface IAvatarButtonDispatch {
  onClick?: () => void;
}

export interface IAvatarButtonState {
  mouseHover: boolean;
}

export class AvatarButton extends React.PureComponent<
  IAvatarButtonProps & IStylable & IAvatarButtonDispatch,
  IAvatarButtonState
> {
  constructor(props: IAvatarButtonProps & IStylable) {
    super(props);

    this.state = {
      mouseHover: false,
    };
  }

  handleClick = () => {
    safeInvokeDeprecated(this.props.onClick);
  };

  handleMouse = (isHover: boolean) => {
    this.setState({
      mouseHover: isHover,
    });
  };

  render() {
    const { style, initials } = this.props;
    const { mouseHover } = this.state;

    const letters =
      initials && initials.length > 2 ? initials.substring(0, 2) : initials;

    return (
      <AvatarContainer
        // ref={innerRef}
        style={style}
        onMouseEnter={() => this.handleMouse(true)}
        onMouseLeave={() => this.handleMouse(false)}
        onClick={this.handleClick}
      >
        <Avatar onClick={this.handleClick}>
          <Letters>{letters}</Letters>
        </Avatar>
        <WheelContainer onClick={this.handleClick}>
          <Spin onClick={this.handleClick} isSpinning={mouseHover}>
            <Wheel fill={mouseHover ? Colors.RED : Colors.BLACK} size={'1em'} />
          </Spin>
        </WheelContainer>
      </AvatarContainer>
    );
  }
}
