import _ from 'lodash';
import * as React from 'react';
import styled from 'styled-components';
import { safeInvokeDeprecated } from '../common';
import { FieldGroup } from '../basic/FieldGroup/FieldGroup';
import { FormLabel } from '../basic/FormLabel/FormLabel';

const pals = require('nice-color-palettes');

const Palette1 = {
  BLACK: '#333333',
  BLUE: '#00B0B9',
  DARKBLUE: '#00778B',
  DARKYELLOW: '#A37F14',
  YELLOW: '#F2A900',
};

const Palette2 = {
  DARKPURPLEX: '#2D2232',
  PURPLEPILLS: '#604C68',
  BLUESTUFF: '#7796B5',
  BOBBY: '#877470',
  PINKY: '#F5EDEE',
};

const Container = styled.div``;

const Palette = styled.div`
  display: flex;
  flex-wrap: wrap;

  //margin-right: -.5em;
  //margin-bottom: -.5em;
`;

interface IColorBoxProps {
  color: string;
  isSelected: boolean;
  showOpacity: boolean;
}

const ColorBall = styled.div`
  background-color: ${(props: IColorBoxProps) =>
    !props.isSelected ? props.color : 'transparent'};
  //opacity: 0.5;
  border-radius: 50%;
  cursor: pointer;
  opacity: ${(props: IColorBoxProps) => (props.showOpacity ? 0.5 : 1)};
  position: relative;
  margin-right: 0.5em;
  margin-bottom: 0.5em;

  width: 1.75em;
  height: 1.75em;
  transform: scale(1);
  transition: transform 100ms ease 0s, box-shadow 100ms ease 0s;

  box-shadow: ${(props: IColorBoxProps) =>
    props.isSelected
      ? `${props.color} 0px 0px 0px .375em inset, ${props.color} 0px 0px 5px`
      : `${props.color} 0px 0px 0px 4px inset`};

  &:hover {
    transform: scale(1.2);
  }

  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    content: ' ';
  }
`;

export interface IColorPickerProps {
  onChange?: (color: string) => void;
  selectedColor?: string;
  label?: string;
  disabled?: boolean;
  showOpacity?: boolean;
  removeColorOption?: boolean;
  onRemoveColor?: () => void;
  palettes?: {
    showDefault: boolean;
    showSecond: boolean;
    showOthers: number;
  };
}

interface IColorPickerState {
  selectedColor?: string;
  id?: string;
}

export class ColorPicker extends React.PureComponent<
  IColorPickerProps,
  IColorPickerState
> {
  public static defaultProps: IColorPickerProps = {
    palettes: {
      showDefault: true,
      showSecond: false,
      showOthers: 0,
    },
  };

  constructor(props: IColorPickerProps) {
    super(props);

    this.state = {
      selectedColor: props.selectedColor || undefined,
    };
  }

  UNSAFE_componentWillMount() {
    const id = _.uniqueId('color-');
    this.setState({
      id: id,
    });
  }

  handleClick = (color: string) => {
    if (!this.props.disabled) {
      this.setState(
        {
          selectedColor: color,
        },
        () => {
          safeInvokeDeprecated(this.props.onChange, this.state.selectedColor);
        }
      );
    }
  };

  render() {
    const { label, palettes } = this.props;
    const { id, selectedColor } = this.state;

    return (
      <FieldGroup>
        {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
        <Container onClick={e => e.stopPropagation()}>
          {palettes && palettes.showDefault && (
            <Palette key={'palette_1asdf'}>
              {_.keys(Palette1).map(key => (
                <ColorBall
                  showOpacity={this.props.showOpacity}
                  color={Palette1[key]}
                  onClick={() => this.handleClick(Palette1[key])}
                  key={'_adf_' + key}
                  isSelected={selectedColor === Palette1[key]}
                />
              ))}
            </Palette>
          )}
          {palettes && palettes.showSecond && (
            <Palette key={'_pasdf_asdf55'}>
              {_.keys(Palette2).map(key => (
                <ColorBall
                  showOpacity={this.props.showOpacity}
                  color={Palette2[key]}
                  onClick={() => this.handleClick(Palette2[key])}
                  key={'ball_' + key}
                  isSelected={selectedColor === Palette2[key]}
                />
              ))}
            </Palette>
          )}

          {this.renderOtherPalettes()}
          {this.renderSelectedPalettes()}
          {/*{this.props.removeColorOption &&*/}
          {/*<div onClick={()=> {*/}
          {/*  safeInvoke(this.props.onRemoveColor);*/}
          {/*}} style={{color: Colors.RED, fontSize: '12px', fontFamily: 'Lato, sans-serif'}}>Fjern fra favoritt</div>*/}
          {/*}*/}
        </Container>
      </FieldGroup>
    );
  }

  renderOtherPalettes() {
    const temp: Array<any> = [];

    if (!this.props.palettes) {
      return null;
    }

    for (let i = 0; i < this.props.palettes.showOthers; i++) {
      temp.push(
        <Palette>
          {pals[i].map((color: string) => (
            <ColorBall
              showOpacity={this.props.showOpacity}
              key={'oth_' + color}
              color={color}
              onClick={() => this.handleClick(color)}
              isSelected={this.state.selectedColor === color}
            />
          ))}
        </Palette>
      );
    }

    return temp;
  }

  renderSelectedPalettes() {
    const nice = [1, 14, 15, 24];

    const temp: Array<any> = [];
    nice.forEach((x: number) => {
      temp.push(
        <Palette key={'palette_' + x}>
          {pals[x].map((color: string) => (
            <ColorBall
              showOpacity={this.props.showOpacity}
              key={'ssc_' + color}
              color={color}
              onClick={() => this.handleClick(color)}
              isSelected={this.state.selectedColor === color}
            />
          ))}
        </Palette>
      );
    });

    return temp;
  }
}
ColorPicker.defaultProps = {
  disabled: false,
};
