import * as React from 'react';
import { CSSProperties, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { MunikumIcons } from '../../common/icons';
import { Colors, safeInvoke } from '../../common';
import { MunikumKeys } from '../../common/keys';

export interface ICheckboxProps {
  name?: string;

  checked?: boolean;
  defaultChecked?: boolean;
  onClick?: (e: any, checked: boolean) => void;
  disabled?: boolean;

  style?: CSSProperties;
  label?: React.ReactNode;
}

const HiddenCheck = styled.input`
  // hide actual checkbox:
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
  &:checked {
    // this works, but noe when referencing in span..
    //opacity: 0;
  }
`;

const MyLabel = styled.label`
  cursor: ${(props: { isDisabled: boolean }) =>
    props.isDisabled ? 'not-allowed' : 'pointer'};
  margin-bottom: 0.5em;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  outline: none;
`;

// const border = ColorLib('#CED0DA');
// const black = ColorLib('#333');
// const from = border.string();
// const to = border.alpha(0.1).string();

interface My {
  isChecked: boolean;
  isHovering: boolean;
  isDisabled: boolean;
}

const Box = styled.div`
  width: 1.25em;
  height: 1.25em;
  border-radius: 4px;
  margin-bottom: -.2em;
  background-color: ${(props: My) =>
    props.isDisabled
      ? 'rgba(208,211,212,0.4)'
      : props.isChecked
      ? Colors.RED
      : props.isHovering
      ? '#E8EAF4'
      : '#fff'};
  background-image: ${(props: My) =>
    props.isDisabled
      ? 'unset'
      : !props.isChecked && props.isHovering
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))'
      : 'unset'};

  // TODO: can we get transition on background-image?
  // => yep, using pseudo elements: https://medium.com/@dave_lunny/animating-css-gradients-using-only-css-d2fd7671e759

  // transition: border .15s ease-in; // , background-color .15s ease-in;
  
  position: relative;
  cursor: ${(props: My) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  
  background-clip: padding-box;

  // border: ${(props: My) =>
    props.isChecked ? '1px solid transparent' : '1px solid #CED0DA'};  
  border: ${(props: My) =>
    props.isChecked ? '1px solid ' + Colors.RED : '1px solid #CED0DA'};  
  box-sizing: border-box;

`;

// fill: ${(props: {ischecked: boolean; ishovering: boolean}) => (props.ischecked) ? '#fff' : '#333'};
// opacity: ${(props: {ischecked: boolean; ishovering: boolean}) => (props.ischecked) ? 1 : props.ishovering ? 0 : 0};

const StyledCheckIcon = styled(MunikumIcons.Check)`
  position: absolute;
  top: 4px;
  left: 4px;
  width: 10px;
  height: 10px;
  size: 10px;
  fill: #fff;

  transition: opacity 0.15s ease-in, transform 0.15s ease-in, fill 0.15s ease-in;
`;

const StyledCheck: React.FunctionComponent<My> = props => {
  const { isChecked, isHovering, isDisabled, ...rest } = props;

  return (
    <StyledCheckIcon
      {...rest}
      style={{
        opacity: isChecked ? 1 : 0,
        transform: isHovering ? 'scale(1.1)' : 'scale(1)',
      }}
    />
  );
};

const Info = styled.div`
  margin-left: 0.5em;
  color: ${props => props.theme.textColor};
  white-space: nowrap;
  opacity: ${(props: { isDisabled: boolean }) => (props.isDisabled ? 0.5 : 1)};
`;

/**
 * Checkbox2
 * a "normal" checkbox, with working label, and only red color =)
 */
export const Checkbox: React.FunctionComponent<ICheckboxProps> = props => {
  const myId = _.uniqueId('cb2-');
  const [isChecked, setIsChecked] = useState(
    props.checked || props.defaultChecked || false
  );
  const [isHovering, setIsHovering] = useState(false);

  const handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (!props.disabled) {
      // TODO: how to invoke event on setState callback??
      const nextVal = !isChecked;
      setIsChecked(nextVal);
      safeInvoke(props.onClick, e, nextVal);
    }
  };

  return (
    <MyLabel
      isDisabled={props.disabled}
      style={props.style}
      htmlFor={myId}
      onClick={handleClick}
      onMouseEnter={e => setIsHovering(true)}
      onMouseLeave={e => setIsHovering(false)}
      tabIndex={0}
      onKeyDown={(e: any) => {
        if (e.which === MunikumKeys.ENTER || e.which === MunikumKeys.SPACE) {
          if (!props.disabled) {
            const next = !isChecked;
            setIsChecked(next);
            safeInvoke(props.onClick, e, next);
          }
        }
      }}
    >
      <HiddenCheck
        id={myId}
        type={'checkbox'}
        name={props.name}
        onChange={handleClick}
        checked={isChecked}
      />
      <Box
        isChecked={isChecked}
        isHovering={isHovering}
        isDisabled={props.disabled}
      >
        <StyledCheck
          isHovering={isHovering}
          isChecked={isChecked}
          isDisabled={props.disabled}
        />
      </Box>
      <Info
        isDisabled={props.disabled}
        onMouseEnter={e => setIsHovering(true)}
        onMouseLeave={e => setIsHovering(false)}
      >
        {props.label}
      </Info>
    </MyLabel>
  );
};
