import * as React from 'react';
import styled from 'styled-components';
import clamp from 'clamp-js';

import {
  ButtType,
  FollowButton2,
  MyType,
} from '../basic/FollowButton/FollowButton2';
import { Colors, safeInvokeDeprecated } from '../common';
import { MetaType } from '../../services/models/contentMeta';
import { MuPreview } from '../basic/MuEditor/MuPreview';

const ItemHeader = styled.div`
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: bold;
  //height: 1.063em;
  line-height: 1.063em;
  //white-space: nowrap;
  max-width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 0.25em;
`;

const ItemContainer = styled.div`
  border-bottom: 1px solid rgba(208, 211, 212, 0.7);
  display: flex;
  justify-content: space-between;

  cursor: pointer;
  width: 100%;
`;

const Left = styled.div`
  padding: 1.875em;
`;

const Right = styled.div`
  padding-top: 1.875em;
  padding-right: 1.875em;
`;

const ItemBody = styled.div`
  font-family: 'Lato', sans-serif;
  font-size: 0.875em; // 14px
  line-height: 1.375em; // 22px
  color: ${Colors.BLACK70};
  max-width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export interface IFollowedMetaListItemProps {
  id: string;
  uri: string;
  type: MetaType;
  title: string;
  tooltip?: string;
  text: string;
  watched: boolean;
  followType: MyType;
}

export interface IFollowedMetaListItemDispatch {
  onClick?: (uri: string) => void;
  onFollow?: (id: string, follow: boolean) => void;
}

export class FollowedMetaListItem extends React.PureComponent<
  IFollowedMetaListItemProps & IFollowedMetaListItemDispatch,
  {}
> {
  private descriptionRef: any = React.createRef();
  constructor(props: any) {
    super(props);
    this.descriptionRef = React.createRef();
  }
  handleClick = (uri: string) => {
    switch (this.props.type) {
      case MetaType.DISCUSSION:
        uri = '/forum/public/' + uri;
        break;
      case MetaType.ACTION_VALUE:
        uri = '/action-value/' + uri;
        break;
      case MetaType.TOPIC:
        uri = '/topic/' + uri;
        break;
      default:
        break;
    }

    safeInvokeDeprecated(this.props.onClick, uri);
  };
  componentDidMount() {
    if (this.descriptionRef.current) {
      let module = this.descriptionRef.current;
      clamp(module, { clamp: 4 });
    }
  }

  handleFollow = (id: string, follow: boolean) => {
    safeInvokeDeprecated(this.props.onFollow, id, follow);
  };

  render() {
    const { id, uri, title, text, watched } = this.props;

    let previewText: JSX.Element = <div />;

    if (text) {
      if (text.length <= 100) {
        previewText = <MuPreview rawMarkdown={text || ''} />;
      } else {
        previewText = <MuPreview rawMarkdown={text || ''} />;
      }
    }

    return (
      <ItemContainer onClick={() => this.handleClick(uri)}>
        <Left>
          <ItemHeader>{title}</ItemHeader>
          <ItemBody ref={this.descriptionRef}>{previewText}</ItemBody>
        </Left>
        <Right>
          <FollowButton2
            isFollowing={watched}
            buttonType={ButtType.STAR}
            followType={this.props.followType}
            followId={id}
          />
        </Right>
      </ItemContainer>
    );
  }
}
