import * as React from 'react';
import { CSSProperties, RefObject, useEffect } from 'react';
import styled from 'styled-components';
import * as _ from 'lodash';
import { IStaticImageType, StaticImage } from '../StaticImage/StaticImage';
import {
  ContentTypeEnum,
  ListDivMunicipalities,
} from '../../../containers/AnnualCycle/CalendarListPage';
import { Colors, IThemeProps } from '../../common';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { commonMessages } from '../../language/commonMessages';

const Container = styled.div`
  display: flex;
  background-color: ${props => props.theme.secondaryContentBackgroundColor};
  height: 31px;
  position: relative;
  border-radius: 3px;
  border: 1px solid ${props => props.theme.noAccent.borderColor};
  width: 100%;
  /*Removes the default x on input field in ie11*/
  input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  /* clears the 'X' from Chrome */
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }
  &:hover {
    cursor: text;
  }
  transition: box-shadow 0.2s ease-in-out;
`;

const Input = styled.input`
  border: none;
  color: ${props => props.theme.textColor};
  font-family: Lato, sans-serif;
  font-size: 14px;
  background-color: ${props => props.theme.secondaryContentBackgroundColor};
  border-radius: 3px;
  margin-left: 0.5em;
  width: 70%;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-appearance: none;
  -webkit-moz-appearance: none;
  appearance: none;
  font-weight: bold;
  &:focus:enabled {
    outline: none;
  }
`;
const RemoveButton = styled.div`
  display: flex;
  width: 15%;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  margin-left: 4px;
  &:hover {
    cursor: pointer;
  }
`;
const Popover = styled.div`
  position: absolute;
  box-sizing: border-box;
  border-radius: 3px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  padding: 0.5em;
  z-index: 3;
  top: 38px;
  min-width: 160px;
  max-height: 25em;
  width: max-content;
  max-width: 20em;
  overflow-y: auto;
  background-color: ${props => props.theme.secondaryContentBackgroundColor};
  //transition: 1s ease-in-out;
`;

const ListDiv = styled.div`
  padding: 0.5em;
  align-items: center;
  display: flex;
  font-size: 14px;
  font-family: Lato, sans-serif;
  background-color: ${(props: { theme: IThemeProps; isActive: boolean }) =>
    props.isActive
      ? props.theme.accent2.color
      : props.theme.secondaryContentBackgroundColor};

  border-bottom: 1px solid
    ${(props: { theme: IThemeProps; isActive: boolean }) =>
      props.theme.textColor};
`;

const SearchIcon = styled.div`
  color: rgb(255, 92, 57);
  display: flex;
  align-items: center;
  margin-left: 4px;
  margin-right: 4px;
  font-size: 18px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
`;

const SelectedMunicipalitiesDiv = styled.div`
  font-size: 14px;
  font-family: Lato, sans-serif;
  background-color: ${Colors.RED}
  color: ${props => props.theme.accent1.textColor};

  padding: .2em;
  box-sizing: border-box;
  text-align: center;
  border-bottom: 1px solid #333;
`;

export interface IItemType {
  id: number;
  label: string;
  img: any;
}

interface IAsyncSearchDropdown {
  /***
   * if true, a default styled list will appear and you will not be able to use the RenderItem prop
   */
  defaultListStyling: boolean;
  /**
   * boolean if you want to render a searchIcon
   */
  leftIcon?: boolean;

  /**
   * Array of items
   */
  items?: Array<any>;
  /**
   * A boolean for if the user can press the x button and clear all the selected items
   */
  canClearAll?: boolean;
  /**
   * Placeholder text for the input object,
   * Will be replaced by the label of the items you select
   */
  placeholder?: string;
  /**
   * Will be called everytime there is a change in the selection of items, and returns the new items
   * @param items
   */
  onChange: (items: Array<any>) => void;
  /**
   * When you want the dropdown to be disabled, grey's out the box and makes it unclickable
   */
  disabled?: boolean;
  /**
   * render prop for reusibility so you can style the list of items how you want it
   * @param items that is returned by the getItems promise
   * @param i the index of each item
   * @param isActive boolean for if the item is active
   */
  renderItem?: (
    items: Array<any>,
    i: number,
    isActive: boolean,
    isSelected: boolean,
    img: any
  ) => React.ReactNode;
  /**
   * if you want to change the size of the dropdown
   */
  style?: CSSProperties;
  /**
   * Default value for the dropdown
   */
  value?: string | undefined;

  noResultMessage?: string;
  getItems: (input: string) => Promise<Array<any>>;
}

const AsyncSearchDropdownComp: React.FunctionComponent<IAsyncSearchDropdown> = (
  props: InjectedIntlProps & IAsyncSearchDropdown
) => {
  let containerRef: RefObject<any>;
  let popoverRef: RefObject<any>;
  let searchRef: RefObject<any>;
  let debouncedFn: any;
  let timeOutId: any = null;
  const [searchValue, setSearchValue] = React.useState<string>('');
  containerRef = React.createRef();
  popoverRef = React.createRef();
  searchRef = React.createRef();

  const [items, setItems] = React.useState([]);
  const [isThereOrgs, setIsThereOrgs] = React.useState(false);
  const [isTherePersons, setIsTherePersons] = React.useState(false);
  const [isPopoverOpen, setPopoverState] = React.useState(false);
  const [hoveredIndex, sethoveredIndex] = React.useState(-1);
  const [lastSelectedItem, setLastSelectedItem] = React.useState(
    props.value || props.placeholder
  );
  const [selectedItems, setSelectedItems] = React.useState<Array<IItemType>>(
    props.items
  );

  const { intl } = props;

  // eslint-disable-next-line
  useEffect(() => {
    function handleKeyPress(e: any) {
      if (e.which === 38) {
        if (hoveredIndex > 0) {
          sethoveredIndex(c => c - 1);
        }
      } else if (e.which === 40) {
        if (hoveredIndex < selectedItems.length + items.length - 1) {
          sethoveredIndex(c => c + 1);
        }
      } else if (e.which === 13) {
        if (isPopoverOpen && hoveredIndex > -1) {
          if (hoveredIndex < selectedItems.length) {
            const selectedItem = selectedItems[hoveredIndex];
            handleClickItem(selectedItem, hoveredIndex);
          } else {
            const selectedItem = items[hoveredIndex - selectedItems.length];
            handleClickItem(selectedItem, hoveredIndex - selectedItems.length);
          }
        }
      } else if (e.which === 27) {
        setPopoverState(false);
        sethoveredIndex(-1);
      }
    }

    document.addEventListener('keyup', handleKeyPress);

    // oouuf, probaly a  better way for doing this.....
    let orgsCount = 0;
    let personCount = 0;
    // sort items to get right index when using arrow keys
    selectedItems.sort(function(a: any, b: any) {
      return a.type === b.type ? 0 : +(a.type > b.type) || -1;
    });
    selectedItems.forEach((t: any) => {
      if (t.type === ContentTypeEnum.ORGANIZATIONS) {
        orgsCount++;
      }
      if (t.type === ContentTypeEnum.PERSONS) {
        personCount++;
      }
    });
    orgsCount === 0 ? setIsThereOrgs(false) : setIsThereOrgs(true);
    personCount === 0 ? setIsTherePersons(false) : setIsTherePersons(true);
    return () => {
      document.removeEventListener('keyup', handleKeyPress);
    };
  });

  function handleClickItem(item: IItemType, index: number) {
    // check if item is already selected

    let isSelected2 = false;
    selectedItems.forEach(t => {
      if (t.id === item.id) {
        isSelected2 = true;
      }
    });
    /* creates a shallow copy of the selected items, then it pushes the new object into
       the copy. After that it runs the setSelectedItems hook with the copy as value
    */
    if (isSelected2) {
      const temp = selectedItems.slice();
      const indexToRemove = temp.findIndex(function(tempValue: any) {
        return tempValue.id === item.id;
      });
      temp.splice(indexToRemove, 1);
      setSelectedItems(temp);

      props.onChange(temp);
      setLastSelectedItem(c =>
        c.replace(item.label.replace('KOMMUNE', ''), '')
      );
      setSearchValue('');
    } else {
      const copy = selectedItems.slice();
      copy.push(item);
      setSelectedItems(copy);

      // Changes the placeholder text to the items that are chosen
      if (selectedItems.length > 0) {
        setLastSelectedItem(c => c + ' ' + item.label.replace('KOMMUNE', ''));
      } else {
        setLastSelectedItem(item.label.replace('KOMMUNE', ''));
      }
      // Empties the input's value, dispatches an onChange event, and closes the popover

      setItems([]);

      setSearchValue('');
      sethoveredIndex(-1);
      setPopoverState(false);

      props.onChange(copy);
    }
  }

  function handleSearchChange(e: any) {
    e.persist();
    sethoveredIndex(-1);
    setSearchValue(e.target.value);
    if (e.target.value.length > 2) {
      setPopoverState(true);
    }
    if (!debouncedFn) {
      debouncedFn = _.debounce(() => {
        props.getItems(e.target.value).then((result: any) => {
          // filter out selectedItems
          let newRes = [];
          result.forEach((value, i) => {
            newRes.push(value);
            selectedItems.forEach(selectedItem => {
              if (selectedItem.id === value.id) {
                const indexToRemove = newRes.findIndex(function(
                  tempValue: any
                ) {
                  return tempValue.id === value.id;
                });
                newRes.splice(indexToRemove);
              }
            });
          });
          setItems(newRes);
        });
      }, 300);
    }

    debouncedFn();
  }

  function clearAllItems() {
    setSelectedItems([]);
    setLastSelectedItem('');
    setSearchValue('');
    props.onChange([]);
  }

  function onBlurHandler() {
    timeOutId = setTimeout(() => {
      setPopoverState(false);
    }, 200);
  }
  return (
    <Container
      style={props.style}
      ref={containerRef}
      onBlur={() => onBlurHandler()}
      onFocus={() => {
        if (searchValue.length > 2) {
          setPopoverState(true);
        }
      }}
    >
      {props.leftIcon && (
        <SearchIcon
          onClick={() => {
            if (searchRef.current) {
              searchRef.current.focus();
            }
          }}
        >
          &#9906;
        </SearchIcon>
      )}
      <Input
        ref={searchRef}
        type="search"
        value={searchValue}
        onChange={e => handleSearchChange(e)}
        placeholder={lastSelectedItem || props.placeholder || 'Search here...'}
        onClick={() => {
          setPopoverState(true);
        }}
      />
      {props.canClearAll && (
        <RemoveButton onClick={() => clearAllItems()}>&#10005;</RemoveButton>
      )}
      {props.items && isPopoverOpen && (
        <Popover
          ref={popoverRef}
          onBlur={() => onBlurHandler()}
          onClick={e => {
            clearTimeout(timeOutId);
          }}
        >
          {items.length === 0 && selectedItems.length === 0 && (
            <div
              style={{
                display: 'flex',
                fontFamily: 'Lato,sans-serif',
                fontSize: '14px',
                fontWeight: 'bold',
                justifyContent: 'center',
              }}
            >
              {props.noResultMessage || 'No result...'}
            </div>
          )}
          {selectedItems.length > 0 && isThereOrgs && (
            <SelectedMunicipalitiesDiv>
              {intl.formatMessage(commonMessages.selectedOrgs)}
            </SelectedMunicipalitiesDiv>
          )}
          {selectedItems.map((selectedItem: any, i) => {
            const isActive = hoveredIndex === i;
            if (selectedItem.type === ContentTypeEnum.ORGANIZATIONS) {
              return (
                <ListDivMunicipalities
                  isActive={isActive}
                  onMouseEnter={() => {
                    sethoveredIndex(i);
                  }}
                  onMouseLeave={() => {
                    sethoveredIndex(-1);
                  }}
                  onClick={() => {
                    handleClickItem(selectedItem, i);
                  }}
                  key={selectedItem.id + i}
                  style={{
                    cursor: isActive ? 'pointer' : 'normal',
                  }}
                >
                  <div
                    style={{
                      height: '1.6em',
                      fontSize: '18px',
                      color: 'red',
                      marginRight: '1em',
                    }}
                  >
                    &#10003;
                  </div>
                  {selectedItem.label}
                  <StaticImage
                    key={i}
                    width={21}
                    height={21}
                    style={{ marginLeft: '1em' }}
                    type={IStaticImageType.ORGANIZATION}
                    filename={selectedItem.img}
                  />
                </ListDivMunicipalities>
              );
            }
            return '';
          })}
          {selectedItems.length > 0 && isTherePersons && (
            <SelectedMunicipalitiesDiv>
              {intl.formatMessage(commonMessages.selectedPersons)}
            </SelectedMunicipalitiesDiv>
          )}
          {selectedItems.map((selectedItem: any, i) => {
            const isActive = hoveredIndex === i;
            if (selectedItem.type === ContentTypeEnum.PERSONS) {
              return (
                <ListDivMunicipalities
                  isActive={isActive}
                  onMouseEnter={() => {
                    sethoveredIndex(i);
                  }}
                  onMouseLeave={() => {
                    sethoveredIndex(-1);
                  }}
                  onClick={() => {
                    handleClickItem(selectedItem, i);
                  }}
                  key={selectedItem.id + i}
                  style={{
                    cursor: isActive ? 'pointer' : 'normal',
                  }}
                >
                  <div
                    style={{
                      height: '1.6em',
                      fontSize: '18px',
                      color: 'red',
                      marginRight: '1em',
                    }}
                  >
                    &#10003;
                  </div>
                  {selectedItem.label}
                  <StaticImage
                    key={i}
                    width={21}
                    height={21}
                    style={{ marginLeft: '1em' }}
                    type={IStaticImageType.ORGANIZATION}
                    filename={selectedItem.img}
                  />
                </ListDivMunicipalities>
              );
            }
            return '';
          })}
          {(selectedItems.length || items.length) > 0 && (
            <SelectedMunicipalitiesDiv>
              {intl.formatMessage(commonMessages.orgSearchResult)}
            </SelectedMunicipalitiesDiv>
          )}
          {selectedItems.length > 0 && items.length === 0 && (
            <div
              style={{
                marginTop: '1em',
                display: 'flex',
                fontFamily: 'Lato,sans-serif',
                fontSize: '14px',
                fontWeight: 'bold',
                justifyContent: 'center',
              }}
            >
              {props.noResultMessage || 'No result...'}
            </div>
          )}
          {items &&
            items.map((c: any, i) => {
              const isActive = hoveredIndex === i + selectedItems.length;
              let isSelected = false;
              selectedItems.forEach(t => {
                if (t.id === c.id) {
                  isSelected = true;
                }
              });
              return (
                <div
                  onMouseEnter={() => {
                    sethoveredIndex(i + selectedItems.length);
                  }}
                  onMouseLeave={() => {
                    sethoveredIndex(-1);
                  }}
                  key={i}
                  onClick={() => {
                    handleClickItem(c, i);
                  }}
                >
                  {!props.defaultListStyling &&
                    props.renderItem &&
                    props.renderItem(c, i, isActive, isSelected, c.img)}
                  {props.defaultListStyling && (
                    <ListDiv
                      isActive={isActive}
                      style={{
                        cursor: isActive ? 'pointer' : 'normal',
                      }}
                    >
                      <div
                        style={{
                          height: '1.6em',
                          fontSize: '18px',
                          color: isSelected ? 'red' : 'grey',
                          marginRight: '.5em',
                        }}
                      >
                        &#10003;
                      </div>
                      {c.label}
                    </ListDiv>
                  )}
                </div>
              );
            })}
        </Popover>
      )}
    </Container>
  );
};
export const AsyncSearchDropdown = injectIntl(AsyncSearchDropdownComp);
