import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { call, put, take } from 'redux-saga/effects';
import { ITopicCreateOrUpdateForm } from '../../services/topicService';
import { createUploadFileChannel } from '../file/file';
import { IApiError, wrapApiError } from '../../services/api';
import { IFileItem } from '../../components/basic/UserFile/FileUploaderPicker';
import { actionCreatorFactory } from '../actionCreatorFactory';

const actionCreator = actionCreatorFactory('TOPIC');

export const updateTopicActionCreator = actionCreator.async<
  ITopicCreateOrUpdateForm,
  {},
  IApiError
>('UPDATE');

export const saveTopicActionCreator = actionCreator.async<
  ITopicCreateOrUpdateForm,
  {},
  IApiError
>('SAVE');

interface ITopicUploadFiles {
  files: ReadonlyArray<IFileItem>;
  topicMetaId: string;
}

export const uploadFilesToTopicAtionCreator = actionCreator.async<
  ITopicUploadFiles,
  {},
  IApiError
>('UPLOADFILES');

export interface ITopicState {
  updateError?: string;
  updateIsSaving: boolean;
  createError?: string;
  createIsSaving: boolean;
}

const initialState: ITopicState = {
  updateError: undefined,
  updateIsSaving: false,
  createError: undefined,
  createIsSaving: false,
};

export const reducer = (
  state: ITopicState = initialState,
  action: Action
): ITopicState => {
  if (isType(action, updateTopicActionCreator.started)) {
    return {
      updateIsSaving: true,
      updateError: undefined,
      ...state,
    };
  }
  if (isType(action, updateTopicActionCreator.success)) {
    return {
      updateIsSaving: false,
      updateError: undefined,
      ...state,
    };
  }
  if (isType(action, updateTopicActionCreator.failed)) {
    console.log('error updating topic: ', action.payload.error);
    return {
      updateIsSaving: false,
      updateError: action.payload.error.message,
      ...state,
    };
  }
  if (isType(action, saveTopicActionCreator.started)) {
    return {
      createIsSaving: true,
      createError: undefined,
      ...state,
    };
  }
  if (isType(action, saveTopicActionCreator.success)) {
    return {
      createIsSaving: false,
      createError: undefined,
      ...state,
    };
  }
  if (isType(action, saveTopicActionCreator.failed)) {
    console.log('error updating topic: ', action.payload.error);
    return {
      createIsSaving: false,
      createError: action.payload.error.message,
      ...state,
    };
  }

  return state;
};

export function* uploadFilesToTopicSaga() {
  while (true) {
    const action = yield take(uploadFilesToTopicAtionCreator.started.type);

    try {
      console.log('upload files to topic...', action.payload);

      if (action.payload.files && action.payload.files.length > 0) {
        for (let sFile of action.payload.files) {
          const channel = yield call(createUploadFileChannel, {
            file: sFile.file,
            metaid: action.payload.topicMetaId,
          });

          while (true) {
            const { success, err } = yield take(channel);
            if (err) {
              throw Error('File upload failed');
            }
            if (success) {
              console.log('finishedUploading files');
              break;
            }
          }
        }
      }

      yield put(
        uploadFilesToTopicAtionCreator.success({
          params: action.payload,
          result: {},
        })
      );
    } catch (e) {
      yield put(
        updateTopicActionCreator.failed({
          params: action.payload,
          error: wrapApiError(e),
        })
      );
    }
  }
}
