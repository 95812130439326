import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import {
  IStaticImageType,
  StaticImage,
} from '../basic/StaticImage/StaticImage';
import { Tooltip } from '../basic/Tooltip/Tooltip';
import { UserPreview } from '../basic/UserPreview/UserPreview';

const profileImage = require('./user.svg') as string;

export interface IUserSummaryProps {
  id: string;
  name: string;
  role: string;
  // use this for orgName if you dont want the userSummary to include the org in the ui, but in userPreview
  userOrgName?: string;
  // use this for orgImg if you dont want the userSummary to include the org in the ui, but in userPreview
  userOrganizationImage?: string;

  organizationImage?: string;
  organizationName?: string;
  organizationUrl?: string;
  userImage?: string | null;
  style?: CSSProperties;
}

const NameAndRoleContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const SmallTextDiv = styled.div`
  font-family: 'Lato', sans-serif;
  color: ${props => props.theme.color};
  font-size: 0.75em;
  margin-top: 0.2em;
  line-height: 1em;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const NameDiv = styled(SmallTextDiv)`
  font-weight: bold;
`;

const ImageAndTextContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ContentDiv = styled.div`
  width: 12em;
`;

/**
 * UserSummary
 * Profile image, name and role
 */
export class UserSummary extends React.PureComponent<IUserSummaryProps, {}> {
  render() {
    const {
      name,
      role,
      organizationImage,
      organizationName,
      style,
      id,
      organizationUrl,
    } = this.props;
    let url = profileImage;
    if (this.props.userImage) {
      url = 'https://hel1.your-objectstorage.com/munikum-public/' + this.props.userImage;
    }
    return (
      <ContentDiv style={style}>
        <Tooltip
          usePortal={true}
          style={{ backgroundColor: '#fff' }}
          content={
            <UserPreview
              organizationImage={
                organizationImage || this.props.userOrganizationImage
              }
              jobTitle={role}
              organizationName={this.props.userOrgName || organizationName}
              name={name}
              image={url}
              id={id}
              organizationUrl={organizationUrl}
            />
          }
        >
          <ImageAndTextContainer>
            <div>
              <img
                alt={'profil bilde'}
                style={{ borderRadius: '50%' }}
                src={url}
                width="35"
                height="35"
              />
            </div>
            <NameAndRoleContainer
              style={{
                marginLeft: '.75em',
              }}
            >
              <NameDiv>{name}</NameDiv>
              <SmallTextDiv>{role}</SmallTextDiv>
            </NameAndRoleContainer>
          </ImageAndTextContainer>
        </Tooltip>
        <ImageAndTextContainer
          style={{
            marginTop: '.5em',
          }}
        >
          <div style={{ marginLeft: '.4em' }}>
            <StaticImage
              filename={organizationImage}
              type={IStaticImageType.ORGANIZATION}
              width={20}
              height={20}
            />
          </div>
          <SmallTextDiv
            style={{
              marginLeft: '1.6em',
              fontWeight: 'bold',
              letterSpacing: '.5px',
            }}
          >
            {organizationName}
          </SmallTextDiv>
        </ImageAndTextContainer>
      </ContentDiv>
    );
  }
}
