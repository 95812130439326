/***
 * localStorageService
 * todo: store ALL global state in local storage? or just parts of it? I've seen both...
 * I think there is a plugin that places a certain part of global state in local storage?
 * we should store user settings with a "better key"?
 * or just place different settings, tokens etc using custom keys?
 * **/

const MUNIKUM_BASE_KEY = 'munikum::';
const ACCESS_TOKEN_KEY = `_ACCESS_TOKEN`;
const REFRESH_TOKEN_KEY = `_REFRESH_TOKEN`;

function getItem(key: string): string | undefined {
  const temp: string | null = localStorage.getItem(MUNIKUM_BASE_KEY + key);
  // console.log('LS READ ' + MUNIKUM_BASE_KEY + key + ':', temp);
  if (temp !== null) {
    return JSON.parse(temp);
  }
  return undefined;
}

function setItem(key: string, value: {}) {
  // console.log('LS SAVE: ' + key + ':', value);
  localStorage.setItem(MUNIKUM_BASE_KEY + key, JSON.stringify(value));
}

function removeItem(key: string) {
  localStorage.removeItem(MUNIKUM_BASE_KEY + key);
}

// export const storeAccessToken = (accessToken: {}) =>
//   setItem(ACCESS_TOKEN_KEY, accessToken);

export const storeRefreshToken = (refreshToken: {}) => {
  setItem(REFRESH_TOKEN_KEY, refreshToken);
};

export const resetTokens = () => {
  removeItem(ACCESS_TOKEN_KEY);
  removeItem(REFRESH_TOKEN_KEY);
  removeItem('munikum_access_token');
};

export const getRefreshToken = () => getItem(REFRESH_TOKEN_KEY);

const APP_VERSION = 'cache-app-version';
export function getCacheAppVersion() {
  return getItem(APP_VERSION);
}

export function storeCacheAppVersion(versionString: string) {
  setItem(APP_VERSION, versionString);
}

export const APOLLO_CACHE_KEY = MUNIKUM_BASE_KEY + 'apollo-chicken-cache';

export async function forceClearApolloPersistedCache(): Promise<void> {
  // console.log('force remove APOLLO_CACHE=>' + APOLLO_CACHE_KEY);
  localStorage.removeItem(APOLLO_CACHE_KEY);
  return Promise.resolve();
}

export const storeAccessToken = (accessToken: string) => {
  setItem('munikum_access_token', accessToken);
};

export const getAccessToken = () => getItem('munikum_access_token');
