import { DocumentType } from '../../../components/topics/TopicDocumentCard';
import * as React from 'react';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import gql from 'graphql-tag';
import {
  isAdminSelector,
  permissionsSelector,
  simpleMeSelector,
  usernameSelector,
} from '../../../modules/auth/auth';
import { MunikumIcons } from '../../../components/common/icons';
import {
  Button,
  ButtonSize,
  ButtonTheme,
  NavButton,
} from '../../../components/basic';
import { uploadFilesToTopicAtionCreator } from '../../../modules/topic/topic';
import { Page, PageTitle, PageTop } from '../../../components/layout/Page/Page';
import {
  AccessEnum,
  TopicQueryActionValues,
  TopicQueryCalendars,
  TopicQueryDiscussions,
  TopicQueryFiles,
  TopicQueryLinks,
  TopicQueryQuery,
  TopicQueryVariables,
} from '../../../models/types';
import { Option } from 'react-select';

import Helmet from 'react-helmet';
import { IFileItem } from '../../../components/basic/UserFile/FileUploaderPicker';
import { doDownloadFile } from '../../../components/basic/UserFile/FileCommon';
import { ShareButton } from '../../../components/meta/ShareButton';
import { FlexGrid } from '../../../components/layout/FlexGrid/FlexGrid';
import { ITabProps, Tabs, TabTheme } from '../../../components/basic/Tabs/Tabs';
import { safeInvokeDeprecated } from '../../../components/common';
import { RouteComponentProps } from 'react-router';
import { myApolloClient } from '../../../graphql/apolloClientFactory';
import { myHistory } from '../../../index';
import { uploadFile } from '../../../services/fileservice';
import { SuspenseLoader } from '../../../components/basic/Loader/CircleSpinner';
import { InjectedAuthRouterProps } from 'redux-auth-wrapper/history3/redirect';
import { IPersonSimple } from '../../../services/models/discussion';
import { commonMessages } from '../../../components/language/commonMessages';
import { TopicTabNoData } from '../../../components/topics/TopicTabNoData';
import { shouldScrollToTop } from '../../../modules/ui/mainLayout/mainLayout';
import styled from 'styled-components';
import Query from 'react-apollo/Query';
import { RootState } from '../../../modules/rootReducer';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import moment from 'moment';
import { InfoBoxInput, metaToInfoBoxInput } from '../../Dashboard/lastUpdated';
import { IEntity } from '../../../services/models/entity';
import { AuthQuery } from '../../../auth';
import { fieldsOnActionValue } from '../../ActionValue/ActionValueItemPage/ActionValueItemPage';
import { OverlayWithSearch } from '../../../components/basic/OverlayWithSearch/OverlayWithSearch';
import NoAccessPage from '../../../auth/NoAccessPage';
import { ITopicNetworkTabItem } from '../../../components/topics/TopicNetworkTab';

const TopicAboutTab = React.lazy(() =>
  import('../../../components/topics/TopicAboutTab')
);

const TopicForumTab = React.lazy(() =>
  import('../../../components/topics/TopicForumTab')
);

const TopicResourcestTab = React.lazy(() =>
  import('../../../components/topics/TopicResourcesTab')
);

const TopicNetworkTab = React.lazy(() =>
  import('../../../components/topics/TopicNetworkTab')
);

const TopicActionValueTab = React.lazy(() =>
  import('../../../components/topics/TopicActionValueTab')
);

const TopicYearWheelTab = React.lazy(() =>
  import('../../../components/topics/TopicYearWheelTab')
);

const messages = defineMessages({
  newQuestion: {
    id: 'DiscussionListPage.newQuestion',
    defaultMessage: 'New question',
  },
  answers: {
    id: 'TopicItemPage.answers',
    defaultMessage: 'Answers',
  },
  noAnswers: {
    id: 'TopicItemPage.noAnswers',
    defaultMessage: 'No answers',
  },
  replyTitle: {
    id: 'TopicItemPage.replyTitle',
    defaultMessage: 'Reply to question',
  },
  sharingExperience: {
    id: 'TopicItemPage.sharingExperience',
    defaultMessage: 'Sharing experience',
  },
  document: {
    id: 'TopicItemPage.document',
    defaultMessage: 'Document',
  },
  grants: {
    id: 'TopicItemPage.grants',
    defaultMessage: 'Grants',
  },
  readComment: {
    id: 'TopicItemPage.readComment',
    defaultMessage: 'Read comment',
  },
  hideComment: {
    id: 'TopicItemPage.hideComment',
    defaultMessage: 'Hide comment',
  },
  about: {
    id: 'TopicItemPage.about',
    defaultMessage: 'About',
  },
  followers: {
    id: 'TopicItemPage.followers',
    defaultMessage: 'Followers',
  },
  mymunicipality: {
    id: 'TopicItemPage.mymunicipality',
    defaultMessage: 'My municipality',
  },
  relatedTopics: {
    id: 'TopicItemPage.relatedTopics',
    defaultMessage: 'Related topics',
  },

  unFollowHelpTopic: {
    id: 'DiscussionListPage.unfollowHelpTopic',
    defaultMessage:
      'Click to unfollow topic. You will no longer receive notifications and e-mails regarding this topic',
  },
  followHelpTopic: {
    id: 'DiscussionListPage.followHelpTopic',
    defaultMessage:
      'Follow this topic to receive notifications when the topic changes',
  },

  noActionValues: {
    id: 'TopicItemPage.noActionValues',
    defaultMessage: 'No grants connected to this topic',
  },
  noFiles: {
    id: 'TopicItemPage.noFiles',
    defaultMessage: 'No files connected to this topic',
  },

  noPermissionActionValues: {
    id: 'TopicItemPage.noPermissionActionValues',
    defaultMessage: 'You do not have access to grants',
  },
  noPermissionFiles: {
    id: 'TopicItemPage.noPermissionFiles',
    defaultMessage: 'You do not have access to files',
  },
  noPermissionMyMunicipality: {
    id: 'TopicItemPage.noPermissionMyMunicipality',
    defaultMessage: 'You do not have access to this page',
  },

  noDataTitleSharingExperience: {
    id: 'DiscussionListPage.noDataTitleSharingExperience',
    defaultMessage: 'Ups!',
  },

  noDataDescriptionSharingExperience: {
    id: 'DiscussionListPage.noDataDescriptionSharingExperience',
    defaultMessage:
      'No questions are connected to this topic. You can add a question at the top right. ',
  },

  noDataTitleGrant: {
    id: 'DiscussionListPage.noDataTitleGrant',
    defaultMessage: 'Ups!',
  },

  noDataTitleDocument: {
    id: 'DiscussionListPage.noDataTitleDocument',
    defaultMessage: 'Ups!',
  },

  noLinks: {
    id: 'DiscussionListPage.noLinks',
    defaultMessage:
      'There is no links added to this topic. If you know links connected to this tops, please let us know!   ',
  },
});

export interface ITopicItemProps {
  isAdmin: boolean;
  username?: string;
  simpleMe?: IPersonSimple;

  onUploadFiles?: (files: ReadonlyArray<IFileItem>, metaId: string) => void;

  onFollowTopic?: (id: string, follow: boolean) => void;
  onClick?: (uri: string) => void;

  onPageChangeActionValue?: (page: number) => void;
  onFollowActionValue?: (id: string, follow: boolean) => void;
  onClickActionValue?: (uri: string) => void;

  onPageChangeDiscussion?: (page: number) => void;
  onFollowDiscussion?: (id: string, follow: boolean) => void;
  onClickDiscussion?: (uri: string) => void;
  scrollToTop?: () => void;

  permissions?: ReadonlyArray<string>;
}

const ContentDiv = styled.div`
  background-color: #ffffff;
  display: block;
`;

export const fieldsOnOrganization = gql`
  fragment fieldsOnOrganization on Organization {
    id
    organizationImage
    name
    url
  }
`;
export const CHANGE_OWNER = gql`
  mutation ChangeOwner($input: ChangeOwnerInput!) {
    changeOwner(input: $input)
  }
`;
export const TOPIC_QUERY = gql`
  query TopicQuery($id: ID!) {
    myFollowed {
      tags {
        id
        title
        color
      }
    }
    me {
      id
      name
      picture
      jobTitle
      isAdmin
      department
      organization {
        ...fieldsOnOrganization
      }
    }
    topic(id: $id) {
      id
      uri
      tags {
        id
        color
        title
      }
      access {
        isPrivate
        myAccess
        visibility
        groups {
          group {
            id
            title
            createdBy {
              id
              name
            }
            dateCreated
            lastUpdated
            members {
              name
              id
            }
          }
          access
        }
        organizationGroups {
          access
          organizationGroup {
            createdBy {
              id
              name
            }
            id
            lastUpdated
            title
            members {
              name
              id
            }
          }
        }
        organizations {
          access
          organization {
            name
            id
            organizationImage
          }
        }
        persons {
          access
          person {
            name
            id
            department
            picture
            role
            organization {
              id
              name
              organizationImage
            }
          }
        }
      }
      title
      isLiked
      createdBy {
        id
        name
        picture
        role
        organization {
          ...fieldsOnOrganization
        }
      }
      links {
        id
        label
        href
        access {
          myAccess
        }
        createdBy {
          ...fieldsOnAuthor
        }
      }
      calendars {
        id
        title
        uri
        description
        color
        status
        access {
          myAccess
        }
        category
        lastUpdated
        dateCreated
        isFollowing
        isRestricted
        isLimited
        isLiked
        likes
        followers {
          id
        }
        topics {
          id
          uri
          title
          likes
          isLiked
          isFollowing
          isLimited
          isRestricted
        }
        createdBy {
          id
          name
          picture
          role
          organization {
            ...fieldsOnOrganization
          }
        }
      }
      description
      isRestricted
      isLimited
      isFollowing
      status
      currentRevision {
        id
        body
        category
        sector {
          id
          title
        }
        kostraFunctions {
          id
          kid
          title
        }
      }
      followers {
        id
        name
        picture
        role
        organization {
          ...fieldsOnOrganization
        }
      }
      actionValues {
        ...FieldsOnActionValue
      }
      discussions {
        id
        title
        isLiked
        likes
        views
        createdBy {
          id
          name
          department
          role
          picture
          organization {
            ...fieldsOnOrganization
          }
        }
        dateCreated
        lastUpdated
        uri
        description
        status
        isFollowing
        currentRevision {
          id
          body
        }
        comments {
          dateCreated
        }
        topics {
          id
          isFollowing
          title
          isLimited
          createdBy {
            id
            name
            department
            role
            organization {
              ...fieldsOnOrganization
            }
          }
        }
        files {
          id
          name
          description
          lastUpdated
          createdBy {
            id
            name
            department
            picture
            role
            organization {
              ...fieldsOnOrganization
            }
          }
          access {
            myAccess
          }
          size
          isLiked
          likes
          contentType
        }
        followers {
          id
        }
      }
      files {
        id
        name
        description
        lastUpdated
        size
        isLiked
        access {
          myAccess
        }
        createdBy {
          id
          name
          picture
          role
          organization {
            ...fieldsOnOrganization
          }
        }
        likes
        contentType
      }
      topics {
        id
        isFollowing
        access {
          visibility
          isPrivate
          myAccess
          groups {
            group {
              id
              title
              createdBy {
                id
                name
              }
              dateCreated
              lastUpdated
              members {
                name
                id
              }
            }
            access
          }
          organizationGroups {
            access
            organizationGroup {
              createdBy {
                id
                name
              }
              id
              lastUpdated
              title
              members {
                name
                id
              }
            }
          }
          organizations {
            access
            organization {
              name
              id
            }
          }
          persons {
            access
            person {
              name
              id
              department
              picture
              role
              organization {
                id
                name
                organizationImage
              }
            }
          }
        }
        createdBy {
          id
          name
          picture
          role
          organization {
            ...fieldsOnOrganization
          }
        }
        title
        lastUpdated
        uri
        description
        likes
        isLiked
        isRestricted

        followers {
          id
          name
        }
        isLimited
        currentRevision {
          id
          category
        }
      }
    }
  }
  ${fieldsOnActionValue}

  ${fieldsOnOrganization}
`;

export class MyTopicQuery extends Query<TopicQueryQuery, TopicQueryVariables> {}

const TopicComponent = class extends React.PureComponent<
  ITopicItemProps &
    InjectedAuthRouterProps &
    RouteComponentProps<{ uri: string; tab: string }> &
    InjectedIntlProps,
  {
    readMore: boolean;
    isChangeOwnerOpen: boolean;
    showTriangleDescription: boolean;
    isMoreVisible: boolean;
    currentTab: number;
    isNewActionValueOpen: boolean;
  }
> {
  constructor(
    props: ITopicItemProps &
      InjectedAuthRouterProps &
      RouteComponentProps<{ uri: string; tab: string }> &
      InjectedIntlProps
  ) {
    super(props);

    this.state = {
      isChangeOwnerOpen: false,
      readMore: false,
      showTriangleDescription: false,
      isMoreVisible: false,
      currentTab: this.selectTab(this.props.match.params.tab),
      isNewActionValueOpen: false,
    };
  }

  selectTab = (tab: string) => {
    switch (tab) {
      case 'forum':
        return 2;
      case 'resources':
        return 3;
      case 'network':
        return 4;
      case 'grants':
        return 5;
      case 'annual-cycle':
        return 6;
      default:
      case 'about':
        return 1;
    }
  };

  tabIdToString = (id: number) => {
    switch (id) {
      case 2:
        return 'forum';
      case 3:
        return 'resources';
      case 4:
        return 'network';
      case 5:
        return 'grants';
      case 6:
        return 'annual-cycle';
      default:
      case 1:
        return 'about';
    }
  };

  handleFollow = (id: string, follow: boolean) => {
    safeInvokeDeprecated(this.props.onFollowTopic, id, follow);
  };

  handleClick = (metaUri: string) => {
    safeInvokeDeprecated(this.props.onClick, metaUri);
  };

  handleClickFile = (uri: string, id: string) => {
    if (id) {
      doDownloadFile(id);
    }
  };

  handlePageChangedDiscussions = (page: number) => {
    safeInvokeDeprecated(this.props.onPageChangeDiscussion, page);
  };

  handleUploadableFiles = async (files2: IFileItem[], metaId: string) => {
    //

    for (let i = 0; i < files2.length; i++) {
      if (files2[i].file !== null && files2[i].file !== null) {
        try {
          const uploadResult = await uploadFile(
            files2[i].file!,
            metaId,
            files2[i].description,
            p => console.log('upload progress: ' + p)
          );
          if (!uploadResult.greatSuccess) {
            // console.warn('Oops, upload failed: ' + files2[i].filename);
            // NOTE: we could take some action here? Now we just continue to the next file...
          }
        } catch (uploadErr) {
          console.log('upload err', uploadErr);
        }
      }
    }

    try {
      await myApolloClient.query({
        query: TOPIC_QUERY,
        variables: {
          id: metaId,
        },
        fetchPolicy: 'network-only',
      });
      // console.log('ok update cache afetr uploading files on topic..');
    } catch (err) {
      console.error(err);
    }

    // disabling redux:
    // safeInvoke(this.props.onUploadFiles, recentFiles, metaId);
  };

  handleShowTriangleDescription = () => {
    this.setState({
      showTriangleDescription: !this.state.showTriangleDescription,
    });
  };

  handleSeeAllClick = (id: number) => {
    this.setState({
      currentTab: id,
    });
    safeInvokeDeprecated(this.props.scrollToTop);
  };

  handleTabChange = (id: number) => {
    if (
      window.location.href.indexOf('about') !== -1 ||
      window.location.href.indexOf('forum') !== -1 ||
      window.location.href.indexOf('resources') !== -1 ||
      window.location.href.indexOf('network') !== -1 ||
      window.location.href.indexOf('grants') !== -1 ||
      window.location.href.indexOf('annual-cycle') !== -1
    ) {
      window.history.pushState('', '', this.tabIdToString(id));
    } else {
      window.history.pushState(
        '',
        '',
        this.props.match.params.uri + '/' + this.tabIdToString(id)
      );
    }
    this.setState({
      currentTab: id,
    });
  };

  render() {
    const { currentTab } = this.state;

    const { isAdmin, intl, permissions } = this.props;

    const id = this.props.match.params.uri.substring(
      this.props.match.params.uri.lastIndexOf('-') + 1,
      this.props.match.params.uri.length
    );

    return (
      <MyTopicQuery
        query={TOPIC_QUERY}
        variables={{ id: id }}
        fetchPolicy={'network-only'}
      >
        {({ loading, error, data }) => {
          if (loading) {
            return (
              <>
                <ContentDiv style={{ padding: '50px' }}>
                  <SuspenseLoader />
                </ContentDiv>
              </>
            );
          }
          if (error) {
            // return <StatusMessage title={intl.formatMessage(errorMessages.unhandledErrorTitle)} text={'Error '}/>;
            throw error; // not too smart, but will be catched by error boundary :-P
          }
          if (!data || !data.topic) {
            return <NoAccessPage />;
          }

          let networkTabData: ITopicNetworkTabItem[] = [];

          networkTabData.push({
            type: 'PERSON',
            id: data.topic.createdBy.id,
            name: data.topic.createdBy.name,
            role: data.topic.createdBy.role,
            picture: data.topic.createdBy.picture,
            organization: {
              name: data.topic.createdBy.organization.name,
              organizationImage:
                data.topic.createdBy.organization.organizationImage,
              url: data.topic.createdBy.organization.url,
            },
          });
          if (data.topic.followers) {
            data.topic.followers.forEach(value => {
              // Check if already in list
              let exists = false;
              networkTabData.forEach(value1 => {
                if (value.id === value1.id) {
                  exists = true;
                  return;
                }
              });
              if (exists) {
                return;
              }

              networkTabData.push({
                type: 'PERSON',
                id: value.id,
                name: value.name,
                role: value.role,
                picture: value.picture,
                organization: {
                  name: value.organization.name,
                  organizationImage: value.organization.organizationImage,
                  url: value.organization.url,
                },
              });
            });
          }
          if (data.topic.access.persons) {
            data.topic.access.persons.forEach(value => {
              // Check if already in list
              let exists = false;
              networkTabData.forEach(value1 => {
                if (value.person.id === value1.id) {
                  exists = true;
                  return;
                }
              });
              if (exists) {
                return;
              }

              networkTabData.push({
                type: 'PERSON',
                id: value.person.id,
                name: value.person.name,
                role: value.person.role,
                picture: value.person.picture,
                organization: {
                  name: value.person.organization.name,
                  organizationImage:
                    value.person.organization.organizationImage,
                  url: '',
                },
              });
            });
          }

          networkTabData.sort(function(a, b) {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            return 0;
          });

          let networkTabDataOrganizations = [];

          if (data.topic.access.organizations) {
            data.topic.access.organizations.forEach(value => {
              // Check if already in list
              let exists = false;
              networkTabData.forEach(value1 => {
                if (value.organization.id === value1.id) {
                  exists = true;
                  return;
                }
              });
              if (exists) {
                return;
              }

              networkTabDataOrganizations.push({
                type: 'ORGANIZATION',
                id: value.organization.id,
                name: value.organization.name,
                picture: value.organization.organizationImage,
              });
            });
          }

          networkTabDataOrganizations.sort(function(a, b) {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            return 0;
          });

          networkTabDataOrganizations.forEach(value => {
            networkTabData.push(value);
          });

          const LockIcon = MunikumIcons.Padlock;
          interface IFileLikes extends IEntity {
            filename: string;
            size: number;
            contenttype: string;
            lastupdated: Date;
            likes: number;
            isLiked: boolean;
          }
          // array with visible files under files tab
          interface IFileContainer {
            file: IFileLikes;
            documentType: DocumentType;
            uri?: string;
          }
          let topicFiles: Array<any> = [];

          // let commentFiles: IFile[] = [];
          // let actionValueFiles: IFile[] = [];

          // infoboxes on about page
          let metasActionValues: InfoBoxInput[] = [];
          let metasCalendars: InfoBoxInput[] = [];
          let metasDiscussion: InfoBoxInput[] = [];
          let metasFollowers: InfoBoxInput[] = [];
          let metasFiles: InfoBoxInput[] = [];
          let doIHaveAccess: boolean = false;
          if (data.topic.actionValues) {
            data.topic.actionValues.forEach(
              (actionValue: TopicQueryActionValues) => {
                metasActionValues.push({
                  id: actionValue.id,
                  title: actionValue.title,

                  lastUpdated: actionValue.lastUpdated
                    ? actionValue.lastUpdated
                    : new Date(0),
                  uri: actionValue.uri,
                });
                actionValue.files.forEach((file: TopicQueryFiles) => {
                  metasFiles.push({
                    id: file.id,
                    title: file.name,
                    lastUpdated: file.lastUpdated,
                    uri: '/topic',
                  });

                  topicFiles.push({
                    file: {
                      id: file.id,
                      filename: file.name,
                      description: file.description,
                      size: 123,
                      isLiked: file.isLiked,
                      likes: file.likes,
                      contenttype: '',
                      access: file.access,
                      lastupdated: file.lastUpdated,
                      createdBy: {
                        id: file.createdBy && file.createdBy.id,
                      },
                    },
                    documentType: DocumentType.ActionValue,
                    uri: actionValue.uri,
                  });
                });
              }
            );
          }

          if (data.topic.calendars) {
            data.topic.calendars.forEach((calendar: TopicQueryCalendars) => {
              metasCalendars.push({
                id: calendar.id,
                title: calendar.title,
                lastUpdated: calendar.lastUpdated
                  ? calendar.lastUpdated
                  : new Date(0),
                uri: calendar.uri,
              });
            });
          }

          networkTabData.forEach((value: any) => {
            metasFollowers.push({
              id: value.id,
              title: value.name,
              lastUpdated: new Date(),
              uri: '',
              organizationImg: value.organization ?? '',
              role: value.role ?? '',
            });
          });

          if (data.topic.discussions) {
            data.topic.discussions.forEach(
              (discussion: TopicQueryDiscussions) => {
                metasDiscussion.push(metaToInfoBoxInput(discussion as any));
                discussion.files.forEach((file: any) => {
                  metasFiles.push({
                    id: file.id,
                    title: file.name,
                    lastUpdated: file.lastUpdated,
                    uri: '/topic',
                  });
                  topicFiles.push({
                    file: {
                      id: file.id,
                      filename: file.name,
                      description: file.description,
                      size: 123,
                      isLiked: file.isLiked,
                      likes: file.likes,
                      contenttype: '',
                      lastupdated: file.lastUpdated,
                      access: file.access,
                      createdBy: {
                        id: file.createdBy && file.createdBy.id,
                      },
                    },
                    documentType: DocumentType.Discussion,
                    uri: discussion.uri,
                  });
                });
              }
            );
          }
          if (data.topic.files) {
            data.topic.files.forEach((file: TopicQueryFiles) => {
              topicFiles.push({
                file: {
                  id: file.id,
                  filename: file.name,
                  description: file.description,
                  size: 123,
                  isLiked: file.isLiked,
                  likes: file.likes,
                  contenttype: '',
                  lastupdated: file.lastUpdated,
                  access: file.access,
                  createdBy: {
                    id: file.createdBy && file.createdBy.id,
                  },
                },
                documentType: DocumentType.Topic,
                uri: '/uri',
              });
              metasFiles.push({
                id: file.id,
                title: file.name,
                lastUpdated: file.lastUpdated,
                uri: '/topic',
              });
            });
          }
          if (
            data &&
            data.topic &&
            data.topic.access &&
            data.topic.access.myAccess === AccessEnum.WRITE
          ) {
            doIHaveAccess = true;
          }
          topicFiles.sort(function(a: IFileContainer, b: IFileContainer) {
            const nameA = a.file.filename.toUpperCase();
            const nameB = b.file.filename.toUpperCase();

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });

          // Sort by last updated
          if (metasDiscussion) {
            metasDiscussion.sort(
              (a, b) =>
                moment.utc(b.lastUpdated).unix() -
                moment.utc(a.lastUpdated).unix()
            );
          }
          if (metasActionValues) {
            metasActionValues.sort(
              (a, b) =>
                moment.utc(b.lastUpdated).unix() -
                moment.utc(a.lastUpdated).unix()
            );
          }
          if (metasFiles) {
            metasFiles.sort(
              (a, b) =>
                moment.utc(b.lastUpdated).unix() -
                moment.utc(a.lastUpdated).unix()
            );
          }
          if (metasCalendars) {
            metasCalendars.sort(
              (a, b) =>
                moment.utc(b.lastUpdated).unix() -
                moment.utc(a.lastUpdated).unix()
            );
          }
          if (metasFollowers) {
            metasFollowers.sort(
              (a, b) =>
                moment.utc(b.lastUpdated).unix() -
                moment.utc(a.lastUpdated).unix()
            );
          }
          metasFollowers.splice(4);
          metasCalendars.splice(4);
          metasDiscussion.splice(4);
          metasActionValues.splice(4);
          metasFiles.splice(4);
          if (data.topic.actionValues) {
            data.topic.actionValues.sort(
              (a, b) =>
                moment.utc(b.lastUpdated).unix() -
                moment.utc(a.lastUpdated).unix()
            );
          }
          if (data.topic.discussions) {
            data.topic.discussions.sort(
              (a, b) =>
                moment.utc(b.lastUpdated).unix() -
                moment.utc(a.lastUpdated).unix()
            );
          }

          let links: TopicQueryLinks[] = [];
          let splicedLinks: TopicQueryLinks[] = [];
          if (data.topic.links) {
            data.topic.links.forEach(link => {
              links.push(link);
            });
            splicedLinks = links.slice();
            splicedLinks.splice(4);
          }

          let hasAccessToActionValues: boolean | undefined = false;
          if (data.topic.createdBy) {
            hasAccessToActionValues =
              isAdmin ||
              (permissions && permissions.indexOf('ACTION_VALUE') > -1);
          }

          let doIhaveAccess = false;
          if (data.topic.access.myAccess === AccessEnum.WRITE) {
            doIhaveAccess = true;
          }
          if (data.me.isAdmin) {
            doIhaveAccess = true;
          }
          let tabs: Array<ITabProps> = [
            {
              id: 1,
              title: intl.formatMessage(messages.about),
              content: () => (
                <>
                  <TopicAboutTab
                    data={data && data}
                    links={splicedLinks}
                    topic={data.topic}
                    me={data.me}
                    metasCalendars={metasCalendars}
                    metasFiles={metasFiles}
                    metasFollowers={metasFollowers}
                    accessActionValue={hasAccessToActionValues}
                    handleClickFile={(uri: string, id2: any) => {
                      this.handleClickFile(uri, id2);
                    }}
                    onChangeTab={tabId => {
                      window.history.pushState(
                        '',
                        '',
                        this.tabIdToString(tabId)
                      );
                      this.setState({
                        currentTab: tabId,
                      });
                    }}
                  />
                </>
              ),
            },
            {
              id: 2,
              title: intl.formatMessage(messages.sharingExperience),
              content: () => (
                <TopicForumTab
                  data={data && data}
                  topic={data.topic}
                  handlePageChangedDiscussions={
                    this.handlePageChangedDiscussions
                  }
                />
              ),
            },
          ];

          // FILES TAB
          if (
            isAdmin ||
            (permissions && permissions.indexOf('ACTION_VALUE') > -1)
          ) {
            // FIXME

            tabs.push({
              id: 3,
              title: intl.formatMessage(commonMessages.resources),
              content: () => (
                <TopicResourcestTab
                  hasWriteAccess={doIhaveAccess}
                  topic={data && data.topic && data.topic}
                  links={links}
                  me={data && data.me}
                  topicFiles={topicFiles}
                  handleUploadableFiles={this.handleUploadableFiles}
                />
              ),
            });
          } else {
            tabs.push({
              id: 3,
              title: intl.formatMessage(messages.document),
              icon: LockIcon,
              content: () => (
                <TopicTabNoData
                  title={intl.formatMessage(messages.noPermissionFiles)}
                />
              ),
            });
          }

          if (!data.topic.access.isPrivate) {
            networkTabData.push({
              type: 'SPECIAL',
              id: '-1',
              name: 'Alle',
            });
          }

          // FOLLOWERS TAB
          tabs.push({
            id: 4,
            title: intl.formatMessage(messages.followers),
            content: () => <TopicNetworkTab data={networkTabData} />,
          });

          // ACTION VALUE TAB
          if (
            isAdmin ||
            (permissions && permissions.indexOf('ACTION_VALUE') > -1)
          ) {
            tabs.push({
              id: 5,
              title: intl.formatMessage(messages.grants),
              content: () => (
                <TopicActionValueTab
                  hasWriteAccess={doIhaveAccess}
                  topicId={+id}
                  topic={data.topic}
                  handlePageChangedDiscussions={
                    this.handlePageChangedDiscussions
                  }
                  isAdmin={isAdmin}
                  data={data.topic.actionValues}
                />
              ),
            });
          } else {
            tabs.push({
              id: 5,
              title: intl.formatMessage(messages.grants),
              icon: LockIcon,
              content: () => (
                <FlexGrid style={{ padding: '2em', minHeight: '19.750em' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      height: '62px',
                    }}
                  >
                    <PageTitle
                      style={{
                        color: '#333333',
                        fontFamily: 'Lato',
                        fontSize: '22px',
                        fontWeight: 'bold',
                      }}
                    >
                      {intl.formatMessage(messages.grants)}
                    </PageTitle>
                  </div>
                  <div>
                    <TopicTabNoData
                      title={intl.formatMessage(
                        messages.noPermissionActionValues
                      )}
                    />
                  </div>
                </FlexGrid>
              ),
            });
          }

          if (
            isAdmin ||
            (permissions && permissions.indexOf('CALENDAR') > -1)
          ) {
            tabs.push({
              id: 6,
              title: intl.formatMessage(commonMessages.yearWheel),
              content: () => (
                <TopicYearWheelTab
                  hasWriteAccess={doIhaveAccess}
                  topic={data.topic}
                  handlePageChangedCalendars={this.handlePageChangedDiscussions}
                />
              ),
            });
          } else {
            tabs.push({
              id: 6,
              title: intl.formatMessage(commonMessages.yearWheel),
              icon: LockIcon,
              content: () => (
                <FlexGrid style={{ padding: '2em', minHeight: '19.750em' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      height: '62px',
                    }}
                  >
                    <PageTitle
                      style={{
                        color: '#333333',
                        fontFamily: 'Lato',
                        fontSize: '22px',
                        fontWeight: 'bold',
                      }}
                    >
                      {intl.formatMessage(commonMessages.yearWheel)}
                    </PageTitle>
                  </div>
                  <div>
                    <TopicTabNoData
                      title={intl.formatMessage(messages.noPermissionFiles)}
                    />
                  </div>
                </FlexGrid>
              ),
            });
          }
          return (
            <Page>
              <Helmet>
                <title>{data.topic.title}</title>
              </Helmet>
              <div>
                <PageTop>
                  <PageTitle
                    style={{ overflow: 'hidden', marginBottom: '.5em' }}
                  >
                    {data.topic.isRestricted && <LockIcon />}
                    {data.topic.title}
                  </PageTitle>
                  <div style={{ display: 'flex' }}>
                    {data?.me?.isAdmin && (
                      <Button
                        text={'Endre eigar'}
                        size={ButtonSize.Small}
                        onClick={person => {
                          this.setState({
                            isChangeOwnerOpen: true,
                          });
                        }}
                      />
                    )}
                    <ShareButton
                      meta={data.topic.id}
                      style={{
                        marginLeft: '.5em',
                      }}
                    />
                    <AuthQuery authFunc={auth => auth.isAdmin || doIHaveAccess}>
                      <NavButton
                        buttonTheme={ButtonTheme.RED}
                        greyBackground={true}
                        navigateTo={'/topic/edit/' + data.topic.id}
                        leftIcon={MunikumIcons.Edit}
                        text={intl.formatMessage(commonMessages.edit)}
                        size={ButtonSize.Small}
                        style={{
                          marginLeft: '8px',
                        }}
                      />
                    </AuthQuery>

                    {/*</React.Fragment>*/}
                    {/*</Popover>*/}
                    {/*)}*/}
                  </div>
                </PageTop>
                {data?.me?.isAdmin && (
                  <OverlayWithSearch
                    changeOwnerMode={true}
                    isOpen={this.state.isChangeOwnerOpen}
                    currentValues={[data?.topic?.createdBy]}
                    onClose={() => {
                      this.setState({
                        isChangeOwnerOpen: false,
                      });
                    }}
                    onSave={(person: Array<Option>) => {
                      myApolloClient
                        .mutate({
                          mutation: CHANGE_OWNER,
                          variables: {
                            input: {
                              id: data?.topic?.id,
                              person: person[0],
                            },
                          },
                        })
                        .then(c => {
                          this.setState({
                            isChangeOwnerOpen: false,
                          });
                        });
                    }}
                  />
                )}
                <Tabs
                  selectedTabId={currentTab}
                  onChange={this.handleTabChange}
                  tabs={tabs}
                  theme={TabTheme.BLUE}
                />
              </div>
            </Page>
          );
        }}
      </MyTopicQuery>
    );
  }
};

// mapping from global state to container props:
// static typing on arrow function example:
const mapStateToProps = (
  state: RootState,
  ownProps: ITopicItemProps & RouteComponentProps<{ uri: string }>
): ITopicItemProps => {
  return {
    isAdmin: isAdminSelector(state),
    username: usernameSelector(state),
    simpleMe: simpleMeSelector(state),

    permissions: permissionsSelector(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<{}>
): Pick<
  ITopicItemProps,
  'onFollowTopic' | 'onClick' | 'scrollToTop' | 'onUploadFiles'
> => {
  return {
    onClick: (uri: string) => {
      dispatch(shouldScrollToTop.started({}));
      myHistory.push(uri);
    },
    scrollToTop: () => {
      dispatch(shouldScrollToTop.started({}));
    },
    onUploadFiles: (files: ReadonlyArray<IFileItem>, metaId: string) => {
      dispatch(
        uploadFilesToTopicAtionCreator.started({
          files: files,
          topicMetaId: metaId,
        })
      );
    },
  };
};

const TopicItemPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(TopicComponent));
export default TopicItemPage;
