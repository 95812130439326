import * as React from 'react';
import { CSSProperties } from 'react';
import gql from 'graphql-tag';
import Query from 'react-apollo/Query';
import {
  defineMessages,
  FormattedMessage,
  InjectedIntlProps,
  injectIntl,
} from 'react-intl';
import Helmet from 'react-helmet';
import { LinkWrapper } from '../../components/basic/Structural/links';
import { MunikumIcons } from '../../components/common/icons';
import { PageTitle } from '../../components/layout/Page/Page';
import Tour from 'reactour';

import { INewsItem } from './DashboardPage';
import { commonMessages } from '../../components/language/commonMessages';
import {
  ContainerDashboard,
  GET_ME_FOR_DASHBOARD,
  IconDiv,
  MainBox,
  OuterContainer,
} from './AnnualCycleDashboard';
import { DashboardBox2 } from '../../components/dashboard/DashboardBox2';
import { Button } from '../../components/basic';
import { RootState } from '../../modules/rootReducer';
import { Dispatch } from 'redux';
import {
  filterDiscussionsActionCreator,
  IDiscussionsFilter,
  resetDiscussionFilterActionCreator,
} from '../../modules/discussion/discussion';
import { connect } from 'react-redux';
import { safeInvoke } from '../../components/common';
import { SuspenseLoader } from '../../components/basic/Loader/CircleSpinner';

const messages = defineMessages({
  whatDoYouWantToDo: {
    id: 'forum.whatDoYouWantToDo',
    defaultMessage: 'What do you want to do?',
  },
  startNewForumThreadText: {
    id: 'forum.startNewForumThreadText',
    defaultMessage: 'Create a new forum thread',
  },
  startNewForumThread: {
    id: 'forum.startNewForumThread',
    defaultMessage: 'Create a new forum thread',
  },
  explore: {
    id: 'forum.explore',
    defaultMessage: 'Explore',
  },
  myDiscussions: {
    id: 'forum.myDiscussions',
    defaultMessage: 'My discussions',
  },
  myDiscussionsText: {
    id: 'forum.myDiscussionsText',
    defaultMessage: 'Discussions that i have created',
  },
  myMunicipalityBody: {
    id: 'forum.myMunicipalityBody',
    defaultMessage: 'Discussions that has been created by my municipality',
  },
  exploreText: {
    id: 'forum.exploreText',
    defaultMessage: 'An arene for sharing experience ',
  },
  news: {
    id: 'forum.news',
    defaultMessage: 'News',
  },
  recomended: {
    id: 'forum.recomended',
    defaultMessage: 'Recomended',
  },
  yourFollowed: {
    id: 'forum.yourFollowed',
    defaultMessage: 'Your followed',
  },
  forumDashboardTourStep7: {
    id: 'forum.forumDashboardTourStep7',
    defaultMessage: 'Do you want to repeat the steps?',
  },
});
export const MY_FOLLOWED_DISCUSSIONS = gql`
  query getMyFollowedDiscussions {
    myFollowed {
      discussions {
        id
        isFollowing
        lastUpdated
      }
    }
  }
`;

export const FOLLOW_DISCUSSION = gql`
  mutation FollowDiscussion($id: ID!) {
    follow(id: $id)
  }
`;
export const UNFOLLOW_DISCUSSION = gql`
  mutation UnfollowDiscussion($id: ID!) {
    unFollow(id: $id)
  }
`;

interface IForumDashboardProps {
  style?: CSSProperties;
  onClick?: (uri: string) => void;
  onFollow?: () => void;
  list: IDiscussionsFilter;
}
interface IForumDashboardDispatchProps {
  handleFilterChange: (filter: IDiscussionsFilter) => void;
  handleClearFilter: () => void;
}
interface IForumDashboardState {
  items: Array<INewsItem>;
  isTourOpen: boolean;
}
export class ForumDashboardComp extends React.PureComponent<
  IForumDashboardProps & IForumDashboardDispatchProps & InjectedIntlProps,
  IForumDashboardState
> {
  constructor(
    props: IForumDashboardDispatchProps &
      IForumDashboardProps &
      InjectedIntlProps
  ) {
    super(props);
    this.state = {
      items: [],
      isTourOpen: false,
    };
  }

  render() {
    const { intl } = this.props;

    const steps = [
      {
        position: 'right',
        selector: '[data-tut="reactour__step1"]',
        content: (
          <span style={{ color: '#000' }}>
            <FormattedMessage
              id={'forumDashboard.ForumDashboardTourStep1'}
              defaultMessage={
                'Here you will find an overview of discussions you have created or have been given access to from others. Discussions that you choose to follow will also be included in the list of your discussions'
              }
              values={{
                br: <br />,
              }}
            />
          </span>
        ),
      },
      {
        position: 'right',

        selector: '[data-tut="reactour__step2"]',
        content: (
          <span style={{ color: '#000' }}>
            <FormattedMessage
              id={'forumDashboard.ForumDashboardTourStep2'}
              defaultMessage={
                ' Here you can see discussions that that you have access to {br} You can share the discussions with others.You can follow the discussions and get notifications about changes.'
              }
              values={{
                br: <br />,
              }}
            />
          </span>
        ),
      },
      {
        position: 'right',

        selector: '[data-tut="reactour__step3"]',
        content: (
          <span style={{ color: '#000' }}>
            <FormattedMessage
              id={'forumDashboard.ForumDashboardTourStep3'}
              defaultMessage={'My municipality'}
              values={{
                br: <br />,
              }}
            />
          </span>
        ),
      },
      {
        position: 'right',
        selector: '[data-tut="reactour__step4"]',
        content: (
          <span style={{ color: '#000' }}>
            <FormattedMessage
              id={'forumDashboard.ForumDashboardTourStep4'}
              defaultMessage={'Here you can create discussions.'}
              values={{
                br: <br />,
              }}
            />
          </span>
        ),
      },

      {
        position: 'right',
        selector: '[data-tut="reactour__step5"]',
        content: (
          <span style={{ color: '#000' }}>
            <FormattedMessage
              id={'forumDashboard.ForumDashboardTourStep5'}
              defaultMessage={
                'The activity log gives you an overview over the last updated discussions wich you follow.'
              }
              values={{
                br: <br />,
              }}
            />
          </span>
        ),
      },
      {
        position: 'right',
        selector: '[data-tut="reactour__step6"]',
        content: (
          <span style={{ color: '#000' }}>
            <FormattedMessage
              id={'forumDashboard.ForumDashboardTourStep6'}
              defaultMessage={
                'We at the Munikum team will make you aware of news related to the discussion. There may be new features or user stories that show different ways of using discussions.'
              }
              values={{
                br: <br />,
              }}
            />
          </span>
        ),
      },
      {
        selector: '[data-tut="reactour__goTo"]',
        content: ({ goTo }) => (
          <div style={{ color: '#000' }}>
            {intl.formatMessage(commonMessages.doYouWantToRepeatTheSteps)}
            <div />
            <Button
              style={{ marginTop: '1em' }}
              onClick={() => goTo(0)}
              text={intl.formatMessage(commonMessages.repeat)}
            />
          </div>
        ),
      },
    ];
    return (
      <Query query={GET_ME_FOR_DASHBOARD}>
        {({ loading, error, data }) => {
          if (loading) {
            return <SuspenseLoader />;
          }
          if (error) {
            throw error;
          }

          let myOrg = data && data.me && data.me.organization;

          return (
            <>
              <Helmet>
                <title>{intl.formatMessage(commonMessages.forum)}</title>
              </Helmet>
              <OuterContainer>
                <ContainerDashboard>
                  <MainBox style={{ margin: '4px' }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '1em',
                      }}
                    >
                      <IconDiv>
                        <MunikumIcons.Info
                          style={{
                            color: '#FF5C39',
                            width: '1.4em',
                            height: '1.4em',
                            marginRight: '.5em',
                          }}
                          onClick={() => {
                            this.setState({
                              isTourOpen: true,
                            });
                          }}
                        />
                      </IconDiv>

                      <PageTitle style={{}}>
                        {intl.formatMessage(commonMessages.forum)}
                      </PageTitle>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <LinkWrapper
                        data-tut="reactour__step1"
                        to={'/forum/list/'}
                      >
                        <DashboardBox2
                          onClick={() => {
                            safeInvoke(this.props.handleFilterChange, {
                              ...this.props.list,
                              predefinedMunicipality: false,
                              organizations: [],
                              showOnlyMine: true,
                            });
                          }}
                          title={intl.formatMessage(messages.myDiscussions)}
                          body={intl.formatMessage(messages.myDiscussionsText)}
                          icon={MunikumIcons.Discussion}
                          isActive={true}
                          style={{
                            backgroundColor: '#00778B',
                          }}
                        />
                      </LinkWrapper>
                      <LinkWrapper
                        to={'/forum/list'}
                        data-tut="reactour__step2"
                      >
                        <DashboardBox2
                          onClick={() => {
                            safeInvoke(this.props.handleClearFilter);
                          }}
                          title={intl.formatMessage(messages.explore)}
                          body={intl.formatMessage(messages.exploreText)}
                          icon={MunikumIcons.Search}
                          isActive={true}
                          style={{
                            backgroundColor: '#00778B',
                          }}
                        />
                      </LinkWrapper>
                      <LinkWrapper
                        to={'/forum/list'}
                        data-tut="reactour__step3"
                      >
                        <DashboardBox2
                          onClick={() => {
                            safeInvoke(this.props.handleFilterChange, {
                              ...this.props.list,
                              predefinedMunicipality: true,
                              showOnlyMine: false,
                              organizations: [myOrg],
                            });
                          }}
                          title={intl.formatMessage(
                            commonMessages.mymunicipality
                          )}
                          body={intl.formatMessage(messages.myMunicipalityBody)}
                          icon={MunikumIcons.KostraEducation}
                          isActive={true}
                          style={{
                            backgroundColor: '#00778B',
                          }}
                        />
                      </LinkWrapper>
                      {/*<LinkWrapper*/}
                      {/*to={'/forum/list'}*/}
                      {/*data-tut="reactour__step3"*/}
                      {/*>*/}
                      {/*<DashboardBox2*/}
                      {/*title={intl.formatMessage(*/}
                      {/*commonMessages.mymunicipality*/}
                      {/*)}*/}
                      {/*body={intl.formatMessage(messages.myMunicipalityBody)}*/}
                      {/*icon={MunikumIcons.KostraEducation}*/}
                      {/*isActive={true}*/}
                      {/*style={{*/}
                      {/*backgroundColor: '#00778B',*/}
                      {/*}}*/}
                      {/*/>*/}
                      {/*</LinkWrapper>*/}
                      <LinkWrapper
                        data-tut="reactour__step4"
                        style={{ alignSelf: 'flex-start' }}
                        to={'/forum/public/create'}
                      >
                        <DashboardBox2
                          onClick={() => {
                            safeInvoke(this.props.handleClearFilter);
                          }}
                          title={intl.formatMessage(
                            messages.startNewForumThread
                          )}
                          body={intl.formatMessage(
                            messages.startNewForumThreadText
                          )}
                          icon={MunikumIcons.Add}
                          isActive={true}
                          style={{
                            backgroundColor: ' #00B0B9',
                          }}
                        />
                      </LinkWrapper>
                    </div>
                  </MainBox>
                  {/*<LastUpdatedComp*/}
                  {/*  data-tut="reactour__step5"*/}
                  {/*  style={{ display: 'flex' }}*/}
                  {/*>*/}
                  {/*  <LastUpdated*/}
                  {/*    title={'Aktivitetslogg'}*/}
                  {/*    list={discussionssSorted}*/}
                  {/*    icon={MunikumIcons.ArrowRight}*/}
                  {/*    onClick={uri => myHistory.push('/forum/public/' + uri)}*/}
                  {/*    onSeeAllClick={() => myHistory.push('/forum/list')}*/}
                  {/*  />*/}
                  {/*</LastUpdatedComp>*/}
                  <div style={{ margin: '4px' }} data-tut="reactour__step6">
                    {/*FIXME Removes news*/}
                    {/*<NewsSectionComp news={this.state.items} />*/}
                  </div>
                </ContainerDashboard>
              </OuterContainer>
              <Tour
                steps={steps}
                isOpen={this.state.isTourOpen}
                onRequestClose={() => {
                  this.setState({ isTourOpen: false });
                }}
                maskClassName="mask"
                className="helper"
                rounded={5}
                accentColor={'#5cb7b7'}
              />
            </>
          );
        }}
      </Query>
    );
  }
}
const mapStateToProps = (
  state: RootState,
  ownProps: IForumDashboardProps
): IForumDashboardProps => ({
  list: state.discussion.list.filter,
});

const mapDispatchToProps = (
  dispatch: Dispatch<{}>
): IForumDashboardDispatchProps => ({
  handleFilterChange: (filter: IDiscussionsFilter) => {
    dispatch(filterDiscussionsActionCreator({ filter: filter }));
  },
  handleClearFilter: () => {
    dispatch(resetDiscussionFilterActionCreator({}));
  },
});

export const ForumDashboard = connect<IForumDashboardProps, {}, {}>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ForumDashboardComp));
export default ForumDashboard;
