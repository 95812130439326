import * as React from 'react';
import { PropsWithChildren, useContext } from 'react';
import { AuthContext, AuthInfo } from './AuthContext';
import { invariant } from 'ts-invariant';
import { isFunction } from '../components/common';

type CustomAuthFunc = (auth: AuthInfo) => boolean;

interface IAuthQueryProps {
  authFunc?: CustomAuthFunc;
}

/***
 * Use this to show content only if user is authenticated, has certain permissions, etc.
 * @param props
 * @constructor
 */
export const AuthQuery: React.FunctionComponent<PropsWithChildren<
  IAuthQueryProps
>> = (props: PropsWithChildren<IAuthQueryProps>) => {
  const value = useContext(AuthContext);
  invariant(value, 'You should not use AuthQuery outside a AuthContext..');

  let isAuthorized = value.isAuthenticated;

  if (isFunction(props.authFunc)) {
    isAuthorized = props.authFunc(value);
  }

  return <> {isAuthorized && props.children} </>;
};
