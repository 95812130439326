import { isType } from 'typescript-fsa';
import { Action } from 'redux';
import { call, put, take } from 'redux-saga/effects';
import { signoutSuccess } from '../auth/auth';
import {
  invitePerson,
  IPersonEditForm,
  IPersonMeForm,
} from '../../services/personService';
import { IApiError } from '../../services/api';
import { IPerson } from '../../services/models/person';
import { IInviteForm } from '../../components/invite/InviteForm';
import { actionCreatorFactory } from '../actionCreatorFactory';

const actionCreator = actionCreatorFactory('PERSON');

/**
 * Person redux module (actions, reducer, sagas, selectors). see also entities.ts for generic code!
 */

// actions:
export const editPerson = actionCreator<{ person: IPersonEditForm }>(
  'EDIT_USER'
);

export const cancelEditPerson = actionCreator<{}>('CANCEL_EDIT_USER');

export const editProfile = actionCreator<{ person: IPerson }>('EDIT_PROFILE');

export const cancelEditProfile = actionCreator<{}>('CANCEL_EDIT_PROFILE');

export const syncElasticSearchActionCreator = actionCreator<{}>(
  'SYNC_ELASTIC_SEARCH'
);

// updatePerson in entities.ts

export const updateMeActionCreator = actionCreator.async<
  IPersonMeForm,
  IPerson,
  IApiError
>('UPDATE_PROFILE');

export const invitePersonActionCreator = actionCreator.async<
  IInviteForm,
  undefined,
  IApiError
>('INVITE_PERSON');

export interface IPersonState {
  invite: {
    isInviting: boolean;
    success: boolean;
  };
  person: {
    editingPerson?: IPersonEditForm;
    isSaving: boolean;
    error?: string;
  };
  profile: {
    editingProfile?: IPersonMeForm;
    isSaving: boolean;
    error?: string;
  };
}

const initialState: IPersonState = {
  person: {
    isSaving: false,
    error: undefined,
  },
  profile: {
    isSaving: false,
    error: undefined,
  },
  invite: {
    isInviting: false,
    success: false,
  },
};

export const reducer = (
  state: IPersonState = initialState,
  action: Action
): IPersonState => {
  if (isType(action, signoutSuccess)) {
    return initialState;
  }

  if (isType(action, invitePersonActionCreator.started)) {
    return {
      ...state,
      invite: {
        ...state.invite,
        isInviting: true,
      },
    };
  }
  if (isType(action, invitePersonActionCreator.success)) {
    return {
      ...state,
      invite: {
        ...state.invite,
        isInviting: false,
        success: true,
      },
    };
  }
  if (isType(action, invitePersonActionCreator.failed)) {
    return {
      ...state,
      invite: {
        ...state.invite,
        isInviting: false,
        success: false,
      },
    };
  }
  if (isType(action, editPerson)) {
    return {
      ...state,
      person: {
        ...state.person,
        editingPerson: action.payload.person,
      },
    };
  }
  if (isType(action, cancelEditPerson)) {
    return {
      ...state,
      person: {
        ...state.person,
        editingPerson: undefined,
        isSaving: false,
      },
    };
  }

  if (isType(action, editProfile)) {
    return {
      ...state,
      profile: {
        ...state.profile,
        editingProfile: action.payload.person,
      },
    };
  }
  if (isType(action, cancelEditProfile)) {
    return {
      ...state,
      profile: {
        ...state.profile,
        editingProfile: undefined,
        isSaving: false,
      },
    };
  }
  if (isType(action, updateMeActionCreator.started)) {
    return {
      ...state,
      profile: {
        ...state.profile,
        isSaving: true,
      },
    };
  }
  if (isType(action, updateMeActionCreator.failed)) {
    return {
      ...state,
      profile: {
        ...state.profile,
        isSaving: false,
        error: action.payload.error.message,
      },
    };
  }
  if (isType(action, updateMeActionCreator.success)) {
    return {
      ...state,
      profile: {
        ...state.profile,
        isSaving: false,
        error: undefined,
        editingProfile: undefined,
      },
    };
  }

  return state;
};

export function* invitePersonSaga() {
  while (true) {
    const action = yield take(invitePersonActionCreator.started.type);

    try {
      const result = yield call(invitePerson, action.payload);
      yield put(
        invitePersonActionCreator.success({
          params: action.payload,
          result: result,
        })
      );
    } catch (e) {
      let tempError: { message: string; statusCode?: number } = {
        message: e.message,
      };
      if (e.hasOwnProperty('statusCode')) {
        tempError.statusCode = e.statusCode;
      }

      yield put(
        invitePersonActionCreator.failed({
          params: action.payload,
          error: tempError,
        })
      );
    }
  }
}
