import * as React from 'react';
import { CSSProperties, Suspense } from 'react';
import styled from 'styled-components';
import { SuspenseLoader } from '../components/basic/Loader/CircleSpinner';

export class EmptyLayout extends React.PureComponent<{
  style?: CSSProperties;
}> {
  render() {
    const Comp = styled.div`
      background-color: ${props => props.theme.bodyBackgroundColor};

      @media print {
        width: 100%;
        margin: 0;
        float: none;
        overflow: visible;
        background-color: #fff;
      }
    `;
    return (
      <Comp style={this.props.style}>
        <Suspense fallback={<SuspenseLoader />}>{this.props.children}</Suspense>
      </Comp>
    );
  }
}
