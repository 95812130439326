import * as React from 'react';
import styled from 'styled-components';
import {
  defineMessages,
  FormattedMessage,
  InjectedIntlProps,
  injectIntl,
} from 'react-intl';
import { readNotificationActionCreator } from '../../modules/notification/notification';
import { myHistory } from '../../index';
import { Colors, safeInvokeDeprecated } from '../common';
import { NotFollowedText } from '../meta/FollowedMetaList';
import { INotification } from '../../services/models/notification';
import { NotificationListItem } from './NotificationListItem';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../modules/rootReducer';

const messages = defineMessages({
  noAlerts: {
    id: 'FollowedMetaList.noAlerts',
    defaultMessage:
      'Here you will be notified of changes in grants, posts and topics that you follow ...',
  },
});
export interface INotificationListProps {
  notifications?: ReadonlyArray<INotification>;
  onClick?: (item: INotification) => void;
  isSidebarOpen: boolean;
}

const WrapperDiv = styled.div``;

const ContentDiv = styled.div``;

const NotificationsHeader = styled.div`
  height: 3em;
  background-color: ${props => props.theme.accent1.color};
  color: ${Colors.WHITE};
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 0.875em;
  font-weight: 400;
`;

class NotificationListComp extends React.PureComponent<
  INotificationListProps & InjectedIntlProps,
  { time: number }
> {
  constructor(props: INotificationListProps & InjectedIntlProps) {
    super(props);
    this.state = { time: 0 };
    if (props.isSidebarOpen) {
      this.poll();
    }
  }

  // tslint:disable-next-line
  private interval: any;

  poll() {
    this.interval = setInterval(
      () => this.setState({ time: Date.now() }),
      1000
    );
  }

  unPoll() {
    clearInterval(this.interval);
  }

  componentWillUnmount() {
    this.unPoll();
  }

  UNSAFE_componentWillReceiveProps(
    nextProps: INotificationListProps & InjectedIntlProps
  ) {
    if (nextProps.isSidebarOpen !== this.props.isSidebarOpen) {
      if (nextProps.isSidebarOpen) {
        this.poll();
      } else {
        this.unPoll();
      }
    }
  }

  render() {
    const { notifications, onClick, intl } = this.props;
    const { time } = this.state;

    return (
      <WrapperDiv>
        <NotificationsHeader>
          <FormattedMessage
            id={'NotificationList.alerts'}
            defaultMessage={'Alerts'}
          />
        </NotificationsHeader>
        <ContentDiv style={{ padding: '1em' }}>
          {(notifications &&
            notifications.length > 0 &&
            notifications.map(value => {
              return (
                <NotificationListItem
                  key={value.id}
                  item={value}
                  onClick={() => safeInvokeDeprecated(onClick, value)}
                  time={time}
                />
              );
            })) || (
            <NotFollowedText>
              {intl.formatMessage(messages.noAlerts)}
            </NotFollowedText>
          )}
        </ContentDiv>
      </WrapperDiv>
    );
  }
}

const mapStateToProps = (
  state: RootState,
  ownProps: INotificationListProps
): INotificationListProps => ({
  isSidebarOpen: ownProps.isSidebarOpen,
  notifications: state.notifications.notifications,
});

const mapDispatchToProps = (
  dispatch: Dispatch<{}>
): Partial<INotificationListProps> => ({
  onClick: (item: INotification) => {
    // console.log('click notificatino redux..', item);
    dispatch(readNotificationActionCreator.started({ id: item.id }));

    if (item.href && item.hrefType) {
      let uri = '';
      switch (item.hrefType) {
        case 'TOPIC':
          uri = '/topic/';
          break;
        case 'DISCUSSION':
          uri = '/forum/public/';
          break;
        case 'ACTION_VALUE':
          uri = '/action-value/';
          break;
        case 'CALENDAR':
        case 'TASK':
        case 'EVENT':
        case 'EVENT_INSTANCE':
          uri = '/calendar/';
          break;
        default:
      }
      myHistory.push(uri + item.href);
    }
  },
});

export const NotificationList = injectIntl(NotificationListComp);

export const ConnectedNotificationList = connect<
  INotificationListProps,
  {},
  INotificationListProps
>(
  mapStateToProps,
  mapDispatchToProps
)(NotificationList);
