import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import { safeInvokeDeprecated } from '../../common';
import { MuFormattingButton } from './MuFormattingButton';
import { MunikumIcons } from '../../common/icons';

export enum MuToolbarMode {
  /**
   * No formatting buttons
   */
  None = 'none',

  /**
   * Basic formatting buttons, bold, italic
   */
  Basic = 'basic',

  /**
   * Full pupp editor with headings, url, bold, +++
   * Not implemented yet, but we can use this when editing "rich text", example: topicpage?
   */
  Full = 'full',
}

export interface IMuToolbarProps {
  /**
   * Toolbar has 3 modes, None, basic, Full
   */
  toolbarMode: MuToolbarMode;

  isBoldChecked?: boolean;

  isItalicChecked?: boolean;

  style?: CSSProperties;
}

export interface IMuToolbarState {
  toolbarMode?: MuToolbarMode;

  isBoldChecked: boolean;

  isItalicChecked: boolean;
}

export type MuToolbarCommands = 'bold' | 'italic';

export interface IMuToolbarDispatch {
  onClickBold?: (isChecked: boolean) => void;
  onClickItalic?: (isChecked: boolean) => void;

  onClickCommand?: (command: MuToolbarCommands) => void;
}

const EditorToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export class MuToolbar extends React.PureComponent<
  IMuToolbarProps & IMuToolbarDispatch,
  IMuToolbarState
> {
  constructor(props: IMuToolbarProps) {
    super(props);

    this.state = {
      isBoldChecked: props.isBoldChecked != null ? props.isBoldChecked : false,
      isItalicChecked:
        props.isItalicChecked != null ? props.isItalicChecked : false,
      toolbarMode:
        props.toolbarMode != null ? props.toolbarMode : MuToolbarMode.Basic,
    };
  }

  UNSAFE_componentWillReceiveProps(
    nextProps: IMuToolbarProps & IMuToolbarDispatch
  ) {
    if (
      nextProps.toolbarMode &&
      nextProps.toolbarMode !== this.state.toolbarMode
    ) {
      this.setState({
        toolbarMode: nextProps.toolbarMode,
      });
    }

    if (
      nextProps.isBoldChecked != null &&
      nextProps.isBoldChecked !== this.state.isBoldChecked
    ) {
      this.setState({
        isBoldChecked: nextProps.isBoldChecked,
      });
    }

    if (
      nextProps.isItalicChecked != null &&
      nextProps.isItalicChecked !== this.state.isItalicChecked
    ) {
      this.setState({
        isItalicChecked: nextProps.isItalicChecked,
      });
    }
  }

  handleClickCmd = (cmd: MuToolbarCommands) => {
    if (cmd === 'bold') {
      this.setState(
        {
          isBoldChecked: !this.state.isBoldChecked,
        },
        () => {
          safeInvokeDeprecated(
            this.props.onClickBold,
            this.state.isBoldChecked
          );
          safeInvokeDeprecated(this.props.onClickCommand, 'bold');
        }
      );
    }
    if (cmd === 'italic') {
      this.setState(
        {
          isItalicChecked: !this.state.isItalicChecked,
        },
        () => {
          safeInvokeDeprecated(
            this.props.onClickItalic,
            this.state.isItalicChecked
          );
          safeInvokeDeprecated(this.props.onClickCommand, 'italic');
        }
      );
    }
  };

  render() {
    const { toolbarMode, isBoldChecked, isItalicChecked } = this.state;
    const { style } = this.props;

    return (
      <EditorToolbar style={style}>
        <ButtonsContainer>
          {toolbarMode === MuToolbarMode.Basic && (
            <React.Fragment>
              <MuFormattingButton
                icon={MunikumIcons.FormatBold}
                onChange={(isChecked: boolean) => {
                  // this.handleKeyCommand('bold', this.state.draftState);
                  // safeInvoke(this.props.onClickBold, isChecked);
                  this.handleClickCmd('bold');
                }}
                checked={isBoldChecked}
              />
              <MuFormattingButton
                icon={MunikumIcons.FormatItalic}
                onChange={(isChecked: boolean) => {
                  // this.handleKeyCommand('italic', this.state.draftState);
                  // safeInvoke(this.props.onClickItalic, isChecked);
                  this.handleClickCmd('italic');
                }}
                checked={isItalicChecked}
                style={{
                  marginLeft: '.25em',
                }}
              />
            </React.Fragment>
          )}
        </ButtonsContainer>
      </EditorToolbar>
    );
  }
}
