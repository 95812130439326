import styled from 'styled-components';
import { Colors } from '../../common';
import { sizes } from '../../common/media';

const isDebugMode = false;

interface ICardContainerRenderProps {
  canHover: boolean;
}

/**
 * Outer flex container for a Card
 */
export const CardContainer = styled.div`
  display: flex;
  border-radius: 3px;
  background-color: ${props => props.theme.contentBackgroundColor};
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);

  border: 1px solid ${props => props.theme.noAccent.borderColor};

  margin-bottom: 1.5em; // 0.625em;

  transition: box-shadow 0.2s ease-in-out;

  cursor: ${(props: ICardContainerRenderProps) =>
    props.canHover ? 'pointer' : 'default'};

  &:hover {
    box-shadow: ${(props: ICardContainerRenderProps) =>
      props.canHover
        ? '0 2px 7px 0 rgba(0, 0, 0, 0.3)'
        : '0 2px 7px 0 rgba(0, 0, 0, 0.1)'};
  }
`;

/**
 * Left (grey) flex container for card
 */
export const CardLeftContainerNormal = styled.div`
  background-color: ${props => props.theme.tertiaryContentBackgroundColor};
  padding: 1em;
  display: none;
  @media (min-width: ${sizes.mobile}px) {
    display: flex;
    flex: 0 0 auto; // wont grow or shrink, initial width = content
  }

  border-radius: 4px 0 0 4px;
`;

export const CardLeftContainerResponsive = styled.div`
  display: none;

  flex: 0 0 auto; // wont grow or shrink, initial width = content

  @media (min-width: ${860}px) {
    display: block;
    padding: 1em;
    background-color: ${props => props.theme.tertiaryContentBackgroundColor};
    border-radius: 4px 0 0 4px;
  }
`;

/**
 * ChatBubble / triangle. Put this in CardLeftContainer and set position relative on container
 */
export const ChatBubble = styled.div`
  position: absolute;
  top: 1.25em;
  right: -0.5em;

  width: 1em;
  height: 1em;

  background-color: ${props => props.theme.contentBackgroundColor};

  transform: rotate(45deg);
  z-index: 15;
`;

export const ChatBubbleHigh = styled(ChatBubble)`
  top: 0.25em;
`;

/**
 * Main Card content flex container
 */
export const CardContentContainer = styled.div`
  flex: 1 1 0px;

  padding: 1.25em;
  border: 1px solid ${props => props.theme.noAccent.borderColor};
  background-color: ${props => props.theme.contentBackgroundColor};
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  overflow-wrap: break-word;
  word-break: break-word; /* Chrome, Safari */
  word-wrap: break-word; /* IE11, Firefox */
`;

/**
 * Card title, hidden overflow
 */
export const CardTitle = styled.div`
  font-family: Lato, sans-serif;
  font-size: 1.375em;
  font-weight: bold;
  color: ${props => props.theme.color};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  background-color: ${isDebugMode ? 'darkorange' : 'transparent'};
`;

/**
 * small text, last updated. no line break
 */
export const CardLastUpdated = styled.div`
  font-family: Lato, sans-serif;
  font-size: 0.75em;
  letter-spacing: 0.05em;
  color: ${props => props.theme.color};
  opacity: 0.7;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

/**
 * Card text, max height on text, if larger than XX px text is faded out
 */
export const CardText = styled.div`
  color: ${props => (props.color ? props.color : Colors.BLACK)};
  font-family: 'Lato', sans-serif;
  font-size: 0.875em; // 14px
  line-height: 1.375em; // 22px

  overflow: hidden;
  height: 6.625em;

  margin-top: 1em;
  margin-bottom: 1em;
`;
