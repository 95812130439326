import { defineMessages, InjectedIntl } from 'react-intl';
import { IComment } from './comment';
import { IContentData } from './contentData';
import { IEntity } from './entity';
import { IPersonSimple } from './discussion';
import { getNamesAndValues } from '../../components/common';

export interface IContentMeta extends IEntity {
  title: string;
  description: string;
  parent: string;
  previous: string;
  dateCreated?: Date;
  lastUpdated?: Date;
  status: ContentStatus;
  currentRevision: string;
  year?: string;
  realms: ReadonlyArray<string>;
  type: MetaType;
  uri: string;
  watched: boolean;
  createdBy: IPersonSimple;
  lastComment: Date;
  master?: string;

  followers: number;

  followerList: ReadonlyArray<IPersonSimple>;

  /**
   * only used in discussion
   */
  comments: ReadonlyArray<IComment>;

  /**
   * Related topics
   */
  topics: ReadonlyArray<number>;

  /**
   * related action values
   */
  actionValues: ReadonlyArray<number>;

  /**
   * related discussions
   */
  discussions: ReadonlyArray<number>;

  /**
   * related files
   */
  files: ReadonlyArray<number>;

  /**
   * all related metas? not used?
   */
  related: ReadonlyArray<number>;

  // used by tree view
  revisions?: ReadonlyArray<{
    id: string;
    date: string;
  }>;

  limited: boolean;
  restricted: boolean;
}

export interface IMetaAndContent {
  meta: IContentMeta;
  content: IContentData;
}

export enum ContentStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  OBSOLETE = 'OBSOLETE',
  TO_APPROVAL = 'TO_APPROVAL',
  ARCHIVED = 'ARCHIVED',
}

// export const contentStatusDict = getNamesAndValues(ContentStatus);

export enum MetaType {
  CONTAINER = 'CONTAINER',
  ACTION_VALUE = 'ACTION_VALUE',
  DISCUSSION = 'DISCUSSION',
  USERFILE = 'USERFILE',
  COMMENT = 'COMMENT',
  TOPIC = 'TOPIC',
  CALENDAR = 'CALENDAR',
  TASK = 'TASK',
}

// Example enum translation
export const metaMessages = defineMessages({
  DRAFT: {
    id: 'ContentStatus.DRAFT',
    defaultMessage: 'Draft',
  },
  PUBLISHED: {
    id: 'ContentStatus.PUBLISHED',
    defaultMessage: 'Published',
  },
  OBSOLETE: {
    id: 'ContentStatus.OBSOLETE',
    defaultMessage: 'Invalid',
  },
  TO_APPROVAL: {
    id: 'ContentStatus.TO_APPROVAL',
    defaultMessage: 'To approval',
  },
  ARCHIVED: {
    id: 'ContentStatus.ARCHIVED',
    defaultMessage: 'Archived',
  },
});

/**
 * get a list of translated content statuses for use in dropdowns etc.
 * @param {ReactIntl.InjectedIntl} intl
 */
export function getTranslatedContentStatuses(intl: InjectedIntl) {
  const temp: { [id: string]: string } = {};
  getNamesAndValues(ContentStatus).forEach(x => {
    temp[x.value] = intl.formatMessage(metaMessages[x.name]);
  });
  return temp;
}
