import * as React from 'react';
import { CSSProperties, RefObject } from 'react';
import styled from 'styled-components';
import { IconType } from 'react-icons/lib';
import {
  Colors,
  DEFAULT_COLOR_THEME,
  getThemeProps,
  IAccentColorSet,
  safeInvokeDeprecated,
} from '../../common';
import { Placement, Popover } from '../Popover/Popover';
import { MunikumIcons } from '../../common/icons';

export interface ITooltipProps {
  /**
   * content to render inside tooltip
   */
  content: JSX.Element | string;

  /**
   * where to put tooltip, default is top
   */
  position?: Placement;

  // used in timeline view for events,
  fullWidthTooltip?: boolean;

  /**
   * put tooltip in controlled mode setting isOpen explicitly
   */
  isOpen?: boolean;

  /**
   * set tooltip as open initially, behaves like a normal tooltip after this
   */
  isDefaultOpen?: boolean;

  accent?: IAccentColorSet;

  /**
   * enable this to force update on popper-js position on EACH RENDER
   * don't use this unless you really have to, rendering will be twice as slow...
   */
  isAggressiveUpdateModeEnabled?: boolean;

  style?: CSSProperties;

  /**
   * delay in ms before showing tooltip, default 500ms
   */
  delayIn?: number;

  /**
   * delay in ms before hiding tooltip, default 200ms
   */
  delayOut?: number;
  onClick?: () => void;

  /**
   * set to true if you have overflow propblems, or you want the tooltip to dynamic position itself
   */
  usePortal?: boolean;

  hideOverflow?: boolean;
}

interface ITooltipState {
  isHoveringTarget: boolean;
  isOpen: boolean;
  isMouseOverPopover: boolean;
}

const TooltipText = styled.div`
  font-family: Lato, sans-serif;
  color: ${props => props.theme.textColor};
  font-size: 0.875em;
  line-height: 1.375em;
  position: relative;
`;

/**
 * A Tooltip component.
 * Wrap your target component with Tooltip and set content prop, example:
 * <Tooltip content={'tooltip text'}><div>hover me</div></Tooltip>
 */
export class Tooltip extends React.PureComponent<ITooltipProps, ITooltipState> {
  public static defaultProps: ITooltipProps = {
    position: 'top',
    content: '',
    accent: getThemeProps(DEFAULT_COLOR_THEME).noAccent,
  };

  // tslint:disable-next-line
  private hoverDelayTimer: any;
  private childRef: RefObject<any> = null;

  constructor(props: ITooltipProps) {
    super(props);
    this.childRef = React.createRef();
    this.state = {
      isMouseOverPopover: false,
      isOpen: this.props.isDefaultOpen || this.props.isOpen || false,
      isHoveringTarget: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: ITooltipProps) {
    if (
      nextProps.isOpen !== undefined &&
      nextProps.isOpen !== this.state.isOpen
    ) {
      this.setState({
        ...this.state,
        isOpen: nextProps.isOpen,
      });
    }
  }

  handleTargetMouseEnter = (e: React.SyntheticEvent<HTMLElement>) => {
    // console.log('tooltip ENTER');

    this.setState(
      {
        isHoveringTarget: true,
      },
      () => {
        if (this.hoverDelayTimer !== undefined) {
          window.clearTimeout(this.hoverDelayTimer);
        }

        if (this.props.isOpen === undefined) {
          this.hoverDelayTimer = setTimeout(() => {
            // console.log ('SHOW if hovering still: ' + this.state.isHoveringTarget);
            if (this.state.isHoveringTarget) {
              this.setState({
                isOpen: true,
              });
            }
          }, this.props.delayIn || 500); // 300
        }
      }
    );
  };

  handleTargetMouseLeave = (e: React.SyntheticEvent<HTMLElement>) => {
    // console.log('tooltip LEAVE');
    if (!this.state.isMouseOverPopover) {
      this.setState(
        {
          isHoveringTarget: false,
        },
        () => {
          if (this.hoverDelayTimer !== undefined) {
            window.clearTimeout(this.hoverDelayTimer);
          }

          if (this.props.isOpen === undefined) {
            this.hoverDelayTimer = setTimeout(() => {
              // console.log (
              //   'HIDE if not hovering, is hovering: ' + this.state.isHoveringTarget
              // );
              if (!this.state.isHoveringTarget) {
                this.setState({
                  isOpen: false,
                });
              }
            }, this.props.delayOut || 200); // 150
          }
        }
      );
    }
  };

  componentWillUnmount() {
    if (this.hoverDelayTimer !== undefined) {
      window.clearTimeout(this.hoverDelayTimer);
    }
  }

  render() {
    const { accent } = this.props;

    const backgroundColor = accent.color;
    const foregroundColor = accent.activeColor;

    const {
      usePortal,
      position,
      content,
      children,
      isAggressiveUpdateModeEnabled,
    } = this.props;
    const { isOpen } = this.state;

    const count = React.Children.count(children);
    if (count !== 1) {
      console.error(
        'Tooltip can only have ONE child and that should be the TARGET element! You passed me ' +
          count +
          ' children :-S'
      );
    }

    let targetElementOrText: any | undefined;

    if (React.isValidElement(children)) {
      const singleChild: any = React.Children.only(children);
      if (singleChild) {
        targetElementOrText = React.cloneElement(singleChild, {
          onMouseEnter: this.handleTargetMouseEnter,
          onMouseLeave: this.handleTargetMouseLeave,
        });
      }
    } else if (typeof children === 'string') {
      targetElementOrText = (
        <span
          onMouseEnter={this.handleTargetMouseEnter}
          onMouseLeave={e => {
            this.handleTargetMouseLeave(e);
          }}
          style={{ position: 'relative' }}
        >
          {children}
        </span>
      );
    } else {
      throw Error(
        'unsupported children please supply a valid target element or text'
      );
    }

    // if content is string we set font size, if content is JSX it's up to YOU to set correct size ;)
    const isContentString = typeof content === 'string';
    // const defaultStyle = {
    //   padding: '.5em 1em',
    //   maxWidth: '21.875em',
    //   textAlign: 'center',
    //   position: 'relative',
    //   backgroundColor: Colors.WHITE,
    // };

    // BUG: style is not set correctly... why?
    // const myStyle: CSSProperties = Object.assign({}, defaultStyle, style);
    // console.log('myStyle', style);

    return (
      <div
        style={{
          width: this.props.fullWidthTooltip ? '100%' : '',
          overflow: this.props.hideOverflow ? 'hidden' : 'visible',
        }}
        onMouseOver={() => {
          this.setState({
            isMouseOverPopover: true,
          });
        }}
        onMouseLeave={e => {
          this.setState(
            {
              isMouseOverPopover: false,
            },
            () => {
              this.handleTargetMouseLeave(e);
            }
          );
        }}
      >
        <Popover
          target={targetElementOrText}
          isOpen={isOpen}
          style={this.props.style}
          position={position}
          usePortal={usePortal}
          useStaticHack={true}
          backgroundColor={backgroundColor}
          foregroundColor={foregroundColor}
          isDefaultOpen={false}
          ref={this.childRef}
          isAggressiveUpdateModeEnabled={isAggressiveUpdateModeEnabled}
        >
          {isContentString && <TooltipText>{content}</TooltipText>}
          {!isContentString && content}
        </Popover>
      </div>
    );
  }
}

interface IInfoTooltipProps {}

const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  //background-color: pink;
  cursor: help;

  position: relative;
`;

const IconContainer = styled.div`
  width: 1em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1em;
  //background-color: deeppink;

  margin-top: 0.5em;

  position: relative;
`;

export class InfoTooltip extends React.PureComponent<
  ITooltipProps & IInfoTooltipProps,
  {}
> {
  renderIcon(iconComponent: IconType) {
    if (iconComponent === undefined || iconComponent === null) {
      return null;
    }

    const Icon = iconComponent;
    const x = '1em';

    return (
      <Icon
        fill={Colors.RED}
        width={x}
        height={x}
        style={{
          transition: 'fill .25s ease-in-out, color .25s ease-in-out',
        }}
      />
    );
  }

  render() {
    const { content, children, ...rest } = this.props;

    return (
      <Tooltip
        content={content}
        {...rest}
        onClick={() => {
          safeInvokeDeprecated(this.props.onClick);
        }}
      >
        <div style={{ position: 'relative' }}>
          <OuterContainer>
            <div style={{ position: 'relative' }}>{children}</div>
            <IconContainer>{this.renderIcon(MunikumIcons.Info)}</IconContainer>
          </OuterContainer>
        </div>
      </Tooltip>
    );
  }
}
