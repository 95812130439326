import * as React from 'react';
import { CSSProperties, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { IThemeProps, safeInvokeDeprecated } from '../../common';
import { MunikumKeys } from '../../common/keys';

export interface IToggleButtonProps {
  name?: string;

  checked?: boolean;
  defaultChecked?: boolean;
  onClick?: (e: any, checked: boolean) => void;
  disabled?: boolean;

  style?: CSSProperties;
  label?: React.ReactNode;
}

const HiddenCheck = styled.input`
  // hide actual checkbox:
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
  &:checked {
    // this works, but noe when referencing in span..
    //opacity: 0;
  }
`;

const MyLabel = styled.label`
  cursor: ${(props: { isDisabled: boolean }) =>
    props.isDisabled ? 'not-allowed' : 'pointer'};
  margin-bottom: 0.5em;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  outline: none;
`;

const OuterCircle = styled.div`
  height: 23px;
  width: 46px;
  border-radius: 83px;
  background-color: ${(props: { isChecked: boolean; theme: IThemeProps }) =>
    props.isChecked
      ? props.theme.accent1.color
      : props.theme.noAccent.borderColor};
  position: relative;
`;

const CircleInside = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 83px;
  background-color: ${props => props.theme.secondaryContentBackgroundColor};
  transition: left 0.25s ease-out;
  position: absolute;
  bottom: 6.5%;
  left: ${(props: { isChecked: boolean }) => (props.isChecked ? '54%' : '6%')};
`;

const Info = styled.div`
  margin-left: 0.5em;
  color: #333;
  opacity: ${(props: { isDisabled: boolean }) => (props.isDisabled ? 0.5 : 1)};
`;

/**
 * Checkbox2
 * a "normal" checkbox, with working label, and only red color =)
 */
const ToggleButton: React.FunctionComponent<IToggleButtonProps> = props => {
  const myId = _.uniqueId('toggle-');
  const [isChecked, setIsChecked] = useState(
    props.checked || props.defaultChecked || false
  );
  // eslint-disable-next-line
  const [isHovering, setIsHovering] = useState(false);

  const handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (!props.disabled) {
      // TODO: how to invoke event on setState callback??
      const nextVal = !isChecked;
      setIsChecked(nextVal);
      safeInvokeDeprecated(props.onClick, e, nextVal);
    }
  };

  // TODO: add if we need controlled mode..
  const myCheckedVal = props.checked !== undefined ? props.checked : isChecked;

  return (
    <MyLabel
      isDisabled={props.disabled}
      style={props.style}
      htmlFor={myId}
      onClick={handleClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      tabIndex={0}
      onKeyDown={(e: any) => {
        if (e.which === MunikumKeys.ENTER || e.which === MunikumKeys.SPACE) {
          if (!props.disabled) {
            const next = !isChecked;
            setIsChecked(next);
            safeInvokeDeprecated(props.onClick, next);
          }
        }
      }}
    >
      <HiddenCheck
        id={myId}
        type={'checkbox'}
        name={props.name}
        onChange={handleClick}
        checked={isChecked}
      />
      <OuterCircle isChecked={myCheckedVal}>
        <CircleInside isChecked={myCheckedVal} />
      </OuterCircle>
      <Info isDisabled={props.disabled}>{props.label}</Info>
    </MyLabel>
  );
};

export default ToggleButton;
