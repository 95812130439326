export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date type */
  Date: Date;
  /** Long type */
  Long: any;
};

/** description of Access */
export type Access = {
  __typename?: 'Access';
  /** description of field isPrivate */
  isPrivate: Scalars['Boolean'];
  /** description of field visibility */
  visibility: ContentVisibilityEnum;
  /** description of field persons */
  persons: Array<PersonAccess>;
  /** description of field groups */
  groups: Array<GroupAccess>;
  /** description of field organizations */
  organizations: Array<OrganizationAccess>;
  /** description of field organizationGroups */
  organizationGroups: Array<OrganizationGroupAccess>;
  myAccess: AccessEnum;
};

/** AccessEnum */
export enum AccessEnum {
  WRITE = 'WRITE',
  READ = 'READ',
  NONE = 'NONE',
}

export type AccessInput = {
  /** description of field isPrivate */
  isPrivate: Scalars['Boolean'];
  /** description of field visibility */
  visibility: ContentVisibilityEnum;
  /** description of field persons */
  persons?: Maybe<Array<PersonAccessInput>>;
  /** description of field groups */
  groups?: Maybe<Array<PersonGroupAccessInput>>;
  /** description of field organizations */
  organizations?: Maybe<Array<OrganizationAccessInput>>;
  /** description of field organizationGroups */
  organizationGroups?: Maybe<Array<OrganizationGroupAccessInput>>;
};

/** An action value */
export type ActionValue = {
  __typename?: 'ActionValue';
  /** id */
  id: Scalars['ID'];
  /** Date the action value was created */
  dateCreated: Scalars['Date'];
  /** Date the action value was last updated */
  lastUpdated: Scalars['Date'];
  /** The title of the action value */
  title: Scalars['String'];
  uri: Scalars['String'];
  /** Short description of the action value */
  description?: Maybe<Scalars['String']>;
  descriptionText?: Maybe<Scalars['String']>;
  /** Publication status of the action value */
  status: ContentStatus;
  /** description of field year */
  year: Scalars['Date'];
  /** The current revision of the action value */
  currentRevision: ActionValueRevision;
  /** Comments on this action value */
  comments: Array<Comment>;
  /** Topics related to this action value */
  topics: Array<Topic>;
  /** Other related action values */
  actionValues: Array<ActionValue>;
  /** Discussions related to this action value */
  discussions: Array<Discussion>;
  /** Files attached to this action value */
  files: Array<UserFile>;
  /** Related calendars */
  calendars: Array<Calendar>;
  /** If you are following this action value */
  isFollowing: Scalars['Boolean'];
  /** If you like this */
  isLiked: Scalars['Boolean'];
  /** description of field likes */
  likes: Scalars['Int'];
  /** People following this action value */
  followers: Array<Person>;
  kostraFunctions: Array<KostraFunction>;
  /** description of field realms */
  realms: Array<Realm>;
  /** description of field views */
  views: Scalars['Int'];
};

/** ActionValueCategoryEnum */
export enum ActionValueCategoryEnum {
  OPERATIONAL = 'OPERATIONAL',
  INVESTMENT = 'INVESTMENT',
}

/** Hello World */
export type ActionValueRevision = {
  __typename?: 'ActionValueRevision';
  /** id */
  id: Scalars['ID'];
  /** Creation date of the revision */
  dateCreated: Scalars['Date'];
  /** Last update to this revision */
  lastUpdated: Scalars['Date'];
  /** The title of the action value at the time of this revision */
  title: Scalars['String'];
  /** The year of the action value */
  year?: Maybe<Scalars['Int']>;
  /** Short description of the action value */
  description?: Maybe<Scalars['String']>;
  descriptionText?: Maybe<Scalars['String']>;
  /** Long description of the action value */
  body?: Maybe<Scalars['String']>;
  bodyText?: Maybe<Scalars['String']>;
  links: Array<HttpLink>;
  sourceUrl?: Maybe<Scalars['String']>;
  source?: Maybe<Organization>;
  category?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  radarGraph?: Maybe<Array<RadarGraphType>>;
  deadlines?: Maybe<Array<DeadlineType>>;
};

/** description of ActionValueSearch */
export type ActionValueSearch = {
  __typename?: 'ActionValueSearch';
  /** description of field id */
  id: Scalars['ID'];
  /** description of field title */
  title: Scalars['String'];
  /** description of field description */
  description?: Maybe<Scalars['String']>;
  /** description of field uri */
  uri: Scalars['String'];
};

/** ActionValueTypeEnum */
export enum ActionValueTypeEnum {
  SHORT_TERM = 'SHORT_TERM',
  MIDDLE_TERM = 'MIDDLE_TERM',
  LONG_TERM = 'LONG_TERM',
  YEARLY = 'YEARLY',
}

export type AddEmailToPersonInput = {
  person: Scalars['ID'];
  email: Scalars['String'];
};

export type AddTagInput = {
  title: Scalars['String'];
  meta: Scalars['ID'];
  color?: Maybe<Scalars['String']>;
};

export type AddToRelatedInput = {
  /** description of field meta */
  meta: Scalars['Long'];
  /** description of field metasToAdd */
  metasToAdd: Array<Scalars['Long']>;
};

export type AdminUpdateOrganization = {
  id: Scalars['ID'];
  displayTitle?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  imageFormat?: Maybe<Scalars['String']>;
};

/** description of AllActionValues */
export type AllActionValues = {
  __typename?: 'AllActionValues';
  /** description of field nodes */
  nodes: Array<ActionValue>;
  /** description of field pageInfo */
  pageInfo: PageInfo;
  /** description of field totalCount */
  totalCount: Scalars['Int'];
};

export type AllActionValuesInput = {
  /** description of field sort */
  sort?: Maybe<SortEnum>;
  /** description of field order */
  order?: Maybe<OrderEnum>;
  /** description of field status */
  status?: Maybe<ContentStatus>;
  /** description of field limit */
  limit?: Maybe<Scalars['Int']>;
  /** description of field offset */
  offset?: Maybe<Scalars['Int']>;
  /** description of field sector */
  sector?: Maybe<Scalars['String']>;
  /** description of field kostraGroup */
  kostraGroup?: Maybe<Scalars['Long']>;
  onlyFollowed?: Maybe<Scalars['Boolean']>;
};

/** description of AllCalendars */
export type AllCalendars = {
  __typename?: 'AllCalendars';
  /** description of field nodes */
  nodes: Array<Calendar>;
  /** description of field pageInfo */
  pageInfo: PageInfo;
  /** description of field totalCount */
  totalCount: Scalars['Int'];
};

export type AllCalendarsInput = {
  /** description of field sort */
  sort?: Maybe<SortEnum>;
  /** description of field order */
  order?: Maybe<OrderEnum>;
  /** description of field status */
  status?: Maybe<ContentStatus>;
  /** description of field limit */
  limit?: Maybe<Scalars['Int']>;
  /** description of field offset */
  offset?: Maybe<Scalars['Int']>;
  /** description of field organizations */
  organizations?: Maybe<Array<Scalars['Long']>>;
  /** description of field kostraGroup */
  kostraGroup?: Maybe<Scalars['Long']>;
  persons?: Maybe<Array<Scalars['ID']>>;
  category?: Maybe<CalendarCategoryEnum>;
};

/** description of AllDiscussions */
export type AllDiscussions = {
  __typename?: 'AllDiscussions';
  /** description of field nodes */
  nodes: Array<Discussion>;
  /** description of field pageInfo */
  pageInfo: PageInfo;
  /** description of field totalCount */
  totalCount: Scalars['Int'];
};

export type AllDiscussionsInput = {
  /** description of field sort */
  sort?: Maybe<SortEnum>;
  /** description of field order */
  order?: Maybe<OrderEnum>;
  /** description of field status */
  status?: Maybe<ContentStatus>;
  /** description of field limit */
  limit?: Maybe<Scalars['Int']>;
  /** description of field offset */
  offset?: Maybe<Scalars['Int']>;
  /** description of field onlyMine */
  onlyMine?: Maybe<Scalars['Boolean']>;
  /** description of field organizations */
  organizations?: Maybe<Array<Scalars['Long']>>;
};

export type AllOrganizations = {
  __typename?: 'AllOrganizations';
  /** description of field nodes */
  nodes: Array<OrganizationAdmin>;
  /** description of field pageInfo */
  pageInfo: PageInfo;
  /** description of field totalCount */
  totalCount: Scalars['Int'];
};

export type AllOrganizationsInput = {
  /** description of field sort */
  sort?: Maybe<SortEnum>;
  /** description of field order */
  order?: Maybe<OrderEnum>;
  searchQuery?: Maybe<Scalars['String']>;
  /** description of field limit */
  limit?: Maybe<Scalars['Int']>;
  /** description of field offset */
  offset?: Maybe<Scalars['Int']>;
};

export type AllPersons = {
  __typename?: 'AllPersons';
  /** description of field nodes */
  nodes: Array<PersonAdmin>;
  /** description of field pageInfo */
  pageInfo: PageInfo;
  /** description of field totalCount */
  totalCount: Scalars['Int'];
};

/** description of AllTopics */
export type AllTopics = {
  __typename?: 'AllTopics';
  /** description of field nodes */
  nodes: Array<Topic>;
  /** description of field pageInfo */
  pageInfo: PageInfo;
  /** description of field totalCount */
  totalCount: Scalars['Int'];
};

export type AllTopicsInput = {
  /** description of field category */
  category?: Maybe<TopicCategorySortEnum>;
  /** description of field sort */
  sort?: Maybe<SortEnum>;
  /** description of field order */
  order?: Maybe<OrderEnum>;
  /** description of field status */
  status?: Maybe<ContentStatus>;
  /** description of field limit */
  limit?: Maybe<Scalars['Int']>;
  /** description of field offset */
  offset?: Maybe<Scalars['Int']>;
  /** description of field sector */
  sector?: Maybe<Scalars['String']>;
  /** description of field kostraGroup */
  kostraGroup?: Maybe<Scalars['Long']>;
  /** description of field organizations */
  organizations?: Maybe<Array<Scalars['Long']>>;
  onlyMine?: Maybe<Scalars['Boolean']>;
  persons?: Maybe<Array<Scalars['ID']>>;
};

export type ApiKey = {
  __typename?: 'ApiKey';
  id: Scalars['ID'];
  token: Scalars['String'];
  description: Scalars['String'];
  dateCreated: Scalars['Date'];
  lastUsed: Scalars['Date'];
};

/** An annual cycle */
export type Calendar = {
  __typename?: 'Calendar';
  /** id */
  id: Scalars['ID'];
  title: Scalars['String'];
  uri: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  descriptionText?: Maybe<Scalars['String']>;
  dateCreated: Scalars['Date'];
  lastUpdated: Scalars['Date'];
  createdBy: Person;
  /** Default color of the calendar */
  color: Scalars['String'];
  /** Tasks in this calendar */
  tasks: Array<Task>;
  /** status */
  status: ContentStatus;
  /** description of field access */
  access: Access;
  /** If you are following this calendar */
  isFollowing: Scalars['Boolean'];
  /** If you like this */
  isLiked: Scalars['Boolean'];
  /** description of field likes */
  likes: Scalars['Int'];
  /** description of field followers */
  followers: Array<Person>;
  /** description of field topics */
  topics: Array<Topic>;
  /** description of field actionValues */
  actionValues: Array<ActionValue>;
  /** description of field discussions */
  discussions: Array<Discussion>;
  /** description of field files */
  files: Array<UserFile>;
  /** description of field calendars */
  calendars: Array<Calendar>;
  /** description of field views */
  views: Scalars['Int'];
  /** If true, this calendar has access restrictions */
  isRestricted: Scalars['Boolean'];
  /** If true, you do not have access to this calendar */
  isLimited: Scalars['Boolean'];
  /** description of field kostraFunctions */
  kostraFunctions: Array<KostraFunction>;
  /** description of field category */
  category: CalendarCategoryEnum;
  shareableUri?: Maybe<Scalars['String']>;
  tags: Array<Tag>;
  forkedFrom?: Maybe<Calendar>;
  forkMaster?: Maybe<Calendar>;
  forks: Array<Calendar>;
};

/** An annual cycle */
export type CalendarTasksArgs = {
  year?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
  tags?: Maybe<Array<Scalars['String']>>;
};

/** CalendarCategoryEnum */
export enum CalendarCategoryEnum {
  TOPIC = 'TOPIC',
  ORGANIZATION = 'ORGANIZATION',
  PROJECT = 'PROJECT',
  GRANT = 'GRANT',
}

/** An annual cycle ring */
export type CalendarRing = {
  __typename?: 'CalendarRing';
  /** id */
  id: Scalars['ID'];
  dateCreated: Scalars['Date'];
  lastUpdated: Scalars['Date'];
  /** Order in wheel */
  order: Scalars['Int'];
  /** Color of the ring */
  color: Scalars['String'];
  /** Calendar data for this ring */
  calendar: Calendar;
};

/** description of CalendarSearch */
export type CalendarSearch = {
  __typename?: 'CalendarSearch';
  /** description of field id */
  id: Scalars['ID'];
  /** description of field title */
  title: Scalars['String'];
  /** description of field description */
  description?: Maybe<Scalars['String']>;
  /** description of field uri */
  uri: Scalars['String'];
  createdBy: Person;
};

export enum CalendarViewEnum {
  DEFAULT = 'DEFAULT',
  TIMELINE = 'TIMELINE',
}

export type ChangeOwnerInput = {
  id: Scalars['ID'];
  person: Scalars['ID'];
};

/** A comment on something */
export type Comment = {
  __typename?: 'Comment';
  /** id */
  id: Scalars['ID'];
  /** The author of the comment */
  author: Person;
  /** Date the comment was posted */
  dateCreated: Scalars['Date'];
  /** Date the comment was last edited */
  lastUpdated: Scalars['Date'];
  /** The comment message body */
  message: Scalars['String'];
  messageText: Scalars['String'];
  /** Replies to the comment */
  replies: Array<Comment>;
  /** Files attached to this comment */
  files: Array<UserFile>;
  /** status */
  status: ContentStatus;
  /** If you like this */
  isLiked: Scalars['Boolean'];
  /** description of field likes */
  likes: Scalars['Int'];
};

/** Content status enum test */
export enum ContentStatus {
  DRAFT = 'DRAFT',
  OBSOLETE = 'OBSOLETE',
  PUBLISHED = 'PUBLISHED',
  TO_APPROVAL = 'TO_APPROVAL',
  ARCHIVED = 'ARCHIVED',
}

/** ContentVisibilityEnum */
export enum ContentVisibilityEnum {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export type CopyCalendarInput = {
  /** description of field id */
  id: Scalars['Long'];
  /** description of field title */
  title: Scalars['String'];
};

export type CreateActionValueInput = {
  /** description of field title */
  title: Scalars['String'];
  /** description of field body */
  body?: Maybe<Scalars['String']>;
  bodyText?: Maybe<Scalars['String']>;
  /** description of field description */
  description?: Maybe<Scalars['String']>;
  descriptionText?: Maybe<Scalars['String']>;
  /** description of field responsible */
  responsible?: Maybe<Scalars['ID']>;
  /** description of field source */
  source: Scalars['ID'];
  /** description of field kostraFunctions */
  kostraFunctions?: Maybe<Array<Scalars['ID']>>;
  /** description of field realms */
  realms?: Maybe<Array<Scalars['ID']>>;
  /** description of field links */
  links?: Maybe<Array<CreateHttpLinkInput>>;
  /** description of field validOrganizationTypes */
  validOrganizationTypes?: Maybe<Array<Scalars['ID']>>;
  /** description of field parameters */
  parameters?: Maybe<Array<CreateActionValueParameterValueInput>>;
  /** description of field type */
  type: ActionValueTypeEnum;
  /** description of field status */
  status: ContentStatus;
  /** description of field category */
  category: ActionValueCategoryEnum;
  /** description of field year */
  year: Scalars['Int'];
  /** description of field deadlines */
  deadlines?: Maybe<Array<CreateDeadlineInput>>;
  /** description of field reportDeadline */
  reportDeadline?: Maybe<Scalars['Date']>;
  /** description of field parent */
  parent?: Maybe<Scalars['ID']>;
  /** description of field talkTo */
  talkTo?: Maybe<Scalars['String']>;
  /** description of field sourceUrl */
  sourceUrl?: Maybe<Scalars['String']>;
};

export type CreateActionValueParameterValueInput = {
  /** description of field parameter */
  parameter: Scalars['Long'];
  /** description of field option */
  option: Scalars['Long'];
  /** description of field comment */
  comment?: Maybe<Scalars['String']>;
};

/** Input type for creating a new calendar */
export type CreateCalendarInput = {
  /** description of field title */
  title: Scalars['String'];
  /** description of field description */
  description: Scalars['String'];
  descriptionText: Scalars['String'];
  /** description of field color */
  color: Scalars['String'];
  /** description of field status */
  status: ContentStatus;
  /** description of field access */
  access: AccessInput;
  /** description of field kostraFunctions */
  kostraFunctions?: Maybe<Array<Scalars['ID']>>;
  /** description of field category */
  category: CalendarCategoryEnum;
  tags?: Maybe<Array<CreateTagInput>>;
};

/** Input type for creating a new annual cycle ring */
export type CreateCalendarRingInput = {
  /** description of field calendar */
  calendar: Scalars['ID'];
  /** description of field order */
  order: Scalars['Int'];
  /** description of field color */
  color?: Maybe<Scalars['String']>;
};

export type CreateCommentInput = {
  /** description of field meta */
  meta: Scalars['ID'];
  /** description of field parent */
  parent?: Maybe<Scalars['ID']>;
  /** description of field status */
  status: ContentStatus;
  /** description of field message */
  message: Scalars['String'];
  messageText: Scalars['String'];
};

export type CreateDeadlineInput = {
  /** description of field date */
  date: Scalars['Date'];
  /** description of field type */
  type?: Maybe<Scalars['String']>;
};

export type CreateDiscussionInput = {
  /** description of field title */
  title: Scalars['String'];
  /** description of field body */
  body: Scalars['String'];
  bodyText: Scalars['String'];
  /** description of field status */
  status: ContentStatus;
  /** description of field master */
  master?: Maybe<Scalars['ID']>;
};

/** Input type for creating a new task event */
export type CreateEventInput = {
  /** Specify taskId, or nothing if your creating a new task */
  task?: Maybe<Scalars['ID']>;
  /** description of field title */
  title: Scalars['String'];
  /** description of field description */
  description?: Maybe<Scalars['String']>;
  descriptionText?: Maybe<Scalars['String']>;
  /** description of field startDate */
  startDate?: Maybe<Scalars['Date']>;
  /** description of field endDate */
  endDate: Scalars['Date'];
  /** description of field endRepetitionDate */
  endRepetitionDate?: Maybe<Scalars['Date']>;
  /** description of field repetitionDefinition */
  repetitionDefinition?: Maybe<Scalars['String']>;
  /** description of field status */
  status: ContentStatus;
  /** description of field todos */
  todos?: Maybe<Array<Maybe<CreateTodoTemplateInput>>>;
  /** description of field reminderDate */
  reminderDate?: Maybe<Scalars['Date']>;
  /** description of field responsible */
  responsible?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<CreateTagInput>>;
};

export type CreateHttpLinkInput = {
  /** description of field label */
  label: Scalars['String'];
  /** description of field href */
  href: Scalars['String'];
};

export type CreateLinkInput = {
  label: Scalars['String'];
  href: Scalars['String'];
  relatedTo: Scalars['ID'];
};

export type CreateOrganizationGroupInput = {
  /** description of field title */
  title: Scalars['String'];
  /** description of field members */
  members: Array<Scalars['Long']>;
};

export type CreatePermissionGroupInput = {
  title: Scalars['String'];
  permissions: Array<Scalars['Int']>;
  members: Array<Scalars['Int']>;
  organizations: Array<Scalars['Int']>;
};

export type CreatePersonGroupInput = {
  /** description of field title */
  title: Scalars['String'];
  /** description of field members */
  members: Array<Scalars['String']>;
};

export type CreateTagInput = {
  title: Scalars['String'];
  color?: Maybe<Scalars['String']>;
};

/** Input type for creating a new calendar task */
export type CreateTaskInput = {
  /** description of field calendar */
  calendar: Scalars['ID'];
  /** description of field title */
  title: Scalars['String'];
  /** description of field description */
  description?: Maybe<Scalars['String']>;
  descriptionText?: Maybe<Scalars['String']>;
  /** description of field status */
  status: ContentStatus;
  /** description of field events */
  events?: Maybe<Array<Maybe<CreateEventInput>>>;
  /** description of field type */
  type: TaskTypeEnum;
};

/** Input type for creating a new todo instance */
export type CreateTodoInstanceInput = {
  /** description of field eventInstance */
  eventInstance: Scalars['ID'];
  /** description of field title */
  title: Scalars['String'];
  /** description of field description */
  description?: Maybe<Scalars['String']>;
  descriptionText?: Maybe<Scalars['String']>;
  /** description of field note */
  note?: Maybe<Scalars['String']>;
  /** description of field isDone */
  isDone: Scalars['Boolean'];
  /** description of field status */
  status: ContentStatus;
  /** description of field responsible */
  responsible?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
};

/** Input type for creating a new todo template */
export type CreateTodoTemplateInput = {
  /** description of field event */
  event?: Maybe<Scalars['ID']>;
  /** description of field title */
  title: Scalars['String'];
  /** description of field description */
  description?: Maybe<Scalars['String']>;
  descriptionText?: Maybe<Scalars['String']>;
  /** description of field status */
  status: ContentStatus;
  index: Scalars['Int'];
};

/** Input type for creating a new topic */
export type CreateTopicInput = {
  /** description of field title */
  title: Scalars['String'];
  /** description of field body */
  body: Scalars['String'];
  /** description of field description */
  description: Scalars['String'];
  descriptionText: Scalars['String'];
  /** description of field status */
  status: ContentStatus;
  /** description of field sector */
  sector?: Maybe<Scalars['ID']>;
  /** description of field category */
  category: TopicCategoryEnum;
  /** description of field links */
  links?: Maybe<Array<CreateHttpLinkInput>>;
  /** description of field parameters */
  parameters?: Maybe<Array<CreateActionValueParameterValueInput>>;
  /** description of field kostraFunctions */
  kostraFunctions?: Maybe<Array<Scalars['ID']>>;
  /** description of field access */
  access: AccessInput;
  tags?: Maybe<Array<CreateTagInput>>;
};

export type DeadlineType = {
  __typename?: 'DeadlineType';
  id: Scalars['ID'];
  date: Scalars['Date'];
  type: Scalars['String'];
};

/** A forum discussion */
export type Discussion = {
  __typename?: 'Discussion';
  /** id */
  id: Scalars['ID'];
  createdBy: Person;
  /** Date the discussion was posted */
  dateCreated: Scalars['Date'];
  /** Last update to the discussion */
  lastUpdated: Scalars['Date'];
  /** The title of the discussion */
  title: Scalars['String'];
  uri: Scalars['String'];
  /** Short description of the discussion */
  description?: Maybe<Scalars['String']>;
  descriptionText?: Maybe<Scalars['String']>;
  /** Publication status of the discussion */
  status: ContentStatus;
  /** The current revision of the discussion */
  currentRevision: ActionValueRevision;
  /** Replies to this discussion */
  comments: Array<Comment>;
  /** Related topics to this discussion */
  topics: Array<Topic>;
  /** Related action values to this discussions */
  actionValues: Array<ActionValue>;
  /** Other related discussions */
  discussions: Array<Discussion>;
  /** Files attached to the first post of this discussion */
  files: Array<UserFile>;
  /** Related calendars */
  calendars: Array<Calendar>;
  /** If you are following this discussion */
  isFollowing: Scalars['Boolean'];
  /** If you like this */
  isLiked: Scalars['Boolean'];
  /** description of field likes */
  likes: Scalars['Int'];
  /** People following this discussion */
  followers: Array<Person>;
  /** description of field views */
  views: Scalars['Int'];
  tags: Array<Tag>;
};

/** description of DiscussionSearch */
export type DiscussionSearch = {
  __typename?: 'DiscussionSearch';
  /** description of field id */
  id: Scalars['ID'];
  /** description of field title */
  title: Scalars['String'];
  /** description of field description */
  description?: Maybe<Scalars['String']>;
  /** description of field uri */
  uri: Scalars['String'];
  /** description of field answers */
  answers?: Maybe<Scalars['Int']>;
  createdBy: Person;
};

/** An event */
export type Event = {
  __typename?: 'Event';
  /** id */
  id: Scalars['ID'];
  title: Scalars['String'];
  /** description of field description */
  description?: Maybe<Scalars['String']>;
  descriptionText?: Maybe<Scalars['String']>;
  dateCreated: Scalars['Date'];
  lastUpdated: Scalars['Date'];
  createdBy: Person;
  startDate: Scalars['Date'];
  endDate?: Maybe<Scalars['Date']>;
  startRepetitionDate?: Maybe<Scalars['Date']>;
  endRepetitionDate?: Maybe<Scalars['Date']>;
  repetitionDefinition?: Maybe<Scalars['String']>;
  todoTemplate: Array<TodoTemplate>;
  instances: Array<EventInstance>;
  /** status */
  status: ContentStatus;
  /** description of field responsible */
  responsible?: Maybe<Person>;
  /** description of field assignedBy */
  assignedBy?: Maybe<Person>;
  /** Files attached to this event */
  files: Array<UserFile>;
  task: Task;
  reminderDate?: Maybe<Scalars['Date']>;
  tags: Array<Tag>;
  forkedFrom?: Maybe<Calendar>;
  forkMaster?: Maybe<Event>;
  forks: Array<Event>;
};

/** An event */
export type EventInstancesArgs = {
  year?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<Scalars['String']>>;
};

/** An instance of an event. This is the object we need to complete :-) */
export type EventInstance = {
  __typename?: 'EventInstance';
  /** id */
  id: Scalars['ID'];
  /** This is the title to show in task list! */
  title: Scalars['String'];
  /** description of field description */
  description?: Maybe<Scalars['String']>;
  descriptionText?: Maybe<Scalars['String']>;
  dateCreated: Scalars['Date'];
  lastUpdated: Scalars['Date'];
  createdBy: Person;
  startDate: Scalars['Date'];
  endDate?: Maybe<Scalars['Date']>;
  isDone: Scalars['Boolean'];
  isFavourite: Scalars['Boolean'];
  /** status */
  status: ContentStatus;
  /** list of smaller todo items */
  todos: Array<TodoInstanceType>;
  reminderDate?: Maybe<Scalars['Date']>;
  /** description of field responsible */
  responsible?: Maybe<Person>;
  /** description of field note */
  note?: Maybe<Scalars['String']>;
  noteText?: Maybe<Scalars['String']>;
  /** description of field assignedBy */
  assignedBy?: Maybe<Person>;
  /** Files attached to this event instance */
  files: Array<UserFile>;
  event: Event;
  tags: Array<Tag>;
  forkedFrom?: Maybe<Calendar>;
  forkMaster?: Maybe<EventInstance>;
  forks: Array<EventInstance>;
};

export type EventNoteSummary = {
  __typename?: 'EventNoteSummary';
  id: Scalars['ID'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  note: Scalars['String'];
  noteText: Scalars['String'];
};

export type EventNoteSummaryInput = {
  id: Scalars['ID'];
};

export type FollowTagInput = {
  title: Scalars['String'];
  color: Scalars['String'];
};

/** description of GroupAccess */
export type GroupAccess = {
  __typename?: 'GroupAccess';
  /** description of field group */
  group: PersonGroup;
  /** description of field access */
  access: AccessEnum;
};

export type HttpLink = {
  __typename?: 'HttpLink';
  label: Scalars['String'];
  href: Scalars['String'];
};

export type KostraFunction = {
  __typename?: 'KostraFunction';
  id: Scalars['ID'];
  title: Scalars['String'];
  kid: Scalars['Int'];
  group?: Maybe<KostraFunctionGroup>;
  description?: Maybe<Scalars['String']>;
};

export type KostraFunctionGroup = {
  __typename?: 'KostraFunctionGroup';
  id: Scalars['ID'];
  kid: Scalars['Int'];
  title: Scalars['String'];
  identifier: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  parent?: Maybe<KostraFunctionGroup>;
  /** description of field kostraFunctions */
  kostraFunctions: Array<KostraFunction>;
};

export type Launch = {
  __typename?: 'Launch';
  isInCart: Scalars['Boolean'];
};

export type Link = {
  __typename?: 'Link';
  id: Scalars['ID'];
  label: Scalars['String'];
  href: Scalars['String'];
  createdBy: Person;
  access: Access;
};

export type LoadCalendarState = {
  __typename?: 'LoadCalendarState';
  isFetching: Scalars['Boolean'];
  calendarId: Scalars['ID'];
  error?: Maybe<Scalars['String']>;
  lastFetched?: Maybe<Scalars['Date']>;
  lastFetchStarted?: Maybe<Scalars['Date']>;
  data?: Maybe<Calendar>;
};

export type Login = {
  __typename?: 'Login';
  id: Scalars['ID'];
  ipAddress: Scalars['String'];
  lastUsed: Scalars['Date'];
  browser?: Maybe<Scalars['String']>;
  deviceType?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
};

export type MakeSharableInput = {
  id: Scalars['ID'];
  uri?: Maybe<Scalars['String']>;
};

/** Your person object */
export type Me = {
  __typename?: 'Me';
  /** id */
  id: Scalars['ID'];
  /** Your name */
  name: Scalars['String'];
  /** Your users email */
  email: Scalars['String'];
  /** Your description */
  description?: Maybe<Scalars['String']>;
  /** Your job title */
  jobTitle?: Maybe<Scalars['String']>;
  /** Your department */
  department?: Maybe<Scalars['String']>;
  /** Your selected language */
  language?: Maybe<Scalars['String']>;
  /** Your secret calendar code */
  calendarCode: Scalars['String'];
  /** description of field picture */
  picture?: Maybe<Scalars['String']>;
  /** Your organization */
  organization?: Maybe<Organization>;
  /** Your selected KOSTRA functions */
  kostraFunctions: Array<KostraFunction>;
  /** What functionality you have permission to use */
  permissions: Array<Scalars['String']>;
  /** Your security level */
  userRoles: Array<Scalars['String']>;
  /** If you have admin privileges */
  isAdmin: Scalars['Boolean'];
  /** description of field settings */
  settings: UserSetting;
  /** description of field channel */
  channel: Scalars['String'];
  /** description of field phoneNumber */
  phoneNumber?: Maybe<Scalars['String']>;
  logins: Array<Login>;
  friends: Array<Person>;
  alternateEmails: Array<Scalars['String']>;
};

export type MergePersonsInput = {
  personToKeep: Scalars['ID'];
  personToMerge: Scalars['ID'];
};

/** description of type Mutations */
export type Mutations = {
  __typename?: 'Mutations';
  addEmailToPerson?: Maybe<Scalars['Boolean']>;
  addOrRemoveFromCart?: Maybe<Array<Maybe<Launch>>>;
  addTag: Tag;
  /** Add relation between a meta object and one or more meta objects */
  addToRelated: Array<QueryStatus>;
  archiveCalendar?: Maybe<Calendar>;
  changeOwner?: Maybe<Scalars['Boolean']>;
  /** Copy an existing calendar */
  copyCalendar?: Maybe<Scalars['String']>;
  createActionValue?: Maybe<ActionValue>;
  createApiKey: ApiKey;
  /** Create a new calendar */
  createCalendar?: Maybe<Calendar>;
  /** Create a new annual cycle ring */
  createCalendarRing?: Maybe<CalendarRing>;
  /** description of field createComment */
  createComment?: Maybe<Comment>;
  /** description of field createDiscussion */
  createDiscussion?: Maybe<Discussion>;
  /** Create a new task event */
  createEvent?: Maybe<Event>;
  createLink?: Maybe<Link>;
  createOrganizationGroup?: Maybe<OrganizationGroup>;
  createPermissionGroup: PermissionGroup;
  createPersonGroup?: Maybe<PersonGroup>;
  /** Create a new calendar task */
  createTask?: Maybe<Task>;
  /** Create a new event todo instance */
  createTodoInstance?: Maybe<TodoInstanceType>;
  /** Create a new event todo template */
  createTodoTemplate?: Maybe<TodoTemplate>;
  /** Create a new topic */
  createTopic?: Maybe<Topic>;
  /** Delete an existing annual cycle ring */
  deleteCalendarRing?: Maybe<Scalars['Boolean']>;
  /** Delete an existing file */
  deleteFile?: Maybe<Scalars['Boolean']>;
  deleteMeta?: Maybe<Scalars['Boolean']>;
  /** Follow meta object */
  follow?: Maybe<Scalars['Boolean']>;
  followTag: Tag;
  /** Fork an calendar */
  forkCalendar?: Maybe<Scalars['String']>;
  /** Like meta object */
  like?: Maybe<Scalars['Boolean']>;
  loadRingData?: Maybe<LoadCalendarState>;
  makeSharable?: Maybe<Scalars['String']>;
  mergePersons?: Maybe<Scalars['Boolean']>;
  /** Remove relation between meta objects */
  removeFromRelated: Array<QueryStatus>;
  removeRingData?: Maybe<Scalars['Boolean']>;
  removeSharable?: Maybe<Scalars['Boolean']>;
  removeTag: Scalars['Boolean'];
  revokeApiKey: Scalars['Boolean'];
  revokeRefreshToken: Scalars['Boolean'];
  /** description of field setStatus */
  setStatus?: Maybe<Scalars['Boolean']>;
  /** Share meta object */
  share?: Maybe<Scalars['Boolean']>;
  unArchiveCalendar?: Maybe<Calendar>;
  /** Un-follow meta object */
  unFollow?: Maybe<Scalars['Boolean']>;
  unFollowTag: Tag;
  /** Unlike meta object */
  unLike?: Maybe<Scalars['Boolean']>;
  updateActionValue?: Maybe<ActionValue>;
  /** Update an existing calendar */
  updateCalendar?: Maybe<Calendar>;
  /** description of field updateComment */
  updateComment?: Maybe<Comment>;
  /** description of field updateDiscussion */
  updateDiscussion?: Maybe<Discussion>;
  /** Update an existing event */
  updateEvent?: Maybe<Event>;
  /** Update an existing event instance */
  updateEventInstance?: Maybe<EventInstance>;
  /** Update file info */
  updateFileInfo?: Maybe<UserFile>;
  updateLink?: Maybe<Link>;
  /** Update personal information */
  updateMe?: Maybe<Me>;
  updateOrganization: OrganizationAdmin;
  updateOrganizationGroup?: Maybe<OrganizationGroup>;
  updatePerson: PersonAdmin;
  updatePersonGroup?: Maybe<PersonGroup>;
  /** Update your settings */
  updateSettings?: Maybe<Me>;
  /** Update an existing task */
  updateTask?: Maybe<Task>;
  /** Update an existing todo instance */
  updateTodoInstance?: Maybe<TodoInstanceType>;
  /** Update an existing todo template */
  updateTodoTemplate?: Maybe<TodoTemplate>;
  /** Update a topic */
  updateTopic?: Maybe<Topic>;
};

/** description of type Mutations */
export type MutationsAddEmailToPersonArgs = {
  input: AddEmailToPersonInput;
};

/** description of type Mutations */
export type MutationsAddOrRemoveFromCartArgs = {
  id: Scalars['ID'];
};

/** description of type Mutations */
export type MutationsAddTagArgs = {
  input: AddTagInput;
};

/** description of type Mutations */
export type MutationsAddToRelatedArgs = {
  input: AddToRelatedInput;
};

/** description of type Mutations */
export type MutationsArchiveCalendarArgs = {
  id: Scalars['ID'];
};

/** description of type Mutations */
export type MutationsChangeOwnerArgs = {
  input: ChangeOwnerInput;
};

/** description of type Mutations */
export type MutationsCopyCalendarArgs = {
  input: CopyCalendarInput;
};

/** description of type Mutations */
export type MutationsCreateActionValueArgs = {
  input: CreateActionValueInput;
};

/** description of type Mutations */
export type MutationsCreateApiKeyArgs = {
  description: Scalars['String'];
};

/** description of type Mutations */
export type MutationsCreateCalendarArgs = {
  input: CreateCalendarInput;
};

/** description of type Mutations */
export type MutationsCreateCalendarRingArgs = {
  input: CreateCalendarRingInput;
};

/** description of type Mutations */
export type MutationsCreateCommentArgs = {
  input: CreateCommentInput;
};

/** description of type Mutations */
export type MutationsCreateDiscussionArgs = {
  input: CreateDiscussionInput;
};

/** description of type Mutations */
export type MutationsCreateEventArgs = {
  input: CreateEventInput;
};

/** description of type Mutations */
export type MutationsCreateLinkArgs = {
  input: CreateLinkInput;
};

/** description of type Mutations */
export type MutationsCreateOrganizationGroupArgs = {
  input: CreateOrganizationGroupInput;
};

/** description of type Mutations */
export type MutationsCreatePermissionGroupArgs = {
  input: CreatePermissionGroupInput;
};

/** description of type Mutations */
export type MutationsCreatePersonGroupArgs = {
  input: CreatePersonGroupInput;
};

/** description of type Mutations */
export type MutationsCreateTaskArgs = {
  input: CreateTaskInput;
};

/** description of type Mutations */
export type MutationsCreateTodoInstanceArgs = {
  input: CreateTodoInstanceInput;
};

/** description of type Mutations */
export type MutationsCreateTodoTemplateArgs = {
  input: CreateTodoTemplateInput;
};

/** description of type Mutations */
export type MutationsCreateTopicArgs = {
  input: CreateTopicInput;
};

/** description of type Mutations */
export type MutationsDeleteCalendarRingArgs = {
  id: Scalars['ID'];
};

/** description of type Mutations */
export type MutationsDeleteFileArgs = {
  id: Scalars['ID'];
};

/** description of type Mutations */
export type MutationsDeleteMetaArgs = {
  id: Scalars['ID'];
};

/** description of type Mutations */
export type MutationsFollowArgs = {
  id: Scalars['ID'];
};

/** description of type Mutations */
export type MutationsFollowTagArgs = {
  input: FollowTagInput;
};

/** description of type Mutations */
export type MutationsForkCalendarArgs = {
  input: CopyCalendarInput;
};

/** description of type Mutations */
export type MutationsLikeArgs = {
  id: Scalars['ID'];
};

/** description of type Mutations */
export type MutationsLoadRingDataArgs = {
  calendarIds: Array<Scalars['ID']>;
};

/** description of type Mutations */
export type MutationsMakeSharableArgs = {
  input: MakeSharableInput;
};

/** description of type Mutations */
export type MutationsMergePersonsArgs = {
  input: MergePersonsInput;
};

/** description of type Mutations */
export type MutationsRemoveFromRelatedArgs = {
  input: RemoveFromRelatedInput;
};

/** description of type Mutations */
export type MutationsRemoveRingDataArgs = {
  calendarId: Scalars['ID'];
};

/** description of type Mutations */
export type MutationsRemoveSharableArgs = {
  id: Scalars['ID'];
};

/** description of type Mutations */
export type MutationsRemoveTagArgs = {
  input: RemoveTagInput;
};

/** description of type Mutations */
export type MutationsRevokeApiKeyArgs = {
  id: Scalars['ID'];
};

/** description of type Mutations */
export type MutationsRevokeRefreshTokenArgs = {
  id: Scalars['ID'];
};

/** description of type Mutations */
export type MutationsSetStatusArgs = {
  input: SetStatusInput;
};

/** description of type Mutations */
export type MutationsShareArgs = {
  input: ShareInput;
};

/** description of type Mutations */
export type MutationsUnArchiveCalendarArgs = {
  id: Scalars['ID'];
};

/** description of type Mutations */
export type MutationsUnFollowArgs = {
  id: Scalars['ID'];
};

/** description of type Mutations */
export type MutationsUnFollowTagArgs = {
  title: Scalars['String'];
};

/** description of type Mutations */
export type MutationsUnLikeArgs = {
  id: Scalars['ID'];
};

/** description of type Mutations */
export type MutationsUpdateActionValueArgs = {
  input: UpdateActionValueInput;
};

/** description of type Mutations */
export type MutationsUpdateCalendarArgs = {
  input: UpdateCalendarInput;
};

/** description of type Mutations */
export type MutationsUpdateCommentArgs = {
  input: UpdateCommentInput;
};

/** description of type Mutations */
export type MutationsUpdateDiscussionArgs = {
  input: UpdateDiscussionInput;
};

/** description of type Mutations */
export type MutationsUpdateEventArgs = {
  input: UpdateEventInput;
};

/** description of type Mutations */
export type MutationsUpdateEventInstanceArgs = {
  input: UpdateEventInstanceInput;
};

/** description of type Mutations */
export type MutationsUpdateFileInfoArgs = {
  input: UpdateFileInfoInput;
};

/** description of type Mutations */
export type MutationsUpdateLinkArgs = {
  input: UpdateLinkInput;
};

/** description of type Mutations */
export type MutationsUpdateMeArgs = {
  input: UpdateMeInput;
};

/** description of type Mutations */
export type MutationsUpdateOrganizationArgs = {
  input: AdminUpdateOrganization;
};

/** description of type Mutations */
export type MutationsUpdateOrganizationGroupArgs = {
  input: UpdateOrganizationGroupInput;
};

/** description of type Mutations */
export type MutationsUpdatePersonArgs = {
  input?: Maybe<UpdatePersonsInput>;
};

/** description of type Mutations */
export type MutationsUpdatePersonGroupArgs = {
  input: UpdatePersonGroupInput;
};

/** description of type Mutations */
export type MutationsUpdateSettingsArgs = {
  input: UpdateMeSettingsInput;
};

/** description of type Mutations */
export type MutationsUpdateTaskArgs = {
  input: UpdateTaskInput;
};

/** description of type Mutations */
export type MutationsUpdateTodoInstanceArgs = {
  input: UpdateTodoInstanceInput;
};

/** description of type Mutations */
export type MutationsUpdateTodoTemplateArgs = {
  input: UpdateTodoTemplateInput;
};

/** description of type Mutations */
export type MutationsUpdateTopicArgs = {
  input: UpdateTopicInput;
};

export type MyCalendarsInput = {
  /** description of field sort */
  sort?: Maybe<SortEnum>;
  /** description of field order */
  order?: Maybe<OrderEnum>;
  /** description of field limit */
  limit?: Maybe<Scalars['Int']>;
  /** description of field offset */
  offset?: Maybe<Scalars['Int']>;
};

export type MyFollowed = {
  __typename?: 'MyFollowed';
  actionValues: Array<ActionValue>;
  discussions: Array<Discussion>;
  topics: Array<Topic>;
  calendars: Array<Calendar>;
  tags: Array<Tag>;
};

export type MyFollowedInput = {
  excludeWrite?: Maybe<Scalars['Boolean']>;
};

/** OrderEnum */
export enum OrderEnum {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}

/** An organization */
export type Organization = {
  __typename?: 'Organization';
  /** id */
  id: Scalars['ID'];
  /** The organizations name */
  name: Scalars['String'];
  /** The organizations image */
  organizationImage?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  members: Array<Person>;
};

/** description of OrganizationAccess */
export type OrganizationAccess = {
  __typename?: 'OrganizationAccess';
  /** description of field organization */
  organization: Organization;
  /** description of field access */
  access: AccessEnum;
};

export type OrganizationAccessInput = {
  /** description of field organization */
  organization: Scalars['Long'];
  /** description of field access */
  access: AccessEnum;
};

export type OrganizationAdmin = {
  __typename?: 'OrganizationAdmin';
  /** id */
  id: Scalars['ID'];
  /** The organizations name */
  title: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  dateCreated: Scalars['Date'];
  lastUpdated: Scalars['Date'];
  organizationNumber: Scalars['String'];
  imageFormat?: Maybe<Scalars['String']>;
  displayTitle?: Maybe<Scalars['String']>;
};

/** description of OrganizationGroup */
export type OrganizationGroup = {
  __typename?: 'OrganizationGroup';
  /** description of field id */
  id: Scalars['ID'];
  /** description of field title */
  title: Scalars['String'];
  /** description of field createdBy */
  createdBy: Person;
  /** description of field dateCreated */
  dateCreated: Scalars['Date'];
  /** description of field lastUpdated */
  lastUpdated: Scalars['Date'];
  /** description of field members */
  members: Array<Organization>;
  topics: Array<Topic>;
  actionValues: Array<ActionValue>;
  discussions: Array<Discussion>;
  files: Array<UserFile>;
  calendars: Array<Calendar>;
};

/** description of OrganizationGroupAccess */
export type OrganizationGroupAccess = {
  __typename?: 'OrganizationGroupAccess';
  /** description of field organizationGroup */
  organizationGroup: OrganizationGroup;
  /** description of field access */
  access: AccessEnum;
};

export type OrganizationGroupAccessInput = {
  /** description of field organizationGroup */
  organizationGroup: Scalars['Long'];
  /** description of field access */
  access: AccessEnum;
};

/** description of PageInfo */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** description of field hasNextPage */
  hasNextPage: Scalars['Boolean'];
  /** description of field hasPreviousPage */
  hasPreviousPage: Scalars['Boolean'];
  /** description of field pageNumber */
  pageNumber: Scalars['Int'];
  /** description of field numberOfPages */
  numberOfPages: Scalars['Int'];
  /** description of field itemsPerPage */
  itemsPerPage: Scalars['Int'];
};

export type Permission = {
  __typename?: 'Permission';
  id: Scalars['ID'];
  function: Scalars['String'];
};

export type PermissionGroup = {
  __typename?: 'PermissionGroup';
  id: Scalars['ID'];
  title: Scalars['String'];
  permissions: Array<Scalars['Int']>;
  members: Array<Scalars['Int']>;
  organizations: Array<Scalars['Int']>;
};

/** A person */
export type Person = {
  __typename?: 'Person';
  /** id */
  id: Scalars['ID'];
  /** The persons name */
  name: Scalars['String'];
  /** The persons self defined role */
  role?: Maybe<Scalars['String']>;
  /** The persons department */
  department?: Maybe<Scalars['String']>;
  /** description of field picture */
  picture?: Maybe<Scalars['String']>;
  /** The organization the person belongs to */
  organization?: Maybe<Organization>;
  kostraFunctions?: Maybe<Array<KostraFunction>>;
};

/** description of PersonAccess */
export type PersonAccess = {
  __typename?: 'PersonAccess';
  /** description of field person */
  person: Person;
  /** description of field access */
  access: AccessEnum;
};

export type PersonAccessInput = {
  /** description of field person */
  person: Scalars['ID'];
  /** description of field access */
  access: AccessEnum;
};

export type PersonAdmin = {
  __typename?: 'PersonAdmin';
  id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  status: Scalars['String'];
  dateCreated: Scalars['Date'];
  lastUpdated: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['Int']>;
  department?: Maybe<Scalars['String']>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  testUser: Scalars['Boolean'];
  tooManyAttempts: Scalars['Boolean'];
};

/** description of PersonGroup */
export type PersonGroup = {
  __typename?: 'PersonGroup';
  /** description of field id */
  id: Scalars['ID'];
  /** description of field title */
  title: Scalars['String'];
  /** description of field createdBy */
  createdBy: Person;
  /** description of field dateCreated */
  dateCreated: Scalars['Date'];
  /** description of field lastUpdated */
  lastUpdated: Scalars['Date'];
  /** description of field members */
  members: Array<Person>;
  topics: Array<Topic>;
  actionValues: Array<ActionValue>;
  discussions: Array<Discussion>;
  files: Array<UserFile>;
  calendars: Array<Calendar>;
};

export type PersonGroupAccessInput = {
  /** description of field group */
  group: Scalars['Long'];
  /** description of field access */
  access: AccessEnum;
};

/** description of type Queries */
export type Queries = {
  __typename?: 'Queries';
  actionValue?: Maybe<ActionValue>;
  /** List of all available action values */
  allActionValues: AllActionValues;
  /** List of all available calendars */
  allCalendars: AllCalendars;
  /** List of all available discussions */
  allDiscussions: AllDiscussions;
  allKostraFunctionGroups: Array<KostraFunctionGroup>;
  allKostraFunctions: Array<KostraFunction>;
  allOrganizationGroups: Array<OrganizationGroup>;
  allOrganizations: AllOrganizations;
  allPermissionGroups: Array<PermissionGroup>;
  allPermissions: Array<Permission>;
  allPersonGroups: Array<PersonGroup>;
  allPersons: AllPersons;
  allRealms: Array<Realm>;
  allSectors: Array<Sector>;
  /** List of all available topics */
  allTopics: AllTopics;
  apiKeys: Array<ApiKey>;
  calendar?: Maybe<Calendar>;
  cartItems: Array<Maybe<Launch>>;
  checkCalendarCopyReady?: Maybe<Scalars['String']>;
  discussion?: Maybe<Discussion>;
  event: Event;
  eventInstance?: Maybe<EventInstance>;
  eventNoteSummary: Array<EventNoteSummary>;
  fileInfo: UserFile;
  /** description of field findOrganizations */
  findOrganizations: Array<Organization>;
  findPersonsByNameOrEmail: Array<Person>;
  incompleteTasksAssignedByMe: Array<EventInstance>;
  isLoggedIn: Scalars['Boolean'];
  me: Me;
  /** description of field myAssignedTasks */
  myAssignedTasks: CalendarRing;
  /** List of your calendars */
  myCalendars: Array<Calendar>;
  myFollowed: MyFollowed;
  myUnfinishedTasks: Array<EventInstance>;
  organization?: Maybe<Organization>;
  organizationById: OrganizationAdmin;
  organizationByIds: Array<OrganizationAdmin>;
  person?: Maybe<Person>;
  personById: PersonAdmin;
  search: Search;
  task?: Maybe<Task>;
  topic?: Maybe<Topic>;
  upcomingDeadlines: Array<EventInstance>;
  upcomingReminders: Array<Reminder>;
  /** Get annual cycle for person */
  yearWheel: Array<CalendarRing>;
  yearWheelData?: Maybe<Array<Maybe<Calendar>>>;
  yearWheelDataStatus?: Maybe<Array<Maybe<LoadCalendarState>>>;
};

/** description of type Queries */
export type QueriesActionValueArgs = {
  id: Scalars['ID'];
};

/** description of type Queries */
export type QueriesAllActionValuesArgs = {
  input: AllActionValuesInput;
};

/** description of type Queries */
export type QueriesAllCalendarsArgs = {
  input: AllCalendarsInput;
};

/** description of type Queries */
export type QueriesAllDiscussionsArgs = {
  input: AllDiscussionsInput;
};

/** description of type Queries */
export type QueriesAllOrganizationsArgs = {
  input: AllOrganizationsInput;
};

/** description of type Queries */
export type QueriesAllPersonsArgs = {
  input: AllOrganizationsInput;
};

/** description of type Queries */
export type QueriesAllTopicsArgs = {
  input: AllTopicsInput;
};

/** description of type Queries */
export type QueriesCalendarArgs = {
  id: Scalars['ID'];
};

/** description of type Queries */
export type QueriesCheckCalendarCopyReadyArgs = {
  id: Scalars['ID'];
};

/** description of type Queries */
export type QueriesDiscussionArgs = {
  id: Scalars['ID'];
};

/** description of type Queries */
export type QueriesEventArgs = {
  id: Scalars['ID'];
};

/** description of type Queries */
export type QueriesEventInstanceArgs = {
  id: Scalars['ID'];
};

/** description of type Queries */
export type QueriesEventNoteSummaryArgs = {
  input?: Maybe<EventNoteSummaryInput>;
};

/** description of type Queries */
export type QueriesFileInfoArgs = {
  id: Scalars['ID'];
};

/** description of type Queries */
export type QueriesFindOrganizationsArgs = {
  query: Scalars['String'];
};

/** description of type Queries */
export type QueriesFindPersonsByNameOrEmailArgs = {
  query: Scalars['String'];
};

/** description of type Queries */
export type QueriesMyAssignedTasksArgs = {
  year?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
};

/** description of type Queries */
export type QueriesMyCalendarsArgs = {
  input: MyCalendarsInput;
};

/** description of type Queries */
export type QueriesMyFollowedArgs = {
  input?: Maybe<MyFollowedInput>;
};

/** description of type Queries */
export type QueriesOrganizationArgs = {
  id: Scalars['ID'];
};

/** description of type Queries */
export type QueriesOrganizationByIdArgs = {
  id: Scalars['ID'];
};

/** description of type Queries */
export type QueriesOrganizationByIdsArgs = {
  ids: Array<Scalars['ID']>;
};

/** description of type Queries */
export type QueriesPersonArgs = {
  id: Scalars['ID'];
};

/** description of type Queries */
export type QueriesPersonByIdArgs = {
  id: Scalars['ID'];
};

/** description of type Queries */
export type QueriesSearchArgs = {
  query: Scalars['String'];
  onlyWrite?: Maybe<Scalars['Boolean']>;
};

/** description of type Queries */
export type QueriesTaskArgs = {
  id: Scalars['ID'];
};

/** description of type Queries */
export type QueriesTopicArgs = {
  id: Scalars['ID'];
};

/** description of type Queries */
export type QueriesYearWheelArgs = {
  person: Scalars['ID'];
};

/** description of type Queries */
export type QueriesYearWheelDataArgs = {
  calendarIds: Array<Scalars['ID']>;
};

/** description of QueryStatusA */
export type QueryStatus = {
  __typename?: 'QueryStatus';
  /** description of field id */
  id: Scalars['ID'];
  /** description of field success */
  success: Scalars['Boolean'];
};

export type RadarGraphType = {
  __typename?: 'RadarGraphType';
  name: Scalars['String'];
  sum: Scalars['Int'];
  type: Scalars['Int'];
};

export type Realm = {
  __typename?: 'Realm';
  id: Scalars['ID'];
  title: Scalars['String'];
  i18nCode: Scalars['String'];
  iso3166: Scalars['String'];
  localId: Scalars['String'];
  parent?: Maybe<Scalars['ID']>;
  type: Scalars['String'];
};

export type Reminder = {
  __typename?: 'Reminder';
  id: Scalars['ID'];
  date: Scalars['Date'];
  reminderDate: Scalars['Date'];
  title: Scalars['String'];
  uri: Scalars['String'];
};

export type RemoveFromRelatedInput = {
  /** description of field meta */
  meta: Scalars['Long'];
  /** description of field metaToDelete */
  metasToDelete: Array<Scalars['Long']>;
};

export type RemoveTagInput = {
  title: Scalars['String'];
  meta: Scalars['ID'];
};

/** Search result */
export type Search = {
  __typename?: 'Search';
  persons: Array<Person>;
  groups: Array<PersonGroup>;
  organizations: Array<Organization>;
  organizationGroups: Array<OrganizationGroup>;
  actionValues: Array<ActionValueSearch>;
  discussions: Array<DiscussionSearch>;
  topics: Array<TopicSearch>;
  calendars: Array<CalendarSearch>;
  tags: Array<Tag>;
};

export type Sector = {
  __typename?: 'Sector';
  id: Scalars['ID'];
  title: Scalars['String'];
  kostraFunctions: Array<KostraFunction>;
};

export type SetStatusInput = {
  /** description of field id */
  id: Scalars['ID'];
  /** description of field status */
  status: ContentStatus;
};

export type ShareInput = {
  /** description of field id */
  id: Scalars['ID'];
  /** description of field email */
  email: Scalars['String'];
  /** description of field description */
  description?: Maybe<Scalars['String']>;
};

/** SortEnum */
export enum SortEnum {
  TITLE = 'TITLE',
  LAST_UPDATED = 'LAST_UPDATED',
  DEADLINE = 'DEADLINE',
}

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID'];
  title: Scalars['String'];
  color?: Maybe<Scalars['String']>;
};

/** A task */
export type Task = {
  __typename?: 'Task';
  /** id */
  id: Scalars['ID'];
  title: Scalars['String'];
  /** description of field description */
  description?: Maybe<Scalars['String']>;
  descriptionText?: Maybe<Scalars['String']>;
  dateCreated: Scalars['Date'];
  lastUpdated: Scalars['Date'];
  parent?: Maybe<Task>;
  /** Events in this task */
  events: Array<Event>;
  /** status */
  status: ContentStatus;
  calendars: Array<Calendar>;
  /** description of field type */
  type: TaskTypeEnum;
  tags: Array<Tag>;
  forkedFrom?: Maybe<Calendar>;
  forkMaster?: Maybe<Task>;
};

/** A task */
export type TaskEventsArgs = {
  year?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
  tags?: Maybe<Array<Scalars['String']>>;
};

/** TaskTypeEnum */
export enum TaskTypeEnum {
  DEFAULT = 'DEFAULT',
  SINGLE = 'SINGLE',
}

export type TodoInstanceType = {
  __typename?: 'TodoInstanceType';
  /** id */
  id: Scalars['ID'];
  title: Scalars['String'];
  dateCreated: Scalars['Date'];
  lastUpdated: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  descriptionText?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  isDone: Scalars['Boolean'];
  /** status */
  status: ContentStatus;
  /** description of field responsible */
  responsible?: Maybe<Person>;
  /** if the TodoInstance is generated from a TodoTemplate on the event */
  fromTemplate: Scalars['Boolean'];
  parent?: Maybe<Scalars['ID']>;
  index: Scalars['Int'];
};

export type TodoTemplate = {
  __typename?: 'TodoTemplate';
  /** id */
  id: Scalars['ID'];
  title: Scalars['String'];
  dateCreated: Scalars['Date'];
  lastUpdated: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  descriptionText?: Maybe<Scalars['String']>;
  /** status */
  status: ContentStatus;
  index: Scalars['Int'];
};

/** A topic */
export type Topic = {
  __typename?: 'Topic';
  /** id */
  id: Scalars['ID'];
  /** The URI of this topic */
  uri: Scalars['String'];
  /** Date the topic was created */
  dateCreated: Scalars['Date'];
  /** Date the topic was last updated */
  lastUpdated: Scalars['Date'];
  /** Title of the topic */
  title: Scalars['String'];
  /** Short description of the topic */
  description?: Maybe<Scalars['String']>;
  descriptionText?: Maybe<Scalars['String']>;
  /** Publication status of the topic */
  status: ContentStatus;
  createdBy?: Maybe<Person>;
  /** The current revision of this topic */
  currentRevision?: Maybe<TopicRevision>;
  /** Related topics */
  topics?: Maybe<Array<Topic>>;
  /** Related action values */
  actionValues?: Maybe<Array<ActionValue>>;
  /** Related discussions */
  discussions?: Maybe<Array<Discussion>>;
  /** Related files */
  files?: Maybe<Array<UserFile>>;
  /** Related calendars */
  calendars?: Maybe<Array<Calendar>>;
  /** If you are following this topic */
  isFollowing: Scalars['Boolean'];
  /** If you like this */
  isLiked: Scalars['Boolean'];
  /** description of field likes */
  likes: Scalars['Int'];
  /** People following this topic */
  followers?: Maybe<Array<Person>>;
  /** If true, this topic has access restrictions */
  isRestricted: Scalars['Boolean'];
  /** If true, your user dont have access to this topic */
  isLimited: Scalars['Boolean'];
  /** description of field views */
  views: Scalars['Int'];
  /** description of field access */
  access: Access;
  links: Array<Link>;
  tags: Array<Tag>;
};

/** TopicCategoryEnum */
export enum TopicCategoryEnum {
  TOPIC = 'TOPIC',
  SUBJECT_GROUP = 'SUBJECT_GROUP',
  PROJECT = 'PROJECT',
}

/** TopicCategorySortEnum */
export enum TopicCategorySortEnum {
  ALL = 'ALL',
  TOPIC = 'TOPIC',
  SUBJECT_GROUP = 'SUBJECT_GROUP',
  PROJECT = 'PROJECT',
}

/** A revision of an topic */
export type TopicRevision = {
  __typename?: 'TopicRevision';
  /** id */
  id: Scalars['ID'];
  /** Creation date of the revision */
  dateCreated: Scalars['Date'];
  /** Last update to this revision */
  lastUpdated: Scalars['Date'];
  /** The title of the topic at the time of this revision */
  title: Scalars['String'];
  /** Short description of the topic */
  description?: Maybe<Scalars['String']>;
  descriptionText?: Maybe<Scalars['String']>;
  /** Long description of the topic */
  body?: Maybe<Scalars['String']>;
  bodyText?: Maybe<Scalars['String']>;
  category: TopicCategoryEnum;
  /** description of field sector */
  sector?: Maybe<Sector>;
  links: Array<HttpLink>;
  /** description of field kostraFunctions */
  kostraFunctions?: Maybe<Array<KostraFunction>>;
};

/** description of TopicSearch */
export type TopicSearch = {
  __typename?: 'TopicSearch';
  /** description of field id */
  id: Scalars['ID'];
  /** description of field title */
  title: Scalars['String'];
  /** description of field description */
  description?: Maybe<Scalars['String']>;
  /** description of field uri */
  uri: Scalars['String'];
  createdBy: Person;
};

export type UpdateActionValueInput = {
  /** description of field id */
  id: Scalars['ID'];
  /** description of field title */
  title: Scalars['String'];
  /** description of field body */
  body?: Maybe<Scalars['String']>;
  bodyText?: Maybe<Scalars['String']>;
  /** description of field description */
  description?: Maybe<Scalars['String']>;
  descriptionText?: Maybe<Scalars['String']>;
  /** description of field responsible */
  responsible?: Maybe<Scalars['ID']>;
  /** description of field source */
  source: Scalars['ID'];
  /** description of field kostraFunctions */
  kostraFunctions?: Maybe<Array<Scalars['ID']>>;
  /** description of field realms */
  realms?: Maybe<Array<Scalars['ID']>>;
  /** description of field links */
  links?: Maybe<Array<CreateHttpLinkInput>>;
  /** description of field parameters */
  parameters?: Maybe<Array<CreateActionValueParameterValueInput>>;
  /** description of field validOrganizationTypes */
  validOrganizationTypes?: Maybe<Array<Scalars['ID']>>;
  /** description of field type */
  type: ActionValueTypeEnum;
  /** description of field status */
  status: ContentStatus;
  /** description of field category */
  category: ActionValueCategoryEnum;
  /** description of field year */
  year: Scalars['Int'];
  /** description of field deadlines */
  deadlines?: Maybe<Array<CreateDeadlineInput>>;
  /** description of field reportDeadline */
  reportDeadline?: Maybe<Scalars['Date']>;
  /** description of field parent */
  parent?: Maybe<Scalars['ID']>;
  /** description of field talkTo */
  talkTo?: Maybe<Scalars['String']>;
  /** description of field sourceUrl */
  sourceUrl?: Maybe<Scalars['String']>;
};

/** Input type for updating a calendar */
export type UpdateCalendarInput = {
  /** description of field id */
  id: Scalars['ID'];
  /** description of field title */
  title: Scalars['String'];
  /** description of field description */
  description: Scalars['String'];
  descriptionText: Scalars['String'];
  /** description of field color */
  color: Scalars['String'];
  /** description of field status */
  status: ContentStatus;
  /** description of field access */
  access: AccessInput;
  /** description of field kostraFunctions */
  kostraFunctions?: Maybe<Array<Scalars['ID']>>;
  /** description of field category */
  category: CalendarCategoryEnum;
  tags?: Maybe<Array<CreateTagInput>>;
};

export type UpdateCommentInput = {
  /** description of field id */
  id: Scalars['ID'];
  /** description of field meta */
  meta: Scalars['ID'];
  /** description of field parent */
  parent?: Maybe<Scalars['ID']>;
  /** description of field status */
  status: ContentStatus;
  /** description of field message */
  message?: Maybe<Scalars['String']>;
  messageText?: Maybe<Scalars['String']>;
};

export type UpdateDiscussionInput = {
  /** description of field id */
  id: Scalars['ID'];
  /** description of field title */
  title: Scalars['String'];
  /** description of field body */
  body: Scalars['String'];
  bodyText: Scalars['String'];
  /** description of field status */
  status: ContentStatus;
};

/** Input type for updating an existing task event */
export type UpdateEventInput = {
  /** description of field id */
  id: Scalars['ID'];
  /** description of field title */
  title: Scalars['String'];
  /** description of field description */
  description?: Maybe<Scalars['String']>;
  descriptionText?: Maybe<Scalars['String']>;
  /** description of field startDate */
  startDate?: Maybe<Scalars['Date']>;
  /** description of field endDate */
  endDate: Scalars['Date'];
  /** description of field endRepetitionDate */
  endRepetitionDate?: Maybe<Scalars['Date']>;
  /** description of field repetitionDefinition */
  repetitionDefinition?: Maybe<Scalars['String']>;
  /** description of field status */
  status: ContentStatus;
  /** description of field reminderDate */
  reminderDate?: Maybe<Scalars['Date']>;
  /** description of field responsible */
  responsible?: Maybe<Scalars['String']>;
  todos?: Maybe<Array<UpdateTodoTemplateInput>>;
  tags?: Maybe<Array<CreateTagInput>>;
};

/** Input type for updating an existing task event instance */
export type UpdateEventInstanceInput = {
  /** description of field id */
  id: Scalars['ID'];
  /** description of field title */
  title: Scalars['String'];
  /** description of field description */
  description?: Maybe<Scalars['String']>;
  descriptionText?: Maybe<Scalars['String']>;
  /** description of field startDate */
  startDate?: Maybe<Scalars['Date']>;
  /** description of field endDate */
  endDate: Scalars['Date'];
  /** description of field status */
  status: ContentStatus;
  /** description of field isDone */
  isDone: Scalars['Boolean'];
  /** description of field isFavourite */
  isFavourite: Scalars['Boolean'];
  /** description of field reminderDate */
  reminderDate?: Maybe<Scalars['Date']>;
  /** description of field responsible */
  responsible?: Maybe<Scalars['String']>;
  /** description of field note */
  note?: Maybe<Scalars['String']>;
  noteText?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<CreateTagInput>>;
};

/** Update type for updating file info */
export type UpdateFileInfoInput = {
  /** description of field id */
  id: Scalars['ID'];
  /** description of field description */
  description: Scalars['String'];
  descriptionText: Scalars['String'];
};

export type UpdateLinkInput = {
  id: Scalars['ID'];
  label: Scalars['String'];
  href: Scalars['String'];
};

export type UpdateMeInput = {
  /** description of field name */
  name?: Maybe<Scalars['String']>;
  /** description of field description */
  description?: Maybe<Scalars['String']>;
  descriptionText?: Maybe<Scalars['String']>;
  /** description of field jobTitle */
  jobTitle?: Maybe<Scalars['String']>;
  /** description of field department */
  department?: Maybe<Scalars['String']>;
  /** description of field oldPassword */
  oldPassword?: Maybe<Scalars['String']>;
  /** description of field newPassword1 */
  newPassword1?: Maybe<Scalars['String']>;
  /** description of field newPassword2 */
  newPassword2?: Maybe<Scalars['String']>;
  /** description of field language */
  language?: Maybe<Scalars['String']>;
  /** description of field kostraFunctions */
  kostraFunctions?: Maybe<Array<Scalars['Long']>>;
  /** description of field phoneNumber */
  phoneNumber?: Maybe<Scalars['String']>;
};

export type UpdateMeSettingsInput = {
  /** description of field showAssignedTasks */
  showAssignedTasks?: Maybe<Scalars['Boolean']>;
  /** description of field sendInstantNotificationMail */
  sendInstantNotificationMail?: Maybe<Scalars['Boolean']>;
  /** description of field sendDailyNotificationMail */
  sendDailyNotificationMail?: Maybe<Scalars['Boolean']>;
  /** description of field sendWeeklyNotificationMail */
  sendWeeklyNotificationMail?: Maybe<Scalars['Boolean']>;
  /** description of field acceptedUserTerms */
  acceptedUserTerms?: Maybe<Scalars['Boolean']>;
  /** description of field allowContactViaPhone */
  allowContactViaPhone?: Maybe<Scalars['Boolean']>;
  /** description of field allowContactViaEmail */
  allowContactViaEmail?: Maybe<Scalars['Boolean']>;
  shouldReceiveNewsletter?: Maybe<Scalars['Boolean']>;
  calendarReverseDirection?: Maybe<Scalars['Boolean']>;
  calendarElementView?: Maybe<Scalars['Boolean']>;
  calendarUseCtrl?: Maybe<Scalars['Boolean']>;
  calendarStartMonth?: Maybe<Scalars['Int']>;
  calendarHideCompleted?: Maybe<Scalars['Boolean']>;
  calendarSelectedView?: Maybe<CalendarViewEnum>;
  isSidebarExpanded?: Maybe<Scalars['Boolean']>;
  theme?: Maybe<Scalars['Int']>;
};

export type UpdateOrganizationGroupInput = {
  /** description of field id */
  id: Scalars['ID'];
  /** description of field title */
  title: Scalars['String'];
  /** description of field members */
  members: Array<Scalars['Long']>;
};

export type UpdatePermissionGroupInput = {
  id: Scalars['ID'];
  title: Scalars['String'];
  permissions: Array<Scalars['Int']>;
  members: Array<Scalars['Int']>;
  organizations: Array<Scalars['Int']>;
};

export type UpdatePersonGroupInput = {
  /** description of field id */
  id: Scalars['ID'];
  /** description of field title */
  title: Scalars['String'];
  /** description of field members */
  members: Array<Scalars['String']>;
};

export type UpdatePersonsInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  testUser?: Maybe<Scalars['Boolean']>;
  tooManyAttempts?: Maybe<Scalars['Boolean']>;
};

/** Input type for updating an existing calendar task */
export type UpdateTaskInput = {
  /** description of field id */
  id: Scalars['ID'];
  /** description of field calendar */
  calendar: Scalars['ID'];
  /** description of field title */
  title: Scalars['String'];
  /** description of field description */
  description?: Maybe<Scalars['String']>;
  descriptionText?: Maybe<Scalars['String']>;
  /** description of field status */
  status: ContentStatus;
  /** description of field type */
  type: TaskTypeEnum;
};

/** Input type for updating an existing todo instance */
export type UpdateTodoInstanceInput = {
  /** description of field id */
  id: Scalars['ID'];
  /** description of field title */
  title: Scalars['String'];
  /** description of field description */
  description?: Maybe<Scalars['String']>;
  descriptionText?: Maybe<Scalars['String']>;
  /** description of field note */
  note?: Maybe<Scalars['String']>;
  /** description of field isDone */
  isDone: Scalars['Boolean'];
  /** description of field status */
  status: ContentStatus;
  /** description of field responsible */
  responsible?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
};

/** Input type for updating an existing todo template */
export type UpdateTodoTemplateInput = {
  /** description of field id */
  id: Scalars['ID'];
  /** description of field title */
  title: Scalars['String'];
  /** description of field description */
  description?: Maybe<Scalars['String']>;
  descriptionText?: Maybe<Scalars['String']>;
  /** description of field status */
  status: ContentStatus;
  index: Scalars['Int'];
};

/** Update type for updating a topic */
export type UpdateTopicInput = {
  /** description of field id */
  id: Scalars['ID'];
  /** description of field title */
  title: Scalars['String'];
  /** description of field body */
  body: Scalars['String'];
  /** description of field description */
  description: Scalars['String'];
  descriptionText: Scalars['String'];
  /** description of field status */
  status: ContentStatus;
  /** description of field sector */
  sector?: Maybe<Scalars['ID']>;
  /** description of field category */
  category: TopicCategoryEnum;
  /** description of field links */
  links?: Maybe<Array<CreateHttpLinkInput>>;
  /** description of field parameters */
  parameters?: Maybe<Array<CreateActionValueParameterValueInput>>;
  /** description of field kostraFunctions */
  kostraFunctions?: Maybe<Array<Scalars['ID']>>;
  /** description of field access */
  access: AccessInput;
  tags?: Maybe<Array<CreateTagInput>>;
};

/** A file */
export type UserFile = {
  __typename?: 'UserFile';
  /** id */
  id: Scalars['ID'];
  /** Filename */
  name: Scalars['String'];
  lastUpdated: Scalars['Date'];
  description: Scalars['String'];
  descriptionText: Scalars['String'];
  /** description of field size */
  size: Scalars['Int'];
  /** description of field contentType */
  contentType: Scalars['String'];
  /** If you like this */
  isLiked: Scalars['Boolean'];
  /** description of field likes */
  likes: Scalars['Int'];
  createdBy: Person;
  access: Access;
};

/** Send a weekly email with unread notifications */
export type UserSetting = {
  __typename?: 'UserSetting';
  /** description of field showAssignedTasks */
  showAssignedTasks: Scalars['Boolean'];
  /** description of field allowContactViaEmail */
  allowContactViaEmail: Scalars['Boolean'];
  /** description of field sendInstantNotificationMail */
  sendInstantNotificationMail: Scalars['Boolean'];
  /** description of field sendDailyNotificationMail */
  sendDailyNotificationMail: Scalars['Boolean'];
  /** description of field sendWeeklyNotificationMail */
  sendWeeklyNotificationMail: Scalars['Boolean'];
  /** description of field acceptedUserTerms */
  acceptedUserTerms: Scalars['Boolean'];
  /** description of field allowContactViaPhone */
  allowContactViaPhone: Scalars['Boolean'];
  shouldReceiveNewsletter: Scalars['Boolean'];
  calendarReverseDirection: Scalars['Boolean'];
  calendarElementView: Scalars['Boolean'];
  calendarUseCtrl: Scalars['Boolean'];
  calendarStartMonth: Scalars['Int'];
  calendarHideCompleted: Scalars['Boolean'];
  calendarSelectedView: CalendarViewEnum;
  isSidebarExpanded: Scalars['Boolean'];
  theme: Scalars['Int'];
};

export type FieldsOnKostraFunctionGroupFragment = {
  __typename?: 'KostraFunctionGroup';
} & Pick<
  KostraFunctionGroup,
  'id' | 'kid' | 'title' | 'identifier' | 'description'
>;

export type FieldsOnKostraFunctionFragment = {
  __typename?: 'KostraFunction';
} & Pick<KostraFunction, 'id' | 'kid' | 'description' | 'title'> & {
    group: Maybe<
      {
        __typename?: 'KostraFunctionGroup';
      } & FieldsOnKostraFunctionGroupFragment
    >;
  };

export type GetKostraFunctionsQueryVariables = {};

export type GetKostraFunctionsQuery = { __typename?: 'Queries' } & {
  allKostraFunctions: Array<
    { __typename?: 'KostraFunction' } & FieldsOnKostraFunctionFragment
  >;
};

export type MyGetKostraFunctionsQueryVariables = {};

export type MyGetKostraFunctionsQuery = { __typename?: 'Queries' } & {
  me: { __typename?: 'Me' } & Pick<Me, 'id'> & {
      kostraFunctions: Array<
        { __typename?: 'KostraFunction' } & Pick<KostraFunction, 'id'>
      >;
    };
};

export type FindPersonsOrganizationsAndGroupsQueryVariables = {
  query: Scalars['String'];
};

export type FindPersonsOrganizationsAndGroupsQuery = {
  __typename?: 'Queries';
} & {
  search: { __typename?: 'Search' } & {
    persons: Array<
      { __typename?: 'Person' } & Pick<
        Person,
        'name' | 'id' | 'department' | 'picture' | 'role'
      > & {
          organization: Maybe<
            { __typename?: 'Organization' } & Pick<
              Organization,
              'id' | 'name' | 'organizationImage'
            >
          >;
        }
    >;
    groups: Array<
      { __typename?: 'PersonGroup' } & Pick<
        PersonGroup,
        'id' | 'title' | 'dateCreated' | 'lastUpdated'
      > & {
          createdBy: { __typename?: 'Person' } & Pick<Person, 'id' | 'name'>;
          members: Array<
            { __typename?: 'Person' } & Pick<Person, 'name' | 'id'>
          >;
        }
    >;
    organizationGroups: Array<
      { __typename?: 'OrganizationGroup' } & Pick<
        OrganizationGroup,
        'id' | 'lastUpdated' | 'title'
      > & {
          createdBy: { __typename?: 'Person' } & Pick<Person, 'id' | 'name'>;
          members: Array<
            { __typename?: 'Organization' } & Pick<Organization, 'name' | 'id'>
          >;
        }
    >;
    organizations: Array<
      { __typename?: 'Organization' } & Pick<
        Organization,
        'id' | 'name' | 'organizationImage'
      >
    >;
  };
};

export type FindPersonsQueryVariables = {
  query: Scalars['String'];
};

export type FindPersonsQuery = { __typename?: 'Queries' } & {
  search: { __typename?: 'Search' } & {
    persons: Array<
      { __typename?: 'Person' } & Pick<
        Person,
        'name' | 'id' | 'department' | 'picture' | 'role'
      > & {
          organization: Maybe<
            { __typename?: 'Organization' } & Pick<
              Organization,
              'id' | 'name' | 'organizationImage'
            >
          >;
        }
    >;
  };
};

export type GetMeForTagsQueryVariables = {};

export type GetMeForTagsQuery = { __typename?: 'Queries' } & {
  me: { __typename?: 'Me' } & Pick<Me, 'id' | 'name'>;
};

export type AddCalendarMutationMutationVariables = {
  input: CreateCalendarInput;
};

export type AddCalendarMutationMutation = { __typename?: 'Mutations' } & {
  createCalendar: Maybe<
    { __typename?: 'Calendar' } & Pick<
      Calendar,
      'id' | 'title' | 'dateCreated' | 'color' | 'status'
    > & {
        tags: Array<
          { __typename?: 'Tag' } & Pick<Tag, 'id' | 'title' | 'color'>
        >;
        access: { __typename?: 'Access' } & Pick<Access, 'isPrivate'> & {
            persons: Array<
              { __typename?: 'PersonAccess' } & Pick<PersonAccess, 'access'> & {
                  person: { __typename?: 'Person' } & Pick<Person, 'id'>;
                }
            >;
          };
        createdBy: { __typename?: 'Person' } & Pick<Person, 'id' | 'name'>;
      }
  >;
};

export type GetMeQueryVariables = {};

export type GetMeQuery = { __typename?: 'Queries' } & {
  me: { __typename?: 'Me' } & Pick<
    Me,
    'id' | 'name' | 'jobTitle' | 'picture' | 'isAdmin' | 'department'
  > & {
      settings: { __typename?: 'UserSetting' } & Pick<
        UserSetting,
        'isSidebarExpanded'
      >;
      organization: Maybe<
        { __typename?: 'Organization' } & Pick<
          Organization,
          'id' | 'organizationImage' | 'name' | 'url'
        >
      >;
    };
  myFollowed: { __typename?: 'MyFollowed' } & {
    tags: Array<{ __typename?: 'Tag' } & Pick<Tag, 'id' | 'title' | 'color'>>;
  };
};

export type AddTaskCollectionMutationMutationVariables = {
  input: CreateTaskInput;
};

export type AddTaskCollectionMutationMutation = { __typename?: 'Mutations' } & {
  createTask: Maybe<
    { __typename?: 'Task' } & Pick<Task, 'id' | 'title'> & {
        events: Array<{ __typename?: 'Event' } & Pick<Event, 'id'>>;
      }
  >;
};

export type FieldsOnPersonFragment = { __typename?: 'Person' } & Pick<
  Person,
  'id' | 'name' | 'role' | 'picture' | 'department'
> & {
    organization: Maybe<
      { __typename?: 'Organization' } & Pick<
        Organization,
        'id' | 'name' | 'organizationImage' | 'url'
      >
    >;
  };

export type GetAnnualCycleQueryQueryVariables = {
  id: Scalars['ID'];
};

export type GetAnnualCycleQueryQuery = { __typename?: 'Queries' } & {
  myFollowed: { __typename?: 'MyFollowed' } & {
    tags: Array<{ __typename?: 'Tag' } & Pick<Tag, 'id' | 'title' | 'color'>>;
  };
  calendar: Maybe<
    { __typename?: 'Calendar' } & Pick<
      Calendar,
      | 'id'
      | 'shareableUri'
      | 'category'
      | 'title'
      | 'description'
      | 'descriptionText'
      | 'color'
      | 'status'
    > & {
        tags: Array<
          { __typename?: 'Tag' } & Pick<Tag, 'id' | 'title' | 'color'>
        >;
        topics: Array<{ __typename?: 'Topic' } & Pick<Topic, 'title' | 'id'>>;
        kostraFunctions: Array<
          { __typename?: 'KostraFunction' } & FieldsOnKostraFunctionFragment
        >;
        actionValues: Array<
          { __typename?: 'ActionValue' } & Pick<ActionValue, 'title' | 'id'>
        >;
        discussions: Array<
          { __typename?: 'Discussion' } & Pick<Discussion, 'title' | 'id'>
        >;
        access: { __typename?: 'Access' } & Pick<
          Access,
          'isPrivate' | 'myAccess' | 'visibility'
        > & {
            groups: Array<
              { __typename?: 'GroupAccess' } & Pick<GroupAccess, 'access'> & {
                  group: { __typename?: 'PersonGroup' } & Pick<
                    PersonGroup,
                    'id' | 'title' | 'dateCreated' | 'lastUpdated'
                  > & {
                      createdBy: { __typename?: 'Person' } & Pick<
                        Person,
                        'id' | 'name'
                      >;
                      members: Array<
                        { __typename?: 'Person' } & Pick<Person, 'name' | 'id'>
                      >;
                    };
                }
            >;
            organizationGroups: Array<
              { __typename?: 'OrganizationGroupAccess' } & Pick<
                OrganizationGroupAccess,
                'access'
              > & {
                  organizationGroup: {
                    __typename?: 'OrganizationGroup';
                  } & Pick<
                    OrganizationGroup,
                    'id' | 'lastUpdated' | 'title'
                  > & {
                      createdBy: { __typename?: 'Person' } & Pick<
                        Person,
                        'id' | 'name'
                      >;
                      members: Array<
                        { __typename?: 'Organization' } & Pick<
                          Organization,
                          'name' | 'id'
                        >
                      >;
                    };
                }
            >;
            organizations: Array<
              { __typename?: 'OrganizationAccess' } & Pick<
                OrganizationAccess,
                'access'
              > & {
                  organization: { __typename?: 'Organization' } & Pick<
                    Organization,
                    'name' | 'id'
                  >;
                }
            >;
            persons: Array<
              { __typename?: 'PersonAccess' } & Pick<PersonAccess, 'access'> & {
                  person: { __typename?: 'Person' } & Pick<
                    Person,
                    'name' | 'id' | 'department' | 'picture' | 'role'
                  > & {
                      organization: Maybe<
                        { __typename?: 'Organization' } & Pick<
                          Organization,
                          'id' | 'name' | 'organizationImage'
                        >
                      >;
                    };
                }
            >;
          };
        createdBy: { __typename?: 'Person' } & FieldsOnPersonFragment;
      }
  >;
  me: { __typename?: 'Me' } & Pick<Me, 'id' | 'isAdmin' | 'calendarCode'>;
};

export type SearchTopicQueryVariables = {
  query: Scalars['String'];
};

export type SearchTopicQuery = { __typename?: 'Queries' } & {
  search: { __typename?: 'Search' } & {
    topics: Array<
      { __typename?: 'TopicSearch' } & Pick<TopicSearch, 'id' | 'title'>
    >;
  };
};

export type UpdateCalendarMutationVariables = {
  input: UpdateCalendarInput;
};

export type UpdateCalendarMutation = { __typename?: 'Mutations' } & {
  updateCalendar: Maybe<
    { __typename?: 'Calendar' } & Pick<
      Calendar,
      'id' | 'title' | 'description' | 'color' | 'status'
    > & {
        access: { __typename?: 'Access' } & Pick<Access, 'isPrivate'> & {
            persons: Array<
              { __typename?: 'PersonAccess' } & Pick<PersonAccess, 'access'> & {
                  person: { __typename?: 'Person' } & FieldsOnPersonFragment;
                }
            >;
          };
        createdBy: { __typename?: 'Person' } & FieldsOnPersonFragment;
      }
  >;
};

export type AddToRelatedMutationVariables = {
  input: AddToRelatedInput;
};

export type AddToRelatedMutation = { __typename?: 'Mutations' } & {
  addToRelated: Array<
    { __typename?: 'QueryStatus' } & Pick<QueryStatus, 'id' | 'success'>
  >;
};

export type RemoveRelatedMutationVariables = {
  input: RemoveFromRelatedInput;
};

export type RemoveRelatedMutation = { __typename?: 'Mutations' } & {
  removeFromRelated: Array<
    { __typename?: 'QueryStatus' } & Pick<QueryStatus, 'id' | 'success'>
  >;
};

export type CopyCalendarMutationMutationVariables = {
  input: CopyCalendarInput;
};

export type CopyCalendarMutationMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'copyCalendar'
>;

export type ForkCalendarMutationMutationVariables = {
  input: CopyCalendarInput;
};

export type ForkCalendarMutationMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'forkCalendar'
>;

export type UpdateInstanceStatusMutationVariables = {
  input: UpdateEventInstanceInput;
};

export type UpdateInstanceStatusMutation = { __typename?: 'Mutations' } & {
  updateEventInstance: Maybe<
    { __typename?: 'EventInstance' } & Pick<
      EventInstance,
      'id' | 'isDone' | 'isFavourite' | 'status'
    >
  >;
};

export type SetStatusFilesMutationVariables = {
  input: SetStatusInput;
};

export type SetStatusFilesMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'setStatus'
>;

export type UpdateEventInstanceMutationVariables = {
  input: UpdateEventInstanceInput;
};

export type UpdateEventInstanceMutation = { __typename?: 'Mutations' } & {
  updateEventInstance: Maybe<
    { __typename?: 'EventInstance' } & Pick<
      EventInstance,
      | 'id'
      | 'title'
      | 'description'
      | 'isDone'
      | 'isFavourite'
      | 'status'
      | 'note'
      | 'startDate'
      | 'endDate'
      | 'reminderDate'
    > & {
        tags: Array<
          { __typename?: 'Tag' } & Pick<Tag, 'id' | 'title' | 'color'>
        >;
        files: Array<
          { __typename?: 'UserFile' } & Pick<
            UserFile,
            | 'id'
            | 'name'
            | 'description'
            | 'lastUpdated'
            | 'size'
            | 'contentType'
          > & { access: { __typename?: 'Access' } & Pick<Access, 'myAccess'> }
        >;
        assignedBy: Maybe<
          { __typename?: 'Person' } & Pick<Person, 'department'> &
            FieldsOnMiniPersonFragment
        >;
        responsible: Maybe<
          { __typename?: 'Person' } & FieldsOnMiniPersonFragment
        >;
        createdBy: { __typename?: 'Person' } & FieldsOnMiniPersonFragment;
        todos: Array<
          { __typename?: 'TodoInstanceType' } & Pick<
            TodoInstanceType,
            | 'id'
            | 'title'
            | 'description'
            | 'descriptionText'
            | 'isDone'
            | 'status'
            | 'parent'
            | 'fromTemplate'
            | 'note'
            | 'index'
          >
        >;
      }
  >;
};

export type CreateTodoMutationVariables = {
  input: CreateTodoInstanceInput;
};

export type CreateTodoMutation = { __typename?: 'Mutations' } & {
  createTodoInstance: Maybe<
    { __typename?: 'TodoInstanceType' } & Pick<
      TodoInstanceType,
      'id' | 'title' | 'description' | 'isDone' | 'status' | 'note'
    >
  >;
};

export type UpdateTodoMutationVariables = {
  input: UpdateTodoInstanceInput;
};

export type UpdateTodoMutation = { __typename?: 'Mutations' } & {
  updateTodoInstance: Maybe<
    { __typename?: 'TodoInstanceType' } & Pick<
      TodoInstanceType,
      'id' | 'title' | 'description' | 'isDone' | 'status' | 'note'
    >
  >;
};

export type UpdateTodoTemplateMutationVariables = {
  input: UpdateTodoTemplateInput;
};

export type UpdateTodoTemplateMutation = { __typename?: 'Mutations' } & {
  updateTodoTemplate: Maybe<
    { __typename?: 'TodoTemplate' } & Pick<
      TodoTemplate,
      'id' | 'title' | 'description' | 'status'
    >
  >;
};

export type CreateTodoTemplateMutationVariables = {
  input: CreateTodoTemplateInput;
};

export type CreateTodoTemplateMutation = { __typename?: 'Mutations' } & {
  createTodoTemplate: Maybe<
    { __typename?: 'TodoTemplate' } & Pick<
      TodoTemplate,
      'id' | 'title' | 'description' | 'status'
    >
  >;
};

export type UpdateEventMutationVariables = {
  input: UpdateEventInput;
};

export type UpdateEventMutation = { __typename?: 'Mutations' } & {
  updateEvent: Maybe<
    { __typename?: 'Event' } & Pick<
      Event,
      | 'id'
      | 'title'
      | 'description'
      | 'status'
      | 'endRepetitionDate'
      | 'repetitionDefinition'
      | 'startDate'
      | 'startRepetitionDate'
    > & {
        responsible: Maybe<
          { __typename?: 'Person' } & FieldsOnMiniPersonFragment
        >;
        todoTemplate: Array<
          { __typename?: 'TodoTemplate' } & Pick<
            TodoTemplate,
            'id' | 'title' | 'description' | 'status' | 'index'
          >
        >;
      }
  >;
};

export type GetTaskQueryVariables = {
  taskId: Scalars['ID'];
};

export type GetTaskQuery = { __typename?: 'Queries' } & {
  task: Maybe<
    { __typename?: 'Task' } & Pick<
      Task,
      'id' | 'title' | 'description' | 'type' | 'status'
    > & {
        calendars: Array<
          { __typename?: 'Calendar' } & FieldsOnCalendarLookupFragment
        >;
      }
  >;
  me: { __typename?: 'Me' } & Pick<Me, 'id' | 'name'>;
};

export type FieldsOnEventExtendedFragment = { __typename?: 'Event' } & Pick<
  Event,
  | 'id'
  | 'title'
  | 'description'
  | 'startDate'
  | 'reminderDate'
  | 'endDate'
  | 'repetitionDefinition'
  | 'endRepetitionDate'
  | 'startRepetitionDate'
  | 'status'
> & {
    forkedFrom: Maybe<
      { __typename?: 'Calendar' } & Pick<Calendar, 'id' | 'title' | 'uri'>
    >;
    tags: Array<{ __typename?: 'Tag' } & Pick<Tag, 'id' | 'title' | 'color'>>;
    files: Array<
      { __typename?: 'UserFile' } & Pick<
        UserFile,
        'id' | 'name' | 'description' | 'lastUpdated' | 'size' | 'contentType'
      > & { access: { __typename?: 'Access' } & Pick<Access, 'myAccess'> }
    >;
    assignedBy: Maybe<
      { __typename?: 'Person' } & Pick<Person, 'department'> &
        FieldsOnMiniPersonFragment
    >;
    responsible: Maybe<{ __typename?: 'Person' } & FieldsOnMiniPersonFragment>;
    todoTemplate: Array<
      { __typename?: 'TodoTemplate' } & Pick<
        TodoTemplate,
        'id' | 'title' | 'description' | 'status' | 'index'
      >
    >;
    instances: Array<
      { __typename?: 'EventInstance' } & Pick<
        EventInstance,
        | 'id'
        | 'title'
        | 'description'
        | 'isDone'
        | 'isFavourite'
        | 'status'
        | 'note'
        | 'startDate'
        | 'endDate'
        | 'reminderDate'
      > & {
          forkedFrom: Maybe<
            { __typename?: 'Calendar' } & Pick<Calendar, 'id' | 'title' | 'uri'>
          >;
          forks: Array<
            { __typename?: 'EventInstance' } & Pick<
              EventInstance,
              | 'id'
              | 'title'
              | 'description'
              | 'isDone'
              | 'isFavourite'
              | 'status'
              | 'note'
              | 'startDate'
              | 'endDate'
              | 'reminderDate'
            > & {
                forkedFrom: Maybe<
                  { __typename?: 'Calendar' } & Pick<
                    Calendar,
                    'id' | 'title' | 'uri'
                  >
                >;
                tags: Array<
                  { __typename?: 'Tag' } & Pick<Tag, 'id' | 'title' | 'color'>
                >;
                files: Array<
                  { __typename?: 'UserFile' } & Pick<
                    UserFile,
                    | 'id'
                    | 'name'
                    | 'description'
                    | 'lastUpdated'
                    | 'size'
                    | 'contentType'
                  > & {
                      access: { __typename?: 'Access' } & Pick<
                        Access,
                        'myAccess'
                      >;
                    }
                >;
                assignedBy: Maybe<
                  { __typename?: 'Person' } & Pick<Person, 'department'> &
                    FieldsOnMiniPersonFragment
                >;
                responsible: Maybe<
                  { __typename?: 'Person' } & FieldsOnMiniPersonFragment
                >;
                createdBy: {
                  __typename?: 'Person';
                } & FieldsOnMiniPersonFragment;
                todos: Array<
                  { __typename?: 'TodoInstanceType' } & Pick<
                    TodoInstanceType,
                    | 'id'
                    | 'title'
                    | 'description'
                    | 'descriptionText'
                    | 'isDone'
                    | 'status'
                    | 'parent'
                    | 'fromTemplate'
                    | 'note'
                    | 'index'
                  >
                >;
              }
          >;
          tags: Array<
            { __typename?: 'Tag' } & Pick<Tag, 'id' | 'title' | 'color'>
          >;
          files: Array<
            { __typename?: 'UserFile' } & Pick<
              UserFile,
              | 'id'
              | 'name'
              | 'description'
              | 'lastUpdated'
              | 'size'
              | 'contentType'
            > & { access: { __typename?: 'Access' } & Pick<Access, 'myAccess'> }
          >;
          assignedBy: Maybe<
            { __typename?: 'Person' } & Pick<Person, 'department'> &
              FieldsOnMiniPersonFragment
          >;
          responsible: Maybe<
            { __typename?: 'Person' } & FieldsOnMiniPersonFragment
          >;
          createdBy: { __typename?: 'Person' } & FieldsOnMiniPersonFragment;
          todos: Array<
            { __typename?: 'TodoInstanceType' } & Pick<
              TodoInstanceType,
              | 'id'
              | 'title'
              | 'description'
              | 'descriptionText'
              | 'isDone'
              | 'status'
              | 'parent'
              | 'fromTemplate'
              | 'note'
              | 'index'
            >
          >;
        }
    >;
  };

export type UpdateTaskMutationVariables = {
  input: UpdateTaskInput;
};

export type UpdateTaskMutation = { __typename?: 'Mutations' } & {
  updateTask: Maybe<
    { __typename?: 'Task' } & Pick<
      Task,
      'id' | 'title' | 'description' | 'type' | 'status'
    > & {
        calendars: Array<
          { __typename?: 'Calendar' } & FieldsOnCalendarLookupFragment
        >;
      }
  >;
};

export type GetTaskForInvalidQueryVariables = {
  taskId: Scalars['ID'];
};

export type GetTaskForInvalidQuery = { __typename?: 'Queries' } & {
  task: Maybe<
    { __typename?: 'Task' } & Pick<Task, 'id' | 'title' | 'type'> & {
        calendars: Array<
          { __typename?: 'Calendar' } & FieldsOnCalendarLookupFragment
        >;
        events: Array<{ __typename?: 'Event' } & FieldsOnEventFragment>;
      }
  >;
};

export type TopicClapQueryVariables = {
  id: Scalars['ID'];
};

export type TopicClapQuery = { __typename?: 'Queries' } & {
  topic: Maybe<
    { __typename?: 'Topic' } & Pick<
      Topic,
      'id' | 'likes' | 'isLiked' | 'isFollowing'
    >
  >;
};

export type GetInstancesPreviewQueryVariables = {
  id: Scalars['ID'];
  year?: Maybe<Scalars['Int']>;
};

export type GetInstancesPreviewQuery = { __typename?: 'Queries' } & {
  calendar: Maybe<
    { __typename?: 'Calendar' } & {
      tasks: Array<
        { __typename?: 'Task' } & Pick<Task, 'id'> & {
            events: Array<
              { __typename?: 'Event' } & Pick<Event, 'id'> & {
                  instances: Array<
                    { __typename?: 'EventInstance' } & Pick<
                      EventInstance,
                      'id' | 'title' | 'startDate' | 'endDate'
                    > & {
                        responsible: Maybe<
                          { __typename?: 'Person' } & Pick<
                            Person,
                            'id' | 'name' | 'picture'
                          >
                        >;
                        createdBy: { __typename?: 'Person' } & Pick<
                          Person,
                          'id' | 'name' | 'picture'
                        >;
                      }
                  >;
                }
            >;
          }
      >;
    }
  >;
};

export type SearchPersonsQueryVariables = {
  query: Scalars['String'];
};

export type SearchPersonsQuery = { __typename?: 'Queries' } & {
  search: { __typename?: 'Search' } & {
    persons: Array<
      { __typename?: 'Person' } & Pick<
        Person,
        'id' | 'name' | 'picture' | 'role' | 'department'
      > & {
          organization: Maybe<
            { __typename?: 'Organization' } & Pick<
              Organization,
              'id' | 'name' | 'organizationImage' | 'url'
            >
          >;
        }
    >;
  };
};

export type GetFileInfoQueryQueryVariables = {
  id: Scalars['ID'];
};

export type GetFileInfoQueryQuery = { __typename?: 'Queries' } & {
  fileInfo: { __typename?: 'UserFile' } & Pick<
    UserFile,
    | 'id'
    | 'name'
    | 'description'
    | 'descriptionText'
    | 'contentType'
    | 'size'
    | 'lastUpdated'
  >;
};

export type UpdateFileDescriptionMutationVariables = {
  input: UpdateFileInfoInput;
};

export type UpdateFileDescriptionMutation = { __typename?: 'Mutations' } & {
  updateFileInfo: Maybe<
    { __typename?: 'UserFile' } & Pick<UserFile, 'id' | 'description'>
  >;
};

export type DeleteFileMutationMutationVariables = {
  id: Scalars['ID'];
};

export type DeleteFileMutationMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'deleteFile'
>;

export type CreatePersonGroupMutationVariables = {
  input: CreatePersonGroupInput;
};

export type CreatePersonGroupMutation = { __typename?: 'Mutations' } & {
  createPersonGroup: Maybe<
    { __typename?: 'PersonGroup' } & Pick<PersonGroup, 'id'>
  >;
};

export type CreateOrgGroupMutationVariables = {
  input: CreateOrganizationGroupInput;
};

export type CreateOrgGroupMutation = { __typename?: 'Mutations' } & {
  createOrganizationGroup: Maybe<
    { __typename?: 'OrganizationGroup' } & Pick<OrganizationGroup, 'id'>
  >;
};

export type UpdatePersonGroupMutationVariables = {
  input: UpdatePersonGroupInput;
};

export type UpdatePersonGroupMutation = { __typename?: 'Mutations' } & {
  updatePersonGroup: Maybe<
    { __typename?: 'PersonGroup' } & Pick<PersonGroup, 'id'>
  >;
};

export type UpdateOrgGroupMutationVariables = {
  input: UpdateOrganizationGroupInput;
};

export type UpdateOrgGroupMutation = { __typename?: 'Mutations' } & {
  updateOrganizationGroup: Maybe<
    { __typename?: 'OrganizationGroup' } & Pick<OrganizationGroup, 'id'>
  >;
};

export type AllGroupsQueryQueryVariables = {};

export type AllGroupsQueryQuery = { __typename?: 'Queries' } & {
  allPersonGroups: Array<
    { __typename?: 'PersonGroup' } & Pick<
      PersonGroup,
      'id' | 'dateCreated' | 'lastUpdated' | 'title'
    > & {
        createdBy: { __typename?: 'Person' } & Pick<Person, 'id' | 'name'> & {
            organization: Maybe<
              { __typename?: 'Organization' } & Pick<
                Organization,
                'id' | 'name' | 'organizationImage'
              >
            >;
          };
        topics: Array<
          { __typename?: 'Topic' } & Pick<Topic, 'id' | 'title' | 'uri'>
        >;
        actionValues: Array<
          { __typename?: 'ActionValue' } & Pick<
            ActionValue,
            'id' | 'title' | 'uri'
          >
        >;
        calendars: Array<
          { __typename?: 'Calendar' } & Pick<Calendar, 'id' | 'title' | 'uri'>
        >;
        files: Array<
          { __typename?: 'UserFile' } & Pick<UserFile, 'id' | 'name'>
        >;
        discussions: Array<
          { __typename?: 'Discussion' } & Pick<
            Discussion,
            'id' | 'title' | 'uri'
          >
        >;
        members: Array<{ __typename?: 'Person' } & Pick<Person, 'id' | 'name'>>;
      }
  >;
  allOrganizationGroups: Array<
    { __typename?: 'OrganizationGroup' } & Pick<
      OrganizationGroup,
      'id' | 'dateCreated' | 'lastUpdated' | 'title'
    > & {
        createdBy: { __typename?: 'Person' } & Pick<Person, 'id' | 'name'> & {
            organization: Maybe<
              { __typename?: 'Organization' } & Pick<
                Organization,
                'id' | 'name' | 'organizationImage'
              >
            >;
          };
        topics: Array<
          { __typename?: 'Topic' } & Pick<Topic, 'id' | 'title' | 'uri'>
        >;
        actionValues: Array<
          { __typename?: 'ActionValue' } & Pick<
            ActionValue,
            'id' | 'title' | 'uri'
          >
        >;
        calendars: Array<
          { __typename?: 'Calendar' } & Pick<Calendar, 'id' | 'title' | 'uri'>
        >;
        files: Array<
          { __typename?: 'UserFile' } & Pick<UserFile, 'id' | 'name'>
        >;
        discussions: Array<
          { __typename?: 'Discussion' } & Pick<
            Discussion,
            'id' | 'title' | 'uri'
          >
        >;
        members: Array<
          { __typename?: 'Organization' } & Pick<
            Organization,
            'id' | 'name' | 'organizationImage'
          >
        >;
      }
  >;
};

export type RelationQueryQueryVariables = {
  query: Scalars['String'];
  onlyWrite?: Maybe<Scalars['Boolean']>;
};

export type RelationQueryQuery = { __typename?: 'Queries' } & {
  search: { __typename?: 'Search' } & {
    topics: Array<
      { __typename?: 'TopicSearch' } & Pick<
        TopicSearch,
        'id' | 'title' | 'description' | 'uri'
      >
    >;
    actionValues: Array<
      { __typename?: 'ActionValueSearch' } & Pick<
        ActionValueSearch,
        'id' | 'title' | 'description' | 'uri'
      >
    >;
    discussions: Array<
      { __typename?: 'DiscussionSearch' } & Pick<
        DiscussionSearch,
        'id' | 'title' | 'description' | 'uri'
      >
    >;
    calendars: Array<
      { __typename?: 'CalendarSearch' } & Pick<
        CalendarSearch,
        'id' | 'title' | 'description' | 'uri'
      >
    >;
  };
};

export type GetLocaleQueryVariables = {};

export type GetLocaleQuery = { __typename?: 'Queries' } & {
  me: { __typename?: 'Me' } & Pick<
    Me,
    'id' | 'name' | 'description' | 'jobTitle' | 'department' | 'language'
  > & {
      kostraFunctions: Array<
        { __typename?: 'KostraFunction' } & Pick<KostraFunction, 'id'>
      >;
    };
};

export type UpdateLocaleMutationVariables = {
  input: UpdateMeInput;
};

export type UpdateLocaleMutation = { __typename?: 'Mutations' } & {
  updateMe: Maybe<{ __typename?: 'Me' } & Pick<Me, 'id' | 'language'>>;
};

export type AllCalendarsForSidebarQueryVariables = {};

export type AllCalendarsForSidebarQuery = { __typename?: 'Queries' } & {
  myFollowed: { __typename?: 'MyFollowed' } & {
    calendars: Array<
      { __typename?: 'Calendar' } & Pick<
        Calendar,
        'id' | 'isFollowing' | 'title' | 'description' | 'uri' | 'lastUpdated'
      >
    >;
  };
};

export type AllDiscussionsForSidebarQueryVariables = {};

export type AllDiscussionsForSidebarQuery = { __typename?: 'Queries' } & {
  myFollowed: { __typename?: 'MyFollowed' } & {
    discussions: Array<
      { __typename?: 'Discussion' } & Pick<
        Discussion,
        'id' | 'isFollowing' | 'title' | 'description' | 'uri' | 'lastUpdated'
      > & {
          currentRevision: { __typename?: 'ActionValueRevision' } & Pick<
            ActionValueRevision,
            'id' | 'body'
          >;
        }
    >;
  };
};

export type AllActionValueForSidebarQueryVariables = {};

export type AllActionValueForSidebarQuery = { __typename?: 'Queries' } & {
  myFollowed: { __typename?: 'MyFollowed' } & {
    actionValues: Array<
      { __typename?: 'ActionValue' } & Pick<
        ActionValue,
        'id' | 'isFollowing' | 'title' | 'description' | 'uri' | 'lastUpdated'
      > & {
          currentRevision: { __typename?: 'ActionValueRevision' } & Pick<
            ActionValueRevision,
            'id' | 'description'
          >;
        }
    >;
  };
};

export type AllTopicsForSidebarQueryVariables = {};

export type AllTopicsForSidebarQuery = { __typename?: 'Queries' } & {
  myFollowed: { __typename?: 'MyFollowed' } & {
    topics: Array<
      { __typename?: 'Topic' } & Pick<
        Topic,
        'id' | 'isFollowing' | 'title' | 'description' | 'uri' | 'lastUpdated'
      >
    >;
  };
};

export type ShareMutationVariables = {
  input: ShareInput;
};

export type ShareMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'share'
>;

export type OmniSearchQueryQueryVariables = {
  query: Scalars['String'];
};

export type OmniSearchQueryQuery = { __typename?: 'Queries' } & {
  search: { __typename?: 'Search' } & {
    persons: Array<
      { __typename?: 'Person' } & Pick<
        Person,
        'id' | 'name' | 'department' | 'picture' | 'role'
      > & {
          organization: Maybe<
            { __typename?: 'Organization' } & Pick<
              Organization,
              'id' | 'name' | 'organizationImage'
            >
          >;
        }
    >;
    organizations: Array<
      { __typename?: 'Organization' } & Pick<
        Organization,
        'id' | 'name' | 'organizationImage' | 'url'
      >
    >;
    actionValues: Array<
      { __typename?: 'ActionValueSearch' } & Pick<
        ActionValueSearch,
        'id' | 'title' | 'description' | 'uri'
      >
    >;
    discussions: Array<
      { __typename?: 'DiscussionSearch' } & Pick<
        DiscussionSearch,
        'id' | 'title' | 'description' | 'uri'
      > & {
          createdBy: { __typename?: 'Person' } & Pick<
            Person,
            'id' | 'name' | 'picture'
          > & {
              organization: Maybe<
                { __typename?: 'Organization' } & Pick<
                  Organization,
                  'id' | 'name' | 'organizationImage' | 'url'
                >
              >;
            };
        }
    >;
    topics: Array<
      { __typename?: 'TopicSearch' } & Pick<
        TopicSearch,
        'id' | 'title' | 'description' | 'uri'
      > & {
          createdBy: { __typename?: 'Person' } & Pick<
            Person,
            'id' | 'name' | 'picture'
          > & {
              organization: Maybe<
                { __typename?: 'Organization' } & Pick<
                  Organization,
                  'id' | 'name' | 'organizationImage' | 'url'
                >
              >;
            };
        }
    >;
    calendars: Array<
      { __typename?: 'CalendarSearch' } & Pick<
        CalendarSearch,
        'id' | 'title' | 'description' | 'uri'
      > & {
          createdBy: { __typename?: 'Person' } & Pick<
            Person,
            'id' | 'name' | 'picture'
          > & {
              organization: Maybe<
                { __typename?: 'Organization' } & Pick<
                  Organization,
                  'id' | 'name' | 'organizationImage' | 'url'
                >
              >;
            };
        }
    >;
  };
};

export type GetApiKeysQueryVariables = {};

export type GetApiKeysQuery = { __typename?: 'Queries' } & {
  apiKeys: Array<
    { __typename?: 'ApiKey' } & Pick<
      ApiKey,
      'id' | 'token' | 'lastUsed' | 'description' | 'dateCreated'
    >
  >;
};

export type RevokeApiKeyMutationMutationVariables = {
  id: Scalars['ID'];
};

export type RevokeApiKeyMutationMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'revokeApiKey'
>;

export type NewApiKeyMutationVariables = {
  description: Scalars['String'];
};

export type NewApiKeyMutation = { __typename?: 'Mutations' } & {
  createApiKey: { __typename?: 'ApiKey' } & Pick<ApiKey, 'id' | 'token'>;
};

export type GetLoginsQueryVariables = {};

export type GetLoginsQuery = { __typename?: 'Queries' } & {
  me: { __typename?: 'Me' } & Pick<Me, 'id'> & {
      logins: Array<
        { __typename?: 'Login' } & Pick<
          Login,
          | 'id'
          | 'ipAddress'
          | 'lastUsed'
          | 'browser'
          | 'deviceType'
          | 'platform'
        >
      >;
    };
};

export type RevokeRefreshTokenMutationMutationVariables = {
  id: Scalars['ID'];
};

export type RevokeRefreshTokenMutationMutation = {
  __typename?: 'Mutations';
} & Pick<Mutations, 'revokeRefreshToken'>;

export type FieldsOnMeFragment = { __typename?: 'Me' } & Pick<
  Me,
  | 'id'
  | 'name'
  | 'email'
  | 'description'
  | 'jobTitle'
  | 'department'
  | 'language'
  | 'picture'
  | 'phoneNumber'
  | 'alternateEmails'
> & {
    organization: Maybe<
      { __typename?: 'Organization' } & Pick<
        Organization,
        'id' | 'name' | 'organizationImage' | 'url'
      >
    >;
    settings: { __typename?: 'UserSetting' } & Pick<
      UserSetting,
      | 'showAssignedTasks'
      | 'sendInstantNotificationMail'
      | 'sendDailyNotificationMail'
      | 'sendWeeklyNotificationMail'
      | 'acceptedUserTerms'
      | 'allowContactViaPhone'
      | 'allowContactViaEmail'
      | 'shouldReceiveNewsletter'
      | 'isSidebarExpanded'
    >;
    kostraFunctions: Array<
      { __typename?: 'KostraFunction' } & Pick<
        KostraFunction,
        'id' | 'kid' | 'title'
      >
    >;
  };

export type GetUserDataQueryVariables = {};

export type GetUserDataQuery = { __typename?: 'Queries' } & {
  me: { __typename?: 'Me' } & FieldsOnMeFragment;
};

export type GetUserWizardDataQueryVariables = {};

export type GetUserWizardDataQuery = { __typename?: 'Queries' } & {
  me: { __typename?: 'Me' } & FieldsOnMeFragment;
  allKostraFunctionGroups: Array<
    { __typename?: 'KostraFunctionGroup' } & Pick<
      KostraFunctionGroup,
      'id' | 'title'
    > & {
        kostraFunctions: Array<
          { __typename?: 'KostraFunction' } & Pick<
            KostraFunction,
            'id' | 'kid' | 'title'
          >
        >;
      }
  >;
};

export type UpdateMeMutationVariables = {
  input: UpdateMeInput;
};

export type UpdateMeMutation = { __typename?: 'Mutations' } & {
  updateMe: Maybe<{ __typename?: 'Me' } & FieldsOnMeFragment>;
};

export type UpdateMeSettingsMutationVariables = {
  input: UpdateMeSettingsInput;
};

export type UpdateMeSettingsMutation = { __typename?: 'Mutations' } & {
  updateSettings: Maybe<{ __typename?: 'Me' } & FieldsOnMeFragment>;
};

export type RelationQueryActionValueQueryVariables = {
  query: Scalars['String'];
  onlyWrite?: Maybe<Scalars['Boolean']>;
};

export type RelationQueryActionValueQuery = { __typename?: 'Queries' } & {
  search: { __typename?: 'Search' } & {
    actionValues: Array<
      { __typename?: 'ActionValueSearch' } & Pick<
        ActionValueSearch,
        'id' | 'title' | 'description' | 'uri'
      >
    >;
  };
};

export type DeleteMetaMutationVariables = {
  id: Scalars['ID'];
};

export type DeleteMetaMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'deleteMeta'
>;

export type CreateLinkMutationVariables = {
  input: CreateLinkInput;
};

export type CreateLinkMutation = { __typename?: 'Mutations' } & {
  createLink: Maybe<
    { __typename?: 'Link' } & Pick<Link, 'id' | 'label' | 'href'>
  >;
};

export type UpdateLinkMutationVariables = {
  input: UpdateLinkInput;
};

export type UpdateLinkMutation = { __typename?: 'Mutations' } & {
  updateLink: Maybe<{ __typename?: 'Link' } & Pick<Link, 'id'>>;
};

export type SearchQueryCalendarsQueryVariables = {
  query: Scalars['String'];
  onlyWrite?: Maybe<Scalars['Boolean']>;
};

export type SearchQueryCalendarsQuery = { __typename?: 'Queries' } & {
  search: { __typename?: 'Search' } & {
    calendars: Array<
      { __typename?: 'CalendarSearch' } & Pick<
        CalendarSearch,
        'id' | 'title' | 'description' | 'uri'
      >
    >;
  };
};

export type FieldsOnRealmsFragment = { __typename?: 'Realm' } & Pick<
  Realm,
  'id' | 'title' | 'i18nCode' | 'iso3166' | 'localId' | 'parent' | 'type'
>;

export type AllKostrasAndRealmsQueryVariables = {};

export type AllKostrasAndRealmsQuery = { __typename?: 'Queries' } & {
  allKostraFunctions: Array<
    { __typename?: 'KostraFunction' } & FieldsOnKostraFunctionFragment
  >;
  allRealms: Array<{ __typename?: 'Realm' } & FieldsOnRealmsFragment>;
};

export type CreateeActionValueMutationVariables = {
  input: CreateActionValueInput;
};

export type CreateeActionValueMutation = { __typename?: 'Mutations' } & {
  createActionValue: Maybe<
    { __typename?: 'ActionValue' } & Pick<ActionValue, 'id'>
  >;
};

export type UpdateActionValueMutationVariables = {
  input: UpdateActionValueInput;
};

export type UpdateActionValueMutation = { __typename?: 'Mutations' } & {
  updateActionValue: Maybe<
    { __typename?: 'ActionValue' } & Pick<ActionValue, 'id'> & {
        realms: Array<{ __typename?: 'Realm' } & Pick<Realm, 'id'>>;
      }
  >;
};

export type GetActionValueQueryVariables = {
  id: Scalars['ID'];
};

export type GetActionValueQuery = { __typename?: 'Queries' } & {
  actionValue: Maybe<
    { __typename?: 'ActionValue' } & Pick<
      ActionValue,
      | 'id'
      | 'title'
      | 'dateCreated'
      | 'lastUpdated'
      | 'uri'
      | 'description'
      | 'status'
      | 'isFollowing'
    > & {
        realms: Array<
          { __typename?: 'Realm' } & Pick<
            Realm,
            | 'id'
            | 'title'
            | 'i18nCode'
            | 'iso3166'
            | 'localId'
            | 'parent'
            | 'type'
          >
        >;
        kostraFunctions: Array<
          { __typename?: 'KostraFunction' } & FieldsOnKostraFunctionFragment
        >;
        currentRevision: { __typename?: 'ActionValueRevision' } & Pick<
          ActionValueRevision,
          | 'id'
          | 'description'
          | 'body'
          | 'sourceUrl'
          | 'year'
          | 'category'
          | 'type'
        > & {
            links: Array<
              { __typename?: 'HttpLink' } & Pick<HttpLink, 'label' | 'href'>
            >;
            source: Maybe<
              { __typename?: 'Organization' } & Pick<
                Organization,
                'id' | 'name'
              >
            >;
            radarGraph: Maybe<
              Array<
                { __typename?: 'RadarGraphType' } & Pick<
                  RadarGraphType,
                  'name' | 'sum' | 'type'
                >
              >
            >;
            deadlines: Maybe<
              Array<
                { __typename?: 'DeadlineType' } & Pick<
                  DeadlineType,
                  'id' | 'date' | 'type'
                >
              >
            >;
          };
        topics: Array<
          { __typename?: 'Topic' } & Pick<Topic, 'id' | 'title' | 'uri'>
        >;
        files: Array<
          { __typename?: 'UserFile' } & Pick<
            UserFile,
            | 'id'
            | 'name'
            | 'description'
            | 'lastUpdated'
            | 'size'
            | 'contentType'
          >
        >;
        comments: Array<
          { __typename?: 'Comment' } & Pick<
            Comment,
            'id' | 'dateCreated' | 'lastUpdated' | 'message'
          > & {
              author: { __typename?: 'Person' } & Pick<
                Person,
                'picture' | 'id' | 'name' | 'role'
              > & {
                  organization: Maybe<
                    { __typename?: 'Organization' } & Pick<
                      Organization,
                      'id' | 'name' | 'organizationImage'
                    >
                  >;
                };
              files: Array<
                { __typename?: 'UserFile' } & Pick<
                  UserFile,
                  | 'id'
                  | 'name'
                  | 'description'
                  | 'lastUpdated'
                  | 'size'
                  | 'contentType'
                >
              >;
              replies: Array<
                { __typename?: 'Comment' } & Pick<
                  Comment,
                  'id' | 'dateCreated' | 'lastUpdated' | 'message'
                > & {
                    author: { __typename?: 'Person' } & Pick<
                      Person,
                      'id' | 'name' | 'picture' | 'role'
                    > & {
                        organization: Maybe<
                          { __typename?: 'Organization' } & Pick<
                            Organization,
                            'id' | 'name' | 'organizationImage'
                          >
                        >;
                      };
                    files: Array<
                      { __typename?: 'UserFile' } & Pick<
                        UserFile,
                        | 'id'
                        | 'name'
                        | 'description'
                        | 'lastUpdated'
                        | 'size'
                        | 'contentType'
                      >
                    >;
                  }
              >;
            }
        >;
      }
  >;
  allKostraFunctionGroups: Array<
    { __typename?: 'KostraFunctionGroup' } & FieldsOnKostraFunctionGroupFragment
  >;
  allKostraFunctions: Array<
    { __typename?: 'KostraFunction' } & FieldsOnKostraFunctionFragment
  >;
  me: { __typename?: 'Me' } & Pick<
    Me,
    'id' | 'name' | 'jobTitle' | 'picture' | 'isAdmin'
  > & {
      organization: Maybe<
        { __typename?: 'Organization' } & Pick<
          Organization,
          'id' | 'name' | 'organizationImage'
        >
      >;
    };
  allRealms: Array<
    { __typename?: 'Realm' } & Pick<
      Realm,
      'id' | 'title' | 'i18nCode' | 'iso3166' | 'localId' | 'parent' | 'type'
    >
  >;
};

export type FieldsOnActionValueFragment = { __typename?: 'ActionValue' } & Pick<
  ActionValue,
  | 'id'
  | 'title'
  | 'dateCreated'
  | 'lastUpdated'
  | 'uri'
  | 'likes'
  | 'description'
  | 'status'
  | 'isLiked'
  | 'isFollowing'
> & {
    kostraFunctions: Array<
      { __typename?: 'KostraFunction' } & FieldsOnKostraFunctionFragment
    >;
    currentRevision: { __typename?: 'ActionValueRevision' } & Pick<
      ActionValueRevision,
      'id' | 'description' | 'body' | 'year' | 'sourceUrl' | 'category' | 'type'
    > & {
        links: Array<
          { __typename?: 'HttpLink' } & Pick<HttpLink, 'label' | 'href'>
        >;
        source: Maybe<
          { __typename?: 'Organization' } & Pick<Organization, 'id' | 'name'>
        >;
        radarGraph: Maybe<
          Array<
            { __typename?: 'RadarGraphType' } & Pick<
              RadarGraphType,
              'name' | 'sum' | 'type'
            >
          >
        >;
        deadlines: Maybe<
          Array<
            { __typename?: 'DeadlineType' } & Pick<
              DeadlineType,
              'id' | 'date' | 'type'
            >
          >
        >;
      };
    topics: Array<
      { __typename?: 'Topic' } & Pick<Topic, 'id' | 'title' | 'uri'>
    >;
    files: Array<{ __typename?: 'UserFile' } & FieldsOnUserFileFragment>;
    comments: Array<
      { __typename?: 'Comment' } & {
        replies: Array<{ __typename?: 'Comment' } & FieldsOnRepliesFragment>;
      } & FieldsOnCommentFragment
    >;
  };

export type ActionValueQueryVariables = {
  id: Scalars['ID'];
};

export type ActionValueQuery = { __typename?: 'Queries' } & {
  actionValue: Maybe<
    { __typename?: 'ActionValue' } & FieldsOnActionValueFragment
  >;
  me: { __typename?: 'Me' } & FieldsOnMeSimpleFragment;
};

export type AllActionValuesQueryVariables = {
  sort?: Maybe<SortEnum>;
  order?: Maybe<OrderEnum>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  kostraGroup?: Maybe<Scalars['Long']>;
  onlyFollowed?: Maybe<Scalars['Boolean']>;
};

export type AllActionValuesQuery = { __typename?: 'Queries' } & {
  allKostraFunctionGroups: Array<
    { __typename?: 'KostraFunctionGroup' } & Pick<
      KostraFunctionGroup,
      'id' | 'title'
    >
  >;
  allActionValues: { __typename?: 'AllActionValues' } & Pick<
    AllActionValues,
    'totalCount'
  > & {
      pageInfo: { __typename?: 'PageInfo' } & Pick<
        PageInfo,
        | 'hasNextPage'
        | 'hasPreviousPage'
        | 'itemsPerPage'
        | 'numberOfPages'
        | 'pageNumber'
      >;
      nodes: Array<
        { __typename?: 'ActionValue' } & FieldsOnActionValueFragment
      >;
    };
  allSectors: Array<{ __typename?: 'Sector' } & Pick<Sector, 'id' | 'title'>>;
};

export type MeForCalendarListPageQueryVariables = {};

export type MeForCalendarListPageQuery = { __typename?: 'Queries' } & {
  me: { __typename?: 'Me' } & Pick<
    Me,
    'id' | 'name' | 'isAdmin' | 'userRoles' | 'permissions' | 'language'
  > & {
      organization: Maybe<
        { __typename?: 'Organization' } & Pick<Organization, 'id' | 'name'>
      >;
    };
};

export type FindOrganizationsQueryVariables = {
  query: Scalars['String'];
};

export type FindOrganizationsQuery = { __typename?: 'Queries' } & {
  findOrganizations: Array<
    { __typename?: 'Organization' } & Pick<
      Organization,
      'id' | 'name' | 'organizationImage'
    >
  >;
};

export type FindPersons2QueryVariables = {
  query: Scalars['String'];
};

export type FindPersons2Query = { __typename?: 'Queries' } & {
  findPersonsByNameOrEmail: Array<
    { __typename?: 'Person' } & Pick<
      Person,
      'id' | 'name' | 'picture' | 'role'
    > & {
        organization: Maybe<
          { __typename?: 'Organization' } & Pick<
            Organization,
            'id' | 'name' | 'organizationImage'
          >
        >;
      }
  >;
};

export type AllCalendarsQueryVariables = {
  sort?: Maybe<SortEnum>;
  order?: Maybe<OrderEnum>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  organizations?: Maybe<Array<Scalars['Long']>>;
  kostraGroup?: Maybe<Scalars['Long']>;
  persons?: Maybe<Array<Scalars['ID']>>;
  category?: Maybe<CalendarCategoryEnum>;
};

export type AllCalendarsQuery = { __typename?: 'Queries' } & {
  me: { __typename?: 'Me' } & Pick<
    Me,
    'id' | 'name' | 'isAdmin' | 'userRoles' | 'permissions' | 'language'
  > & {
      organization: Maybe<
        { __typename?: 'Organization' } & Pick<Organization, 'id'>
      >;
    };
  allKostraFunctionGroups: Array<
    { __typename?: 'KostraFunctionGroup' } & Pick<
      KostraFunctionGroup,
      'id' | 'title'
    >
  >;
  allCalendars: { __typename?: 'AllCalendars' } & Pick<
    AllCalendars,
    'totalCount'
  > & {
      pageInfo: { __typename?: 'PageInfo' } & Pick<
        PageInfo,
        | 'hasNextPage'
        | 'hasPreviousPage'
        | 'itemsPerPage'
        | 'numberOfPages'
        | 'pageNumber'
      >;
      nodes: Array<{ __typename?: 'Calendar' } & FieldsOnCalendarFragment>;
    };
};

export type CheckCalendarCopyReadyQueryVariables = {
  id: Scalars['ID'];
};

export type CheckCalendarCopyReadyQuery = { __typename?: 'Queries' } & Pick<
  Queries,
  'checkCalendarCopyReady'
>;

export type FieldsOnTaskFragment = { __typename?: 'Task' } & Pick<
  Task,
  'id' | 'title' | 'status'
> & {
    tags: Array<{ __typename?: 'Tag' } & Pick<Tag, 'id' | 'title' | 'color'>>;
  };

export type FieldsOnMiniPersonFragment = { __typename?: 'Person' } & Pick<
  Person,
  'id' | 'name' | 'role' | 'picture'
> & {
    organization: Maybe<
      { __typename?: 'Organization' } & Pick<
        Organization,
        'id' | 'name' | 'organizationImage' | 'url'
      >
    >;
  };

export type FieldsOnEventFragment = { __typename?: 'Event' } & Pick<
  Event,
  | 'id'
  | 'title'
  | 'description'
  | 'startDate'
  | 'reminderDate'
  | 'endDate'
  | 'repetitionDefinition'
  | 'endRepetitionDate'
  | 'startRepetitionDate'
  | 'status'
> & {
    forkedFrom: Maybe<
      { __typename?: 'Calendar' } & Pick<Calendar, 'id' | 'title' | 'uri'>
    >;
    tags: Array<{ __typename?: 'Tag' } & Pick<Tag, 'id' | 'title' | 'color'>>;
    files: Array<
      { __typename?: 'UserFile' } & Pick<
        UserFile,
        'id' | 'name' | 'description' | 'lastUpdated' | 'size' | 'contentType'
      > & { access: { __typename?: 'Access' } & Pick<Access, 'myAccess'> }
    >;
    assignedBy: Maybe<
      { __typename?: 'Person' } & Pick<Person, 'department'> &
        FieldsOnMiniPersonFragment
    >;
    responsible: Maybe<{ __typename?: 'Person' } & FieldsOnMiniPersonFragment>;
    todoTemplate: Array<
      { __typename?: 'TodoTemplate' } & Pick<
        TodoTemplate,
        'id' | 'title' | 'description' | 'status' | 'index'
      >
    >;
    instances: Array<
      { __typename?: 'EventInstance' } & Pick<
        EventInstance,
        | 'id'
        | 'title'
        | 'description'
        | 'isDone'
        | 'isFavourite'
        | 'status'
        | 'note'
        | 'startDate'
        | 'endDate'
        | 'reminderDate'
      > & {
          forkedFrom: Maybe<
            { __typename?: 'Calendar' } & Pick<Calendar, 'id' | 'title' | 'uri'>
          >;
          forks: Array<
            { __typename?: 'EventInstance' } & Pick<
              EventInstance,
              | 'id'
              | 'title'
              | 'description'
              | 'isDone'
              | 'isFavourite'
              | 'status'
              | 'note'
              | 'startDate'
              | 'endDate'
              | 'reminderDate'
            > & {
                event: { __typename?: 'Event' } & {
                  task: { __typename?: 'Task' } & Pick<Task, 'id'> & {
                      calendars: Array<
                        { __typename?: 'Calendar' } & Pick<
                          Calendar,
                          'id' | 'title' | 'uri'
                        >
                      >;
                    };
                };
                tags: Array<
                  { __typename?: 'Tag' } & Pick<Tag, 'id' | 'title' | 'color'>
                >;
                files: Array<
                  { __typename?: 'UserFile' } & Pick<
                    UserFile,
                    | 'id'
                    | 'name'
                    | 'description'
                    | 'lastUpdated'
                    | 'size'
                    | 'contentType'
                  > & {
                      access: { __typename?: 'Access' } & Pick<
                        Access,
                        'myAccess'
                      >;
                    }
                >;
                assignedBy: Maybe<
                  { __typename?: 'Person' } & Pick<Person, 'department'> &
                    FieldsOnMiniPersonFragment
                >;
                responsible: Maybe<
                  { __typename?: 'Person' } & FieldsOnMiniPersonFragment
                >;
                createdBy: {
                  __typename?: 'Person';
                } & FieldsOnMiniPersonFragment;
                todos: Array<
                  { __typename?: 'TodoInstanceType' } & Pick<
                    TodoInstanceType,
                    | 'id'
                    | 'title'
                    | 'description'
                    | 'descriptionText'
                    | 'isDone'
                    | 'status'
                    | 'parent'
                    | 'fromTemplate'
                    | 'note'
                    | 'index'
                  >
                >;
              }
          >;
          tags: Array<
            { __typename?: 'Tag' } & Pick<Tag, 'id' | 'title' | 'color'>
          >;
          files: Array<
            { __typename?: 'UserFile' } & Pick<
              UserFile,
              | 'id'
              | 'name'
              | 'description'
              | 'lastUpdated'
              | 'size'
              | 'contentType'
            > & { access: { __typename?: 'Access' } & Pick<Access, 'myAccess'> }
          >;
          assignedBy: Maybe<
            { __typename?: 'Person' } & Pick<Person, 'department'> &
              FieldsOnMiniPersonFragment
          >;
          responsible: Maybe<
            { __typename?: 'Person' } & FieldsOnMiniPersonFragment
          >;
          createdBy: { __typename?: 'Person' } & FieldsOnMiniPersonFragment;
          todos: Array<
            { __typename?: 'TodoInstanceType' } & Pick<
              TodoInstanceType,
              | 'id'
              | 'title'
              | 'description'
              | 'descriptionText'
              | 'isDone'
              | 'status'
              | 'parent'
              | 'fromTemplate'
              | 'note'
              | 'index'
            >
          >;
        }
    >;
  };

export type FieldsOnEventInstanceFragment = {
  __typename?: 'EventInstance';
} & Pick<
  EventInstance,
  | 'id'
  | 'title'
  | 'description'
  | 'isDone'
  | 'isFavourite'
  | 'status'
  | 'note'
  | 'startDate'
  | 'endDate'
  | 'reminderDate'
> & {
    forkedFrom: Maybe<
      { __typename?: 'Calendar' } & Pick<Calendar, 'id' | 'title' | 'uri'>
    >;
    forks: Array<
      { __typename?: 'EventInstance' } & Pick<
        EventInstance,
        | 'id'
        | 'title'
        | 'description'
        | 'isDone'
        | 'isFavourite'
        | 'status'
        | 'note'
        | 'startDate'
        | 'endDate'
        | 'reminderDate'
      > & {
          event: { __typename?: 'Event' } & Pick<Event, 'id'> & {
              task: { __typename?: 'Task' } & Pick<Task, 'id'> & {
                  calendars: Array<
                    { __typename?: 'Calendar' } & Pick<
                      Calendar,
                      'id' | 'title' | 'uri'
                    >
                  >;
                };
            };
          tags: Array<
            { __typename?: 'Tag' } & Pick<Tag, 'id' | 'title' | 'color'>
          >;
          files: Array<
            { __typename?: 'UserFile' } & Pick<
              UserFile,
              | 'id'
              | 'name'
              | 'description'
              | 'lastUpdated'
              | 'size'
              | 'contentType'
            > & { access: { __typename?: 'Access' } & Pick<Access, 'myAccess'> }
          >;
          assignedBy: Maybe<
            { __typename?: 'Person' } & Pick<Person, 'department'> &
              FieldsOnMiniPersonFragment
          >;
          responsible: Maybe<
            { __typename?: 'Person' } & FieldsOnMiniPersonFragment
          >;
          createdBy: { __typename?: 'Person' } & FieldsOnMiniPersonFragment;
          todos: Array<
            { __typename?: 'TodoInstanceType' } & Pick<
              TodoInstanceType,
              | 'id'
              | 'title'
              | 'description'
              | 'descriptionText'
              | 'isDone'
              | 'status'
              | 'parent'
              | 'fromTemplate'
              | 'note'
              | 'index'
            >
          >;
        }
    >;
    tags: Array<{ __typename?: 'Tag' } & Pick<Tag, 'id' | 'title' | 'color'>>;
    files: Array<
      { __typename?: 'UserFile' } & Pick<
        UserFile,
        'id' | 'name' | 'description' | 'lastUpdated' | 'size' | 'contentType'
      > & { access: { __typename?: 'Access' } & Pick<Access, 'myAccess'> }
    >;
    assignedBy: Maybe<
      { __typename?: 'Person' } & Pick<Person, 'department'> &
        FieldsOnMiniPersonFragment
    >;
    responsible: Maybe<{ __typename?: 'Person' } & FieldsOnMiniPersonFragment>;
    createdBy: { __typename?: 'Person' } & FieldsOnMiniPersonFragment;
    todos: Array<
      { __typename?: 'TodoInstanceType' } & Pick<
        TodoInstanceType,
        | 'id'
        | 'title'
        | 'description'
        | 'descriptionText'
        | 'isDone'
        | 'status'
        | 'parent'
        | 'fromTemplate'
        | 'note'
        | 'index'
      >
    >;
  };

export type FieldsOnCalendarFragment = { __typename?: 'Calendar' } & Pick<
  Calendar,
  | 'id'
  | 'title'
  | 'uri'
  | 'description'
  | 'category'
  | 'color'
  | 'status'
  | 'lastUpdated'
  | 'dateCreated'
  | 'isFollowing'
  | 'isRestricted'
  | 'isLimited'
  | 'isLiked'
  | 'likes'
> & {
    access: { __typename?: 'Access' } & Pick<
      Access,
      'myAccess' | 'isPrivate'
    > & {
        persons: Array<
          { __typename?: 'PersonAccess' } & Pick<PersonAccess, 'access'> & {
              person: { __typename?: 'Person' } & Pick<Person, 'id'>;
            }
        >;
      };
    topics: Array<
      { __typename?: 'Topic' } & Pick<
        Topic,
        | 'id'
        | 'title'
        | 'uri'
        | 'dateCreated'
        | 'isFollowing'
        | 'isLimited'
        | 'isRestricted'
      >
    >;
    followers: Array<{ __typename?: 'Person' } & Pick<Person, 'id'>>;
    createdBy: { __typename?: 'Person' } & Pick<
      Person,
      'id' | 'name' | 'picture' | 'role'
    > & {
        organization: Maybe<
          { __typename?: 'Organization' } & Pick<
            Organization,
            'id' | 'organizationImage' | 'name' | 'url'
          >
        >;
      };
    forkedFrom: Maybe<
      { __typename?: 'Calendar' } & Pick<
        Calendar,
        'id' | 'title' | 'uri' | 'color'
      >
    >;
    forks: Array<
      { __typename?: 'Calendar' } & Pick<
        Calendar,
        'id' | 'title' | 'uri' | 'color'
      >
    >;
  };

export type FieldsOnCalendarLookupFragment = { __typename?: 'Calendar' } & Pick<
  Calendar,
  'id' | 'title' | 'description' | 'color'
> & {
    access: { __typename?: 'Access' } & {
      persons: Array<
        { __typename?: 'PersonAccess' } & Pick<PersonAccess, 'access'> & {
            person: { __typename?: 'Person' } & Pick<Person, 'id'>;
          }
      >;
    };
    createdBy: { __typename?: 'Person' } & Pick<Person, 'id'>;
  };

export type FieldsOnCalendarRingFragment = {
  __typename?: 'CalendarRing';
} & Pick<CalendarRing, 'id' | 'order' | 'color'> & {
    calendar: { __typename?: 'Calendar' } & FieldsOnCalendarLookupFragment;
  };

export type YearWheelQueryVariables = {
  person: Scalars['ID'];
};

export type YearWheelQuery = { __typename?: 'Queries' } & {
  myFollowed: { __typename?: 'MyFollowed' } & {
    calendars: Array<
      { __typename?: 'Calendar' } & Pick<
        Calendar,
        'id' | 'title' | 'description' | 'color' | 'isFollowing'
      >
    >;
  };
  me: { __typename?: 'Me' } & Pick<
    Me,
    'id' | 'name' | 'calendarCode' | 'picture' | 'isAdmin'
  > & {
      settings: { __typename?: 'UserSetting' } & Pick<
        UserSetting,
        | 'showAssignedTasks'
        | 'calendarReverseDirection'
        | 'calendarElementView'
        | 'calendarUseCtrl'
        | 'calendarSelectedView'
        | 'calendarStartMonth'
        | 'calendarHideCompleted'
      >;
    };
  myCalendars: Array<
    { __typename?: 'Calendar' } & FieldsOnCalendarLookupFragment
  >;
  yearWheel: Array<
    { __typename?: 'CalendarRing' } & FieldsOnCalendarRingFragment
  >;
  myAssignedTasks: {
    __typename?: 'CalendarRing';
  } & FieldsOnCalendarRingFragment;
};

export type YearWheelRingQueryVariables = {
  calendarId: Scalars['ID'];
  year?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
};

export type YearWheelRingQuery = { __typename?: 'Queries' } & {
  myFollowed: { __typename?: 'MyFollowed' } & {
    tags: Array<{ __typename?: 'Tag' } & Pick<Tag, 'id' | 'title' | 'color'>>;
  };
  calendar: Maybe<
    { __typename?: 'Calendar' } & Pick<
      Calendar,
      | 'id'
      | 'title'
      | 'description'
      | 'uri'
      | 'category'
      | 'color'
      | 'status'
      | 'lastUpdated'
      | 'dateCreated'
      | 'isFollowing'
      | 'isRestricted'
      | 'isLimited'
      | 'isLiked'
      | 'likes'
    > & {
        access: { __typename?: 'Access' } & Pick<
          Access,
          'myAccess' | 'isPrivate'
        > & {
            persons: Array<
              { __typename?: 'PersonAccess' } & Pick<PersonAccess, 'access'> & {
                  person: { __typename?: 'Person' } & Pick<Person, 'id'>;
                }
            >;
          };
        topics: Array<
          { __typename?: 'Topic' } & Pick<
            Topic,
            | 'id'
            | 'title'
            | 'uri'
            | 'dateCreated'
            | 'isFollowing'
            | 'isLimited'
            | 'isRestricted'
          >
        >;
        followers: Array<{ __typename?: 'Person' } & Pick<Person, 'id'>>;
        createdBy: { __typename?: 'Person' } & Pick<
          Person,
          'id' | 'name' | 'picture' | 'role'
        > & {
            organization: Maybe<
              { __typename?: 'Organization' } & Pick<
                Organization,
                'id' | 'organizationImage' | 'name' | 'url'
              >
            >;
          };
        forkedFrom: Maybe<
          { __typename?: 'Calendar' } & Pick<Calendar, 'id' | 'title' | 'uri'>
        >;
        tasks: Array<
          { __typename?: 'Task' } & {
            events: Array<
              { __typename?: 'Event' } & {
                instances: Array<
                  {
                    __typename?: 'EventInstance';
                  } & FieldsOnEventInstanceFragment
                >;
              } & FieldsOnEventFragment
            >;
          } & FieldsOnTaskFragment
        >;
      }
  >;
};

export type YearWheelMagicRingQueryVariables = {
  year?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
};

export type YearWheelMagicRingQuery = { __typename?: 'Queries' } & {
  myAssignedTasks: { __typename?: 'CalendarRing' } & {
    calendar: { __typename?: 'Calendar' } & Pick<
      Calendar,
      | 'id'
      | 'title'
      | 'uri'
      | 'description'
      | 'category'
      | 'color'
      | 'status'
      | 'lastUpdated'
      | 'dateCreated'
      | 'isFollowing'
      | 'isRestricted'
      | 'isLimited'
    > & {
        access: { __typename?: 'Access' } & Pick<
          Access,
          'myAccess' | 'isPrivate'
        > & {
            persons: Array<
              { __typename?: 'PersonAccess' } & Pick<PersonAccess, 'access'> & {
                  person: { __typename?: 'Person' } & Pick<Person, 'id'>;
                }
            >;
          };
        createdBy: { __typename?: 'Person' } & Pick<
          Person,
          'id' | 'name' | 'picture' | 'role'
        > & {
            organization: Maybe<
              { __typename?: 'Organization' } & Pick<
                Organization,
                'id' | 'organizationImage' | 'name'
              >
            >;
          };
        tasks: Array<
          { __typename?: 'Task' } & {
            events: Array<
              { __typename?: 'Event' } & {
                instances: Array<
                  {
                    __typename?: 'EventInstance';
                  } & FieldsOnEventInstanceFragment
                >;
              } & FieldsOnEventFragment
            >;
          } & FieldsOnTaskFragment
        >;
      };
  };
};

export type UpdateSettingsMutationVariables = {
  input: UpdateMeSettingsInput;
};

export type UpdateSettingsMutation = { __typename?: 'Mutations' } & {
  updateSettings: Maybe<
    { __typename?: 'Me' } & Pick<
      Me,
      'id' | 'calendarCode' | 'picture' | 'isAdmin'
    > & {
        settings: { __typename?: 'UserSetting' } & Pick<
          UserSetting,
          | 'showAssignedTasks'
          | 'calendarReverseDirection'
          | 'calendarElementView'
          | 'calendarUseCtrl'
          | 'calendarStartMonth'
          | 'calendarHideCompleted'
          | 'calendarSelectedView'
        >;
      }
  >;
};

export type CreateCalendarRingMutationMutationVariables = {
  input: CreateCalendarRingInput;
};

export type CreateCalendarRingMutationMutation = {
  __typename?: 'Mutations';
} & {
  createCalendarRing: Maybe<
    { __typename?: 'CalendarRing' } & FieldsOnCalendarRingFragment
  >;
};

export type DeleteCalendarRingMutationMutationVariables = {
  id: Scalars['ID'];
};

export type DeleteCalendarRingMutationMutation = {
  __typename?: 'Mutations';
} & Pick<Mutations, 'deleteCalendarRing'>;

export type ArchiveCalendarMutationMutationVariables = {
  id: Scalars['ID'];
};

export type ArchiveCalendarMutationMutation = { __typename?: 'Mutations' } & {
  archiveCalendar: Maybe<
    { __typename?: 'Calendar' } & Pick<Calendar, 'id' | 'status'>
  >;
};

export type MyCalendarsQueryVariables = {};

export type MyCalendarsQuery = { __typename?: 'Queries' } & {
  myCalendars: Array<
    { __typename?: 'Calendar' } & Pick<
      Calendar,
      'id' | 'title' | 'description' | 'descriptionText' | 'color'
    >
  >;
};

export type CalendarQueryVariables = {
  id: Scalars['ID'];
  year?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<Scalars['String']>>;
};

export type CalendarQuery = { __typename?: 'Queries' } & {
  myFollowed: { __typename?: 'MyFollowed' } & {
    tags: Array<{ __typename?: 'Tag' } & Pick<Tag, 'id' | 'title' | 'color'>>;
  };
  me: { __typename?: 'Me' } & Pick<
    Me,
    'id' | 'name' | 'calendarCode' | 'picture' | 'isAdmin'
  > & {
      settings: { __typename?: 'UserSetting' } & Pick<
        UserSetting,
        'calendarHideCompleted' | 'showAssignedTasks'
      >;
    };
  yearWheel: Array<
    { __typename?: 'CalendarRing' } & {
      calendar: { __typename?: 'Calendar' } & Pick<Calendar, 'id'>;
    }
  >;
  calendar: Maybe<
    { __typename?: 'Calendar' } & {
      tasks: Array<
        { __typename?: 'Task' } & {
          events: Array<
            { __typename?: 'Event' } & {
              instances: Array<
                { __typename?: 'EventInstance' } & FieldsOnEventInstanceFragment
              >;
            } & FieldsOnEventFragment
          >;
        } & FieldsOnTaskFragment
      >;
    } & FieldsOnCalendarFragment
  >;
};

export type SearchTagsQueryVariables = {
  query: Scalars['String'];
};

export type SearchTagsQuery = { __typename?: 'Queries' } & {
  search: { __typename?: 'Search' } & {
    tags: Array<{ __typename?: 'Tag' } & Pick<Tag, 'id' | 'title' | 'color'>>;
  };
};

export type FollowTagMutationVariables = {
  input: FollowTagInput;
};

export type FollowTagMutation = { __typename?: 'Mutations' } & {
  followTag: { __typename?: 'Tag' } & Pick<Tag, 'id' | 'color' | 'title'>;
};

export type UnfollowTagMutationVariables = {
  title: Scalars['String'];
};

export type UnfollowTagMutation = { __typename?: 'Mutations' } & {
  unFollowTag: { __typename?: 'Tag' } & Pick<Tag, 'id' | 'title' | 'color'>;
};

export type MakeSharableMutationVariables = {
  input: MakeSharableInput;
};

export type MakeSharableMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'makeSharable'
>;

export type RemoveSharableMutationVariables = {
  id: Scalars['ID'];
};

export type RemoveSharableMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'removeSharable'
>;

export type GetMyFollowedCalendarsDropdownQueryVariables = {};

export type GetMyFollowedCalendarsDropdownQuery = { __typename?: 'Queries' } & {
  myFollowed: { __typename?: 'MyFollowed' } & {
    calendars: Array<
      { __typename?: 'Calendar' } & Pick<
        Calendar,
        'id' | 'title' | 'description' | 'color' | 'isFollowing'
      >
    >;
  };
};

export type GetIncompleteTasksAssignedByMeQueryVariables = {};

export type GetIncompleteTasksAssignedByMeQuery = { __typename?: 'Queries' } & {
  me: { __typename?: 'Me' } & Pick<Me, 'id' | 'calendarCode'>;
  incompleteTasksAssignedByMe: Array<
    { __typename?: 'EventInstance' } & Pick<
      EventInstance,
      | 'id'
      | 'title'
      | 'isFavourite'
      | 'isDone'
      | 'note'
      | 'description'
      | 'startDate'
      | 'endDate'
      | 'reminderDate'
    > & {
        tags: Array<
          { __typename?: 'Tag' } & Pick<Tag, 'id' | 'title' | 'color'>
        >;
        createdBy: { __typename?: 'Person' } & FieldsOnMiniPersonFragment;
        responsible: Maybe<
          { __typename?: 'Person' } & FieldsOnMiniPersonFragment
        >;
        event: { __typename?: 'Event' } & Pick<Event, 'id'> & {
            task: { __typename?: 'Task' } & Pick<Task, 'id' | 'title'> & {
                calendars: Array<
                  { __typename?: 'Calendar' } & Pick<
                    Calendar,
                    'id' | 'title' | 'color' | 'uri'
                  >
                >;
              };
          };
      }
  >;
};

export type GetUpcomingDeadlinesQueryVariables = {};

export type GetUpcomingDeadlinesQuery = { __typename?: 'Queries' } & {
  me: { __typename?: 'Me' } & Pick<Me, 'id' | 'calendarCode'>;
  upcomingDeadlines: Array<
    { __typename?: 'EventInstance' } & Pick<
      EventInstance,
      | 'id'
      | 'title'
      | 'isFavourite'
      | 'isDone'
      | 'note'
      | 'description'
      | 'startDate'
      | 'endDate'
      | 'reminderDate'
    > & {
        tags: Array<
          { __typename?: 'Tag' } & Pick<Tag, 'id' | 'title' | 'color'>
        >;
        createdBy: { __typename?: 'Person' } & FieldsOnMiniPersonFragment;
        responsible: Maybe<
          { __typename?: 'Person' } & FieldsOnMiniPersonFragment
        >;
        assignedBy: Maybe<
          { __typename?: 'Person' } & FieldsOnMiniPersonFragment
        >;
        event: { __typename?: 'Event' } & Pick<Event, 'id'> & {
            task: { __typename?: 'Task' } & Pick<Task, 'id' | 'title'> & {
                calendars: Array<
                  { __typename?: 'Calendar' } & Pick<
                    Calendar,
                    'id' | 'title' | 'color' | 'uri'
                  >
                >;
              };
          };
      }
  >;
};

export type GetMyUnfinishedTasksQueryVariables = {};

export type GetMyUnfinishedTasksQuery = { __typename?: 'Queries' } & {
  me: { __typename?: 'Me' } & Pick<Me, 'id' | 'calendarCode'>;
  myUnfinishedTasks: Array<
    { __typename?: 'EventInstance' } & Pick<
      EventInstance,
      | 'id'
      | 'title'
      | 'isFavourite'
      | 'isDone'
      | 'note'
      | 'description'
      | 'startDate'
      | 'endDate'
      | 'reminderDate'
    > & {
        tags: Array<
          { __typename?: 'Tag' } & Pick<Tag, 'id' | 'title' | 'color'>
        >;
        createdBy: { __typename?: 'Person' } & FieldsOnMiniPersonFragment;
        responsible: Maybe<
          { __typename?: 'Person' } & FieldsOnMiniPersonFragment
        >;
        assignedBy: Maybe<
          { __typename?: 'Person' } & FieldsOnMiniPersonFragment
        >;
        event: { __typename?: 'Event' } & Pick<Event, 'id'> & {
            task: { __typename?: 'Task' } & Pick<Task, 'id' | 'title'> & {
                calendars: Array<
                  { __typename?: 'Calendar' } & Pick<
                    Calendar,
                    'id' | 'title' | 'color' | 'uri'
                  >
                >;
              };
          };
      }
  >;
};

export type GetMyFollowedActionValuesQueryVariables = {};

export type GetMyFollowedActionValuesQuery = { __typename?: 'Queries' } & {
  myFollowed: { __typename?: 'MyFollowed' } & {
    actionValues: Array<
      { __typename?: 'ActionValue' } & Pick<
        ActionValue,
        'id' | 'isFollowing' | 'lastUpdated'
      >
    >;
  };
};

export type GetActionValuesQueryVariables = {
  input: AllActionValuesInput;
};

export type GetActionValuesQuery = { __typename?: 'Queries' } & {
  allActionValues: { __typename?: 'AllActionValues' } & {
    nodes: Array<
      { __typename?: 'ActionValue' } & Pick<
        ActionValue,
        'id' | 'uri' | 'lastUpdated' | 'title' | 'isFollowing' | 'dateCreated'
      >
    >;
  };
  myFollowed: { __typename?: 'MyFollowed' } & {
    actionValues: Array<
      { __typename?: 'ActionValue' } & Pick<
        ActionValue,
        'id' | 'uri' | 'lastUpdated' | 'title' | 'isFollowing' | 'dateCreated'
      >
    >;
  };
};

export type FollowGrantMutationVariables = {
  id: Scalars['ID'];
};

export type FollowGrantMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'follow'
>;

export type UnfollowGrantMutationVariables = {
  id: Scalars['ID'];
};

export type UnfollowGrantMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'unFollow'
>;

export type GetMyFollowedCalendarsQueryVariables = {};

export type GetMyFollowedCalendarsQuery = { __typename?: 'Queries' } & {
  myFollowed: { __typename?: 'MyFollowed' } & {
    calendars: Array<
      { __typename?: 'Calendar' } & Pick<
        Calendar,
        'id' | 'title' | 'description' | 'color' | 'isFollowing' | 'lastUpdated'
      >
    >;
  };
};

export type GetOneCalendarQueryVariables = {
  id: Scalars['ID'];
};

export type GetOneCalendarQuery = { __typename?: 'Queries' } & {
  calendar: Maybe<
    { __typename?: 'Calendar' } & Pick<
      Calendar,
      'id' | 'isFollowing' | 'likes' | 'isLiked'
    > & { followers: Array<{ __typename?: 'Person' } & Pick<Person, 'id'>> }
  >;
};

export type MeForDashboardQueryVariables = {};

export type MeForDashboardQuery = { __typename?: 'Queries' } & {
  me: { __typename?: 'Me' } & Pick<Me, 'id' | 'picture'> & {
      organization: Maybe<
        { __typename?: 'Organization' } & Pick<
          Organization,
          'id' | 'name' | 'organizationImage'
        >
      >;
    };
};

export type FollowCalendarDashboardMutationVariables = {
  id: Scalars['ID'];
};

export type FollowCalendarDashboardMutation = {
  __typename?: 'Mutations';
} & Pick<Mutations, 'follow'>;

export type UnfollowCalendarDashboardMutationVariables = {
  id: Scalars['ID'];
};

export type UnfollowCalendarDashboardMutation = {
  __typename?: 'Mutations';
} & Pick<Mutations, 'unFollow'>;

export type GetMyFollowedDiscussionsQueryVariables = {};

export type GetMyFollowedDiscussionsQuery = { __typename?: 'Queries' } & {
  myFollowed: { __typename?: 'MyFollowed' } & {
    discussions: Array<
      { __typename?: 'Discussion' } & Pick<
        Discussion,
        'id' | 'isFollowing' | 'lastUpdated'
      >
    >;
  };
};

export type FollowDiscussionMutationVariables = {
  id: Scalars['ID'];
};

export type FollowDiscussionMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'follow'
>;

export type UnfollowDiscussionMutationVariables = {
  id: Scalars['ID'];
};

export type UnfollowDiscussionMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'unFollow'
>;

export type MyFollowedTopicsQueryVariables = {};

export type MyFollowedTopicsQuery = { __typename?: 'Queries' } & {
  myFollowed: { __typename?: 'MyFollowed' } & {
    topics: Array<
      { __typename?: 'Topic' } & Pick<
        Topic,
        'id' | 'isFollowing' | 'lastUpdated'
      >
    >;
  };
};

export type GetTopicsQueryVariables = {
  input: AllTopicsInput;
};

export type GetTopicsQuery = { __typename?: 'Queries' } & {
  allTopics: { __typename?: 'AllTopics' } & {
    nodes: Array<
      { __typename?: 'Topic' } & Pick<
        Topic,
        'id' | 'uri' | 'lastUpdated' | 'title' | 'dateCreated' | 'isFollowing'
      >
    >;
  };
  me: { __typename?: 'Me' } & Pick<Me, 'id'> & {
      organization: Maybe<
        { __typename?: 'Organization' } & Pick<
          Organization,
          'id' | 'name' | 'organizationImage'
        >
      >;
    };
};

export type FollowTopicMutationVariables = {
  id: Scalars['ID'];
};

export type FollowTopicMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'follow'
>;

export type UnfollowTopicMutationVariables = {
  id: Scalars['ID'];
};

export type UnfollowTopicMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'unFollow'
>;

export type UpdateDiscussionMutationVariables = {
  input: UpdateDiscussionInput;
};

export type UpdateDiscussionMutation = { __typename?: 'Mutations' } & {
  updateDiscussion: Maybe<
    { __typename?: 'Discussion' } & Pick<Discussion, 'id' | 'status'>
  >;
};

export type CreateCommentMutationVariables = {
  input: CreateCommentInput;
};

export type CreateCommentMutation = { __typename?: 'Mutations' } & {
  createComment: Maybe<{ __typename?: 'Comment' } & Pick<Comment, 'id'>>;
};

export type UpdateCommentMutationVariables = {
  input: UpdateCommentInput;
};

export type UpdateCommentMutation = { __typename?: 'Mutations' } & {
  updateComment: Maybe<{ __typename?: 'Comment' } & Pick<Comment, 'id'>>;
};

export type SetStatusMutationVariables = {
  input: SetStatusInput;
};

export type SetStatusMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'setStatus'
>;

export type FieldsOnUserFileFragment = { __typename?: 'UserFile' } & Pick<
  UserFile,
  | 'id'
  | 'name'
  | 'description'
  | 'lastUpdated'
  | 'size'
  | 'likes'
  | 'isLiked'
  | 'contentType'
> & {
    access: { __typename?: 'Access' } & Pick<Access, 'myAccess'>;
    createdBy: { __typename?: 'Person' } & Pick<
      Person,
      'id' | 'name' | 'picture'
    > & {
        organization: Maybe<
          { __typename?: 'Organization' } & Pick<
            Organization,
            'id' | 'name' | 'organizationImage' | 'url'
          >
        >;
      };
  };

export type FieldsOnAuthorFragment = { __typename?: 'Person' } & Pick<
  Person,
  'id' | 'name' | 'role' | 'picture'
> & {
    organization: Maybe<
      { __typename?: 'Organization' } & Pick<
        Organization,
        'id' | 'name' | 'organizationImage' | 'url'
      >
    >;
  };

export type FieldsOnRepliesFragment = { __typename?: 'Comment' } & Pick<
  Comment,
  | 'id'
  | 'message'
  | 'dateCreated'
  | 'lastUpdated'
  | 'status'
  | 'likes'
  | 'isLiked'
> & { author: { __typename?: 'Person' } & FieldsOnAuthorFragment };

export type FieldsOnCommentFragment = { __typename?: 'Comment' } & Pick<
  Comment,
  | 'id'
  | 'message'
  | 'dateCreated'
  | 'likes'
  | 'lastUpdated'
  | 'status'
  | 'isLiked'
> & {
    replies: Array<{ __typename?: 'Comment' } & FieldsOnRepliesFragment>;
    author: { __typename?: 'Person' } & FieldsOnAuthorFragment;
    files: Array<{ __typename?: 'UserFile' } & FieldsOnUserFileFragment>;
  };

export type FieldsOnMeSimpleFragment = { __typename?: 'Me' } & Pick<
  Me,
  'id' | 'name' | 'jobTitle' | 'picture' | 'isAdmin' | 'calendarCode'
> & {
    organization: Maybe<
      { __typename?: 'Organization' } & Pick<
        Organization,
        'id' | 'name' | 'organizationImage' | 'url'
      >
    >;
  };

export type DiscussionQueryVariables = {
  id: Scalars['ID'];
};

export type DiscussionQuery = { __typename?: 'Queries' } & {
  discussion: Maybe<
    { __typename?: 'Discussion' } & Pick<
      Discussion,
      | 'id'
      | 'title'
      | 'dateCreated'
      | 'lastUpdated'
      | 'isLiked'
      | 'uri'
      | 'likes'
      | 'description'
      | 'status'
      | 'isFollowing'
    > & {
        createdBy: { __typename?: 'Person' } & FieldsOnAuthorFragment;
        currentRevision: { __typename?: 'ActionValueRevision' } & Pick<
          ActionValueRevision,
          'id' | 'description' | 'body'
        >;
        topics: Array<
          { __typename?: 'Topic' } & Pick<Topic, 'id' | 'title' | 'uri'>
        >;
        files: Array<{ __typename?: 'UserFile' } & FieldsOnUserFileFragment>;
        comments: Array<
          { __typename?: 'Comment' } & {
            replies: Array<
              { __typename?: 'Comment' } & FieldsOnRepliesFragment
            >;
          } & FieldsOnCommentFragment
        >;
      }
  >;
  me: { __typename?: 'Me' } & FieldsOnMeSimpleFragment;
};

export type FieldsOnDiscussionFragment = { __typename?: 'Discussion' } & Pick<
  Discussion,
  | 'id'
  | 'title'
  | 'isLiked'
  | 'likes'
  | 'views'
  | 'dateCreated'
  | 'lastUpdated'
  | 'uri'
  | 'description'
  | 'status'
  | 'isFollowing'
> & {
    createdBy: { __typename?: 'Person' } & Pick<
      Person,
      'department' | 'id' | 'name' | 'picture' | 'role'
    > & {
        organization: Maybe<
          { __typename?: 'Organization' } & Pick<
            Organization,
            'id' | 'name' | 'organizationImage' | 'url'
          >
        >;
      };
    currentRevision: { __typename?: 'ActionValueRevision' } & Pick<
      ActionValueRevision,
      'id' | 'body'
    >;
    comments: Array<
      { __typename?: 'Comment' } & Pick<Comment, 'id' | 'dateCreated'>
    >;
    topics: Array<
      { __typename?: 'Topic' } & Pick<Topic, 'id' | 'title' | 'uri'> & {
          createdBy: Maybe<
            { __typename?: 'Person' } & Pick<
              Person,
              'department' | 'id' | 'name' | 'picture' | 'role'
            > & {
                organization: Maybe<
                  { __typename?: 'Organization' } & Pick<
                    Organization,
                    'id' | 'name' | 'organizationImage'
                  >
                >;
              }
          >;
        }
    >;
    followers: Array<{ __typename?: 'Person' } & Pick<Person, 'id' | 'name'>>;
    files: Array<
      { __typename?: 'UserFile' } & Pick<
        UserFile,
        | 'contentType'
        | 'id'
        | 'isLiked'
        | 'lastUpdated'
        | 'likes'
        | 'name'
        | 'description'
        | 'size'
      > & {
          createdBy: { __typename?: 'Person' } & Pick<
            Person,
            'department' | 'id' | 'name' | 'picture' | 'role'
          > & {
              organization: Maybe<
                { __typename?: 'Organization' } & Pick<
                  Organization,
                  'id' | 'name' | 'organizationImage'
                >
              >;
            };
          access: { __typename?: 'Access' } & Pick<Access, 'myAccess'>;
        }
    >;
  };

export type AllDiscussionsQueryVariables = {
  sort?: Maybe<SortEnum>;
  order?: Maybe<OrderEnum>;
  onlyMine?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  organizations?: Maybe<Array<Scalars['Long']>>;
};

export type AllDiscussionsQuery = { __typename?: 'Queries' } & {
  me: { __typename?: 'Me' } & Pick<Me, 'id'> & {
      organization: Maybe<
        { __typename?: 'Organization' } & Pick<Organization, 'id' | 'name'>
      >;
    };
  allDiscussions: { __typename?: 'AllDiscussions' } & Pick<
    AllDiscussions,
    'totalCount'
  > & {
      pageInfo: { __typename?: 'PageInfo' } & Pick<
        PageInfo,
        | 'hasNextPage'
        | 'hasPreviousPage'
        | 'itemsPerPage'
        | 'numberOfPages'
        | 'pageNumber'
      >;
      nodes: Array<{ __typename?: 'Discussion' } & FieldsOnDiscussionFragment>;
    };
};

export type CreateDiscussion2MutationVariables = {
  input: CreateDiscussionInput;
};

export type CreateDiscussion2Mutation = { __typename?: 'Mutations' } & {
  createDiscussion: Maybe<
    { __typename?: 'Discussion' } & FieldsOnDiscussionFragment
  >;
};

export type OrganizationQueryQueryVariables = {
  id: Scalars['ID'];
};

export type OrganizationQueryQuery = { __typename?: 'Queries' } & {
  organization: Maybe<
    { __typename?: 'Organization' } & Pick<
      Organization,
      'id' | 'name' | 'organizationImage'
    > & {
        members: Array<
          { __typename?: 'Person' } & Pick<
            Person,
            'id' | 'name' | 'picture' | 'role' | 'department'
          > & {
              organization: Maybe<
                { __typename?: 'Organization' } & Pick<
                  Organization,
                  'id' | 'name' | 'organizationImage' | 'url'
                >
              >;
            }
        >;
      }
  >;
};

export type ProfileQueryQueryVariables = {
  id: Scalars['ID'];
};

export type ProfileQueryQuery = { __typename?: 'Queries' } & {
  person: Maybe<
    { __typename?: 'Person' } & Pick<
      Person,
      'id' | 'name' | 'role' | 'department' | 'picture'
    > & {
        organization: Maybe<
          { __typename?: 'Organization' } & Pick<
            Organization,
            'id' | 'name' | 'organizationImage' | 'url'
          >
        >;
        kostraFunctions: Maybe<
          Array<
            { __typename?: 'KostraFunction' } & Pick<
              KostraFunction,
              'id' | 'kid' | 'title'
            >
          >
        >;
      }
  >;
  me: { __typename?: 'Me' } & Pick<Me, 'id'>;
};

export type AddTopicMutationMutationVariables = {
  input: CreateTopicInput;
};

export type AddTopicMutationMutation = { __typename?: 'Mutations' } & {
  createTopic: Maybe<
    { __typename?: 'Topic' } & Pick<
      Topic,
      'id' | 'uri' | 'title' | 'description' | 'isRestricted' | 'status'
    > & {
        currentRevision: Maybe<
          { __typename?: 'TopicRevision' } & Pick<TopicRevision, 'body'>
        >;
      }
  >;
};

export type MeCreateTopicQueryVariables = {};

export type MeCreateTopicQuery = { __typename?: 'Queries' } & {
  myFollowed: { __typename?: 'MyFollowed' } & {
    tags: Array<{ __typename?: 'Tag' } & Pick<Tag, 'id' | 'color' | 'title'>>;
  };
  me: { __typename?: 'Me' } & Pick<
    Me,
    'id' | 'name' | 'picture' | 'jobTitle' | 'department'
  > & {
      kostraFunctions: Array<
        { __typename?: 'KostraFunction' } & Pick<
          KostraFunction,
          'id' | 'title' | 'kid'
        >
      >;
      organization: Maybe<
        { __typename?: 'Organization' } & Pick<
          Organization,
          'id' | 'organizationImage' | 'name'
        >
      >;
    };
};

export type UpdateTopicMutationVariables = {
  input: UpdateTopicInput;
};

export type UpdateTopicMutation = { __typename?: 'Mutations' } & {
  updateTopic: Maybe<
    { __typename?: 'Topic' } & Pick<Topic, 'id' | 'uri'> & {
        topics: Maybe<
          Array<
            { __typename?: 'Topic' } & Pick<
              Topic,
              'id' | 'title' | 'isLimited'
            > & {
                createdBy: Maybe<
                  { __typename?: 'Person' } & Pick<
                    Person,
                    'id' | 'name' | 'department' | 'role'
                  > & {
                      organization: Maybe<
                        {
                          __typename?: 'Organization';
                        } & FieldsOnOrganizationFragment
                      >;
                    }
                >;
              }
          >
        >;
      }
  >;
};

export type FieldsOnOrganizationFragment = {
  __typename?: 'Organization';
} & Pick<Organization, 'id' | 'organizationImage' | 'name' | 'url'>;

export type ChangeOwnerMutationVariables = {
  input: ChangeOwnerInput;
};

export type ChangeOwnerMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'changeOwner'
>;

export type TopicQueryQueryVariables = {
  id: Scalars['ID'];
};

export type TopicQueryQuery = { __typename?: 'Queries' } & {
  myFollowed: { __typename?: 'MyFollowed' } & {
    tags: Array<{ __typename?: 'Tag' } & Pick<Tag, 'id' | 'title' | 'color'>>;
  };
  me: { __typename?: 'Me' } & Pick<
    Me,
    'id' | 'name' | 'picture' | 'jobTitle' | 'isAdmin' | 'department'
  > & {
      organization: Maybe<
        { __typename?: 'Organization' } & FieldsOnOrganizationFragment
      >;
    };
  topic: Maybe<
    { __typename?: 'Topic' } & Pick<
      Topic,
      | 'id'
      | 'uri'
      | 'title'
      | 'isLiked'
      | 'description'
      | 'isRestricted'
      | 'isLimited'
      | 'isFollowing'
      | 'status'
    > & {
        tags: Array<
          { __typename?: 'Tag' } & Pick<Tag, 'id' | 'color' | 'title'>
        >;
        access: { __typename?: 'Access' } & Pick<
          Access,
          'isPrivate' | 'myAccess' | 'visibility'
        > & {
            groups: Array<
              { __typename?: 'GroupAccess' } & Pick<GroupAccess, 'access'> & {
                  group: { __typename?: 'PersonGroup' } & Pick<
                    PersonGroup,
                    'id' | 'title' | 'dateCreated' | 'lastUpdated'
                  > & {
                      createdBy: { __typename?: 'Person' } & Pick<
                        Person,
                        'id' | 'name'
                      >;
                      members: Array<
                        { __typename?: 'Person' } & Pick<Person, 'name' | 'id'>
                      >;
                    };
                }
            >;
            organizationGroups: Array<
              { __typename?: 'OrganizationGroupAccess' } & Pick<
                OrganizationGroupAccess,
                'access'
              > & {
                  organizationGroup: {
                    __typename?: 'OrganizationGroup';
                  } & Pick<
                    OrganizationGroup,
                    'id' | 'lastUpdated' | 'title'
                  > & {
                      createdBy: { __typename?: 'Person' } & Pick<
                        Person,
                        'id' | 'name'
                      >;
                      members: Array<
                        { __typename?: 'Organization' } & Pick<
                          Organization,
                          'name' | 'id'
                        >
                      >;
                    };
                }
            >;
            organizations: Array<
              { __typename?: 'OrganizationAccess' } & Pick<
                OrganizationAccess,
                'access'
              > & {
                  organization: { __typename?: 'Organization' } & Pick<
                    Organization,
                    'name' | 'id' | 'organizationImage'
                  >;
                }
            >;
            persons: Array<
              { __typename?: 'PersonAccess' } & Pick<PersonAccess, 'access'> & {
                  person: { __typename?: 'Person' } & Pick<
                    Person,
                    'name' | 'id' | 'department' | 'picture' | 'role'
                  > & {
                      organization: Maybe<
                        { __typename?: 'Organization' } & Pick<
                          Organization,
                          'id' | 'name' | 'organizationImage'
                        >
                      >;
                    };
                }
            >;
          };
        createdBy: Maybe<
          { __typename?: 'Person' } & Pick<
            Person,
            'id' | 'name' | 'picture' | 'role'
          > & {
              organization: Maybe<
                { __typename?: 'Organization' } & FieldsOnOrganizationFragment
              >;
            }
        >;
        links: Array<
          { __typename?: 'Link' } & Pick<Link, 'id' | 'label' | 'href'> & {
              access: { __typename?: 'Access' } & Pick<Access, 'myAccess'>;
              createdBy: { __typename?: 'Person' } & FieldsOnAuthorFragment;
            }
        >;
        calendars: Maybe<
          Array<
            { __typename?: 'Calendar' } & Pick<
              Calendar,
              | 'id'
              | 'title'
              | 'uri'
              | 'description'
              | 'color'
              | 'status'
              | 'category'
              | 'lastUpdated'
              | 'dateCreated'
              | 'isFollowing'
              | 'isRestricted'
              | 'isLimited'
              | 'isLiked'
              | 'likes'
            > & {
                access: { __typename?: 'Access' } & Pick<Access, 'myAccess'>;
                followers: Array<
                  { __typename?: 'Person' } & Pick<Person, 'id'>
                >;
                topics: Array<
                  { __typename?: 'Topic' } & Pick<
                    Topic,
                    | 'id'
                    | 'uri'
                    | 'title'
                    | 'likes'
                    | 'isLiked'
                    | 'isFollowing'
                    | 'isLimited'
                    | 'isRestricted'
                  >
                >;
                createdBy: { __typename?: 'Person' } & Pick<
                  Person,
                  'id' | 'name' | 'picture' | 'role'
                > & {
                    organization: Maybe<
                      {
                        __typename?: 'Organization';
                      } & FieldsOnOrganizationFragment
                    >;
                  };
              }
          >
        >;
        currentRevision: Maybe<
          { __typename?: 'TopicRevision' } & Pick<
            TopicRevision,
            'id' | 'body' | 'category'
          > & {
              sector: Maybe<
                { __typename?: 'Sector' } & Pick<Sector, 'id' | 'title'>
              >;
              kostraFunctions: Maybe<
                Array<
                  { __typename?: 'KostraFunction' } & Pick<
                    KostraFunction,
                    'id' | 'kid' | 'title'
                  >
                >
              >;
            }
        >;
        followers: Maybe<
          Array<
            { __typename?: 'Person' } & Pick<
              Person,
              'id' | 'name' | 'picture' | 'role'
            > & {
                organization: Maybe<
                  { __typename?: 'Organization' } & FieldsOnOrganizationFragment
                >;
              }
          >
        >;
        actionValues: Maybe<
          Array<{ __typename?: 'ActionValue' } & FieldsOnActionValueFragment>
        >;
        discussions: Maybe<
          Array<
            { __typename?: 'Discussion' } & Pick<
              Discussion,
              | 'id'
              | 'title'
              | 'isLiked'
              | 'likes'
              | 'views'
              | 'dateCreated'
              | 'lastUpdated'
              | 'uri'
              | 'description'
              | 'status'
              | 'isFollowing'
            > & {
                createdBy: { __typename?: 'Person' } & Pick<
                  Person,
                  'id' | 'name' | 'department' | 'role' | 'picture'
                > & {
                    organization: Maybe<
                      {
                        __typename?: 'Organization';
                      } & FieldsOnOrganizationFragment
                    >;
                  };
                currentRevision: { __typename?: 'ActionValueRevision' } & Pick<
                  ActionValueRevision,
                  'id' | 'body'
                >;
                comments: Array<
                  { __typename?: 'Comment' } & Pick<Comment, 'dateCreated'>
                >;
                topics: Array<
                  { __typename?: 'Topic' } & Pick<
                    Topic,
                    'id' | 'isFollowing' | 'title' | 'isLimited'
                  > & {
                      createdBy: Maybe<
                        { __typename?: 'Person' } & Pick<
                          Person,
                          'id' | 'name' | 'department' | 'role'
                        > & {
                            organization: Maybe<
                              {
                                __typename?: 'Organization';
                              } & FieldsOnOrganizationFragment
                            >;
                          }
                      >;
                    }
                >;
                files: Array<
                  { __typename?: 'UserFile' } & Pick<
                    UserFile,
                    | 'id'
                    | 'name'
                    | 'description'
                    | 'lastUpdated'
                    | 'size'
                    | 'isLiked'
                    | 'likes'
                    | 'contentType'
                  > & {
                      createdBy: { __typename?: 'Person' } & Pick<
                        Person,
                        'id' | 'name' | 'department' | 'picture' | 'role'
                      > & {
                          organization: Maybe<
                            {
                              __typename?: 'Organization';
                            } & FieldsOnOrganizationFragment
                          >;
                        };
                      access: { __typename?: 'Access' } & Pick<
                        Access,
                        'myAccess'
                      >;
                    }
                >;
                followers: Array<
                  { __typename?: 'Person' } & Pick<Person, 'id'>
                >;
              }
          >
        >;
        files: Maybe<
          Array<
            { __typename?: 'UserFile' } & Pick<
              UserFile,
              | 'id'
              | 'name'
              | 'description'
              | 'lastUpdated'
              | 'size'
              | 'isLiked'
              | 'likes'
              | 'contentType'
            > & {
                access: { __typename?: 'Access' } & Pick<Access, 'myAccess'>;
                createdBy: { __typename?: 'Person' } & Pick<
                  Person,
                  'id' | 'name' | 'picture' | 'role'
                > & {
                    organization: Maybe<
                      {
                        __typename?: 'Organization';
                      } & FieldsOnOrganizationFragment
                    >;
                  };
              }
          >
        >;
        topics: Maybe<
          Array<
            { __typename?: 'Topic' } & Pick<
              Topic,
              | 'id'
              | 'isFollowing'
              | 'title'
              | 'lastUpdated'
              | 'uri'
              | 'description'
              | 'likes'
              | 'isLiked'
              | 'isRestricted'
              | 'isLimited'
            > & {
                access: { __typename?: 'Access' } & Pick<
                  Access,
                  'visibility' | 'isPrivate' | 'myAccess'
                > & {
                    groups: Array<
                      { __typename?: 'GroupAccess' } & Pick<
                        GroupAccess,
                        'access'
                      > & {
                          group: { __typename?: 'PersonGroup' } & Pick<
                            PersonGroup,
                            'id' | 'title' | 'dateCreated' | 'lastUpdated'
                          > & {
                              createdBy: { __typename?: 'Person' } & Pick<
                                Person,
                                'id' | 'name'
                              >;
                              members: Array<
                                { __typename?: 'Person' } & Pick<
                                  Person,
                                  'name' | 'id'
                                >
                              >;
                            };
                        }
                    >;
                    organizationGroups: Array<
                      { __typename?: 'OrganizationGroupAccess' } & Pick<
                        OrganizationGroupAccess,
                        'access'
                      > & {
                          organizationGroup: {
                            __typename?: 'OrganizationGroup';
                          } & Pick<
                            OrganizationGroup,
                            'id' | 'lastUpdated' | 'title'
                          > & {
                              createdBy: { __typename?: 'Person' } & Pick<
                                Person,
                                'id' | 'name'
                              >;
                              members: Array<
                                { __typename?: 'Organization' } & Pick<
                                  Organization,
                                  'name' | 'id'
                                >
                              >;
                            };
                        }
                    >;
                    organizations: Array<
                      { __typename?: 'OrganizationAccess' } & Pick<
                        OrganizationAccess,
                        'access'
                      > & {
                          organization: { __typename?: 'Organization' } & Pick<
                            Organization,
                            'name' | 'id'
                          >;
                        }
                    >;
                    persons: Array<
                      { __typename?: 'PersonAccess' } & Pick<
                        PersonAccess,
                        'access'
                      > & {
                          person: { __typename?: 'Person' } & Pick<
                            Person,
                            'name' | 'id' | 'department' | 'picture' | 'role'
                          > & {
                              organization: Maybe<
                                { __typename?: 'Organization' } & Pick<
                                  Organization,
                                  'id' | 'name' | 'organizationImage'
                                >
                              >;
                            };
                        }
                    >;
                  };
                createdBy: Maybe<
                  { __typename?: 'Person' } & Pick<
                    Person,
                    'id' | 'name' | 'picture' | 'role'
                  > & {
                      organization: Maybe<
                        {
                          __typename?: 'Organization';
                        } & FieldsOnOrganizationFragment
                      >;
                    }
                >;
                followers: Maybe<
                  Array<{ __typename?: 'Person' } & Pick<Person, 'id' | 'name'>>
                >;
                currentRevision: Maybe<
                  { __typename?: 'TopicRevision' } & Pick<
                    TopicRevision,
                    'id' | 'category'
                  >
                >;
              }
          >
        >;
      }
  >;
};

export type FieldsOnTopicTopicFragment = { __typename?: 'Topic' } & Pick<
  Topic,
  | 'id'
  | 'uri'
  | 'title'
  | 'description'
  | 'isRestricted'
  | 'isLimited'
  | 'status'
  | 'dateCreated'
  | 'lastUpdated'
  | 'isLiked'
  | 'isFollowing'
>;

export type FieldsOnTopicFragment = { __typename?: 'Topic' } & Pick<
  Topic,
  | 'id'
  | 'uri'
  | 'title'
  | 'description'
  | 'isRestricted'
  | 'isLimited'
  | 'status'
  | 'lastUpdated'
  | 'likes'
  | 'isFollowing'
  | 'isLiked'
> & {
    topics: Maybe<Array<{ __typename?: 'Topic' } & FieldsOnTopicTopicFragment>>;
    currentRevision: Maybe<
      { __typename?: 'TopicRevision' } & Pick<
        TopicRevision,
        'id' | 'body' | 'description' | 'category'
      >
    >;
    followers: Maybe<Array<{ __typename?: 'Person' } & Pick<Person, 'id'>>>;
    createdBy: Maybe<
      { __typename?: 'Person' } & Pick<
        Person,
        'id' | 'name' | 'department' | 'picture' | 'role'
      > & {
          organization: Maybe<
            { __typename?: 'Organization' } & Pick<
              Organization,
              'id' | 'name' | 'organizationImage'
            >
          >;
        }
    >;
  };

export type AllTopicsAndMeQueryTQueryVariables = {
  category?: Maybe<TopicCategorySortEnum>;
  sort?: Maybe<SortEnum>;
  order?: Maybe<OrderEnum>;
  status?: Maybe<ContentStatus>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  kostraGroup?: Maybe<Scalars['Long']>;
  organizations?: Maybe<Array<Scalars['Long']>>;
  onlyMine?: Maybe<Scalars['Boolean']>;
  persons?: Maybe<Array<Scalars['ID']>>;
};

export type AllTopicsAndMeQueryTQuery = { __typename?: 'Queries' } & {
  allKostraFunctionGroups: Array<
    { __typename?: 'KostraFunctionGroup' } & Pick<
      KostraFunctionGroup,
      'id' | 'title'
    >
  >;
  me: { __typename?: 'Me' } & Pick<
    Me,
    'id' | 'name' | 'isAdmin' | 'userRoles' | 'permissions' | 'language'
  > & {
      organization: Maybe<
        { __typename?: 'Organization' } & Pick<Organization, 'id' | 'name'>
      >;
    };
  allSectors: Array<{ __typename?: 'Sector' } & Pick<Sector, 'id' | 'title'>>;
  allTopics: { __typename?: 'AllTopics' } & Pick<AllTopics, 'totalCount'> & {
      pageInfo: { __typename?: 'PageInfo' } & Pick<
        PageInfo,
        | 'hasNextPage'
        | 'hasPreviousPage'
        | 'itemsPerPage'
        | 'numberOfPages'
        | 'pageNumber'
      >;
      nodes: Array<{ __typename?: 'Topic' } & FieldsOnTopicFragment>;
    };
};

export type GrantsTestQueryQueryVariables = {};

export type GrantsTestQueryQuery = { __typename?: 'Queries' } & {
  allActionValues: { __typename?: 'AllActionValues' } & {
    nodes: Array<
      { __typename?: 'ActionValue' } & Pick<ActionValue, 'id' | 'title'>
    >;
  };
};

export type LikeMutationVariables = {
  id: Scalars['ID'];
};

export type LikeMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'like'
>;

export type UnLikeMutationVariables = {
  id: Scalars['ID'];
};

export type UnLikeMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'unLike'
>;

export type FieldsOnKostraFunctionGroup = FieldsOnKostraFunctionGroupFragment;
export type GetKostraFunctionsVariables = GetKostraFunctionsQueryVariables;
export type GetKostraFunctionsAllKostraFunctions = FieldsOnKostraFunctionFragment;
export type MyGetKostraFunctionsVariables = MyGetKostraFunctionsQueryVariables;
export type MyGetKostraFunctionsMe = MyGetKostraFunctionsQuery['me'];
export type MyGetKostraFunctionsKostraFunctions = MyGetKostraFunctionsQuery['me']['kostraFunctions'][0];
export type FindPersonsOrganizationsAndGroupsVariables = FindPersonsOrganizationsAndGroupsQueryVariables;
export type FindPersonsOrganizationsAndGroupsSearch = FindPersonsOrganizationsAndGroupsQuery['search'];
export type FindPersonsOrganizationsAndGroupsPersons = FindPersonsOrganizationsAndGroupsQuery['search']['persons'][0];
export type FindPersonsOrganizationsAndGroupsOrganization = FindPersonsOrganizationsAndGroupsQuery['search']['persons'][0]['organization'];
export type FindPersonsOrganizationsAndGroupsGroups = FindPersonsOrganizationsAndGroupsQuery['search']['groups'][0];
export type FindPersonsOrganizationsAndGroupsCreatedBy = FindPersonsOrganizationsAndGroupsQuery['search']['groups'][0]['createdBy'];
export type FindPersonsOrganizationsAndGroupsMembers = FindPersonsOrganizationsAndGroupsQuery['search']['groups'][0]['members'][0];
export type FindPersonsOrganizationsAndGroupsOrganizationGroups = FindPersonsOrganizationsAndGroupsQuery['search']['organizationGroups'][0];
export type FindPersonsOrganizationsAndGroups_CreatedBy = FindPersonsOrganizationsAndGroupsQuery['search']['organizationGroups'][0]['createdBy'];
export type FindPersonsOrganizationsAndGroups_Members = FindPersonsOrganizationsAndGroupsQuery['search']['organizationGroups'][0]['members'][0];
export type FindPersonsOrganizationsAndGroupsOrganizations = FindPersonsOrganizationsAndGroupsQuery['search']['organizations'][0];
export type FindPersonsVariables = FindPersonsQueryVariables;
export type FindPersonsSearch = FindPersonsQuery['search'];
export type FindPersonsPersons = FindPersonsQuery['search']['persons'][0];
export type FindPersonsOrganization = FindPersonsQuery['search']['persons'][0]['organization'];
export type GetMeForTagsVariables = GetMeForTagsQueryVariables;
export type GetMeForTagsMe = GetMeForTagsQuery['me'];
export type AddCalendarMutationVariables = AddCalendarMutationMutationVariables;
export type AddCalendarMutationCreateCalendar = AddCalendarMutationMutation['createCalendar'];
export type AddCalendarMutationTags = AddCalendarMutationMutation['createCalendar']['tags'][0];
export type AddCalendarMutationAccess = AddCalendarMutationMutation['createCalendar']['access'];
export type AddCalendarMutationPersons = AddCalendarMutationMutation['createCalendar']['access']['persons'][0];
export type AddCalendarMutationPerson = AddCalendarMutationMutation['createCalendar']['access']['persons'][0]['person'];
export type AddCalendarMutationCreatedBy = AddCalendarMutationMutation['createCalendar']['createdBy'];
export type GetMeVariables = GetMeQueryVariables;
export type GetMeMe = GetMeQuery['me'];
export type GetMeSettings = GetMeQuery['me']['settings'];
export type GetMeOrganization = GetMeQuery['me']['organization'];
export type GetMeMyFollowed = GetMeQuery['myFollowed'];
export type GetMeTags = GetMeQuery['myFollowed']['tags'][0];
export type AddTaskCollectionMutationVariables = AddTaskCollectionMutationMutationVariables;
export type AddTaskCollectionMutationCreateTask = AddTaskCollectionMutationMutation['createTask'];
export type AddTaskCollectionMutationEvents = AddTaskCollectionMutationMutation['createTask']['events'][0];
export type FieldsOnPersonOrganization = FieldsOnPersonFragment['organization'];
export type GetAnnualCycleQueryVariables = GetAnnualCycleQueryQueryVariables;
export type GetAnnualCycleQueryMyFollowed = GetAnnualCycleQueryQuery['myFollowed'];
export type GetAnnualCycleQueryTags = GetAnnualCycleQueryQuery['myFollowed']['tags'][0];
export type GetAnnualCycleQueryCalendar = GetAnnualCycleQueryQuery['calendar'];
export type GetAnnualCycleQuery_Tags = GetAnnualCycleQueryQuery['calendar']['tags'][0];
export type GetAnnualCycleQueryTopics = GetAnnualCycleQueryQuery['calendar']['topics'][0];
export type GetAnnualCycleQueryKostraFunctions = FieldsOnKostraFunctionFragment;
export type GetAnnualCycleQueryActionValues = GetAnnualCycleQueryQuery['calendar']['actionValues'][0];
export type GetAnnualCycleQueryDiscussions = GetAnnualCycleQueryQuery['calendar']['discussions'][0];
export type GetAnnualCycleQueryAccess = GetAnnualCycleQueryQuery['calendar']['access'];
export type GetAnnualCycleQueryGroups = GetAnnualCycleQueryQuery['calendar']['access']['groups'][0];
export type GetAnnualCycleQueryGroup = GetAnnualCycleQueryQuery['calendar']['access']['groups'][0]['group'];
export type GetAnnualCycleQueryCreatedBy = GetAnnualCycleQueryQuery['calendar']['access']['groups'][0]['group']['createdBy'];
export type GetAnnualCycleQueryMembers = GetAnnualCycleQueryQuery['calendar']['access']['groups'][0]['group']['members'][0];
export type GetAnnualCycleQueryOrganizationGroups = GetAnnualCycleQueryQuery['calendar']['access']['organizationGroups'][0];
export type GetAnnualCycleQueryOrganizationGroup = GetAnnualCycleQueryQuery['calendar']['access']['organizationGroups'][0]['organizationGroup'];
export type GetAnnualCycleQuery_CreatedBy = GetAnnualCycleQueryQuery['calendar']['access']['organizationGroups'][0]['organizationGroup']['createdBy'];
export type GetAnnualCycleQuery_Members = GetAnnualCycleQueryQuery['calendar']['access']['organizationGroups'][0]['organizationGroup']['members'][0];
export type GetAnnualCycleQueryOrganizations = GetAnnualCycleQueryQuery['calendar']['access']['organizations'][0];
export type GetAnnualCycleQueryOrganization = GetAnnualCycleQueryQuery['calendar']['access']['organizations'][0]['organization'];
export type GetAnnualCycleQueryPersons = GetAnnualCycleQueryQuery['calendar']['access']['persons'][0];
export type GetAnnualCycleQueryPerson = GetAnnualCycleQueryQuery['calendar']['access']['persons'][0]['person'];
export type GetAnnualCycleQuery_Organization = GetAnnualCycleQueryQuery['calendar']['access']['persons'][0]['person']['organization'];
export type GetAnnualCycleQuery__CreatedBy = FieldsOnPersonFragment;
export type GetAnnualCycleQueryMe = GetAnnualCycleQueryQuery['me'];
export type SearchTopicVariables = SearchTopicQueryVariables;
export type SearchTopicSearch = SearchTopicQuery['search'];
export type SearchTopicTopics = SearchTopicQuery['search']['topics'][0];
export type UpdateCalendarVariables = UpdateCalendarMutationVariables;
export type UpdateCalendarUpdateCalendar = UpdateCalendarMutation['updateCalendar'];
export type UpdateCalendarAccess = UpdateCalendarMutation['updateCalendar']['access'];
export type UpdateCalendarPersons = UpdateCalendarMutation['updateCalendar']['access']['persons'][0];
export type UpdateCalendarPerson = FieldsOnPersonFragment;
export type UpdateCalendarCreatedBy = FieldsOnPersonFragment;
export type AddToRelatedVariables = AddToRelatedMutationVariables;
export type AddToRelatedAddToRelated = AddToRelatedMutation['addToRelated'][0];
export type RemoveRelatedVariables = RemoveRelatedMutationVariables;
export type RemoveRelatedRemoveFromRelated = RemoveRelatedMutation['removeFromRelated'][0];
export type CopyCalendarMutationVariables = CopyCalendarMutationMutationVariables;
export type ForkCalendarMutationVariables = ForkCalendarMutationMutationVariables;
export type UpdateInstanceStatusVariables = UpdateInstanceStatusMutationVariables;
export type UpdateInstanceStatusUpdateEventInstance = UpdateInstanceStatusMutation['updateEventInstance'];
export type SetStatusFilesVariables = SetStatusFilesMutationVariables;
export type UpdateEventInstanceVariables = UpdateEventInstanceMutationVariables;
export type UpdateEventInstanceUpdateEventInstance = UpdateEventInstanceMutation['updateEventInstance'];
export type UpdateEventInstanceTags = UpdateEventInstanceMutation['updateEventInstance']['tags'][0];
export type UpdateEventInstanceFiles = UpdateEventInstanceMutation['updateEventInstance']['files'][0];
export type UpdateEventInstanceAccess = UpdateEventInstanceMutation['updateEventInstance']['files'][0]['access'];
export type UpdateEventInstanceAssignedBy = FieldsOnMiniPersonFragment;
export type UpdateEventInstanceResponsible = FieldsOnMiniPersonFragment;
export type UpdateEventInstanceCreatedBy = FieldsOnMiniPersonFragment;
export type UpdateEventInstanceTodos = UpdateEventInstanceMutation['updateEventInstance']['todos'][0];
export type CreateTodoVariables = CreateTodoMutationVariables;
export type CreateTodoCreateTodoInstance = CreateTodoMutation['createTodoInstance'];
export type UpdateTodoVariables = UpdateTodoMutationVariables;
export type UpdateTodoUpdateTodoInstance = UpdateTodoMutation['updateTodoInstance'];
export type UpdateTodoTemplateVariables = UpdateTodoTemplateMutationVariables;
export type UpdateTodoTemplateUpdateTodoTemplate = UpdateTodoTemplateMutation['updateTodoTemplate'];
export type CreateTodoTemplateVariables = CreateTodoTemplateMutationVariables;
export type CreateTodoTemplateCreateTodoTemplate = CreateTodoTemplateMutation['createTodoTemplate'];
export type UpdateEventVariables = UpdateEventMutationVariables;
export type UpdateEventUpdateEvent = UpdateEventMutation['updateEvent'];
export type UpdateEventResponsible = FieldsOnMiniPersonFragment;
export type UpdateEventTodoTemplate = UpdateEventMutation['updateEvent']['todoTemplate'][0];
export type GetTaskVariables = GetTaskQueryVariables;
export type GetTaskTask = GetTaskQuery['task'];
export type GetTaskCalendars = FieldsOnCalendarLookupFragment;
export type GetTaskMe = GetTaskQuery['me'];
export type FieldsOnEventExtendedForkedFrom = FieldsOnEventExtendedFragment['forkedFrom'];
export type FieldsOnEventExtendedTags = FieldsOnEventExtendedFragment['tags'][0];
export type FieldsOnEventExtendedFiles = FieldsOnEventExtendedFragment['files'][0];
export type FieldsOnEventExtendedAccess = FieldsOnEventExtendedFragment['files'][0]['access'];
export type FieldsOnEventExtendedAssignedBy = FieldsOnMiniPersonFragment;
export type FieldsOnEventExtendedResponsible = FieldsOnMiniPersonFragment;
export type FieldsOnEventExtendedTodoTemplate = FieldsOnEventExtendedFragment['todoTemplate'][0];
export type FieldsOnEventExtendedInstances = FieldsOnEventExtendedFragment['instances'][0];
export type FieldsOnEventExtended_ForkedFrom = FieldsOnEventExtendedFragment['instances'][0]['forkedFrom'];
export type FieldsOnEventExtendedForks = FieldsOnEventExtendedFragment['instances'][0]['forks'][0];
export type FieldsOnEventExtended__ForkedFrom = FieldsOnEventExtendedFragment['instances'][0]['forks'][0]['forkedFrom'];
export type FieldsOnEventExtended_Tags = FieldsOnEventExtendedFragment['instances'][0]['forks'][0]['tags'][0];
export type FieldsOnEventExtended_Files = FieldsOnEventExtendedFragment['instances'][0]['forks'][0]['files'][0];
export type FieldsOnEventExtended_Access = FieldsOnEventExtendedFragment['instances'][0]['forks'][0]['files'][0]['access'];
export type FieldsOnEventExtended_AssignedBy = FieldsOnMiniPersonFragment;
export type FieldsOnEventExtended_Responsible = FieldsOnMiniPersonFragment;
export type FieldsOnEventExtendedCreatedBy = FieldsOnMiniPersonFragment;
export type FieldsOnEventExtendedTodos = FieldsOnEventExtendedFragment['instances'][0]['forks'][0]['todos'][0];
export type FieldsOnEventExtended__Tags = FieldsOnEventExtendedFragment['instances'][0]['tags'][0];
export type FieldsOnEventExtended__Files = FieldsOnEventExtendedFragment['instances'][0]['files'][0];
export type FieldsOnEventExtended__Access = FieldsOnEventExtendedFragment['instances'][0]['files'][0]['access'];
export type FieldsOnEventExtended__AssignedBy = FieldsOnMiniPersonFragment;
export type FieldsOnEventExtended__Responsible = FieldsOnMiniPersonFragment;
export type FieldsOnEventExtended_CreatedBy = FieldsOnMiniPersonFragment;
export type FieldsOnEventExtended_Todos = FieldsOnEventExtendedFragment['instances'][0]['todos'][0];
export type UpdateTaskVariables = UpdateTaskMutationVariables;
export type UpdateTaskUpdateTask = UpdateTaskMutation['updateTask'];
export type UpdateTaskCalendars = FieldsOnCalendarLookupFragment;
export type GetTaskForInvalidVariables = GetTaskForInvalidQueryVariables;
export type GetTaskForInvalidTask = GetTaskForInvalidQuery['task'];
export type GetTaskForInvalidCalendars = FieldsOnCalendarLookupFragment;
export type GetTaskForInvalidEvents = FieldsOnEventFragment;
export type TopicClapVariables = TopicClapQueryVariables;
export type TopicClapTopic = TopicClapQuery['topic'];
export type GetInstancesPreviewVariables = GetInstancesPreviewQueryVariables;
export type GetInstancesPreviewCalendar = GetInstancesPreviewQuery['calendar'];
export type GetInstancesPreviewTasks = GetInstancesPreviewQuery['calendar']['tasks'][0];
export type GetInstancesPreviewEvents = GetInstancesPreviewQuery['calendar']['tasks'][0]['events'][0];
export type GetInstancesPreviewInstances = GetInstancesPreviewQuery['calendar']['tasks'][0]['events'][0]['instances'][0];
export type GetInstancesPreviewResponsible = GetInstancesPreviewQuery['calendar']['tasks'][0]['events'][0]['instances'][0]['responsible'];
export type GetInstancesPreviewCreatedBy = GetInstancesPreviewQuery['calendar']['tasks'][0]['events'][0]['instances'][0]['createdBy'];
export type SearchPersonsVariables = SearchPersonsQueryVariables;
export type SearchPersonsSearch = SearchPersonsQuery['search'];
export type SearchPersonsPersons = SearchPersonsQuery['search']['persons'][0];
export type SearchPersonsOrganization = SearchPersonsQuery['search']['persons'][0]['organization'];
export type GetFileInfoQueryVariables = GetFileInfoQueryQueryVariables;
export type GetFileInfoQueryFileInfo = GetFileInfoQueryQuery['fileInfo'];
export type UpdateFileDescriptionVariables = UpdateFileDescriptionMutationVariables;
export type UpdateFileDescriptionUpdateFileInfo = UpdateFileDescriptionMutation['updateFileInfo'];
export type DeleteFileMutationVariables = DeleteFileMutationMutationVariables;
export type CreatePersonGroupVariables = CreatePersonGroupMutationVariables;
export type CreatePersonGroupCreatePersonGroup = CreatePersonGroupMutation['createPersonGroup'];
export type CreateOrgGroupVariables = CreateOrgGroupMutationVariables;
export type CreateOrgGroupCreateOrganizationGroup = CreateOrgGroupMutation['createOrganizationGroup'];
export type UpdatePersonGroupVariables = UpdatePersonGroupMutationVariables;
export type UpdatePersonGroupUpdatePersonGroup = UpdatePersonGroupMutation['updatePersonGroup'];
export type UpdateOrgGroupVariables = UpdateOrgGroupMutationVariables;
export type UpdateOrgGroupUpdateOrganizationGroup = UpdateOrgGroupMutation['updateOrganizationGroup'];
export type AllGroupsQueryVariables = AllGroupsQueryQueryVariables;
export type AllGroupsQueryAllPersonGroups = AllGroupsQueryQuery['allPersonGroups'][0];
export type AllGroupsQueryCreatedBy = AllGroupsQueryQuery['allPersonGroups'][0]['createdBy'];
export type AllGroupsQueryOrganization = AllGroupsQueryQuery['allPersonGroups'][0]['createdBy']['organization'];
export type AllGroupsQueryTopics = AllGroupsQueryQuery['allPersonGroups'][0]['topics'][0];
export type AllGroupsQueryActionValues = AllGroupsQueryQuery['allPersonGroups'][0]['actionValues'][0];
export type AllGroupsQueryCalendars = AllGroupsQueryQuery['allPersonGroups'][0]['calendars'][0];
export type AllGroupsQueryFiles = AllGroupsQueryQuery['allPersonGroups'][0]['files'][0];
export type AllGroupsQueryDiscussions = AllGroupsQueryQuery['allPersonGroups'][0]['discussions'][0];
export type AllGroupsQueryMembers = AllGroupsQueryQuery['allPersonGroups'][0]['members'][0];
export type AllGroupsQueryAllOrganizationGroups = AllGroupsQueryQuery['allOrganizationGroups'][0];
export type AllGroupsQuery_CreatedBy = AllGroupsQueryQuery['allOrganizationGroups'][0]['createdBy'];
export type AllGroupsQuery_Organization = AllGroupsQueryQuery['allOrganizationGroups'][0]['createdBy']['organization'];
export type AllGroupsQuery_Topics = AllGroupsQueryQuery['allOrganizationGroups'][0]['topics'][0];
export type AllGroupsQuery_ActionValues = AllGroupsQueryQuery['allOrganizationGroups'][0]['actionValues'][0];
export type AllGroupsQuery_Calendars = AllGroupsQueryQuery['allOrganizationGroups'][0]['calendars'][0];
export type AllGroupsQuery_Files = AllGroupsQueryQuery['allOrganizationGroups'][0]['files'][0];
export type AllGroupsQuery_Discussions = AllGroupsQueryQuery['allOrganizationGroups'][0]['discussions'][0];
export type AllGroupsQuery_Members = AllGroupsQueryQuery['allOrganizationGroups'][0]['members'][0];
export type RelationQueryVariables = RelationQueryQueryVariables;
export type RelationQuerySearch = RelationQueryQuery['search'];
export type RelationQueryTopics = RelationQueryQuery['search']['topics'][0];
export type RelationQueryActionValues = RelationQueryQuery['search']['actionValues'][0];
export type RelationQueryDiscussions = RelationQueryQuery['search']['discussions'][0];
export type RelationQueryCalendars = RelationQueryQuery['search']['calendars'][0];
export type GetLocaleVariables = GetLocaleQueryVariables;
export type GetLocaleMe = GetLocaleQuery['me'];
export type GetLocaleKostraFunctions = GetLocaleQuery['me']['kostraFunctions'][0];
export type UpdateLocaleVariables = UpdateLocaleMutationVariables;
export type UpdateLocaleUpdateMe = UpdateLocaleMutation['updateMe'];
export type AllCalendarsForSidebarVariables = AllCalendarsForSidebarQueryVariables;
export type AllCalendarsForSidebarMyFollowed = AllCalendarsForSidebarQuery['myFollowed'];
export type AllCalendarsForSidebarCalendars = AllCalendarsForSidebarQuery['myFollowed']['calendars'][0];
export type AllDiscussionsForSidebarVariables = AllDiscussionsForSidebarQueryVariables;
export type AllDiscussionsForSidebarMyFollowed = AllDiscussionsForSidebarQuery['myFollowed'];
export type AllDiscussionsForSidebarDiscussions = AllDiscussionsForSidebarQuery['myFollowed']['discussions'][0];
export type AllDiscussionsForSidebarCurrentRevision = AllDiscussionsForSidebarQuery['myFollowed']['discussions'][0]['currentRevision'];
export type AllActionValueForSidebarVariables = AllActionValueForSidebarQueryVariables;
export type AllActionValueForSidebarMyFollowed = AllActionValueForSidebarQuery['myFollowed'];
export type AllActionValueForSidebarActionValues = AllActionValueForSidebarQuery['myFollowed']['actionValues'][0];
export type AllActionValueForSidebarCurrentRevision = AllActionValueForSidebarQuery['myFollowed']['actionValues'][0]['currentRevision'];
export type AllTopicsForSidebarVariables = AllTopicsForSidebarQueryVariables;
export type AllTopicsForSidebarMyFollowed = AllTopicsForSidebarQuery['myFollowed'];
export type AllTopicsForSidebarTopics = AllTopicsForSidebarQuery['myFollowed']['topics'][0];
export type ShareVariables = ShareMutationVariables;
export type OmniSearchQueryVariables = OmniSearchQueryQueryVariables;
export type OmniSearchQuerySearch = OmniSearchQueryQuery['search'];
export type OmniSearchQueryPersons = OmniSearchQueryQuery['search']['persons'][0];
export type OmniSearchQueryOrganization = OmniSearchQueryQuery['search']['persons'][0]['organization'];
export type OmniSearchQueryOrganizations = OmniSearchQueryQuery['search']['organizations'][0];
export type OmniSearchQueryActionValues = OmniSearchQueryQuery['search']['actionValues'][0];
export type OmniSearchQueryDiscussions = OmniSearchQueryQuery['search']['discussions'][0];
export type OmniSearchQueryCreatedBy = OmniSearchQueryQuery['search']['discussions'][0]['createdBy'];
export type OmniSearchQuery_Organization = OmniSearchQueryQuery['search']['discussions'][0]['createdBy']['organization'];
export type OmniSearchQueryTopics = OmniSearchQueryQuery['search']['topics'][0];
export type OmniSearchQuery_CreatedBy = OmniSearchQueryQuery['search']['topics'][0]['createdBy'];
export type OmniSearchQuery__Organization = OmniSearchQueryQuery['search']['topics'][0]['createdBy']['organization'];
export type OmniSearchQueryCalendars = OmniSearchQueryQuery['search']['calendars'][0];
export type OmniSearchQuery__CreatedBy = OmniSearchQueryQuery['search']['calendars'][0]['createdBy'];
export type OmniSearchQuery___Organization = OmniSearchQueryQuery['search']['calendars'][0]['createdBy']['organization'];
export type GetApiKeysVariables = GetApiKeysQueryVariables;
export type GetApiKeysApiKeys = GetApiKeysQuery['apiKeys'][0];
export type RevokeApiKeyMutationVariables = RevokeApiKeyMutationMutationVariables;
export type NewApiKeyVariables = NewApiKeyMutationVariables;
export type NewApiKeyCreateApiKey = NewApiKeyMutation['createApiKey'];
export type GetLoginsVariables = GetLoginsQueryVariables;
export type GetLoginsMe = GetLoginsQuery['me'];
export type GetLoginsLogins = GetLoginsQuery['me']['logins'][0];
export type RevokeRefreshTokenMutationVariables = RevokeRefreshTokenMutationMutationVariables;
export type FieldsOnMeOrganization = FieldsOnMeFragment['organization'];
export type FieldsOnMeSettings = FieldsOnMeFragment['settings'];
export type FieldsOnMeKostraFunctions = FieldsOnMeFragment['kostraFunctions'][0];
export type GetUserDataVariables = GetUserDataQueryVariables;
export type GetUserDataMe = FieldsOnMeFragment;
export type GetUserWizardDataVariables = GetUserWizardDataQueryVariables;
export type GetUserWizardDataMe = FieldsOnMeFragment;
export type GetUserWizardDataAllKostraFunctionGroups = GetUserWizardDataQuery['allKostraFunctionGroups'][0];
export type GetUserWizardDataKostraFunctions = GetUserWizardDataQuery['allKostraFunctionGroups'][0]['kostraFunctions'][0];
export type UpdateMeVariables = UpdateMeMutationVariables;
export type UpdateMeUpdateMe = FieldsOnMeFragment;
export type UpdateMeSettingsVariables = UpdateMeSettingsMutationVariables;
export type UpdateMeSettingsUpdateSettings = FieldsOnMeFragment;
export type RelationQueryActionValueVariables = RelationQueryActionValueQueryVariables;
export type RelationQueryActionValueSearch = RelationQueryActionValueQuery['search'];
export type RelationQueryActionValueActionValues = RelationQueryActionValueQuery['search']['actionValues'][0];
export type DeleteMetaVariables = DeleteMetaMutationVariables;
export type CreateLinkVariables = CreateLinkMutationVariables;
export type CreateLinkCreateLink = CreateLinkMutation['createLink'];
export type UpdateLinkVariables = UpdateLinkMutationVariables;
export type UpdateLinkUpdateLink = UpdateLinkMutation['updateLink'];
export type SearchQueryCalendarsVariables = SearchQueryCalendarsQueryVariables;
export type SearchQueryCalendarsSearch = SearchQueryCalendarsQuery['search'];
export type SearchQueryCalendarsCalendars = SearchQueryCalendarsQuery['search']['calendars'][0];
export type AllKostrasAndRealmsVariables = AllKostrasAndRealmsQueryVariables;
export type AllKostrasAndRealmsAllKostraFunctions = FieldsOnKostraFunctionFragment;
export type AllKostrasAndRealmsAllRealms = FieldsOnRealmsFragment;
export type CreateeActionValueVariables = CreateeActionValueMutationVariables;
export type CreateeActionValueCreateActionValue = CreateeActionValueMutation['createActionValue'];
export type UpdateActionValueVariables = UpdateActionValueMutationVariables;
export type UpdateActionValueUpdateActionValue = UpdateActionValueMutation['updateActionValue'];
export type UpdateActionValueRealms = UpdateActionValueMutation['updateActionValue']['realms'][0];
export type GetActionValueVariables = GetActionValueQueryVariables;
export type GetActionValueActionValue = GetActionValueQuery['actionValue'];
export type GetActionValueRealms = GetActionValueQuery['actionValue']['realms'][0];
export type GetActionValueKostraFunctions = FieldsOnKostraFunctionFragment;
export type GetActionValueCurrentRevision = GetActionValueQuery['actionValue']['currentRevision'];
export type GetActionValueLinks = GetActionValueQuery['actionValue']['currentRevision']['links'][0];
export type GetActionValueSource = GetActionValueQuery['actionValue']['currentRevision']['source'];
export type GetActionValueRadarGraph = GetActionValueQuery['actionValue']['currentRevision']['radarGraph'][0];
export type GetActionValueDeadlines = GetActionValueQuery['actionValue']['currentRevision']['deadlines'][0];
export type GetActionValueTopics = GetActionValueQuery['actionValue']['topics'][0];
export type GetActionValueFiles = GetActionValueQuery['actionValue']['files'][0];
export type GetActionValueComments = GetActionValueQuery['actionValue']['comments'][0];
export type GetActionValueAuthor = GetActionValueQuery['actionValue']['comments'][0]['author'];
export type GetActionValueOrganization = GetActionValueQuery['actionValue']['comments'][0]['author']['organization'];
export type GetActionValue_Files = GetActionValueQuery['actionValue']['comments'][0]['files'][0];
export type GetActionValueReplies = GetActionValueQuery['actionValue']['comments'][0]['replies'][0];
export type GetActionValue_Author = GetActionValueQuery['actionValue']['comments'][0]['replies'][0]['author'];
export type GetActionValue_Organization = GetActionValueQuery['actionValue']['comments'][0]['replies'][0]['author']['organization'];
export type GetActionValue__Files = GetActionValueQuery['actionValue']['comments'][0]['replies'][0]['files'][0];
export type GetActionValueAllKostraFunctionGroups = FieldsOnKostraFunctionGroupFragment;
export type GetActionValueAllKostraFunctions = FieldsOnKostraFunctionFragment;
export type GetActionValueMe = GetActionValueQuery['me'];
export type GetActionValue__Organization = GetActionValueQuery['me']['organization'];
export type GetActionValueAllRealms = GetActionValueQuery['allRealms'][0];
export type FieldsOnActionValueKostraFunctions = FieldsOnKostraFunctionFragment;
export type FieldsOnActionValueCurrentRevision = FieldsOnActionValueFragment['currentRevision'];
export type FieldsOnActionValueLinks = FieldsOnActionValueFragment['currentRevision']['links'][0];
export type FieldsOnActionValueSource = FieldsOnActionValueFragment['currentRevision']['source'];
export type FieldsOnActionValueRadarGraph = FieldsOnActionValueFragment['currentRevision']['radarGraph'][0];
export type FieldsOnActionValueDeadlines = FieldsOnActionValueFragment['currentRevision']['deadlines'][0];
export type FieldsOnActionValueTopics = FieldsOnActionValueFragment['topics'][0];
export type FieldsOnActionValueFiles = FieldsOnUserFileFragment;
export type FieldsOnActionValueComments = FieldsOnCommentFragment;
export type FieldsOnActionValueReplies = FieldsOnRepliesFragment;
export type ActionValueVariables = ActionValueQueryVariables;
export type ActionValueActionValue = FieldsOnActionValueFragment;
export type ActionValueMe = FieldsOnMeSimpleFragment;
export type AllActionValuesVariables = AllActionValuesQueryVariables;
export type AllActionValuesAllKostraFunctionGroups = AllActionValuesQuery['allKostraFunctionGroups'][0];
export type AllActionValuesAllActionValues = AllActionValuesQuery['allActionValues'];
export type AllActionValuesPageInfo = AllActionValuesQuery['allActionValues']['pageInfo'];
export type AllActionValuesNodes = FieldsOnActionValueFragment;
export type AllActionValuesAllSectors = AllActionValuesQuery['allSectors'][0];
export type MeForCalendarListPageVariables = MeForCalendarListPageQueryVariables;
export type MeForCalendarListPageMe = MeForCalendarListPageQuery['me'];
export type MeForCalendarListPageOrganization = MeForCalendarListPageQuery['me']['organization'];
export type FindOrganizationsVariables = FindOrganizationsQueryVariables;
export type FindOrganizationsFindOrganizations = FindOrganizationsQuery['findOrganizations'][0];
export type FindPersons2Variables = FindPersons2QueryVariables;
export type FindPersons2FindPersonsByNameOrEmail = FindPersons2Query['findPersonsByNameOrEmail'][0];
export type FindPersons2Organization = FindPersons2Query['findPersonsByNameOrEmail'][0]['organization'];
export type AllCalendarsVariables = AllCalendarsQueryVariables;
export type AllCalendarsMe = AllCalendarsQuery['me'];
export type AllCalendarsOrganization = AllCalendarsQuery['me']['organization'];
export type AllCalendarsAllKostraFunctionGroups = AllCalendarsQuery['allKostraFunctionGroups'][0];
export type AllCalendarsAllCalendars = AllCalendarsQuery['allCalendars'];
export type AllCalendarsPageInfo = AllCalendarsQuery['allCalendars']['pageInfo'];
export type AllCalendarsNodes = FieldsOnCalendarFragment;
export type CheckCalendarCopyReadyVariables = CheckCalendarCopyReadyQueryVariables;
export type FieldsOnTaskTags = FieldsOnTaskFragment['tags'][0];
export type FieldsOnMiniPersonOrganization = FieldsOnMiniPersonFragment['organization'];
export type FieldsOnEventForkedFrom = FieldsOnEventFragment['forkedFrom'];
export type FieldsOnEventTags = FieldsOnEventFragment['tags'][0];
export type FieldsOnEventFiles = FieldsOnEventFragment['files'][0];
export type FieldsOnEventAccess = FieldsOnEventFragment['files'][0]['access'];
export type FieldsOnEventAssignedBy = FieldsOnMiniPersonFragment;
export type FieldsOnEventResponsible = FieldsOnMiniPersonFragment;
export type FieldsOnEventTodoTemplate = FieldsOnEventFragment['todoTemplate'][0];
export type FieldsOnEventInstances = FieldsOnEventFragment['instances'][0];
export type FieldsOnEvent_ForkedFrom = FieldsOnEventFragment['instances'][0]['forkedFrom'];
export type FieldsOnEventForks = FieldsOnEventFragment['instances'][0]['forks'][0];
export type FieldsOnEventEvent = FieldsOnEventFragment['instances'][0]['forks'][0]['event'];
export type FieldsOnEventTask = FieldsOnEventFragment['instances'][0]['forks'][0]['event']['task'];
export type FieldsOnEventCalendars = FieldsOnEventFragment['instances'][0]['forks'][0]['event']['task']['calendars'][0];
export type FieldsOnEvent_Tags = FieldsOnEventFragment['instances'][0]['forks'][0]['tags'][0];
export type FieldsOnEvent_Files = FieldsOnEventFragment['instances'][0]['forks'][0]['files'][0];
export type FieldsOnEvent_Access = FieldsOnEventFragment['instances'][0]['forks'][0]['files'][0]['access'];
export type FieldsOnEvent_AssignedBy = FieldsOnMiniPersonFragment;
export type FieldsOnEvent_Responsible = FieldsOnMiniPersonFragment;
export type FieldsOnEventCreatedBy = FieldsOnMiniPersonFragment;
export type FieldsOnEventTodos = FieldsOnEventFragment['instances'][0]['forks'][0]['todos'][0];
export type FieldsOnEvent__Tags = FieldsOnEventFragment['instances'][0]['tags'][0];
export type FieldsOnEvent__Files = FieldsOnEventFragment['instances'][0]['files'][0];
export type FieldsOnEvent__Access = FieldsOnEventFragment['instances'][0]['files'][0]['access'];
export type FieldsOnEvent__AssignedBy = FieldsOnMiniPersonFragment;
export type FieldsOnEvent__Responsible = FieldsOnMiniPersonFragment;
export type FieldsOnEvent_CreatedBy = FieldsOnMiniPersonFragment;
export type FieldsOnEvent_Todos = FieldsOnEventFragment['instances'][0]['todos'][0];
export type FieldsOnEventInstanceForkedFrom = FieldsOnEventInstanceFragment['forkedFrom'];
export type FieldsOnEventInstanceForks = FieldsOnEventInstanceFragment['forks'][0];
export type FieldsOnEventInstanceEvent = FieldsOnEventInstanceFragment['forks'][0]['event'];
export type FieldsOnEventInstanceTask = FieldsOnEventInstanceFragment['forks'][0]['event']['task'];
export type FieldsOnEventInstanceCalendars = FieldsOnEventInstanceFragment['forks'][0]['event']['task']['calendars'][0];
export type FieldsOnEventInstanceTags = FieldsOnEventInstanceFragment['forks'][0]['tags'][0];
export type FieldsOnEventInstanceFiles = FieldsOnEventInstanceFragment['forks'][0]['files'][0];
export type FieldsOnEventInstanceAccess = FieldsOnEventInstanceFragment['forks'][0]['files'][0]['access'];
export type FieldsOnEventInstanceAssignedBy = FieldsOnMiniPersonFragment;
export type FieldsOnEventInstanceResponsible = FieldsOnMiniPersonFragment;
export type FieldsOnEventInstanceCreatedBy = FieldsOnMiniPersonFragment;
export type FieldsOnEventInstanceTodos = FieldsOnEventInstanceFragment['forks'][0]['todos'][0];
export type FieldsOnEventInstance_Tags = FieldsOnEventInstanceFragment['tags'][0];
export type FieldsOnEventInstance_Files = FieldsOnEventInstanceFragment['files'][0];
export type FieldsOnEventInstance_Access = FieldsOnEventInstanceFragment['files'][0]['access'];
export type FieldsOnEventInstance_AssignedBy = FieldsOnMiniPersonFragment;
export type FieldsOnEventInstance_Responsible = FieldsOnMiniPersonFragment;
export type FieldsOnEventInstance_CreatedBy = FieldsOnMiniPersonFragment;
export type FieldsOnEventInstance_Todos = FieldsOnEventInstanceFragment['todos'][0];
export type FieldsOnCalendarAccess = FieldsOnCalendarFragment['access'];
export type FieldsOnCalendarPersons = FieldsOnCalendarFragment['access']['persons'][0];
export type FieldsOnCalendarPerson = FieldsOnCalendarFragment['access']['persons'][0]['person'];
export type FieldsOnCalendarTopics = FieldsOnCalendarFragment['topics'][0];
export type FieldsOnCalendarFollowers = FieldsOnCalendarFragment['followers'][0];
export type FieldsOnCalendarCreatedBy = FieldsOnCalendarFragment['createdBy'];
export type FieldsOnCalendarOrganization = FieldsOnCalendarFragment['createdBy']['organization'];
export type FieldsOnCalendarForkedFrom = FieldsOnCalendarFragment['forkedFrom'];
export type FieldsOnCalendarForks = FieldsOnCalendarFragment['forks'][0];
export type FieldsOnCalendarLookupAccess = FieldsOnCalendarLookupFragment['access'];
export type FieldsOnCalendarLookupPersons = FieldsOnCalendarLookupFragment['access']['persons'][0];
export type FieldsOnCalendarLookupPerson = FieldsOnCalendarLookupFragment['access']['persons'][0]['person'];
export type FieldsOnCalendarLookupCreatedBy = FieldsOnCalendarLookupFragment['createdBy'];
export type FieldsOnCalendarRingCalendar = FieldsOnCalendarLookupFragment;
export type YearWheelVariables = YearWheelQueryVariables;
export type YearWheelMyFollowed = YearWheelQuery['myFollowed'];
export type YearWheelCalendars = YearWheelQuery['myFollowed']['calendars'][0];
export type YearWheelMe = YearWheelQuery['me'];
export type YearWheelSettings = YearWheelQuery['me']['settings'];
export type YearWheelMyCalendars = FieldsOnCalendarLookupFragment;
export type YearWheelYearWheel = FieldsOnCalendarRingFragment;
export type YearWheelMyAssignedTasks = FieldsOnCalendarRingFragment;
export type YearWheelRingVariables = YearWheelRingQueryVariables;
export type YearWheelRingMyFollowed = YearWheelRingQuery['myFollowed'];
export type YearWheelRingTags = YearWheelRingQuery['myFollowed']['tags'][0];
export type YearWheelRingCalendar = YearWheelRingQuery['calendar'];
export type YearWheelRingAccess = YearWheelRingQuery['calendar']['access'];
export type YearWheelRingPersons = YearWheelRingQuery['calendar']['access']['persons'][0];
export type YearWheelRingPerson = YearWheelRingQuery['calendar']['access']['persons'][0]['person'];
export type YearWheelRingTopics = YearWheelRingQuery['calendar']['topics'][0];
export type YearWheelRingFollowers = YearWheelRingQuery['calendar']['followers'][0];
export type YearWheelRingCreatedBy = YearWheelRingQuery['calendar']['createdBy'];
export type YearWheelRingOrganization = YearWheelRingQuery['calendar']['createdBy']['organization'];
export type YearWheelRingForkedFrom = YearWheelRingQuery['calendar']['forkedFrom'];
export type YearWheelRingTasks = FieldsOnTaskFragment;
export type YearWheelRingEvents = FieldsOnEventFragment;
export type YearWheelRingInstances = FieldsOnEventInstanceFragment;
export type YearWheelMagicRingVariables = YearWheelMagicRingQueryVariables;
export type YearWheelMagicRingMyAssignedTasks = YearWheelMagicRingQuery['myAssignedTasks'];
export type YearWheelMagicRingCalendar = YearWheelMagicRingQuery['myAssignedTasks']['calendar'];
export type YearWheelMagicRingAccess = YearWheelMagicRingQuery['myAssignedTasks']['calendar']['access'];
export type YearWheelMagicRingPersons = YearWheelMagicRingQuery['myAssignedTasks']['calendar']['access']['persons'][0];
export type YearWheelMagicRingPerson = YearWheelMagicRingQuery['myAssignedTasks']['calendar']['access']['persons'][0]['person'];
export type YearWheelMagicRingCreatedBy = YearWheelMagicRingQuery['myAssignedTasks']['calendar']['createdBy'];
export type YearWheelMagicRingOrganization = YearWheelMagicRingQuery['myAssignedTasks']['calendar']['createdBy']['organization'];
export type YearWheelMagicRingTasks = FieldsOnTaskFragment;
export type YearWheelMagicRingEvents = FieldsOnEventFragment;
export type YearWheelMagicRingInstances = FieldsOnEventInstanceFragment;
export type UpdateSettingsVariables = UpdateSettingsMutationVariables;
export type UpdateSettingsUpdateSettings = UpdateSettingsMutation['updateSettings'];
export type UpdateSettingsSettings = UpdateSettingsMutation['updateSettings']['settings'];
export type CreateCalendarRingMutationVariables = CreateCalendarRingMutationMutationVariables;
export type CreateCalendarRingMutationCreateCalendarRing = FieldsOnCalendarRingFragment;
export type DeleteCalendarRingMutationVariables = DeleteCalendarRingMutationMutationVariables;
export type ArchiveCalendarMutationVariables = ArchiveCalendarMutationMutationVariables;
export type ArchiveCalendarMutationArchiveCalendar = ArchiveCalendarMutationMutation['archiveCalendar'];
export type MyCalendarsVariables = MyCalendarsQueryVariables;
export type MyCalendarsMyCalendars = MyCalendarsQuery['myCalendars'][0];
export type CalendarVariables = CalendarQueryVariables;
export type CalendarMyFollowed = CalendarQuery['myFollowed'];
export type CalendarTags = CalendarQuery['myFollowed']['tags'][0];
export type CalendarMe = CalendarQuery['me'];
export type CalendarSettings = CalendarQuery['me']['settings'];
export type CalendarYearWheel = CalendarQuery['yearWheel'][0];
export type CalendarCalendar = CalendarQuery['yearWheel'][0]['calendar'];
export type Calendar_Calendar = FieldsOnCalendarFragment;
export type CalendarTasks = FieldsOnTaskFragment;
export type CalendarEvents = FieldsOnEventFragment;
export type CalendarInstances = FieldsOnEventInstanceFragment;
export type SearchTagsVariables = SearchTagsQueryVariables;
export type SearchTagsSearch = SearchTagsQuery['search'];
export type SearchTagsTags = SearchTagsQuery['search']['tags'][0];
export type FollowTagVariables = FollowTagMutationVariables;
export type FollowTagFollowTag = FollowTagMutation['followTag'];
export type UnfollowTagVariables = UnfollowTagMutationVariables;
export type UnfollowTagUnFollowTag = UnfollowTagMutation['unFollowTag'];
export type MakeSharableVariables = MakeSharableMutationVariables;
export type RemoveSharableVariables = RemoveSharableMutationVariables;
export type GetMyFollowedCalendarsDropdownVariables = GetMyFollowedCalendarsDropdownQueryVariables;
export type GetMyFollowedCalendarsDropdownMyFollowed = GetMyFollowedCalendarsDropdownQuery['myFollowed'];
export type GetMyFollowedCalendarsDropdownCalendars = GetMyFollowedCalendarsDropdownQuery['myFollowed']['calendars'][0];
export type GetIncompleteTasksAssignedByMeVariables = GetIncompleteTasksAssignedByMeQueryVariables;
export type GetIncompleteTasksAssignedByMeMe = GetIncompleteTasksAssignedByMeQuery['me'];
export type GetIncompleteTasksAssignedByMeIncompleteTasksAssignedByMe = GetIncompleteTasksAssignedByMeQuery['incompleteTasksAssignedByMe'][0];
export type GetIncompleteTasksAssignedByMeTags = GetIncompleteTasksAssignedByMeQuery['incompleteTasksAssignedByMe'][0]['tags'][0];
export type GetIncompleteTasksAssignedByMeCreatedBy = FieldsOnMiniPersonFragment;
export type GetIncompleteTasksAssignedByMeResponsible = FieldsOnMiniPersonFragment;
export type GetIncompleteTasksAssignedByMeEvent = GetIncompleteTasksAssignedByMeQuery['incompleteTasksAssignedByMe'][0]['event'];
export type GetIncompleteTasksAssignedByMeTask = GetIncompleteTasksAssignedByMeQuery['incompleteTasksAssignedByMe'][0]['event']['task'];
export type GetIncompleteTasksAssignedByMeCalendars = GetIncompleteTasksAssignedByMeQuery['incompleteTasksAssignedByMe'][0]['event']['task']['calendars'][0];
export type GetUpcomingDeadlinesVariables = GetUpcomingDeadlinesQueryVariables;
export type GetUpcomingDeadlinesMe = GetUpcomingDeadlinesQuery['me'];
export type GetUpcomingDeadlinesUpcomingDeadlines = GetUpcomingDeadlinesQuery['upcomingDeadlines'][0];
export type GetUpcomingDeadlinesTags = GetUpcomingDeadlinesQuery['upcomingDeadlines'][0]['tags'][0];
export type GetUpcomingDeadlinesCreatedBy = FieldsOnMiniPersonFragment;
export type GetUpcomingDeadlinesResponsible = FieldsOnMiniPersonFragment;
export type GetUpcomingDeadlinesAssignedBy = FieldsOnMiniPersonFragment;
export type GetUpcomingDeadlinesEvent = GetUpcomingDeadlinesQuery['upcomingDeadlines'][0]['event'];
export type GetUpcomingDeadlinesTask = GetUpcomingDeadlinesQuery['upcomingDeadlines'][0]['event']['task'];
export type GetUpcomingDeadlinesCalendars = GetUpcomingDeadlinesQuery['upcomingDeadlines'][0]['event']['task']['calendars'][0];
export type GetMyUnfinishedTasksVariables = GetMyUnfinishedTasksQueryVariables;
export type GetMyUnfinishedTasksMe = GetMyUnfinishedTasksQuery['me'];
export type GetMyUnfinishedTasksMyUnfinishedTasks = GetMyUnfinishedTasksQuery['myUnfinishedTasks'][0];
export type GetMyUnfinishedTasksTags = GetMyUnfinishedTasksQuery['myUnfinishedTasks'][0]['tags'][0];
export type GetMyUnfinishedTasksCreatedBy = FieldsOnMiniPersonFragment;
export type GetMyUnfinishedTasksResponsible = FieldsOnMiniPersonFragment;
export type GetMyUnfinishedTasksAssignedBy = FieldsOnMiniPersonFragment;
export type GetMyUnfinishedTasksEvent = GetMyUnfinishedTasksQuery['myUnfinishedTasks'][0]['event'];
export type GetMyUnfinishedTasksTask = GetMyUnfinishedTasksQuery['myUnfinishedTasks'][0]['event']['task'];
export type GetMyUnfinishedTasksCalendars = GetMyUnfinishedTasksQuery['myUnfinishedTasks'][0]['event']['task']['calendars'][0];
export type GetMyFollowedActionValuesVariables = GetMyFollowedActionValuesQueryVariables;
export type GetMyFollowedActionValuesMyFollowed = GetMyFollowedActionValuesQuery['myFollowed'];
export type GetMyFollowedActionValuesActionValues = GetMyFollowedActionValuesQuery['myFollowed']['actionValues'][0];
export type GetActionValuesVariables = GetActionValuesQueryVariables;
export type GetActionValuesAllActionValues = GetActionValuesQuery['allActionValues'];
export type GetActionValuesNodes = GetActionValuesQuery['allActionValues']['nodes'][0];
export type GetActionValuesMyFollowed = GetActionValuesQuery['myFollowed'];
export type GetActionValuesActionValues = GetActionValuesQuery['myFollowed']['actionValues'][0];
export type FollowGrantVariables = FollowGrantMutationVariables;
export type UnfollowGrantVariables = UnfollowGrantMutationVariables;
export type GetMyFollowedCalendarsVariables = GetMyFollowedCalendarsQueryVariables;
export type GetMyFollowedCalendarsMyFollowed = GetMyFollowedCalendarsQuery['myFollowed'];
export type GetMyFollowedCalendarsCalendars = GetMyFollowedCalendarsQuery['myFollowed']['calendars'][0];
export type GetOneCalendarVariables = GetOneCalendarQueryVariables;
export type GetOneCalendarCalendar = GetOneCalendarQuery['calendar'];
export type GetOneCalendarFollowers = GetOneCalendarQuery['calendar']['followers'][0];
export type MeForDashboardVariables = MeForDashboardQueryVariables;
export type MeForDashboardMe = MeForDashboardQuery['me'];
export type MeForDashboardOrganization = MeForDashboardQuery['me']['organization'];
export type FollowCalendarDashboardVariables = FollowCalendarDashboardMutationVariables;
export type UnfollowCalendarDashboardVariables = UnfollowCalendarDashboardMutationVariables;
export type GetMyFollowedDiscussionsVariables = GetMyFollowedDiscussionsQueryVariables;
export type GetMyFollowedDiscussionsMyFollowed = GetMyFollowedDiscussionsQuery['myFollowed'];
export type GetMyFollowedDiscussionsDiscussions = GetMyFollowedDiscussionsQuery['myFollowed']['discussions'][0];
export type FollowDiscussionVariables = FollowDiscussionMutationVariables;
export type UnfollowDiscussionVariables = UnfollowDiscussionMutationVariables;
export type MyFollowedTopicsVariables = MyFollowedTopicsQueryVariables;
export type MyFollowedTopicsMyFollowed = MyFollowedTopicsQuery['myFollowed'];
export type MyFollowedTopicsTopics = MyFollowedTopicsQuery['myFollowed']['topics'][0];
export type GetTopicsVariables = GetTopicsQueryVariables;
export type GetTopicsAllTopics = GetTopicsQuery['allTopics'];
export type GetTopicsNodes = GetTopicsQuery['allTopics']['nodes'][0];
export type GetTopicsMe = GetTopicsQuery['me'];
export type GetTopicsOrganization = GetTopicsQuery['me']['organization'];
export type FollowTopicVariables = FollowTopicMutationVariables;
export type UnfollowTopicVariables = UnfollowTopicMutationVariables;
export type UpdateDiscussionVariables = UpdateDiscussionMutationVariables;
export type UpdateDiscussionUpdateDiscussion = UpdateDiscussionMutation['updateDiscussion'];
export type CreateCommentVariables = CreateCommentMutationVariables;
export type CreateCommentCreateComment = CreateCommentMutation['createComment'];
export type UpdateCommentVariables = UpdateCommentMutationVariables;
export type UpdateCommentUpdateComment = UpdateCommentMutation['updateComment'];
export type SetStatusVariables = SetStatusMutationVariables;
export type FieldsOnUserFileAccess = FieldsOnUserFileFragment['access'];
export type FieldsOnUserFileCreatedBy = FieldsOnUserFileFragment['createdBy'];
export type FieldsOnUserFileOrganization = FieldsOnUserFileFragment['createdBy']['organization'];
export type FieldsOnAuthorOrganization = FieldsOnAuthorFragment['organization'];
export type FieldsOnRepliesAuthor = FieldsOnAuthorFragment;
export type FieldsOnCommentReplies = FieldsOnRepliesFragment;
export type FieldsOnCommentAuthor = FieldsOnAuthorFragment;
export type FieldsOnCommentFiles = FieldsOnUserFileFragment;
export type FieldsOnMeSimpleOrganization = FieldsOnMeSimpleFragment['organization'];
export type DiscussionVariables = DiscussionQueryVariables;
export type DiscussionDiscussion = DiscussionQuery['discussion'];
export type DiscussionCreatedBy = FieldsOnAuthorFragment;
export type DiscussionCurrentRevision = DiscussionQuery['discussion']['currentRevision'];
export type DiscussionTopics = DiscussionQuery['discussion']['topics'][0];
export type DiscussionFiles = FieldsOnUserFileFragment;
export type DiscussionComments = FieldsOnCommentFragment;
export type DiscussionReplies = FieldsOnRepliesFragment;
export type DiscussionMe = FieldsOnMeSimpleFragment;
export type FieldsOnDiscussionCreatedBy = FieldsOnDiscussionFragment['createdBy'];
export type FieldsOnDiscussionOrganization = FieldsOnDiscussionFragment['createdBy']['organization'];
export type FieldsOnDiscussionCurrentRevision = FieldsOnDiscussionFragment['currentRevision'];
export type FieldsOnDiscussionComments = FieldsOnDiscussionFragment['comments'][0];
export type FieldsOnDiscussionTopics = FieldsOnDiscussionFragment['topics'][0];
export type FieldsOnDiscussion_CreatedBy = FieldsOnDiscussionFragment['topics'][0]['createdBy'];
export type FieldsOnDiscussion_Organization = FieldsOnDiscussionFragment['topics'][0]['createdBy']['organization'];
export type FieldsOnDiscussionFollowers = FieldsOnDiscussionFragment['followers'][0];
export type FieldsOnDiscussionFiles = FieldsOnDiscussionFragment['files'][0];
export type FieldsOnDiscussion__CreatedBy = FieldsOnDiscussionFragment['files'][0]['createdBy'];
export type FieldsOnDiscussion__Organization = FieldsOnDiscussionFragment['files'][0]['createdBy']['organization'];
export type FieldsOnDiscussionAccess = FieldsOnDiscussionFragment['files'][0]['access'];
export type AllDiscussionsVariables = AllDiscussionsQueryVariables;
export type AllDiscussionsMe = AllDiscussionsQuery['me'];
export type AllDiscussionsOrganization = AllDiscussionsQuery['me']['organization'];
export type AllDiscussionsAllDiscussions = AllDiscussionsQuery['allDiscussions'];
export type AllDiscussionsPageInfo = AllDiscussionsQuery['allDiscussions']['pageInfo'];
export type AllDiscussionsNodes = FieldsOnDiscussionFragment;
export type CreateDiscussion2Variables = CreateDiscussion2MutationVariables;
export type CreateDiscussion2CreateDiscussion = FieldsOnDiscussionFragment;
export type OrganizationQueryVariables = OrganizationQueryQueryVariables;
export type OrganizationQueryOrganization = OrganizationQueryQuery['organization'];
export type OrganizationQueryMembers = OrganizationQueryQuery['organization']['members'][0];
export type OrganizationQuery_Organization = OrganizationQueryQuery['organization']['members'][0]['organization'];
export type ProfileQueryVariables = ProfileQueryQueryVariables;
export type ProfileQueryPerson = ProfileQueryQuery['person'];
export type ProfileQueryOrganization = ProfileQueryQuery['person']['organization'];
export type ProfileQueryKostraFunctions = ProfileQueryQuery['person']['kostraFunctions'][0];
export type ProfileQueryMe = ProfileQueryQuery['me'];
export type AddTopicMutationVariables = AddTopicMutationMutationVariables;
export type AddTopicMutationCreateTopic = AddTopicMutationMutation['createTopic'];
export type AddTopicMutationCurrentRevision = AddTopicMutationMutation['createTopic']['currentRevision'];
export type MeCreateTopicVariables = MeCreateTopicQueryVariables;
export type MeCreateTopicMyFollowed = MeCreateTopicQuery['myFollowed'];
export type MeCreateTopicTags = MeCreateTopicQuery['myFollowed']['tags'][0];
export type MeCreateTopicMe = MeCreateTopicQuery['me'];
export type MeCreateTopicKostraFunctions = MeCreateTopicQuery['me']['kostraFunctions'][0];
export type MeCreateTopicOrganization = MeCreateTopicQuery['me']['organization'];
export type UpdateTopicVariables = UpdateTopicMutationVariables;
export type UpdateTopicUpdateTopic = UpdateTopicMutation['updateTopic'];
export type UpdateTopicTopics = UpdateTopicMutation['updateTopic']['topics'][0];
export type UpdateTopicCreatedBy = UpdateTopicMutation['updateTopic']['topics'][0]['createdBy'];
export type UpdateTopicOrganization = FieldsOnOrganizationFragment;
export type ChangeOwnerVariables = ChangeOwnerMutationVariables;
export type TopicQueryVariables = TopicQueryQueryVariables;
export type TopicQueryMyFollowed = TopicQueryQuery['myFollowed'];
export type TopicQueryTags = TopicQueryQuery['myFollowed']['tags'][0];
export type TopicQueryMe = TopicQueryQuery['me'];
export type TopicQueryOrganization = FieldsOnOrganizationFragment;
export type TopicQueryTopic = TopicQueryQuery['topic'];
export type TopicQuery_Tags = TopicQueryQuery['topic']['tags'][0];
export type TopicQueryAccess = TopicQueryQuery['topic']['access'];
export type TopicQueryGroups = TopicQueryQuery['topic']['access']['groups'][0];
export type TopicQueryGroup = TopicQueryQuery['topic']['access']['groups'][0]['group'];
export type TopicQueryCreatedBy = TopicQueryQuery['topic']['access']['groups'][0]['group']['createdBy'];
export type TopicQueryMembers = TopicQueryQuery['topic']['access']['groups'][0]['group']['members'][0];
export type TopicQueryOrganizationGroups = TopicQueryQuery['topic']['access']['organizationGroups'][0];
export type TopicQueryOrganizationGroup = TopicQueryQuery['topic']['access']['organizationGroups'][0]['organizationGroup'];
export type TopicQuery_CreatedBy = TopicQueryQuery['topic']['access']['organizationGroups'][0]['organizationGroup']['createdBy'];
export type TopicQuery_Members = TopicQueryQuery['topic']['access']['organizationGroups'][0]['organizationGroup']['members'][0];
export type TopicQueryOrganizations = TopicQueryQuery['topic']['access']['organizations'][0];
export type TopicQuery_Organization = TopicQueryQuery['topic']['access']['organizations'][0]['organization'];
export type TopicQueryPersons = TopicQueryQuery['topic']['access']['persons'][0];
export type TopicQueryPerson = TopicQueryQuery['topic']['access']['persons'][0]['person'];
export type TopicQuery__Organization = TopicQueryQuery['topic']['access']['persons'][0]['person']['organization'];
export type TopicQuery__CreatedBy = TopicQueryQuery['topic']['createdBy'];
export type TopicQuery___Organization = FieldsOnOrganizationFragment;
export type TopicQueryLinks = TopicQueryQuery['topic']['links'][0];
export type TopicQuery_Access = TopicQueryQuery['topic']['links'][0]['access'];
export type TopicQuery___CreatedBy = FieldsOnAuthorFragment;
export type TopicQueryCalendars = TopicQueryQuery['topic']['calendars'][0];
export type TopicQuery__Access = TopicQueryQuery['topic']['calendars'][0]['access'];
export type TopicQueryFollowers = TopicQueryQuery['topic']['calendars'][0]['followers'][0];
export type TopicQueryTopics = TopicQueryQuery['topic']['calendars'][0]['topics'][0];
export type TopicQuery____CreatedBy = TopicQueryQuery['topic']['calendars'][0]['createdBy'];
export type TopicQuery____Organization = FieldsOnOrganizationFragment;
export type TopicQueryCurrentRevision = TopicQueryQuery['topic']['currentRevision'];
export type TopicQuerySector = TopicQueryQuery['topic']['currentRevision']['sector'];
export type TopicQueryKostraFunctions = TopicQueryQuery['topic']['currentRevision']['kostraFunctions'][0];
export type TopicQuery_Followers = TopicQueryQuery['topic']['followers'][0];
export type TopicQuery_____Organization = FieldsOnOrganizationFragment;
export type TopicQueryActionValues = FieldsOnActionValueFragment;
export type TopicQueryDiscussions = TopicQueryQuery['topic']['discussions'][0];
export type TopicQuery_____CreatedBy = TopicQueryQuery['topic']['discussions'][0]['createdBy'];
export type TopicQuery______Organization = FieldsOnOrganizationFragment;
export type TopicQuery_CurrentRevision = TopicQueryQuery['topic']['discussions'][0]['currentRevision'];
export type TopicQueryComments = TopicQueryQuery['topic']['discussions'][0]['comments'][0];
export type TopicQuery_Topics = TopicQueryQuery['topic']['discussions'][0]['topics'][0];
export type TopicQuery______CreatedBy = TopicQueryQuery['topic']['discussions'][0]['topics'][0]['createdBy'];
export type TopicQuery_______Organization = FieldsOnOrganizationFragment;
export type TopicQueryFiles = TopicQueryQuery['topic']['discussions'][0]['files'][0];
export type TopicQuery_______CreatedBy = TopicQueryQuery['topic']['discussions'][0]['files'][0]['createdBy'];
export type TopicQuery________Organization = FieldsOnOrganizationFragment;
export type TopicQuery___Access = TopicQueryQuery['topic']['discussions'][0]['files'][0]['access'];
export type TopicQuery__Followers = TopicQueryQuery['topic']['discussions'][0]['followers'][0];
export type TopicQuery_Files = TopicQueryQuery['topic']['files'][0];
export type TopicQuery____Access = TopicQueryQuery['topic']['files'][0]['access'];
export type TopicQuery________CreatedBy = TopicQueryQuery['topic']['files'][0]['createdBy'];
export type TopicQuery_________Organization = FieldsOnOrganizationFragment;
export type TopicQuery__Topics = TopicQueryQuery['topic']['topics'][0];
export type TopicQuery_____Access = TopicQueryQuery['topic']['topics'][0]['access'];
export type TopicQuery_Groups = TopicQueryQuery['topic']['topics'][0]['access']['groups'][0];
export type TopicQuery_Group = TopicQueryQuery['topic']['topics'][0]['access']['groups'][0]['group'];
export type TopicQuery_________CreatedBy = TopicQueryQuery['topic']['topics'][0]['access']['groups'][0]['group']['createdBy'];
export type TopicQuery__Members = TopicQueryQuery['topic']['topics'][0]['access']['groups'][0]['group']['members'][0];
export type TopicQuery_OrganizationGroups = TopicQueryQuery['topic']['topics'][0]['access']['organizationGroups'][0];
export type TopicQuery_OrganizationGroup = TopicQueryQuery['topic']['topics'][0]['access']['organizationGroups'][0]['organizationGroup'];
export type TopicQuery__________CreatedBy = TopicQueryQuery['topic']['topics'][0]['access']['organizationGroups'][0]['organizationGroup']['createdBy'];
export type TopicQuery___Members = TopicQueryQuery['topic']['topics'][0]['access']['organizationGroups'][0]['organizationGroup']['members'][0];
export type TopicQuery_Organizations = TopicQueryQuery['topic']['topics'][0]['access']['organizations'][0];
export type TopicQuery__________Organization = TopicQueryQuery['topic']['topics'][0]['access']['organizations'][0]['organization'];
export type TopicQuery_Persons = TopicQueryQuery['topic']['topics'][0]['access']['persons'][0];
export type TopicQuery_Person = TopicQueryQuery['topic']['topics'][0]['access']['persons'][0]['person'];
export type TopicQuery___________Organization = TopicQueryQuery['topic']['topics'][0]['access']['persons'][0]['person']['organization'];
export type TopicQuery___________CreatedBy = TopicQueryQuery['topic']['topics'][0]['createdBy'];
export type TopicQuery____________Organization = FieldsOnOrganizationFragment;
export type TopicQuery___Followers = TopicQueryQuery['topic']['topics'][0]['followers'][0];
export type TopicQuery__CurrentRevision = TopicQueryQuery['topic']['topics'][0]['currentRevision'];
export type FieldsOnTopicTopics = FieldsOnTopicTopicFragment;
export type FieldsOnTopicCurrentRevision = FieldsOnTopicFragment['currentRevision'];
export type FieldsOnTopicFollowers = FieldsOnTopicFragment['followers'][0];
export type FieldsOnTopicCreatedBy = FieldsOnTopicFragment['createdBy'];
export type FieldsOnTopicOrganization = FieldsOnTopicFragment['createdBy']['organization'];
export type AllTopicsAndMeQueryTVariables = AllTopicsAndMeQueryTQueryVariables;
export type AllTopicsAndMeQueryTAllKostraFunctionGroups = AllTopicsAndMeQueryTQuery['allKostraFunctionGroups'][0];
export type AllTopicsAndMeQueryTMe = AllTopicsAndMeQueryTQuery['me'];
export type AllTopicsAndMeQueryTOrganization = AllTopicsAndMeQueryTQuery['me']['organization'];
export type AllTopicsAndMeQueryTAllSectors = AllTopicsAndMeQueryTQuery['allSectors'][0];
export type AllTopicsAndMeQueryTAllTopics = AllTopicsAndMeQueryTQuery['allTopics'];
export type AllTopicsAndMeQueryTPageInfo = AllTopicsAndMeQueryTQuery['allTopics']['pageInfo'];
export type AllTopicsAndMeQueryTNodes = FieldsOnTopicFragment;
export type GrantsTestQueryVariables = GrantsTestQueryQueryVariables;
export type GrantsTestQueryAllActionValues = GrantsTestQueryQuery['allActionValues'];
export type GrantsTestQueryNodes = GrantsTestQueryQuery['allActionValues']['nodes'][0];
export type LikeVariables = LikeMutationVariables;
export type UnLikeVariables = UnLikeMutationVariables;
